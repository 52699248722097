import { ShieldCheck, Info } from "@phosphor-icons/react";
import * as iso from "iso-3166-1";
import { Trans, useTranslation } from "react-i18next";

import {
  ModalBody,
  VStack,
  Text,
  Button,
  HStack,
  Center,
  Link,
  Card,
  Box,
  Flex,
} from "@chakra-ui/react";

import { HiiveModalFooter } from "@/components/common";
import { SimpleListbox } from "@/components/form";
import {
  ExecutionCollectBankAccountTaskDataFragment,
  ExecutionTaskActionType,
  useCountriesQuery,
  useExecutionTaskActionMutation,
} from "@/gql";
import {
  useCountryList,
  useMemoizedCountries,
  useMutationWithError,
} from "@/hooks";

import MTPrivacyPolicyLink from "./MTPrivacyPolicyLink";
import { CollectBankAccountSteps, Country } from "./types";

const ENABLED_COUNTRIES = [
  `USA`,
  `CAN`,
  `IND`,
  `GBR`,
  `AUS`,
  `BEL`,
  `CHL`,
  `CHN`,
  `COL`,
  `DEU`,
  `ESP`,
  `FRA`,
  `HKG`,
  `IRL`,
  `ITA`,
  `PER`,
  `MEX`,
  `NLD`,
];

const CountryCollectionContent = ({
  onClose,
  setStep,
  entity,
  taskId,
  setClientToken,
  selectedCountry,
  setSelectedCountry,
}: {
  onClose: () => void;
  setStep: (step: CollectBankAccountSteps) => void;
  entity: ExecutionCollectBankAccountTaskDataFragment["entity"];
  taskId: string;
  setClientToken: (token: string) => void;
  selectedCountry?: Country;
  setSelectedCountry: (country: Country | undefined) => void;
}) => {
  const { t } = useTranslation(`execution`);

  const { data } = useCountriesQuery();
  const countryList = useCountryList();
  const countriesMap: Record<string, string> = useMemoizedCountries({
    countries: data?.countries,
    countryList,
  });

  const [executionTaskActionMutation, isLoading] = useMutationWithError(
    useExecutionTaskActionMutation(),
    `executionTaskAction`,
  );

  const countryItems = Object.keys(countriesMap)
    .map((key) => ({
      id: key,
      name: countriesMap[key],
      iso: iso.whereCountry(
        // in useMemoizedCountries we rename the UK -- so for the ISO lookup we need to use the actual name
        countriesMap[key] === `United Kingdom`
          ? `United Kingdom of Great Britain and Northern Ireland`
          : countriesMap[key],
      )?.alpha3,
    }))
    .filter((country) => ENABLED_COUNTRIES.includes(country?.iso || ``))
    .sort((countryA, countryB) => {
      const countryAIndex = ENABLED_COUNTRIES.indexOf(countryA?.iso || ``);
      const countryBIndex = ENABLED_COUNTRIES.indexOf(countryB?.iso || ``);

      if (countryAIndex < countryBIndex) return -1;

      return 1;
    });

  const isUSASelected = selectedCountry?.iso === `USA`;

  const handleNext = async () => {
    if (!selectedCountry) return;
    const response = await executionTaskActionMutation({
      variables: {
        id: taskId,
        type: ExecutionTaskActionType.CollectBankAccountCreateFlow,
        input: {
          collectBankAccountCreateFlow: {
            countryCode: selectedCountry?.iso || ``,
          },
        },
      },
    });

    if (
      !response ||
      response.executionTaskAction.result?.__typename !==
        `ExecutionTaskActionCollectBankAccountCreateFlow`
    )
      return;

    setClientToken(response.executionTaskAction.result.clientToken || ``);
    setStep(`account_collection`);
  };

  return (
    <>
      <ModalBody>
        <VStack spacing={6} alignItems="start">
          <VStack spacing={4} w="full" alignItems="start">
            <Text textStyle="heading-xl">{t(`your_bank_details`)}</Text>
            <Card
              px={3}
              py={2}
              variant="flat"
              w="full"
              borderWidth="1px"
              alignItems="start"
            >
              <Text textStyle="text-sm" color="grey.600">
                {entity?.legalName} &#x2022;{` `}
                {entity?.jurisdictionOfFormation?.name}
              </Text>
            </Card>
          </VStack>
          <VStack spacing={4} w="full" alignItems="start">
            <Box w="full">
              <SimpleListbox
                placeholder={t`select_bank_location`}
                items={countryItems}
                itemToString={(item) => item?.name || ``}
                getItemKey={(item) => item?.id || ``}
                onItemSelected={(item) => setSelectedCountry(item)}
                value={selectedCountry}
                label={t`where_is_your_bank_located`}
              />
            </Box>
            {isUSASelected && (
              <Card
                px={4}
                py={3}
                as={Flex}
                flexDirection="row"
                gap={3}
                variant="flat"
                alignItems="center"
                w="full"
                borderColor="grey.800"
                borderWidth="1px"
                bg="grey.50"
              >
                <Box>
                  <Info size={24} weight="fill" />
                </Box>
                <Text textStyle="text-sm">
                  <Trans
                    i18nKey="usa_disclaimer"
                    components={{
                      u: <u />,
                    }}
                    ns="execution"
                  />
                </Text>
              </Card>
            )}
            <Text>
              <Trans
                i18nKey="no_bank_available"
                components={{
                  a: (
                    <Link
                      href="mailto:execution@hiive.com"
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                }}
                ns="execution"
              />
            </Text>
          </VStack>
          <HStack spacing={3}>
            <Center>
              <ShieldCheck size={24} />
            </Center>
            <Text textStyle="text-xs" mt={2} color="grey.700">
              <Trans
                i18nKey="bank_country_disclaimer"
                components={{ a: <MTPrivacyPolicyLink /> }}
                ns="execution"
              />
            </Text>
          </HStack>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HStack>
          <Button variant="rounded-outline-grey" onClick={onClose} size="xl">
            {t(`cancel`)}
          </Button>
          <Button
            variant="rounded-solid-grey"
            onClick={handleNext}
            size="xl"
            isDisabled={!selectedCountry || isLoading}
          >
            {t(`next`)}
          </Button>
        </HStack>
      </HiiveModalFooter>
    </>
  );
};

export default CountryCollectionContent;
