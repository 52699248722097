import { Text } from "@chakra-ui/react";

import { ActiveTransactionItemTransactionFragment } from "@/gql";
import { useTransactionDetails } from "@/hooks";

type ActiveTransactionItemStateLabelProps = {
  transaction: ActiveTransactionItemTransactionFragment;
};

const ActiveTransactionItemStateLabel = ({
  transaction,
}: ActiveTransactionItemStateLabelProps) => {
  const { getStateLabel } = useTransactionDetails();

  return (
    <Text color="teal.900" textStyle="heading-xs" fontWeight={400}>
      {getStateLabel(
        transaction.workflow?.lastCompletedMilestone
          ? transaction.workflow?.lastCompletedMilestone
          : transaction.state,
      )}
    </Text>
  );
};

export default ActiveTransactionItemStateLabel;
