import { nanoid } from "nanoid";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { useRouter } from "next/router";

import { VStack, HStack, Box, Text } from "@chakra-ui/react";

import { InternalLink } from "@/components/common";
import {
  DesktopSideBarCollapsedLink,
  DesktopNavContent,
  DesktopSideBarDivider,
  DesktopSideBarLink,
} from "@/components/nav";

import { IssuerNavContentType, IssuerNavContentEntity } from "./constants";
import { MobileNavDivider } from "./mobile";

export type IssuerNavContentLinkType = {
  href: string;
  icon: ReactNode;
  labelKey: "transactions" | "company_info" | "manage_users";
  paths?: string[];
};

type IssuerMobileNavBarContentLinkProps = {
  isOpen?: boolean;
  onToggle?: () => void;
  children?: ReactNode;
} & IssuerNavContentLinkType;

type IssuerDesktopSideBarContentLinkProps = {
  isOpen: boolean;
} & IssuerNavContentLinkType;

type IssuerDesktopNavContentProps = {
  content: IssuerNavContentEntity[];
  isOpen: boolean;
};

type IssuerNavContentProps = {
  content: IssuerNavContentEntity[];
  isOpen: boolean;
  onToggle?: () => void;
};

function IssuerMobileNavContentLink({
  labelKey,
  href,
  icon,
  paths,
  onToggle,
}: IssuerMobileNavBarContentLinkProps) {
  const { t } = useTranslation(`issuers`);
  const { pathname } = useRouter();
  const active = pathname === href || (paths && paths.includes(pathname));

  return (
    <InternalLink
      href={href}
      textDecor="none !important"
      bg={active ? `grey.75` : `transparent`}
      w="full"
      textTransform="capitalize"
      onClick={onToggle}
    >
      <HStack
        justifyContent="flex-start"
        p={5}
        color="grey.900"
        fontWeight="500"
        fontSize={16}
      >
        <Box flexShrink={1}>{icon}</Box>
        <Box>{t(labelKey)}</Box>
      </HStack>
    </InternalLink>
  );
}

function IssuerDesktopNavContentLink({
  isOpen,
  labelKey,
  href,
  icon,
  paths,
}: IssuerDesktopSideBarContentLinkProps) {
  const { t } = useTranslation(`issuers`);

  return isOpen ? (
    <DesktopSideBarLink href={href} icon={icon} paths={paths}>
      <Text textStyle="heading-xs">{t(labelKey)}</Text>
    </DesktopSideBarLink>
  ) : (
    <DesktopSideBarCollapsedLink label={t(labelKey)} href={href} paths={paths}>
      {icon}
    </DesktopSideBarCollapsedLink>
  );
}

export function IssuerDesktopNavContent({
  content,
  isOpen,
}: IssuerDesktopNavContentProps) {
  return (
    <DesktopNavContent>
      {content.map((props) => {
        const { type } = props;

        return match(type)
          .with(IssuerNavContentType.Link, () => {
            const { type: _type, ...restProps } = props;

            return (
              <IssuerDesktopNavContentLink
                key={nanoid()}
                isOpen={isOpen}
                {...(restProps as IssuerNavContentLinkType)}
              />
            );
          })
          .with(IssuerNavContentType.Divider, () => (
            <DesktopSideBarDivider key={nanoid()} />
          ))
          .exhaustive();
      })}
    </DesktopNavContent>
  );
}

export function IssuerMobileNavContent({
  content,
  isOpen,
  onToggle,
}: IssuerNavContentProps) {
  return (
    <VStack alignItems="flex-start" gap={0} w="full">
      {content.map((props) => {
        const { type } = props;

        return match(type)
          .with(IssuerNavContentType.Link, () => {
            const { type: _type, ...restProps } = props;

            return (
              <IssuerMobileNavContentLink
                key={nanoid()}
                isOpen={isOpen}
                onToggle={onToggle}
                {...(restProps as IssuerNavContentLinkType)}
              />
            );
          })
          .with(IssuerNavContentType.Divider, () => (
            <MobileNavDivider key={nanoid()} />
          ))
          .exhaustive();
      })}
    </VStack>
  );
}
