import { TabProps } from "@/components/common";
import { CompaniesSearch } from "@/components/nav";

import { MobileNavBarWrapper } from "./MobileNavBarWrapper";
import { XButton } from "./XButton";
import { TabKeys } from "./tabs";

export const SearchTab = ({
  tabRouter: { tabControls },
}: TabProps<TabKeys>) => (
  <MobileNavBarWrapper>
    <XButton onClick={() => tabControls.jumpToTab(`index`)} />
    <CompaniesSearch />
  </MobileNavBarWrapper>
);
