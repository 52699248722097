import { useTranslation } from "react-i18next";

import { Box, Card, CardBody, Flex, Stack, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { PersonaSvg } from "@/components/svg";
import { useCurrentActor, useIdentityVerificationModal } from "@/hooks";

export const PersonaCard = ({
  onComplete,
  isLoading,
}: {
  readonly onComplete: () => void;
  readonly isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const actor = useCurrentActor();

  const { onOpen } = useIdentityVerificationModal({
    actor,
    onComplete,
  });

  return (
    <Card>
      <CardBody>
        <Flex
          direction={{ base: `column`, md: `row` }}
          justifyContent="space-between"
          alignItems="center"
          borderWidth={0.5}
          borderColor="grey.200"
          borderRadius="md"
          p={4}
          gap={4}
        >
          <Stack
            textAlign={{ base: `center`, md: `left` }}
            alignItems="center"
            direction={{ base: `column`, md: `row` }}
            spacing={4}
          >
            <Box>
              <PersonaSvg />
            </Box>
            <Flex direction="column" gap={1}>
              <Text textStyle="heading-md">
                {t(`hiive_uses_persona_to_verify_your_identity`)}
              </Text>
              <Text>{t(`this_process_will_take_about_2_minutes`)}</Text>
            </Flex>
          </Stack>

          <HiiveButton
            isLoading={isLoading}
            variant="rounded-solid-salmon"
            size="xl"
            sentryLabel="[VerifyIdentityPage] Verify Identity"
            onClick={onOpen}
          >
            {t(`verify_identity`)}
          </HiiveButton>
        </Flex>
      </CardBody>
    </Card>
  );
};
