import { PencilSimpleLine } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Badge,
  Button,
  Card,
  CardFooter,
  Center,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveButton,
  HiiveModalFooter,
} from "@/components/common";
import { FormTextInput } from "@/components/react-hook-form";
import {
  ExecutionAnvilFillDocumentTaskData,
  TransactionExecutionPageTaskFragment,
  ExecutionTaskStatus,
  useExecutionTaskActionMutation,
  ExecutionTaskActionType,
} from "@/gql";
import { useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { toFullDate } from "@/utils/datetime";

type InviteAlternateUserFormValues = {
  email: string;
  firstName: string;
  lastName: string;
};

function UninviteAlternateUserModal({
  isOpen,
  onClose,
  task,
}: {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly task: TransactionExecutionPageTaskFragment;
}) {
  const [uninviteAlternateuserMutation] = useMutationWithError(
    useExecutionTaskActionMutation({
      variables: {
        id: task.id,
        type: ExecutionTaskActionType.AnvilFillDocumentUninviteAlternateUser,
      },
      onCompleted: onClose,
    }),
    `executionTaskAction`,
  );

  const { t } = useTranslation(`execution`);

  const handleSubmit = () => {
    uninviteAlternateuserMutation();
  };

  return (
    <Modal variant="texas" isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent w="2xl">
        <ModalHeader>
          <Text textStyle="heading-md">
            {t(`remove_alternate_representative`)}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="start">
            <Text textStyle="heading-xl">
              {t(`are_you_sure_remove_alternate`)}
            </Text>
            <Text textStyle="text-md" color="grey.900">
              {t(`remove_alternate_modal_description`)}
            </Text>
          </VStack>
        </ModalBody>
        <HiiveModalFooter>
          <ButtonFooterWrapper
            direction={{
              base: `row`,
            }}
          >
            <HiiveButton
              onClick={handleSubmit}
              size="xl"
              sentryLabel="[UninviteAlternateUserModal] Remove"
              variant="rounded-outline-salmon"
            >
              {t(`remove`)}
            </HiiveButton>
          </ButtonFooterWrapper>
        </HiiveModalFooter>
      </ModalContent>
    </Modal>
  );
}

function InviteAlternateUserModal({
  isOpen,
  onClose,
  task,
}: {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly task: TransactionExecutionPageTaskFragment;
}) {
  const { t } = useTranslation(`execution`);
  const mapVariables =
    (task: TransactionExecutionPageTaskFragment) =>
    (values: InviteAlternateUserFormValues) => ({
      id: task.id,
      type: ExecutionTaskActionType.AnvilFillDocumentInviteAlternateUser,
      input: {
        anvilFillDocumentInviteAlternateUser: values,
      },
    });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
  });

  const mutation = useExecutionTaskActionMutation();

  const initialValues = {
    email: ``,
    firstName: ``,
    lastName: ``,
  };

  const { handleSubmit, control } = useFormQL({
    mutation,
    initialValues,
    validationSchema,
    mapVariables: mapVariables(task),
    onSuccess: onClose,
  });

  return (
    <Modal variant="texas" isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent w="2xl">
        <ModalHeader>
          <Text textStyle="heading-md">
            {t(`invite_an_alternate_representative`)}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="start">
            <VStack spacing={2} align="start">
              <Text textStyle="heading-xl">
                {t(`provide_alternate_representative`)}
              </Text>
              <Text textStyle="text-md" color="grey.900">
                {t(`alternate_representative_instructions`)}
              </Text>
            </VStack>
            <HStack spacing={4} w="full">
              <FormTextInput
                control={control}
                name="firstName"
                label={t(`first_name`)}
              />
              <FormTextInput
                control={control}
                name="lastName"
                label={t(`last_name`)}
              />
            </HStack>
            <FormTextInput control={control} name="email" label={t(`email`)} />
          </VStack>
        </ModalBody>
        <HiiveModalFooter>
          <ButtonFooterWrapper
            direction={{
              base: `row`,
            }}
          >
            <HiiveButton
              onClick={onClose}
              size="xl"
              sentryLabel="[InviteAlternateUserModal] Cancel"
              variant="rounded-outline-grey"
            >
              {t(`cancel`)}
            </HiiveButton>
            <HiiveButton
              onClick={handleSubmit}
              size="xl"
              sentryLabel="[InviteAlternateUserModal] Send Invites"
              variant="rounded-solid-grey"
            >
              {t(`send_invite`)}
            </HiiveButton>
          </ButtonFooterWrapper>
        </HiiveModalFooter>
      </ModalContent>
    </Modal>
  );
}

function AlternateUserModals({
  task,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
}) {
  const { t } = useTranslation(`execution`);
  const {
    isOpen: isInviteModalOpen,
    onClose: onInviteModalClose,
    onOpen: onInviteModalOpen,
  } = useDisclosure();

  const {
    isOpen: isUninviteOpen,
    onClose: onUninviteClose,
    onOpen: onUninviteOpen,
  } = useDisclosure();

  const hasAlternateUser = !!task.alternateUser;

  return (
    <HStack>
      {!hasAlternateUser ? (
        <>
          <Text color="grey.600">{t(`need_someone_else_to_do_this_task`)}</Text>
          <Button
            variant="link"
            size="sm"
            color="grey.900"
            onClick={onInviteModalOpen}
          >
            {t(`send_an_invite`)}
          </Button>
        </>
      ) : (
        <>
          <Text color="grey.600">{t(`want_to_complete_this_yourself`)}</Text>
          <Button
            variant="link"
            size="sm"
            color="red.900"
            onClick={onUninviteOpen}
          >
            {t(`cancel_invite`)}
          </Button>
        </>
      )}
      <InviteAlternateUserModal
        isOpen={isInviteModalOpen}
        onClose={onInviteModalClose}
        task={task}
      />
      <UninviteAlternateUserModal
        isOpen={isUninviteOpen}
        onClose={onUninviteClose}
        task={task}
      />
    </HStack>
  );
}

function AlternateUser({
  task,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
}) {
  const { t } = useTranslation(`execution`);

  if (!task?.alternateUser) {
    return null;
  }

  const { firstName, lastName, email } = task.alternateUser;

  return (
    <>
      <Divider w="full" py={1} />
      <VStack w="full" align="start" py={1}>
        <Text color="grey.700" textStyle="heading-md">
          {t(`you_invited_alternate`)}
        </Text>
        <HStack w="full">
          <Badge variant="rounded-light-grey">{t(`alternate`)}</Badge>
          <Text color="grey.900" textStyle="text-md">
            {firstName} {lastName} • {email}
          </Text>
        </HStack>
      </VStack>
    </>
  );
}

const AnvilFillDocumentTaskCard = ({
  task,
  data: _data,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionAnvilFillDocumentTaskData;
}) => {
  const [executionTaskActionMutation, isLoading] = useMutationWithError(
    useExecutionTaskActionMutation(),
    `executionTaskAction`,
  );

  const [embedUrl, setEmbedUrl] = useState(``);

  const { t } = useTranslation(`execution`);

  const handleOpen = async () => {
    const response = await executionTaskActionMutation({
      variables: {
        id: task.id,
        type: ExecutionTaskActionType.AnvilFillDocumentGenerateEmbedUrl,
      },
    });

    if (
      !response ||
      response.executionTaskAction.result?.__typename !==
        `ExecutionTaskActionAnvilFillDocumentGenerateEmbedUrl`
    )
      return;

    setEmbedUrl(response.executionTaskAction.result.url);
  };

  const handleClose = () => setEmbedUrl(``);

  return (
    <>
      <Card variant="flat" px={4} py={3} mb={6}>
        <HStack spacing={4} alignItems="start">
          <Center pt={2}>
            <PencilSimpleLine width="24px" height="24px" />
          </Center>
          <VStack w="full">
            <HStack alignItems="center" w="full" justifyContent="space-between">
              <VStack alignItems="start" spacing={0.5}>
                <Text textStyle="heading-md">
                  {t(`information_collection`)}
                </Text>
                <Text textStyle="text-xs" color="grey.700">
                  {t(`please_fill_information`)}
                </Text>
              </VStack>
              {task.status === ExecutionTaskStatus.InProgress && (
                <Badge variant="status-pending">
                  <Text textStyle="text-sm" lineHeight="unset" color="teal.900">
                    {t(`pending`)}
                  </Text>
                </Badge>
              )}
              {!!task.completedAt && (
                <Text>
                  {t(`completed`)}, {toFullDate(task.completedAt)}
                </Text>
              )}
            </HStack>
            <AlternateUser task={task} />
          </VStack>
        </HStack>
      </Card>
      {task.status === ExecutionTaskStatus.InProgress && (
        <CardFooter bg="grey.25" px={6} py={4} mx={-6} justifyContent="end">
          <HStack justifyContent="space-between" w="full">
            <AlternateUserModals task={task} />
            <Button
              isLoading={isLoading}
              variant="rounded-solid-grey"
              size="xl"
              onClick={handleOpen}
            >
              {t(`fill_document`)}
            </Button>
          </HStack>
        </CardFooter>
      )}
      <Modal variant="texas" isOpen={!!embedUrl} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent h="6xl" w="6xl">
          <ModalHeader>{t(`information_collection`)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0} px={0} overflow="hidden">
            <chakra.iframe src={embedUrl} w="full" h="full" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnvilFillDocumentTaskCard;
