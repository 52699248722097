import { useTranslation } from "react-i18next";

import {
  CardBody,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";

import { QuestionTooltip } from "@/components/form";
import { TileCheckboxInput } from "@/components/react-hook-form";
import { AccreditationQuestion } from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  USQIBQuestionKey as QIBQuestionKey,
  USOtherAccreditationCriteriaQuestionKey as OtherAccreditationCriteriaQuestionKey,
} from "@/utils/accreditation";

import {
  InstitutionAccreditationBodyProps,
  AccreditationValuesContainer,
} from ".";

const USInstitutionAccreditationBody = ({
  questionGroup,
  onModify,
}: InstitutionAccreditationBodyProps) => {
  const actor = useCurrentActor();
  const { t } = useTranslation();

  const accQuestions = questionGroup?.questions || [];

  const qibQuestion = accQuestions.find(
    (question: AccreditationQuestion) => question.key === QIBQuestionKey,
  );

  const accAnswers = actor.institution?.accreditationAnswers;
  const hasAccAnswers = !!accAnswers && accAnswers.length > 0;

  if (!hasAccAnswers) return null;
  const accAnswerQuestion = accAnswers[0].question;

  const isQIB = accAnswerQuestion.key === QIBQuestionKey;
  const isOther =
    accAnswerQuestion.key === OtherAccreditationCriteriaQuestionKey;

  return (
    <CardBody>
      <AccreditationValuesContainer onModify={onModify}>
        <VStack alignItems="flex-start" gap={6}>
          <Text textStyle="heading-lg">
            {qibQuestion?.text}
            {` `}
            <QuestionTooltip
              href="https://www.law.cornell.edu/cfr/text/17/230.144A"
              pl={1}
              translateY={1.5}
              transform="auto"
              tooltipContent={t(`qualified_institutional_buyer`)}
            />
          </Text>
          <TileCheckboxInput.Tooltip>
            <VStack alignItems="flex-start" p={2}>
              <Text>{t`us_institution_accreditation_qib_description`}:</Text>
              <UnorderedList>
                <ListItem>{t`us_institution_accreditation_qib_description_bullet_1`}</ListItem>
                <ListItem>{t`us_institution_accreditation_qib_description_bullet_2`}</ListItem>
                <ListItem>{t`us_institution_accreditation_qib_description_bullet_3`}</ListItem>
                <ListItem>{t`us_institution_accreditation_qib_description_bullet_4`}</ListItem>
              </UnorderedList>
            </VStack>
          </TileCheckboxInput.Tooltip>
          <HStack alignItems="flex-start">
            <Text textStyle="heading-md">{t`answer`}:</Text>
            <Text>
              {isQIB
                ? t`yes`
                : t(`us_not_qib_other_accreditation_reason`, {
                    reason: isOther
                      ? accAnswers[0].text
                      : accAnswerQuestion.text,
                  })}
            </Text>
          </HStack>
        </VStack>
      </AccreditationValuesContainer>
    </CardBody>
  );
};

export default USInstitutionAccreditationBody;
