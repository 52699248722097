import { useLoginActions } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { FRONTEGG_LOGIN_DELAY_MS } from "@/components/frontegg";
import { Session } from "@/gql";
import { setAuthFromSession } from "@/state/auth";
import { buildSessionPayload } from "@/utils";

import { useMarketplaceLogin } from "./featureFlags";

export function useDelayedLogin() {
  const [isLoginPending, setIsLoginPending] = useState(false);
  const dispatch = useDispatch();
  const { login: fronteggLogin } = useLoginActions();
  const marketplaceLoginEnabled = useMarketplaceLogin();

  const handleSession = (session: Session) => {
    const sessionPayload = buildSessionPayload(session);
    dispatch(setAuthFromSession(sessionPayload));
  };

  const login = (email: string, password: string, session: Session) => {
    if (marketplaceLoginEnabled) {
      setIsLoginPending(true);
      setTimeout(() => {
        fronteggLogin({
          email,
          password,
          callback: (success, error) => {
            setIsLoginPending(false);
            if (success) {
              return;
            }
            Sentry.captureException(`Frontegg login failed: ${error}`);
            // If frontegg login fails, as a backup we perform a "legacy" login
            handleSession(session);
          },
        });
      }, FRONTEGG_LOGIN_DELAY_MS);
    } else {
      handleSession(session);
    }
  };

  return { isLoginPending, login };
}
