import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";

import { Hiive50Logo } from "@/components/common";
import {
  Hiive50IndexPriceDateRange,
  useHiiveFiftyIndexPricesQuery,
} from "@/gql";
import { formatSimulatedTimestamp } from "@/utils";

import { H50Chart } from "./H50Chart";
import { IndexStat } from "./IndexStat";
import { Pulse } from "./Pulse";
import { RangeSelector } from "./RangeSelector";

export const ChartCard = () => {
  const { t } = useTranslation();

  const [range, setRange] = useState(Hiive50IndexPriceDateRange.Max);

  const { data: response, loading } = useHiiveFiftyIndexPricesQuery({
    variables: {
      filterBy: {
        range,
      },
    },
  });

  const chartData = response?.hiiveFiftyIndexPrices?.prices || [];

  const { currentPrice, percentageChange } =
    response?.hiiveFiftyIndexPrices || {
      currentPrice: 0,
      percentageChange: 0,
    };

  const simulatedTimestamp = formatSimulatedTimestamp();

  return (
    <Card w="full">
      <CardBody>
        <VStack alignItems="start" spacing={6}>
          <VStack alignItems="start">
            <Wrap spacingX={3}>
              <Box width={{ base: `133px`, sm: `200px` }}>
                <Hiive50Logo />
              </Box>
              <HStack spacing={1}>
                <Pulse top={{ base: `2px`, sm: `6px` }} />
                <Text
                  textStyle="heading-3xs"
                  color="salmon.900"
                  lineHeight="unset"
                  position="relative"
                  top={{ base: `3px`, sm: `7px` }}
                >
                  {t(`updated`)} {simulatedTimestamp}
                </Text>
              </HStack>
            </Wrap>
            <Box w="full" maxW="784" h="full">
              <Text textStyle="text-sm">{t(`hiive_50_index_description`)}</Text>
            </Box>
          </VStack>
          <Flex
            direction={{ base: `column`, sm: `row` }}
            justifyContent="space-between"
            alignItems={{ base: `start`, sm: `end` }}
            w="full"
            gap={2}
          >
            <IndexStat
              loading={loading}
              currentPrice={currentPrice}
              percentageChange={percentageChange}
              range={range}
            />
            <Box>
              <RangeSelector selected={range} onChange={setRange} />
            </Box>
          </Flex>
          <Box h="96" w="full">
            <H50Chart range={range} data={chartData} loading={loading} />
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
};
