import { defineStyleConfig } from "@chakra-ui/react";

export const Progress = defineStyleConfig({
  baseStyle: {
    filledTrack: {
      bg: `teal.900`,
      borderRadius: `full`,
    },
    track: {
      bg: `grey.100`,
      borderRadius: `full`,
    },
  },
});
