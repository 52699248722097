import i18next from "i18next";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ModalBody, Text } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormTextInput } from "@/components/react-hook-form";
import { useUpdateJobTitleMutation } from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { ToastDuration } from "@/types/toast";
import { constants } from "@/utils";

const { JOB_TITLE_MAX_LEN } = constants;

const getValidationSchema = (t: typeof i18next.t) =>
  Yup.object().shape({
    jobTitle: Yup.string()
      .nullable()
      .required(t(`job_title_is_required`))
      .max(
        JOB_TITLE_MAX_LEN,
        t(`job_title_max_length`, { max: JOB_TITLE_MAX_LEN }),
      ),
  });

interface ChangeJobTitleFormValues {
  readonly jobTitle: string;
}

const createInitialValues = (jobTitle: string) => ({
  jobTitle,
});

const mapVariables = (values: ChangeJobTitleFormValues) => ({
  jobTitle: values.jobTitle,
});

interface ChangeJobTitleModalProps {
  readonly oldJobTitle: string;
}

const ChangeJobTitleModal = ({ oldJobTitle }: ChangeJobTitleModalProps) => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useCustomToast();
  const mutation = useUpdateJobTitleMutation();
  const { closeModal } = useModal();

  const initialValues = createInitialValues(oldJobTitle);

  const validationSchema = getValidationSchema(t);

  const onSuccess = () => {
    closeModal();
    successToast(t(`job_title_change_success_message`), {
      duration: ToastDuration.Medium,
    });
  };

  const onError = () => {
    errorToast(t(`job_title_change_error_message`), {
      duration: ToastDuration.Medium,
    });
  };

  const { handleSubmit, isLoading, control, watch } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
    onError,
  });

  const jobTitleValue = watch(`jobTitle`);

  return (
    <HiiveModalContentWrapper>
      <form onSubmit={handleSubmit}>
        <HiiveModalHeader>
          <Text>{t(`update_job_title`)}</Text>
        </HiiveModalHeader>
        <ModalBody>
          <FormTextInput
            bg="h-white"
            control={control}
            label={t(`new_job_title`)}
            name="jobTitle"
          />
        </ModalBody>
        <HiiveModalFooter>
          <ButtonFooterWrapper>
            <HiiveCancelButton
              onCancel={closeModal}
              sentryLabel="[ChangeJobTitle/Cancel]"
            />
            <HiiveSubmitButton
              isDisabled={oldJobTitle === jobTitleValue}
              isLoading={isLoading}
              sentryLabel="[ChangeJobTitle/Submit]"
              submitText={t(`update`)}
              type="submit"
            />
          </ButtonFooterWrapper>
        </HiiveModalFooter>
      </form>
    </HiiveModalContentWrapper>
  );
};

export default ChangeJobTitleModal;
