import { ReactNode } from "react";

import { Box } from "@chakra-ui/react";

import Meta from "@/components/Meta";

const Layout = ({
  children,
  title,
}: {
  readonly children: ReactNode | JSX.Element | readonly JSX.Element[];
  readonly title?: string;
}) => (
  <>
    <Meta title={title} shouldTrackPageEvent />
    <Box>{children}</Box>
  </>
);

export default Layout;
