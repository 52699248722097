import currency from "currency.js";
import { Trans, useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Card, CardBody, CardFooter, CardHeader, Text } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveCancelButton,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import {
  ListingPageBuyerActivityPlaceBidCardPlaceBidListingFragment,
  PlaceBidCardPlaceBidMutation,
} from "@/gql";
import { useModal } from "@/hooks";
import { formatShares, countLabel, bidLabel } from "@/utils";

import { usePlaceBidCardStepFormContext } from "./PlaceBidCardStepFormContext";
import { StepKeys } from "./steps";
import { PlaceBidCardFormValues } from "./types";

interface PlaceBidConfirmationProps
  extends StepPropsV2<StepKeys, PlaceBidCardFormValues> {
  readonly listing: ListingPageBuyerActivityPlaceBidCardPlaceBidListingFragment;
}

const PlaceBidConfirmation = ({
  listing,
  stepRouter: { stepControls },
  isSubmitting,
  values,
}: PlaceBidConfirmationProps) => {
  const { submitMutation } = usePlaceBidCardStepFormContext();
  const { t } = useTranslation();

  const { onOpenModal, modals } = useModal();

  const router = useRouter();

  const onClickSubmit = () =>
    submitMutation().then((response: PlaceBidCardPlaceBidMutation) => {
      const newBid = response.placeBid.bid;
      if (!newBid?.id) return;

      onOpenModal(modals.placeBidSuccess(newBid))();
      router.push(`/listings/bids/${newBid.id}`);
    });

  const { numShares, pricePerShare } = values;
  // return type of pricePerShare is string | number
  const parsedPPS = pricePerShare ? parseFloat(pricePerShare.toString()) : 0;
  const total = currency(numShares * parsedPPS).format();
  const value = currency(parsedPPS).format();
  const shares = formatShares(numShares);
  const { company } = listing;

  const unitType = countLabel(listing);
  const orderType = bidLabel(listing).toLowerCase();

  return (
    <Card variant="darkened-footer" w="full">
      <CardHeader py={4}>
        <Text textStyle="heading-2xl">{t(`confirm`)}</Text>
      </CardHeader>
      <CardBody>
        <Text textStyle="heading-lg">
          <Trans
            i18nKey="place_bid_confirmation"
            values={{
              shares,
              total,
              companyName: company.name,
              value,
              unitType,
              orderType,
            }}
          />
        </Text>
      </CardBody>
      <CardFooter py={6}>
        <ButtonFooterWrapper>
          <HiiveCancelButton
            sentryLabel="[PlaceBidConfirmation/Cancel]"
            onCancel={stepControls.previousStep}
          />
          <HiiveSubmitButton
            sentryLabel="[PlaceBidConfirmation/Submit]"
            onClick={onClickSubmit}
            submitText={t(`confirm`)}
            isLoading={isSubmitting}
          />
        </ButtonFooterWrapper>
      </CardFooter>
    </Card>
  );
};

export default PlaceBidConfirmation;
