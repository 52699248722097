import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useFronteggMutation } from "./useFronteggMutation";

interface VerifyMfaRequestPayload {
  readonly code: string;
  readonly otcToken: string;
}

interface VerifyMfaResponse {
  readonly errors: string[];
  readonly recoveryCode?: string;
}

const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/sms/enroll/verify`;

const useVerifySmsMfa = ({
  onSuccess,
}: {
  onSuccess?: (data: VerifyMfaResponse) => void;
}) => {
  const { t } = useTranslation();

  const { state, execute } = useFronteggMutation<
    VerifyMfaRequestPayload,
    VerifyMfaResponse
  >();

  const verifyMfa = useCallback(
    async (body: VerifyMfaRequestPayload) =>
      execute(API_URL, {
        body,
        fallbackErrorMessage: t(`incorrect_code`),
        onSuccess,
      }),
    [execute],
  );

  return [verifyMfa, state] as const;
};

export default useVerifySmsMfa;
