import { ReactNode } from "react";

import { IssuerNavLayout } from "@/components/nav";
import {
  IssuerCurrentActorProvider,
  ModalProvider,
} from "@/components/providers";

import Layout from "./Layout";

interface IssuerPortalLayoutProps {
  readonly children: ReactNode;
  readonly title?: string;
}

export const IssuerPortalLayout = ({
  children,
  title,
}: IssuerPortalLayoutProps) => (
  <IssuerCurrentActorProvider>
    <IssuerNavLayout
      content={
        <Layout title={title}>
          <ModalProvider>{children}</ModalProvider>
        </Layout>
      }
    />
  </IssuerCurrentActorProvider>
);
