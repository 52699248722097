import { CopySimple } from "@phosphor-icons/react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { useRouter } from "next/router";

import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  ModalBody,
  Text,
  useClipboard,
  VStack,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { RELOAD_DELAY_MS } from "@/components/frontegg";
import { useCustomToast } from "@/hooks";
import { MfaToastKeys, VALID_TOAST_FLAG } from "@/hooks/frontegg";
import { constants } from "@/utils";

export enum EnrollAppMfaSuccessAction {
  Redirect,
  Reload,
}

const EnrollAppMfaSuccessModal = ({
  recoveryCode,
  successAction,
}: {
  readonly recoveryCode?: string;
  readonly successAction?: EnrollAppMfaSuccessAction;
}) => {
  const { t } = useTranslation();
  const { reload, push } = useRouter();
  const { onCopy } = useClipboard(recoveryCode || ``);
  const { successToast } = useCustomToast();
  const [isReloadPending, setIsReloadPending] = useState(false);

  const handleCopy = () => {
    onCopy();
    successToast(t(`copied_to_clipboard`));
  };

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`mfa_setup_complete`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          {recoveryCode ? (
            <>
              <Text>
                <Trans i18nKey="mfa_setup_complete_description_with_recovery_code_1" />
              </Text>
              <Text>
                <Trans i18nKey="mfa_setup_complete_description_with_recovery_code_2" />
              </Text>
              <Text>
                <Trans
                  i18nKey="mfa_setup_complete_description_with_recovery_code_3"
                  components={{ bold: <strong /> }}
                />
              </Text>
              <Text>
                <Trans i18nKey="mfa_setup_complete_description_with_recovery_code_4" />
              </Text>
            </>
          ) : (
            <>
              <Text>
                <Trans
                  i18nKey="mfa_setup_complete_description_without_recovery_code_1"
                  values={{ method: constants.MFA_METHODS.APP }}
                />
              </Text>
              <Text>
                <Trans i18nKey="mfa_setup_complete_description_without_recovery_code_2" />
              </Text>
            </>
          )}
          {recoveryCode && (
            <VStack alignItems="flex-start" width="full">
              <Text>
                <Trans
                  i18nKey="recovery_code_bold"
                  components={{ bold: <strong /> }}
                />
              </Text>
              <InputGroup>
                <Input isReadOnly value={recoveryCode} />
                <InputRightElement>
                  <Box
                    alignItems="center"
                    backgroundColor="grey.50"
                    borderRadius="100%"
                    cursor="pointer"
                    display="flex"
                    height="1.625rem"
                    justifyContent="center"
                    width="1.625rem"
                    flexShrink={0}
                    onClick={handleCopy}
                    _hover={{ backgroundColor: `grey.100` }}
                  >
                    <CopySimple size={14} />
                  </Box>
                </InputRightElement>
              </InputGroup>
            </VStack>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            sentryLabel="[EnrollAppMfaSuccess/Submit]"
            submitText={t(`done`)}
            isLoading={isReloadPending}
            onClick={() => {
              setIsReloadPending(true);
              sessionStorage.setItem(
                MfaToastKeys.showAppMfaEnrolledToast,
                VALID_TOAST_FLAG,
              );
              match(successAction)
                .with(EnrollAppMfaSuccessAction.Redirect, () =>
                  setTimeout(() => push(`/dashboard`), RELOAD_DELAY_MS),
                )
                .otherwise(() => setTimeout(() => reload(), RELOAD_DELAY_MS));
            }}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default EnrollAppMfaSuccessModal;
