import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Flex,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  Card,
  CardBody,
  Checkbox,
  HStack,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { TooltipFormLabel } from "@/components/form";
// eslint-disable-next-line no-restricted-imports
import styles from "@/components/form/CheckboxInput.module.css";
import {
  OnboardingContainerV2,
  SlideAnimation,
} from "@/components/onboarding-v2";
import { useTransitionCurrentStepMutation } from "@/gql";
import { useMutationWithError } from "@/hooks";

const IAgree = ({
  value,
  isDisabled,
  onClick,
}: {
  readonly value: boolean;
  readonly isDisabled: boolean;
  readonly onClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <HStack alignItems="flex-start" justifyContent="flex-start" gap={2}>
      <Checkbox
        size="lg"
        className={`${styles.checkbox} ${styles.checkboxValid}`}
        alignItems="flex-start"
        colorScheme="white"
        iconColor="h-dark-grey"
        isChecked={value}
        _focus={{
          boxShadow: `focus`,
        }}
        onChange={onClick}
        id="i_agree_checkbox"
        isDisabled={isDisabled}
      />
      <TooltipFormLabel htmlFor="i_agree_checkbox" pb={0} cursor="pointer">
        <Text fontWeight={400}>{t`i_agree`}</Text>
      </TooltipFormLabel>
    </HStack>
  );
};

export const AcknowledgmentAndConsentPage = () => {
  const { t } = useTranslation();
  const [hasAgreed, setHasAgreed] = useState<boolean>(false);
  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const onNext = async () => {
    await transitionCurrentStepMutation();
  };

  return (
    <OnboardingContainerV2 canGoBack metaTitle={t`acknowledgment_and_consent`}>
      <Flex direction="column" w={{ base: `full`, lg: 160 }}>
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`acknowledgment_and_consent`)} *
          </Text>
        </VStack>
        <SlideAnimation>
          <Card>
            <CardBody>
              <form>
                <VStack alignItems="flex-start" gap={6}>
                  <Text fontWeight={500}>{t`by_selecting_agree_below`}</Text>
                  <UnorderedList>
                    <ListItem>{t`acknowledgment_and_consent_list_item_1`}</ListItem>
                    <ListItem>{t`acknowledgment_and_consent_list_item_2`}</ListItem>
                    <ListItem>{t`acknowledgment_and_consent_list_item_3`}</ListItem>
                    <ListItem>{t`acknowledgment_and_consent_list_item_4`}</ListItem>
                  </UnorderedList>
                  <IAgree
                    value={hasAgreed}
                    isDisabled={isTransitioningCurrentStep}
                    onClick={() => setHasAgreed(!hasAgreed)}
                  />
                </VStack>
              </form>
            </CardBody>
          </Card>
        </SlideAnimation>
        <HStack p={2} mt={6} justifyContent="flex-end" w="full">
          <HiiveButton
            sentryLabel="[AcknowledgmentAndConsentPageV2/Submit]"
            variant="rounded-solid-salmon"
            size="xl"
            maxW="unset"
            w={{ base: `full`, lg: `unset` }}
            isLoading={isTransitioningCurrentStep}
            onClick={onNext}
            isDisabled={!hasAgreed}
          >
            {t(`next`)}
          </HiiveButton>
        </HStack>
      </Flex>
    </OnboardingContainerV2>
  );
};
