import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Center } from "@chakra-ui/react";

import {
  Paginator,
  PaginatorButton,
  PaginatorLeft,
  PaginatorLegend,
  PaginatorRight,
} from "@/components/nav";

import { IssuerTransactionsTableContext } from "./IssuerTransactionsProvider";
import { DEFAULT_FETCH_LIMIT } from "./useQueryVariables";

export const IssuerTransactionsTablePaginator = () => {
  const { t } = useTranslation(`issuers`);
  const {
    onFirstPage,
    onPreviousPage,
    onNextPage,
    page,
    pageInfo,
    totalCount,
  } = useContext(IssuerTransactionsTableContext);

  const renderPaginationText = () => {
    const start = (page - 1) * DEFAULT_FETCH_LIMIT + 1;
    const end = Math.min(page * DEFAULT_FETCH_LIMIT, totalCount);

    return t(`pagination_text`, {
      start,
      end,
      totalCount,
    });
  };

  return (
    <Center w="full">
      <Paginator>
        <PaginatorLegend>{renderPaginationText()}</PaginatorLegend>
        <PaginatorButton isDisabled={page === 1} onClick={onFirstPage}>
          {t(`first`)}
        </PaginatorButton>
        <PaginatorLeft
          isDisabled={!pageInfo?.hasPreviousPage}
          onClick={onPreviousPage}
        />
        <PaginatorRight
          isDisabled={!pageInfo?.hasNextPage}
          onClick={onNextPage}
        />
      </Paginator>
    </Center>
  );
};
