import { useTranslation } from "react-i18next";

import {
  IssuerPageHeader,
  IssuerPortalPageContainer,
} from "@/components/issuers";
import { useIsDesktop } from "@/hooks";

import { IssuerTransactionsCardList } from "./IssuerTransactionsCardList";
import { IssuerTransactionsProvider } from "./IssuerTransactionsProvider";
import { IssuerTransactionsStageTabs } from "./IssuerTransactionsStageTabs";
import { IssuerTransactionsSummary } from "./IssuerTransactionsSummary";
import { IssuerTransactionsTable } from "./IssuerTransactionsTable";

export const IssuerTransactionsPage = () => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  return (
    <IssuerTransactionsProvider>
      <IssuerPortalPageContainer
        header={
          <>
            <IssuerPageHeader title={t(`transactions`)} />
            <IssuerTransactionsSummary />
            <IssuerTransactionsStageTabs />
          </>
        }
        content={
          isDesktop ? (
            <IssuerTransactionsTable />
          ) : (
            <IssuerTransactionsCardList />
          )
        }
      />
    </IssuerTransactionsProvider>
  );
};
