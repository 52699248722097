import { FormikValues } from "formik";
import isNil from "lodash/fp/isNil";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Radio, Text, HStack, VStack } from "@chakra-ui/react";

import { FormRadioInput, QuestionTooltip, RadioCard } from "@/components/form";
import {
  AccreditationAnswerInput,
  useAccreditationQuestionGroupsQuery,
  InvestorStatus,
} from "@/gql";

type AccreditationUSModifyFormQuestion = {
  readonly text: string;
  readonly id: string;
  readonly order: number;
};

const AccreditationInstitutionUSModifyFields = ({
  answer: currentAnswer,
  setValues,
  countryId,
  version = 2,
}: {
  readonly countryId: string;
  readonly version?: number;
  readonly answer: string;
  readonly setValues: (
    values: SetStateAction<FormikValues>,
    shouldValidate?: boolean | undefined,
  ) => void;
}) => {
  const { t } = useTranslation();

  const [qib, setQib] = useState<boolean>();

  const [initialCurrentAnswer] = useState(currentAnswer);

  const [questions, setQuestions] =
    useState<readonly AccreditationUSModifyFormQuestion[]>();
  const [qibQuestion, setQibQuestion] =
    useState<AccreditationUSModifyFormQuestion>();

  const questionsResponse = useAccreditationQuestionGroupsQuery({
    variables: {
      version,
      investorType: InvestorStatus.Institutional,
      countryId,
    },
  });

  useEffect(() => {
    if (
      questionsResponse.data &&
      questionsResponse.data.accreditationQuestionGroups
    ) {
      const [group] = questionsResponse.data.accreditationQuestionGroups;

      const filteredQuestions = group.questions.filter(
        (question): question is AccreditationUSModifyFormQuestion => !!question,
      );

      const [qibQ] = filteredQuestions.filter(({ order }) => order === 1);

      setQuestions(
        filteredQuestions
          .filter(({ order }) => order !== 1)
          .sort(({ order: orderA }, { order: orderB }) => orderA - orderB),
      );
      setQibQuestion(qibQ);
      setQib(qibQ.id === currentAnswer);
    }
  }, [questionsResponse]);

  useEffect(() => {
    if (qib && qibQuestion) {
      const { id: qibQuestionId } = qibQuestion;
      setValues({
        answer: qibQuestionId,
      });
    }

    if (!qib && currentAnswer !== initialCurrentAnswer && questions?.[0])
      setValues({
        answer: questions?.[0].id,
      });
  }, [qib, questions, initialCurrentAnswer, setValues]);

  return (
    <FormRadioInput<readonly AccreditationAnswerInput[]> name="answer">
      <Text textStyle="heading-lg">
        {t(`is_qualified_qib`)}
        <QuestionTooltip
          href="https://www.law.cornell.edu/cfr/text/17/230.144A"
          pl={1}
          translateY={1.5}
          transform="auto"
          tooltipContent={t(`qualified_institutional_buyer`)}
        />
      </Text>
      <HStack
        w={500}
        mt={4}
        opacity={isNil(qib) ? 0.2 : 1}
        pointerEvents={isNil(qib) ? `none` : `all`}
      >
        <RadioCard
          w={234}
          h="60px"
          isChecked={qib ?? false}
          onClick={() => setQib(true)}
        >
          {t(`yes`)}
        </RadioCard>
        <RadioCard
          w={234}
          h="60px"
          isChecked={!(qib ?? true)}
          onClick={() => setQib(false)}
        >
          {t(`no`)}
        </RadioCard>
      </HStack>

      {!qib && (
        <VStack alignItems="flex-start" gap={4} mt={10}>
          <Text textStyle="heading-lg">
            {t(`firm_fund_must_be_accredited_and_how`)}
          </Text>
          {questions?.slice(0, -2).map(({ text, id }) => (
            <Radio key={id} variant="base" value={id}>
              {text}
            </Radio>
          ))}
        </VStack>
      )}
    </FormRadioInput>
  );
};

export default AccreditationInstitutionUSModifyFields;
