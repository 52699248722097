import { TFunction } from "i18next";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Flex,
  FormControl,
  FormErrorMessage,
  GridItem,
  Text,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import {
  FormCountryCombobox,
  FormRadioTile,
} from "@/components/react-hook-form";
import {
  UserWithInstitutionFragment,
  useBrokerResidencePageUpdateBrokerResidenceMutation,
  useTransitionCurrentStepMutation,
} from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

interface BrokerResidenceFormValues {
  readonly countryId: string | null;
  readonly finraRegistered: boolean | null;
}

const createValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    countryId: Yup.string()
      .min(1)
      .nullable()
      .required(
        t(`validation_required`, {
          field: t(`country`),
        }),
      ),
    finraRegistered: Yup.boolean().nullable().required(t(`required`)),
  });

const getInitialValues = (actor: UserWithInstitutionFragment) => ({
  countryId: actor.country?.id || null,
  finraRegistered: actor.finraRegistered,
});

const mapVariables = ({
  countryId,
  finraRegistered,
}: BrokerResidenceFormValues) => ({
  input: { countryId, finraRegistered },
});

export const BrokerResidenceForm = () => {
  const actor = useCurrentActor();
  const mutation = useBrokerResidencePageUpdateBrokerResidenceMutation();
  const { t } = useTranslation();

  const initialValues = getInitialValues(actor);
  const validationSchema = createValidationSchema(t);

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const onSuccess = async () => {
    await transitionCurrentStepMutation();
  };

  const { control, handleSubmit, isLoading, setValue } = useFormQL({
    initialValues,
    mapVariables,
    mutation,
    onSuccess,
    validationSchema,
  });

  const {
    fieldState: { error },
  } = useController({
    name: `finraRegistered`,
    control,
  });

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Flex direction="column" gap={8}>
        <SlideAnimation>
          <Flex
            direction="column"
            gap={14}
            bgColor="white"
            borderRadius="md"
            borderColor="grey.200"
            borderWidth={0.5}
            boxShadow="card"
            px={7}
            py={8}
          >
            <FormCountryCombobox
              control={control}
              disabled={isLoading}
              label={t(`where_are_you_located`)}
              name="countryId"
              placeholder={t(`entities.search_country`, {
                ns: `account`,
              })}
              showSearchIcon
              showClearIcon
              onClear={() => setValue(`countryId`, null)}
            />
            <FormControl isInvalid={!!error?.message}>
              <Flex direction="column" gap={3}>
                <Text textStyle="heading-md">
                  {t(`are_you_registered_with_finra`)}
                </Text>
                <Text
                  textStyle="text-sm"
                  borderRadius="md"
                  borderColor="grey.800"
                  bg="grey.50"
                  px={3}
                  py={2}
                >
                  {t(`finra_registration_is_required`)}
                </Text>
                <FormRadioTile.Group
                  display="grid"
                  gap={4}
                  gridTemplateColumns={{ base: `1fr`, md: `repeat(3, 1fr)` }}
                  size="md"
                >
                  <FormRadioTile.Tile
                    name="finraRegistered"
                    control={control}
                    value
                    alignItems="center"
                  >
                    <Text>{t(`yes`)}</Text>
                  </FormRadioTile.Tile>
                  <FormRadioTile.Tile
                    name="finraRegistered"
                    control={control}
                    value={false}
                    alignItems="center"
                  >
                    <Text>{t(`no`)}</Text>
                  </FormRadioTile.Tile>

                  <FormErrorMessage
                    as={GridItem}
                    colSpan={{ base: 1, md: 3 }}
                    m={0}
                  >
                    {error?.message}
                  </FormErrorMessage>
                </FormRadioTile.Group>
              </Flex>
            </FormControl>
          </Flex>
        </SlideAnimation>
        <Flex w="full" justifyContent="flex-end">
          <HiiveButton
            isLoading={isLoading || isTransitioningCurrentStep}
            sentryLabel="[BrokerResidencePage] Next"
            size="xl"
            type="submit"
            variant="rounded-solid-salmon"
          >
            {t(`next`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </form>
  );
};
