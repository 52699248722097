import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";

import {
  InputProps,
  NumberInput as ChakraNumberInput,
  NumberInputField,
} from "@chakra-ui/react";

const validationRegex = /^[0-9+\-.]$/;
const nonNegativeValidationRegex = /^[0-9+.]$/;

type NumberInputProps<TFieldValues extends FieldValues> = InputProps &
  ControllerRenderProps<TFieldValues, Path<TFieldValues>> & {
    readonly defaultValue?: string | number;
    readonly step?: number;
    readonly min?: number;
    readonly max?: number;
    readonly allowNegativeNumbers?: boolean;
  };

export const NumberInput = <TFieldValues extends FieldValues>({
  allowNegativeNumbers,
  ...numberInputProps
}: NumberInputProps<TFieldValues>) => (
  <ChakraNumberInput
    {...numberInputProps}
    onInvalid={undefined}
    inputMode="numeric"
    isValidCharacter={(value: string) =>
      allowNegativeNumbers
        ? validationRegex.test(value)
        : nonNegativeValidationRegex.test(value)
    }
  >
    <NumberInputField placeholder={numberInputProps.placeholder} />
  </ChakraNumberInput>
);
