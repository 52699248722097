import { XCircle, Warning, WarningCircle } from "@phosphor-icons/react";
import { match } from "ts-pattern";

import { Box } from "@chakra-ui/react";

import { ExecutionWorkflowCondition } from "@/gql";
import { useColors } from "@/hooks";

export default function WorkflowConditionStatusIcon({
  condition,
  isBuySide = false,
  background = `white`,
}: {
  condition: ExecutionWorkflowCondition;
  isBuySide?: boolean;
  background?: string;
}) {
  const [red700, yellow600] = useColors([`red.700`, `yellow.600`]);

  return match({ isBuySide, condition })
    .with({ condition: ExecutionWorkflowCondition.Cancelled }, () => (
      <Box background={background} borderRadius="50%">
        <WarningCircle
          width="100%"
          height="100%"
          color={red700}
          weight="fill"
        />
      </Box>
    ))
    .with(
      { condition: ExecutionWorkflowCondition.IssuerApprovalDeclined },
      () => (
        <Box background={background} borderRadius="50%">
          <XCircle width="100%" height="100%" color={red700} weight="fill" />
        </Box>
      ),
    )
    .with(
      { isBuySide: true, condition: ExecutionWorkflowCondition.Rofr },
      () => (
        <Box background={background} borderRadius="50%">
          <WarningCircle
            width="100%"
            height="100%"
            color={red700}
            weight="fill"
          />
        </Box>
      ),
    )
    .with(
      { isBuySide: false, condition: ExecutionWorkflowCondition.Rofr },
      () => (
        <Box background={background} borderRadius="50%">
          <Warning width="100%" height="100%" color={yellow600} weight="fill" />
        </Box>
      ),
    )
    .otherwise(() => null);
}
