import { useCallback } from "react";

import { PageInfo } from "@/gql";

import {
  DEFAULT_FETCH_LIMIT,
  IssuerTransactionsQueryVariables,
} from "./useQueryVariables";

interface UsePaginationProps {
  queryVariables: IssuerTransactionsQueryVariables;
  setQueryVariables: (variables: IssuerTransactionsQueryVariables) => void;
  pageInfo: PageInfo;
  setPage: (page: number) => void;
  page: number;
}

export const usePagination = ({
  queryVariables,
  setQueryVariables,
  pageInfo,
  setPage,
  page,
}: UsePaginationProps) => {
  const onFirstPage = useCallback(() => {
    setQueryVariables({
      ...queryVariables,
      first: DEFAULT_FETCH_LIMIT,
      last: undefined,
      after: undefined,
      before: undefined,
    });

    setPage(1);
  }, [queryVariables, setQueryVariables, setPage]);

  const onPreviousPage = useCallback(() => {
    const { startCursor } = pageInfo;

    setQueryVariables({
      ...queryVariables,
      first: undefined,
      last: DEFAULT_FETCH_LIMIT,
      after: undefined,
      before: startCursor,
    });

    setPage(page - 1);
  }, [pageInfo, queryVariables, setQueryVariables, setPage, page]);

  const onNextPage = useCallback(() => {
    const { endCursor } = pageInfo;

    setQueryVariables({
      ...queryVariables,
      first: DEFAULT_FETCH_LIMIT,
      last: undefined,
      before: undefined,
      after: endCursor,
    });

    setPage(page + 1);
  }, [pageInfo, queryVariables, setQueryVariables, setPage, page]);

  return {
    onFirstPage,
    onPreviousPage,
    onNextPage,
  };
};
