export default {
  capital_raised: `Capital raised`,
  company_info: `Company Info`,
  date: `Date`,
  download: `Download`,
  general_details: `General Details`,
  industries: `Industries`,
  last_round_details: `Last round details`,
  last_round_details_description_preqin: `Details are obtained from Preqin and a variety of public and private
  sources, and are not verified as being accurate or up to date by Hiive.`,
  na: `N/A`,
  notable_investors: `Notable Investors`,
  preferences: `Preferences`,
  profile: `Profile`,
  requestChange: `Request a change`,
  round: `Round`,
  templates: `Templates`,
  total_capital_raised: `Total Capital Raised`,
  uploaded: `Uploaded`,
  value: `Value`,
  last_round_value: `Last Round Value`,
  view_company_description_and_preferences: `View your company's description and preferences`,
  manage_users: `Manage users`,
  name: `Name`,
  job_title: `Job title`,
  email: `Email`,
  permissions: `Permissions`,
  added_on: `Added on`,
  status: `Status`,
  admin: `Admin`,
  viewer: `View only`,
  active: `Active`,
  pending: `Pending`,
  deactivated: `Deactivated`,
  invite_expired: `Invite expired`,
  no_preferences_1: `You currently don't have any preferences.`,
  no_preferences_2: `If you'd like to add some (ex. document templates), please "Request a change" to get in contact with us.`,
  cancel_invitation: `Cancel invitation`,
  cancel_invitation_question: `Cancel invitation?`,
  remove_user_confirmation: `Are you sure you’d like to remove {{name}} from this portal? This means they can no longer login to this company portal.`,
  remove_user_warning: `Are you sure you’d like to remove yourself from this portal? If your organization would like to access Hiive, you will need to reach out again to be invited to the platform.`,
  reassign_admin_description: `Hiive requires at least one administrator per company. Please select below who should have admin permissions.`,
  cancel_invitation_confirmation: `Are you sure you’d like to remove {{email}} from this portal? This means they can no longer login to this company portal.`,
  confirm: `Confirm`,
  make_admin: `Make admin`,
  remove_admin: `Remove admin`,
  make_admin_description: `Are you sure you’d like to give {{name}} admin permissions? This means they’ll be able to invite and remove users from this company portal.`,
  remove_admin_description: `Are you sure you’d like to remove {{name}}'s admin permissions? This means they’ll no longer be able to invite and remove users from this company portal.`,
  invite_a_user: `Invite a user`,
  invite_user: `Invite user`,
  issuer_portal_expiration_notice: `Email invites to join the portal expire after 30 days. If needed, you can send another invite later.`,
  issuer_portal_expiration_notice_resend_invite: `This invite will expire in 30 days.`,
  user_type: `User Type`,
  role: `Role`,
  send_invite: `Send Invite`,
  resend_invite: `Resend invite`,
  resend_invite_to_email: `Resend an invitation to {{email}}?`,
  invite_resent: `Invite resent`,
  error_sending_invite: `Error resending invite`,
  user_type_is_required: `User type is required`,
  email_is_required: `Email is required`,
  email_invalid: `Invalid email address`,
  enter_an_email: `Enter an email`,
  cancel_invitation_success: `Invite cancelled`,
  send_invitation_success: `Invite sent`,
  remove_user: `Remove user`,
  remove_user_question: `Remove user?`,
  remove_admin_role_success: `Admin permissions removed`,
  add_admin_role_success: `Admin permissions added`,
  remove_user_success: `User removed`,
  about: `About`,
  transactions: `Transactions`,
  expand_menu: `Expand menu`,
  minimize_menu: `Minimize menu`,
  activate_user: `Activate user`,
  activate_user_question: `Activate User?`,
  activate_user_description: `Once activated, this user can access the company portal.`,
  activate_user_confirmation: `Activate {{name}} ({{email}})? `,
  activate_user_success: `User activated`,
  id: `ID`,
  rofr: `ROFR`,
  right_of_first_refusal: `Right of first refusal`,
  seller: `Seller`,
  buyer: `Buyer`,
  holding_type: `Holding type`,
  notice_received: `Notice received`,
  no_of_shares: `No. of shares`,
  pps: `PPS`,
  first: `First`,
  no_results: `No results`,
  no_results_description: `We couldn’t find any transactions based on what you submitted.`,
  documents: `Documents`,
  details: `Details`,
  institution: `Institution`,
  purchasing_entity: `Purchasing entity`,
  selling_entity: `Selling entity`,
  entity_country: `Entity country`,
  back_to_transactions: `Back to Transactions`,
  corporation: `Corporation`,
  individual: `Individual`,
  terms: `Terms`,
  notice_date: `Notice date`,
  transfer_fee: `Transfer fee`,
  number_of_shares: `Number of shares`,
  price_per_share_pps: `Price per share (PPS)`,
  price_per_share: `Price per share`,
  purchase_price: `Purchase price`,
  closed: `Closed`,
  in_review: `In review`,
  in_progress: `In progress`,
  stage: `Stage`,
  cancelled: `Cancelled`,
  view_all: `View all`,
  no_transactions_yet: `No transactions yet!`,
  no_in_review_transactions_description: `When a transaction is in review, you’ll find it here.`,
  no_in_progress_transactions_description: `When a transaction is in progress, you’ll find it here.`,
  no_closed_transactions_description: `When a transaction has been closed, you’ll find it here.`,
  no_cancelled_transactions_description: `When a transaction has been cancelled, you’ll find it here.`,
  no_transactions_description: `There are currently no transactions for your company.`,
  pagination_text: `{{start}}-{{end}} of {{totalCount}}`,
  transaction_status: `Transaction status`,
  transaction_cancelled: `Transaction cancelled`,
  submitted: `Submitted`,
  approved: `Approved`,
  signed: `Signed`,
  completed: `Completed`,
  transaction_awaiting_approval: `Transaction is awaiting approval from your company to proceed.`,
  documents_awaiting_signatures: `Documents are waiting to be signed by all parties for the transaction to proceed.`,
  funds_awaiting_transfer: `Funds between parties is awaiting transfer to close the transaction.`,
  transfer_of_funds_confirmed: `Transfer of funds have been confirmed. Please transfer share ownership and update your cap table.`,
  user: `User`,
  set_a_new_admin: `Set a new admin`,
  failed_to_change_user_roles: `Failed to change user roles`,
  failed_to_remove_user: `Failed to remove user`,
  changes_updated: `Changes updated`,
  no_documents_yet: `No documents yet`,
  transaction_has_no_documents: `You’ll find documents for this transaction here.`,
  transactions_table_search_placeholder: `Search by buyer, seller, or ID`,
  in_review_description: `These transactions are in review and awaiting decisions on ROFR.`,
  in_progress_description: `These transactions are awaiting signatures or fund settlement.`,
  closed_description: `These transactions may be awaiting transfer of shares and cap table updates.`,
  cancelled_description: `These transactions have been cancelled due to reason X, Y, Z.`,
  days_since_submission: `Days since submission`,
  date_closed: `Date closed`,
  date_cancelled: `Date cancelled`,
  day_ago: `{{daysAgo}} day ago`,
  days_ago: `{{daysAgo}} days ago`,
  less_than_one_day_ago: `< 1 day ago`,
  all_transactions_loaded: `All transactions loaded`,
  company_template_documents: `Company template documents`,
};
