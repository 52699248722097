import { GridItem } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { useUnaccreditedSellerDashboardByIdMarketActivityQuery } from "@/gql";

import { ListSharesSellNowBlockSkeleton } from "./ListSharesSellNowBlockSkeleton";
import { ListYourSharesCardV2 } from "./ListYourSharesCardV2";
import { SellNowCard } from "./SellNowCard";

const ListSharesSellNowBlockV2 = ({ id }: { id: string }) => {
  const query = useUnaccreditedSellerDashboardByIdMarketActivityQuery({
    variables: { id },
  });

  return (
    <WithQuery query={query} fallback={<ListSharesSellNowBlockSkeleton />}>
      {({ data: { companyById: company } }) => {
        const { othersStandingBids } = company.activity;

        const hasAvailableStandingBid = othersStandingBids.length > 0;

        return (
          <>
            <GridItem
              colSpan={{ base: 2, lg: hasAvailableStandingBid ? 1 : 2 }}
            >
              <ListYourSharesCardV2
                singleColumn={!hasAvailableStandingBid}
                id={id}
                company={company}
              />
            </GridItem>
            {hasAvailableStandingBid && (
              <GridItem colSpan={{ base: 2, lg: 1 }}>
                <SellNowCard id={company.id} company={company} />
              </GridItem>
            )}
          </>
        );
      }}
    </WithQuery>
  );
};
export default ListSharesSellNowBlockV2;
