import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Box, BoxProps } from "@chakra-ui/react";

function DaysAgoBadge(props: BoxProps) {
  return (
    <Box
      bg="grey.50"
      borderRadius={6}
      color="grey.900"
      display="inline-block"
      fontSize={12}
      px={1}
      py={0.5}
      {...props}
    />
  );
}

interface IssuerTransactionsDaysAgoBadgeProps {
  readonly daysAgo: number;
}

export function IssuerTransactionsDaysAgoBadge({
  daysAgo,
}: IssuerTransactionsDaysAgoBadgeProps) {
  const { t } = useTranslation(`issuers`);

  return match(daysAgo)
    .when(
      (days) => days === 0,
      () => <DaysAgoBadge>{t(`less_than_one_day_ago`)}</DaysAgoBadge>,
    )
    .when(
      (days) => days === 1,
      () => <DaysAgoBadge>{t(`day_ago`, { daysAgo })}</DaysAgoBadge>,
    )
    .when(
      (days) => days <= 9,
      () => <DaysAgoBadge> {t(`days_ago`, { daysAgo })}</DaysAgoBadge>,
    )
    .when(
      (days) => days <= 19,
      () => (
        <DaysAgoBadge bg="yellow.100" color="yellow.800">
          {t(`days_ago`, { daysAgo })}
        </DaysAgoBadge>
      ),
    )
    .otherwise(() => (
      <DaysAgoBadge bg="red.50" color="red.800">
        {t(`days_ago`, { daysAgo })}
      </DaysAgoBadge>
    ));
}
