import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { ReactNode } from "react";

import {
  Button,
  HStack,
  Text,
  ButtonProps,
  TextProps,
  StackProps,
} from "@chakra-ui/react";

type PaginatorButtonProps = ButtonProps;

type PaginatorLegendProps = {
  children: ReactNode;
} & TextProps;

type PaginatorProps = {
  children: ReactNode;
} & StackProps;

export function PaginatorButton(props: PaginatorButtonProps) {
  return <Button variant="paginator" {...props} />;
}

export function PaginatorLeft(props: Omit<PaginatorButtonProps, "children">) {
  return (
    <PaginatorButton {...props}>
      <HStack gap={2}>
        <CaretLeft size={14} />
      </HStack>
    </PaginatorButton>
  );
}

export function PaginatorRight(props: Omit<PaginatorButtonProps, "children">) {
  return (
    <PaginatorButton {...props}>
      <HStack gap={2}>
        <CaretRight size={14} />
      </HStack>
    </PaginatorButton>
  );
}

export function PaginatorLegend(props: PaginatorLegendProps) {
  return <Text fontSize={16} mr={4} {...props} />;
}

export function Paginator({ children, ...restProps }: PaginatorProps) {
  return (
    <HStack gap={2} {...restProps}>
      {children}
    </HStack>
  );
}
