import { VStack } from "@chakra-ui/react";

import {
  DiscussionThreadCard,
  MessageCounterpartyCard,
} from "@/components/common";
import { BuyerEntityCard, SettlementCard } from "@/components/entities";
import {
  BidPageBuyerActivityBidFragment,
  BidState,
  TransactionState,
  UserWithInstitutionFragment,
} from "@/gql";
import { useBAITEnabled } from "@/hooks/featureFlags";
import { getAvailableBidActions, getAvailableCompanyActions } from "@/utils";

import { PlaceStandingBidCTACard } from "./PlaceStandingBidCTACard";

const BuyerActivity = ({
  bid,
  actor,
}: {
  readonly bid: BidPageBuyerActivityBidFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { canPlaceStandingBid } = getAvailableCompanyActions(bid.company);

  const { canRequestDiscussion, canMessageCounterparty } =
    getAvailableBidActions(bid);

  const showStandingBidCTA =
    canPlaceStandingBid &&
    [BidState.Active, BidState.Countered].includes(bid.state) &&
    !bid.listing.isHiiveSpv;

  const showMessageCounterpartyCard = canRequestDiscussion;
  const showDiscussionThreadCard =
    canMessageCounterparty && !showMessageCounterpartyCard && !!bid.discussion;
  const isInstitution = !!actor.institutionId;
  const transactionAwaitingClosing =
    bid.transaction?.state &&
    [
      TransactionState.InReview,
      TransactionState.BidAccepted,
      TransactionState.AwaitingClosing,
      TransactionState.ClosedFeePending,
      TransactionState.IssuerApproved,
      TransactionState.IssuerPendingApproval,
    ].includes(bid.transaction?.state);

  const showEntityCard =
    transactionAwaitingClosing && (!actor.isHiiveUser || actor.isHiiveUser);

  const isBAITEnabled = useBAITEnabled();

  return (
    <VStack spacing={6} w="full">
      {isBAITEnabled && bid.transaction?.buyerVirtualAccount && (
        <SettlementCard
          virtualAccount={bid.transaction?.buyerVirtualAccount}
          wireAmount={bid.transaction?.grossProceeds ?? 0}
        />
      )}
      {showEntityCard && (
        <BuyerEntityCard
          displayId={bid.displayId}
          entities={
            isInstitution ? actor.institution?.entities : actor.entities
          }
          isInstitution={isInstitution}
          transaction={bid?.transaction}
        />
      )}
      {showStandingBidCTA && <PlaceStandingBidCTACard bid={bid} />}
      {canRequestDiscussion && (
        <MessageCounterpartyCard bidId={bid.id} topic={bid.listing} />
      )}
      {showDiscussionThreadCard && !!bid.discussion && (
        <DiscussionThreadCard discussion={bid.discussion} />
      )}
    </VStack>
  );
};

export default BuyerActivity;
