import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  dialog: {
    my: { base: 0, md: `auto` },
    mx: { base: 0, md: `auto` },
    borderColor: `grey.200`,
    borderRadius: { base: 0, md: `lg` },
    bg: `white`,
    boxShadow: `card`,
  },
  header: {
    borderWidth: 0.5,
    borderColor: `grey.700`,

    bg: `grey.900`,
    py: { base: 4, md: 6 },
    px: { base: 5, md: 10 },
    borderTopRadius: { base: 0, md: `lg` },

    color: `white`,
    textStyle: `heading-2xl`,
    fontWeight: `medium`,
  },
  closeButton: {
    color: `white`,
    size: `xl`,
    position: `absolute`,
  },
  body: {
    bg: `white`,
    borderColor: `grey.200`,
    borderWidth: 0.5,
    borderTopWidth: 0,
    "&:last-child": {
      borderBottomRadius: `md`,
    },
    py: 6,
    px: { base: 5, md: 10 },
  },
  footer: {
    bg: `grey.25`,
    borderColor: `grey.200`,
    borderWidth: { base: 0, md: 0.5 },
    borderTopWidth: { base: 0, md: 0 },
    borderBottomRadius: { base: 0, md: `md` },
    py: 6,
  },
});

export const Modal = defineMultiStyleConfig({
  baseStyle,
  variants: {
    texas: {
      ...baseStyle,
      dialog: {
        maxW: {
          base: `full`,
          md: `calc(100% - 128px)`,
        },
        maxH: {
          base: `full`,
          md: `calc(100% - 128px)`,
        },
      },
      closeButton: {
        color: `grey.900`,
        borderColor: `grey.100`,
        position: `absolute`,
        top: 5,
        right: 5,
        p: 1,
        borderWidth: 1,
      },
      header: {
        bg: `white`,
        color: `grey.900`,
        borderColor: `grey.100`,
      },
      body: {
        borderColor: `grey.100`,
      },
    },
  },
});
