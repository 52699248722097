import {
  InstitutionPermission,
  GetAvailableInstitutionActionsInstitutionFragment,
} from "@/gql";
import { iHaveEntityPermission } from "@/hooks";

const getCanUpdateComplianceEmail = (
  institution?: GetAvailableInstitutionActionsInstitutionFragment | null,
) =>
  iHaveEntityPermission(
    institution,
    InstitutionPermission.UpdateInstitutionComplianceEmail,
  );

const getCanViewInstitutionUsers = (
  institution?: GetAvailableInstitutionActionsInstitutionFragment | null,
) => iHaveEntityPermission(institution, InstitutionPermission.InstitutionUsers);

const getCanViewInstitutionUserInvitations = (
  institution?: GetAvailableInstitutionActionsInstitutionFragment | null,
) =>
  iHaveEntityPermission(
    institution,
    InstitutionPermission.InstitutionInvitations,
  );

const getCanManageInstitutionUsers = (
  institution?: GetAvailableInstitutionActionsInstitutionFragment | null,
) =>
  getCanViewInstitutionUserInvitations(institution) &&
  getCanViewInstitutionUsers(institution);

const getCanUpdateAccreditation = (
  institution?: GetAvailableInstitutionActionsInstitutionFragment | null,
) =>
  iHaveEntityPermission(
    institution,
    InstitutionPermission.CanUpdateAccreditation,
  );

const getCanUpdateSuitability = (
  institution?: GetAvailableInstitutionActionsInstitutionFragment | null,
) =>
  iHaveEntityPermission(
    institution,
    InstitutionPermission.CanUpdateSuitability,
  );

export const getAvailableInstitutionActions = (
  institution?: GetAvailableInstitutionActionsInstitutionFragment | null,
) => {
  const canUpdateComplianceEmail = getCanUpdateComplianceEmail(institution);
  const canViewInstitutionUsers = getCanViewInstitutionUsers(institution);
  const canViewInstitutionUserInvitations =
    getCanViewInstitutionUserInvitations(institution);
  const canManageInstitutionUsers = getCanManageInstitutionUsers(institution);
  const canUpdateInstitutionAccreditation =
    getCanUpdateAccreditation(institution);
  const canUpdateInstitutionSuitability = getCanUpdateSuitability(institution);

  return {
    canUpdateComplianceEmail,
    canViewInstitutionUsers,
    canViewInstitutionUserInvitations,
    canManageInstitutionUsers,
    canUpdateInstitutionAccreditation,
    canUpdateInstitutionSuitability,
  };
};
