import { useTranslation } from "react-i18next";

import { HiiveModalHeader } from "@/components/common";
import { StepPropsV2, StepsHeader } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  CreateListingSelectCompanyInputCompanyFragment,
  UserWithInstitutionFragment,
} from "@/gql";

import {
  createAdditionalDetailsValidationSchema,
  useShareDetailsValidationSchema,
  BrokerSubmitListingSequenceModalFormValues,
} from ".";
import { AdditionalDetails } from "./AdditionalDetails";
import BrokerSubmitListingShareDetails from "./BrokerSubmitListingShareDetails";
import { StepKeys, stepKeys } from "./steps";

interface CreateListingModalProps
  extends StepPropsV2<StepKeys, BrokerSubmitListingSequenceModalFormValues> {
  readonly initialCompany?: CreateListingSelectCompanyInputCompanyFragment;
  readonly actor: UserWithInstitutionFragment;
}

const BrokerSubmitListingModal = ({
  stepRouter,
  initialCompany,
  ...formikProps
}: CreateListingModalProps) => {
  const { values } = formikProps;
  const { stepsInfo } = stepRouter;

  const { t } = useTranslation();
  const shareDetailsValidationSchema = useShareDetailsValidationSchema();
  const isShareDetailsStepValid =
    shareDetailsValidationSchema.isValidSync(values);
  const isAdditionalDetailsStepValid =
    createAdditionalDetailsValidationSchema().isValidSync(values);

  return (
    <div data-testid="create-listing-modal">
      <HiiveModalHeader>{t(`submit_a_listing`)}</HiiveModalHeader>
      <StepsHeader
        stepRouter={stepRouter}
        steps={[
          {
            stepKey: stepKeys.brokerSubmitListingShareDetails,
            stepTitle: `Share Details`,
            isStepValid: isShareDetailsStepValid,
          },
          {
            stepKey: stepKeys.brokerSubmitListingAdditionalDetails,
            stepTitle: `Additional Details`,
            isStepValid: isAdditionalDetailsStepValid,
          },
        ]}
      />
      {stepsInfo.currentStepKey ===
        stepKeys.brokerSubmitListingShareDetails && (
        <BrokerSubmitListingShareDetails
          initialCompany={initialCompany}
          stepRouter={stepRouter}
          {...formikProps}
        />
      )}
      {stepsInfo.currentStepKey ===
        stepKeys.brokerSubmitListingAdditionalDetails && (
        <AdditionalDetails stepRouter={stepRouter} {...formikProps} />
      )}
    </div>
  );
};

export default withCurrentActor(BrokerSubmitListingModal);
