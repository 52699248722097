import dayjs from "dayjs";
import capitalize from "lodash/capitalize";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Flex, Text, Badge, Show, Stack } from "@chakra-ui/react";

import { BasicUserEntityFragment } from "@/gql";
import { useCountryList, useTranslatedEntityNames } from "@/hooks";

import EntityItemIcon from "./EntityItemIcon";
import EntityItemRemoveButton from "./RemoveEntityButton";

type EntityProps = {
  entity: Pick<
    BasicUserEntityFragment,
    | "active"
    | "id"
    | "insertedAt"
    | "legalName"
    | "otherType"
    | "jurisdictionOfFormation"
    | "type"
  >;
  showDate?: boolean;
  showIcon?: boolean;
  showRemoveButton?: boolean;
  showStatus?: boolean;
};

const EntityItemMobile = ({
  entity,
  countryName,
  date,
  entityTypeName,
  showDate,
  showIcon,
  showRemoveButton,
  showStatus,
}: Pick<
  EntityProps,
  "entity" | "showDate" | "showRemoveButton" | "showStatus" | "showIcon"
> & {
  countryName: string;
  date: string;
  entityTypeName: string;
}) => {
  const { t } = useTranslation();

  const { id, legalName, active } = entity;

  return (
    <Flex
      alignItems="center"
      gap={4}
      justifyContent={showIcon ? `start` : `space-between`}
    >
      {showIcon && <EntityItemIcon type={entity?.type} />}
      <Stack gap={0}>
        <Text color="skyBlue.1200" textStyle="text-xs" fontWeight="500">
          {entityTypeName}
        </Text>
        <Text as="span" textTransform="capitalize" fontWeight={500}>
          {legalName}
        </Text>
        <Stack textTransform="capitalize" gap={1}>
          <Text as="span" fontWeight={400} fontSize={14} lineHeight={1.5}>
            {countryName}
          </Text>
          {showDate && (
            <Text fontSize={12} fontWeight="400" color="grey.600">
              {date}
            </Text>
          )}
        </Stack>
      </Stack>
      <Flex gap={2}>
        {showStatus && active && (
          <Badge
            variant="status-active"
            lineHeight="normal"
            flex="row"
            alignContent="center"
            textAlign="center"
            borderRadius={6}
            w="43px"
            p="2px 4px 2px 4px"
          >
            <Text fontWeight="400" fontSize="12">
              {t(`entity_active_label`)}
            </Text>
          </Badge>
        )}
        {showRemoveButton && !active && (
          <EntityItemRemoveButton
            disabled={active}
            entity={entity}
            transactionId={id}
          />
        )}
      </Flex>
    </Flex>
  );
};

const EntityItemDesktop = ({
  entity,
  countryName,
  date,
  entityTypeName,
  showDate,
  showRemoveButton,
  showStatus,
}: EntityProps & {
  countryName: string;
  date: string;
  entityTypeName: string;
}) => {
  const { t } = useTranslation();

  const { id, legalName, active } = entity;

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center" gap={4}>
        <EntityItemIcon type={entity?.type} />
        <Stack gap={0}>
          <Text color="skyBlue.1200" textStyle="text-xs" fontWeight="500">
            {entityTypeName}
          </Text>
          <Text textTransform="capitalize">
            <Text as="span" fontWeight={500} marginRight={1}>
              {legalName}
            </Text>
            <Text as="span" fontWeight={400} textAlign="right">
              {countryName}
            </Text>
          </Text>
          {showDate && (
            <Text textStyle="text-xs" fontWeight="400">
              {date}
            </Text>
          )}
        </Stack>
      </Flex>
      <Flex gap={2}>
        {showStatus && active && (
          <Badge
            variant="status-active"
            lineHeight="normal"
            flex="row"
            alignContent="center"
            textAlign="center"
            borderRadius={6}
            w="43px"
            p="0"
          >
            <Text fontWeight="400" fontSize="12">
              {t(`entity_active_label`)}
            </Text>
          </Badge>
        )}
        {showRemoveButton && (
          <EntityItemRemoveButton
            disabled={active}
            entity={entity}
            transactionId={id}
          />
        )}
      </Flex>
    </Flex>
  );
};

const EntityItem = ({
  entity,
  showDate = true,
  showRemoveButton = true,
  showStatus = true,
  showIcon = true,
}: EntityProps) => {
  const { t } = useTranslation();
  const { jurisdictionOfFormation, insertedAt, type, otherType } = entity;
  const countryCode = jurisdictionOfFormation?.name;
  const formattedDate = dayjs(insertedAt).format(`MMMM D, YYYY`);

  const { getFormattedEntityName } = useTranslatedEntityNames();
  const { getName } = useCountryList();
  const countryName = getName(countryCode);
  const entityTypeName =
    type === `OTHER` && !!otherType
      ? capitalize(otherType)
      : getFormattedEntityName(type);

  return (
    <Card shadow="0" padding="12px 16px 12px 16px">
      <Show below="lg" ssr={false}>
        <EntityItemMobile
          countryName={countryName}
          date={`${t(`added`)} ${formattedDate}`}
          entity={entity}
          entityTypeName={entityTypeName}
          showDate={showDate}
          showRemoveButton={showRemoveButton}
          showStatus={showStatus}
          showIcon={showIcon}
        />
      </Show>
      <Show above="lg" ssr={false}>
        <EntityItemDesktop
          countryName={countryName}
          date={`${t(`added`)} ${formattedDate}`}
          entity={entity}
          entityTypeName={entityTypeName}
          showDate={showDate}
          showRemoveButton={showRemoveButton}
          showStatus={showStatus}
        />
      </Show>
    </Card>
  );
};

export default EntityItem;
