import {
  ActivityCard,
  ActivityCardListingCardFooter,
} from "@/components/common";
import {
  CompanyListingCardBody,
  CompanyListingCardHeader,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyMarketActivityListingCardCompanyFragment,
  CompanyMarketActivityListingCardListingFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { appendSellerCompanyIdToUrl, getIsUnaccreditedSeller } from "@/utils";

const CompanyMarketActivityListingCard = withCurrentActor(
  ({
    listing,
    company,
    actor,
  }: {
    readonly listing: CompanyMarketActivityListingCardListingFragment;
    readonly company: CompanyMarketActivityListingCardCompanyFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    const url = `/listings/${listing.id}`;
    return (
      <ActivityCard
        href={
          isUnaccreditedSeller
            ? appendSellerCompanyIdToUrl(url, company.id)
            : url
        }
      >
        <CompanyListingCardHeader listing={listing} />
        <CompanyListingCardBody listing={listing} company={company} />
        <ActivityCardListingCardFooter listing={listing} />
      </ActivityCard>
    );
  },
);

export default CompanyMarketActivityListingCard;
