import { SidebarSimple } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { HStack, Box, TooltipProps, VStack } from "@chakra-ui/react";

/*
 * The import here must be direct import, otherwise storybook will break
 * Ask Nicole or Aydan as to why, but they still might not know
 * October 6, 2024
 * Following the thread from src/components/common/index.tsx:71
 */
// eslint-disable-next-line no-restricted-imports
import { Logo } from "@/components/common/Logo";
import { IssuerTooltip } from "@/components/issuers";
import {
  DESKTOP_NAV_LINK_TOOLTIP_DELAY,
  DesktopNavHeader,
  DesktopNavToggleButton,
} from "@/components/nav";

type IssuerDesktopNavHeaderProps = {
  isOpen: boolean;
  onToggle: () => void;
};

type IssuerDesktopNavHeaderEntityProps = {
  onToggle: () => void;
};

type DesktopNavToggleActionButtonProps = TooltipProps & {
  onToggle: () => void;
  children: ReactNode;
};

function DesktopNavToggleActionButton({
  children,
  onToggle,
  ...restProps
}: DesktopNavToggleActionButtonProps) {
  return (
    <IssuerTooltip
      placement="right-end"
      openDelay={DESKTOP_NAV_LINK_TOOLTIP_DELAY}
      {...restProps}
    >
      <DesktopNavToggleButton onClick={onToggle}>
        {children}
      </DesktopNavToggleButton>
    </IssuerTooltip>
  );
}

function IssuerDesktopNavExpandedHeader({
  onToggle,
}: IssuerDesktopNavHeaderEntityProps) {
  const { t } = useTranslation(`issuers`);

  return (
    <HStack>
      <Logo.RedBlackIcon w={6} h={6} />
      <Box flexGrow={1} />
      <DesktopNavToggleActionButton
        label={t(`minimize_menu`)}
        onToggle={onToggle}
        shouldWrapChildren
      >
        <Box as={SidebarSimple} size={34} p={1} />
      </DesktopNavToggleActionButton>
    </HStack>
  );
}

function IssuerDesktopNavCollapsedHeader({
  onToggle,
}: IssuerDesktopNavHeaderEntityProps) {
  const { t } = useTranslation(`issuers`);

  return (
    <VStack w="full" gap={6}>
      <DesktopNavToggleActionButton
        label={t(`expand_menu`)}
        onToggle={onToggle}
        shouldWrapChildren
      >
        <Box as={SidebarSimple} size={34} p={1} />
      </DesktopNavToggleActionButton>
    </VStack>
  );
}

export function IssuerDesktopNavHeader({
  isOpen,
  ...restProps
}: IssuerDesktopNavHeaderProps) {
  return (
    <DesktopNavHeader>
      {isOpen ? (
        <IssuerDesktopNavExpandedHeader {...restProps} />
      ) : (
        <IssuerDesktopNavCollapsedHeader {...restProps} />
      )}
    </DesktopNavHeader>
  );
}
