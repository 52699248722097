import {
  ActivityCard,
  ActivityCardStandingBidCardFooter,
} from "@/components/common";
import {
  CompanyStandingBidCardBody,
  CompanyStandingBidCardHeader,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyMarketActivityStandingBidCardStandingBidFragment,
  CompanyPageMarketActivityCompanyFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { appendSellerCompanyIdToUrl, getIsUnaccreditedSeller } from "@/utils";

const CompanyMarketActivityStandingBidCard = withCurrentActor(
  ({
    standingBid,
    company,
    actor,
  }: {
    readonly standingBid: CompanyMarketActivityStandingBidCardStandingBidFragment;
    readonly company?: CompanyPageMarketActivityCompanyFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    const url = `/standing-bids/${standingBid.id}`;
    return (
      <ActivityCard
        href={
          isUnaccreditedSeller && company?.id
            ? appendSellerCompanyIdToUrl(url, company.id)
            : url
        }
      >
        <CompanyStandingBidCardHeader standingBid={standingBid} />
        <CompanyStandingBidCardBody
          standingBid={standingBid}
          company={company}
        />
        <ActivityCardStandingBidCardFooter standingBid={standingBid} />
      </ActivityCard>
    );
  },
);

export default CompanyMarketActivityStandingBidCard;
