import { WarningCircle } from "@phosphor-icons/react";
import { Trans } from "react-i18next";

import { Box, Flex, Text } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { CompanyActionsCompanyFragment } from "@/gql";
import { useColors } from "@/hooks";

const UntradeableReminder = ({
  company,
  securitySpecialistEmail,
}: {
  readonly company: Pick<
    CompanyActionsCompanyFragment,
    | `id`
    | `securitySpecialist`
    | `name`
    | `tradeableSellSide`
    | `myPermissionsV2`
  >;
  readonly securitySpecialistEmail: string;
}) => {
  const [red600] = useColors([`red.600`]);

  return (
    <Flex gap="2" data-testid="compliance-reminder">
      <Box flexShrink={0}>
        <WarningCircle color={red600} size={20} weight="fill" />
      </Box>
      <Text textStyle="text-sm">
        <Trans
          i18nKey="untradeable_company"
          values={{
            companyName: company.name,
            securitySpecialist: securitySpecialistEmail,
          }}
          components={{
            b: <strong />,
            mailto: (
              <MailtoLink
                key="contact"
                textDecoration="underline"
                email={securitySpecialistEmail}
              />
            ),
          }}
        />
      </Text>
    </Flex>
  );
};

export default UntradeableReminder;
