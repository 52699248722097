import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { useBreakpointValue } from "@chakra-ui/react";

import {
  CTACard,
  UnaccreditedSellerComplianceReminder,
  WithQuery,
} from "@/components/common";
import {
  UnaccreditedSellerCompanyPageActionsCompanyFragment,
  useUnaccreditedSellerCompanyPageActionsQuery,
} from "@/gql";
import { getAvailableCompanyActions } from "@/utils";

import { ActionsSkeleton } from "./ActionsSkeleton";

const ListForSaleCTACard = ({
  company,
}: {
  readonly company: UnaccreditedSellerCompanyPageActionsCompanyFragment;
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  const { canCreateListing } = getAvailableCompanyActions(company);

  const action = {
    ctaText: t(`sell`),
    disabled: !canCreateListing,
    onClick: () => router.push(`/create-listing`),
  };

  const isMobileView = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    { ssr: false },
  );

  return (
    <CTACard
      // direction is not only passed directly to style the card
      direction={isMobileView ? `column` : `row`}
      heading={t(`list_for_sale`)}
      description={t(`create_listing_cta`)}
      action={action}
      footer={
        canCreateListing ? null : (
          <UnaccreditedSellerComplianceReminder company={company} />
        )
      }
    />
  );
};

const Actions = () => {
  const query = useUnaccreditedSellerCompanyPageActionsQuery();

  return (
    <WithQuery query={query} fallback={<ActionsSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myListing, myCompany },
        },
      }) => {
        if (!!myListing) {
          return null;
        }

        return <ListForSaleCTACard company={myCompany} />;
      }}
    </WithQuery>
  );
};

export default Actions;
