import { useTranslation } from "react-i18next";

import { useToast, UseToastOptions } from "@chakra-ui/react";

import { ToastDuration } from "@/types/toast";

const useCustomToast = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const successToast = (message: string, opts?: UseToastOptions) =>
    toast({
      title: message,
      status: `success`,
      duration: ToastDuration.Short,
      isClosable: true,
      ...opts,
    });

  const infoToast = (title: string, opts?: UseToastOptions) =>
    toast({
      title,
      status: `info`,
      duration: ToastDuration.Short,
      isClosable: true,
      variant: `grey`,
      ...opts,
    });

  const errorToast = (message: string, opts?: UseToastOptions) =>
    toast({
      title: t(`error_occurred`),
      description: message,
      status: `error`,
      duration: ToastDuration.Long,
      isClosable: true,
      ...opts,
    });

  return {
    successToast,
    errorToast,
    infoToast,
  };
};

export default useCustomToast;
