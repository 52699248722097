import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  `pagePosition`,
  `iconContainer`,
]);

export const FrontChatLauncher = defineMultiStyleConfig({
  baseStyle: {
    pagePosition: {
      position: `fixed`,
      right: `20px`,
      zIndex: 50,
    },
    iconContainer: {
      backgroundColor: `rgb(50, 50, 50)`,
      height: `62px`,
      width: `62px`,
      borderRadius: `31px`,
      cursor: `pointer`,
      _hover: {
        opacity: 0.9,
      },
    },
  },
  variants: {
    mobile: {
      pagePosition: {
        bottom: `80px`,
      },
    },
    desktop: {
      pagePosition: {
        bottom: `20px`,
      },
    },
  },
  defaultProps: {
    variant: `desktop`,
  },
});
