import { useTranslation } from "react-i18next";

import { Button, Stack, Text, useDisclosure } from "@chakra-ui/react";

import { ConfirmationModal } from "@/components/common";
import {
  useArchiveEntityMutation,
  CurrentContextDocument,
  BasicUserEntityFragment,
} from "@/gql";
import { useCustomToast } from "@/hooks";

type RemoveEntityButtonProps = {
  disabled: boolean;
  entity: BasicUserEntityFragment;
  transactionId: string;
};

const RemoveEntityButton = ({
  disabled,
  entity,
  transactionId,
}: RemoveEntityButtonProps) => {
  const { t } = useTranslation(`account`);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { successToast } = useCustomToast();

  const [archiveEntity, { loading: isLoading }] = useArchiveEntityMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onArchiveEntity = async (entityId: string) => {
    await archiveEntity({
      variables: {
        input: {
          entityId,
        },
      },
    });
    successToast(t(`entities.remove_entity_confirmation_success_message`));
    onClose();
  };

  return (
    <>
      <Button
        onClick={() => onOpen()}
        isDisabled={disabled}
        variant="text-salmon"
      >
        {t(`remove`, {
          ns: `translation`,
        })}
      </Button>
      <ConfirmationModal
        confirmationButtonProps={{
          isLoading,
          children: <>{t(`entities.remove_entity`)}</>,
        }}
        isOpen={isOpen}
        onCancel={onClose}
        onClose={onClose}
        onConfirm={() => onArchiveEntity(transactionId)}
        body={
          <Stack gap={2}>
            <Text fontSize={22} fontWeight={500}>
              {t(`entities.remove_entity_confirmation_header`, {
                entity: entity.legalName,
              })}
            </Text>
            <Text fontWeight={400}>
              {t(`entities.remove_entity_confirmation_message`)}
            </Text>
          </Stack>
        }
        size="xl"
        title={<Text>{t(`entities.remove_entity_confirmation_title`)}</Text>}
      />
    </>
  );
};

export default RemoveEntityButton;
