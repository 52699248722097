import dayjs from "dayjs";

import { ExecutionMilestone, ExecutionWorkflowCondition } from "@/gql";

import { NonNullableTransactionWorkflowEdge } from "./IssuerTransactionsProvider";

const MOST_RECENT_DATE_INDEX = 0;

export const getNoticeReceivedDate = (
  workflow: NonNullableTransactionWorkflowEdge["node"]["workflow"],
) => {
  const submittedMilestones = workflow.milestoneCompletions
    .filter(
      (milestoneCompletion) =>
        milestoneCompletion.milestone === ExecutionMilestone.Submitted,
    )
    .sort((a, b) => b.completedAt - a.completedAt);

  return dayjs(submittedMilestones[MOST_RECENT_DATE_INDEX].completedAt).format(
    `MMM D, YYYY`,
  );
};

export const getClosedDate = (
  workflow: NonNullableTransactionWorkflowEdge["node"]["workflow"],
) => {
  const closedMilestones = workflow.milestoneCompletions
    .filter(
      (milestoneCompletion) =>
        milestoneCompletion.milestone === ExecutionMilestone.Complete,
    )
    .sort((a, b) => b.completedAt - a.completedAt);

  return dayjs(closedMilestones[MOST_RECENT_DATE_INDEX].completedAt).format(
    `MMM D, YYYY`,
  );
};

export const getCancelledDate = (
  workflow: NonNullableTransactionWorkflowEdge["node"]["workflow"],
) => {
  const cancelledConditions = workflow.conditions
    .filter(
      (condition) =>
        condition.condition === ExecutionWorkflowCondition.Cancelled ||
        condition.condition ===
          ExecutionWorkflowCondition.IssuerApprovalDeclined,
    )
    .sort((a, b) => b.insertedAt - a.insertedAt);

  return dayjs(cancelledConditions[MOST_RECENT_DATE_INDEX].insertedAt).format(
    `MMM D, YYYY`,
  );
};

export const getDaysSinceSubmission = (
  workflow: NonNullableTransactionWorkflowEdge["node"]["workflow"],
) => {
  const submittedMilestones = workflow.milestoneCompletions
    .filter(
      (milestoneCompletion) =>
        milestoneCompletion.milestone === ExecutionMilestone.Submitted,
    )
    .sort((a, b) => b.completedAt - a.completedAt);

  const submissionDate = dayjs(
    submittedMilestones[MOST_RECENT_DATE_INDEX].completedAt,
  );

  return dayjs().diff(submissionDate, `day`);
};

export enum ISSUER_TRANSACTION_TAB_INDEX {
  DETAILS = 0,
  STATUS = 1,
  DOCUMENTS = 2,
}
