import { match } from "ts-pattern";

import {
  ManualLOIBuyerSteps,
  ManualLOIStatesMap,
  ManualLOITransactionState,
} from "@/components/postings";
import { TransactionState, ManualLoiBuyerStatesBidFragment } from "@/gql";
import {
  bidLabel,
  getTransactionCounterpartyName,
  isEitherFund,
  isOtherMethod,
} from "@/utils";

import { TransferStatusItem } from "./TransferStatusItem";

const ManualLOIBuyerStates = ({
  state,
  bid,
}: {
  readonly state: ManualLOITransactionState;
  readonly bid: ManualLoiBuyerStatesBidFragment;
}) => {
  const states: ManualLOIStatesMap<ManualLOIBuyerSteps> = {
    [TransactionState.BidAccepted]: {
      bidAccepted: `PENDING`,
      awaitingClosing: null,
      closed: null,
    },
    [TransactionState.AwaitingClosing]: {
      bidAccepted: `SUCCESS`,
      awaitingClosing: `PENDING`,
      closed: null,
    },
    [TransactionState.ClosedFeePending]: {
      bidAccepted: `SUCCESS`,
      awaitingClosing: `SUCCESS`,
      closed: `SUCCESS`,
    },
    [TransactionState.ClosedFeePaid]: {
      bidAccepted: `SUCCESS`,
      awaitingClosing: `SUCCESS`,
      closed: `SUCCESS`,
    },
  };

  const counterpartyName = bid.transaction
    ? getTransactionCounterpartyName(bid.transaction)
    : null;

  const bidAcceptedSubtitle = match(states[state].bidAccepted)
    .with(`PENDING`, () => `Awaiting signed letter of intent`)
    .with(`SUCCESS`, () => `Counter-party signer: ${counterpartyName}`)
    .otherwise(() => undefined);

  const loiSignedSubtitle =
    states[state].bidAccepted === `SUCCESS` &&
    states[state].awaitingClosing === `PENDING`
      ? `Awaiting closing`
      : undefined;

  const isFundOrOther = isEitherFund(bid.listing) || isOtherMethod(bid.listing);
  const title = bidLabel(bid.listing);

  return (
    <>
      <TransferStatusItem
        isCompleted
        title={`${title} accepted`}
        subtitle={isFundOrOther ? undefined : bidAcceptedSubtitle}
      />
      {!isFundOrOther && (
        <TransferStatusItem
          isCompleted={states[state].bidAccepted === `SUCCESS`}
          title="Letter of Intent signed"
          subtitle={loiSignedSubtitle}
        />
      )}
      <TransferStatusItem
        isCompleted={states[state].closed === `SUCCESS`}
        title="Transaction completed"
      />
    </>
  );
};

export default ManualLOIBuyerStates;
