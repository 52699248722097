import { match } from "ts-pattern";

import { DocumentType, TransferMethodV2, ValidTransferMethodV2 } from "@/gql";

/**
 * Converts a document type into its corresponding short document title
 * @param documentType - the document type on a document that will be converted into a document title
 * @returns - appropriate document title
 */
export const getShortDocumentTitleByDocumentType = (
  documentType: DocumentType,
) =>
  match(documentType)
    .with(DocumentType.Stn, () => `STN`)
    .with(DocumentType.Loi, () => `LOI`)
    .otherwise((documentType) => {
      throw new Error(
        `Unknown documentType in getShortDocumentTitleByDocumentType: ${documentType}`,
      );
    });

/**
 * Converts a document type into its corresponding long document title
 * @param documentType - the document type on a document that will be converted into a document title
 * @returns - appropriate document title
 */
export const getLongDocumentTitleByDocumentType = (
  documentType: DocumentType,
) =>
  match(documentType)
    .with(DocumentType.Stn, () => `Share Transfer Notice`)
    .with(DocumentType.Loi, () => `Letter of Intent`)
    .otherwise((documentType) => {
      throw new Error(
        `Unknown documentType in getLongDocumentTitleByDocumentType: ${documentType}`,
      );
    });

/**
 * Converts a transfer method into its corresponding short document title
 * @param transferMethod - the transfer method on a transaction / listing that will be converted into a document title
 * @returns - appropriate document title
 */
export const getShortDocumentTitleByTransferMethod = (
  transferMethod: TransferMethodV2,
) =>
  match(transferMethod)
    .with(TransferMethodV2.Direct, () => `STN`)
    .with(TransferMethodV2.ForwardContract, () => `LOI`)
    .with(TransferMethodV2.SpvLister, () => `LOI`)
    .with(TransferMethodV2.SpvThirdparty, () => `LOI`)
    .with(TransferMethodV2.Fund, () => `LOI`)
    .with(TransferMethodV2.HiiveFund, () => `LOI`)
    .with(TransferMethodV2.Unknown, () => `STN/LOI`)
    .with(TransferMethodV2.Other, () => `Agreement`)
    .otherwise(() => {
      throw new Error(
        `Unknown transfer method in getShortDocumentTitleByTransferMethod: ${transferMethod}`,
      );
    });

/**
 * Converts a transfer method into its corresponding long document title
 * @param transferMethod - the transfer method on a transaction / listing that will be converted into a document title
 * @returns - appropriate document title
 */
export const getLongDocumentTitleByTransferMethod = (
  transferMethod: TransferMethodV2 | ValidTransferMethodV2,
) =>
  match(transferMethod)
    .with(TransferMethodV2.Direct, () => `Share Transfer Notice`)
    .with(TransferMethodV2.ForwardContract, () => `Letter of Intent`)
    .with(TransferMethodV2.SpvLister, () => `Letter of Intent`)
    .with(TransferMethodV2.SpvThirdparty, () => `Letter of Intent`)
    .with(TransferMethodV2.Fund, () => `Letter of Intent`)
    .with(TransferMethodV2.HiiveFund, () => `Letter of Intent`)
    .with(TransferMethodV2.Other, () => `Other`)
    .with(
      TransferMethodV2.Unknown,
      () => `Share Transfer Notice / Letter of Intent`,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transfer method in getLongDocumentTitleByTransferMethod: ${transferMethod}`,
      );
    });

export const getFullDocumentTitleByTransferMethod = (
  transferMethod: TransferMethodV2,
) =>
  match(transferMethod)
    .with(TransferMethodV2.Direct, () => `STN (Share Transfer Notice)`)
    .with(TransferMethodV2.ForwardContract, () => `LOI (Letter of Intent)`)
    .with(TransferMethodV2.SpvLister, () => `LOI (Letter of Intent)`)
    .with(TransferMethodV2.SpvThirdparty, () => `LOI (Letter of Intent)`)
    .with(TransferMethodV2.Fund, () => `LOI (Letter of Intent)`)
    .with(TransferMethodV2.HiiveFund, () => `LOI (Letter of Intent)`)
    .with(
      TransferMethodV2.Unknown,
      () => `STN (Share Transfer Notice) / LOI (Letter of Intent)`,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transfer method in getFullDocumentTitleByTransferMethod: ${transferMethod}`,
      );
    });
