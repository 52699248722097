import dayjs from "dayjs";
import isNil from "lodash/fp/isNil";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { CardFooter } from "@chakra-ui/react";

import { InvestorStatus } from "@/gql";
import { useCurrentActor } from "@/hooks";

import { AccreditationForm } from "./AccreditationForm";
import IndividualAccreditationCardBody from "./IndividualAccreditationCardBody";
import InstitutionAccreditationCardBody from "./InstitutionAccreditationCardBody";

const AccreditationCardContent = () => {
  const { t } = useTranslation();
  const actor = useCurrentActor();
  const [isModifying, setIsModifying] = useState(false);
  const isIndividualUser = actor.investorStatus === InvestorStatus.Individual;
  const accreditationLastUpdatedAt = isIndividualUser
    ? actor.accreditationLastUpdatedAt
    : actor.institution?.accreditationLastUpdatedAt;

  const accreditationBody = match({ isIndividualUser })
    .with({ isIndividualUser: true }, () => (
      <IndividualAccreditationCardBody onModify={() => setIsModifying(true)} />
    ))
    .otherwise(() => (
      <InstitutionAccreditationCardBody onModify={() => setIsModifying(true)} />
    ));

  const hasCompletedAccreditation =
    (isIndividualUser && actor.accreditationAnswers?.length > 0) ||
    (!isIndividualUser &&
      (actor?.institution?.accreditationAnswers || []).length > 0);

  if (isModifying || !hasCompletedAccreditation) {
    const formId = `account-accreditation-form`;
    return (
      <AccreditationForm
        formId={formId}
        accreditationLastUpdatedAt={accreditationLastUpdatedAt}
        onSuccess={() => setIsModifying(false)}
      />
    );
  }

  return (
    <>
      {accreditationBody}
      {!isNil(accreditationLastUpdatedAt) && (
        <CardFooter>
          {t(`submitted_on`, {
            date: `${dayjs(accreditationLastUpdatedAt).format(
              `Do MMM YYYY`,
            )} (${dayjs(accreditationLastUpdatedAt).format(`hh:mm A z`)})`,
          })}
        </CardFooter>
      )}
    </>
  );
};

export default AccreditationCardContent;
