import { Trans, useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Link, ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PleaseNote,
  WithQuery,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { BidHighFeesWarning } from "@/components/postings";
import {
  AcceptBidModalBidFragment,
  AcceptBidModalCompanyFragment,
  AcceptBidMutation,
  BidState,
  UserWithInstitutionFragment,
  useAcceptBidModalCompanyByIdQuery,
} from "@/gql";
import { useModal } from "@/hooks";
import {
  abbrCountLabel,
  bidLabel,
  countLabel,
  constants,
  formatPricePerShare,
  formatShares,
  getBidNumSharesActual,
  getAreFeesHighForBid,
  isEitherFund,
  isOtherMethod,
  getLongDocumentTitleByTransferMethod,
  makeUrl,
  getIsUnaccreditedSeller,
  appendSellerCompanyIdToUrl,
} from "@/utils";

import { useAcceptBidSequenceModalStepFormContext } from "./AcceptBidSequenceModalStepFormContext";
import { StepKeys } from "./steps";

const ResidualListingWarning = () => (
  <PleaseNote>
    <Text textStyle="deprecated-heading-lg">
      By accepting this bid, the remaining shares in your listing will drop
      below our minimum of {constants.min_listing_size.text} and will no longer
      be listed for sale.
    </Text>
  </PleaseNote>
);

const RejectExcessBidsWarning = () => (
  <PleaseNote>
    <Text textStyle="deprecated-heading-lg">
      After you accept this bid, some other bids will be automatically rejected
      as they cannot be fulfilled with the remaining shares in your listing.
    </Text>
  </PleaseNote>
);

interface AcceptBidConfirmationModalProps
  extends StepPropsV2<StepKeys, Record<string, never>> {
  readonly bid: AcceptBidModalBidFragment;
  readonly actor: UserWithInstitutionFragment;
}

const AcceptBidConfirmationModalContent = ({
  bid,
  company,
  isSubmitting,
  actor,
  stepRouter: { stepControls },
}: AcceptBidConfirmationModalProps & {
  readonly company: AcceptBidModalCompanyFragment;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const router = useRouter();

  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
  const documentTitle = getLongDocumentTitleByTransferMethod(
    bid.listing.transferMethodV2,
  );

  const numShares = formatShares(getBidNumSharesActual(bid));
  const pricePerShare = formatPricePerShare(bid.pricePerShare);
  const listingNumSharesAfter =
    bid.listing.numSharesAvailable - bid.numSharesActual;

  const useLOITerms = !isEitherFund(bid.listing) && !isOtherMethod(bid.listing);
  const bidType = bidLabel(bid.listing).toLowerCase();
  const share = countLabel(bid.listing);
  const abbrShare = abbrCountLabel(bid.listing);
  const textKey = isOtherMethod(bid.listing)
    ? `accept_bid_terms_and_conditions`
    : `seller_accept_bid_terms_and_conditions`;

  const willRejectExcessBids = bid.listing.bids
    .filter(
      (otherBid) =>
        otherBid.id !== bid.id && otherBid.state === BidState.Active,
    )
    .some((otherBid) => otherBid.numShares > listingNumSharesAfter);

  const { submitMutation } = useAcceptBidSequenceModalStepFormContext();

  const onClickSubmit = () =>
    submitMutation().then((response: AcceptBidMutation) => {
      const transaction = response.acceptBid.bid?.transaction;

      if (!transaction) return;

      const nextUrl = isUnaccreditedSeller
        ? appendSellerCompanyIdToUrl(makeUrl(transaction), company.id)
        : makeUrl(transaction);

      router.push(nextUrl);
      stepControls.nextStep();
    });

  const isHighFeesWarningVisible = getAreFeesHighForBid({
    pricePerShare: bid.pricePerShare,
    numberOfShares: getBidNumSharesActual(bid),
  });

  return (
    <>
      <HiiveModalHeader>
        {t(`are_you_sure_accept`, { bidType })}
      </HiiveModalHeader>
      <ModalBody>
        <VStack align="flex-start" spacing={3}>
          <Text textStyle="heading-lg">
            {`${bid.displayId}: ${numShares} ${share}s @ ${pricePerShare}/${abbrShare}`}
          </Text>
          {isHighFeesWarningVisible && <BidHighFeesWarning bid={bid} />}
          {willRejectExcessBids && <RejectExcessBidsWarning />}
          {bid.listingBelowMinLotOnAcceptance && <ResidualListingWarning />}
          <Text>
            <Trans
              i18nKey={textKey}
              components={{
                italic: (
                  <Link
                    key="terms-and-conditions"
                    href={constants.t_and_c_url}
                    target="_blank"
                    textDecorationLine="underline"
                  />
                ),
              }}
            />
          </Text>
          {useLOITerms && (
            <Text>
              {t(`loi_terms`, { documentTitle, buyerOrSeller: `buyer` })}
            </Text>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[AcceptBidConfirmation/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveSubmitButton
          sentryLabel="[AcceptBidConfirmation/Submit]"
          isLoading={isSubmitting}
          submitText={t(`yes_accept_bid`, { bidType })}
          onClick={onClickSubmit}
        />
      </HiiveModalFooter>
    </>
  );
};

const AcceptBidConfirmationModal = ({
  bid,
  ...props
}: AcceptBidConfirmationModalProps) => {
  const query = useAcceptBidModalCompanyByIdQuery({
    variables: { id: bid.company.id },
  });

  return (
    <WithQuery query={query}>
      {({ data: { companyById: company } }) => (
        <AcceptBidConfirmationModalContent
          company={company}
          bid={bid}
          {...props}
        />
      )}
    </WithQuery>
  );
};

export default withCurrentActor(AcceptBidConfirmationModal);
