import EntityCard, { EntityCardProps, EntityCardTypes } from "./EntityCard";
import { EntityItem } from "./EntityItem";
import { InternalEntityCard } from "./InternalEntityCard";

type BuyerEntityCardProps = Omit<EntityCardProps, "children" | "type">;

const BuyerEntityCard = ({ transaction, ...props }: BuyerEntityCardProps) => {
  const entity = transaction?.buyerEntity;
  const hasEntity = !!entity;
  const isHiiveUser = transaction?.buyer.isHiiveUser;

  return isHiiveUser ? (
    <InternalEntityCard
      entity={entity}
      transactionId={transaction.id}
      type={EntityCardTypes.Buyer}
    />
  ) : (
    <EntityCard
      type={EntityCardTypes.Buyer}
      transaction={transaction}
      {...props}
    >
      {hasEntity && (
        <EntityItem
          entity={entity}
          showDate={false}
          showRemoveButton={false}
          showStatus={false}
        />
      )}
    </EntityCard>
  );
};

export default BuyerEntityCard;
