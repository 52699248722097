import isNil from "lodash/isNil";

import { Text } from "@chakra-ui/react";

import { SensitiveText } from "@/components/common";
import { Maybe } from "@/types";
import { formatPricePerShare } from "@/utils";

const ShareDetailsPricePerShareStat = ({
  pricePerShare,
  shareTextAbbreviation = `sh`,
  strikethrough = false,
}: {
  readonly pricePerShare: Maybe<number> | undefined;
  readonly shareTextAbbreviation?: Maybe<string> | undefined;
  readonly strikethrough?: boolean;
}) => {
  const pricePerShareText = !isNil(pricePerShare)
    ? `@ ${formatPricePerShare(pricePerShare)} /${shareTextAbbreviation}`
    : `-`;

  return (
    <Text
      as={strikethrough ? `del` : undefined}
      color={strikethrough ? `grey.200` : `grey.900`}
      flex="1"
      textAlign="center"
      alignSelf="flex-end"
      textStyle={{ base: `heading-lg`, md: `heading-3xl` }}
      whiteSpace="nowrap"
      data-testid="price-per-share"
    >
      <SensitiveText>{pricePerShareText}</SensitiveText>
    </Text>
  );
};

export default ShareDetailsPricePerShareStat;
