import { useTranslation } from "react-i18next";

import { ActivityCardPropertyPill } from "@/components/common";
import { StandingBidAcceptsTransferMethodsOptionsV2 } from "@/gql";
import { standingBidAcceptsTransferMethodOptionsToString } from "@/utils/translate";

const acceptsTransferMethodsToString = (
  acceptsShares: readonly StandingBidAcceptsTransferMethodsOptionsV2[],
): string =>
  acceptsShares
    .reduce((acc: readonly string[], tm) => {
      const str = standingBidAcceptsTransferMethodOptionsToString(tm);
      return acc.includes(str) ? acc : [str, ...acc];
    }, [])
    .join(`, `);

export const AcceptableTransferMethods = ({
  acceptsTransferMethods,
}: {
  readonly acceptsTransferMethods: readonly StandingBidAcceptsTransferMethodsOptionsV2[];
}) => {
  const acceptableTransferMethods = acceptsTransferMethodsToString(
    acceptsTransferMethods,
  );
  const { t } = useTranslation();

  return (
    <ActivityCardPropertyPill
      title={t`transfer_type`}
      value={acceptableTransferMethods}
    />
  );
};
