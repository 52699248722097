import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useFronteggMutation } from "./useFronteggMutation";

interface VerifyMfaRequestPayload {
  readonly token: string;
}

interface VerifyMfaResponse {
  readonly recoveryCode?: string;
}

const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/authenticator/enroll/verify`;

const useVerifyAppMfa = ({
  onSuccess,
}: {
  onSuccess?: (data: VerifyMfaResponse) => void;
}) => {
  const { t } = useTranslation();

  const { state, execute } = useFronteggMutation<
    VerifyMfaRequestPayload,
    VerifyMfaResponse
  >();

  const verifyMfa = useCallback(
    async (body: VerifyMfaRequestPayload) =>
      execute(API_URL, {
        body,
        onSuccess,
        fallbackErrorMessage: t(`incorrect_code`),
      }),
    [execute],
  );

  return [verifyMfa, state] as const;
};

export default useVerifyAppMfa;
