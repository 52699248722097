import { CheckCircle, SealCheck } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Text,
  Button,
  HStack,
  Circle,
  ModalCloseButton,
  ModalHeader,
} from "@chakra-ui/react";

import { HiiveModalFooter, PoweredByModernTreasury } from "@/components/common";
import { ExecutionCollectBankAccountTaskDataFragment } from "@/gql";
import { useColors } from "@/hooks";

import AccountCollectionContent from "./AccountCollectionContent";
import CountryCollectionContent from "./CountryCollectionContent";
import DisclaimerContent from "./DisclaimerContent";
import { CollectBankAccountSteps, Country } from "./types";

const VerifyDisclaimerContent = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation(`execution`);

  return (
    <>
      <ModalBody>
        <VStack spacing={4} py={4}>
          <Circle size="108" bgColor="grey.50">
            <SealCheck size={64} />
          </Circle>
          <Text textStyle="heading-3xl" color="grey.700">
            {t(`verify_your_account`)}!
          </Text>
          <Text textStyle="text-sm">{t(`verify_confirm_need`)}</Text>
          <Text textStyle="text-sm">{t(`verify_confirm_send_deposits`)}</Text>
          <Text textStyle="text-sm">{t(`verify_confirm_use_deposits`)}</Text>
          <PoweredByModernTreasury />
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Button variant="rounded-solid-grey" size="xl" onClick={onClose}>
          {t(`got_it`)}
        </Button>
      </HiiveModalFooter>
    </>
  );
};

const SuccessContent = ({
  setStep,
}: {
  setStep: (step: CollectBankAccountSteps) => void;
}) => {
  const { t } = useTranslation(`execution`);

  const [emerald600] = useColors([`emerald.600`]);

  return (
    <>
      <ModalBody>
        <VStack spacing={4} py={4}>
          <Circle size="108" bgColor="emerald.100">
            <CheckCircle size={64} weight="fill" color={emerald600} />
          </Circle>
          <Text textStyle="heading-3xl" color="grey.700">
            {t(`bank_account_added`)}!
          </Text>
          <Text textStyle="text-sm">{t(`bank_account_connected`)}</Text>
          <HStack>
            <Text
              textStyle="text-sm"
              textTransform="uppercase"
              fontWeight={500}
            >
              {t(`next_step`)}:
            </Text>
            <Text textStyle="text-sm">{t(`verify_your_bank_account`)}</Text>
          </HStack>
          <PoweredByModernTreasury />
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Button
          variant="rounded-solid-grey"
          size="xl"
          onClick={() => setStep(`verify_disclaimer`)}
        >
          {t(`next`)}
        </Button>
      </HiiveModalFooter>
    </>
  );
};

const CollectBankAccountModal = ({
  isOpen,
  onClose,
  entity,
  taskId,
}: {
  isOpen: boolean;
  onClose: () => void;
  entity: ExecutionCollectBankAccountTaskDataFragment["entity"];
  taskId: string;
}) => {
  const { t } = useTranslation(`execution`);

  const [step, setStep] = useState<CollectBankAccountSteps>(`intro_disclaimer`);
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [clientToken, setClientToken] = useState<string>();

  const handleClose = () => {
    setStep(`intro_disclaimer`);
    setSelectedCountry(undefined);
    setClientToken(undefined);
    onClose();
  };

  return (
    <Modal variant="texas" isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent w="2xl">
        <ModalHeader>
          <Text>{t(`add_bank_account`)}</Text>
        </ModalHeader>
        <ModalCloseButton />
        {match(step)
          .with(`intro_disclaimer`, () => (
            <DisclaimerContent onClose={handleClose} setStep={setStep} />
          ))
          .with(`country_collection`, () => (
            <CountryCollectionContent
              onClose={handleClose}
              setStep={setStep}
              entity={entity}
              taskId={taskId}
              setClientToken={setClientToken}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
          ))
          .with(`account_collection`, () => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {clientToken && (
                <AccountCollectionContent
                  clientToken={clientToken}
                  setStep={setStep}
                />
              )}
            </>
          ))
          .with(`collection_success`, () => (
            <SuccessContent setStep={setStep} />
          ))
          .with(`verify_disclaimer`, () => (
            <VerifyDisclaimerContent onClose={handleClose} />
          ))
          .exhaustive()}
      </ModalContent>
    </Modal>
  );
};

export default CollectBankAccountModal;
