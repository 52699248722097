import i18next from "i18next";
import { match } from "ts-pattern";

import { RoleType, UserRole } from "@/gql";

export function getInviteeRoleText(roles: (RoleType | UserRole)[]) {
  return roles
    .map((role) =>
      match(role)
        .with(RoleType.Admin, () => i18next.t(`admin`, { ns: `issuers` }))
        .with(RoleType.Viewer, () => i18next.t(`viewer`, { ns: `issuers` }))
        .otherwise(() => `-`),
    )
    .join(`, `);
}
