import { motion } from "framer-motion";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Fade,
  Flex,
  HStack,
  Show,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardFooterWrapper,
  ActivityCardFooterStatusWrapper,
  ActivityCardPlacedByHiive,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTimestamp,
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  WithQuery,
  HiiveButton,
  Skeleton,
  ActivityCardSkeleton,
  ActivityCardFooterCountsWrapper,
  ActivityCardNewBadge,
} from "@/components/common";
import { CompanyStandingBidCardTransferDetails } from "@/components/companies";
import { UnaccreditedSellerListForSaleSvg } from "@/components/svg";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import {
  PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompanyFragment,
  PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageListingFragment,
  PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragment,
  usePostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery,
} from "@/gql";
import {
  appendSellerCompanyIdToUrl,
  getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2,
  getNumOfShares,
} from "@/utils";

const StandingBidCardDetails = ({
  standingBid,
}: {
  readonly standingBid: PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragment;
}) => {
  const numShares = getNumOfShares(standingBid);

  return !isNil(numShares) ? (
    <HStack justifyContent="space-between" spacing={6}>
      <ActivityCardPricingWrapper>
        <ActivityCardPricingNumSharesAndPrice
          numShares={numShares}
          price={standingBid.pricePerShare}
        />
        <ActivityCardPricingTotal
          numShares={numShares}
          price={standingBid.pricePerShare}
        />
      </ActivityCardPricingWrapper>
      <CompanyStandingBidCardTransferDetails standingBid={standingBid} />
    </HStack>
  ) : null;
};

const StandingBidCard = ({
  standingBid,
  company,
}: {
  readonly standingBid: PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragment;
  readonly company: PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompanyFragment;
}) => {
  const { t } = useTranslation();
  const { getIsNewStandingBid } = useUnaccreditedSellerInlineNotifications();
  const isNewStandingBid = getIsNewStandingBid(standingBid);

  return (
    <ActivityCard
      href={appendSellerCompanyIdToUrl(
        `/standing-bids/${standingBid.id}`,
        company.id,
      )}
    >
      <CardHeader>
        <HStack justifyContent="space-between">
          <HStack>
            <ActivityCardBadge variant="bid" title={t(`standing_bid`)} />
            <Text textStyle="text-lg">{standingBid.displayId}</Text>
          </HStack>
          {standingBid.fromHiive && <ActivityCardPlacedByHiive />}
        </HStack>
      </CardHeader>
      <CardBody>
        <StandingBidCardDetails standingBid={standingBid} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            <ActivityCardTimestamp
              title={t(`placed`)}
              date={standingBid.placedAt}
            />
            {!!standingBid.expireAt && (
              <ActivityCardTimestamp
                title={t(`expires`)}
                date={standingBid.expireAt}
              />
            )}
          </ActivityCardFooterStatusWrapper>
          {isNewStandingBid && (
            <ActivityCardFooterCountsWrapper>
              <Fade in>
                <ActivityCardNewBadge />
              </Fade>
            </ActivityCardFooterCountsWrapper>
          )}
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};

const ListYourSharesCard = ({ id }: { id: string }) => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <Card w="full" overflow="hidden">
      <CardBody position="relative">
        <VStack spacing={8} w="full" alignItems="flex-start">
          <VStack alignItems="flex-start" h="full">
            <Text textStyle="heading-3xl">
              {t(`list_your_shares_for_sale`)}
            </Text>
            <Text textStyle="text-md" maxW={{ base: `unset`, xl: `50%` }}>
              {t(`list_your_shares_for_sale_description_alternative`)}
            </Text>
          </VStack>
          <HiiveButton
            variant="rounded-solid-salmon"
            size="xl"
            boxShadow="card"
            px={16}
            onClick={() => router.push(`/create-listing/${id}`)}
            sentryLabel="[UnaccreditedSellerDashboardPage/ListYourSharesCard] Create Listing"
          >
            {t(`create_listing`)}
          </HiiveButton>
        </VStack>
        <Show above="xl" ssr={false}>
          <Box
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Box
              position="absolute"
              zIndex="0"
              h="full"
              w="50%"
              right="0"
              top="0"
              preserveAspectRatio="xMinYMid slice"
              as={UnaccreditedSellerListForSaleSvg}
            />
          </Box>
        </Show>
      </CardBody>
    </Card>
  );
};

const StandingBidsWithDiscussionsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <ActivityGroupEmptyState message={t(`inquiries_on_standing_bids_empty`)} />
  );
};

const StandingBidsWithDiscussions = ({
  standingBidsWithDiscussions,
  company,
}: {
  readonly standingBidsWithDiscussions: readonly PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragment[];
  readonly company: PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompanyFragment;
}) => (
  <ActivityGroup emptyState={<StandingBidsWithDiscussionsEmptyState />}>
    {standingBidsWithDiscussions.map((standingBidWithDiscussion) => (
      <StandingBidCard
        key={standingBidWithDiscussion.id}
        standingBid={standingBidWithDiscussion}
        company={company}
      />
    ))}
  </ActivityGroup>
);

const AvailableStandingBidsEmptyState = ({
  company,
}: {
  readonly company: PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompanyFragment;
}) => {
  const { t } = useTranslation();
  return (
    <ActivityGroupEmptyState
      message={t(`no_standing_bids`, {
        companyName: company.name,
      })}
    />
  );
};

const AvailableStandingBids = ({
  availableStandingBids,
  company,
}: {
  readonly availableStandingBids: readonly PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragment[];
  readonly company: PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompanyFragment;
}) => (
  <ActivityGroup
    emptyState={<AvailableStandingBidsEmptyState company={company} />}
  >
    {availableStandingBids.map((availableStandingBid) => (
      <StandingBidCard
        key={availableStandingBid.id}
        standingBid={availableStandingBid}
        company={company}
      />
    ))}
  </ActivityGroup>
);

const StandingBidsPageContent = ({
  availableStandingBids,
  standingBidsWithDiscussions,
  company,
  listing,
}: {
  readonly availableStandingBids: readonly PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragment[];
  readonly standingBidsWithDiscussions: readonly PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragment[];
  readonly company: PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompanyFragment;
  readonly listing?: PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageListingFragment | null;
}) => {
  const { t } = useTranslation();
  const hasListYourSharesCard = !listing;

  return (
    <VStack
      spacing={8}
      pt={{ base: 4, lg: hasListYourSharesCard ? 0 : 8 }}
      transform={
        hasListYourSharesCard
          ? { base: `none`, lg: `translateY(-48px)` }
          : undefined
      }
    >
      {hasListYourSharesCard && (
        <Fade in>
          <ListYourSharesCard id={company.id} />
        </Fade>
      )}
      <ActivitySection>
        <VStack alignItems="flext-start" spacing={4}>
          <Text textStyle="heading-sm" textTransform="uppercase">
            {t(`inquiries_on_standing_bids`)}
          </Text>
          <Text textStyle="text-md">
            {t(`inquiries_on_standing_bids_description`)}
          </Text>
          <StandingBidsWithDiscussions
            standingBidsWithDiscussions={standingBidsWithDiscussions}
            company={company}
          />
        </VStack>
        <VStack alignItems="flex-start" spacing={4}>
          <Text textStyle="heading-sm" textTransform="uppercase">
            {t(`available_standing_bids`)}
          </Text>
          <Text textStyle="text-md">
            {t(`available_standing_bids_description`)}
          </Text>
          <AvailableStandingBids
            availableStandingBids={availableStandingBids}
            company={company}
          />
        </VStack>
      </ActivitySection>
    </VStack>
  );
};

const StandingBidsPageSkeleton = () => (
  <VStack spacing={8} w="full" pt={{ base: 4, lg: 8 }}>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" w="full" maxW="208px" />
      <Skeleton h="16px" maxW="75%" />
      <ActivityCardSkeleton includeBody />
    </Flex>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" w="full" maxW="196px" />
      <Skeleton h="16px" maxW="95%" />
      <ActivityCardSkeleton includeBody />
    </Flex>
  </VStack>
);

const StandingBidsPageV2 = ({ id }: { id: string }) => {
  const query =
    usePostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery({
      variables: { id },
    });
  const router = useRouter();
  return (
    <WithQuery query={query} fallback={<StandingBidsPageSkeleton />}>
      {({ data: { companyById: company } }) => {
        const { activity } = company;
        const {
          myListings,
          availableStandingBids,
          standingBidsWithDiscussions,
        } = activity;
        const listing = myListings[0] ?? null;

        if (
          !getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2({
            activity,
          }) &&
          !listing
        ) {
          router.replace(`/dashboard/${id}`);
          return null;
        }

        return (
          <StandingBidsPageContent
            company={company}
            listing={listing}
            availableStandingBids={availableStandingBids}
            standingBidsWithDiscussions={standingBidsWithDiscussions}
          />
        );
      }}
    </WithQuery>
  );
};

export default StandingBidsPageV2;
