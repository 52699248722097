import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  HStack,
  Image,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";

import { IssuerTransactionContextTransaction } from "./IssuerTransactionProvider";
import { IssuerTransactionsStageBadge } from "./IssuerTransactionsStageBadge";
import { ISSUER_TRANSACTION_TAB_INDEX } from "./util";

type IssuerTransactionPageMainPanelHeaderProps = {
  transaction: NonNullable<IssuerTransactionContextTransaction>;
};

export const IssuerTransactionPageMainPanelHeader = ({
  transaction,
}: IssuerTransactionPageMainPanelHeaderProps) => {
  const { asPath, push, query } = useRouter();
  const { t } = useTranslation(`issuers`);

  const { bid, company, issuerStage } = transaction || {};
  const { displayId } = bid || {};
  const { logoUrl, name = `` } = company || {};

  const { id: transactionId } = query;

  const handleTabChange = useCallback(
    (index: number) => {
      const routes: Record<number, string> = {
        [ISSUER_TRANSACTION_TAB_INDEX.DETAILS]: `/issuer/transactions/${transactionId}/details`,
        [ISSUER_TRANSACTION_TAB_INDEX.STATUS]: `/issuer/transactions/${transactionId}/status`,
        [ISSUER_TRANSACTION_TAB_INDEX.DOCUMENTS]: `/issuer/transactions/${transactionId}/documents`,
      };

      const route = routes[index];
      if (route) {
        push(route);
      }
    },
    [push],
  );

  const getDefaultTabIndex = () =>
    match(asPath)
      .when(
        (path) => path.endsWith(`details`),
        () => ISSUER_TRANSACTION_TAB_INDEX.DETAILS,
      )
      .when(
        (path) => path.endsWith(`status`),
        () => ISSUER_TRANSACTION_TAB_INDEX.STATUS,
      )
      .when(
        (path) => path.endsWith(`documents`),
        () => ISSUER_TRANSACTION_TAB_INDEX.DOCUMENTS,
      )
      .otherwise(() => 0);

  return (
    <Card w="full" overflow="hidden" variant="issuer">
      <VStack px={6} py={6} alignItems="flex-start">
        <HStack justifyContent="space-between" w="full">
          <Text textStyle="heading-4xl">{displayId}</Text>
          <Box h={10} w={10}>
            {logoUrl && (
              <Image
                alt={name}
                height={10}
                src={logoUrl}
                objectFit="contain"
                width={10}
              />
            )}
          </Box>
        </HStack>
        {issuerStage && <IssuerTransactionsStageBadge stage={issuerStage} />}
      </VStack>
      <Tabs
        backgroundColor="grey.15"
        variant="black"
        defaultIndex={getDefaultTabIndex()}
        onChange={handleTabChange}
      >
        <HStack
          alignItems="center"
          justifyContent="space-between"
          borderTopWidth="1px"
          borderTop="light-grey-border"
        >
          <TabList alignItems="center" overflowY="scroll">
            <Tab px={7} py={5}>
              {t(`details`)}
            </Tab>
            <Tab px={7} py={5}>
              {t(`status`)}
            </Tab>
            <Tab px={7} py={5}>
              {t(`documents`)}
            </Tab>
          </TabList>
        </HStack>
      </Tabs>
    </Card>
  );
};
