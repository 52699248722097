import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import {
  IssuerInvitationInviteeFragment,
  IssuerInvitationUsersDocument,
  useChangeUserActiveStatusMutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";

type IssuersActivateUserModalProps = {
  invitee: IssuerInvitationInviteeFragment;
};

export function IssuersActivateUserModal({
  invitee: { id: userId, name, email },
}: IssuersActivateUserModalProps) {
  const { t } = useTranslation(`issuers`);
  const { closeModal } = useModal();
  const { successToast } = useCustomToast();

  const [activateIssuerUser, { loading }] = useChangeUserActiveStatusMutation({
    refetchQueries: [IssuerInvitationUsersDocument],
  });

  const onSubmit = useCallback(async () => {
    const { data } = await activateIssuerUser({
      variables: { userId, active: true },
    });

    if (data?.changeUserActiveStatus?.user) {
      closeModal();
      successToast(t(`activate_user_success`));
    }
  }, [userId]);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`activate_user_question`)}</Text>
      </HiiveModalHeader>
      <ModalBody data-dd-privacy="mask">
        <VStack alignItems="flex-start" gap={4}>
          <Text>{t(`activate_user_description`)}</Text>
          <Text>{t(`activate_user_confirmation`, { name, email })}</Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            sentryLabel="[IssuersActivateUser/Submit]"
            submitText={t(`confirm`)}
            onClick={onSubmit}
            isLoading={loading}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
}
