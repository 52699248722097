import { useEffect, useState } from "react";

import { Text } from "@chakra-ui/react";

import { CompanyCombobox } from "@/components/companies";
import { Combobox } from "@/components/form";
import {
  CompanyWatchlistCompanyFragment,
  ListCompaniesOrderBy,
  useWatchlistComboboxListCompaniesLazyQuery,
  WatchlistComboboxCompanyFragment,
} from "@/gql";
import { useCombobox, useDebounce } from "@/hooks";

const getVariables = (searchText: string) => ({
  first: 20,
  orderBy: ListCompaniesOrderBy.MarketActivity,
  searchText,
});

const WatchlistCombobox = ({
  watchlist,
  onSelectCompany,
}: {
  readonly watchlist: readonly CompanyWatchlistCompanyFragment[];
  readonly onSelectCompany: (companyId: string) => void;
}) => {
  const { debounce, isDebouncing } = useDebounce();

  const [search, setSearch] = useState<string>(``);

  const [loadCompanies, { data, loading }] =
    useWatchlistComboboxListCompaniesLazyQuery({
      fetchPolicy: `no-cache`,
    });

  useEffect(() => {
    loadCompanies({
      variables: getVariables(``),
    });
  }, []);

  const handleChangeSearch = (search: string) => {
    setSearch(search);
    debounce(() =>
      loadCompanies({
        variables: getVariables(search),
      }),
    );
  };

  const items = !!data?.listCompanies?.edges
    ? data?.listCompanies?.edges?.flatMap((edge) => {
        if (
          !edge ||
          !edge.node ||
          watchlist.map(({ id }) => id).includes(edge.node.id)
        )
          return [];
        return [edge.node];
      })
    : [];

  const isLoading = loading || isDebouncing;

  const { inputProps, menuProps, labelProps, itemProps, actions } =
    useCombobox<WatchlistComboboxCompanyFragment>({
      items,
      itemToString: (item) => item.name,
      getItemKey: (item) => item.id,
      onSelectItem: (item) => {
        if (!item) return;
        actions.blur();
        onSelectCompany(item.id);
        handleChangeSearch(``);
      },
      onChangeInputValue: handleChangeSearch,
      inputValue: search,
      isLoading,
    });

  return (
    <>
      <Combobox.Label srOnly {...labelProps}>
        Search Companies
      </Combobox.Label>
      <Combobox.Container>
        <Combobox.Input placeholder="Search companies" {...inputProps} />
        <Combobox.Menu
          isLoading={isLoading}
          fallback={<CompanyCombobox.Skeleton />}
          maxH={242}
          isLazy={false}
          {...menuProps}
        >
          {items.map((item, index) => (
            <CompanyCombobox.Item
              key={item.id}
              item={item}
              index={index}
              {...itemProps}
            >
              <CompanyCombobox.ItemIcon company={item} />
              <Text as="span">{item.name}</Text>
            </CompanyCombobox.Item>
          ))}
        </Combobox.Menu>
      </Combobox.Container>
    </>
  );
};

export default WatchlistCombobox;
