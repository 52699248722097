import { useTranslation } from "react-i18next";

import { Flex, Text } from "@chakra-ui/react";

import { FocusedShareDetails } from "@/components/common";
import {
  BidInfoCardStatusTile,
  BidBuyerActionsTile,
  BidInfoCardBidShareDetails,
  PlacedByHiive,
} from "@/components/postings";
import { BidState, BuyerBidInfoCardBidFragment } from "@/gql";
import { getAvailableBidActions, bidLabel } from "@/utils";

const BuyerBidInfoCard = ({
  bid,
}: {
  readonly bid: BuyerBidInfoCardBidFragment;
}) => {
  const { t } = useTranslation();
  const placedAt = bid.counteredAt || bid.expireAt;
  const hasCounterBid = bid.state === BidState.Countered;
  const { company } = bid.listing;
  const showPlacedByHiive = bid.fromHiive;
  const { canActOnBidAsBuyer } = getAvailableBidActions(bid);
  const label = bidLabel(bid.listing);

  return (
    <Flex direction="column" gap={7}>
      <Flex direction="column" gap={7} data-testid="bid-info-card">
        {hasCounterBid && (
          <Text textStyle="heading-sm">{t`bid_info_original_bid`}</Text>
        )}
        <FocusedShareDetails.Card variant="bid">
          <FocusedShareDetails.Header
            title={`${label} ${bid.displayId}`}
            company={company}
          >
            <BidInfoCardBidShareDetails bid={bid} />
          </FocusedShareDetails.Header>
          <FocusedShareDetails.Content>
            <FocusedShareDetails.ContentSection>
              <Flex direction="column" gap={6}>
                {canActOnBidAsBuyer && <BidBuyerActionsTile bid={bid} />}
                {placedAt && (
                  <BidInfoCardStatusTile
                    bid={bid}
                    showCounterBidDetails={false}
                  />
                )}
              </Flex>
            </FocusedShareDetails.ContentSection>
            {showPlacedByHiive && (
              <FocusedShareDetails.ContentSection p={{ base: 4, md: 6 }} pb={5}>
                <PlacedByHiive bid={bid} />
              </FocusedShareDetails.ContentSection>
            )}
          </FocusedShareDetails.Content>
        </FocusedShareDetails.Card>
      </Flex>
    </Flex>
  );
};

export default BuyerBidInfoCard;
