import en from "./en";
import account from "./en/account";
import company from "./en/company";
import dashboard from "./en/dashboard";
import error from "./en/error";
import execution from "./en/execution";
import issuers from "./en/issuers";
import onboarding from "./en/onboarding";
import postings from "./en/postings";
import transactions from "./en/transactions";
import { LocaleKey, TranslationKey, Resources } from "./types";

export const locales: Record<LocaleKey, Resources> = {
  en: {
    account,
    dashboard,
    error,
    execution,
    issuers,
    onboarding,
    postings,
    translation: en,
    transactions,
    company,
  },
};

export type { TranslationKey, Resources };
