import { useTranslation } from "react-i18next";

import { SecurityCard } from "@/components/account";
import {
  IssuerPageHeader,
  IssuerPortalPageContainer,
} from "@/components/issuers";

import { AccountSettingsTabs } from "./AccountSettingsTabs";

const AccountSecurityPage = () => {
  const { t } = useTranslation();

  return (
    <IssuerPortalPageContainer
      header={
        <>
          <IssuerPageHeader title={t(`account_settings`)} />
          <AccountSettingsTabs />
        </>
      }
      content={<SecurityCard />}
    />
  );
};

export default AccountSecurityPage;
