import { useTranslation } from "react-i18next";

import { Box, Card, CardBody, HStack, Text, VStack } from "@chakra-ui/react";

import { InvitationUserTableData, getInviteeRoleText } from "@/utils";
import * as datetime from "@/utils/datetime";

import { InvitationMobileActionButton } from "./IssuerManageUsersActionButton";
import { IssuerManageUsersStatusBadge } from "./IssuerManageUsersStatusBadge";

type IssuerManageUsersCardProps = {
  invitation: InvitationUserTableData;
};

export function IssuerManageUsersCard({
  invitation: [originalInvitation, { status }],
}: IssuerManageUsersCardProps) {
  const {
    invitee,
    roles: invitationRoles,
    email,
    insertedAt,
  } = originalInvitation;
  const { t } = useTranslation(`issuers`);
  const inviteeRoleText = getInviteeRoleText(invitee?.roles ?? invitationRoles);

  return (
    <Card w="full" boxShadow="none" borderColor="grey.200" borderRadius="none">
      <CardBody>
        <VStack alignItems="flex-start" gap={2}>
          <VStack alignItems="flex-start" gap={1} w="full">
            <HStack
              alignItems="flex-start"
              justifyContent="center"
              pb={2}
              w="full"
            >
              <IssuerManageUsersStatusBadge status={status} />
              <Box flexGrow={1} />
              <InvitationMobileActionButton invitation={originalInvitation} />
            </HStack>
            <HStack w="full">
              <Text fontSize={14}>{invitee?.name ?? `–`}</Text>
            </HStack>
            <HStack w="full">
              <Text fontSize={14} w="full" overflowX="hidden">
                {invitee?.jobTitle ?? `–`}
              </Text>
              <Box flexGrow={1} />
              <Text fontSize={14}>{inviteeRoleText}</Text>
            </HStack>
          </VStack>
          <HStack w="full">
            <Text
              fontSize={12}
              color="grey.500"
              maxW={40}
              overflowX="hidden"
              whiteSpace="no-wrap"
            >
              {email}
            </Text>
            <Box flexGrow={1} />
            <Text fontSize={12} color="grey.500" textAlign="right">
              {t(`added_on`)} {datetime.format(`MMM D, YYYY`, insertedAt)}
            </Text>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
}
