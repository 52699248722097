import { MagnifyingGlass } from "@phosphor-icons/react";
import isNil from "lodash/isNil";
import { useState } from "react";

import {
  FormControl,
  FormErrorMessage,
  FormLabelProps,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

import { Combobox, ItemList } from "@/components/form";
import { useColors, useCombobox, useItemField } from "@/hooks";

const FormCombobox = <TItem,>({
  placeholder,
  getItems,
  name,
  onChangeSearch,
  itemToString,
  getItemKey,
  getItemDisabled = () => false,
  label,
  description,
  isLoading,
  isDisabled,
  labelSrOnly,
  showSearchIcon = false,
}: {
  readonly placeholder: string;
  readonly getItems: ({
    search,
  }: {
    readonly search: string;
  }) => ItemList<TItem>;
  readonly name: string;
  readonly onChangeSearch?: (search: string) => void;
  readonly itemToString: (item: TItem) => string;
  readonly getItemKey: (item: TItem) => string;
  readonly getItemDisabled?: (item: TItem) => boolean;
  readonly label: string;
  readonly description?: string;
  readonly isLoading: boolean;
  readonly isDisabled?: boolean;
  readonly labelSrOnly?: FormLabelProps[`srOnly`];
  readonly showSearchIcon?: boolean;
}) => {
  const { error, isInvalid, selectedItem, setSelectedItem } =
    useItemField<TItem>(name);

  const [search, setSearch] = useState(
    !!selectedItem ? itemToString(selectedItem) : ``,
  );

  const handleChangeInputValue = (inputValue: string) => {
    if (!isNil(onChangeSearch)) onChangeSearch(inputValue);

    setSearch(inputValue);
  };

  const items = getItems({ search });

  const { inputProps, menuProps, labelProps, itemProps } = useCombobox<TItem>({
    items,
    itemToString,
    getItemKey,
    selectedItem,
    onSelectItem: (item: TItem | null) => {
      setSelectedItem(item);
      if (!isNil(item)) handleChangeInputValue(itemToString(item));
    },
    inputValue: search,
    onChangeInputValue: handleChangeInputValue,
    isLoading,
  });

  const [grey500] = useColors([`grey.500`]);

  return (
    <FormControl id={name} isInvalid={isInvalid}>
      <Combobox.Label srOnly={labelSrOnly} {...labelProps}>
        {label}
      </Combobox.Label>
      {description && <Combobox.Description description={description} />}
      <Combobox.Container>
        <InputGroup>
          {showSearchIcon && (
            <InputLeftElement pointerEvents="none">
              <MagnifyingGlass
                size={20}
                color={grey500}
                opacity={isDisabled ? `.5` : ``}
              />
            </InputLeftElement>
          )}
          <Combobox.Input
            isDisabled={isDisabled}
            name={name}
            placeholder={placeholder}
            {...inputProps}
          />
        </InputGroup>
        <Combobox.Menu isLoading={isLoading} {...menuProps}>
          {items.map((item, index) => (
            <Combobox.Item
              key={getItemKey(item)}
              isDisabled={getItemDisabled(item)}
              item={item}
              index={index}
              {...itemProps}
            >
              {itemToString(item)}
            </Combobox.Item>
          ))}
        </Combobox.Menu>
      </Combobox.Container>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default FormCombobox;
