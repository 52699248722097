import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Tabs, HStack, TabList, Tab } from "@chakra-ui/react";

import { TAB_MARGIN_OFFSET } from "@/components/issuers";
import { useIsDesktop } from "@/hooks";
import { ROUTES } from "@/utils";

const COMPANY_INFO_TABS = [ROUTES.ISSUER_PROFILE, ROUTES.ISSUER_TEMPLATES];

export const CompanyInfoTabs = () => {
  const { t } = useTranslation(`issuers`);
  const { push, pathname } = useRouter();
  const isDesktop = useIsDesktop();

  const tabIndex = COMPANY_INFO_TABS.indexOf(pathname) ?? 0;

  const handleTabsChange = (index: number) => {
    const route = COMPANY_INFO_TABS[index];
    if (route && pathname !== route) {
      push(route);
    }
  };

  return (
    <Tabs
      variant="black"
      mx={TAB_MARGIN_OFFSET}
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <TabList alignItems="center" pb={0}>
          <Tab py={4} borderTopRadius={8} fontSize={isDesktop ? `unset` : 14}>
            {t(`profile`)}
          </Tab>
          <Tab py={4} borderTopRadius={8}>
            {t(`templates`)}
          </Tab>
        </TabList>
      </HStack>
    </Tabs>
  );
};
