import { match } from "ts-pattern";

import { InvestorType, UserWithInstitutionFragment } from "@/gql";

import { useIsHiiveIssuerPortal } from "./issuer/useIsHiiveIssuerPortal";
import useIsHiiveConnect from "./useIsHiiveConnect";

export function useIsOnCorrectPortal() {
  const isHiiveConnect = useIsHiiveConnect();
  const isIssuerPortal = useIsHiiveIssuerPortal();

  return ({ investorType }: UserWithInstitutionFragment) =>
    match(investorType)
      .with(InvestorType.Broker, () => isHiiveConnect)
      .with(InvestorType.Issuer, () => isIssuerPortal)
      .otherwise(() => !(isHiiveConnect || isIssuerPortal));
}
