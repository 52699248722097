import { DotsThreeOutlineVertical } from "@phosphor-icons/react";

import { Box, MenuButton, MenuButtonProps } from "@chakra-ui/react";

export function TableActionButton(props: MenuButtonProps) {
  return (
    <MenuButton
      width={8}
      height={8}
      borderRadius={6}
      borderColor="grey.100"
      borderStyle="solid"
      borderWidth={1}
      p={2}
      m={0}
      _hover={{
        bg: `grey.50`,
      }}
      {...props}
    >
      <Box as={DotsThreeOutlineVertical} weight="fill" size={14} width="full" />
    </MenuButton>
  );
}
