import { ChartAnnouncement } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { getIsUnaccreditedSeller } from "@/utils";

import { CompanyPageContent } from "./CompanyPageContent";

interface CompanyPageProps {
  readonly id: string;
  readonly actor: UserWithInstitutionFragment;
}

const CompanyPage = withCurrentActor(({ id, actor }: CompanyPageProps) => {
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  return (
    <>
      {!isUnaccreditedSeller && <ChartAnnouncement />}
      <CompanyPageContent companyId={id} />
    </>
  );
});

export default CompanyPage;
