import isNil from "lodash/isNil";

import { Card, GridItem, SimpleGrid, Text } from "@chakra-ui/react";

import {
  FocusedShareDetails,
  ListingNumShareAndPriceDetails,
  MarkdownDocument,
} from "@/components/common";
import {
  AcceptedBidSharePriceDetails,
  ListingSellerRoundingDisclaimer,
} from "@/components/postings";
import { PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragment } from "@/gql";
import { transferMethodToString } from "@/utils";

interface ListingInfoCardProps {
  readonly listing: PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragment;
}

export const ListingInfoCard = ({ listing }: ListingInfoCardProps) => {
  const { acceptedBidPrices, otherDetails, transferMethodV2 } = listing;

  const hasAcceptedBidPrices = acceptedBidPrices.length >= 1;

  return (
    <Card w="full" variant="no-border">
      <FocusedShareDetails.Header title={`Listing ${listing.displayId}`}>
        <FocusedShareDetails.HeaderCard>
          <ListingNumShareAndPriceDetails listing={listing} />
          {hasAcceptedBidPrices && (
            <AcceptedBidSharePriceDetails listing={listing} />
          )}
        </FocusedShareDetails.HeaderCard>
        <ListingSellerRoundingDisclaimer />
      </FocusedShareDetails.Header>
      <FocusedShareDetails.Content>
        <FocusedShareDetails.ContentSection>
          <SimpleGrid
            alignItems="baseline"
            columnGap={6}
            gridTemplateColumns="auto 1fr"
            rowGap={6}
          >
            <GridItem>
              <Text textStyle="heading-sm">Transfer Type</Text>
            </GridItem>
            <GridItem>
              <Text data-testid="transfer-type">
                {transferMethodToString(transferMethodV2)}
              </Text>
            </GridItem>
            {!isNil(otherDetails) && (
              <>
                <GridItem>
                  <Text textStyle="heading-sm">Listing Notes</Text>
                </GridItem>
                <GridItem>
                  <Text data-testid="listing-notes">
                    <div className="listing-details-md">
                      <MarkdownDocument markdown={otherDetails} />
                    </div>
                  </Text>
                </GridItem>
              </>
            )}
          </SimpleGrid>
        </FocusedShareDetails.ContentSection>
      </FocusedShareDetails.Content>
    </Card>
  );
};
