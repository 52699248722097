import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { reset as resetAuth } from "@/state/auth";

import { useToken } from "./useToken";

const useClearAuth = () => {
  const token = useToken();
  const dispatch = useDispatch();
  const client = useApolloClient();
  useEffect(() => {
    if (!token) return;
    // Clearing Apollo Client cache and redux persist on mount
    dispatch(resetAuth());
    client.clearStore();
  }, []);
};

export default useClearAuth;
