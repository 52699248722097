import { capitalCase } from "change-case";
import { useTranslation } from "react-i18next";

import { Text, Box, Flex, FlexProps, VStack, HStack } from "@chakra-ui/react";

import { InternalLink, NextImage } from "@/components/common";
import { useCurrentIssuerActor, useSignOut } from "@/hooks";
import { ROUTES } from "@/utils";

type IssuerMobileNavBarFooterProfileIconProps = {
  name: string;
  logoUrl: string;
} & FlexProps;

type IssuerMobileNavBarFooterProps = {
  onToggle: () => void;
};

function IssuerMobileNavBarFooterProfileIcon({
  name,
  logoUrl,
  ...restProps
}: IssuerMobileNavBarFooterProfileIconProps) {
  return (
    <Flex {...restProps}>
      <NextImage
        alt={name}
        height={10}
        src={logoUrl}
        objectFit="contain"
        width={10}
      />
    </Flex>
  );
}

type IssuerMobileNavBarContentLinkType = {
  children: string;
  href: string;
} & IssuerMobileNavBarFooterProps;

function MobileFooterNavBarLink({
  children,
  href,
  onToggle,
}: IssuerMobileNavBarContentLinkType) {
  return (
    <InternalLink
      href={href}
      borderRadius={8}
      textDecor="none !important"
      w="full"
      textTransform="capitalize"
    >
      <Box
        color="grey.900"
        fontWeight="500"
        fontSize={16}
        py={3}
        px={5}
        onClick={onToggle}
      >
        {children}
      </Box>
    </InternalLink>
  );
}

function IssuerMobileNavBarFooterProfile({
  onToggle,
}: IssuerMobileNavBarFooterProps) {
  const { name, roles, issuer } = useCurrentIssuerActor();

  const formattedRoles = roles.map((role) => capitalCase(role)).join(`, `);

  const { t } = useTranslation();
  const signOut = useSignOut();

  const handleLogoutClick = () => {
    signOut();
    onToggle();
  };

  return (
    <Box
      borderColor="grey.100"
      borderStyle="solid"
      borderTopWidth={0.5}
      w="full"
      pb={3}
    >
      <VStack w="full" align="left">
        <HStack px={5} pt={6} pb={3}>
          {issuer?.logoUrl && (
            <IssuerMobileNavBarFooterProfileIcon
              name={issuer.name}
              logoUrl={issuer.logoUrl}
            />
          )}
          <VStack align="start" gap={0}>
            <Text textStyle="heading-md">{name}</Text>
            <Text textStyle="text-xs">{formattedRoles}</Text>
          </VStack>
        </HStack>
        <VStack align="start">
          <MobileFooterNavBarLink
            href={ROUTES.ISSUER_ACCOUNT_DETAILS}
            onToggle={onToggle}
          >
            {t(`account_settings`)}
          </MobileFooterNavBarLink>
          <Text
            textStyle="heading-md"
            onClick={() => handleLogoutClick()}
            py={3}
            px={5}
          >
            {t(`log_out`)}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
}

export function IssuerMobileNavFooter({
  onToggle,
}: IssuerMobileNavBarFooterProps) {
  return <IssuerMobileNavBarFooterProfile onToggle={onToggle} />;
}
