import { useApolloClient } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { useAuthUserOrNull, useLoginActions } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useCallback, useContext } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";

import { useRouter } from "next/router";

import { FrontChatContext } from "@/components/providers";
import { useMarketplaceLogin } from "@/hooks/featureFlags";
import { reset as resetAuth } from "@/state/auth";
import { reset as resetPersona } from "@/state/persona";
import { ROUTES } from "@/utils";

export default function useSignOut() {
  const dispatch = useDispatch();
  const router = useRouter();
  const client = useApolloClient();
  const ldClient = useLDClient();
  const fronteggUser = useAuthUserOrNull();
  const { logout } = useLoginActions();
  const marketplaceLoginEnabled = useMarketplaceLogin();
  const { ensureFrontChatIsShutdown } = useContext(FrontChatContext);

  return useCallback(
    (signOutTo?: string) => {
      if (ensureFrontChatIsShutdown) {
        ensureFrontChatIsShutdown();
      }
      const loginPageUrl = marketplaceLoginEnabled
        ? ROUTES.FRONTEGG_LOGIN
        : ROUTES.DEFAULT_LOGIN;

      const path = signOutTo || loginPageUrl;
      router.prefetch(path);

      ReactDOM.unstable_batchedUpdates(async () => {
        await client.clearStore();
        await ldClient?.identify({
          key: undefined,
          anonymous: true,
        });
        dispatch(resetAuth());
        dispatch(resetPersona());
        sessionStorage.clear();
        datadogRum.clearUser();
        Sentry.getCurrentScope().setUser(null);

        if (fronteggUser) {
          return logout(() => {
            router.push(path);
          });
        }

        return router.push(path);
      });
    },
    [dispatch, ensureFrontChatIsShutdown],
  );
}
