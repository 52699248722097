import { useEffect } from "react";

import { InvestorType } from "@/gql";
import { useCurrentActor, useLockBodyScroll, useTabRouter } from "@/hooks";
import { hasInvestorType } from "@/utils";

import { IndexTab } from "./IndexTab";
import { NavigationTab } from "./NavigationTab";
import { SearchTab } from "./SearchTab";
import { tabKeys, TabKeys } from "./tabs";

const MobileNavBar = () => {
  const tabRouter = useTabRouter<TabKeys>({
    initialTab: tabKeys.index,
  });
  const actor = useCurrentActor();
  const { onLock, onUnlock } = useLockBodyScroll();

  const isSeller = hasInvestorType(actor, InvestorType.Seller);
  const isTrader = hasInvestorType(actor, InvestorType.Trader);
  const isBroker = hasInvestorType(actor, InvestorType.Broker);

  const { tabsInfo } = tabRouter;

  useEffect(() => {
    if (tabsInfo.currentTabKey === tabKeys.navigation) {
      onLock();
    }

    onUnlock();
  }, [tabsInfo.currentTabKey]);

  return (
    <>
      {tabsInfo.currentTabKey === tabKeys.index && (
        <IndexTab actor={actor} tabRouter={tabRouter} />
      )}
      {tabsInfo.currentTabKey === tabKeys.search &&
        (isSeller || isTrader || isBroker) && (
          <SearchTab tabRouter={tabRouter} />
        )}
      {tabsInfo.currentTabKey === tabKeys.navigation && (
        <NavigationTab tabRouter={tabRouter} />
      )}
    </>
  );
};

export default MobileNavBar;
