import isEmpty from "lodash/isEmpty";

import {
  GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardCompanyActivityFragment,
  GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardMyActivityFragment,
  GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardMyCompanyActivityFragment,
} from "@/gql";

export const getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard = (
  {
    pendingSales,
    completedSales,
  }: GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardMyActivityFragment,
  {
    standingBidsWithDiscussions,
  }: GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardMyCompanyActivityFragment,
) =>
  !isEmpty(pendingSales) ||
  !isEmpty(completedSales) ||
  !isEmpty(standingBidsWithDiscussions);

export const getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2 =
  ({
    activity,
  }: GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardCompanyActivityFragment) =>
    !isEmpty(activity.myCompletedTransactions) ||
    !isEmpty(activity.myOpenTransactions) ||
    !isEmpty(activity.standingBidsWithDiscussions);
