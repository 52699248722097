import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { CompanySecuritySpecialistContactCompanyFragment } from "@/gql";
import { getSecuritySpecialistContact } from "@/utils";

const SecuritySpecialistLink = ({
  company,
}: {
  readonly company: CompanySecuritySpecialistContactCompanyFragment;
}) => {
  const { t } = useTranslation();

  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <Text as="span" fontWeight="medium">
      <MailtoLink
        email={securitySpecialistEmail}
        subject={t(`contact_company_security_specialist`, {
          companyName: company.name,
        })}
      >
        {t(`company_security_specialist`, { companyName: company.name })}
      </MailtoLink>
    </Text>
  );
};

export default SecuritySpecialistLink;
