import { Control, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Box, BoxProps, HStack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton, Skeleton } from "@/components/common";
import { FormRadioTile, FormTextAreaInput } from "@/components/react-hook-form";
import {
  AccreditationQuestion,
  Maybe,
  useAnswerInstitutionAccreditationQuestionsMutation,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { shadows } from "@/styles/theme";
import { CAOtherAccreditationCriteriaQuestionKey as OtherAccreditationCriteriaQuestionKey } from "@/utils/accreditation";

import { InstitutionAccreditationFormProps } from "./InstitutionAccreditationForm";
import { UseAccreditationFormProps } from "./InvestorAccreditationPageV2";

interface InstitutionAccreditationFormValues {
  readonly accreditationOptionKey?: string | null;
  readonly accreditationOptionText?: string | null;
}

export const useCADInstitutionAccreditationForm = ({
  accreditationQuestions,
  accreditationAnswers,
  onSuccess,
  mutationOptions,
}: UseAccreditationFormProps) => {
  const { t } = useTranslation();

  const mutation =
    useAnswerInstitutionAccreditationQuestionsMutation(mutationOptions);

  const validationSchema = Yup.object().shape({
    accreditationOptionKey: Yup.string()
      .oneOf(accreditationQuestions.map((q: AccreditationQuestion) => q.key))
      .required(t`required`),
    accreditationOptionText: Yup.string()
      .nullable()
      .when(`accreditationOptionKey`, {
        is: OtherAccreditationCriteriaQuestionKey,
        then: (schema) => schema.required(t`required`),
        otherwise: (schema) => schema.optional(),
      }),
  });

  const hasPreviousAnswers =
    !!accreditationAnswers && accreditationAnswers.length > 0;

  const initialValues = {
    accreditationOptionKey: hasPreviousAnswers
      ? accreditationAnswers[0].question.key
      : null,
    accreditationOptionText: hasPreviousAnswers
      ? accreditationAnswers[0].text
      : null,
  };

  const mapVariables = ({
    accreditationOptionKey,
    accreditationOptionText,
  }: InstitutionAccreditationFormValues) => ({
    input: {
      answers: [
        {
          key: accreditationOptionKey || ``,
          text:
            accreditationOptionKey === OtherAccreditationCriteriaQuestionKey
              ? accreditationOptionText
              : null,
        },
      ],
    },
  });

  return useFormQL({
    mutation,
    mode: `onChange`,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
  });
};

export const CADInstitutionAccreditationFormFields = ({
  control,
  watch,
  accreditationQuestions,
  ...boxProps
}: {
  readonly control: Control<InstitutionAccreditationFormValues>;
  readonly watch: UseFormWatch<InstitutionAccreditationFormValues>;
  readonly accreditationQuestions: Maybe<AccreditationQuestion>[];
} & BoxProps) => {
  const { t } = useTranslation();

  const accreditationOptionKey = watch(`accreditationOptionKey`);

  return (
    <FormRadioTile.Group
      size="md"
      p={{ base: 4, lg: 6 }}
      borderRadius="md"
      bg="white"
      borderColor="grey.200"
      borderWidth={0.5}
      boxShadow={shadows.card}
      gap={4}
      w={{ base: `full`, lg: 160 }}
      {...boxProps}
    >
      <VStack alignItems="flex-start" gap={4} py={2}>
        {accreditationQuestions.map((q: AccreditationQuestion) => (
          <FormRadioTile.Tile
            key={q.id}
            name="accreditationOptionKey"
            control={control}
            value={q.key}
            alignItems="flex-start"
          >
            <Box justifyContent="center" alignItems="flex-start">
              <Text>{q.text}</Text>
            </Box>
          </FormRadioTile.Tile>
        ))}
      </VStack>
      {accreditationOptionKey === OtherAccreditationCriteriaQuestionKey && (
        <VStack alignItems="flex-start" w="full">
          <Text fontWeight={500}>{t`please_describe`}</Text>
          <FormTextAreaInput
            w="full"
            control={control}
            name="accreditationOptionText"
            label=""
            placeholder={t`description`}
            labelSrOnly
          />
        </VStack>
      )}
    </FormRadioTile.Group>
  );
};

export const CADInstitutionAccreditationForm = ({
  questionGroup,
  onSuccess,
}: InstitutionAccreditationFormProps) => {
  const actor = useCurrentActor();
  const { t } = useTranslation();
  const accQuestions = questionGroup?.questions || [];

  const { handleSubmit, isLoading, control, formState, watch } =
    useCADInstitutionAccreditationForm({
      accreditationQuestions: accQuestions,
      accreditationAnswers: actor.institution?.accreditationAnswers,
      onSuccess,
    });

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      id="institution-accreditation-form"
    >
      <CADInstitutionAccreditationFormFields
        control={control}
        watch={watch}
        accreditationQuestions={accQuestions}
      />
      <HStack p={2} mt={6} justifyContent="flex-end" w="full">
        <HiiveButton
          sentryLabel="[InstitutionAccreditationPageV2/Submit]"
          variant="rounded-solid-salmon"
          type="submit"
          size="xl"
          maxW="unset"
          form="institution-accreditation-form"
          w={{ base: `full`, lg: `unset` }}
          isLoading={isLoading}
          onClick={handleSubmit}
          isDisabled={!formState?.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </HStack>
    </form>
  );
};

export const CADInstitutionAccreditationFormSkeleton = () => (
  <VStack w={{ base: `full`, lg: 160 }} gap={2}>
    <Skeleton h={100} />
    <Skeleton h={75} />
    <Skeleton h={110} />
    <Skeleton h={75} />
    <Skeleton h={75} />
    <Skeleton h={100} />
    <VStack alignItems="flex-end" w="full">
      <Skeleton h={10} w={155} />
    </VStack>
  </VStack>
);
