import { ShareDetailsDisclaimer } from "@/components/common";
import { BidRoundedSharesTooltip } from "@/components/tooltip";
import { formatShares } from "@/utils";

const BidSellerRoundingDisclaimer = ({
  numSharesOriginal,
  bidText,
}: {
  readonly numSharesOriginal: number;
  readonly bidText?: string;
}) => (
  <ShareDetailsDisclaimer tooltip={<BidRoundedSharesTooltip />}>
    of buyer&apos;s original {bidText || `bid`} of{` `}
    {formatShares(numSharesOriginal)} shares
  </ShareDetailsDisclaimer>
);

export default BidSellerRoundingDisclaimer;
