import { useTranslation } from "react-i18next";

import { Card, CardBody, CardHeader, Text } from "@chakra-ui/react";

import { VirtualAccount } from "@/gql";

import FundsReceipts from "./FundsReceipts";
import WireInformation from "./WireInformation";

type SettlementCardProps = {
  virtualAccount: VirtualAccount;
  wireAmount: number;
};

const SettlementCard = ({
  virtualAccount,
  wireAmount,
}: SettlementCardProps) => {
  const { t } = useTranslation(`transactions`);
  const hasFundsReceipts =
    virtualAccount?.fundsReceipts && virtualAccount?.fundsReceipts?.length > 0;
  const hasAccountNumber = !!virtualAccount?.accountNumber;

  return (
    <Card variant="darkened-footer" w="full">
      <CardHeader py={4}>
        <Text textStyle="heading-2xl">{t(`settlement`)}</Text>
      </CardHeader>
      <CardBody p={6}>
        {!hasFundsReceipts && hasAccountNumber && (
          <WireInformation
            accountNumber={virtualAccount.accountNumber}
            amount={wireAmount}
            routingNumber={virtualAccount.routingNumber}
          />
        )}
        {hasFundsReceipts && (
          <FundsReceipts receipts={virtualAccount?.fundsReceipts} />
        )}
      </CardBody>
    </Card>
  );
};

export default SettlementCard;
