import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

const ListingViewerRoundedSharesTooltip = ({
  shareText,
}: {
  readonly shareText: string;
}) => {
  const { t } = useTranslation();

  return (
    <Text>{t(`listing_viewer_rounded_shares_tooltip`, { shareText })}</Text>
  );
};

export default ListingViewerRoundedSharesTooltip;
