import { ReactNode } from "react";

import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";

import { useIsDesktop } from "@/hooks";

const DESKTOP_CONTAINER_GAP = 8;
const MOBILE_CONTAINER_GAP = 3;

interface IssuerPageHeaderProps {
  title: string;
  actions?: ReactNode;
}

function IssuerPageHeader({ title, actions }: IssuerPageHeaderProps) {
  const isDesktop = useIsDesktop();

  return (
    <Flex
      flexDirection={isDesktop ? `row` : `column`}
      w="full"
      gap={isDesktop ? DESKTOP_CONTAINER_GAP : MOBILE_CONTAINER_GAP}
      justify="space-between"
      mb={isDesktop ? 8 : 6}
    >
      <VStack align="flex-start" gap={3}>
        <HStack gap={3}>
          <Text textStyle={isDesktop ? `heading-4xl` : `heading-2xl`}>
            {title}
          </Text>
        </HStack>
      </VStack>
      {actions && <Box flexGrow={1} />}
      {actions}
    </Flex>
  );
}

export default IssuerPageHeader;
