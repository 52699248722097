import { CircleDashed } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Badge, HStack, Text } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";
import { TransferMethodV2 } from "@/gql";
import { useColors } from "@/hooks";
import { getLongDocumentTitleByTransferMethod } from "@/utils";

const PartiallySoldBadge = ({
  transferMethod,
}: {
  readonly transferMethod: TransferMethodV2;
}) => {
  const { t } = useTranslation();
  const [teal800] = useColors([`teal.800`]);

  const longDocumentTitle =
    getLongDocumentTitleByTransferMethod(transferMethod);

  const content = match(transferMethod)
    .with(TransferMethodV2.Direct, () => t(`partially_sold_tooltip_bid`))
    .with(TransferMethodV2.ForwardContract, () =>
      t(`partially_sold_tooltip_bid`),
    )
    .with(TransferMethodV2.Fund, () => t(`partially_sold_tooltip_bid`))
    .with(TransferMethodV2.HiiveFund, () => t(`partially_sold_tooltip_hiive`))
    .otherwise(() => t(`partially_sold_tooltip`, { longDocumentTitle }));

  return (
    <CustomTooltip placement="bottom" tooltipContent={content}>
      <Badge variant="rounded-teal">
        <HStack spacing={1.5}>
          <CircleDashed color={teal800} weight="bold" size={14} />
          <Text>{t(`partially_sold`)}</Text>
        </HStack>
      </Badge>
    </CustomTooltip>
  );
};

export default PartiallySoldBadge;
