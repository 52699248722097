import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Stack, Text } from "@chakra-ui/react";

const AccreditationValuesContainer = ({
  children,
  onModify,
}: {
  readonly children: ReactNode;
  readonly onModify: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction={{ base: `column-reverse`, lg: `row` }}
      alignItems={{ base: `flex-end`, lg: `normal` }}
      gap={4}
      p={{ base: 0, lg: 4 }}
    >
      <Box w="full">{children}</Box>
      <Box pb={{ base: 4, md: 0 }}>
        <Button variant="boxed-grey" size="xl" onClick={onModify}>
          <Text as="span" px={6}>
            {t(`modify`)}
          </Text>
        </Button>
      </Box>
    </Stack>
  );
};

export default AccreditationValuesContainer;
