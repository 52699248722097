import { TableCell } from "@/components/table";
import { IssuerInvitationFragment, RoleType, UserRole } from "@/gql";
import { getInviteeRoleText, InviteeStatus } from "@/utils";

import { InvitationActionButton } from "./IssuerManageUsersActionButton";
import { IssuerManageUsersStatusBadge } from "./IssuerManageUsersStatusBadge";

type RoleTypesTableCellProps = {
  roles: (RoleType | UserRole)[];
};

type InvitationStatusCellProps = {
  status: InviteeStatus;
};

type InvitationActionCellProps = {
  invitation: IssuerInvitationFragment;
};

export function InvitationStatusCell({ status }: InvitationStatusCellProps) {
  return (
    <TableCell>
      <IssuerManageUsersStatusBadge status={status} />
    </TableCell>
  );
}

export function InvitationActionCell({
  invitation,
}: InvitationActionCellProps) {
  return (
    <TableCell>
      <InvitationActionButton invitation={invitation} />
    </TableCell>
  );
}

export function RoleTypesTableCell({ roles }: RoleTypesTableCellProps) {
  const rolesText = getInviteeRoleText(roles);

  return <TableCell>{rolesText}</TableCell>;
}
