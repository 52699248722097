import { useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Text, VStack } from "@chakra-ui/react";

import {
  OnboardingContainerV2,
  SlideAnimation,
} from "@/components/onboarding-v2";

export const TraderIVRequiredPage = () => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `awaiting_approval`,
  });

  return (
    <OnboardingContainerV2
      canGoBack={false}
      metaTitle={t(`verify_your_identity`)}
    >
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`verify_your_identity`)}*
          </Text>
        </VStack>

        <SlideAnimation>
          <Card>
            <CardBody>
              <Flex direction="column" gap={7}>
                <Text>{t(`we_are_verifying_your_identity`)}</Text>
                <Text>{t(`this_usually_takes_a_minute`)}</Text>
              </Flex>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
    </OnboardingContainerV2>
  );
};
