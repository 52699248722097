import { Trans, useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";

export const MethodologyCard = () => {
  const { t } = useTranslation();

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`methodology`)}</Text>
      </CardHeader>
      <CardBody>
        <VStack alignItems="start" spacing={1}>
          <Text textStyle="text-sm">
            <Trans
              i18nKey="methodology_description"
              components={{
                a: (
                  <Link
                    fontWeight={500}
                    href="https://www.hiive.com/disclosures/hiive50-disclaimers"
                    rel="noreferrer"
                    target="_blank"
                    textDecoration="underline"
                  />
                ),
              }}
            />
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
};
