import { useTranslation } from "react-i18next";

import { FormListbox } from "@/components/form";
import { EntityType } from "@/gql";
import { useTranslatedEntityNames } from "@/hooks";

type EntityTypeSelectProps = {
  filterIndividualEntityType?: boolean;
  isInstitution: boolean;
  label?: string;
};

const EntityTypeSelect = ({
  filterIndividualEntityType,
  isInstitution,
  label,
}: EntityTypeSelectProps) => {
  const { t } = useTranslation(`account`);

  const { getFormattedEntityName } = useTranslatedEntityNames();

  const items = isInstitution
    ? [
        EntityType.Corporation,
        EntityType.Partnership,
        EntityType.Llc,
        EntityType.Trust,
        EntityType.Other,
      ]
    : [
        EntityType.Trust,
        EntityType.Corporation,
        EntityType.Llc,
        EntityType.Partnership,
        ...(filterIndividualEntityType ? [] : [EntityType.Individual]),
        EntityType.Other,
      ];

  return (
    <FormListbox<EntityType>
      name="type"
      items={items}
      itemToString={(item) => getFormattedEntityName(item)}
      getItemKey={(item) => item}
      placeholder={t(`entities.select_entity_type`)}
      label={label ?? t(`entities.entity_type`)}
    />
  );
};

export default EntityTypeSelect;
