import { IssuerProfilePageCompanyFragment } from "@/gql";

export function sanitizeCompanyData(company: IssuerProfilePageCompanyFragment) {
  const filteredInvestors = company?.investors.filter(
    (investor) => investor.name,
  );

  const filteredIndustries = company?.industries.filter(
    (industry) => industry.name,
  );

  return {
    ...company,
    investors: filteredInvestors,
    industries: filteredIndustries,
  };
}
