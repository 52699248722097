import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { MappedActiveInvitees } from "@/components/issuers";
import {
  IssuerInvitationInviteeFragment,
  IssuerInvitationUsersDocument,
  useChangeIssuerUserRolesMutation,
  useChangeUserActiveStatusMutation,
  UserRole,
} from "@/gql";
import {
  useCustomToast,
  useModal,
  useCurrentIssuerActor,
  useSignOut,
} from "@/hooks";

import { ReassignAdminSelectInput } from "./IssuersReassignAdminSelectInput";

type IssuersRemoveUserModalProps = {
  readonly invitee: IssuerInvitationInviteeFragment;
  readonly activeInvitees: MappedActiveInvitees;
  readonly showReassignAdmin: boolean;
  readonly showRemoveUserWarning: boolean;
};

interface ModalBodyContentProps {
  readonly activeInvitees: MappedActiveInvitees;
  readonly onReassignAdmin: (targetUserId: string) => void;
  readonly name: string;
  readonly showReassignAdmin: boolean;
  readonly showRemoveUserWarning: boolean;
}

function ModalBodyContent({
  activeInvitees,
  onReassignAdmin,
  name,
  showReassignAdmin,
  showRemoveUserWarning,
}: ModalBodyContentProps) {
  const { t } = useTranslation(`issuers`);

  return match({ showReassignAdmin, showRemoveUserWarning })
    .with({ showReassignAdmin: true, showRemoveUserWarning: false }, () => (
      <ReassignAdminSelectInput
        activeInvitees={activeInvitees}
        onReassignAdmin={onReassignAdmin}
      />
    ))
    .with({ showReassignAdmin: false, showRemoveUserWarning: true }, () => (
      <Text>{t(`remove_user_warning`)}</Text>
    ))
    .otherwise(() => <Text>{t(`remove_user_confirmation`, { name })}</Text>);
}

export function IssuersRemoveUserModal({
  invitee: { id: userId, name },
  activeInvitees,
  showReassignAdmin,
  showRemoveUserWarning,
}: IssuersRemoveUserModalProps) {
  const { t } = useTranslation(`issuers`);
  const { closeModal } = useModal();
  const signOut = useSignOut();
  const { id: sessionActorId } = useCurrentIssuerActor();
  const { errorToast, successToast } = useCustomToast();
  const targetUserIdRef = useRef<string>(``);

  const [changeIssuerUserRoles, { loading: changeIssuerUserRolesLoading }] =
    useChangeIssuerUserRolesMutation();
  const [removeIssuerUser, { loading: removeIssuerUserLoading }] =
    useChangeUserActiveStatusMutation({
      refetchQueries: [IssuerInvitationUsersDocument],
    });

  const onSubmit = useCallback(async () => {
    const successMessage = showReassignAdmin
      ? t(`changes_updated`)
      : t(`remove_user_success`);

    try {
      if (showReassignAdmin) {
        const { data: reassignAdminData } = await changeIssuerUserRoles({
          variables: {
            userId: targetUserIdRef.current,
            roles: [UserRole.Admin],
          },
        });

        if (!reassignAdminData?.changeIssuerUserRoles) {
          throw new Error(t(`failed_to_change_user_roles`));
        }
      }

      const { data: removeUserData } = await removeIssuerUser({
        variables: { userId, active: false },
      });

      if (!removeUserData?.changeUserActiveStatus) {
        throw new Error(t(`failed_to_remove_user`));
      }

      const updatedUser = removeUserData?.changeUserActiveStatus?.user;

      if (updatedUser) {
        closeModal();
        successToast(successMessage);
      }

      if (updatedUser?.id === sessionActorId) {
        signOut();
      }
    } catch (error) {
      errorToast(error.message);
    }
  }, [
    userId,
    showReassignAdmin,
    changeIssuerUserRoles,
    removeIssuerUser,
    closeModal,
    successToast,
    t,
  ]);

  const updateTargetUserId = useCallback((targetUserId: string) => {
    targetUserIdRef.current = targetUserId;
  }, []);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>
          {showReassignAdmin ? t(`set_a_new_admin`) : t(`remove_user_question`)}
        </Text>
      </HiiveModalHeader>
      <ModalBody data-dd-privacy="mask">
        <VStack alignItems="flex-start" gap={4}>
          <ModalBodyContent
            activeInvitees={activeInvitees}
            onReassignAdmin={updateTargetUserId}
            name={name}
            showReassignAdmin={showReassignAdmin}
            showRemoveUserWarning={showRemoveUserWarning}
          />
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            sentryLabel="[IssuersRemoveUser/Submit]"
            submitText={t(`confirm`)}
            onClick={onSubmit}
            isLoading={changeIssuerUserRolesLoading || removeIssuerUserLoading}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
}
