import EntityCard, { EntityCardProps, EntityCardTypes } from "./EntityCard";
import { EntityItem } from "./EntityItem";
import { InternalEntityCard } from "./InternalEntityCard";

type SellerEntityCardProps = Omit<EntityCardProps, "type">;

const SellerEntityCard = ({ transaction, ...props }: SellerEntityCardProps) => {
  const entity = transaction?.sellerEntity;
  const hasEntity = !!entity;
  const isHiiveUser = transaction?.seller.isHiiveUser;

  return isHiiveUser ? (
    <InternalEntityCard
      entity={entity}
      transactionId={transaction?.id}
      type={EntityCardTypes.Seller}
    />
  ) : (
    <EntityCard
      type={EntityCardTypes.Seller}
      transaction={transaction}
      {...props}
    >
      {hasEntity && (
        <EntityItem
          entity={entity}
          showDate={false}
          showRemoveButton={false}
          showStatus={false}
        />
      )}
    </EntityCard>
  );
};

export default SellerEntityCard;
