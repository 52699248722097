import { useTranslation } from "react-i18next";

import { Center } from "@chakra-ui/react";

import {
  Paginator,
  PaginatorButton,
  PaginatorLeft,
  PaginatorLegend,
  PaginatorRight,
} from "@/components/nav";

import { TABLE_SKELETON_ROW_LENGTH } from "./IssuerTransactionsTableSkeleton";

export const IssuerTransactionsTablePaginatorSkeleton = () => {
  const { t } = useTranslation(`issuers`);

  return (
    <Center w="full">
      <Paginator>
        <PaginatorLegend>{`1-${TABLE_SKELETON_ROW_LENGTH} of ${TABLE_SKELETON_ROW_LENGTH}`}</PaginatorLegend>
        <PaginatorButton isDisabled>{t(`first`)}</PaginatorButton>
        <PaginatorLeft isDisabled />
        <PaginatorRight isDisabled />
      </Paginator>
    </Center>
  );
};
