import { defineStyleConfig } from "@chakra-ui/react";

export const FormLabel = defineStyleConfig({
  baseStyle: {
    mb: `1.5`,
  },
  variants: {
    issuer: {
      color: `grey.600`,
      textTransform: `uppercase`,
    },
  },
});
