import {
  CreateEntityModal,
  CreateEntityModalProps,
  CreateEntityModalTypes,
} from "@/components/entities";

type CreateSellerEntityModalProps = Omit<CreateEntityModalProps, "type">;

const CreateSellerEntityModal = (props: CreateSellerEntityModalProps) => (
  <CreateEntityModal type={CreateEntityModalTypes.Seller} {...props} />
);

export default CreateSellerEntityModal;
