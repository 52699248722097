import { useTranslation } from "react-i18next";

import { Card, CardHeader, Text } from "@chakra-ui/react";

import AccreditationCardContent from "./AccreditationCardContent";

const AccreditationCard = () => {
  const { t } = useTranslation();

  return (
    <Card variant="darkened-footer" w="full" flex="1">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`accreditation`)}</Text>
      </CardHeader>
      <AccreditationCardContent />
    </Card>
  );
};

export default AccreditationCard;
