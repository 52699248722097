import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { Stack } from "@chakra-ui/react";

import { CreateEntityModalTypes } from "@/components/entities";
import { withCurrentActor } from "@/components/hoc";
import {
  AddEntityInput,
  UserWithInstitutionFragment,
  EntityType,
  InvestorType,
} from "@/gql";

import { EntityTypeInput } from "./EntityTypeInput";
import { EntityTypeSelect } from "./EntityTypeSelect";
import { JurisdictionOfFormationInput } from "./JurisdictionOfFormationInput";
import { LegalNameInput } from "./LegalNameInput";

type EntityFormFieldsProps = {
  actor: UserWithInstitutionFragment;
  type?: CreateEntityModalTypes;
};

const InternalEntityFormFields = withCurrentActor(
  ({ actor, type }: EntityFormFieldsProps) => {
    const { t } = useTranslation(`account`);
    const { values } = useFormikContext<AddEntityInput>();
    const showEntityTypeInput = values.type === EntityType.Other;
    const isInstitution = !!actor.institutionId;

    return (
      <Stack gap="6">
        <EntityTypeSelect
          isInstitution={isInstitution}
          label={t(`entities.party_type`)}
        />
        {showEntityTypeInput && <EntityTypeInput />}
        <LegalNameInput
          isUnaccredittedSeller={
            actor.investorType === InvestorType.UnaccreditedSeller
          }
          showLabelText={false}
          type={type}
        />
        <JurisdictionOfFormationInput />
      </Stack>
    );
  },
);

export default InternalEntityFormFields;
