import {
  CardFooter,
  CardHeader,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ActivityCard } from "@/components/common";
import { ActiveTransactionItemTransactionFragment } from "@/gql";
import { formatPricePerShare, lot } from "@/utils";

import ActiveTransactionItemStateBadge from "./ActiveTransactionItemStateBadge";
import ActiveTransactionTasksBadge from "./ActiveTransactionTasksBadge";

type ActiveTransactionItemCardProps = {
  transaction: ActiveTransactionItemTransactionFragment;
  taskCount: number;
};

const formatNumber = (number: number, options?: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat(undefined, {
    maximumSignificantDigits: 3,
    ...options,
  }).format(number);

const ActiveTransactionItemCard = ({
  transaction,
  taskCount,
}: ActiveTransactionItemCardProps) => {
  const {
    bid: { displayId },
    company: { logoUrl, name },
    numShares,
    pricePerShare,
    id,
  } = transaction;

  const showTasksBadge = taskCount > 0;

  return (
    <ActivityCard href={`/transactions/${id}`}>
      <CardHeader
        as={HStack}
        alignItems="start"
        justify="space-between"
        p={0}
        px={4}
        py={3}
        w="full"
      >
        <VStack alignItems="start" gap={0.5}>
          <HStack color="grey.900" gap={1}>
            <Text textStyle="heading-lg">{name}</Text>
            <Text textStyle="heading-md" fontWeight={400}>
              {displayId}
            </Text>
          </HStack>
          <HStack textStyle="text-sm" color="grey.700" gap={1}>
            <Text fontWeight={500}>
              {formatNumber(numShares)} @ {formatPricePerShare(pricePerShare)}
            </Text>
            <Text fontWeight={400}>
              {lot(numShares, pricePerShare, `$0,0.00`)}
            </Text>
          </HStack>
        </VStack>
        {logoUrl && <Image alt={name} h={6} src={logoUrl} w={6} />}
      </CardHeader>
      <CardFooter
        as={HStack}
        justifyContent="space-between"
        p={0}
        px={4}
        py={2}
        w="full"
      >
        <ActiveTransactionItemStateBadge transaction={transaction} />
        {showTasksBadge && (
          <ActiveTransactionTasksBadge taskCount={taskCount} />
        )}
      </CardFooter>
    </ActivityCard>
  );
};

export default ActiveTransactionItemCard;
