import { AnimatePresence } from "framer-motion";
import intersection from "lodash/intersection";

import { HStack } from "@chakra-ui/react";

import {
  FadeInOut,
  MessageNotificationCount,
  TotalCount,
  WithQuery,
} from "@/components/common";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import { useUnaccreditedSellerActiveBidNotificationsCountQuery } from "@/gql";

const UnaccreditedSellerActiveBidNotificationsCount = () => {
  const { newBidIds, newMessagesCountByInquiryId } =
    useUnaccreditedSellerInlineNotifications();
  const query = useUnaccreditedSellerActiveBidNotificationsCountQuery();

  return (
    <WithQuery query={query} hideLoadingSpinner>
      {({
        data: {
          unaccreditedSellerMyListingActivity: { activeBids },
        },
      }) => {
        const inquiryIds = activeBids.flatMap(({ discussion }) => {
          if (!discussion) return [];
          return [discussion.id];
        });

        const numNewMessages = inquiryIds.reduce(
          (prev, curr) => prev + (newMessagesCountByInquiryId[curr] || 0),
          0,
        );

        const activeBidIds = activeBids.map(({ id }) => id);

        const numNewActiveBids = intersection(newBidIds, activeBidIds).length;
        const hasNewActiveBids = numNewActiveBids > 0;

        const hasNewMessages = numNewMessages > 0;

        const totalActiveBids = activeBids.length;
        const hasActiveBids = totalActiveBids > 0;

        return (
          <HStack>
            <AnimatePresence mode="wait">
              {hasNewMessages && (
                <FadeInOut key={numNewMessages}>
                  <MessageNotificationCount count={numNewMessages} />
                </FadeInOut>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {hasActiveBids && (
                <FadeInOut>
                  <TotalCount
                    totalCount={totalActiveBids}
                    hasNewNotifications={hasNewActiveBids}
                  />
                </FadeInOut>
              )}
            </AnimatePresence>
          </HStack>
        );
      }}
    </WithQuery>
  );
};

export default UnaccreditedSellerActiveBidNotificationsCount;
