import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Text } from "@chakra-ui/react";

import { IssuerStage } from "@/gql";
import { useIsDesktop } from "@/hooks";

import { IssuerTransactionsTableContext } from "./IssuerTransactionsProvider";

export const IssuerTransactionsStageDescription = () => {
  const { t } = useTranslation(`issuers`);
  const { filterBy } = useContext(IssuerTransactionsTableContext);
  const isDesktop = useIsDesktop();

  const { stage } = filterBy ?? {};

  const stageDescription = match(stage)
    .with(IssuerStage.InReview, () => t(`in_review_description`))
    .with(IssuerStage.InProgress, () => t(`in_progress_description`))
    .with(IssuerStage.Closed, () => t(`closed_description`))
    .with(IssuerStage.Cancelled, () => t(`cancelled_description`))
    .otherwise(() => null);

  return stageDescription ? (
    <Text textColor="grey.600" px={isDesktop ? `unset` : 1}>
      {stageDescription}
    </Text>
  ) : null;
};
