import { HiiveModalHeader } from "@/components/common";
import { StepPropsV2, StepsHeader } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  CreateListingSelectCompanyInputCompanyFragment,
  UserWithInstitutionFragment,
} from "@/gql";

import {
  CreateListingSequenceModalFormValues,
  createAdditionalDetailsValidationSchema,
  useShareDetailsValidationSchema,
} from ".";
import { AdditionalDetails } from "./AdditionalDetails";
import CreateListingShareDetails from "./CreateListingShareDetails";
import { StepKeys, stepKeys } from "./steps";

interface CreateListingModalProps
  extends StepPropsV2<StepKeys, CreateListingSequenceModalFormValues> {
  readonly initialCompany?: CreateListingSelectCompanyInputCompanyFragment;
  readonly actor: UserWithInstitutionFragment;
}

const CreateListingModal = ({
  stepRouter,
  initialCompany,
  actor,
  ...formikProps
}: CreateListingModalProps) => {
  const { values } = formikProps;
  const { stepsInfo } = stepRouter;

  const shareDetailsValidationSchema = useShareDetailsValidationSchema();
  const isShareDetailsStepValid =
    shareDetailsValidationSchema.isValidSync(values);
  const isAdditionalDetailsStepValid = createAdditionalDetailsValidationSchema(
    actor,
    {
      isEligibilityRequirementsCheckboxRequired: false,
      isInformationDisclosureCheckboxRequired: false,
    },
  ).isValidSync(values);

  return (
    <div data-testid="create-listing-modal">
      <HiiveModalHeader>Make a Listing</HiiveModalHeader>
      <StepsHeader
        stepRouter={stepRouter}
        steps={[
          {
            stepKey: stepKeys.createListingShareDetails,
            stepTitle: `Share Details`,
            isStepValid: isShareDetailsStepValid,
          },
          {
            stepKey: stepKeys.createListingAdditionalDetails,
            stepTitle: `Additional Details`,
            isStepValid: isAdditionalDetailsStepValid,
          },
        ]}
      />
      {stepsInfo.currentStepKey === stepKeys.createListingShareDetails && (
        <CreateListingShareDetails
          initialCompany={initialCompany}
          actor={actor}
          stepRouter={stepRouter}
          {...formikProps}
        />
      )}
      {stepsInfo.currentStepKey === stepKeys.createListingAdditionalDetails && (
        <AdditionalDetails stepRouter={stepRouter} {...formikProps} />
      )}
    </div>
  );
};

export default withCurrentActor(CreateListingModal);
