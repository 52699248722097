import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { useTokenExchangeMutation } from "@/gql";
import { setAuthFromSession } from "@/state/auth";
import { buildSessionPayload } from "@/utils";

const useTokenExchange = ({
  onError,
  onSuccess,
}: {
  onError?: () => void;
  onSuccess?: () => void;
} = {}) => {
  const [fetching, setFetching] = useState(false);
  const [tokenExchangeMutation] = useTokenExchangeMutation();
  const dispatch = useDispatch();

  const exchangeToken = useCallback(
    async (identityToken: string) => {
      setFetching(true);
      const { data } = await tokenExchangeMutation({
        variables: { identityToken },
      });

      if (data?.token?.session?.token) {
        const sessionPayload = buildSessionPayload(data.token.session);
        dispatch(setAuthFromSession(sessionPayload));
        onSuccess?.();
      } else {
        onError?.();
      }
      setFetching(false);
    },
    [tokenExchangeMutation, dispatch, onSuccess, onError],
  );

  return { fetching, exchangeToken };
};

export default useTokenExchange;
