import { CaretDown } from "@phosphor-icons/react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import {
  SelectFieldProps,
  FormLabelProps,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";

interface FormSelectInputProps<TFieldValues extends FieldValues>
  extends SelectFieldProps {
  readonly control: Control<TFieldValues>;
  readonly name: Path<TFieldValues>;
  readonly label: string;
  readonly labelSrOnly?: FormLabelProps["srOnly"];
  readonly displayError?: boolean;
  readonly isRequired?: boolean;
  readonly options: (
    | {
        label: string;
        value: string;
      }
    | string
  )[];
  readonly formLabelProps?: FormLabelProps;
}

export function FormSelectInput<TFieldValues extends FieldValues>({
  control,
  name,
  label,
  labelSrOnly,
  isRequired,
  displayError = true,
  options,
  formLabelProps,
  ...selectProps
}: FormSelectInputProps<TFieldValues>) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel srOnly={labelSrOnly} {...formLabelProps}>
        {isRequired ? `${label} *` : label}
      </FormLabel>
      <Select
        borderColor="grey.700"
        icon={<CaretDown />}
        {...selectProps}
        {...field}
      >
        {options.map((option) => {
          if (typeof option === `object`) {
            const { value, label } = option;
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          }
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </Select>
      {displayError && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
}
