import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { PlaceStandingBidMutation } from "@/gql";

import { StepKeys } from "./steps";

export const PlaceStandingBidSequenceModalStepFormContext =
  createStepFormContext<StepKeys, PlaceStandingBidMutation>();

export const usePlaceStandingBidSequenceModalStepFormContext = () =>
  useContext(PlaceStandingBidSequenceModalStepFormContext);
