import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Flex, CardFooter, Button, CardBody, Box } from "@chakra-ui/react";

import { ActionNeeded, HiiveButton, Loader } from "@/components/common";
import {
  InvestorSuitabilityForm,
  useSuitabilityForm,
} from "@/components/suitability";
import { UserRole, useSuitabilityQuestionGroupsQuery } from "@/gql";
import { useCurrentActor, useCustomToast } from "@/hooks";
import {
  areSuitabilityAnswersCurrent,
  getIsInstitutionUser,
  getOutdatedSuitabilityText,
  getSuitabilityAnsweredVersion,
  getSuitabilityLastUpdatedAtForActor,
  getSuitabilityUnansweredText,
  getUserCountry,
} from "@/utils";

interface Props {
  onClose: () => void;
}

interface WarningBannerProps {}

const WarningBanner: FC<WarningBannerProps> = () => {
  const actor = useCurrentActor();
  const country = getUserCountry(actor);
  const { t } = useTranslation();
  const { data, loading: questionGroupsLoading } =
    useSuitabilityQuestionGroupsQuery({
      variables: {
        investorStatus: actor.investorStatus!,
        countryId: country.id,
      },
    });

  const isSuitabilityVersionCurrent = areSuitabilityAnswersCurrent(
    actor,
    data?.currentSuitabilityQuestionGroup,
  );

  const currentSuitabilityVersion = getSuitabilityAnsweredVersion(actor);

  const suitabilityQuestionsUnanswered = !actor.suitabilityLastUpdatedAt;

  const companyName = actor.institution?.legalName;

  const isInstitutionUser = getIsInstitutionUser(actor);

  const { institution, membershipAgreementSigned, identityVerified } = actor;

  const verified =
    ((membershipAgreementSigned || institution?.membershipAgreementSigned) &&
      identityVerified) ??
    false;

  if (questionGroupsLoading) {
    return <Loader />;
  }

  if (suitabilityQuestionsUnanswered) {
    return (
      <ActionNeeded heading={t(`complete_suitability_to_buy`)}>
        {getSuitabilityUnansweredText({
          companyName,
          isInstitutionAdmin: actor.roles.includes(UserRole.Admin),
          isInstitutionUser,
          verified,
          t,
        })}
      </ActionNeeded>
    );
  }
  if (!isSuitabilityVersionCurrent) {
    return (
      <Box m={4}>
        <ActionNeeded heading={t(`update_required_suitability`)}>
          {getOutdatedSuitabilityText({
            companyName,
            currentSuitabilityVersion,
            isInstitutionUser,
            t,
          })}
        </ActionNeeded>
      </Box>
    );
  }
  return null;
};

const SuitabilityForm: FC<Props> = ({ onClose }) => {
  const actor = useCurrentActor();
  const { t } = useTranslation();
  const { successToast } = useCustomToast();
  const onSuccess = () => {
    onClose();
    successToast(t`updated_suitability`);
  };
  const suitabilityLastUpdatedAt = getSuitabilityLastUpdatedAtForActor(actor);
  const {
    formProps,
    formId,
    questionTree,
    loading,
    country,
    renderDisclaimer,
    version,
  } = useSuitabilityForm({ onSuccess, user: actor });

  return (
    <>
      <CardBody>
        <WarningBanner />
        <InvestorSuitabilityForm
          formProps={formProps}
          formId={formId}
          hideBorder
          loading={loading}
          questionTree={questionTree}
          country={country.name}
          renderDisclaimer={renderDisclaimer}
          version={version}
        />
      </CardBody>
      <CardFooter>
        <Flex w="full" justify="flex-end" gap={4}>
          {suitabilityLastUpdatedAt && (
            <Button
              variant="rounded-outline-grey"
              size="xl"
              onClick={onClose}
              w={{ base: `full`, lg: 180 }}
              minW="auto"
            >
              {t(`cancel`)}
            </Button>
          )}
          <HiiveButton
            sentryLabel="[InvestorStatusPageV2/Submit]"
            variant="rounded-solid-salmon"
            type="submit"
            size="xl"
            maxW="unset"
            w={{ base: `full`, lg: `unset` }}
            isLoading={loading}
            form={formId}
          >
            {t(`save`)}
          </HiiveButton>
        </Flex>
      </CardFooter>
    </>
  );
};

export default SuitabilityForm;
