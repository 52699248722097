import {
  ExecutionMilestone,
  IssuerTransactionWorkflowMilestoneCompletionFragment,
} from "@/gql";
import { pipe } from "@/utils/pipe";

export enum IssuerMilestoneTimelineStatus {
  Done = `done`,
  InProgress = `in-progress`,
  Pending = `pending`,
}

export type IssuerTransactionValidMilestones =
  | ExecutionMilestone.Submitted
  | ExecutionMilestone.Approved
  | ExecutionMilestone.Signed
  | ExecutionMilestone.Complete;

export type MappedMilestoneTimelineDoneNode = {
  name: IssuerTransactionValidMilestones;
  status: IssuerMilestoneTimelineStatus.Done;
  milestone: IssuerTransactionWorkflowMilestoneCompletionFragment;
  isLastMilestone: boolean;
};

export type MappedMilestoneTimelineInProgressNode = {
  name: IssuerTransactionValidMilestones;
  status: IssuerMilestoneTimelineStatus.InProgress;
  isLastMilestone: boolean;
};

export type MappedMilestoneTimelinePendingNode = {
  name: IssuerTransactionValidMilestones;
  status: IssuerMilestoneTimelineStatus.Pending;
  isLastMilestone: boolean;
};

type MappedMilestoneTimelineNode =
  | MappedMilestoneTimelineDoneNode
  | MappedMilestoneTimelineInProgressNode
  | MappedMilestoneTimelinePendingNode;

function mapMilestoneTimeline(
  milestoneCompletions: IssuerTransactionWorkflowMilestoneCompletionFragment[],
) {
  const completedMilestones = milestoneCompletions.reduce(
    (acc, milestone) => {
      const { milestone: milestoneKey } = milestone;
      return {
        ...acc,
        [milestoneKey]: milestone,
      };
    },
    {} as Record<
      ExecutionMilestone,
      IssuerTransactionWorkflowMilestoneCompletionFragment
    >,
  );

  return (
    milestoneOrder: IssuerTransactionValidMilestones[],
  ): MappedMilestoneTimelineNode[] => {
    const { length: milestoneOrderLength } = milestoneOrder;

    return milestoneOrder.map((milestone, index) => {
      const completedMilestone = completedMilestones[milestone];

      const nextIndex = index + 1;
      const isLastMilestone = milestoneOrderLength === nextIndex;

      if (completedMilestone) {
        return {
          name: milestone,
          status: IssuerMilestoneTimelineStatus.Done,
          milestone: completedMilestone,
          isLastMilestone,
        };
      }

      return {
        name: milestone,
        status: IssuerMilestoneTimelineStatus.Pending,
        isLastMilestone,
      };
    });
  };
}

function insertPendingMilestone(
  milestoneTimeline: MappedMilestoneTimelineNode[],
) {
  const { items } = milestoneTimeline.reduce(
    (acc, item) => {
      const { found } = acc;
      const { status } = item;

      if (status === IssuerMilestoneTimelineStatus.Pending && !found) {
        return {
          found: true,
          items: [
            ...acc.items,
            {
              ...item,
              status: IssuerMilestoneTimelineStatus.InProgress,
            } as MappedMilestoneTimelineInProgressNode,
          ],
        };
      }

      return {
        ...acc,
        items: [...acc.items, item],
      };
    },
    {
      found: false,
      items: [],
    },
  );

  return items as MappedMilestoneTimelineNode[];
}

export function mapIssuerMilestoneTimeline(
  milestoneOrder: readonly IssuerTransactionValidMilestones[],
  milestoneCompletions: IssuerTransactionWorkflowMilestoneCompletionFragment[],
) {
  return pipe(milestoneOrder)
    .step(mapMilestoneTimeline(milestoneCompletions))
    .step(insertPendingMilestone)
    .end();
}
