import { MagnifyingGlass } from "@phosphor-icons/react";
import { t } from "i18next";
import isNil from "lodash/isNil";
import { useState, forwardRef, useImperativeHandle, ForwardedRef } from "react";

import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Text,
  HStack,
  VStack,
} from "@chakra-ui/react";

import { CompanyCombobox } from "@/components/companies";
import { Combobox, FormCompaniesControls, ItemList } from "@/components/form";
import { FormCompaniesComboboxCompanyFragment } from "@/gql";
import { useCombobox, useItemField } from "@/hooks";

const ManualActionContent = () => (
  <>
    <Text textStyle="text-sm">{t(`seller_lot_details_company_unlisted`)}</Text>
    <Text color="salmon.900">
      + {t(`seller_lot_details_manually_add_company`)}
    </Text>
  </>
);

const NotFoundManualAction = ({ onlyItem }: { onlyItem: boolean }) =>
  onlyItem ? (
    <VStack py={4} w="full" align="center" justify="center">
      <ManualActionContent />
    </VStack>
  ) : (
    <HStack w="full" pt={1}>
      <ManualActionContent />
    </HStack>
  );

const FormCompaniesCombobox = forwardRef(
  (
    {
      placeholder,
      getItems,
      name,
      onChangeSearch,
      getItemDisabled = () => false,
      label,
      description,
      isLoading,
      isDisabled,
      manualActionName,
      onSelectInput,
    }: {
      readonly placeholder: string;
      readonly getItems: ({
        search,
      }: {
        readonly search: string;
      }) => ItemList<FormCompaniesComboboxCompanyFragment>;
      readonly name: string;
      readonly onChangeSearch?: (search: string) => void;
      readonly onSelectInput?: (
        company: FormCompaniesComboboxCompanyFragment | null,
      ) => void;
      readonly getItemDisabled?: (
        company: FormCompaniesComboboxCompanyFragment,
      ) => boolean;
      readonly label?: string;
      readonly description?: string;
      readonly isLoading: boolean;
      readonly isDisabled?: boolean;
      readonly manualActionName?: string;
    },
    ref: ForwardedRef<FormCompaniesControls>,
  ) => {
    const getItemKey = (company: FormCompaniesComboboxCompanyFragment) =>
      company.id;
    const itemToString = (company: FormCompaniesComboboxCompanyFragment) =>
      company.name;

    const { error, isInvalid, selectedItem, setSelectedItem } =
      useItemField<FormCompaniesComboboxCompanyFragment>(name);

    const [search, setSearch] = useState(
      !!selectedItem ? itemToString(selectedItem) : ``,
    );

    const handleChangeInputValue = (inputValue: string) => {
      if (!inputValue) {
        setSelectedItem(null);
      }

      if (!isNil(onChangeSearch)) onChangeSearch(inputValue);

      setSearch(inputValue);
    };

    const dummyAction = {
      __typename: `Company`,
      id: manualActionName,
      name: search,
      logoUrl: ``,
    } as FormCompaniesComboboxCompanyFragment;

    const originalItemsList = getItems({ search });
    const items = manualActionName
      ? [...originalItemsList, dummyAction]
      : originalItemsList;

    const defaultOnSelectItem = (
      company: FormCompaniesComboboxCompanyFragment | null,
    ) => {
      setSelectedItem(company);
      if (!isNil(company)) handleChangeInputValue(itemToString(company));
    };

    const { inputProps, menuProps, labelProps, itemProps, actions } =
      useCombobox<FormCompaniesComboboxCompanyFragment>({
        items,
        itemToString,
        getItemKey,
        selectedItem,
        onSelectItem: onSelectInput || defaultOnSelectItem,
        inputValue: search,
        onChangeInputValue: handleChangeInputValue,
        isLoading,
      });

    const handleFocus = (focus: boolean) => {
      if (focus) {
        actions.focus();
      } else {
        actions.blur();
      }
    };

    const handleClear = () => {
      setSelectedItem(null);
      handleChangeInputValue(``);
    };

    const handleClearSearch = () => {
      actions.focus();
      handleClear();
    };

    useImperativeHandle(
      ref,
      () => ({
        handleClear,
        handleFocus,
      }),
      [],
    );

    const canClearSearch = !!search;

    return (
      <FormControl id={name} isInvalid={isInvalid}>
        {label && <Combobox.Label {...labelProps}>{label}</Combobox.Label>}
        {description && <Combobox.Description description={description} />}
        <Combobox.Container>
          <InputGroup>
            {selectedItem && selectedItem.logoUrl && (
              <InputLeftElement pointerEvents="none">
                <CompanyCombobox.SelectedIcon company={selectedItem} />
              </InputLeftElement>
            )}
            {!selectedItem && (
              <InputLeftElement pointerEvents="none">
                <MagnifyingGlass
                  size={20}
                  color="var(--chakra-colors-grey-200)"
                />
              </InputLeftElement>
            )}
            <Combobox.Input
              isDisabled={isDisabled}
              name={name}
              placeholder={placeholder}
              {...inputProps}
            />
            {canClearSearch && (
              <InputRightElement
                as={IconButton}
                variant="icon"
                aria-label="Clear"
                onClick={handleClearSearch}
              >
                <Combobox.ClearButton />
              </InputRightElement>
            )}
          </InputGroup>
          <Combobox.Menu
            isLoading={isLoading}
            {...menuProps}
            maxH={242}
            fallback={<CompanyCombobox.Skeleton />}
            isLazy={false}
          >
            {originalItemsList.map((company, index) => (
              <CompanyCombobox.Item
                key={getItemKey(company)}
                isDisabled={getItemDisabled(company)}
                item={company}
                index={index}
                {...itemProps}
              >
                <CompanyCombobox.ItemIcon company={company} />
                <Text as="span">{itemToString(company)}</Text>
              </CompanyCombobox.Item>
            ))}
            {manualActionName && (
              <CompanyCombobox.Item
                key="after"
                item={dummyAction}
                index={items.length - 1}
                isDisabled={false}
                {...itemProps}
              >
                <NotFoundManualAction
                  onlyItem={originalItemsList.length === 0}
                />
              </CompanyCombobox.Item>
            )}
          </Combobox.Menu>
        </Combobox.Container>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  },
);

export default FormCompaniesCombobox;
