import { useTranslation, Trans } from "react-i18next";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  VStack,
  Text,
} from "@chakra-ui/react";

import { ActivityCardBadge, MailtoLink } from "@/components/common";
import { LatestActivityListStandingBidFragment } from "@/gql";
import { getSecuritySpecialistContact } from "@/utils";

export const BrokerLatestStandingBidSubcard = ({
  standingBid,
}: {
  readonly standingBid: LatestActivityListStandingBidFragment;
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const contactEmail = getSecuritySpecialistContact(standingBid.company);

  return (
    <Card
      variant="activity-subcard"
      as="button"
      w="full"
      onClick={() => router.push(`/standing-bids/${standingBid.id}`)}
      data-dd-action-name={`Click on standing bid ${standingBid.id}`}
    >
      <CardHeader w="full">
        <HStack>
          <ActivityCardBadge title="Standing Bid" variant="bid" />
          <Text textStyle="text-lg" align="left">
            {standingBid.company.name}
          </Text>
        </HStack>
      </CardHeader>
      <CardBody w="full">
        <VStack spacing={0} alignItems="flex-start" textAlign="left">
          <Text textStyle="heading-md">
            {t(`solicited_listing_activity_card_body`)}
          </Text>
          <Text>
            <Trans
              i18nKey="solicited_listing_activity_card_contact"
              t={t}
              components={[
                <MailtoLink
                  key="contact"
                  textDecoration="underline"
                  email={contactEmail}
                  subject="Broker Interest in Solicited Listing"
                />,
              ]}
              values={{ email: contactEmail }}
            />
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
};
