import { FormControl, FormLabel, HStack, Switch } from "@chakra-ui/react";

export const NotificationOption = ({
  title,
  value,
  onChange,
}: {
  readonly title: string;
  readonly value?: boolean;
  readonly onChange: (value: boolean) => void;
}) => (
  <FormControl
    as={HStack}
    align="center"
    justify="space-between"
    w="full"
    py={2.5}
    borderBottomWidth="0.5px"
    borderBottomColor="grey.50"
  >
    <FormLabel fontWeight={400} htmlFor={title}>
      {title}
    </FormLabel>
    <Switch
      size="lg"
      isChecked={value}
      id={title}
      colorScheme="salmonPink"
      onChange={() => onChange(!value)}
    />
  </FormControl>
);
