import { useTranslation, Trans } from "react-i18next";

import { Text, VStack, StackProps } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { SolicitedListingActivityCardCompanyFragment } from "@/gql";
import { getSecuritySpecialistContact } from "@/utils";

interface ActivityCardListingSolicitedCardBody extends StackProps {
  readonly company?: SolicitedListingActivityCardCompanyFragment;
}

const ActivityCardListingSolicitedCardBody = ({
  company,
  ...stackProps
}: ActivityCardListingSolicitedCardBody) => {
  const { t } = useTranslation();

  const contactEmail = getSecuritySpecialistContact(company);

  return (
    <VStack spacing={0} {...stackProps}>
      <Text textStyle="heading-md">
        {t(`solicited_standing_bid_activity_broker_card_body`)}
      </Text>
      <Text>
        <Trans
          i18nKey="solicited_listing_activity_card_contact"
          t={t}
          components={[
            // Ignored because typescript doesn't know the children prop
            // is passed to this component via Trans component
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <MailtoLink
              key="contact"
              textDecoration="underline"
              email={contactEmail}
              subject="Broker Interest in Solicited Listing"
            />,
          ]}
          values={{ email: contactEmail }}
        />
      </Text>
    </VStack>
  );
};

export default ActivityCardListingSolicitedCardBody;
