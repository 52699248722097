import { t } from "i18next";

import Link from "next/link";

import { Text, VStack, Flex, Card, CardBody } from "@chakra-ui/react";

import Meta from "@/components/Meta";
import { HiiveButton } from "@/components/common";
import { useLoginPath } from "@/hooks";

const PasswordUpdateConfirmedPage = () => {
  const loginPath = useLoginPath();

  return (
    <VStack minH="100vh">
      <Meta title={t`new_password_set`} />
      <Flex
        position="relative"
        direction="column"
        p={{ base: 2, lg: 9 }}
        flex={1}
        boxSize="full"
        maxW="50rem"
      >
        <VStack spacing={2} alignItems="flex-start" mb={8} mt={32}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`new_password_set`)}
          </Text>
        </VStack>
        <Card>
          <CardBody as={VStack} alignItems="flex-start" spacing={4} p={8}>
            <Text align="center">{t(`sign_in_with_new_password`)}</Text>
            <Link href={loginPath}>
              <HiiveButton
                variant="rounded-solid-salmon"
                size="xl"
                sentryLabel="[PasswordUpdateConfirmedPage] Sign In"
              >
                {t(`sign_in`)}
              </HiiveButton>
            </Link>
          </CardBody>
        </Card>
      </Flex>
    </VStack>
  );
};

export default PasswordUpdateConfirmedPage;
