import { useEffect } from "react";

import { Card, CardHeader, Text } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  DiscussionThreadCardDiscussionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useKnockClient, useNotificationFeed } from "@/hooks";
import { maybeViewNewInquiryNotification } from "@/services/knock";
import { getDiscussionTopicCounterpartyText } from "@/utils";

import DiscussionThreadCardBody from "./DiscussionThreadCardBody";
import DiscussionThreadCardFooter from "./DiscussionThreadCardFooter";

const DiscussionThreadCard = withCurrentActor(
  ({
    discussion,
    actor,
    userNotificationToken,
  }: {
    readonly discussion: DiscussionThreadCardDiscussionFragment;
    readonly actor: UserWithInstitutionFragment;
    readonly userNotificationToken: string;
  }) => {
    const counterpartyText = getDiscussionTopicCounterpartyText(
      actor,
      discussion.topic,
    );

    const knockClient = useKnockClient({ actor, userNotificationToken });
    const notificationFeed = useNotificationFeed({
      knockClient,
    });

    useEffect(() => {
      maybeViewNewInquiryNotification({
        notificationFeed,
        inquiryId: discussion.id,
      });
    }, []);

    return (
      <Card
        w="full"
        data-testId="discussion-thread-card"
        data-dd-privacy="mask"
      >
        <CardHeader py={4}>
          <Text textStyle={{ base: `heading-xl`, md: `heading-2xl` }}>
            {counterpartyText}
          </Text>
        </CardHeader>
        <DiscussionThreadCardBody discussion={discussion} />
        <DiscussionThreadCardFooter discussion={discussion} />
      </Card>
    );
  },
);

export default DiscussionThreadCard;
