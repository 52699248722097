import { AnimatePresence } from "framer-motion";
import intersection from "lodash/intersection";

import { FadeInOut, TotalCount, WithQuery } from "@/components/common";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import { useUnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery } from "@/gql";

const UnaccreditedSellerStandingBidNotificationsCountV2 = ({
  id,
}: {
  id: string;
}) => {
  const { newStandingBidIds } = useUnaccreditedSellerInlineNotifications();
  const query =
    useUnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery({
      variables: { id },
    });

  return (
    <WithQuery query={query} hideLoadingSpinner>
      {({
        data: {
          companyById: {
            activity: { availableStandingBids },
          },
        },
      }) => {
        const standingBidIds = availableStandingBids.map(({ id }) => id);

        const numNewStandingBids = intersection(
          newStandingBidIds,
          standingBidIds,
        ).length;

        const hasNewStandingBids = numNewStandingBids > 0;

        const totalStandingBids = availableStandingBids.length;
        const hasStandingBids = totalStandingBids > 0;

        return (
          <AnimatePresence>
            {hasStandingBids && (
              <FadeInOut>
                <TotalCount
                  totalCount={totalStandingBids}
                  hasNewNotifications={hasNewStandingBids}
                />
              </FadeInOut>
            )}
          </AnimatePresence>
        );
      }}
    </WithQuery>
  );
};

export default UnaccreditedSellerStandingBidNotificationsCountV2;
