import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { OnboardingContainerV2 } from "@/components/onboarding-v2";

import { InvestorStatusForm } from "./InvestorStatusForm";

const InvestorStatusPageV2 = () => {
  const { t } = useTranslation();

  return (
    <OnboardingContainerV2 canGoBack={false} metaTitle="Investor Status">
      <Flex direction="column">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`investor_status`)} *
          </Text>
          <Text color="grey.900">{t(`in_what_capacity`)}</Text>
        </VStack>

        <InvestorStatusForm />
      </Flex>
    </OnboardingContainerV2>
  );
};

export default withCurrentActor(InvestorStatusPageV2);
