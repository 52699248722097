import { menuAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

export const Menu = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    list: {
      borderRadius: `md`,
      borderColor: `grey.200`,
      borderWidth: `0.5px`,
      boxShadow: `card`,
      py: 0,
    },
    item: {
      color: `grey.900`,
      py: 2.5,
      px: 5,
      textStyle: `heading-xs`,
      transition: `.2s background-color ease-in-out`,
      _hover: {
        bg: `grey.50`,
      },
      _focus: {
        bg: `grey.50`,
      },
      _active: {
        bg: `grey.75`,
      },
      _first: {
        borderTopRadius: `md`,
      },
      _last: {
        borderBottomRadius: `md`,
      },
      _notLast: {
        borderBottomWidth: `0.5px`,
        borderBottomColor: `grey.50`,
      },
    },
    divider: {
      my: 0,
      borderColor: `grey.50`,
    },
  }),
});
