import { ReactNode, useRef, LegacyRef, RefObject } from "react";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  FieldError,
  FormState,
} from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { Box, BoxProps, HStack, Text, VStack } from "@chakra-ui/react";

import { InternalLink, MarkdownDocument, Skeleton } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import {
  FormRadioTile,
  TileCheckboxInput,
  FormTextInput,
  FormCheckboxInput,
  FormNumberInput,
} from "@/components/react-hook-form";
import { useScrollToErrorsOnSubmitEffect } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import styles from "@/styles/suitability-markdown-styles.module.css";

import {
  FullSuitabilityOption,
  FullSuitabilityQuestion,
  cadDisclaimerFieldName,
} from "./useSuitabilityForm";

const borderProps = {
  borderRadius: `md`,
  borderColor: `grey.200`,
  borderWidth: 0.5,
  boxShadow: `card`,
};

type CustomSuitabilityOptionInputProps<TFieldValues extends FieldValues> = {
  readonly option: FullSuitabilityOption;
  readonly question: FullSuitabilityQuestion;
  readonly control: Control<TFieldValues>;
};

const CustomSuitabilityOptionInput = <TFieldValues extends FieldValues>({
  option,
  question,
  control,
}: CustomSuitabilityOptionInputProps<TFieldValues>) => {
  const { t } = useTranslation();
  const inputProps = {
    label: ``,
    placeholder: option.placeholder || t`required` || ``,
    control,
    displayError: false,
    name: question.id as Path<TFieldValues>,
  };
  switch (question.renderOptions?.inputType) {
    case `number`:
      return <FormNumberInput {...inputProps} allowNegativeNumbers={false} />;
    default:
      return <FormTextInput {...inputProps} />;
  }
};

const SuitabilityFormContainer = ({
  hideBorder = false,
  ref,
  children,
  ...otherBoxProps
}: BoxProps & {
  readonly children: ReactNode;
  readonly ref?: LegacyRef<HTMLDivElement>;
  readonly hideBorder?: boolean;
}) => (
  <VStack
    bg="white"
    spacing={4}
    ref={ref}
    mt={2}
    alignItems="flex-start"
    {...(!hideBorder && borderProps)}
    {...otherBoxProps}
  >
    {children}
  </VStack>
);

const getColumnTemplateForQuestion = (
  question: FullSuitabilityQuestion,
  country: string,
  version?: number,
) => {
  if (version === 3) {
    const numOfColumns = question.renderOptions?.columns || 1;

    return new Array(numOfColumns).fill(0).reduce((acc) => `${acc} 1fr`, ``);
  }
  if (country === `CA` && [5, 6, 7, 8, 9].includes(question.order))
    return `1fr`;

  if (country === `US` && [6, 7, 8].includes(question.order)) return `1fr`;

  if (country === `US` && [3].includes(question.order)) return `1fr 1fr`;

  return `1fr 1fr 1fr`;
};

type InvestorSuitabilityQuestionTreeProps<TFieldValues extends FieldValues> = {
  readonly question: FullSuitabilityQuestion;
  readonly control: Control<TFieldValues>;
  readonly value?: PathValue<TFieldValues, Path<TFieldValues>>;
  readonly formValues?: TFieldValues;
  readonly country: string;
  readonly formState: FormState<TFieldValues>;
  readonly version?: number;
  readonly containerRef?: RefObject<Element>;
};

const InvestorSuitabilityQuestionTree = <TFieldValues extends FieldValues>({
  question,
  control,
  value,
  formValues,
  formState,
  country,
  version,
  containerRef,
}: InvestorSuitabilityQuestionTreeProps<TFieldValues>) => {
  useScrollToErrorsOnSubmitEffect({
    isSubmitting: formState.isSubmitting,
    containerElement: containerRef?.current || null,
  });

  const optionSelected = question.suitabilityOptions?.find(
    (opt: FullSuitabilityOption) => opt.id === value,
  );
  const formError = formState.errors
    ? (formState.errors[question?.id] as FieldError)
    : undefined;

  return (
    <VStack w="full" alignItems="flex-start" spacing={1} py={2}>
      <VStack
        bg={!!formError ? `red.10` : `unset`}
        aria-invalid={!!formError}
        w="full"
        alignItems="flex-start"
        className="chakra-form-control"
        px={{ base: 4, lg: 6 }}
        py={4}
        spacing={2}
      >
        <div className={styles[`suitability-question-text-markdown`]}>
          <MarkdownDocument markdown={question.text!} />
        </div>
        {question?.description && (
          <TileCheckboxInput.Tooltip>
            <div className={styles[`suitability-markdown`]}>
              <MarkdownDocument markdown={question.description} />
            </div>
          </TileCheckboxInput.Tooltip>
        )}
        {question?.caption && (
          <Text color="grey.700" textStyle="text-sm">
            {question.caption}
          </Text>
        )}
        <VStack alignItems="flex-start" gap={0.5} w="full">
          <FormRadioTile.Group
            size="md"
            gridTemplateColumns={{
              base: `1fr`,
              lg: getColumnTemplateForQuestion(question, country, version),
            }}
          >
            {question?.suitabilityOptions
              ?.slice()
              .sort(
                (a: FullSuitabilityOption, b: FullSuitabilityOption) =>
                  a.order - b.order,
              )
              .map((option: FullSuitabilityOption) =>
                option.custom ? (
                  <CustomSuitabilityOptionInput
                    key={option.id}
                    option={option}
                    question={question}
                    control={control}
                  />
                ) : (
                  <FormRadioTile.Tile
                    key={option.id}
                    name={question.id as Path<TFieldValues>}
                    control={control}
                    value={
                      option.id as PathValue<TFieldValues, Path<TFieldValues>>
                    }
                    alignItems="center"
                  >
                    <Box justifyContent="center" alignItems="flex-start">
                      <Text>{option.text}</Text>
                    </Box>
                  </FormRadioTile.Tile>
                ),
              )}
          </FormRadioTile.Group>
          <Box w="full" pt={1}>
            {!!formError && (
              <TileCheckboxInput.ErrorMessage error={formError} />
            )}
          </Box>
        </VStack>
        {optionSelected && optionSelected?.description && (
          <TileCheckboxInput.Tooltip>
            <div className={styles[`suitability-markdown`]}>
              <MarkdownDocument markdown={optionSelected.description} />
            </div>
          </TileCheckboxInput.Tooltip>
        )}
      </VStack>
      {optionSelected &&
        optionSelected?.nextQuestionIds &&
        optionSelected?.nextQuestionIds.map(
          (innerQ: FullSuitabilityQuestion) => (
            <InvestorSuitabilityQuestionTree
              control={control}
              country={country}
              question={innerQ}
              formValues={formValues}
              formState={formState}
              value={formValues && formValues[innerQ?.id]}
              key={innerQ?.id}
              version={version}
            />
          ),
        )}
    </VStack>
  );
};

interface Props {
  hideBorder?: boolean;
  formProps: ReturnType<typeof useFormQL>;
  formId: string;
  loading: boolean;
  questionTree: FullSuitabilityQuestion;
  country: string;
  renderDisclaimer: boolean;
  version?: number;
}

const InvestorSuitabilityForm: React.FC<Props> = ({
  hideBorder,
  formProps,
  formId,
  loading,
  questionTree,
  country,
  renderDisclaimer,
  version,
}) => {
  const containerRef = useRef<HTMLFormElement>(null);

  if (loading)
    return (
      <SuitabilityFormContainer hideBorder={hideBorder}>
        <Skeleton h="80" w="full" />
      </SuitabilityFormContainer>
    );

  const formAnswers = formProps.watch();

  return (
    <SlideAnimation>
      <form
        id={formId}
        onSubmit={formProps.handleSubmit}
        autoComplete="off"
        ref={containerRef}
      >
        <SuitabilityFormContainer hideBorder={hideBorder}>
          <InvestorSuitabilityQuestionTree
            question={questionTree}
            formValues={formAnswers}
            country={country}
            value={formAnswers[questionTree.id]}
            version={version}
            containerRef={containerRef}
            {...formProps}
          />

          {renderDisclaimer && (
            <HStack
              w="full"
              alignItems="flex-start"
              justifyContent="flex-start"
              px={{ base: 4, lg: 6 }}
              pb={6}
            >
              <FormCheckboxInput
                name={cadDisclaimerFieldName}
                control={formProps.control}
                label={
                  <Text textStyle="text-md">
                    <Trans
                      i18nKey="investor_suitability_canadian_disclaimer"
                      components={{
                        disclaimer: (
                          <InternalLink
                            href="https://www.hiive.com/disclosures"
                            textDecorationLine="underline"
                            target="_blank"
                          />
                        ),
                        relationship: (
                          <InternalLink
                            href="https://www.hiive.com/relationship-disclosure"
                            textDecorationLine="underline"
                            target="_blank"
                          />
                        ),
                      }}
                    />
                  </Text>
                }
              />
            </HStack>
          )}
        </SuitabilityFormContainer>
      </form>
    </SlideAnimation>
  );
};

export default InvestorSuitabilityForm;
