import { useMemo, createContext, ReactNode, useEffect } from "react";
import { match } from "ts-pattern";

import { FrontChatLauncher } from "@/components/front";
import { withCurrentActor, WithCurrentActorProps } from "@/components/hoc";
import { InvestorType } from "@/gql";
import {
  FlagValue,
  useFrontChatU16r,
  useFrontChatTrader,
} from "@/hooks/featureFlags";
import { EnsureFrontChatIsShutdown, useFrontChatRef } from "@/hooks/front";
import { Maybe } from "@/types";

export const FrontChatContext = createContext<{
  ensureFrontChatIsShutdown: EnsureFrontChatIsShutdown | undefined;
}>({
  ensureFrontChatIsShutdown: undefined,
});

const FRONT_CHAT_ID = process.env.NEXT_PUBLIC_FRONT_CHAT_ID;

type FrontChatProviderProps = {
  readonly children: ReactNode;
} & WithCurrentActorProps;

const flagOn = (
  investorType: Maybe<InvestorType> | undefined,
  isU16rEnabled: FlagValue,
  isTraderEnabled: FlagValue,
) =>
  match(investorType)
    .with(InvestorType.UnaccreditedSeller, () => isU16rEnabled)
    .with(InvestorType.Trader, () => isTraderEnabled)
    .otherwise(() => false);

const FrontChatProvider = ({ children, actor }: FrontChatProviderProps) => {
  const isFrontChatU16rEnabled = useFrontChatU16r();
  const isFrontChatTraderEnabled = useFrontChatTrader();

  const frontChatHook = useFrontChatRef();

  const { ensureFrontChatIsShutdown, isInitialized, initialize, frontChat } =
    frontChatHook;

  const contextValue = useMemo(
    () => ({ ensureFrontChatIsShutdown }),
    [ensureFrontChatIsShutdown],
  );

  useEffect(() => {
    const shouldInitializeChat = FRONT_CHAT_ID && initialize && !isInitialized;

    const chatEnabled =
      actor.onboardingComplete &&
      flagOn(
        actor.investorType,
        isFrontChatU16rEnabled,
        isFrontChatTraderEnabled,
      );

    if (shouldInitializeChat && chatEnabled) {
      initialize({
        chatId: FRONT_CHAT_ID,
        userId: actor.id,
        userHash: actor.chatIdHash,
        useDefaultLauncher: false,
        name: actor.name,
        contact: {
          email: actor.email,
        },
      });
    }
  }, [isInitialized, initialize, frontChat, isFrontChatU16rEnabled]);

  return (
    <FrontChatContext.Provider value={contextValue}>
      <>
        {frontChat && (
          <FrontChatLauncher
            isInitialized={isInitialized}
            frontChat={frontChat}
          />
        )}
        {children}
      </>
    </FrontChatContext.Provider>
  );
};

export default withCurrentActor(FrontChatProvider);
