import { isNil } from "lodash/fp";
import { Trans, useTranslation } from "react-i18next";

import { Button, CardBody, CardFooter, HStack, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  CADInstitutionAccreditationFormFields,
  useCADInstitutionAccreditationForm,
} from "@/components/onboarding-v2";
import { CurrentContextDocument } from "@/gql";
import { useCurrentActor } from "@/hooks";

import { AccreditationFormProps } from "./AccreditationForm";

export const CAInstitutionAccreditationForm = ({
  accreditationQuestions,
  formId,
  onSuccess,
  accreditationLastUpdatedAt,
}: AccreditationFormProps) => {
  const actor = useCurrentActor();
  const { t } = useTranslation();

  const { handleSubmit, control, watch, formState } =
    useCADInstitutionAccreditationForm({
      accreditationQuestions,
      accreditationAnswers: actor?.institution?.accreditationAnswers,
      onSuccess,
      mutationOptions: {
        refetchQueries: [CurrentContextDocument],
      },
    });

  return (
    <>
      <CardBody gap={4} mt={2}>
        <Text
          px={6}
          textStyle="heading-lg"
        >{t`firm_fund_must_be_accredited_and_how`}</Text>
        <form onSubmit={handleSubmit} autoComplete="off" id={formId}>
          <CADInstitutionAccreditationFormFields
            control={control}
            watch={watch}
            accreditationQuestions={accreditationQuestions}
            borderColor="transparent"
            boxShadow="none"
            w="full"
          />
        </form>
        <Text textStyle="text-md" px={6}>
          <Trans
            i18nKey="inst_no_longer_qualify"
            components={{ bold: <strong /> }}
            values={{ company: actor.institution?.legalName }}
          />
        </Text>
      </CardBody>
      <CardFooter>
        <HStack justifyContent="flex-end" w="full">
          {!isNil(accreditationLastUpdatedAt) && (
            <Button
              variant="rounded-outline-grey"
              size="xl"
              onClick={onSuccess}
              w={{ base: `full`, lg: 180 }}
              minW="auto"
            >
              {t(`cancel`)}
            </Button>
          )}
          <HiiveButton
            sentryLabel="[AccreditationCardV2/Submit]"
            variant="rounded-solid-salmon"
            type="submit"
            size="xl"
            maxW="unset"
            form={formId}
            isDisabled={!formState?.isValid || !formState?.isDirty}
            isLoading={formState?.isSubmitting}
            w={{ base: `full`, lg: `unset` }}
          >
            {t(`modify`)}
          </HiiveButton>
        </HStack>
      </CardFooter>
    </>
  );
};
