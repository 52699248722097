import { match } from "ts-pattern";

import { HoldingField } from "@/components/unaccredited-seller";
import {
  HoldingDetailsPageHoldingFragment,
  HoldingDetailsPageUnlistedHoldingFragment,
  HoldingType,
  useHoldingDetailsPageMyHoldingsQuery,
} from "@/gql";

const convertExistingHoldingToField = (
  holding:
    | HoldingDetailsPageHoldingFragment
    | HoldingDetailsPageUnlistedHoldingFragment,
) =>
  match(holding)
    .with(
      { __typename: `Holding` },
      ({ company, numSharesV2 }): HoldingField => ({
        name: company.name,
        logoUrl: company.logoUrl,
        companyId: company.id,
        numShares: numSharesV2,
        type: HoldingType.Listed,
      }),
    )
    .with(
      { __typename: `UnlistedHolding` },
      ({ companyName, numSharesV2 }): HoldingField => ({
        name: companyName,
        numShares: numSharesV2,
        type: HoldingType.Unlisted,
        logoUrl: undefined,
        companyId: undefined,
      }),
    )
    .run();

export const useExistingHoldingFields = (
  callback: (holdingFields: HoldingField[]) => void,
) => {
  useHoldingDetailsPageMyHoldingsQuery({
    fetchPolicy: `no-cache`,
    onCompleted: ({ myHoldings }) => {
      const holdings = myHoldings.holdingsV2 || [];
      const holdingFields = holdings.map(convertExistingHoldingToField);
      callback(holdingFields);
    },
  });
};
