import { CardBody, CardFooter, CardHeader, HStack } from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardCompany,
  ActivityCardDisplayId,
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  BrokerBidActivityCardTimestamps,
} from "@/components/common";
import { UserActivityBidFragment } from "@/gql";
import { getBidNumSharesActual, makeUrl, bidLabel } from "@/utils";

const BrokerDashboardBidCardDetails = ({
  bid,
}: {
  readonly bid: UserActivityBidFragment;
}) => {
  const numShares = getBidNumSharesActual(bid);
  const price = bid.pricePerShare;

  return (
    <ActivityCardPricingWrapper>
      <ActivityCardPricingNumSharesAndPrice
        numShares={numShares}
        price={price}
      />
      <ActivityCardPricingTotal numShares={numShares} price={price} />
    </ActivityCardPricingWrapper>
  );
};

export const BrokerDashboardBidCard = ({
  bid,
}: {
  readonly bid: UserActivityBidFragment;
}) => {
  const title = bidLabel(bid.listing);

  return (
    <ActivityCard href={makeUrl(bid)}>
      <CardHeader>
        <HStack justifyContent="space-between">
          <HStack>
            <ActivityCardBadge variant="bid" title={title} />
            <ActivityCardDisplayId displayId={bid.displayId} />
          </HStack>
          <ActivityCardCompany company={bid.company} />
        </HStack>
      </CardHeader>
      <CardBody>
        <BrokerDashboardBidCardDetails bid={bid} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            <BrokerBidActivityCardTimestamps bid={bid} />
          </ActivityCardFooterStatusWrapper>
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};
