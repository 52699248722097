import { WarningCircle } from "@phosphor-icons/react";
import { lowerCase } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Text,
  VStack,
  Image,
  CardHeader,
  CardBody,
} from "@chakra-ui/react";

import { WithQuery, MailtoLink } from "@/components/common";
import { DocumentNotReady, PandadocDocFrame } from "@/components/pandadoc";
import { TransactionDisclaimer } from "@/components/postings";
import {
  Maybe,
  TltAlternateSignerByTokenDocument,
  TltAlternateSignerByTokenQueryResult,
  TltAlternateSignerPageAlternateSignerFragment,
  TltAlternateSignerPageTransactionFragment,
  useRecordTltAlternateSignerSignatureMutation,
  useTltAlternateSignerByTokenQuery,
  useViewTltAlternateSignerTransactionDocumentMutation,
  ViewTltAlternateSignerTransactionDocumentMutation,
} from "@/gql";
import { useColors, useClearAuth, useMutationWithError } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getLongDocumentTitleByDocumentType,
  getIsBroker,
  shareTypeToString,
} from "@/utils";

const PendingModificationCardHeader = () => {
  const { t } = useTranslation();

  const [red600] = useColors([`red.600`]);

  return (
    <CardHeader py={4}>
      <HStack textAlign="left">
        <WarningCircle fill={red600} weight="fill" size="24px" />
        <Text
          textStyle={{
            base: `heading-lg`,
            md: `heading-lg`,
          }}
        >{t`transaction_modification_header`}</Text>
      </HStack>
    </CardHeader>
  );
};

const TransactionDetailsCard = ({
  transaction,
}: {
  readonly transaction: TltAlternateSignerPageTransactionFragment;
}) => {
  const getNumShares = () =>
    !!transaction.pendingModification
      ? transaction.pendingModification.numShares
      : transaction.numShares;

  const getPricePerShare = () =>
    !!transaction.pendingModification
      ? transaction.pendingModification.pricePerShare
      : transaction.pricePerShare;

  const numShares = formatShares(getNumShares());
  const pricePerShare = formatPricePerShare(getPricePerShare());
  const shareType = lowerCase(
    shareTypeToString(transaction.bid.listing.shareTypeV2),
  );

  return (
    <Card>
      {!!transaction.pendingModification && <PendingModificationCardHeader />}
      <CardBody>
        <Grid
          templateRows="repeat(3, 1fr)"
          templateColumns="min-content 1fr"
          rowGap={4}
          columnGap={9}
          textAlign="left"
          alignItems="center"
        >
          <GridItem>
            <Text textStyle="heading-sm" textTransform="uppercase">
              Issuer:
            </Text>
          </GridItem>
          <GridItem>
            <HStack spacing={3}>
              {transaction.bid.company.logoUrl && (
                <Image
                  h={6}
                  src={transaction.bid.company.logoUrl}
                  alt={transaction.bid.company.name}
                />
              )}
              <Text textStyle={{ base: `heading-md`, sm: `heading-lg` }}>
                {transaction.bid.company.name}
              </Text>
            </HStack>
          </GridItem>
          <GridItem>
            <Text textStyle="heading-sm" textTransform="uppercase">
              Security:
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              {numShares} {shareType} shares
            </Text>
          </GridItem>
          <GridItem>
            <Text textStyle="heading-sm" textTransform="uppercase">
              Price:
            </Text>
          </GridItem>
          <GridItem>
            <Text>{pricePerShare} per share</Text>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
};

const ForQuestionsNotice = ({
  alternateSigner,
}: {
  readonly alternateSigner: TltAlternateSignerPageAlternateSignerFragment;
}) => (
  <VStack spacing={1} mt={4}>
    <>
      <Text textStyle="text-md">
        If you have any questions, please contact{` `}
        {getIsBroker(alternateSigner.sender) ? `your broker.` : ``}
      </Text>
      <MailtoLink
        email={alternateSigner.sender.email}
        subject="Questions on Transaction Document"
      >
        <Text textStyle="heading-md">{alternateSigner.sender.email}</Text>
      </MailtoLink>
    </>
  </VStack>
);

const TransactionDocument = ({
  token,
  alternateSigner,
}: {
  readonly token: string;
  readonly alternateSigner: TltAlternateSignerPageAlternateSignerFragment;
}) => {
  const [documentUrl, setDocumentUrl] = useState<string>(``);
  const [isDocumentReady, setIsDocumentReady] = useState<boolean>(false);

  const [viewTltAlternateSignerTransactionDocument, loadingViewDocument] =
    useMutationWithError(
      useViewTltAlternateSignerTransactionDocumentMutation(),
      `viewTltAlternateSignerTransactionDocument`,
    );

  const [recordTltAlternateSignerSignature, loadingRecordSignature] =
    useMutationWithError(
      useRecordTltAlternateSignerSignatureMutation(),
      `recordTltAlternateSignerSignature`,
    );

  const onSuccess = (
    response: ViewTltAlternateSignerTransactionDocumentMutation,
  ) => {
    const url = response.viewTltAlternateSignerTransactionDocument?.url;

    if (!url) return;

    setDocumentUrl(url);
    setIsDocumentReady(true);
  };

  useEffect(() => {
    viewTltAlternateSignerTransactionDocument({
      variables: { token },
    }).then(onSuccess);
  }, []);

  const onDocumentComplete = async () => {
    await recordTltAlternateSignerSignature({
      variables: { token },
      refetchQueries: [TltAlternateSignerByTokenDocument],
    });
  };

  if (loadingViewDocument || loadingRecordSignature)
    return (
      <Center m={20}>
        <Spinner />
      </Center>
    );

  if (!isDocumentReady) return <DocumentNotReady />;

  return (
    <Center w={{ base: `100%`, lg: `900px` }}>
      <PandadocDocFrame
        url={documentUrl}
        title={`${getLongDocumentTitleByDocumentType(
          alternateSigner.document.type,
        )} Embed`}
        w="full"
        h="754px"
        onComplete={onDocumentComplete}
      />
    </Center>
  );
};

const Page = ({
  alternateSigner,
  token,
}: {
  readonly alternateSigner?: Maybe<TltAlternateSignerPageAlternateSignerFragment>;
  readonly token: string;
}) => {
  const { t } = useTranslation();

  if (!alternateSigner) return null;

  const { id, document, transaction } = alternateSigner;

  const iSigned = document.signers.some(
    ({ alternateSignerId }) => alternateSignerId === id,
  );
  const documentTitle = getLongDocumentTitleByDocumentType(document.type);

  const isPendingModification = !!transaction.pendingModification;

  return (
    <Flex
      bg="grey.50"
      direction="column"
      alignItems="center"
      minW="100vw"
      minH="100vh"
      pt="88px"
      pb={14}
      px={{ base: 4, lg: 8 }}
    >
      {iSigned ? (
        <>
          <Text textAlign="center" textStyle="heading-3xl" mb={10}>
            {t(`alt_signer_document_signed_title`, {
              documentTitle,
              companyName: transaction.bid.company.name,
            })}
          </Text>
          <Center mb={14} maxW={640} w="full">
            <TransactionDetailsCard transaction={transaction} />
          </Center>
          <Text textAlign="center" textStyle="text-md">
            {t(`alt_signer_document_signed`)}
          </Text>
        </>
      ) : (
        <>
          <Text textAlign="center" textStyle="heading-3xl" mb={10}>
            {t(
              isPendingModification
                ? `alt_signer_document_pending_mod_title`
                : `alt_signer_document_title`,
              {
                documentTitle,
                companyName: transaction.bid.company.name,
              },
            )}
          </Text>
          <VStack mb={4} maxW={640} w="full">
            <TransactionDetailsCard transaction={transaction} />
          </VStack>
          <Text textAlign="center" textStyle="text-md">
            {isPendingModification
              ? t(`alt_signer_document_pending_mod_page_body`, {
                  companyName: transaction.bid.company.name,
                })
              : t(`alt_signer_document_page_body`, { documentTitle })}
          </Text>
        </>
      )}
      <ForQuestionsNotice alternateSigner={alternateSigner} />
      {isPendingModification ? null : (
        <>
          <Center w="full" my={10}>
            <Divider
              w={590}
              borderTop="1px"
              borderColor="grey.200 !important"
            />
          </Center>
          <Center w="full" maxW={900} mt={1}>
            <TransactionDisclaimer />
          </Center>
          <TransactionDocument
            alternateSigner={alternateSigner}
            token={token}
          />
        </>
      )}
    </Flex>
  );
};

const TltAlternateSignerPageWithToken = ({
  token,
}: {
  readonly token: string;
}) => {
  const query = useTltAlternateSignerByTokenQuery({ variables: { token } });
  return (
    <WithQuery query={query}>
      {({ data }: TltAlternateSignerByTokenQueryResult) => (
        <Page token={token} alternateSigner={data?.tltAlternateSignerByToken} />
      )}
    </WithQuery>
  );
};

const TltAlternateSignerPage = ({ token }: { readonly token?: string }) => {
  useClearAuth();

  if (!token) return null;

  return <TltAlternateSignerPageWithToken token={token} />;
};

export default TltAlternateSignerPage;
