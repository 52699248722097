import { isNil } from "lodash/fp";
import { Trans, useTranslation } from "react-i18next";

import { Button, CardBody, CardFooter, HStack, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  useInvestorAccreditationForm,
  InvestorAccreditationFormFields,
} from "@/components/onboarding-v2";
import {
  AccreditationQuestion,
  CurrentContextDocument,
  InvestorStatus,
  useCurrentAccreditationQuestionGroupQuery,
} from "@/gql";
import { useCurrentActor } from "@/hooks";

import { AccreditationFormProps } from "./AccreditationForm";

export const IndividualAccreditationForm = ({
  formId,
  onSuccess,
  accreditationLastUpdatedAt,
}: Omit<AccreditationFormProps, `accreditationQuestions`>) => {
  const actor = useCurrentActor();
  const { t } = useTranslation();
  const { data } = useCurrentAccreditationQuestionGroupQuery({
    variables: {
      investorType: InvestorStatus.Individual,
      countryId: actor.country?.id || ``,
    },
  });

  const accreditationQuestions =
    data?.currentAccreditationQuestionGroup?.questions.filter(
      (question: AccreditationQuestion) => question.accredited,
    ) || [];

  const { watch, control, handleSubmit, formState } =
    useInvestorAccreditationForm({
      accreditationQuestions,
      accreditationAnswers: actor.accreditationAnswers,
      onSuccess,
      mutationOptions: {
        refetchQueries: [CurrentContextDocument],
      },
    });

  return (
    <>
      <CardBody gap={4} mt={2}>
        <Text px={6} textStyle="heading-lg">{t`must_be_accredited`}</Text>
        <form autoComplete="off" id={formId} onSubmit={handleSubmit}>
          <InvestorAccreditationFormFields
            control={control}
            watch={watch}
            accreditationQuestions={accreditationQuestions}
            borderColor="transparent"
            boxShadow="none"
            w="full"
          />
        </form>
        <Text textStyle="text-md" px={6}>
          <Trans
            i18nKey="no_longer_qualify"
            components={{ bold: <strong /> }}
          />
        </Text>
      </CardBody>
      <CardFooter>
        <HStack justifyContent="flex-end" w="full">
          {!isNil(accreditationLastUpdatedAt) &&
            actor.accreditationAnswers?.length > 0 && (
              <Button
                variant="rounded-outline-grey"
                size="xl"
                onClick={onSuccess}
                w={{ base: `full`, lg: 180 }}
                minW="auto"
              >
                {t(`cancel`)}
              </Button>
            )}
          <HiiveButton
            sentryLabel="[AccreditationCardV2/Submit]"
            variant="rounded-solid-salmon"
            type="submit"
            size="xl"
            maxW="unset"
            form={formId}
            isDisabled={!formState?.isValid}
            isLoading={formState?.isSubmitting}
            w={{ base: `full`, lg: `unset` }}
          >
            {t(`modify`)}
          </HiiveButton>
        </HStack>
      </CardFooter>
    </>
  );
};
