import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { ReactNode, useCallback, useContext } from "react";

import { Box, HStack, Text, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import { TableCell, TableHeader } from "@/components/table";
import { IssuerTransactionSortField, SortDirection } from "@/gql";
import * as datetime from "@/utils/datetime";

import { IssuerTooltip } from "./IssuerTooltip";
import { IssuerTransactionsTableContext } from "./Transactions/IssuerTransactionsProvider";

const TOOLTIP_CELL_DELAY = 400;

type DateTableCellProps = {
  children: string;
};

type TooltipTableCellProps = {
  children: ReactNode;
  label: string | undefined;
  shouldWrapText?: boolean;
};

export function DateTableCell({ children }: DateTableCellProps) {
  return <TableCell>{datetime.format(`MMM DD, YYYY`, children)}</TableCell>;
}

export function TooltipTableCell({
  children,
  label,
  shouldWrapText,
}: TooltipTableCellProps) {
  return (
    <TableCell cursor="text">
      <IssuerTooltip label={label} openDelay={TOOLTIP_CELL_DELAY}>
        <Box
          display="inline-block"
          fontSize={16}
          fontWeight={400}
          wordBreak={shouldWrapText ? `break-word` : `unset`}
          whiteSpace={shouldWrapText ? `normal` : `unset`}
        >
          {children}
        </Box>
      </IssuerTooltip>
    </TableCell>
  );
}

export function NullTableCell() {
  return <TableCell>–</TableCell>;
}

export function SkeletonTableCell() {
  return (
    <TableCell>
      <Skeleton w={100} h={6} />
    </TableCell>
  );
}

interface ActiveSortButtonProps {
  readonly direction: SortDirection;
  readonly isActive: boolean;
}

function SortButton({ direction, isActive }: ActiveSortButtonProps) {
  return (
    <VStack gap={0}>
      <Box
        as={CaretUp}
        size={11}
        weight="fill"
        color={
          isActive && direction === SortDirection.Asc ? `grey.900` : `grey.200`
        }
      />
      <Box
        as={CaretDown}
        size={11}
        mt={-1}
        weight="fill"
        color={
          isActive && direction === SortDirection.Desc ? `grey.900` : `grey.200`
        }
      />
    </VStack>
  );
}

const DESKTOP_TOOLTIP_DELAY = 400;

interface SortableTableHeaderProps {
  readonly field: IssuerTransactionSortField;
  readonly name: string;
  readonly rightAlign?: boolean;
  readonly toolTipLabel?: string;
}

export function SortableTableHeader({
  field,
  name,
  rightAlign,
  toolTipLabel,
}: SortableTableHeaderProps) {
  const { onSort, sortBy } = useContext(IssuerTransactionsTableContext);

  const isActive = field === sortBy.field;

  const handleClick = useCallback(() => {
    const direction =
      isActive && sortBy.direction === SortDirection.Asc
        ? SortDirection.Desc
        : SortDirection.Asc;
    onSort(direction, field);
  }, [onSort, field]);

  return (
    <TableHeader>
      <HStack justify={rightAlign ? `end` : `start`}>
        <IssuerTooltip
          placement="bottom"
          label={toolTipLabel}
          openDelay={DESKTOP_TOOLTIP_DELAY}
        >
          <HStack cursor="pointer" onClick={handleClick}>
            <Text>{name}</Text>
            <SortButton direction={sortBy.direction} isActive={isActive} />
          </HStack>
        </IssuerTooltip>
      </HStack>
    </TableHeader>
  );
}

interface IssuerTableHeaderProps {
  readonly name: string;
  readonly toolTipLabel?: string;
}

export function IssuerTableHeader({
  toolTipLabel,
  name,
}: IssuerTableHeaderProps) {
  return (
    <TableHeader>
      <HStack>
        <IssuerTooltip
          placement="bottom"
          label={toolTipLabel}
          openDelay={DESKTOP_TOOLTIP_DELAY}
        >
          <Text>{name}</Text>
        </IssuerTooltip>
      </HStack>
    </TableHeader>
  );
}
