import Link from "next/link";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";

import { Hiive50BadgeLong, WatchlistButton } from "@/components/common";
import {
  CompanyMarketActivity,
  CompanyPricing,
  CompanySecuritySpecialistContact,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyPageCompanyDescriptionCardCompanyFragment,
  InvestorType,
  UserWithInstitutionFragment,
} from "@/gql";
import { hasInvestorType } from "@/utils";

import { SuperadminDetailsTile } from "./SuperadminDetailsTile";

export const CompanyDescriptionCard = withCurrentActor(
  ({
    company,
    actor,
  }: {
    readonly company: CompanyPageCompanyDescriptionCardCompanyFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isMobileView = useBreakpointValue(
      {
        base: true,
        sm: false,
      },
      { ssr: false },
    );
    const isSeller = hasInvestorType(actor, InvestorType.Seller);
    const isTrader = hasInvestorType(actor, InvestorType.Trader);
    const isBroker = hasInvestorType(actor, InvestorType.Broker);

    const showWatchlistButton = isSeller || isTrader || isBroker;

    const showH50Badge = company.activeOnHiiveFiftyIndex;

    return (
      <Card variant="darkened-footer">
        <CardHeader>
          <HStack minH={10}>
            {company.logoUrl && (
              <Image
                h={{ base: `24px`, sm: `30px` }}
                src={company.logoUrl}
                alt={company.name}
                mr={{ base: 2, sm: 4 }}
              />
            )}
            <Text
              as="h1"
              w="full"
              lineHeight="initial"
              textStyle={{
                base: `heading-2xl`,
                md: `heading-4xl`,
              }}
            >
              {company.name}
            </Text>
            {showWatchlistButton && (
              <Box>
                <WatchlistButton
                  companyId={company.id}
                  isCollapsed={isMobileView}
                />
              </Box>
            )}
          </HStack>
        </CardHeader>
        <CardBody>
          <VStack gap={isMobileView ? [3, 4] : [2, 4]} alignItems="flex-start">
            {showH50Badge && (
              <Link href="/hiive50">
                <Hiive50BadgeLong width="100%" />
              </Link>
            )}
            <Text as="h4" w="full" textStyle="heading-sm">
              Current Pricing
            </Text>
            <CompanyPricing company={company} />
            <Text as="h4" w="full" textStyle="heading-sm">
              Market Activity
            </Text>
            <CompanyMarketActivity company={company} />
            {actor.isSuperadmin && <SuperadminDetailsTile company={company} />}
          </VStack>
        </CardBody>
        <CardBody>
          <Text as="h4" w="full" textStyle="heading-sm" mb={2}>
            About
          </Text>
          <Text>{company.description}</Text>
        </CardBody>
        <CardFooter>
          <CompanySecuritySpecialistContact company={company} />
        </CardFooter>
      </Card>
    );
  },
);
