import capitalize from "lodash/capitalize";
import { useTranslation } from "react-i18next";

import { ShareDetailsDisclaimer } from "@/components/common";
import { ListingViewerRoundedSharesTooltip } from "@/components/tooltip";

const ListingViewerRoundingDisclaimer = ({
  shareText,
}: {
  readonly shareText: string;
}) => {
  const { t } = useTranslation();
  return (
    <ShareDetailsDisclaimer
      tooltip={<ListingViewerRoundedSharesTooltip shareText={shareText} />}
    >
      {capitalize(t(`listing_viewer_rounding_disclaimer`, { shareText }))}
    </ShareDetailsDisclaimer>
  );
};

export default ListingViewerRoundingDisclaimer;
