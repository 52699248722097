import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardProps,
  Divider,
  HStack,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";

import { constants } from "@/utils";

import AmountDueCardHeader from "./AmountDueCardHeader";

const {
  HIIVE_BANKING_INFO: {
    BANK_NAME,
    BENEFICIARY_ADDRESS,
    BENEFICIARY_NAME,
    BANK_ADDRESS,
    SWIFT_CODE,
  },
} = constants;

export type WireInformationCardProps = CardProps & {
  accountNumber: string;
  amount: number;
  completedAt?: string;
  routingNumber: string;
  showAmountDue?: boolean;
};

const WireInformationCard = ({
  accountNumber,
  amount,
  completedAt,
  routingNumber,
  showAmountDue = true,
  ...props
}: WireInformationCardProps) => {
  const { t } = useTranslation(`execution`);

  return (
    <Card variant="flat" p={0} w="full" borderColor="grey.800" {...props}>
      {showAmountDue && (
        <AmountDueCardHeader amount={amount} completedAt={completedAt} />
      )}
      <CardBody p={0}>
        <VStack p={4} gap={1} alignItems="start">
          <Text color="grey.400" lineHeight={1.125} textStyle="heading-3xs">
            {t(`beneficiary`)}
          </Text>
          <Text color="grey.900" textStyle="heading-md">
            {BENEFICIARY_NAME}
          </Text>
          <Text color="grey.900" textStyle="text-sm">
            {BENEFICIARY_ADDRESS}
          </Text>
        </VStack>
        <Divider borderColor="grey.100" />
        <VStack p={4} alignItems="start">
          <VStack gap={1} alignItems="start">
            <Text color="grey.400" lineHeight={1.125} textStyle="heading-3xs">
              {t(`wire_details`)}
            </Text>
            <Text color="grey.900" textStyle="heading-md">
              {BANK_NAME}
            </Text>
            <Text color="grey.900" textStyle="text-sm">
              {BANK_ADDRESS}
            </Text>
          </VStack>
          <List as={VStack} w="full" alignItems="start" gap={2}>
            <ListItem as={HStack} w="full" justifyContent="space-between">
              <Text color="grey.500" textStyle="heading-xs">
                {t(`account_number`)}
              </Text>
              <Text textStyle="text-md">{accountNumber}</Text>
            </ListItem>
            <ListItem as={HStack} w="full" justifyContent="space-between">
              <Text color="grey.500" textStyle="heading-xs">
                {t(`routing_number`)}
              </Text>
              <Text textStyle="text-md">{routingNumber}</Text>
            </ListItem>
            <ListItem as={HStack} w="full" justifyContent="space-between">
              <Text color="grey.500" textStyle="heading-xs">
                {t(`swift_code`)}
              </Text>
              <Text textStyle="text-md">{SWIFT_CODE}</Text>
            </ListItem>
          </List>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default WireInformationCard;
