import { WithQuery } from "@/components/common";
import {
  PostListingHeroBanner,
  PreListingHeroBannerV2,
  HeroBannerSkeleton,
} from "@/components/dashboard";
import { usePostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery } from "@/gql";

export const HeroBannerV2 = ({ id }: { id: string }) => {
  const query =
    usePostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery({
      variables: { id },
    });

  return (
    <WithQuery query={query} fallback={<HeroBannerSkeleton />}>
      {({ data: { companyById: company } }) =>
        company.activity.myListings.length > 0 ? (
          <PostListingHeroBanner companyName={company.name} />
        ) : (
          <PreListingHeroBannerV2 id={id} />
        )
      }
    </WithQuery>
  );
};
