import { t } from "i18next";
import capitalize from "lodash/capitalize";

import { ShareDetailsDisclaimer } from "@/components/common";
import { ListingSellerRoundedSharesTooltip } from "@/components/tooltip";

const ListingSellerRoundingDisclaimer = () => {
  const shareText = `share`;

  return (
    <ShareDetailsDisclaimer tooltip={<ListingSellerRoundedSharesTooltip />}>
      {capitalize(t(`listing_viewer_rounding_disclaimer`, { shareText }))}
    </ShareDetailsDisclaimer>
  );
};

export default ListingSellerRoundingDisclaimer;
