import { useTranslation } from "react-i18next";

import {
  IssuerPageHeader,
  IssuerPortalPageContainer,
} from "@/components/issuers";

import { AccountSettingsTabs } from "./AccountSettingsTabs";
import DetailsCard from "./DetailsCard";

const AccountDetailsPage = () => {
  const { t } = useTranslation();

  return (
    <IssuerPortalPageContainer
      header={
        <>
          <IssuerPageHeader title={t(`account_settings`)} />
          <AccountSettingsTabs />
        </>
      }
      content={<DetailsCard />}
    />
  );
};

export default AccountDetailsPage;
