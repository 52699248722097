import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { DashboardModal } from "@uppy/react";
import Transloadit from "@uppy/transloadit";
import { useState } from "react";

import { WithMutation } from "@/components/common";
import {
  FileUpload,
  TransloaditParams,
  TransloaditTemplateKind,
  useCreateFileUploadMutation,
  useGenerateTransloaditParamsMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";
import { extractS3ObjectRef } from "@/utils";

const defaultRestrictions = {
  allowedFileTypes: [`.pdf`, `.doc`, `image/*`],
  maxNumberOfFiles: 1,
  maxFileSize: 25 * 1024 * 1024,
};

interface FileUploadParams {
  readonly filename: string;
  readonly contentType: string;
  readonly url: string;
}

const UppyDashboard = ({
  transloaditParams,
  open,
  onClose,
  onComplete,
}: {
  readonly transloaditParams: TransloaditParams;
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onComplete: (params: FileUploadParams) => void;
}) => {
  const [uppy] = useState(() => {
    const uppyInstance = new Uppy({
      restrictions: defaultRestrictions,
      autoProceed: true,
    }).use(Transloadit, {
      // eslint-disable-next-line
      // @ts-ignore
      params: transloaditParams.params,
      waitForEncoding: true,
      signature: transloaditParams.signature,
      fields: {},
      limit: 0,
    });

    uppyInstance.on(`transloadit:result`, (stepName, result) => {
      if (stepName === `:original` && result?.ssl_url) {
        onComplete({
          url: result?.ssl_url,
          filename: result?.original_name,
          contentType: result?.mime,
        });
      }
    });

    return uppyInstance;
  });

  return <DashboardModal uppy={uppy} open={open} onRequestClose={onClose} />;
};

const FileUploader = ({
  button,
  onUploadComplete,
}: {
  readonly button: ({
    onClick,
  }: {
    readonly onClick: () => void;
  }) => JSX.Element;
  readonly onUploadComplete: (fileUpload: FileUpload) => void;
}) => {
  const [open, setOpen] = useState(false);

  const [createFileUpload] = useMutationWithError(
    useCreateFileUploadMutation(),
    `createFileUpload`,
  );

  const onComplete = async ({
    url,
    filename,
    contentType,
  }: FileUploadParams) => {
    const s3ObjectRef = extractS3ObjectRef(url);

    if (s3ObjectRef) {
      const mutation = await createFileUpload({
        variables: {
          input: {
            s3ObjectRef,
            filename,
            contentType,
          },
        },
      });

      const fileUpload = mutation?.createFileUpload?.fileUpload;

      if (fileUpload) {
        onUploadComplete(fileUpload as FileUpload);
      }
    }
  };

  return (
    <WithMutation
      mutation={useGenerateTransloaditParamsMutation({
        variables: { templateKind: TransloaditTemplateKind.PrivateUploads },
      })}
    >
      {({ data }) => (
        <>
          {button({ onClick: () => setOpen(true) })}

          <UppyDashboard
            transloaditParams={data.generateTransloaditParams}
            open={open}
            onClose={() => setOpen(false)}
            onComplete={onComplete}
          />
        </>
      )}
    </WithMutation>
  );
};
export default FileUploader;
