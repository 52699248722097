import { Diamond } from "@phosphor-icons/react";
import { ComponentProps, ReactNode } from "react";
import { match } from "ts-pattern";

import {
  Box,
  HStack,
  TextProps,
  VStack,
  Text,
  chakra,
  StackProps,
} from "@chakra-ui/react";

export enum MilestoneTimelineIcons {
  Fill = `fill`,
  Active = `active`,
  Outline = `outline`,
}

export enum MilestoneTimelineLines {
  Fill = `fill`,
  Active = `active`,
  Outline = `outline`,
}

type MilestoneTimelineProps = {
  children: ReactNode;
};

type MilestoneTimelineNodeProps = {
  indicator?: ReactNode;
} & StackProps;

type MilestoneTimelineIndicatorProps = {
  line?: ReactNode;
  icon: ReactNode;
} & StackProps;

type MilestoneTimelineIconProps = {
  variant: MilestoneTimelineIcons;
};

type MilestoneTimelineContentProps = {
  label: ReactNode;
  description?: ReactNode;
  after?: ReactNode;
};

type MilestoneTimelineLineProps = ComponentProps<typeof chakra.svg>;

type MilestoneTimelineLabelProps = TextProps;
type MilestoneTimelineDescriptionProps = TextProps;
type MilestoneTimelineAfterProps = TextProps;

const MILESTONE_TIMELINE_ICON_BOX_SIZE = 4;

export function MilestoneTimeline({ children }: MilestoneTimelineProps) {
  return (
    <VStack spacing={0} gap={0} w="full">
      {children}
    </VStack>
  );
}

export function MilestoneTimelineLine(props: MilestoneTimelineLineProps) {
  return (
    <chakra.svg
      w="2px"
      viewBox="0 0 2 1"
      preserveAspectRatio="none"
      stroke="plum.1000"
      h={9}
      my={-1}
      {...props}
    >
      <chakra.line y1={0} y2={1} x1={1} x2={1} strokeWidth={1} />
    </chakra.svg>
  );
}

export function MilestoneTimelineIcon({ variant }: MilestoneTimelineIconProps) {
  return (
    <Box
      w={MILESTONE_TIMELINE_ICON_BOX_SIZE}
      h={MILESTONE_TIMELINE_ICON_BOX_SIZE}
      position="relative"
    >
      {match(variant)
        .with(MilestoneTimelineIcons.Fill, () => (
          <Diamond width="100%" height="100%" weight="fill" />
        ))
        .with(MilestoneTimelineIcons.Outline, () => (
          <>
            <Diamond width="100%" height="100%" weight="fill" fill="white" />
            <Diamond
              width="100%"
              height="100%"
              weight="bold"
              style={{ position: `absolute`, top: 0, left: 0 }}
            />
          </>
        ))
        .with(MilestoneTimelineIcons.Active, () => (
          <>
            <Diamond
              width="100%"
              height="100%"
              weight="fill"
              fill="white"
              style={{ position: `absolute`, top: 0, left: 0 }}
            />
            <Diamond
              width="100%"
              height="100%"
              weight="bold"
              style={{ position: `absolute`, top: 0, left: 0 }}
            />

            <Diamond
              width="45%"
              height="45%"
              weight="fill"
              style={{
                position: `absolute`,
                top: `50%`,
                left: `50%`,
                transform: `auto`,
                translate: `-50% -50%`,
              }}
            />
          </>
        ))
        .exhaustive()}
    </Box>
  );
}

export function MilestoneTimelineLabel(props: MilestoneTimelineLabelProps) {
  return <Text textStyle="heading-xs" lineHeight={4} {...props} />;
}

export function MilestoneTimelineDescription(
  props: MilestoneTimelineDescriptionProps,
) {
  return <Text fontSize={12} color="grey.500" lineHeight="18px" {...props} />;
}

export function MilestoneTimelineAfter(props: MilestoneTimelineAfterProps) {
  return <Text fontSize={12} color="grey.800" lineHeight="18px" {...props} />;
}

export function MilestoneTimelineContent({
  label,
  description,
  after,
}: MilestoneTimelineContentProps) {
  return (
    <HStack w="full" alignItems="flex-start">
      <VStack alignItems="flex-start" gap={4}>
        {label}
        {description}
      </VStack>
      {after && <Box flexGrow={1} />}
      {after}
    </HStack>
  );
}

export function MilestoneTimelineIndicator({
  line,
  icon,
  ...restProps
}: MilestoneTimelineIndicatorProps) {
  return (
    <VStack gap={0} alignItems="center" justifyContent="center" {...restProps}>
      {icon}
      <Box h="full">{line}</Box>
    </VStack>
  );
}

export function MilestoneTimelineNode({
  indicator,
  children,
  ...restProps
}: MilestoneTimelineNodeProps) {
  return (
    <HStack
      alignItems="flex-start"
      gap={3}
      px={6}
      w="full"
      textAlign="left"
      {...restProps}
    >
      {indicator}
      {children}
    </HStack>
  );
}
