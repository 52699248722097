import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  CardBody,
  FormControl,
  Image,
  HStack,
  Text,
  VStack,
  Grid,
  Box,
} from "@chakra-ui/react";

import { FadeInOut } from "@/components/common";
import { FormNumberInput, TextInput } from "@/components/form";
import { HoldingField } from "@/components/unaccredited-seller";
import { HoldingSelectCompanyFragment, HoldingType } from "@/gql";

const ListedHoldingCardContents = ({
  inputId,
  company,
}: {
  inputId: string;
  company: HoldingSelectCompanyFragment;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <HStack align="center">
        {company?.logoUrl && (
          <Box maxW={6}>
            <Image
              h="full"
              maxH={6}
              src={company.logoUrl}
              alt={company.name}
              mr={{ base: 0, sm: 1 }}
            />
          </Box>
        )}
        <Text fontWeight={500} textStyle="heading-lg">
          {company.name}
        </Text>
      </HStack>

      <div style={{ display: `none` }}>
        <TextInput
          type="hidden"
          name={`${inputId}.type`}
          value={HoldingType.Listed}
        />
        <TextInput type="hidden" name={`${inputId}.value`} value={company.id} />
      </div>

      <FormNumberInput
        name={`${inputId}.numShares`}
        label={t(`seller_lot_details_shares_owned_multiple_holdings`)}
        placeholder={t(`approximate_number_of_shares`)}
      />
    </>
  );
};

const UnlistedHoldingCardContents = ({ inputId }: { inputId: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ display: `none` }}>
        <TextInput
          name={`${inputId}.type`}
          type="hidden"
          value={HoldingType.Unlisted}
        />
      </div>

      <FormControl>
        <TextInput
          name={`${inputId}.value`}
          type="text"
          isRequired
          label={t(`seller_lot_details_company_name`)}
        />
      </FormControl>
      <FormNumberInput
        name={`${inputId}.numShares`}
        label={t(`seller_lot_details_shares_owned_multiple_holdings`)}
        placeholder={t(`approximate_number_of_shares`)}
      />
    </>
  );
};

const HoldingInputCard = ({
  company,
  holding,
  inputId,
  handleRemove,
}: {
  inputId: string;
  holding: HoldingField;
  company: HoldingSelectCompanyFragment;

  handleRemove: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <FadeInOut duration={0.5}>
      <Card>
        <CardBody
          // TODO: Figure out why behaviour changes when base not present
          py={{ base: 4, lg: 4 }}
          pl={{ base: 6, lg: 6 }}
          pr={{ base: 4, lg: 4 }}
        >
          <Grid gridAutoFlow="column" templateColumns="4fr 1fr" gap={4}>
            <VStack align="start" gap={8} py={{ base: 2, lg: 3 }}>
              {holding.type === HoldingType.Unlisted ? (
                <UnlistedHoldingCardContents inputId={inputId} />
              ) : (
                <ListedHoldingCardContents
                  company={company}
                  inputId={inputId}
                />
              )}
            </VStack>

            <Button
              variant="boxed-salmon"
              size="sm"
              minH={{ base: `4`, sm: `6` }}
              h={{ base: `4`, sm: `6` }}
              minW={{ base: `16`, sm: `none` }}
              onClick={handleRemove}
            >
              <Text
                textStyle={{ base: `heading-3xs`, sm: `heading-xs` }}
                as="span"
              >
                {t(`seller_lot_details_remove_holding`)}
              </Text>
            </Button>
          </Grid>
        </CardBody>
      </Card>
    </FadeInOut>
  );
};

export { HoldingInputCard };
