import isEmpty from "lodash/isEmpty";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Box, HStack, Text, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import { useIssuerTransactionsSummaryQuery } from "@/gql";
import { useIsDesktop } from "@/hooks";

const DESKTOP_CARD_WIDTH = `190px`;
const MOBILE_CARD_WIDTH = `114px`;

interface IssuerTransactionsSummaryCardProps {
  readonly count: number;
  readonly label: string;
}

function IssuerTransactionsSummarySkeleton() {
  const isDesktop = useIsDesktop();
  const cardWidth = isDesktop ? DESKTOP_CARD_WIDTH : MOBILE_CARD_WIDTH;

  return (
    <HStack spacing={2}>
      <Skeleton h={20} w={cardWidth} />
      <Skeleton h={20} w={cardWidth} />
      <Skeleton h={20} w={cardWidth} />
    </HStack>
  );
}

function IssuerTransactionsSummaryCard({
  count,
  label,
}: IssuerTransactionsSummaryCardProps) {
  const isDesktop = useIsDesktop();
  const cardWidth = isDesktop ? DESKTOP_CARD_WIDTH : MOBILE_CARD_WIDTH;

  return (
    <Box
      minW={cardWidth}
      px={{ base: 3, lg: 5 }}
      py={{ base: 2, lg: 3 }}
      border="grey-border"
      bg="grey.15"
      borderRadius="md"
    >
      <VStack spacing={4} alignItems="flex-start">
        <Text textStyle="text-sm">{label}</Text>
        <Text textStyle="heading-xl">{count}</Text>
      </VStack>
    </Box>
  );
}

export const IssuerTransactionsSummary = () => {
  const { t } = useTranslation(`issuers`);
  const { data, loading } = useIssuerTransactionsSummaryQuery();

  const issuerTransactionsSummary = useMemo(
    () => data?.issuerTransactionsSummary,
    [data],
  );

  if (loading) {
    return <IssuerTransactionsSummarySkeleton />;
  }

  if (isEmpty(issuerTransactionsSummary)) {
    return null;
  }

  const { closed, inProgress, inReview } = issuerTransactionsSummary;

  return (
    <HStack gap={{ base: 2, md: 4 }}>
      <IssuerTransactionsSummaryCard count={inReview} label={t(`in_review`)} />
      <IssuerTransactionsSummaryCard
        count={inProgress}
        label={t(`in_progress`)}
      />
      <IssuerTransactionsSummaryCard count={closed} label={t(`closed`)} />
    </HStack>
  );
};
