import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormTextInput, FormSelectInput } from "@/components/react-hook-form";
import {
  InvitationType,
  IssuerInvitationUsersDocument,
  RoleType,
  useSendIssuerInvitationMutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import { constants } from "@/utils";

const { EMAIL_REGEX } = constants;

type ValidationMessages = {
  email: {
    required: string;
    matches: string;
  };
  userType: {
    required: string;
  };
};

const ROLE_TYPE_KEYS = Object.values(RoleType);

const getValidationSchema = ({ email, userType }: ValidationMessages) =>
  Yup.object({
    userType: Yup.mixed().oneOf(ROLE_TYPE_KEYS).required(userType.required),
    email: Yup.string()
      .required(email.required)
      .matches(EMAIL_REGEX, email.matches),
  });

type IssuerInviteUserModalSchema = Yup.InferType<
  ReturnType<typeof getValidationSchema>
>;

export function IssuersInviteUserModal() {
  const { t } = useTranslation(`issuers`);
  const validationMessages: ValidationMessages = {
    email: {
      required: t(`email_is_required`),
      matches: t(`email_invalid`),
    },
    userType: {
      required: t(`user_type_is_required`),
    },
  };

  const { closeModal } = useModal();
  const { successToast } = useCustomToast();

  const [sendInvitation, { loading }] = useSendIssuerInvitationMutation({
    refetchQueries: [IssuerInvitationUsersDocument],
  });

  const { control, handleSubmit, setError } =
    useForm<IssuerInviteUserModalSchema>({
      resolver: yupResolver(getValidationSchema(validationMessages)),
      defaultValues: {
        userType: RoleType.Admin,
        email: ``,
      },
    });

  const onSubmit: SubmitHandler<IssuerInviteUserModalSchema> = useCallback(
    async ({ userType, email }) => {
      const { data } = await sendInvitation({
        variables: {
          input: {
            email,
            roles: [userType],
            invitationType: InvitationType.Issuer,
          },
        },
      });

      if (data?.sendInvitation?.invitation) {
        closeModal();
        successToast(t(`send_invitation_success`));
      }

      if (data?.sendInvitation.errors) {
        const { errors } = data.sendInvitation;
        errors.forEach(({ field, message }) =>
          setError(field as keyof IssuerInviteUserModalSchema, {
            message,
          }),
        );
      }
    },
    [],
  );

  return (
    <HiiveModalContentWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HiiveModalHeader>
          <Text>{t(`invite_user`)}</Text>
        </HiiveModalHeader>
        <ModalBody>
          <VStack alignItems="flex-start" spacing={6}>
            <Text>{t(`issuer_portal_expiration_notice`)}</Text>
            <FormSelectInput
              label={t(`role`)}
              name="userType"
              control={control}
              formLabelProps={{
                variant: `issuer`,
              }}
              options={[
                {
                  label: t(`admin`),
                  value: RoleType.Admin,
                },
                {
                  label: t(`viewer`),
                  value: RoleType.Viewer,
                },
              ]}
            />
            <FormTextInput
              label={t(`email`)}
              placeholder={t(`enter_an_email`)}
              name="email"
              type="email"
              formLabelProps={{
                variant: `issuer`,
              }}
              control={control}
            />
          </VStack>
        </ModalBody>
        <HiiveModalFooter>
          <ButtonFooterWrapper>
            <HiiveSubmitButton
              sentryLabel="[IssuerInviteUser/Submit]"
              submitText={t(`send_invite`)}
              type="submit"
              isLoading={loading}
            />
          </ButtonFooterWrapper>
        </HiiveModalFooter>
      </form>
    </HiiveModalContentWrapper>
  );
}
