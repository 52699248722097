import { ReactNode } from "react";

import { Flex } from "@chakra-ui/react";

const ShareDetailsStatsWrapper = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <Flex
    justify="space-between"
    wrap="wrap"
    gap={4}
    py={4}
    px={{ base: 4, md: 6, lg: 10 }}
    data-testid="share-details-stats"
    data-dd-privacy="mask"
  >
    {children}
  </Flex>
);

export default ShareDetailsStatsWrapper;
