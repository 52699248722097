import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useFronteggMutation } from "./useFronteggMutation";

interface DisableAllMfaRequestPayload {
  readonly email: string;
  readonly recoveryCode: string;
}

const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/frontegg/identity/resources/auth/v1/user/mfa/recover`;

const useDisableAllMfa = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { t } = useTranslation();

  const { state, execute } = useFronteggMutation<DisableAllMfaRequestPayload>();

  const disableAllMfa = useCallback(
    async (body: DisableAllMfaRequestPayload) =>
      execute(API_URL, {
        body,
        onSuccess,
        fallbackErrorMessage: t(`incorrect_code`),
      }),
    [execute],
  );

  return [disableAllMfa, state] as const;
};

export default useDisableAllMfa;
