import { useAuthUserOrNull } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  FRONTEGG_INPUT_ERROR_STATUS,
  FronteggInputError,
} from "@/components/frontegg";

interface UseRequestState<TResponse> {
  loading: boolean;
  data: TResponse | null;
  error: Error | null;
  success: boolean;
}

interface UseRequestOptions<TRequestPayload, TResponse> {
  headers?: Record<string, string>;
  body?: TRequestPayload;
  fallbackErrorMessage?: string;
  onSuccess?: (data: TResponse, response: Response) => void;
  onError?: (error: Error, response?: Response) => void;
}

interface UseRequestReturn<TRequestPayload, TResponse> {
  state: UseRequestState<TResponse>;
  setState: Dispatch<SetStateAction<UseRequestState<TResponse>>>;
  execute: (
    url: string,
    options: UseRequestOptions<TRequestPayload, TResponse>,
  ) => void;
}

const INITIAL_STATE = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export function useFronteggMutation<
  TRequestPayload = null,
  TResponse = null,
>(): UseRequestReturn<TRequestPayload, TResponse> {
  const [state, setState] = useState<UseRequestState<TResponse>>(INITIAL_STATE);
  const user = useAuthUserOrNull();
  const { t } = useTranslation();

  const execute = useCallback(
    async (
      url: string,
      {
        onSuccess,
        onError,
        fallbackErrorMessage,
        ...options
      }: UseRequestOptions<TRequestPayload, TResponse>,
    ) => {
      setState((currentState) => ({ ...currentState, loading: true }));

      try {
        if (!user) {
          throw new Error(t(`invalid_request`));
        }

        const response = await fetch(url, {
          method: `POST`,
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            ...options.headers,
          },
          ...(options.body && { body: JSON.stringify(options.body) }),
        });

        // Frontegg is using their content types wrong so some requests (with no response body)
        // have the possibility of returning a 200 instead of a 204. Fetch will throw an error if
        // a response with a 200 has no content and response.json() is called on it
        const text = await response.text();
        const data = text ? JSON.parse(text) : null;

        if (!response.ok) {
          const [message] = data.errors;
          const errorMessage = message || fallbackErrorMessage;

          const error = new Error(errorMessage);

          if (response?.status === FRONTEGG_INPUT_ERROR_STATUS) {
            throw new FronteggInputError(errorMessage);
          }

          throw error;
        }

        onSuccess?.(data, response);
        return setState({ loading: false, error: null, data, success: true });
      } catch (error) {
        const isInputError = error instanceof FronteggInputError;

        if (!isInputError) {
          Sentry.captureEvent(error);
        }

        onError?.(error);
        return setState({ loading: false, error, data: null, success: false });
      }
    },
    [user, setState],
  );

  return { state, setState, execute };
}
