import { match } from "ts-pattern";

import {
  TransferMethodV2,
  UnaccreditedSellerTransferMethodInputV2,
} from "@/gql";

import { transferTypeChoices, TransferTypeChoices } from "./types";

export const transferTypeChoiceToTransferMethod = (
  transferTypeChoice: TransferTypeChoices,
) =>
  match(transferTypeChoice)
    .with(`Yes`, () => UnaccreditedSellerTransferMethodInputV2.Direct)
    .with(`No`, () => UnaccreditedSellerTransferMethodInputV2.ForwardContract)
    .with(`DontKnow`, () => UnaccreditedSellerTransferMethodInputV2.Unknown)
    .with(`Other`, () => UnaccreditedSellerTransferMethodInputV2.Other)
    .exhaustive();

export const transferMethodToTransferTypeChoice = (
  transferMethod: TransferMethodV2,
) =>
  match(transferMethod)
    .with(TransferMethodV2.Direct, () => transferTypeChoices.Yes)
    .with(TransferMethodV2.ForwardContract, () => transferTypeChoices.No)
    .with(TransferMethodV2.Unknown, () => transferTypeChoices.DontKnow)
    .with(TransferMethodV2.Other, () => transferTypeChoices.Other)
    .otherwise(() => {
      throw new Error(
        `Invalid transferMethod in transferMethodToTransferTypeChoice`,
      );
    });

export const transferTypeChoiceToString = (
  transferTypeChoice: TransferTypeChoices,
) =>
  match(transferTypeChoice)
    .with(`Yes`, () => `I can transfer the shares directly`)
    .with(`No`, () => `I can't transfer the shares directly`)
    .with(`Other`, () => `I can't transfer the shares directly`)
    .with(`DontKnow`, () => `I am not sure if I transfer the shares directly`)
    .exhaustive();
