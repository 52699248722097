import { useEffect, useState } from "react";

import { VStack, Radio } from "@chakra-ui/react";

import { FormRadioInput } from "@/components/form";
import {
  AccreditationAnswerInput,
  InvestorStatus,
  useAccreditationQuestionGroupsQuery,
} from "@/gql";

type AccreditationModifyFormQuestion = {
  readonly text: string;
  readonly id: string;
  readonly order: number;
};

const AccreditationModifyFields = ({
  investorType,
  countryId,
  version = 2,
  resolver = (values) => values,
}: {
  readonly investorType: InvestorStatus;
  readonly countryId: string;
  readonly version?: number;
  readonly resolver?: (
    values: readonly AccreditationModifyFormQuestion[],
  ) => readonly AccreditationModifyFormQuestion[];
}) => {
  const [questions, setQuestions] =
    useState<readonly AccreditationModifyFormQuestion[]>();

  const questionsResponse = useAccreditationQuestionGroupsQuery({
    variables: { version, investorType, countryId },
  });

  useEffect(() => {
    if (
      questionsResponse.data &&
      questionsResponse.data.accreditationQuestionGroups
    ) {
      const [group] = questionsResponse.data.accreditationQuestionGroups;

      if (group?.questions)
        setQuestions(
          resolver(
            group.questions
              ?.filter(
                (question): question is AccreditationModifyFormQuestion =>
                  !!question,
              )
              .sort(({ order: orderA }, { order: orderB }) => orderA - orderB),
          ),
        );
    }
  }, [questionsResponse, setQuestions]);

  return (
    <FormRadioInput<readonly AccreditationAnswerInput[]> name="answer">
      <VStack alignItems="flex-start" gap={4}>
        {questions?.map(({ text, id }) => (
          <Radio key={id} variant="base" value={id}>
            {text}
          </Radio>
        ))}
      </VStack>
    </FormRadioInput>
  );
};

export default AccreditationModifyFields;
