import { Control, FieldValues, Path, useController } from "react-hook-form";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  InputProps,
} from "@chakra-ui/react";

import { NumberInput } from "./NumberInput";

interface FormNumberInputProps<TFieldValues extends FieldValues>
  extends InputProps {
  readonly control: Control<TFieldValues>;
  readonly name: Path<TFieldValues>;
  readonly label: string;
  readonly labelSrOnly?: FormLabelProps["srOnly"];
  readonly displayError?: boolean;
  readonly defaultValue?: string | number;
  readonly step?: number;
  readonly min?: number;
  readonly max?: number;
  readonly allowNegativeNumbers?: boolean;
}

const defaultNumberProps = {
  min: 0,
  max: 1e15,
  defaultValue: undefined,
  step: 1,
};

const FormNumberInput = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  labelSrOnly,
  isRequired,
  displayError = true,
  allowNegativeNumbers = true,
  ...inputProps
}: FormNumberInputProps<TFieldValues>) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel srOnly={labelSrOnly}>
        {isRequired ? `${label} *` : label}
      </FormLabel>
      <NumberInput
        {...defaultNumberProps}
        {...inputProps}
        {...field}
        allowNegativeNumbers={allowNegativeNumbers}
      />
      {displayError && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormNumberInput;
