import { ShieldStar } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ActionCard } from "@/components/common";
import { useModal } from "@/hooks";

const LoginCard = () => {
  const { t } = useTranslation();

  const { onOpenModal, modals } = useModal();

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`login`)}</Text>
      </CardHeader>
      <CardBody p={{ base: 6, lg: 10 }}>
        <VStack spacing={6}>
          <ActionCard
            action={{
              label: t(`update`),
              onClick: onOpenModal(modals.changePassword()),
            }}
            icon={<ShieldStar size={24} />}
            text={t(`password`)}
          />
        </VStack>
      </CardBody>
    </Card>
  );
};

export const SecurityCard = () => (
  <SimpleGrid w="full" height="fit-content" rowGap={6}>
    <GridItem>
      <LoginCard />
    </GridItem>
  </SimpleGrid>
);
