import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import { FlowKind, ModalKind } from "@/components/providers";
import { BrokerSubmitBidSequenceModalCompanyFragment } from "@/gql";
import { useFlowLauncher, useModal } from "@/hooks";
import { getAvailableCompanyActions } from "@/utils";

import { useBrokerSubmitBidSequenceModalStepFormContext } from "./BrokerSubmitBidSequenceModalStepFormContext";

const BrokerSubmitBidSuccessModal = ({
  company,
}: {
  readonly company: BrokerSubmitBidSequenceModalCompanyFragment;
}) => {
  const { mutationData } = useBrokerSubmitBidSequenceModalStepFormContext();
  const { closeModal, addModalCloseHandler, removeModalCloseHandler } =
    useModal();
  const { showFlow } = useFlowLauncher();
  const { t } = useTranslation();

  const { canPlaceStandingBid } = getAvailableCompanyActions(company);

  useEffect(() => {
    addModalCloseHandler(ModalKind.BrokerSubmitBid, () => {
      if (canPlaceStandingBid) {
        showFlow(FlowKind.BrokerSubmitBidSuccessStandingBidCTA);
      }
    });

    return () => {
      removeModalCloseHandler(ModalKind.BrokerSubmitBid);
    };
  }, []);

  const bid = mutationData?.brokerSubmitBid.bid;

  if (!bid) return null;

  const { listing } = bid;

  return (
    <>
      <HiiveModalHeader>
        {t(`submit_bid_success_in_review_title`)}
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start">
          <Text textStyle="heading-lg">
            {t(`submit_bid_success_in_review_heading`, {
              companyName: company.name,
              displayId: listing.displayId,
            })}
          </Text>
          <Text>{t(`submit_bid_success_in_review_body`)}</Text>
          <Text>
            <Trans
              i18nKey="submit_bid_success_in_review_contact_us"
              t={t}
              components={[
                <SecuritySpecialistLink
                  key={bid.listing.displayId}
                  company={company}
                />,
              ]}
            />
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          sentryLabel="[BrokerSubmitBidSuccess/Submit]"
          submitText="Okay"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default BrokerSubmitBidSuccessModal;
