import React, { useEffect, useMemo, forwardRef, ForwardedRef } from "react";
import { useTranslation } from "react-i18next";

import {
  FormCompaniesCombobox,
  FormCompaniesControls,
} from "@/components/form";
import {
  ListCompaniesOrderBy,
  useHoldingSelectCompanyListLazyQuery,
  HoldingSelectCompanyFragment,
} from "@/gql";
import { useDebounce } from "@/hooks";

interface HoldingCompanySelectProps {
  readonly name: string;
  readonly isDisabled: boolean;
  readonly manualActionName?: string;
  readonly onSelectInput?: (
    company: HoldingSelectCompanyFragment | null,
  ) => void;
  readonly selectedHoldingCompanyIds: string[];
}

const getVariables = (searchText: string) => ({
  first: 20,
  orderBy: ListCompaniesOrderBy.MarketActivity,
  searchText,
});

const HoldingCompanySelect = forwardRef(
  (
    {
      name,
      isDisabled,
      manualActionName,
      selectedHoldingCompanyIds,
      onSelectInput,
    }: HoldingCompanySelectProps,
    ref: ForwardedRef<FormCompaniesControls>,
  ) => {
    const { debounce, isDebouncing } = useDebounce();
    const { t } = useTranslation();

    const [loadCompanies, { data, loading }] =
      useHoldingSelectCompanyListLazyQuery({
        fetchPolicy: `no-cache`,
      });

    useEffect(() => {
      loadCompanies({
        variables: getVariables(``),
      });
    }, []);

    const handleChangeSearch = (search: string) => {
      debounce(() =>
        loadCompanies({
          variables: getVariables(search),
        }),
      );
    };

    const companyItems = useMemo(
      () =>
        data?.listCompanies?.edges
          ?.filter(
            (edge) =>
              edge?.node && !selectedHoldingCompanyIds.includes(edge?.node.id),
          )
          .reduce(
            (soFar, edge) => (edge?.node ? [...soFar, edge.node] : soFar),
            [],
          ) || [],
      [data?.listCompanies, selectedHoldingCompanyIds],
    );

    const isLoading = loading || isDebouncing;

    return (
      <FormCompaniesCombobox
        getItems={() => companyItems}
        placeholder={t(`search_company_name`)}
        onChangeSearch={handleChangeSearch}
        onSelectInput={onSelectInput}
        name={name}
        isLoading={isLoading}
        isDisabled={isDisabled}
        ref={ref}
        manualActionName={manualActionName}
      />
    );
  },
);

export default HoldingCompanySelect;
