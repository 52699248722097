import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { AcceptCounteredBidMutation } from "@/gql";

import { StepKeys } from "./steps";

export const AcceptCounterBidSequenceModalStepFormContext =
  createStepFormContext<StepKeys, AcceptCounteredBidMutation>();

export const useAcceptCounterBidSequenceModalStepFormContext = () =>
  useContext(AcceptCounterBidSequenceModalStepFormContext);
