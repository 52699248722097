import { match } from "ts-pattern";

import { CompanyActionsCompanyFragment, InvestorType } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { TradeIntentType } from "@/types";
import { getAvailableCompanyActions, getIsInstitutionUser } from "@/utils";

import ComplianceReminder from "./ComplianceReminder";
import UntradeableReminder from "./UntradeableReminder";

const UnableToTransact = ({
  tradeIntent,
  company,
}: {
  readonly tradeIntent?: TradeIntentType;
  readonly company: Pick<
    CompanyActionsCompanyFragment,
    | `id`
    | `securitySpecialist`
    | `name`
    | `tradeableSellSide`
    | `myPermissionsV2`
  >;
}) => {
  const actor = useCurrentActor();
  const isInstitution = getIsInstitutionUser(actor);
  const { canCreateListing } = getAvailableCompanyActions(company);
  const securitySpecialistEmail =
    actor.investorType === InvestorType.UnaccreditedSeller
      ? company.securitySpecialist?.marketAnalyst?.email
      : company.securitySpecialist?.user.email;

  return match({
    canCreateListing,
    isTradeable: company.tradeableSellSide,
    ivCompleted: actor.identityVerified,
    isInstitution,
  })
    .with({ ivCompleted: false, isInstitution: false }, () => (
      <ComplianceReminder tradeIntent={tradeIntent} />
    ))
    .with({ isTradeable: true, canCreateListing: false }, () => (
      <ComplianceReminder tradeIntent={tradeIntent} />
    ))
    .with({ isTradeable: false }, () => (
      <UntradeableReminder
        company={company}
        securitySpecialistEmail={securitySpecialistEmail || ``}
      />
    ))
    .otherwise(() => <ComplianceReminder tradeIntent={tradeIntent} />);
};

export default UnableToTransact;
