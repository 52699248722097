import { match } from "ts-pattern";

import { AccreditationQuestion, InvestorStatus, Maybe } from "@/gql";
import { useCurrentActor } from "@/hooks";

import { IndividualAccreditationForm } from "./IndividualAccreditationForm";
import { InstitutionAccreditationForm } from "./InstitutionAccreditationForm";

export type AccreditationFormProps = {
  readonly accreditationQuestions: Maybe<AccreditationQuestion>[];
  readonly formId: string;
  readonly onSuccess: () => void;
  readonly accreditationLastUpdatedAt?: string | null;
};

export const AccreditationForm = ({
  formId,
  onSuccess,
  accreditationLastUpdatedAt,
}: Omit<AccreditationFormProps, `accreditationQuestions`>) => {
  const actor = useCurrentActor();
  const isIndividualUser = actor.investorStatus === InvestorStatus.Individual;

  return match({ isIndividualUser })
    .with({ isIndividualUser: true }, () => (
      <IndividualAccreditationForm
        formId={formId}
        onSuccess={onSuccess}
        accreditationLastUpdatedAt={accreditationLastUpdatedAt}
      />
    ))
    .otherwise(() => (
      <InstitutionAccreditationForm
        formId={formId}
        onSuccess={onSuccess}
        accreditationLastUpdatedAt={accreditationLastUpdatedAt}
      />
    ));
};
