import { Tooltip, TooltipProps } from "@chakra-ui/react";

export function IssuerTooltip(props: TooltipProps) {
  return (
    <Tooltip
      bg="grey.900"
      color="white"
      py={3}
      px={4}
      maxW={80}
      borderRadius={4}
      gutter={16}
      hasArrow
      {...props}
    />
  );
}
