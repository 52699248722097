import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  CardBody,
  Text,
  VStack,
  SimpleGrid,
  GridItem,
  CardFooter,
  HStack,
  Button,
  CardHeader,
  Card,
  Box,
} from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { TileCheckboxInput } from "@/components/react-hook-form";
import { UserWithInstitutionFragment } from "@/gql";
import * as datetime from "@/utils/datetime";

import { TrustedContactPersonForm } from "./TrustedContactPersonForm";

export const TrustedContactPersonInfo = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { t } = useTranslation();
    const { trustedContactPerson } = actor;
    const [isModifying, setIsModifying] =
      useState<boolean>(!trustedContactPerson);

    const onModifyClick = () => {
      setIsModifying(true);
    };

    if (!trustedContactPerson) return null;

    if (!trustedContactPerson || isModifying)
      return (
        <TrustedContactPersonForm
          isUpdating
          trustedContactPerson={trustedContactPerson}
          onGoBack={() => setIsModifying(false)}
        />
      );
    const { isContactProvided, contactInfo } = trustedContactPerson;

    return (
      <Card variant="darkened-footer" w="full" flex="1" data-dd-privacy="mask">
        <CardHeader>
          <Text textStyle="heading-sm">{t(`trusted_contact_person`)}</Text>
        </CardHeader>
        <CardBody p={{ base: 4, lg: 10 }}>
          <SimpleGrid
            gridTemplateColumns={{ base: `1fr`, lg: `1fr` }}
            rowGap={6}
          >
            <GridItem>
              <Box
                display="flex"
                justifyContent={{ base: `flex-end`, lg: `space-between` }}
                alignItems="flex-start"
                flexDir={{ base: `column-reverse`, lg: `row` }}
                gap={{ base: 4, lg: 0 }}
                w="full"
              >
                <VStack
                  spacing={4}
                  alignItems="flex-start"
                  w={{ base: `full`, lg: `85%` }}
                >
                  <Text textStyle="heading-lg">
                    <Trans
                      i18nKey="add_trusted_contact_person"
                      components={{ italic: <i /> }}
                    />
                    {` `}*
                  </Text>
                  <TileCheckboxInput.Tooltip>
                    <VStack spacing={4} alignItems="flex-start" px={2}>
                      <Text>{t`add_trusted_contact_person_disclaimer`}</Text>
                      <Text>{t`add_trusted_contact_person_disclaimer_continued`}</Text>
                    </VStack>
                  </TileCheckboxInput.Tooltip>
                </VStack>
                <HStack
                  w={{ base: `full`, lg: `unset` }}
                  justifyContent="flex-end"
                >
                  <Button
                    variant="boxed-grey"
                    size="xl"
                    onClick={onModifyClick}
                  >
                    <Text px={4}>{t`modify`}</Text>
                  </Button>
                </HStack>
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <SimpleGrid gridTemplateColumns="1fr" rowGap={4}>
                <HStack>
                  <Text>{t`answer`}:</Text>
                  <Text fontWeight={500}>
                    {isContactProvided ? t`yes` : t`no`}
                  </Text>
                </HStack>
                {isContactProvided && (
                  <SimpleGrid gridTemplateColumns="1fr" rowGap={4}>
                    <VStack alignItems="flex-start">
                      <Text fontWeight={500}>{t`name`}</Text>
                      <Text>{`${contactInfo?.firstName} ${contactInfo?.lastName}`}</Text>
                    </VStack>
                    <VStack alignItems="flex-start">
                      <Text fontWeight={500}>{t`telephone`}</Text>
                      <Text>{contactInfo?.phoneNumber}</Text>
                    </VStack>
                    <VStack alignItems="flex-start">
                      <Text fontWeight={500}>{t`email`}</Text>
                      <Text>{contactInfo?.email}</Text>
                    </VStack>
                    <VStack alignItems="flex-start">
                      <Text fontWeight={500}>{t`relationship`}</Text>
                      <Text>{contactInfo?.relationship}</Text>
                    </VStack>
                  </SimpleGrid>
                )}
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
        </CardBody>
        <CardFooter>
          <Text textColor="grey.800" textStyle="text-sm">
            {t(`submitted_on`, {
              date: datetime.toFullTimestamp(trustedContactPerson?.updatedAt),
            })}
          </Text>
        </CardFooter>
      </Card>
    );
  },
);
