export default {
  anvil: `Anvil`,
  information_collection: `Information Collection`,
  completed: `Completed`,
  pending: `Pending`,
  please_fill_information: `Please fill in your information for the document`,
  fill_document: `Fill Document`,
  document_signing: `Document Signing`,
  please_complete_document: `Please complete the document by signing`,
  sign_document: `Sign Document`,
  signed: `Signed`,
  your_signature: `Your Signature`,
  counterpartys_signature: `Counterparty's Signature`,
  download: `Download`,
  decline: `Decline`,
  approval_status: `Approval Status`,
  approved: `Approved`,
  rejected: `Rejected`,
  your_approval: `Your Approval`,
  counterparty_approval: `Counterparty Approval`,
  gross_sale_amount: `Gross Sale Amount`,
  total_hiive_fees: `Total Hiive Fees`,
  net_proceeds: `Net Proceeds`,
  shares: `Shares`,
  proceeds_description_collapsed: `This is the estimated price per share and net proceeds after deducting the Hiive Transaction Fee.`,
  proceeds_description_expanded: `This is the estimated price per share and net proceeds after deducting the Hiive Transaction Fee. There may be other charges that you will have to incur in order to complete this transaction. For example, some issuers charge fees to sellers in order to cover their legal and other costs of executing the sale transaction. As well, you may need to pay taxes, or fees to consultants such as lawyers or tax advisors. These charges are borne by you and not deducted from the purchase price for the purpose of calculating Hiive's transaction fee.<br/><br/>`,
  proceeds_description_terms: `To see how the Hiive Transaction Fee is calculated, consult the <a>Hiive Customer Terms & Conditions.</a><br/>`,
  fees: `Fees`,
  hiive_closing_fee: `Hiive Closing Fee`,
  hiive_commission: `Hiive Commission`,
  gross_value: `Gross Value`,
  read_more: `Read More`,
  see_less: `See Less`,
  accept: `Accept`,
  accept_proposed_modified_transaction: `Accept Proposed Modified Transaction`,
  accept_proposed_modified_transaction_title: `Are you sure you want to accept this modification?`,
  accept_proposed_modified_transaction_description: `Your may need to sign a new Share Transfer Notice.`,
  proposed_update: `Proposed update: {{shares}} shares of {{companyName}} @{{price}}/sh {{type}}.`,
  completed_date: `Completed {{completedAt}}`,
  completed_tasks: `Completed tasks`,
  confirm: `Confirm`,
  inviter_has_asked: `{{name}} has asked you to fill out a document on their behalf.`,
  disclaimer: `disclaimer`,
  disclaimer_text: `Mollit reprehenderit amet consectetur mollit nostrud fugiat quis.Aute ut sunt dolor exercitation.`,
  document_filled: `Document Filled!`,
  document_filled_description: `Thank you for filling on behalf of {{name}}`,
  cancel: `Cancel`,
  contact_our_security_specialist: `If you have any questions, please contact our Security Specialist. <bold><a>{{email}}</a></bold>`,
  reject_proposed_modified_transaction: `Reject Proposed Modified Transaction`,
  reject_proposed_modified_transaction_title: `Are you sure you want to reject this modification?`,
  reject_proposed_modified_transaction_description: `Rejecting the modification may impact the progress or completion of your transaction. For more details, please reach out to your Hiive contact or email <a>{{email}}.<a>`,
  no_completed_tasks: `No completed tasks, this is where they will appear.`,
  no_documents_uploaded: `No documents. All documents completed in the transaction will appear here.`,
  add_bank_account: `Add bank account`,
  add_bank_account_description: `Provide the bank details we should use for this transaction.`,
  add_account: `Add Account`,
  add_new_account: `Add New Account`,
  bank_details_for: `Bank details for`,
  select_bank_account: `Select bank account`,
  last_used_on_transaction: `Last used on transaction`,
  added: `Added`,
  transaction: `Transaction`,
  modern_treasury_disclaimer: `We use Modern Treasury to facilitate payments`,
  modern_treasury_feature_1: `Fully encrypted, your financial data is secure`,
  modern_treasury_feature_2: `Direct bank integration, for easy payment setup`,
  modern_treasury_feature_3: `Streamlined processing, for faster access to your funds`,
  modern_treasury_terms: `By clicking next, you agree to Modern Treasury’s <italic>Privacy Policy.<italic>`,
  next: `Next`,
  your_bank_details: `Your bank details`,
  where_is_your_bank_located: `Where is your bank located?`,
  no_bank_available: `Can’t find your country in the list? Contact <a>execution@hiive.com.</a>`,
  bank_country_disclaimer: `Hiive uses a trusted external partner, <a>Modern Treasury</a> to securely and anonymously transact. Hiive doesn’t store your banking credentials.`,
  select_bank_location: `Select bank location`,
  usa_disclaimer: `For US accounts, we send payments through <u>Same-Day ACH</u>. You’ll need to provide your ACH routing number, which may be different from your wire routing number.`,
  add_your_account_details: `Add your account details`,
  account_details_disclaimer: `Before you submit, it is your responsibility to ensure these details are correct. Hiive will send funds to the account details provided.`,
  account_details_additional: `To provide any additional details, like For Further Credit instructions, contact <a>execution@hiive.com</a>.`,
  bank_account_added: `Bank account added`,
  bank_account_connected: `Your bank account is now connected to this transaction.`,
  next_step: `Next Step`,
  next_tasks: `Next tasks`,
  next_tasks_description: `You’ll need to complete these soon. We’ll let you know when.`,
  verify_your_bank_account: `Verify your bank account`,
  verify_your_account: `Verify your account`,
  verify_confirm_need: `We need to confirm we’ve got the right account details.`,
  verify_confirm_send_deposits: `In the next three days, we’ll send two micro-deposits to the details provided.`,
  verify_confirm_use_deposits: ` When these show in your account, you’ll use them to verify your details.`,
  got_it: `Got it`,
  account_number: `Account number`,
  amount_due: `Amount due`,
  beneficiary: `Beneficiary`,
  payments: `Payments`,
  routing_number: `Routing number`,
  send_amount_due: `Send the amount due to the wire details provided. Please note, we cannot accept ACH payments.`,
  swift_code: `Swift code`,
  the_account_number: `The account number is unique to this transaction, please do not use it for subsequent transactions.`,
  transfer_funds: `Transfer funds`,
  wire_instructions: `Wire Instructions`,
  wire_details: `Wire details`,
  alternate: `Alternate`,
  you_invited_alternate: `You invited an alternate to fill out this document on your behalf`,
  need_someone_else_to_do_this_task: `Need someone else to do this task?`,
  send_an_invite: `Send an invite`,
  send_invite: `Send Invite`,
  want_to_complete_this_yourself: `Want to complete this yourself?`,
  cancel_invite: `Cancel Invite`,
  remove_alternate_representative: `Remove alternate representative`,
  are_you_sure_remove_alternate: `Are you sure you want to remove the alternate representative?`,
  remove_alternate_modal_description: `You’ll need to complete this task yourself, or you can invite a new alternate to complete the task on your behalf.`,
  remove: `Remove`,
  invite_an_alternate_representative: `Invite an alternate representative`,
  provide_alternate_representative: `Provide your alternate representative’s details`,
  alternate_representative_instructions: `We’ll send them an email with instructions on how to complete this task.`,
  first_name: `First Name`,
  last_name: `Last Name`,
  gross_proceeds: `Gross Proceeds`,
  email: `Email`,
};
