import { useState, useEffect, useRef } from "react";

interface UseCountDownProps {
  initialTime?: number; // Initial countdown time in seconds
  onComplete?: () => void; // Optional callback when countdown completes
}

const useCountDown = ({
  initialTime = 60,
  onComplete,
}: UseCountDownProps = {}) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeLeft > 0) {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (onComplete) {
      onComplete();
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [timeLeft, onComplete]);

  const resetTimer = () => {
    setTimeLeft(initialTime);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  return { timeLeft, resetTimer };
};

export default useCountDown;
