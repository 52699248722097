import { useContext } from "react";

import { LDStatusContext } from "@/components/async-with-ld-provider";

export type FlagValue = boolean | string | string[];

export type FeatureFlags = {
  readonly "hiive.trace_full_graphql": FlagValue;
  readonly "hiive.enable_maintenance": FlagValue;
  readonly "hiive.marketplace.react_hook_form_refactor": FlagValue;
  readonly "marketplace.sell_side_broker_portal": FlagValue;
  readonly "issuer.fee_discount.new_proceeds_section": FlagValue;
  readonly "execution.buyer_account_information_transfer": FlagValue;
  readonly "execution.internal_multi_entity": FlagValue;
  readonly "execution.transaction_execution_automation_system": FlagValue;
  readonly "marketplace.suitability_upfront": FlagValue;
  readonly "marketplace.suppress_notifications": FlagValue;
  readonly "marketplace.suitability_v3": FlagValue;
  readonly "marketplace.front_chat_u16r": FlagValue;
  readonly "marketplace.front_chat_trader": FlagValue;
  readonly "issuer.auth_system.marketplace_login": FlagValue;
  readonly "issuer.auth_system.marketplace_configuration": FlagValue;
  readonly "issuer.auth_system.bypass_mfa_enforcement": FlagValue;
  readonly "marketplace.complete_profile_banner": FlagValue;
  readonly "marketplace.iv_required": FlagValue;
  readonly "issuer.issuer_portal.retool_parity": FlagValue;
  readonly "marketplace.new_terms_and_conditions": FlagValue;
  readonly "marketplace.other_transfer_method": FlagValue;
  readonly "marketplace.pricing_chart_disclaimer_updates": FlagValue;
  readonly "marketplace.rsu_restriction_and_eligible_buyers": FlagValue;
  readonly "marketplace.other_eligible": FlagValue;
};

export enum ServerFeatureFlagKeys {
  ISSUER_AUTH_SYSTEM_MARKETPLACE_LOGIN = `issuer.auth_system.marketplace_login`,
  ISSUER_PORTAL_RETOOL_PARITY = `issuer.issuer_portal.retool_parity`,
  BYPASS_MFA_ENFORCEMENT = `issuer.auth_system.bypass_mfa_enforcement`,
}

export const useFeatureFlags = (): FeatureFlags => {
  const { flags } = useContext(LDStatusContext);

  return (flags as FeatureFlags) || {};
};

export const useMaintenanceMode = () =>
  useFeatureFlags()[`hiive.enable_maintenance`];

export const useFeeDiscountNewProceedsSectionEnabled = () =>
  useFeatureFlags()[`issuer.fee_discount.new_proceeds_section`];

export const useTEXASEnabled = () =>
  useFeatureFlags()[`execution.transaction_execution_automation_system`];

export const useSuitabilityUpfront = () =>
  useFeatureFlags()[`marketplace.suitability_upfront`];

export const useSuppressNotifications = () =>
  useFeatureFlags()[`marketplace.suppress_notifications`];

export const useFrontChatU16r = () =>
  useFeatureFlags()[`marketplace.front_chat_u16r`];

export const useFrontChatTrader = () =>
  useFeatureFlags()[`marketplace.front_chat_trader`];

export const useBAITEnabled = () =>
  useFeatureFlags()[`execution.buyer_account_information_transfer`];

export const useMarketplaceLogin = () =>
  useFeatureFlags()[`issuer.auth_system.marketplace_login`];

export const useMarketplaceConfiguration = () =>
  useFeatureFlags()[`issuer.auth_system.marketplace_configuration`];

export const useBypassMfaEnforcement = () =>
  useFeatureFlags()[`issuer.auth_system.bypass_mfa_enforcement`];

export const useMarketplaceCompleteProfileBanner = () =>
  useFeatureFlags()[`marketplace.complete_profile_banner`];

export const useIVRequired = () => useFeatureFlags()[`marketplace.iv_required`];

export const useIssuerPortalRetoolParity = () =>
  useFeatureFlags()[`issuer.issuer_portal.retool_parity`];

export const useNewTermsAndConditions = () =>
  useFeatureFlags()[`marketplace.new_terms_and_conditions`];

export const useOtherTransferMethod = () =>
  useFeatureFlags()[`marketplace.other_transfer_method`];

export const useUpdatedPricingChartCopy = () =>
  useFeatureFlags()[`marketplace.pricing_chart_disclaimer_updates`];
// Uncomment when implementing rsu restriction
// export const useRsuRestrictionAndEligibleBuyers = () =>
//  useFeatureFlags()[`marketplace.rsu_restriction_and_eligible_buyers`];

// ts-prune-ignore-next
export const useOtherEligible = () =>
  useFeatureFlags()[`marketplace.other_eligible`];
