import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";

import { ItemList } from "@/components/form";
import { useItemField, useListbox } from "@/hooks";

import Listbox from "./Listbox";

const FormListbox = <TItem,>({
  items,
  name,
  itemToString,
  getItemKey,
  label,
  isDisabled,
  isLoading,
  placeholder = ``,
  labelSrOnly,
  onSelectItem,
}: {
  /** The items to show in the listbox */
  readonly items: ItemList<TItem>;
  /** The name of the listbox field in the form */
  readonly name: string;
  /** Function to convert an item to a string for display */
  readonly itemToString: (item: TItem) => string;
  /** Function to get the key for a given item */
  readonly getItemKey: (item: TItem) => string;
  /** Label to show above the listbox field */
  readonly label: string;
  /** Whether to disable the listbox */
  readonly isDisabled?: boolean;
  /** Whether to show the loading state */
  readonly isLoading?: boolean;
  /** Placeholder text to display when the input is empty */
  readonly placeholder?: string;
  /** Whether the label should be visually hidden, but still available to screen readers */
  readonly labelSrOnly?: boolean;
  /** Additional callback to fire when an item is selected */
  readonly onSelectItem?: (item: TItem) => void;
}) => {
  const { error, isInvalid, setSelectedItem, selectedItem } =
    useItemField<TItem>(name);

  const { buttonProps, menuProps, labelProps, itemProps } = useListbox<TItem>({
    onClickItem: (item) => {
      setSelectedItem(item);
      if (!!onSelectItem) onSelectItem(item);
    },
    items,
    itemToString,
    selectedItem,
  });

  return (
    <FormControl id={name} isInvalid={isInvalid}>
      <FormLabel srOnly={labelSrOnly} {...labelProps}>
        {label}
      </FormLabel>
      <Listbox.Container>
        <Listbox.Button
          isDisabled={isDisabled}
          placeholder={placeholder}
          {...buttonProps}
        />
        <Listbox.Menu isLoading={isLoading} {...menuProps}>
          {items.map((item, index) => (
            <Listbox.Item
              key={getItemKey(item)}
              item={item}
              index={index}
              {...itemProps}
            >
              {itemToString(item)}
            </Listbox.Item>
          ))}
        </Listbox.Menu>
      </Listbox.Container>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default FormListbox;
