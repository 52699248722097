import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Box, Flex, Spacer } from "@chakra-ui/react";

import { HiiveButton, WithQuery } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import { FormTextInput } from "@/components/react-hook-form";
import {
  BrokerInfoPageCurrentActorDocument,
  BrokerInfoPageUserFragment,
  useBrokerInfoPageUpsertBrokerInfoMutation,
  useBrokerInfoPageCurrentActorQuery,
  useTransitionCurrentStepMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

import { AddressFields } from "./AddressFields";

const getInitialValues = (actor: BrokerInfoPageUserFragment) => ({
  firstName: actor.firstName,
  lastName: actor.lastName,
  brokerRegistrationNumber: actor.brokerInfo?.brokerRegistrationNumber || ``,
  firmName: actor.brokerInfo?.firmName || ``,
  firmRegistrationNumber: actor.brokerInfo?.firmRegistrationNumber || ``,
  firmStreetAddress: actor.brokerInfo?.firmStreetAddress || ``,
  firmCity: actor.brokerInfo?.firmCity || ``,
  firmState: actor.brokerInfo?.firmState || ``,
  firmPostalCode: actor.brokerInfo?.firmPostalCode || ``,
});

export interface BrokerInfoFormValues {
  firstName: string;
  lastName: string;
  brokerRegistrationNumber: string;
  firmName: string;
  firmRegistrationNumber: string;
  firmStreetAddress: string;
  firmCity: string;
  firmState: string;
  firmPostalCode: string;
}

const useValidationSchema = ({
  isUSAOrFinraRegistered,
}: {
  isUSAOrFinraRegistered: boolean;
}) => {
  const { t } = useTranslation();

  return Yup.object().shape({
    firstName: Yup.string()
      .nullable()
      .required(t(`validation_required`, { field: t(`first_name`) })),
    lastName: Yup.string()
      .nullable()
      .required(t(`validation_required`, { field: t(`last_name`) })),
    brokerRegistrationNumber: Yup.string()
      .nullable()
      .required(
        t(`validation_required`, {
          field: isUSAOrFinraRegistered ? t(`crd_number`) : t(`nrd_number`),
        }),
      ),
    firmName: Yup.string()
      .nullable()
      .required(t(`validation_required`, { field: t(`firm_name`) })),
    firmRegistrationNumber: Yup.string()
      .nullable()
      .required(
        t(`validation_required`, {
          field: isUSAOrFinraRegistered
            ? t(`firm_crd_number`)
            : t(`firm_nrd_number`),
        }),
      ),
    firmStreetAddress: Yup.string()
      .nullable()
      .required(t(`validation_required`, { field: t(`street_address`) })),
    firmCity: Yup.string()
      .nullable()
      .required(t(`validation_required`, { field: t(`city`) })),
    firmState: Yup.string()
      .nullable()
      .required(
        t(`validation_required`, {
          field: isUSAOrFinraRegistered ? t(`state`) : t(`province`),
        }),
      ),
    firmPostalCode: Yup.string()
      .nullable()
      .required(
        `${isUSAOrFinraRegistered ? `Zip` : `Postal`} code is required`,
      ),
  });
};

const mapVariables = (values: BrokerInfoFormValues) => ({
  input: { ...values },
});

const BrokerInfoFormContent = ({
  actor,
}: {
  actor: BrokerInfoPageUserFragment;
}) => {
  const { t } = useTranslation();

  const finraRegistered = !!actor?.finraRegistered;
  const countryCode = actor.country?.name;
  const isUSAOrFinraRegistered = countryCode === `US` || finraRegistered;

  const mutation = useBrokerInfoPageUpsertBrokerInfoMutation({
    refetchQueries: [BrokerInfoPageCurrentActorDocument],
  });

  const initialValues = getInitialValues(actor);
  const validationSchema = useValidationSchema({ isUSAOrFinraRegistered });

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const fieldData = isUSAOrFinraRegistered
    ? {
        brokerRegistrationNumberLabel: t(`crd_number`),
        brokerRegistrationNumberPlaceholder: t(`crd_number`),
        firmRegistrationNumberLabel: t(`firm_crd_number`),
        firmRegistrationNumberPlaceholder: t(`firm_crd_number`),
        stateLabel: t(`state`),
        postalCodeLabel: t(`zip_code`),
      }
    : {
        brokerRegistrationNumberLabel: t(`nrd_number`),
        brokerRegistrationNumberPlaceholder: t(`nrd_number`),
        firmRegistrationNumberLabel: t(`firm_nrd_number`),
        firmRegistrationNumberPlaceholder: t(`firm_nrd_number`),
        stateLabel: t(`province`),
        postalCodeLabel: t(`postal_code`),
      };

  const onSuccess = async () => {
    await transitionCurrentStepMutation();
  };

  const { control, handleSubmit, isLoading } = useFormQL({
    initialValues,
    mapVariables,
    mutation,
    onSuccess,
    validationSchema,
    shouldFocusError: false,
  });

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Flex direction="column" gap={8}>
        <SlideAnimation>
          <Flex
            direction="column"
            gap={5}
            bgColor="white"
            borderRadius="md"
            borderColor="grey.200"
            borderWidth={0.5}
            boxShadow="card"
            px={7}
            py={8}
          >
            <Flex
              direction={{ base: `column`, md: `row` }}
              rowGap={4}
              columnGap={5}
            >
              <FormTextInput
                control={control}
                name="firstName"
                label={t(`your_full_name`)}
                placeholder={t(`first`)}
                bg="white"
                autoFocus
              />
              <Box w="full">
                <Spacer display={{ base: `none`, md: `block` }} h={6} />
                <FormTextInput
                  mt={{ base: 0, md: 1.5 }}
                  control={control}
                  name="lastName"
                  placeholder={t(`last`)}
                  label={t(`last_name`)}
                  labelSrOnly
                />
              </Box>
            </Flex>
            <FormTextInput
              control={control}
              name="brokerRegistrationNumber"
              label={fieldData.brokerRegistrationNumberLabel}
              placeholder={fieldData.brokerRegistrationNumberPlaceholder}
            />
            <FormTextInput
              control={control}
              name="firmName"
              label={t(`firm_name`)}
              placeholder={t(`name`)}
            />
            <FormTextInput
              control={control}
              name="firmRegistrationNumber"
              label={fieldData.firmRegistrationNumberLabel}
              placeholder={fieldData.firmRegistrationNumberPlaceholder}
            />
            <AddressFields control={control} />
          </Flex>
        </SlideAnimation>
        <Flex w="full" justifyContent="flex-end">
          <HiiveButton
            isLoading={isLoading || isTransitioningCurrentStep}
            sentryLabel="[BrokerInfoPage] Next"
            size="xl"
            maxW="auto"
            w={{ base: `full`, sm: `auto` }}
            type="submit"
            variant="rounded-solid-salmon"
          >
            {t(`next`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </form>
  );
};

export const BrokerInfoForm = () => {
  const query = useBrokerInfoPageCurrentActorQuery();

  return (
    <WithQuery query={query}>
      {({ data: { currentActor } }) => (
        <BrokerInfoFormContent actor={currentActor} />
      )}
    </WithQuery>
  );
};
