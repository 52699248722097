import { useTranslation } from "react-i18next";

import { FormCountriesCombobox } from "@/components/form";

type JurisdictionOfFormationInputProps = Partial<
  Pick<HTMLInputElement, "disabled">
>;
const JurisdictionOfFormationInput = ({
  disabled,
}: JurisdictionOfFormationInputProps) => {
  const { t } = useTranslation(`account`, {
    keyPrefix: `entities`,
  });

  return (
    <FormCountriesCombobox
      disabled={disabled}
      placeholder={t(`search_country`)}
      name="jurisdictionOfFormationId"
      label={t(`jurisdiction_of_formation`)}
      showSearchIcon
    />
  );
};

export default JurisdictionOfFormationInput;
