import { ApolloError } from "@apollo/client";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  ExecutionWorkflowCondition,
  IssuerStage,
  IssuerTransactionsEdgeFragment,
  IssuerTransactionsProviderQuery,
  IssuerTransactionSortField,
  PageInfo,
  SortDirection,
  useIssuerTransactionsProviderQuery,
  useIssuerTransactionsSummaryQuery,
} from "@/gql";

import { usePagination } from "./usePagination";
import { useQueryParams } from "./useQueryParams";
import { DEFAULT_FETCH_LIMIT, useQueryVariables } from "./useQueryVariables";

const DEFAULT_PAGE_INFO = {
  hasNextPage: false,
  hasPreviousPage: false,
  startCursor: null,
  endCursor: null,
};

const DEFAULT_ISSUER_TRANSACTIONS = {
  edges: [],
  pageInfo: DEFAULT_PAGE_INFO,
  totalCount: 0,
};

const VIEW_ALL_STAGE = `VIEW_ALL`;

export type NonNullableTransactionWorkflowEdge = Omit<
  IssuerTransactionsEdgeFragment,
  "node"
> & {
  node: Omit<
    IssuerTransactionsEdgeFragment["node"],
    "issuerStage" | "workflow"
  > & {
    isRofr: boolean;
    issuerStage: NonNullable<
      IssuerTransactionsEdgeFragment["node"]["issuerStage"]
    >;
    workflow: NonNullable<IssuerTransactionsEdgeFragment["node"]["workflow"]>;
  };
};

const mapEdges = (
  edges: IssuerTransactionsEdgeFragment[],
): NonNullableTransactionWorkflowEdge[] =>
  edges.map((edge) => {
    const { node: transaction } = edge;

    if (!transaction.workflow) {
      throw new Error(`Workflow should not be null or undefined`);
    }

    const issuerStage = transaction.issuerStage as NonNullable<
      IssuerTransactionsEdgeFragment["node"]["issuerStage"]
    >;

    const workflow = transaction.workflow as NonNullable<
      IssuerTransactionsEdgeFragment["node"]["workflow"]
    >;

    const isRofr =
      workflow.conditions?.some(
        (condition) => condition.condition === ExecutionWorkflowCondition.Rofr,
      ) ?? false;

    return {
      ...edge,
      node: {
        ...edge.node,
        issuerStage,
        isRofr,
        workflow,
      },
    };
  });

const mapIssuerTransactions = (data?: IssuerTransactionsProviderQuery) => {
  if (!data?.issuerTransactions?.edges) {
    return DEFAULT_ISSUER_TRANSACTIONS;
  }

  try {
    const edges = mapEdges(data.issuerTransactions.edges);

    return {
      edges,
      pageInfo: data.issuerTransactions.pageInfo ?? DEFAULT_PAGE_INFO,
      totalCount: data.issuerTransactions.totalCount ?? 0,
    };
  } catch (error) {
    return DEFAULT_ISSUER_TRANSACTIONS;
  }
};

type IssuerTransactionsTableContext = {
  error?: ApolloError;
  filterBy?: {
    searchText?: string | null;
    stage?: IssuerStage | null;
  };
  loading: boolean;
  onFirstPage: () => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onLoadMore: () => void;
  onSearchTextChange: (searchText: string) => void;
  onSort: (direction: SortDirection, field: IssuerTransactionSortField) => void;
  onStageChange: (stage: IssuerStage | null) => void;
  page: number;
  pageInfo: PageInfo;
  refetch: () => void;
  sortBy: {
    direction: SortDirection;
    field: IssuerTransactionSortField;
  };
  totalCount: number;
  transactions: NonNullableTransactionWorkflowEdge[];
};

const DEFAULT_CONTEXT: IssuerTransactionsTableContext = {
  error: undefined,
  filterBy: {
    searchText: null,
    stage: IssuerStage.InReview,
  },
  loading: false,
  onFirstPage: () => {},
  onPreviousPage: () => {},
  onNextPage: () => {},
  onLoadMore: () => {},
  onSearchTextChange: () => {},
  onSort: () => {},
  onStageChange: () => {},
  page: 1,
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  },
  refetch: () => {},
  sortBy: {
    direction: SortDirection.Asc,
    field: IssuerTransactionSortField.DisplayId,
  },
  totalCount: 0,
  transactions: [],
};

export const IssuerTransactionsTableContext =
  createContext<IssuerTransactionsTableContext>(DEFAULT_CONTEXT);

interface IssuerTransactionsProviderProps {
  readonly children: ReactNode;
}

export function IssuerTransactionsProvider({
  children,
}: IssuerTransactionsProviderProps) {
  const [page, setPage] = useState(1);
  const { queryVariables, setQueryVariables } = useQueryVariables();
  const { queryParams, updateQueryParams } = useQueryParams();
  const { data, error, loading, refetch, fetchMore } =
    useIssuerTransactionsProviderQuery({
      variables: queryVariables,
      fetchPolicy: `network-only`,
    });

  const { refetch: refetchTransactionsSummary } =
    useIssuerTransactionsSummaryQuery();

  const { edges, pageInfo, totalCount } = mapIssuerTransactions(data);

  const { onFirstPage, onPreviousPage, onNextPage } = usePagination({
    queryVariables,
    setQueryVariables,
    pageInfo,
    setPage,
    page,
  });

  const onLoadMore = useCallback(async () => {
    if (!pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        ...queryVariables,
        first: DEFAULT_FETCH_LIMIT,
        last: undefined,
        before: undefined,
        after: pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ...fetchMoreResult,
          issuerTransactions: {
            ...fetchMoreResult.issuerTransactions,
            edges: [
              ...(prev.issuerTransactions?.edges || []),
              ...(fetchMoreResult.issuerTransactions?.edges || []),
            ],
            totalCount: fetchMoreResult.issuerTransactions?.totalCount ?? 0,
            pageInfo:
              fetchMoreResult.issuerTransactions?.pageInfo ?? DEFAULT_PAGE_INFO,
          },
        };
      },
    });
  }, [pageInfo, queryVariables]);

  const onSearchTextChange = useCallback(
    (searchText: string) => {
      setQueryVariables({
        ...queryVariables,
        first: DEFAULT_FETCH_LIMIT,
        last: undefined,
        after: undefined,
        before: undefined,
        filterBy: {
          ...queryVariables.filterBy,
          searchText: searchText.trim(),
        },
      });

      setPage(1);
    },
    [queryVariables, setQueryVariables],
  );

  const onStageChange = useCallback(
    (stage: IssuerStage | null) => {
      setQueryVariables({
        ...queryVariables,
        first: DEFAULT_FETCH_LIMIT,
        last: undefined,
        after: undefined,
        before: undefined,
        filterBy: {
          stage,
        },
      });

      updateQueryParams({ stage: stage ?? VIEW_ALL_STAGE });
      setPage(1);
      refetchTransactionsSummary();
    },
    [queryVariables, setQueryVariables],
  );

  const onSort = useCallback(
    (direction: SortDirection, field: IssuerTransactionSortField) => {
      setQueryVariables({
        ...queryVariables,
        sortBy: {
          direction,
          field,
        },
      });

      setPage(1);
    },
    [queryVariables, setQueryVariables],
  );

  const contextValue = useMemo(
    () => ({
      error,
      filterBy: queryVariables.filterBy,
      loading,
      onFirstPage,
      onPreviousPage,
      onNextPage,
      onLoadMore,
      onSearchTextChange,
      onSort,
      onStageChange,
      page,
      pageInfo,
      refetch,
      sortBy: queryVariables.sortBy,
      totalCount,
      transactions: edges,
    }),
    [
      data,
      edges,
      error,
      loading,
      onFirstPage,
      onPreviousPage,
      onNextPage,
      onLoadMore,
      onSearchTextChange,
      onSort,
      onStageChange,
      page,
      pageInfo,
      queryVariables.filterBy,
      refetch,
      queryVariables.sortBy,
      totalCount,
    ],
  );

  useEffect(() => {
    const stage =
      queryParams.stage === VIEW_ALL_STAGE ? null : queryParams.stage;
    onStageChange(stage as IssuerStage);
  }, []);

  return (
    <IssuerTransactionsTableContext.Provider value={contextValue}>
      {children}
    </IssuerTransactionsTableContext.Provider>
  );
}
