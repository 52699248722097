import { VStack } from "@chakra-ui/react";

import {
  FullContentWrapper,
  MobileTab,
  MobileTabList,
  MobileTabPanel,
  MobileTabPanels,
  MobileTabs,
} from "@/components/common";

import { DashboardActions } from "./DashboardActions";
import { DashboardHiive50Card } from "./DashboardHiive50Card";
import { DashboardLatestMarketActivity } from "./DashboardLatestMarketActivity";
import { DashboardMostActiveCompanies } from "./DashboardMostActiveCompanies";
import { DashboardWatchlist } from "./DashboardWatchlist";
import { DashboardYourActivity } from "./DashboardYourActivity";

export const MobileAccreditedDashboardPage = () => (
  <FullContentWrapper px={4}>
    <MobileTabs>
      <MobileTabList>
        <MobileTab>Profile</MobileTab>
        <MobileTab>Activity</MobileTab>
        <MobileTab>Market</MobileTab>
      </MobileTabList>
      <MobileTabPanels>
        <MobileTabPanel>
          <VStack spacing={4}>
            <DashboardActions />
            <DashboardWatchlist />
          </VStack>
        </MobileTabPanel>
        <MobileTabPanel>
          <VStack spacing={4}>
            <DashboardHiive50Card />
            <DashboardYourActivity />
          </VStack>
        </MobileTabPanel>
        <MobileTabPanel>
          <VStack spacing={4}>
            <DashboardMostActiveCompanies />
            <DashboardLatestMarketActivity />
          </VStack>
        </MobileTabPanel>
      </MobileTabPanels>
    </MobileTabs>
  </FullContentWrapper>
);
