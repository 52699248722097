import { Trans, useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { CreateEntityModal } from "@/components/entities";
import { withCurrentActor } from "@/components/hoc";

import { EntityItem } from "./EntityItem";

const InstitutionEntitiesCard = withCurrentActor(({ actor }) => {
  const { t } = useTranslation(`account`, {
    keyPrefix: `entities`,
  });
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { institution } = actor;
  const hasEntities =
    institution?.entities && institution?.entities?.length > 0;

  return (
    <>
      <Card variant="darkened-footer" w="full" flex="1">
        <CardHeader>
          <Text textStyle="heading-sm">{t(`transacting_entities`)}</Text>
        </CardHeader>
        <CardBody p={{ base: 4, lg: 10 }}>
          <Flex direction="column" gap="8">
            <Text>
              <Trans
                components={{
                  a: (
                    <Link
                      fontWeight={500}
                      href="mailto:execution@hiive.com"
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                }}
                i18nKey="entities.transacting_entities_institution_description"
                ns="account"
              />
            </Text>
            {hasEntities && (
              <Stack gap={4}>
                {institution?.entities.map((entity) => (
                  <EntityItem
                    key={entity.id}
                    entity={entity}
                    showIcon={false}
                  />
                ))}
              </Stack>
            )}
            <Flex justify="end">
              <HiiveButton
                onClick={onOpen}
                sentryLabel="[TransactionEntitiesInsitution] Add New Entity"
                size="xl"
                variant="rounded-solid-salmon"
              >
                {t(`add_new_entity`)}
              </HiiveButton>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
      <CreateEntityModal isOpen={isOpen} onClose={onClose} />
    </>
  );
});

export default InstitutionEntitiesCard;
