import { TFunction } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ButtonFooterWrapper, HiiveSubmitButton } from "@/components/common";
import {
  FormPasswordInput,
  FormPasswordWithInteractiveValidationInput,
} from "@/components/react-hook-form";
import { useUpdatePasswordMutation } from "@/gql";
import { useCurrentActor, useSignOut } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { constants, ROUTES } from "@/utils";

const { PASSWORD_REGEX } = constants;

const createValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    currentPassword: Yup.string()
      .nullable()
      .required(`Current password is required`),
    password: Yup.string()
      .nullable()
      .required(`Password is required`)
      .matches(PASSWORD_REGEX, t(`password_regex_error`)),
    passwordConfirmation: Yup.string()
      .nullable()
      .required(`Password confirmation is required`)
      .oneOf([Yup.ref(`password`)], `Passwords must match`),
  });

interface ChangePasswordFormValues {
  readonly currentPassword: string;
  readonly password: string;
  readonly passwordConfirmation: string;
}

const initialValues: ChangePasswordFormValues = {
  currentPassword: ``,
  password: ``,
  passwordConfirmation: ``,
};

const mapVariables = (values: ChangePasswordFormValues) => ({
  input: {
    ...values,
  },
});

export const ChangePasswordCard = () => {
  const signOut = useSignOut();
  const { t } = useTranslation();
  const { email, firstName, lastName } = useCurrentActor();
  const excludedTexts = [email, firstName, lastName, `hiive`];

  const onSuccess = () => signOut(ROUTES.ACCOUNT_PASSWORD_UPDATE_CONFIRMED);

  const mutation = useUpdatePasswordMutation();

  const { handleSubmit, control, formState, trigger, watch } = useFormQL({
    mutation,
    initialValues,
    validationSchema: createValidationSchema(t),
    mapVariables,
    onSuccess,
  });

  const password = watch(`password`);

  useEffect(() => {
    if (formState?.errors?.passwordConfirmation) {
      trigger(`passwordConfirmation`);
    }
  }, [password]);

  return (
    <Card
      w="full"
      flex="1"
      as="form"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <CardHeader>
        <Text textStyle="heading-sm">{t`change_password`}</Text>
      </CardHeader>
      <CardBody p={{ base: 4, lg: 10 }}>
        <SimpleGrid columns={2} columnGap={9}>
          <GridItem colSpan={{ base: 2, xl: 1 }}>
            <VStack spacing={6}>
              <SimpleGrid columns={1} rowGap={9} w="full">
                <FormPasswordInput
                  control={control}
                  label={t(`current_password`)}
                  labelSrOnly
                  name="currentPassword"
                  placeholder={t(`current_password`)}
                  isRequired
                />
                <VStack spacing={4}>
                  <VStack spacing={1} alignItems="flex-start">
                    <FormPasswordWithInteractiveValidationInput
                      control={control}
                      excludedTexts={excludedTexts}
                      isRequired
                      label={t(`password`)}
                      name="password"
                      placeholder={t(`signup_password_placeholder`)}
                    />
                    <Text textStyle="text-sm">
                      {t(`signup_password_policy`)}
                    </Text>
                  </VStack>
                  <FormPasswordInput
                    control={control}
                    label={t(`signup_confirm_password`)}
                    labelSrOnly
                    name="passwordConfirmation"
                    placeholder={t(`signup_confirm_password`)}
                    isRequired
                  />
                </VStack>
              </SimpleGrid>
              <ButtonFooterWrapper>
                <HiiveSubmitButton
                  submitText="Update"
                  type="submit"
                  sentryLabel="[ChangePassword/Submit]"
                  isLoading={formState.isSubmitting}
                />
              </ButtonFooterWrapper>
            </VStack>
          </GridItem>
        </SimpleGrid>
      </CardBody>
    </Card>
  );
};
