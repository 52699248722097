import { Flex, HStack, VStack, OtherProps } from "@chakra-ui/react";

import { LongHyphen, SensitiveText } from "@/components/common";
import { AcceptedBidSharePrices } from "@/components/companies";
import { ActivityCardListingNumShareAndPriceDetailsFragment } from "@/gql";
import {
  formatPricePerShare,
  formatShares,
  lot,
  getIsPartiallySoldListing,
  getIsConditionallySoldListing,
  getListingNumSharesAvailable,
  getListingNumSharesOriginal,
  calculateListingNumSharesSoldConditionally,
} from "@/utils";

const ActivityCardListingNumShareAndPriceDetails = ({
  listing,
  textStyle = `heading-lg`,
}: {
  readonly listing: ActivityCardListingNumShareAndPriceDetailsFragment;
  readonly textStyle?: OtherProps["textStyle"];
  readonly noPricePerShareText?: string;
}) => {
  const { listingPricePerShare: pricePerShare, acceptedBidPrices } = listing;

  const numSharesOriginal = getListingNumSharesOriginal(listing);
  const numSharesAvailable = getListingNumSharesAvailable(listing);
  const numSharesSoldConditionally =
    calculateListingNumSharesSoldConditionally(listing);

  switch (true) {
    case getIsPartiallySoldListing(listing):
      return (
        <VStack align="flex-start">
          <Flex wrap="wrap" textStyle={textStyle} columnGap={1.5}>
            <SensitiveText as="del" color="grey.200">
              {formatShares(numSharesOriginal)}
            </SensitiveText>
            <SensitiveText>{formatShares(numSharesAvailable)}</SensitiveText>
            {!!pricePerShare ? (
              <HStack spacing={6}>
                <SensitiveText>
                  @ {formatPricePerShare(pricePerShare)}/sh
                </SensitiveText>
                <SensitiveText>
                  {lot(numSharesAvailable, pricePerShare)}
                </SensitiveText>
              </HStack>
            ) : (
              <HStack>
                <SensitiveText>@</SensitiveText>
                <SensitiveText>
                  <LongHyphen />
                </SensitiveText>
              </HStack>
            )}
          </Flex>
          <AcceptedBidSharePrices
            acceptedBidPrices={acceptedBidPrices}
            numSharesSold={numSharesSoldConditionally}
          />
        </VStack>
      );
    case getIsConditionallySoldListing(listing):
      return (
        <VStack align="flex-start">
          <Flex as="del" color="grey.200" wrap="wrap" textStyle={textStyle}>
            <SensitiveText>{formatShares(numSharesOriginal)}</SensitiveText>
            {!!pricePerShare ? (
              <HStack spacing={6}>
                <SensitiveText>
                  &nbsp;@ {formatPricePerShare(pricePerShare)}/sh
                </SensitiveText>
                <SensitiveText>
                  {lot(numSharesOriginal, pricePerShare)}
                </SensitiveText>
              </HStack>
            ) : (
              <HStack>
                <SensitiveText>@</SensitiveText>
                <SensitiveText>
                  <LongHyphen />
                </SensitiveText>
              </HStack>
            )}
          </Flex>
          <AcceptedBidSharePrices
            acceptedBidPrices={acceptedBidPrices}
            numSharesSold={numSharesSoldConditionally}
          />
        </VStack>
      );
    default:
      return (
        <Flex wrap="wrap" textStyle={textStyle} columnGap={1.5}>
          <SensitiveText>{formatShares(numSharesOriginal)}</SensitiveText>
          {!!pricePerShare ? (
            <HStack spacing={6}>
              <SensitiveText>
                @ {formatPricePerShare(pricePerShare)}/sh
              </SensitiveText>
              <SensitiveText>
                {lot(numSharesOriginal, pricePerShare)}
              </SensitiveText>
            </HStack>
          ) : (
            <HStack>
              <SensitiveText>@</SensitiveText>
              <SensitiveText>
                <LongHyphen />
              </SensitiveText>
            </HStack>
          )}
        </Flex>
      );
  }
};

export default ActivityCardListingNumShareAndPriceDetails;
