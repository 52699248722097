import { Control, FieldValues, Path, useController } from "react-hook-form";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Text,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";

interface FormTextAreaInputProps<TFieldValues extends FieldValues>
  extends TextareaProps {
  readonly control: Control<TFieldValues>;
  readonly name: Path<TFieldValues>;
  readonly label: string;
  readonly labelSrOnly?: FormLabelProps["srOnly"];
  readonly displayError?: boolean;
  readonly displayCounter?: boolean;
}

const FormTextAreaInput = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  labelSrOnly,
  isRequired,
  displayError = true,
  maxLength,
  displayCounter = false,
  ...inputProps
}: FormTextAreaInputProps<TFieldValues>) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel srOnly={labelSrOnly}>
        {isRequired ? `${label} *` : label}
      </FormLabel>
      <Textarea minH={10} {...inputProps} {...field} />
      {!!maxLength && displayCounter && (
        <Text textAlign="right" w="full" textStyle="text-sm" mt={1}>
          {!field.value ? `0` : field.value.length}/{maxLength}
        </Text>
      )}
      {displayError && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormTextAreaInput;
