function end<T>(value: T) {
  return function next() {
    return value;
  };
}

function step<T>(value: T) {
  return function next<TStep>(callback: (value: T) => TStep) {
    const stepValue = callback(value);

    return {
      step: step(stepValue),
      end: end(stepValue),
    };
  };
}

export function pipe<T>(value?: T) {
  return {
    step: step(value as T),
  };
}
