import { SensitiveText, Tile, TileHeader, TileRow } from "@/components/common";
import { StatusTile } from "@/components/listings";
import {
  ListingPageListingStatusTileListingFragment,
  ListingState,
} from "@/gql";
import { format, getHasExpired } from "@/utils/datetime";

interface ListingStatusTileProps {
  readonly listing: ListingPageListingStatusTileListingFragment;
}

const ListingStatusTile = ({ listing }: ListingStatusTileProps) => {
  const showExpiry =
    !!listing.expireAt &&
    [ListingState.Open, ListingState.Expired].includes(listing.state);

  return (
    <Tile
      py={2}
      gap={4}
      display="grid"
      gridTemplateColumns={{ base: `1fr`, md: `auto 1fr` }}
      alignItems="baseline"
      data-testid="listing-status-tile"
    >
      {showExpiry && (
        <TileRow>
          <TileHeader>
            {getHasExpired(listing.expireAt) ? `Expired` : `Expires`}
          </TileHeader>
          <SensitiveText>
            {format(`Do MMM (h:mm A z)`, listing.expireAt)}
          </SensitiveText>
        </TileRow>
      )}
      <StatusTile listing={listing} />
    </Tile>
  );
};

export default ListingStatusTile;
