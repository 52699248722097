import { useTranslation } from "react-i18next";

import {
  Card,
  CardHeader,
  HStack,
  CardBody,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

import { IssuerInfoCard } from "./IssuerInfoCard";

interface CardRowSkeletonProps {
  readonly title: string;
}

const CardRowSkeleton = ({ title }: CardRowSkeletonProps) => (
  <Flex py={5} px={6} display="grid" gridTemplateColumns="1fr 1fr" gap={5}>
    <Text
      textStyle="deprecated-heading-xs"
      color="grey.900"
      textTransform="uppercase"
    >
      {title}
    </Text>
    <Box>
      <Skeleton h="16px" w="60%" />
    </Box>
  </Flex>
);

export const IssuerProfilePageSkeleton = () => {
  const { t } = useTranslation(`issuers`);

  return (
    <>
      <Card variant="flat">
        <CardHeader>
          <HStack minH={10}>
            <Skeleton
              h={{ base: 6, sm: 8 }}
              w={{ base: 6, sm: 8 }}
              mr={{ base: 2, sm: 4 }}
            />
            <Skeleton h="28px" w="20%" />
          </HStack>
        </CardHeader>
        <CardBody>
          <Text as="h4" w="full" textStyle="heading-sm" mb={2}>
            {t(`about`)}
          </Text>
          <Skeleton h="16px" w="60%" mb={2} />
        </CardBody>
      </Card>
      <Card variant="flat">
        <CardHeader px={6} py={6}>
          <HStack justifyContent="space-between">
            <Text as="h5" textStyle="heading-sm">
              {t(`general_details`)}
            </Text>
          </HStack>
        </CardHeader>
        <IssuerInfoCard>
          <CardRowSkeleton title={t(`last_round_value`)} />
          <CardRowSkeleton title={t(`total_capital_raised`)} />
          <CardRowSkeleton title={t(`notable_investors`)} />
          <CardRowSkeleton title={t(`industries`)} />
        </IssuerInfoCard>
      </Card>
      <Card variant="flat">
        <CardHeader px={6} py={6}>
          <HStack justifyContent="space-between">
            <Text as="h5" textStyle="heading-sm">
              {t(`last_round_details`)}
            </Text>
          </HStack>
        </CardHeader>
        <IssuerInfoCard>
          <CardRowSkeleton title={t(`date`)} />
          <CardRowSkeleton title={t(`round`)} />
          <CardRowSkeleton title={t(`capital_raised`)} />
        </IssuerInfoCard>
      </Card>
    </>
  );
};
