import { createContext, ReactNode, useMemo } from "react";

import { IssuerActorFragment, useIssuerActorQuery } from "@/gql";

export type IssuerCurrentActorContext = IssuerActorFragment;

export const IssuerCurrentActorContext =
  createContext<IssuerCurrentActorContext>({} as IssuerCurrentActorContext);

export function IssuerCurrentActorProvider({
  children,
}: {
  readonly children: ReactNode;
}) {
  const { data, loading } = useIssuerActorQuery();
  const { currentActor } = data?.currentContext || {};
  const actor = useMemo(() => currentActor, [currentActor, loading]);

  if (!actor) {
    return null;
  }

  return (
    <IssuerCurrentActorContext.Provider value={actor}>
      {children}
    </IssuerCurrentActorContext.Provider>
  );
}
