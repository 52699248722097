import { Box, CardHeader, HStack, Text } from "@chakra-ui/react";

import { ActivityCardBadge, Logo } from "@/components/common";
import { CustomTooltip } from "@/components/form";
import { CompanyListingCardHeaderListingFragment } from "@/gql";
import { isHiiveFund } from "@/utils";

const Tooltip = () => (
  <Box pos="absolute" right={5}>
    <CustomTooltip
      placement="bottom"
      tooltipContent="This fund is organized by Hiive"
      pos="absolute"
    >
      <Logo.DarkGreyIcon width="16px" height="20px" />
    </CustomTooltip>
  </Box>
);

const CompanyListingCardHeader = ({
  listing,
}: {
  readonly listing: CompanyListingCardHeaderListingFragment;
}) => {
  const { displayId } = listing;

  return (
    <CardHeader>
      <HStack justifyContent="space-between">
        <HStack>
          <ActivityCardBadge variant="listing" title="Listing" />
          <Text textStyle="text-lg" color="grey.900">
            {displayId}
          </Text>
          {isHiiveFund(listing) && <Tooltip />}
        </HStack>
      </HStack>
    </CardHeader>
  );
};

export default CompanyListingCardHeader;
