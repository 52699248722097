import { useMemo } from "react";

import {
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardFooterWrapper,
  ActivityCardMostRecentMessage,
} from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  ListingPageSellerActivityListingFragment,
  MyDiscussionListDiscussionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  appendSellerCompanyIdToUrl,
  getDiscussionHasCounterpartyMessage,
  getDiscussionStatusText,
  getIsUnaccreditedSeller,
} from "@/utils";

const ListingPageInquiryCard = withCurrentActor(
  ({
    discussion,
    actor,
    listing,
  }: {
    readonly discussion: MyDiscussionListDiscussionFragment;
    readonly actor: UserWithInstitutionFragment;
    readonly listing?: ListingPageSellerActivityListingFragment;
  }) => {
    const hasCounterpartyMessage = getDiscussionHasCounterpartyMessage(
      actor,
      discussion,
    );

    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    const url = `/discussions/${discussion.id}`;

    const discussionStatus = useMemo(
      () => getDiscussionStatusText(discussion),
      [discussion.state],
    );

    const hasMessages = discussion.totalNumMessages > 0;

    return (
      <ActivityCard
        href={
          isUnaccreditedSeller && listing?.companyId
            ? appendSellerCompanyIdToUrl(url, listing.companyId)
            : url
        }
      >
        <CardHeader>
          <HStack>
            <ActivityCardBadge variant="discussion" title="Inquiry" />
            <Text textStyle="text-lg">{discussion.displayId}</Text>
          </HStack>
        </CardHeader>
        {hasCounterpartyMessage && !!discussion.mostRecentMessage && (
          <CardBody>
            <ActivityCardMostRecentMessage discussion={discussion} />
          </CardBody>
        )}
        <CardFooter>
          <ActivityCardFooterWrapper>
            <Text textStyle="text-sm">{discussionStatus}</Text>
            {hasMessages && (
              <MessagesBadge
                numUnreadMessages={discussion.numUnreadMessages}
                totalNumMessages={discussion.totalNumMessages}
              />
            )}
          </ActivityCardFooterWrapper>
        </CardFooter>
      </ActivityCard>
    );
  },
);

export default ListingPageInquiryCard;
