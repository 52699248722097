import { useTranslation } from "react-i18next";

import { Card, Text, VStack } from "@chakra-ui/react";

import { FileCard } from "@/components/common";
import {
  IssuerPageHeader,
  IssuerPortalPageContainer,
} from "@/components/issuers";
import { IssuerPreferenceFragment, useIssuerTemplatesPageQuery } from "@/gql";
import { useCurrentIssuerActor, useIsDesktop } from "@/hooks";

import { CompanyInfoTabs } from "./CompanyInfoTabs";
import { IssuerTemplatesPageSkeleton } from "./IssuerTemplatesPageSkeleton";

interface IssuerPreferencesPageContentProps {
  issuerPreference?: IssuerPreferenceFragment | null;
}

const IssuerTemplatesPageContent = ({
  issuerPreference,
}: IssuerPreferencesPageContentProps) => {
  const { t } = useTranslation(`issuers`);

  if (!issuerPreference || !issuerPreference?.staTemplateFileUpload?.id) {
    return (
      <VStack align="flex-start" gap={3}>
        <Text>{t(`no_preferences_1`)}</Text>
        <Text>{t(`no_preferences_2`)}</Text>
      </VStack>
    );
  }

  const { staTemplateFileUpload } = issuerPreference;

  return (
    <>
      <Text textStyle="heading-md">{t(`company_template_documents`)}:</Text>
      <FileCard fileUpload={staTemplateFileUpload} w={300} />
    </>
  );
};

const IssuerTemplatesPage = () => {
  const { t } = useTranslation(`issuers`);
  const actor = useCurrentIssuerActor();
  const { data, loading } = useIssuerTemplatesPageQuery({
    variables: { id: actor.issuer?.id || `` },
  });

  const { issuerPreference } = data?.companyById || {};
  const isDesktop = useIsDesktop();

  return (
    <IssuerPortalPageContainer
      header={
        <>
          <IssuerPageHeader title={t(`company_info`)} />
          <CompanyInfoTabs />
        </>
      }
      content={
        <Card p={6} gap={6} borderRadius={isDesktop ? `md` : `unset`}>
          {loading ? (
            <IssuerTemplatesPageSkeleton />
          ) : (
            <IssuerTemplatesPageContent issuerPreference={issuerPreference} />
          )}
        </Card>
      }
    />
  );
};

export default IssuerTemplatesPage;
