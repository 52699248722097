import * as Apollo from "@apollo/client";
import { Warning } from "@phosphor-icons/react";
import { Control, UseFormWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Box, BoxProps, Flex, Text, VStack } from "@chakra-ui/react";

import { CallOut, HiiveButton, InternalLink } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import { FormRadioTile, FormTextAreaInput } from "@/components/react-hook-form";
import {
  AccreditationQuestion,
  AnswerAccreditationQuestionsNewMutation,
  AnswerAccreditationQuestionsNewMutationVariables,
  InvestorStatus,
  InvestorType,
  Maybe,
  useAnswerAccreditationQuestionsNewMutation,
  useCurrentAccreditationQuestionGroupQuery,
  useTransitionCurrentStepMutation,
  useUpdateUserMutation,
} from "@/gql";
import { useColors, useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { shadows } from "@/styles/theme";
import { OtherAccreditationCriteriaQuestionKeys } from "@/utils/accreditation";

import { UseAccreditationFormProps } from "./InvestorAccreditationPageV2";

interface InvestorAccreditationFormValues {
  readonly questionKey?: string | null;
  readonly questionText?: string | null;
}

export const useInvestorAccreditationForm = ({
  accreditationAnswers,
  accreditationQuestions,
  onSuccess,
  mutationOptions,
}: Omit<UseAccreditationFormProps, `mutationOptions`> & {
  readonly mutationOptions?: Apollo.MutationHookOptions<
    AnswerAccreditationQuestionsNewMutation,
    AnswerAccreditationQuestionsNewMutationVariables
  >;
}) => {
  const actor = useCurrentActor();
  const { t } = useTranslation();

  const mutation = useAnswerAccreditationQuestionsNewMutation(mutationOptions);

  const hasPreviousAnswers =
    !!accreditationAnswers && accreditationAnswers.length > 0;
  const initialValues = {
    questionKey: hasPreviousAnswers
      ? accreditationAnswers[0].question.key
      : null,
    questionText: hasPreviousAnswers ? accreditationAnswers[0].text : null,
  };

  const validationSchema = Yup.object().shape({
    questionKey: Yup.string()
      .oneOf(
        accreditationQuestions.map(
          (question: AccreditationQuestion) => question.key,
        ),
      )
      .nullable()
      .required(t`required`),
    questionText: Yup.string()
      .nullable()
      .when(`questionKey`, {
        is: (questionKey: string) =>
          OtherAccreditationCriteriaQuestionKeys.includes(questionKey),
        then: (schema) => schema.required(t`required`),
        otherwise: (schema) => schema.optional(),
      }),
  });

  const mapVariables = ({
    questionKey,
    questionText,
  }: InvestorAccreditationFormValues) => ({
    input: {
      countryId: actor.country?.id,
      answers: [
        {
          key: questionKey || ``,
          text: !!questionText ? questionText : undefined,
        },
      ],
    },
  });

  return useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    mode: `onChange`,
    onSuccess,
  });
};

export const InvestorAccreditationFormFields = ({
  accreditationQuestions,
  control,
  onContinueAsSeller,
  watch,
  ...boxProps
}: {
  readonly accreditationQuestions: Maybe<AccreditationQuestion>[];
  readonly control: Control<InvestorAccreditationFormValues>;
  readonly watch: UseFormWatch<InvestorAccreditationFormValues>;
  readonly onContinueAsSeller?: () => void;
} & BoxProps) => {
  const { t } = useTranslation();

  const [yellow600] = useColors([`yellow.600`]);
  const questionKeyValue = watch(`questionKey`);
  const unaccreditedQuestion = accreditationQuestions
    .slice()
    .reverse()
    .find((question: AccreditationQuestion) => !question.accredited);

  const shouldRenderUnaccreditedWarning =
    !!onContinueAsSeller &&
    unaccreditedQuestion &&
    unaccreditedQuestion?.key &&
    questionKeyValue === unaccreditedQuestion.key;

  return (
    <FormRadioTile.Group
      size="md"
      bg="white"
      p={{ base: 4, lg: 6 }}
      borderRadius="md"
      borderColor="grey.200"
      borderWidth={0.5}
      boxShadow={shadows.card}
      gap={6}
      w={{ base: `full`, lg: 160 }}
      {...boxProps}
    >
      <VStack gap={4} w="full">
        {accreditationQuestions
          .slice()
          .sort(
            (a: AccreditationQuestion, b: AccreditationQuestion) =>
              a.order - b.order,
          )
          .map((question: AccreditationQuestion) => (
            <FormRadioTile.Tile
              key={question.id}
              name="questionKey"
              control={control}
              value={question.key!}
            >
              <Text>{question.text}</Text>
            </FormRadioTile.Tile>
          ))}
      </VStack>
      {OtherAccreditationCriteriaQuestionKeys.includes(
        questionKeyValue || ``,
      ) && (
        <VStack alignItems="flex-start" w="full">
          <Text fontWeight={500}>{t`please_describe`}</Text>
          <FormTextAreaInput
            w="full"
            control={control}
            name="questionText"
            label=""
            placeholder={t`description`}
            labelSrOnly
          />
        </VStack>
      )}
      {shouldRenderUnaccreditedWarning && (
        <CallOut variant="yellow">
          <Box
            as={Warning}
            size={20}
            color={yellow600}
            weight="fill"
            flex="none"
            alignSelf="flex-start"
          />
          <VStack gap={1} alignItems="flex-start">
            <Text textStyle="text-md">
              <Trans
                i18nKey="investor_accreditation_disclaimer"
                components={{
                  bold: (
                    <InternalLink
                      href=""
                      onClick={onContinueAsSeller}
                      style={{
                        fontWeight: 500,
                        textDecorationLine: `underline`,
                      }}
                    />
                  ),
                }}
              />
            </Text>
            <Text textStyle="text-md">{t`investor_accreditation_disclaimer_2`}</Text>
          </VStack>
        </CallOut>
      )}
    </FormRadioTile.Group>
  );
};

export const InvestorAccreditationForm = () => {
  const actor = useCurrentActor();
  const [updateUserMutation] = useUpdateUserMutation();

  const { t } = useTranslation();
  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const { data } = useCurrentAccreditationQuestionGroupQuery({
    variables: {
      investorType: InvestorStatus.Individual,
      countryId: actor.country?.id || ``,
    },
  });

  const accQuestionGroup = data?.currentAccreditationQuestionGroup;
  const accQuestions = accQuestionGroup?.questions || [];

  const onSuccess = async () => {
    await transitionCurrentStepMutation();
  };

  const { handleSubmit, isLoading, control, formState, watch } =
    useInvestorAccreditationForm({
      accreditationQuestions: accQuestions,
      accreditationAnswers: actor.accreditationAnswers,
      onSuccess,
    });

  const onContinueAsSeller = async () => {
    await updateUserMutation({
      variables: { input: { investorType: InvestorType.UnaccreditedSeller } },
    });
    await transitionCurrentStepMutation();
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <SlideAnimation>
        <InvestorAccreditationFormFields
          control={control}
          watch={watch}
          accreditationQuestions={accQuestions}
          onContinueAsSeller={onContinueAsSeller}
        />
      </SlideAnimation>
      <Flex w="full" justify="flex-end" mt={8}>
        <HiiveButton
          sentryLabel="[InvestorStatusPageV2/Submit]"
          variant="rounded-solid-salmon"
          type="submit"
          size="xl"
          maxW="unset"
          w={{ base: `full`, lg: `unset` }}
          isLoading={isLoading || isTransitioningCurrentStep}
          isDisabled={!formState.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </Flex>
    </form>
  );
};
