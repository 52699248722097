import { WarningCircle } from "@phosphor-icons/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Image, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PIN_LENGTH,
} from "@/components/common";
import { useModal } from "@/hooks";
import { useVerifyAppMfa } from "@/hooks/frontegg";

import { EnrollAppMfaSuccessAction } from "./EnrollAppMfaSuccessModal";

interface EnrollAppMfaModalProps {
  readonly isHiiveUser: boolean;
  readonly qrCode: string;
  readonly successAction: EnrollAppMfaSuccessAction;
}

const EnrollAppMfaModal = ({
  isHiiveUser,
  qrCode,
  successAction,
}: EnrollAppMfaModalProps) => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();

  const [pinValue, setPinValue] = useState<string>(``);

  const [executeVerifyMfa, { loading, error }] = useVerifyAppMfa({
    onSuccess: (data) => {
      const { recoveryCode } = data;
      const mfaAppEnrollCompleteModal = onOpenModal(
        modals.enrollAppMfaSuccess(recoveryCode, successAction),
      );

      mfaAppEnrollCompleteModal();
    },
  });

  const handleComplete = useCallback(
    async (value: string) => {
      await executeVerifyMfa({ token: value });
    },
    [executeVerifyMfa],
  );

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>
          {isHiiveUser
            ? t(`set_up_authenticator_app_hiive`)
            : t(`set_up_authenticator_app`)}
        </Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="center" gap={4}>
          <Text>
            {isHiiveUser
              ? t(`set_up_authenticator_app_hiive_user_description`)
              : t(`set_up_authenticator_app_description`)}
          </Text>
          {qrCode && <Image src={qrCode} />}
          <CodeInput
            disabled={loading}
            onChange={(value: string) => setPinValue(value)}
            onComplete={handleComplete}
          />
          {error && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{t(`incorrect_code`)}</Text>
            </CallOut>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            isDisabled={pinValue.length !== PIN_LENGTH || loading}
            sentryLabel="[EnrollAppMfa/Submit]"
            submitText={t(`next`)}
            type="submit"
            onClick={() => handleComplete(pinValue)}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default EnrollAppMfaModal;
