import { ReactNode } from "react";

import { useRouter } from "next/router";

import { Box, BoxProps, Flex, HStack, Link, VStack } from "@chakra-ui/react";

import { InternalLink } from "@/components/common";
import { IssuerTooltip } from "@/components/issuers";

type DesktopSideBarLinkProps = {
  children: ReactNode;
  icon: ReactNode;
  href: string;
  paths?: string[];
};

type DesktopSideBarFooterLinkProps = {
  children: ReactNode;
  href?: string;
};

type DesktopNavProps = {
  children: ReactNode;
  isOpen: boolean;
};

type DesktopSideBarContentProps = {
  children: ReactNode;
};

type DesktopSideBarFooterProps = {
  children: ReactNode;
};

type DesktopSideBarCollapsedLinkProps = {
  children: ReactNode;
  label: ReactNode;
  href: string;
  paths?: string[];
};

type DesktopNavHeaderProps = {
  children: ReactNode;
};

const DESKTOP_NAV_TOGGLE_EXPANDED_WIDTH = 380;
const DESKTOP_NAV_TOGGLE_COLLAPSED_WIDTH = 90;

export const DESKTOP_NAV_LINK_TOOLTIP_DELAY = 400;

const DESKTOP_NAV_LINK_TRANSITION = `all 200ms ease-in-out`;
const DESKTOP_NAV_TOGGLE_TRANSITION = `all 200ms ease-in-out`;

export function DesktopSideBarLink({
  children,
  icon,
  href,
  paths,
}: DesktopSideBarLinkProps) {
  const { pathname } = useRouter();
  const active = pathname === href || (paths && paths.includes(pathname));

  return (
    <InternalLink
      href={href}
      borderRadius={8}
      textDecor="none !important"
      bg={active ? `grey.75` : `transparent`}
      w="full"
      textTransform="capitalize"
      transition={DESKTOP_NAV_LINK_TRANSITION}
      _hover={
        active ? {} : { bg: `grey.50`, transition: DESKTOP_NAV_LINK_TRANSITION }
      }
    >
      <HStack
        justifyContent="flex-start"
        px="14.75px"
        py={2}
        color="grey.900"
        fontWeight="500"
        fontSize={16}
      >
        <Box flexShrink={1}>{icon}</Box>
        <Box>{children}</Box>
      </HStack>
    </InternalLink>
  );
}

export function DesktopSideBarCollapsedLink({
  children,
  href,
  paths,
  label,
}: DesktopSideBarCollapsedLinkProps) {
  const { pathname } = useRouter();
  const active = pathname === href || (paths && paths.includes(pathname));

  return (
    <IssuerTooltip
      placement="right-end"
      label={label}
      textTransform="capitalize"
      openDelay={DESKTOP_NAV_LINK_TOOLTIP_DELAY}
    >
      <Flex w="full">
        <InternalLink
          href={href}
          borderRadius={8}
          textDecor="none !important"
          bg={active ? `grey.75` : `transparent`}
          w="full"
          transition={DESKTOP_NAV_LINK_TRANSITION}
          _hover={
            active
              ? {}
              : { bg: `grey.50`, transition: DESKTOP_NAV_LINK_TRANSITION }
          }
        >
          <HStack
            px={3}
            py={2}
            color="grey.900"
            fontWeight="500"
            fontSize={16}
            w="full"
            justifyContent="center"
          >
            {children}
          </HStack>
        </InternalLink>
      </Flex>
    </IssuerTooltip>
  );
}

export function DesktopSideBarDivider() {
  return (
    <Box
      h={0}
      w="full"
      borderColor="grey.100"
      borderStyle="solid"
      borderWidth={0.5}
      my={2}
    />
  );
}

export function DesktopNavHeader({ children }: DesktopNavHeaderProps) {
  return (
    <Box w="full" px={9} pt={8} pb={0}>
      {children}
    </Box>
  );
}

export function DesktopNavContent({ children }: DesktopSideBarContentProps) {
  return (
    <VStack alignItems="flex-start" pt={2} gap={2} px="20px" w="full">
      {children}
    </VStack>
  );
}

export function DesktopSideBarFooter({ children }: DesktopSideBarFooterProps) {
  return (
    <VStack alignItems="flex-start" gap={2} w="full">
      {children}
    </VStack>
  );
}

export function DesktopSideBarFooterLink({
  children,
  href,
}: DesktopSideBarFooterLinkProps) {
  return (
    <Link
      href={href}
      p={6}
      textDecor="none !important"
      w="full"
      borderTopWidth={1}
      borderStyle="solid"
      borderColor="grey.100"
    >
      {children}
    </Link>
  );
}

export function DesktopNavToggleButton({ children, ...restProps }: BoxProps) {
  return (
    <Flex
      role="button"
      borderRadius={8}
      alignItems="center"
      justifyContent="center"
      transition={DESKTOP_NAV_TOGGLE_TRANSITION}
      _hover={{
        cursor: `pointer`,
        bg: `grey.50`,
        transition: DESKTOP_NAV_TOGGLE_TRANSITION,
      }}
      {...restProps}
    >
      {children}
    </Flex>
  );
}

export function DesktopNav({ children, isOpen }: DesktopNavProps) {
  return (
    <Box
      w={
        isOpen
          ? DESKTOP_NAV_TOGGLE_EXPANDED_WIDTH
          : DESKTOP_NAV_TOGGLE_COLLAPSED_WIDTH
      }
      h="full"
    >
      <VStack
        alignItems="flex-start"
        gap={2}
        bg="white"
        h="100vh"
        w="full"
        borderColor="grey.100"
        borderStyle="solid"
        borderRightWidth={1}
      >
        {children}
      </VStack>
    </Box>
  );
}
