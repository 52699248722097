import { match } from "ts-pattern";

import {
  StandingBidAcceptsTransferMethodsOptions,
  StandingBidAcceptsTransferMethodsOptionsV2,
  TransferMethod,
  TransferMethodV2,
  UnaccreditedSellerTransferMethodInput,
  UnaccreditedSellerTransferMethodInputV2,
} from "@/gql";

/**
 * Converts StandingBidAcceptsTransferMethodsOptions to proper TransferMethod
 */
export const getTransferMethodByStandingBidAcceptsTransferMethodsOptions = (
  transferMethod:
    | StandingBidAcceptsTransferMethodsOptions
    | StandingBidAcceptsTransferMethodsOptionsV2,
): TransferMethodV2 =>
  match(transferMethod)
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.Other,
      () => TransferMethodV2.Other,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.Direct,
      () => TransferMethodV2.Direct,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.ForwardContract,
      () => TransferMethodV2.ForwardContract,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.SpvLister,
      () => TransferMethodV2.SpvLister,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.SpvThirdparty,
      () => TransferMethodV2.SpvThirdparty,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.Fund,
      () => TransferMethodV2.Fund,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transferMethod in getTransferMethodByStandingBidAcceptsTransferMethodsOptions: ${transferMethod}`,
      );
    });

/*
 * Converts a single StandingBidAcceptsTransferMethodsOptionsV2 to StandingBidAcceptsTransferMethodsOptions
 */
export const getV1StandingBidAcceptsTransferMethodsOption = (
  transferMethod: StandingBidAcceptsTransferMethodsOptionsV2,
): StandingBidAcceptsTransferMethodsOptions =>
  match(transferMethod)
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.Direct,
      () => StandingBidAcceptsTransferMethodsOptions.Direct,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.ForwardContract,
      () => StandingBidAcceptsTransferMethodsOptions.ForwardContract,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.SpvLister,
      () => StandingBidAcceptsTransferMethodsOptions.SpvLister,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.SpvThirdparty,
      () => StandingBidAcceptsTransferMethodsOptions.SpvThirdparty,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.Fund,
      () => StandingBidAcceptsTransferMethodsOptions.SpvLister,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.Other,
      () => StandingBidAcceptsTransferMethodsOptions.ForwardContract,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transferMethod in getV1StandingBidAcceptsTransferMethodsOption: ${transferMethod}`,
      );
    });

/**
 * Converts V1 transfer methods to V2, or if V2 will remain the same
 */
export const getV2TransferMethodFromV1 = (
  transferMethod: TransferMethod | TransferMethodV2,
): TransferMethodV2 =>
  match(transferMethod)
    .with(TransferMethod.Direct, () => TransferMethodV2.Direct)
    .with(
      TransferMethod.ForwardContract,
      () => TransferMethodV2.ForwardContract,
    )
    .with(TransferMethod.SpvLister, () => TransferMethodV2.SpvLister)
    .with(TransferMethod.SpvThirdparty, () => TransferMethodV2.SpvThirdparty)
    .with(TransferMethodV2.Fund, () => TransferMethodV2.Fund)
    .with(TransferMethodV2.HiiveFund, () => TransferMethodV2.HiiveFund)
    .with(TransferMethodV2.Other, () => TransferMethodV2.Other)
    .otherwise(() => {
      throw new Error(
        `Unknown transferMethod in getV2TransferMethodFromV1: ${transferMethod}`,
      );
    });

/**
 * Converts UnaccreditedSellerTransferMethodInput to proper TransferMethod
 */
export const getTransferMethodByUnaccreditedSellerTransferMethodInput = (
  transferMethod: UnaccreditedSellerTransferMethodInput,
) =>
  match(transferMethod)
    .with(
      UnaccreditedSellerTransferMethodInput.Direct,
      () => TransferMethod.Direct,
    )
    .with(
      UnaccreditedSellerTransferMethodInput.ForwardContract,
      () => TransferMethod.ForwardContract,
    )
    .with(
      UnaccreditedSellerTransferMethodInput.Unknown,
      () => TransferMethod.Unknown,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transferMethod in getTransferMethodByUnaccreditedSellerTransferMethodInput: ${transferMethod}`,
      );
    });

export const getTransferMethodByUnaccreditedSellerTransferMethodInputV2 = (
  transferMethod: UnaccreditedSellerTransferMethodInputV2,
) =>
  match(transferMethod)
    .with(
      UnaccreditedSellerTransferMethodInputV2.Direct,
      () => TransferMethodV2.Direct,
    )
    .with(
      UnaccreditedSellerTransferMethodInputV2.ForwardContract,
      () => TransferMethodV2.ForwardContract,
    )
    .with(
      UnaccreditedSellerTransferMethodInputV2.Unknown,
      () => TransferMethodV2.Unknown,
    )
    .with(
      UnaccreditedSellerTransferMethodInputV2.Other,
      () => TransferMethodV2.Other,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transferMethod in getTransferMethodByUnaccreditedSellerTransferMethodInput: ${transferMethod}`,
      );
    });
