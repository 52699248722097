import { useEffect } from "react";

import { useRouter } from "next/router";

import { WithQuery } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { BidPageSkeleton, BidPageContent } from "@/components/postings";
import {
  TransactionPageTransactionFragment,
  UserWithInstitutionFragment,
  useTransactionPageTexasEnabledQuery,
  useTransactionPageTransactionByIdQuery,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { appendSellerCompanyIdToUrl, getIsUnaccreditedSeller } from "@/utils";

import { TransactionExecutionPage } from "./TransactionExecutionPage";

interface Props {
  id: string;
  transaction: TransactionPageTransactionFragment;
}

const BidPageContentWithRedirect: React.FC<Props> = ({ transaction, id }) => {
  const router = useRouter();
  const { query: params } = router;
  const actor = useCurrentActor();
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  useEffect(() => {
    const url = `/transactions/${id}`;

    if (isUnaccreditedSeller && !params.sellerCompanyId) {
      router.replace(
        appendSellerCompanyIdToUrl(url, transaction.bid.companyId),
      );
    }
  }, [isUnaccreditedSeller, params.sellerCompanyId]);

  return <BidPageContent bid={transaction.bid} />;
};

const LegacyTransactionPage = withCurrentActor(
  ({
    id,
  }: {
    readonly id: string;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const router = useRouter();
    const query = useTransactionPageTransactionByIdQuery({
      variables: {
        id,
      },
      fetchPolicy: `cache-and-network`,
      skip: !router.isReady,
    });

    return (
      <WithQuery query={query} fallback={<BidPageSkeleton />}>
        {({
          data: { transactionById: transaction },
        }: {
          readonly data: {
            readonly transactionById: TransactionPageTransactionFragment;
          };
        }) => <BidPageContentWithRedirect transaction={transaction} id={id} />}
      </WithQuery>
    );
  },
);

const TransactionPage = ({ id }: { readonly id: string }) => {
  const query = useTransactionPageTexasEnabledQuery({
    variables: { id },
  });

  return (
    <WithQuery query={query} hideLoadingSpinner>
      {({ data: { transactionById: transaction } }) =>
        transaction?.texasEnabled ? (
          <TransactionExecutionPage id={id} />
        ) : (
          <LegacyTransactionPage id={id} />
        )
      }
    </WithQuery>
  );
};

export default TransactionPage;
