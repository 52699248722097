import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { useRouter } from "next/router";

import {
  HoldingsQuery,
  InvestorType,
  ListedHoldingFragment,
  useCurrentContextQuery,
  useHoldingsQuery,
} from "@/gql";

interface HoldingContextType {
  readonly holdingCompany: ListedHoldingFragment | null;
  readonly setHoldingCompany: (holding: ListedHoldingFragment | null) => void;
  readonly holdings: HoldingsQuery["myHoldings"]["holdingsV2"] | undefined;
  readonly loading: boolean;
}

const HoldingContext = createContext<HoldingContextType>({
  holdingCompany: null,
  setHoldingCompany: () => {},
  holdings: undefined,
  loading: false,
});

export const useHoldingSwitcher = () => useContext(HoldingContext);

export const HoldingProvider = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  const router = useRouter();
  const { data: currentContextData } = useCurrentContextQuery();

  const isActorRestrictedSeller =
    currentContextData?.currentContext?.currentActor?.investorType ===
    InvestorType.UnaccreditedSeller;
  const { data, loading } = useHoldingsQuery({
    skip: !isActorRestrictedSeller,
  });
  const [holdingCompany, setHoldingCompany] =
    useState<ListedHoldingFragment | null>(null);

  const { query } = router;
  const { id, sellerCompanyId } = query;
  const isDashboardPage = window.location.pathname.startsWith(`/dashboard`);
  const holdings = data?.myHoldings.holdingsV2;

  useEffect(() => {
    if (data?.myHoldings.holdingsV2 && isActorRestrictedSeller) {
      // get holding matching the query id
      const holdingWithCompanyId = data.myHoldings.holdingsV2.find(
        (holding) =>
          holding.__typename === `Holding` && holding.companyId === id,
      ) as ListedHoldingFragment | undefined;

      // get first listed holding if no id is provided
      const firstHolding = data.myHoldings.holdingsV2.find(
        (holding) => holding.__typename === `Holding`,
      ) as ListedHoldingFragment | undefined;

      // if holdingCompany is set and the sellerCompanyId is different, update the holding company
      if (
        holdingCompany &&
        sellerCompanyId &&
        sellerCompanyId !== holdingCompany.id
      ) {
        const holding = data.myHoldings.holdingsV2.find(
          (holding) =>
            holding.__typename === `Holding` &&
            holding.companyId === sellerCompanyId,
        ) as ListedHoldingFragment;

        setHoldingCompany(holding);
        return;
      }

      // if holdingCompany is set and the id is different, update the holding company
      if (isDashboardPage && holdingCompany && id && id !== holdingCompany.id) {
        const holding = data.myHoldings.holdingsV2.find(
          (holding) =>
            holding.__typename === `Holding` && holding.companyId === id,
        ) as ListedHoldingFragment;

        setHoldingCompany(holding);
        return;
      }

      // if no id or sellerCompanyId param was given, default to the first holding
      if (!holdingCompany?.id) {
        setHoldingCompany(holdingWithCompanyId || firstHolding || null);
        return;
      }

      // if on the dashboard page and no id is provided, redirect to the first holding
      if (isDashboardPage && !id && firstHolding) {
        router.replace(`/dashboard/${firstHolding.companyId}`);
      }
    }
  }, [data, loading, isDashboardPage, id, holdingCompany?.id, sellerCompanyId]);

  const value = useMemo(
    () => ({
      holdingCompany,
      setHoldingCompany,
      holdings,
      loading,
    }),
    [holdingCompany, setHoldingCompany, data, loading],
  );

  return (
    <HoldingContext.Provider value={value}>{children}</HoldingContext.Provider>
  );
};
