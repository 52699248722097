import { ArrowsOutSimple } from "@phosphor-icons/react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
  IconButton,
  Image,
} from "@chakra-ui/react";

import { CollapsibleInlineText } from "@/components/common";
import { Chart, Legend, ChartHeader } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { useColors, useObscureCompanyInfo } from "@/hooks";
import { useUpdatedPricingChartCopy } from "@/hooks/featureFlags";

import { PricingChartCompany } from "./types";

const PricingChartCard = ({
  company,
  onOpen,
  actor,
}: {
  readonly company: PricingChartCompany;
  readonly onOpen: () => void;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();
  const [grey900] = useColors([`grey.900`]);

  const { name, priceChartConfig } = company;
  const { showInfo } = useObscureCompanyInfo(actor);
  const shouldRenderUpdatedDisclaimerCopy = useUpdatedPricingChartCopy();

  return (
    <Card>
      <CardHeader py={4}>
        <HStack justifyContent="space-between">
          <Text textStyle="heading-sm">
            {t(`historical_pricing_heading`, {
              companyName: name,
            })}
          </Text>
          {showInfo && (
            <IconButton
              aria-label="Open Fullscreen"
              icon={<ArrowsOutSimple size={24} color={grey900} />}
              variant="boxed-grey"
              onClick={onOpen}
            />
          )}
        </HStack>
      </CardHeader>
      <CardBody>
        <VStack spacing={5} alignItems="start">
          {showInfo ? (
            <>
              <ChartHeader
                availableTimeRanges={priceChartConfig.availableTimeRanges}
              />
              <Box h="96" w="full">
                <Chart />
              </Box>
              <Legend />
            </>
          ) : (
            <Image src="/assets/blurred-graph.png" maxH={160} w="full" />
          )}
          <CollapsibleInlineText
            collapsedText={t(
              shouldRenderUpdatedDisclaimerCopy
                ? `price_chart_note_collapsed_v2`
                : `price_chart_note_collapsed`,
            )}
            expandedText={
              <Text as="span" textStyle="text-xs" lineHeight="inherit">
                <Trans
                  i18nKey={
                    shouldRenderUpdatedDisclaimerCopy
                      ? `price_chart_note_full_v2`
                      : `price_chart_note_full`
                  }
                />
              </Text>
            }
            label={{ collapsed: `Read More`, expanded: `View Less` }}
          />
        </VStack>
      </CardBody>
    </Card>
  );
};

export default withCurrentActor(PricingChartCard);
