import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ActivityCardSkeleton,
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  Skeleton,
  WithQuery,
} from "@/components/common";
import { checkListingTerminalState } from "@/components/postings";
import {
  PostActivityUnaccreditedSellerDashboardPageActiveBidsPageBidFragment,
  PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragment,
  usePostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery,
} from "@/gql";
import { getAvailableListingActions } from "@/utils";

import { ActiveBidCard } from "./ActiveBidCard";
import { ListingActionsTile } from "./ListingActionsTile";
import { ListingInfoCard } from "./ListingInfoCard";
import { ProceedsDetailsCard } from "./ProceedsDetailsCard";

const ActiveBidsEmptyState = () => {
  const { t } = useTranslation();
  return <ActivityGroupEmptyState message={t(`no_active_bids_on_listing`)} />;
};

interface ActiveBidsProps {
  readonly activeBids: readonly PostActivityUnaccreditedSellerDashboardPageActiveBidsPageBidFragment[];
}

const ActiveBids = ({ activeBids }: ActiveBidsProps) => (
  <VStack alignItems="flex-start" spacing={4} w="full">
    <Text textStyle="heading-sm" textTransform="uppercase">
      Active Bids
    </Text>
    <Text textStyle="text-md">
      Click on the bid to review details and respond to the buyer.
    </Text>
    <ActivityGroup emptyState={<ActiveBidsEmptyState />}>
      {activeBids.map((activeBid) => (
        <ActiveBidCard key={activeBid.id} activeBid={activeBid} />
      ))}
    </ActivityGroup>
  </VStack>
);

interface MyListingProps {
  readonly listing: PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragment;
}

const MyListing = ({ listing }: MyListingProps) => {
  const { canWithdrawListing, canModifyListing } =
    getAvailableListingActions(listing);

  const isListingInTerminalState = checkListingTerminalState(listing.state);

  return (
    <VStack spacing={4} w="full">
      <ListingInfoCard listing={listing} />
      {!isListingInTerminalState && <ProceedsDetailsCard listing={listing} />}
      {(canWithdrawListing || canModifyListing) && (
        <ListingActionsTile listing={listing} />
      )}
    </VStack>
  );
};

const MyListingActivity = ({
  activeBids,
}: {
  readonly activeBids: readonly PostActivityUnaccreditedSellerDashboardPageActiveBidsPageBidFragment[];
}) => (
  <ActivitySection>
    <ActiveBids activeBids={activeBids} />
  </ActivitySection>
);

const ActiveBidsPageContent = ({
  listing,
  activeBids,
}: {
  readonly listing: PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragment;
  readonly activeBids: readonly PostActivityUnaccreditedSellerDashboardPageActiveBidsPageBidFragment[];
}) => (
  <VStack spacing={8} transform={{ base: `none`, lg: `translateY(-48px)` }}>
    <MyListing listing={listing} />
    <MyListingActivity activeBids={activeBids} />
  </VStack>
);

const ActiveBidsPageSkeleton = () => (
  <VStack spacing={8} transform={{ base: `none`, lg: `translateY(-48px)` }}>
    <Card w="full">
      <Card borderBottomRadius="30px" h="248px" variant="no-border">
        <CardBody>
          <VStack spacing={6}>
            <VStack w="full" spacing={4} alignItems="flex-start">
              <Skeleton h="28px" w="196px" />
              <Skeleton h="110px" w="full" />
            </VStack>
            <Skeleton h="18px" w="full" maxW="388px" />
          </VStack>
        </CardBody>
      </Card>
      <CardBody>
        <SimpleGrid columnGap={6} gridTemplateColumns="auto 1fr" rowGap={6}>
          <GridItem>
            <Skeleton h={4} w="132px" maxW="132px" />
          </GridItem>
          <GridItem>
            <Skeleton h="12px" maxW="64px" />
          </GridItem>
          <GridItem>
            <Skeleton h={4} w="124px" maxW="124px" />
          </GridItem>
          <GridItem>
            <VStack gap={3} w="full" alignItems="flex-start" spacing={0}>
              <Skeleton h="12px" w="100%" />
              <Skeleton h="12px" w="90%" />
            </VStack>
          </GridItem>
        </SimpleGrid>
      </CardBody>
    </Card>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" maxW="10%" />
      <Skeleton h="16px" maxW="50%" />
      <ActivityCardSkeleton includeBody />
    </Flex>
  </VStack>
);

const ActiveBidsPage = () => {
  const query =
    usePostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery();
  const router = useRouter();

  return (
    <WithQuery query={query} fallback={<ActiveBidsPageSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myListing },
          unaccreditedSellerMyListingActivity: { activeBids },
        },
      }) => {
        if (!myListing) {
          router.replace(`/dashboard`);
          return null;
        }

        return (
          <ActiveBidsPageContent listing={myListing} activeBids={activeBids} />
        );
      }}
    </WithQuery>
  );
};

export default ActiveBidsPage;
