const locale = {
  ten_characters: `10 characters`,
  one_uppercase: `One uppercase`,
  one_lowercase: `One lowercase`,
  one_number_or_special_character: `One number or special character (!?@#$%^&*_)`,
  contain_name_email_or_hiive: `Does not contain your first/last name, email address, or "Hiive"`,
  sign_up: `Sign up`,
  signup_terms_and_policy_agreement: `I agree to Hiive's <0>Terms of Use</0> and <1>Privacy Policy</1>.`,
  signup_key_placeholder: `Enter signup key`,
  signup_key: `Signup key`,
  signup_confirm_password: `Confirm password`,
  signup_password_policy: `Minimum of 10 characters, one uppercase letter, one lowercase letter, one
   number or special character (!?@#$%^&*_). Must not contain your first/last name, email address, or "Hiive".`,
  signup_password_placeholder: `Enter a password`,
  password: `Password`,
  email_address: `Email address`,
  email: `Email`,
  telephone: `Telephone`,
  first_name: `First name`,
  last_name: `Last name`,
  first: `First`,
  last: `Last`,
};

export default locale;
