import { ReactNode } from "react";

import { InternalLink } from "@/components/common";

const MTPrivacyPolicyLink = ({
  children,
}: {
  readonly children?: ReactNode;
}) => (
  <InternalLink
    href="https://www.moderntreasury.com/legal/privacy-policy"
    textStyle="text-xs"
    textDecoration="underline"
    target="_blank"
  >
    {children}
  </InternalLink>
);

export default MTPrivacyPolicyLink;
