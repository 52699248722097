import { useState, useCallback } from "react";

import { IssuerStage, IssuerTransactionSortField, SortDirection } from "@/gql";

export const DEFAULT_FETCH_LIMIT = 10;

export type IssuerTransactionsQueryVariables = {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  filterBy?: {
    searchText?: string | null;
    stage?: IssuerStage | null;
  };
  sortBy: {
    direction: SortDirection;
    field: IssuerTransactionSortField;
  };
};

export const DEFAULT_QUERY_VARIABLES: IssuerTransactionsQueryVariables = {
  first: DEFAULT_FETCH_LIMIT,
  sortBy: {
    direction: SortDirection.Asc,
    field: IssuerTransactionSortField.DisplayId,
  },
  filterBy: {
    stage: IssuerStage.InReview,
  },
};

export const useQueryVariables = () => {
  const [queryVariables, setQueryVariables] =
    useState<IssuerTransactionsQueryVariables>(DEFAULT_QUERY_VARIABLES);

  const updateQueryVariables = useCallback(
    (newVariables: Partial<IssuerTransactionsQueryVariables>) => {
      setQueryVariables((prevVariables) => ({
        ...prevVariables,
        ...newVariables,
      }));
    },
    [],
  );

  return { queryVariables, setQueryVariables: updateQueryVariables };
};
