import { ApolloError } from "@apollo/client";
import { createContext, ReactNode, useMemo } from "react";

import { IssuerInvitationFragment, useIssuerInvitationUsersQuery } from "@/gql";

const mapActiveInvitees = (invitations: IssuerInvitationFragment[]) =>
  invitations
    .map((invitation) => invitation.invitee)
    .filter((invitee) => invitee?.isActive);

export type MappedActiveInvitees = ReturnType<typeof mapActiveInvitees>;

type IssuerManageUsersTableContext = {
  activeInvitees: MappedActiveInvitees;
  error?: ApolloError;
  invitations: IssuerInvitationFragment[];
  loading: boolean;
  refetch: () => void;
};

const DEFAULT_CONTEXT: IssuerManageUsersTableContext = {
  activeInvitees: [],
  error: undefined,
  invitations: [],
  loading: false,
  refetch: () => {},
};

export const IssuerManageUsersTableContext =
  createContext<IssuerManageUsersTableContext>(DEFAULT_CONTEXT);

interface IssuerManageUsersProviderProps {
  readonly children: ReactNode;
}

export function IssuerManageUsersTableProvider({
  children,
}: IssuerManageUsersProviderProps) {
  const { data, error, loading, refetch } = useIssuerInvitationUsersQuery();

  const invitations = useMemo(() => data?.issuerInvitations, [data]) ?? [];

  const activeInvitees = mapActiveInvitees(invitations);

  const contextValue = useMemo(
    () => ({
      activeInvitees,
      error,
      invitations,
      loading,
      refetch,
    }),
    [error, invitations, loading, refetch],
  );

  return (
    <IssuerManageUsersTableContext.Provider value={contextValue}>
      {children}
    </IssuerManageUsersTableContext.Provider>
  );
}
