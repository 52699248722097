import { tableAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  Table as TableComponent,
} from "@chakra-ui/react";

const BORDER_STYLES = {
  borderStyle: `solid`,
  borderColor: `grey.200 !important`,
  borderWidth: 1,
};

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys,
);

const Table = defineMultiStyleConfig(
  defineMultiStyleConfig({
    defaultProps: {
      ...TableComponent.defaultProps,
      variant: `base`,
    },
    variants: {
      base: {
        thead: {
          tr: {
            ...BORDER_STYLES,
            borderY: `1px`,
            th: {
              borderColor: `grey.200`,
              bg: `grey.25`,
              color: `grey.800`,
              textTransform: `none`,
              fontWeight: 500,
              fontSize: 12,
              py: `20px`,
            },
          },
        },
        tbody: {
          bg: `white`,
          ...BORDER_STYLES,
          tr: {
            ...BORDER_STYLES,
            td: {
              py: `20px`,
            },
          },
        },
      },
    },
  }),
);

export default Table;
