import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  readonly token: string | null;
  readonly refreshToken: string | null;
};

const initialState: AuthState = {
  token: null,
  refreshToken: null,
};

type SetAuthSessionFromSessionPayload = {
  readonly token: string | null;
  readonly refreshToken: string;
};

const authSlice = createSlice({
  name: `auth`,
  initialState,
  reducers: {
    // eslint-disable-next-line no-return-assign
    setAuthFromSession: (
      state,
      action: PayloadAction<SetAuthSessionFromSessionPayload>,
      // eslint-disable-next-line no-param-reassign
    ) => (state = action.payload),
    reset: (_state) => initialState,
  },
});

// Exported for convenience
export const { setAuthFromSession, reset } = authSlice.actions;

export default authSlice;
