import { filter, orderBy, pipe } from "lodash/fp";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Card,
  CardHeader,
  HStack,
  Image,
  Text,
  VStack,
  CardBody,
  Stack,
} from "@chakra-ui/react";

import { HiiveButton, WithQuery } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  CompletedTransfersCardTransactionFragment,
  TransactionState,
  useCompletedTransfersCardMyActivityQuery,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  getIsBrokerForBid,
  getIsCounterpartyForBid,
  makeUrl,
  getShortDocumentTitleByDocumentType,
  getIsUnaccreditedSeller,
  appendSellerCompanyIdToUrl,
} from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";
import * as datetime from "@/utils/datetime";

const CompletedTransferItem = withCurrentActor(
  ({
    transaction,
    actor,
  }: {
    readonly transaction: CompletedTransfersCardTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const router = useRouter();
    const { t } = useTranslation();
    const isBuyer = getIsBuyerForBid(actor, transaction.bid);
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    const dealUrl = isUnaccreditedSeller
      ? appendSellerCompanyIdToUrl(makeUrl(transaction), transaction.company.id)
      : makeUrl(transaction);

    const baseDocumentUrl = `/listings/bids/${transaction.bid.id}/document/${transaction.id}`;
    const documentUrl = isUnaccreditedSeller
      ? appendSellerCompanyIdToUrl(baseDocumentUrl, transaction.company.id)
      : baseDocumentUrl;

    return (
      <Card w="full" maxW={{ base: `full`, md: `md` }}>
        <CardBody as={VStack} gap={4} alignItems="flex-start">
          <HStack
            justifyContent="flex-start"
            alignItems="center"
            w="full"
            gap={2}
          >
            {transaction.bid.company.logoUrl && (
              <Image h={5} src={transaction.bid.company.logoUrl} />
            )}
            <Text textStyle="heading-xl">{transaction.bid.company.name}</Text>
          </HStack>
          <HStack justifyContent="space-between">
            <Text textStyle="heading-xs" textTransform="uppercase">
              {t`completion_date`}
            </Text>
            <Text textStyle="text-sm">
              {datetime.format(
                `MMMM DD, YYYY`,
                isBuyer
                  ? transaction.closedFeePendingAt
                  : transaction.closedFeePaidAt,
              )}
            </Text>
          </HStack>
          <Stack
            justifyContent="flex-end"
            w="full"
            pt={2}
            direction={{ base: `column`, sm: `row`, md: `column`, lg: `row` }}
          >
            {transaction.document && (
              <HiiveButton
                size="lg"
                variant="rounded-outline-grey"
                sentryLabel="[CompletedTransfersCard] Download"
                onClick={() => router.push(documentUrl)}
              >
                {t`download`}
                {` `}
                {getShortDocumentTitleByDocumentType(transaction.document.type)}
              </HiiveButton>
            )}
            <HiiveButton
              size="lg"
              variant="rounded-solid-salmon"
              sentryLabel="[CompletedTransfersCard] View Deal"
              onClick={() => router.push(dealUrl)}
            >
              {t`view_deal`}
            </HiiveButton>
          </Stack>
        </CardBody>
      </Card>
    );
  },
);

const byCompletedTransactions =
  (actor: UserWithInstitutionFragment) =>
  ({ state, bid }: CompletedTransfersCardTransactionFragment) => {
    if (
      getIsBuyerForBid(actor, bid) ||
      getIsBrokerForBid({ user: actor, bid })
    ) {
      return (
        state === TransactionState.ClosedFeePending ||
        state === TransactionState.ClosedFeePaid
      );
    }
    if (getIsCounterpartyForBid({ user: actor, bid })) {
      return state === TransactionState.ClosedFeePaid;
    }
    return false;
  };

export const CompletedTransfersCard = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { t } = useTranslation();
    const query = useCompletedTransfersCardMyActivityQuery({
      fetchPolicy: `network-only`,
    });

    return (
      <Card w="full" flex="1">
        <CardHeader>
          <Text textStyle="heading-sm">Completed Transfers</Text>
        </CardHeader>
        <CardBody p={{ base: 4, lg: 10 }}>
          <WithQuery query={query}>
            {({ data }) => {
              const transactions: readonly CompletedTransfersCardTransactionFragment[] =
                pipe([
                  filter(byCompletedTransactions(actor)),
                  orderBy([`insertedAt`], [`desc`]),
                ])(data.myActivity.myTransactions);
              const hasCompletedTransfers = transactions.length > 0;

              if (!hasCompletedTransfers)
                return <Text>{t`no_completed_transfers`}</Text>;

              return (
                <VStack gap={4} alignItems="flex-start">
                  {transactions.map((transaction) => (
                    <CompletedTransferItem
                      key={transaction.id}
                      transaction={transaction}
                    />
                  ))}
                </VStack>
              );
            }}
          </WithQuery>
        </CardBody>
      </Card>
    );
  },
);
