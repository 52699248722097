/* eslint-disable functional/immutable-data */

/* eslint-disable no-param-reassign */
import { MutableRefObject, RefCallback } from "react";

/**
 * Allows assigning an arbitrary number of refs or callback refs to a node
 */
export const handleRefs =
  <T>(
    refs: readonly (
      | RefCallback<T>
      | MutableRefObject<T | undefined>
      | null
      | undefined
    )[],
  ) =>
  (node: T) =>
    refs.forEach((ref) => {
      if (typeof ref === `function`) {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    });
