import AnvilEmbedFrame from "@anvilco/anvil-embed-frame";
import { CheckCircle, Circle, Signature } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Box,
  Badge,
  Button,
  Card,
  CardBody,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  CardFooter,
} from "@chakra-ui/react";

import {
  ExecutionAnvilSignDocumentTaskData,
  ExecutionTaskActorType,
  TransactionExecutionPageTaskFragment,
  ExecutionTaskStatus,
  useExecutionTaskActionMutation,
  ExecutionTaskActionType,
} from "@/gql";
import { useColors, useMutationWithError } from "@/hooks";
import { toFullDate } from "@/utils/datetime";

const AnvilSignDocumentTaskCard = ({
  task,
  data,
  isBuySide,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionAnvilSignDocumentTaskData;
  readonly isBuySide: boolean;
}) => {
  const [executionTaskActionMutation, isLoading] = useMutationWithError(
    useExecutionTaskActionMutation(),
    `executionTaskAction`,
  );

  const { t } = useTranslation(`execution`);

  const [embedUrl, setEmbedUrl] = useState(``);

  const handleOpen = async () => {
    const response = await executionTaskActionMutation({
      variables: {
        id: task.id,
        type: ExecutionTaskActionType.AnvilSignDocumentGenerateEmbedUrl,
      },
    });

    if (
      !response ||
      response.executionTaskAction.result?.__typename !==
        `ExecutionTaskActionAnvilSignDocumentGenerateEmbedUrl`
    )
      return;

    setEmbedUrl(response.executionTaskAction.result.url);
  };

  const handleClose = () => setEmbedUrl(``);

  const [grey900] = useColors([`grey.900`]);

  const pendingSignatures = data.signers.find((signer) => !signer.signedAt);
  const completed = !pendingSignatures && !!task.completedAt;

  return (
    <>
      <Card variant="flat" px={4} py={3} mb={6}>
        <VStack gap={4}>
          <HStack w="full" justifyContent="space-between">
            <HStack gap={4}>
              <Center>
                <Signature width="24px" height="24px" />
              </Center>
              <VStack alignItems="start" spacing={0.5}>
                <Text textStyle="heading-md">{t(`document_signing`)}</Text>
                {completed ? (
                  <Text textStyle="text-xs" color="grey.700">
                    {t(`download`)}
                  </Text>
                ) : (
                  <Text textStyle="text-xs" color="grey.700">
                    {t(`please_complete_document`)}
                  </Text>
                )}
              </VStack>
            </HStack>
            {completed ? (
              <Text>
                {t(`completed`)}, {toFullDate(task.completedAt)}
              </Text>
            ) : (
              <Badge variant="rounded-teal" px={2} py={1}>
                <Text textStyle="text-sm" lineHeight="unset" color="teal.900">
                  {t(`pending`)}
                </Text>
              </Badge>
            )}
          </HStack>
          {pendingSignatures && (
            <Card
              variant="flat"
              w="calc(100% + 2rem)"
              bg="grey.15"
              mb={-3}
              border="none"
              borderTop="1px solid"
              borderTopRadius="none"
              borderColor="grey.75"
            >
              {data.signers.map((signer) => (
                <CardBody
                  key={signer.taskId}
                  p={0}
                  px={4}
                  py={3}
                  borderColor="grey.75"
                >
                  <HStack w="full" justifyContent="space-between">
                    <HStack gap={4}>
                      <Center width="24px" height="24px">
                        {!!signer.signedAt ? (
                          <CheckCircle
                            color={grey900}
                            weight="fill"
                            size="18px"
                          />
                        ) : (
                          <Circle color={grey900} size="18px" />
                        )}
                      </Center>
                      <Text textStyle="heading-md" color="grey.700">
                        {match(signer.actorType)
                          .with(ExecutionTaskActorType.Seller, () =>
                            isBuySide
                              ? t(`counterpartys_signature`)
                              : t(`your_signature`),
                          )
                          .with(ExecutionTaskActorType.Buyer, () =>
                            isBuySide
                              ? t(`your_signature`)
                              : t(`counterpartys_signature`),
                          )
                          .otherwise(() => ``)}
                      </Text>
                    </HStack>
                    {!!signer.signedAt && (
                      <Text textStyle="text-xs">
                        {t(`signed`)} {toFullDate(signer.signedAt)}
                      </Text>
                    )}
                  </HStack>
                </CardBody>
              ))}
            </Card>
          )}
        </VStack>
      </Card>
      {task.status === ExecutionTaskStatus.InProgress && (
        <CardFooter bg="grey.25" px={6} py={4} mx={-6} justifyContent="end">
          <Button
            isLoading={isLoading}
            variant="rounded-solid-grey"
            size="xl"
            onClick={handleOpen}
          >
            {t(`sign_document`)}
          </Button>
        </CardFooter>
      )}
      <Modal variant="texas" isOpen={!!embedUrl} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent h="full">
          <ModalHeader>{t(`document_signing`)}</ModalHeader>
          <ModalBody p={0} px={0} overflow="hidden">
            <Box as={AnvilEmbedFrame} iframeURL={embedUrl} w="full" h="full" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnvilSignDocumentTaskCard;
