import { HiiveWelcomeFlow } from "@/components/frigade";
import { FlowKind } from "@/components/providers";

export const DashboardWelcomeGuides = () => {
  const flows = [
    FlowKind.IndividualTraderNoIVNoCAWelcome,
    FlowKind.IndividualTraderNoIVYesCAWelcome,
    FlowKind.IndividualTraderYesIVNoCAWelcome,
    FlowKind.IndividualTraderYesIVYesCAWelcome,
    FlowKind.InstitutionTraderYesCAWelcome,
    FlowKind.InstitutionTraderNoCAWelcome,
    FlowKind.BrokerWelcome,
  ];

  return (
    <>
      {flows.map((flowKind) => (
        <HiiveWelcomeFlow key={flowKind} flowKind={flowKind} />
      ))}
    </>
  );
};
