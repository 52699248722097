import {
  AcceptStandingBidModalCompanyFragment,
  CreateListingSelectCompanyInputCompanyFragment,
  GetSecuritySpecialistContactCompanyFragment,
} from "@/gql";
import { constants } from "@/utils";

export const getSecuritySpecialistContact = (
  company?:
    | AcceptStandingBidModalCompanyFragment
    | GetSecuritySpecialistContactCompanyFragment
    | CreateListingSelectCompanyInputCompanyFragment
    | null,
) =>
  company?.securitySpecialist?.user.email ||
  constants.email_security_specialist;

export const getMarketAnalystContact = (
  company?:
    | GetSecuritySpecialistContactCompanyFragment
    | CreateListingSelectCompanyInputCompanyFragment
    | null,
) =>
  company?.securitySpecialist?.marketAnalyst?.email ||
  constants.email_security_specialist;

export const getCC = (
  company: GetSecuritySpecialistContactCompanyFragment,
  isUnaccreditedSeller: boolean,
): string | undefined => {
  const hasSecuritySpecialist = !!company.securitySpecialist;
  const hasMarketAnalyst = !!company.securitySpecialist?.marketAnalyst;
  const securitySpecialistEmail = getSecuritySpecialistContact(company);
  const marketAnalystEmail = getMarketAnalystContact(company);

  if (isUnaccreditedSeller && hasSecuritySpecialist) {
    return securitySpecialistEmail;
  }
  if (hasMarketAnalyst) {
    return marketAnalystEmail;
  }
  return undefined;
};
