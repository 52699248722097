import { BagSimple, Envelope } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { Card, CardBody, CardHeader, Text, VStack } from "@chakra-ui/react";

import { ActionCard } from "@/components/common";
import { useCurrentIssuerActor, useModal } from "@/hooks";
import { ROUTES } from "@/utils";

const Details = () => {
  const { t } = useTranslation();
  const { email, jobTitle } = useCurrentIssuerActor();
  const { onOpenModal, modals } = useModal();

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`contact`)}</Text>
      </CardHeader>
      <CardBody p={{ base: 6, lg: 10 }}>
        <VStack spacing={6}>
          <ActionCard
            action={{
              label: t(`update`),
              onClick: onOpenModal(
                modals.changeEmail(email, ROUTES.REQUEST_EMAIL_VERIFICATION),
              ),
            }}
            icon={<Envelope size={24} />}
            subText={email}
            text={t(`email`)}
          />
          <ActionCard
            action={{
              label: t(`edit`),
              onClick: onOpenModal(modals.changeJobTitle(jobTitle ?? ``)),
            }}
            icon={<BagSimple size={24} />}
            subText={jobTitle ?? t(`not_set`)}
            text={t(`job_title`)}
          />
        </VStack>
      </CardBody>
    </Card>
  );
};

export default Details;
