import { CaretRight, SignOut } from "@phosphor-icons/react";
import { capitalCase } from "change-case";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  GridItem,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  Box,
  Flex,
  FlexProps,
  MenuProps,
  Image,
} from "@chakra-ui/react";

import { InternalLink } from "@/components/common";
import { useCurrentIssuerActor, useSignOut } from "@/hooks";
import { ROUTES } from "@/utils";

type IssuerDesktopNavCollapsedFooterProfileIconProps = {
  name: string;
  logoUrl: string;
} & FlexProps;

type IssuerDesktopNavMenuProps = MenuProps & {
  children: ReactNode;
};

type IssuerDesktopNavFooterProps = {
  isOpen: boolean;
};

function IssuerDesktopNavMenu({
  children,
  ...restProps
}: IssuerDesktopNavMenuProps) {
  const signOut = useSignOut();
  const { t } = useTranslation();

  return (
    <Menu autoSelect={false} placement="right" {...restProps}>
      <MenuButton w="full">{children}</MenuButton>
      <MenuList zIndex={1000}>
        <MenuItem>
          <InternalLink href={ROUTES.ISSUER_ACCOUNT_DETAILS}>
            {t(`account_settings`)}
          </InternalLink>
        </MenuItem>
        <MenuItem>
          <HStack
            justifyContent="space-between"
            onClick={() => signOut()}
            w="full"
          >
            <Text>{t(`log_out`)}</Text>
            <SignOut size={24} />
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function IssuerDesktopNavCollapsedFooterProfileIcon({
  name,
  logoUrl,
  ...restProps
}: IssuerDesktopNavCollapsedFooterProfileIconProps) {
  return (
    <Flex justifyContent="center" alignItems="center" {...restProps}>
      <Image
        alt={name}
        height={9}
        src={logoUrl}
        objectFit="contain"
        width={9}
      />
    </Flex>
  );
}

function IssuerDesktopNavExpandedFooterProfile() {
  const { name, roles, issuer } = useCurrentIssuerActor();

  const formattedRoles = roles.map((role) => capitalCase(role)).join(`, `);

  return (
    <Box
      borderColor="grey.100"
      borderStyle="solid"
      borderTopWidth={0.5}
      w="full"
    >
      <HStack w="full" justifyContent="space-around" py="29px" px={6}>
        <SimpleGrid
          columns={2}
          columnGap={3}
          gridTemplateColumns="auto 1fr"
          w="full"
        >
          <GridItem alignSelf="center" rowSpan={2}>
            {issuer?.logoUrl && (
              <IssuerDesktopNavCollapsedFooterProfileIcon
                name={issuer.name}
                logoUrl={issuer.logoUrl}
              />
            )}
          </GridItem>
          <GridItem>
            <Text textStyle="heading-md">{name}</Text>
            <Text textStyle="text-xs">{formattedRoles}</Text>
          </GridItem>
        </SimpleGrid>
        <Box w={6}>
          <IssuerDesktopNavMenu>
            <CaretRight size={24} color="#818181" />
          </IssuerDesktopNavMenu>
        </Box>
      </HStack>
    </Box>
  );
}

function IssuerDesktopNavCollapsedFooterProfile() {
  const { issuer } = useCurrentIssuerActor();

  return (
    <Box
      borderColor="grey.100"
      borderStyle="solid"
      borderTopWidth={0.5}
      w="full"
    >
      {issuer?.logoUrl && (
        <IssuerDesktopNavMenu>
          <IssuerDesktopNavCollapsedFooterProfileIcon
            name={issuer.name}
            logoUrl={issuer.logoUrl}
            py={8}
          />
        </IssuerDesktopNavMenu>
      )}
    </Box>
  );
}

export function IssuerDesktopNavFooter({
  isOpen,
}: IssuerDesktopNavFooterProps) {
  return (
    <>
      <Box flexGrow={1} />
      {isOpen ? (
        <IssuerDesktopNavExpandedFooterProfile />
      ) : (
        <IssuerDesktopNavCollapsedFooterProfile />
      )}
    </>
  );
}
