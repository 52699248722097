import { useMemo } from "react";
import { Control, FieldValues, Path } from "react-hook-form";

import { Flex, FormLabelProps } from "@chakra-ui/react";

import { Skeleton, WithQuery } from "@/components/common";
import { FormCombobox } from "@/components/react-hook-form";
import { BasicCountryFragment, useCountriesQuery } from "@/gql";
import { useCountryList, useMemoizedCountries } from "@/hooks";

interface FormCountryInputProps<TFieldValues extends FieldValues> {
  readonly control: Control<TFieldValues>;
  readonly countries: readonly BasicCountryFragment[];
  readonly disabled?: boolean;
  readonly label: string;
  readonly name: Path<TFieldValues>;
  readonly placeholder: string;
  readonly showSearchIcon: boolean;
  readonly labelSrOnly?: FormLabelProps["srOnly"];
  readonly onSelectCountry?: (countryId: string) => void;
  readonly showClearIcon?: boolean;
  readonly onClear?: () => void;
}

const FormCountryComboboxContent = <TFieldValues extends FieldValues>({
  control,
  countries,
  disabled,
  label,
  name,
  placeholder,
  showSearchIcon,
  labelSrOnly,
  onSelectCountry,
  showClearIcon,
  onClear,
}: FormCountryInputProps<TFieldValues>) => {
  const countryList = useCountryList();

  const countriesMap: Record<string, string> = useMemoizedCountries({
    countries,
    countryList,
  });

  const countryIds = useMemo(() => Object.keys(countriesMap), [countriesMap]);

  const filterItems = ({ search }: { readonly search: string }) =>
    countryIds.filter((id) =>
      countriesMap[id].toLowerCase().startsWith(search.toLowerCase()),
    );

  return (
    <FormCombobox
      control={control}
      getItems={filterItems}
      getItemKey={(item) => item}
      isDisabled={disabled}
      isLoading={false}
      itemToString={(item) => countriesMap[item]}
      label={label}
      labelSrOnly={labelSrOnly}
      name={name}
      placeholder={placeholder}
      showSearchIcon={showSearchIcon}
      onSelectItem={onSelectCountry}
      showClearIcon={showClearIcon}
      onClear={onClear}
    />
  );
};

const FormCountryComboboxSkeleton = ({
  hasLabel,
}: {
  readonly hasLabel: boolean;
}) =>
  hasLabel ? (
    <Flex direction="column" gap={2}>
      <Skeleton h="24px" w="180px" />
      <Skeleton h="40px" w="full" />
    </Flex>
  ) : (
    <Skeleton h="40px" w="full" />
  );

const FormCountryCombobox = <TFieldValues extends FieldValues>({
  control,
  disabled,
  label,
  name,
  placeholder = `Select country`,
  showSearchIcon,
  labelSrOnly,
  onSelectCountry,
  showClearIcon,
  onClear,
}: {
  readonly control: Control<TFieldValues>;
  readonly disabled?: boolean;
  readonly label: string;
  readonly name: Path<TFieldValues>;
  readonly placeholder: string;
  readonly showSearchIcon: boolean;
  readonly labelSrOnly?: FormLabelProps["srOnly"];
  readonly onSelectCountry?: (countryId: string) => void;
  readonly showClearIcon?: boolean;
  readonly onClear?: () => void;
}) => {
  const query = useCountriesQuery({
    variables: {
      sorted: true,
    },
  });

  return (
    <WithQuery
      query={query}
      fallback={<FormCountryComboboxSkeleton hasLabel={!labelSrOnly} />}
    >
      {({ data: { countries } }) => (
        <FormCountryComboboxContent
          control={control}
          countries={countries}
          disabled={disabled}
          label={label}
          name={name}
          placeholder={placeholder}
          showSearchIcon={showSearchIcon}
          labelSrOnly={labelSrOnly}
          onSelectCountry={onSelectCountry}
          showClearIcon={showClearIcon}
          onClear={onClear}
        />
      )}
    </WithQuery>
  );
};

export default FormCountryCombobox;
