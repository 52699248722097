import { IssuerInvitationFragment } from "@/gql";
import { pipe } from "@/utils/pipe";

import { InviteeStatus, getInviteeStatus } from "./getInviteeStatus";

export type InvitationUserTableData = [
  IssuerInvitationFragment,
  { status: InviteeStatus },
];

function sortInvitationUsersByStatus(invitations: InvitationUserTableData[]) {
  return invitations.toSorted(
    (
      [, { status: invitationUserStatusA }],
      [, { status: invitationUserStatusB }],
    ) => invitationUserStatusA.localeCompare(invitationUserStatusB),
  );
}

function mapInvitationUsers(
  invitations: IssuerInvitationFragment[],
): InvitationUserTableData[] {
  return invitations.map((invitationUser) => [
    invitationUser,
    { status: getInviteeStatus(invitationUser) },
  ]);
}

export function getInvitationUsersTableData(
  invitations: IssuerInvitationFragment[],
) {
  return pipe(invitations)
    .step(mapInvitationUsers)
    .step(sortInvitationUsersByStatus)
    .end();
}
