import { CaretDown, CaretRight } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  Card,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  VStack,
  Text,
} from "@chakra-ui/react";

import {
  ExecutionSendFundsTaskData,
  FundsReceipt,
  TransactionExecutionPageTaskFragment,
} from "@/gql";

import AmountDueCardHeader from "./AmountDueCardHeader";
import WireInformationAlert from "./WireInformationAlert";
import WireInformationCard from "./WireInformationCard";

type SendFundsTaskCardProps = {
  data: ExecutionSendFundsTaskData;
  task: TransactionExecutionPageTaskFragment;
  wireAmount: number;
};

const SendFundsTaskCard = ({
  data,
  task,
  wireAmount,
}: SendFundsTaskCardProps) => {
  const { t } = useTranslation(`execution`);
  const payments: FundsReceipt[] = [
    {
      amount: 100000,
      id: `1`,
      insertedAt: Date.now().toString(),
    },
    {
      amount: 156000,
      id: `2`,
      insertedAt: Date.now().toString(),
    },
  ];
  const hasPayments = payments.length > 0;

  return (
    <>
      {hasPayments && (
        <Card borderColor="grey.100" mb={6} p={0} w="full" variant="flat">
          <AmountDueCardHeader amount={wireAmount} />
          <Accordion allowToggle borderColor="transparent" defaultIndex={0}>
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    {...{
                      _hover: {
                        bg: `grey.15`,
                      },
                      _active: {
                        bg: `grey.25`,
                      },
                    }}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Text textStyle="heading-md">
                        {t(`wire_instructions`)}
                      </Text>
                    </Box>
                    {isExpanded ? <CaretDown /> : <CaretRight />}
                  </AccordionButton>
                  <AccordionPanel p={4}>
                    <WireInformationCard
                      accountNumber={data.virtualAccount.accountNumber}
                      amount={wireAmount}
                      border="none"
                      bg="grey.75"
                      completedAt={task.completedAt}
                      routingNumber={data.virtualAccount.routingNumber}
                      showAmountDue={false}
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Card>
      )}
      {!hasPayments && (
        <VStack gap={4} mb={6}>
          <WireInformationCard
            accountNumber={data.virtualAccount.accountNumber}
            amount={wireAmount}
            completedAt={task.completedAt}
            routingNumber={data.virtualAccount.routingNumber}
          />
          <WireInformationAlert />
        </VStack>
      )}
    </>
  );
};

export default SendFundsTaskCard;
