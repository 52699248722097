import xor from "lodash/xor";
import { match } from "ts-pattern";

import Link from "next/link";

import { ButtonProps, Grid, GridItem, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  AlternateSignerDetails,
  NotSignerForInstitution,
} from "@/components/postings";
import {
  BidPageDocumentActionsBidFragment,
  DocumentType,
  UserRole,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCurrentActor, useDocumentSigners, useModal } from "@/hooks";
import {
  appendSellerCompanyIdToUrl,
  getIsBroker,
  getIsUnaccreditedSeller,
  hasUserRole,
} from "@/utils";

interface InviteAlternateSignerButtonProps extends ButtonProps {
  readonly bid: BidPageDocumentActionsBidFragment;
}

const InviteAlternateSignerButton = ({
  bid,
  ...buttonProps
}: InviteAlternateSignerButtonProps) => {
  const { onOpenModal, modals } = useModal();

  const actor = useCurrentActor();

  const label = getIsBroker(actor)
    ? `Send to Your Customer`
    : `Send to Alternate Signer`;

  const handleClick = () => {
    onOpenModal(modals.inviteAlternateSigner(bid))();
  };

  return (
    <HiiveButton
      whiteSpace="normal"
      size="xl"
      w="full"
      maxW="unset"
      variant="rounded-solid-salmon"
      onClick={handleClick}
      sentryLabel="[AcceptedBidDetails] Send to Alternate Signer"
      {...buttonProps}
    >
      {label}
    </HiiveButton>
  );
};

const RevokeAlternateSignerInvitationButton = ({
  bid,
}: {
  readonly bid: BidPageDocumentActionsBidFragment;
}) => {
  const { onOpenModal, modals } = useModal();

  const handleClick = () => {
    if (!bid.transaction || !bid.transaction.document) return;

    onOpenModal(
      modals.revokeAlternateSignerInvitation(bid.transaction.document),
    )();
  };

  return (
    <HiiveButton
      whiteSpace="normal"
      size="xl"
      w="full"
      maxW="unset"
      variant="rounded-solid-salmon"
      onClick={handleClick}
      sentryLabel="[AcceptedBidDetails] Cancel Signing Invitation"
    >
      Cancel Signing Invitation
    </HiiveButton>
  );
};

const ViewDocumentButton = ({
  bid,
}: {
  readonly bid: BidPageDocumentActionsBidFragment;
}) =>
  bid.transaction?.document && (
    <Link href={`/listings/bids/${bid.id}/document/${bid.transaction?.id}`}>
      <HiiveButton
        size="xl"
        w="full"
        maxW="unset"
        whiteSpace="normal"
        variant="rounded-solid-salmon"
        sentryLabel="[AcceptedBidDetails] Document HiiveButton"
      >
        {match({ type: bid.transaction.document.type })
          .with({ type: DocumentType.Loi }, (_) => `View Letter of Intent`)
          .with(
            {
              type: DocumentType.Stn,
            },
            (_) => `View Share Transfer Notice`,
          )
          .otherwise((documentInfo) => {
            throw new Error(
              `Signature status unhandled for document: ${documentInfo}`,
            );
          })}
      </HiiveButton>
    </Link>
  );

const SignDocumentButton = withCurrentActor(
  ({
    bid,
    actor,
  }: {
    readonly bid: BidPageDocumentActionsBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    if (!bid.transaction?.document) return null;

    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
    const url = `/listings/bids/${bid.id}/document/${bid.transaction?.id}`;
    return (
      <Link
        href={
          isUnaccreditedSeller
            ? appendSellerCompanyIdToUrl(url, bid.companyId)
            : url
        }
      >
        <HiiveButton
          size="xl"
          w="full"
          maxW="unset"
          whiteSpace="normal"
          variant="rounded-solid-salmon"
          sentryLabel="[AcceptedBidDetails] Document HiiveButton"
        >
          {match({ type: bid.transaction.document.type })
            .with({ type: DocumentType.Loi }, (_) => `Sign Letter of Intent`)
            .with(
              { type: DocumentType.Stn },
              (_) => `Sign Share Transfer Notice`,
            )
            .otherwise((documentInfo) => {
              throw new Error(
                `Signature status unhandled for document: ${documentInfo}`,
              );
            })}
        </HiiveButton>
      </Link>
    );
  },
);

const DocumentActions = withCurrentActor(
  ({
    bid,
    actor,
  }: {
    readonly bid: BidPageDocumentActionsBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { institutionId: myInstitutionId } = useCurrentActor();
    const amIInstitutionUser = Boolean(myInstitutionId);

    const {
      canInviteAlternateSigner,
      canSignTransactionDocument,
      canSignForInstitution,
      canRevokeAlternateSignerInvitation,
      canViewTransactionDocument,
      haveISigned,
    } = useDocumentSigners(bid.transaction);

    const isLimitedToOneAction =
      xor(
        [canSignTransactionDocument],
        [canInviteAlternateSigner || canRevokeAlternateSignerInvitation],
      ).length > 0;

    const showActions =
      canSignTransactionDocument ||
      canInviteAlternateSigner ||
      canRevokeAlternateSignerInvitation ||
      canViewTransactionDocument;

    const isSigner = hasUserRole(actor, UserRole.Signer);

    return (
      <VStack spacing={6}>
        {bid.transaction?.document &&
          amIInstitutionUser &&
          !canSignForInstitution &&
          !haveISigned &&
          !isSigner && <NotSignerForInstitution bid={bid} />}
        <AlternateSignerDetails bid={bid} />
        {showActions && (
          <Grid
            w="full"
            justifyContent="flex-end"
            gap={4}
            gridTemplateColumns={{
              base: `1fr`,
              sm: isLimitedToOneAction ? `min(300px, 100%)` : `1fr 1fr`,
            }}
          >
            {canViewTransactionDocument && (
              <GridItem order={{ base: `2`, sm: `1` }}>
                <ViewDocumentButton bid={bid} />
              </GridItem>
            )}
            {canSignTransactionDocument && (
              <GridItem order={{ base: `2`, sm: `1` }}>
                <SignDocumentButton bid={bid} />
              </GridItem>
            )}
            {canInviteAlternateSigner && (
              <GridItem order={{ base: `2`, sm: `1` }}>
                <InviteAlternateSignerButton bid={bid} />
              </GridItem>
            )}
            {canRevokeAlternateSignerInvitation && (
              <GridItem order={{ base: `2`, sm: `1` }}>
                <RevokeAlternateSignerInvitationButton bid={bid} />
              </GridItem>
            )}
          </Grid>
        )}
      </VStack>
    );
  },
);

export default DocumentActions;
