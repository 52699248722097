import { ComponentType } from "react";

import { useRouter } from "next/router";

import { CurrentActorContext } from "@/components/providers";
import { InvestorType, useCurrentContextQuery } from "@/gql";
import { canAccessPlatform } from "@/hooks";
import {
  FeatureFlags,
  useFeatureFlags,
  useIssuerPortalRetoolParity,
} from "@/hooks/featureFlags";
import { useToken } from "@/hooks/useToken";
import { ROUTES, getIsValidRedirectURL } from "@/utils";

const getAuthenticatedRedirectRoute = (
  user: CurrentActorContext,
  _featureFlags: FeatureFlags,
  redirectURL?: string,
) => {
  if (!canAccessPlatform(user)) {
    return ROUTES.WELCOME;
  }

  if (!!redirectURL && getIsValidRedirectURL(redirectURL)) {
    return redirectURL;
  }

  return ROUTES.DASHBOARD;
};

export function withGuestRoute<T>(WrappedComponent: ComponentType<T>) {
  const GuestComponent = (props: T) => {
    const token = useToken();
    const retoolParityEnabled = useIssuerPortalRetoolParity();
    const featureFlags = useFeatureFlags();
    const router = useRouter();
    const { data, loading } = useCurrentContextQuery({ skip: !token });

    const currentActor = data?.currentContext?.currentActor;
    const isAuthenticated = token || (!loading && !!currentActor);
    const isIssuer = currentActor?.investorType === InvestorType.Issuer;

    if (isAuthenticated && currentActor) {
      const route = getAuthenticatedRedirectRoute(
        currentActor,
        featureFlags,
        router.query.redirect as string,
      );

      if (!retoolParityEnabled && !isIssuer) {
        router.push(route);
      } else {
        setTimeout(() => router.push(ROUTES.INDEX), 2500);
      }

      return null;
    }

    if (isAuthenticated && !currentActor) {
      return null;
    }

    return <WrappedComponent {...props} key="wrappedGuestRoute" />;
  };

  return GuestComponent;
}
