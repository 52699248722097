import { MagnifyingGlass } from "@phosphor-icons/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Input, InputGroup, InputLeftElement, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import { SkeletonTableCell } from "@/components/issuers";
import {
  Table,
  TableHeader,
  TableHeaders,
  TableRow,
  TableRows,
} from "@/components/table";
import { IssuerStage } from "@/gql";

import { IssuerTransactionsTableContext } from "./IssuerTransactionsProvider";
import { StageDateTableHeader } from "./IssuerTransactionsTableCells";
import { IssuerTransactionsTablePaginatorSkeleton } from "./IssuerTransactionsTablePaginatorSkeleton";

export const TABLE_SKELETON_ROW_LENGTH = 5;

export const IssuerTransactionsTableSkeleton = () => {
  const { t } = useTranslation(`issuers`);
  const { filterBy } = useContext(IssuerTransactionsTableContext);

  const { stage } = filterBy ?? {};

  return (
    <VStack spacing={8} w="full" alignItems="flex-start">
      {stage && <Skeleton h={25} w={500} />}
      <InputGroup maxW={{ base: `full`, lg: `45%` }}>
        <InputLeftElement pointerEvents="none">
          <MagnifyingGlass size={20} color="var(--chakra-colors-grey-200)" />
        </InputLeftElement>
        <Input
          placeholder={t(`transactions_table_search_placeholder`)}
          type="text"
        />
      </InputGroup>
      <Table>
        <TableHeaders>
          <TableHeader>{t(`id`)}</TableHeader>
          {stage !== IssuerStage.InReview && (
            <TableHeader>{t(`rofr`)}</TableHeader>
          )}
          <TableHeader>{t(`seller`)}</TableHeader>
          <TableHeader>{t(`buyer`)}</TableHeader>
          <TableHeader>{t(`holding_type`)}</TableHeader>
          <StageDateTableHeader stage={stage} />
          {!stage && <TableHeader>{t(`stage`)}</TableHeader>}
          <TableHeader>{t(`no_of_shares`)}</TableHeader>
          <TableHeader>{t(`pps`)}</TableHeader>
        </TableHeaders>
        <TableRows>
          {Array.from({ length: TABLE_SKELETON_ROW_LENGTH }).map((_, index) => (
            <TableRow key={`${new Date().toISOString()}-${index.toString()}`}>
              <SkeletonTableCell />
              {stage !== IssuerStage.InReview && <SkeletonTableCell />}
              <SkeletonTableCell />
              <SkeletonTableCell />
              <SkeletonTableCell />
              <SkeletonTableCell />
              {!stage && <SkeletonTableCell />}
              <SkeletonTableCell />
              <SkeletonTableCell />
            </TableRow>
          ))}
        </TableRows>
      </Table>
      <IssuerTransactionsTablePaginatorSkeleton />
    </VStack>
  );
};
