import { createContext, ReactNode, useMemo } from "react";

import { useCurrentContextQuery, UserWithInstitutionFragment } from "@/gql";

export type CurrentActorContext = UserWithInstitutionFragment;

export const CurrentActorContext = createContext<
  CurrentActorContext | undefined
>(undefined);

export const CurrentActorProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const { data, loading } = useCurrentContextQuery();
  const { currentActor } = data?.currentContext || {};
  const contextValue = useMemo(() => currentActor, [currentActor, loading]);

  if (contextValue === undefined) return null;

  return (
    <CurrentActorContext.Provider value={contextValue}>
      {children}
    </CurrentActorContext.Provider>
  );
};
