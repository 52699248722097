import { WarningCircle } from "@phosphor-icons/react";
import assign from "lodash/assign";
import { ReactNode, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Box, Flex, Text } from "@chakra-ui/react";

import { InternalLink } from "@/components/common";
import { withCurrentActor, WithCurrentActorProps } from "@/components/hoc";
import { InvestorStatus, UserRole, UserWithInstitutionFragment } from "@/gql";
import { useColors } from "@/hooks";
import { getAvailableUserPermissions, getIsInstitutionUser } from "@/utils";

interface ComplianceReminderMessageProps {
  readonly canSell: boolean;
  readonly canBuy: boolean;
}

interface ComplianceReminderInstitutionalMessageProps {
  readonly canSell: boolean;
  readonly canBuy: boolean;
  readonly company: string;
  readonly isAdmin: boolean;
}

const ComplianceReminderMessageComponent = withCurrentActor<
  {
    readonly IndividualMessageComponent: (
      props: ComplianceReminderMessageProps,
    ) => JSX.Element | null;
    readonly InstitutionMessageComponent: (
      props: ComplianceReminderInstitutionalMessageProps,
    ) => JSX.Element | null;
    readonly InstitutionMessageSuitabilityOverrideComponent?: (
      props: ComplianceReminderInstitutionalMessageProps,
    ) => JSX.Element | null;
  } & WithCurrentActorProps
>(
  ({
    IndividualMessageComponent,
    InstitutionMessageComponent,
    InstitutionMessageSuitabilityOverrideComponent = null,
    actor,
  }) => {
    const {
      institution,
      investorStatus,
      accreditationAnswers,
      suitabilityAnswers,
    } = actor;

    const {
      canSignUnsignedCustomerAgreement,
      canCreateListing: canSell,
      canPlaceStandingBid: canBuy,
    } = getAvailableUserPermissions(actor);

    const overrideWithSuitabilityCTA =
      investorStatus === InvestorStatus.Institutional &&
      institution?.membershipAgreementSigned &&
      accreditationAnswers.length > 0 &&
      suitabilityAnswers.length < 1;

    const { t } = useTranslation();

    return match({
      canSignUnsignedCustomerAgreement,
      investorStatus,
      overrideWithSuitabilityCTA,
    })
      .with(
        {
          investorStatus: InvestorStatus.Individual,
        },
        () => <IndividualMessageComponent canSell={canSell} canBuy={canBuy} />,
      )
      .with(
        {
          canSignUnsignedCustomerAgreement: true,
          investorStatus: InvestorStatus.Institutional,
          overrideWithSuitabilityCTA: false,
        },
        // In this case we want the institutional user to see this message in the first person
        // since it's referring to their signing capabilities
        () => <IndividualMessageComponent canSell={canSell} canBuy={canBuy} />,
      )
      .with(
        {
          canSignUnsignedCustomerAgreement: true,
          investorStatus: InvestorStatus.Institutional,
          overrideWithSuitabilityCTA: true,
        },
        () =>
          InstitutionMessageSuitabilityOverrideComponent && (
            <InstitutionMessageSuitabilityOverrideComponent
              company={institution?.legalName ?? t(`your_institution`)}
              isAdmin={actor.roles.includes(UserRole.Admin)}
              canSell={false}
              canBuy={false}
            />
          ),
      )
      .with(
        {
          canSignUnsignedCustomerAgreement: false,
          investorStatus: InvestorStatus.Institutional,
          overrideWithSuitabilityCTA: false,
        },
        () => (
          <InstitutionMessageComponent
            canSell={canSell}
            canBuy={canBuy}
            company={institution?.legalName ?? t(`your_institution`)}
            isAdmin={actor.roles.includes(UserRole.Admin)}
          />
        ),
      )
      .with(
        {
          canSignUnsignedCustomerAgreement: false,
          investorStatus: InvestorStatus.Institutional,
          overrideWithSuitabilityCTA: true,
        },
        () =>
          InstitutionMessageSuitabilityOverrideComponent && (
            <InstitutionMessageSuitabilityOverrideComponent
              company={institution?.legalName ?? t(`your_institution`)}
              isAdmin={actor.roles.includes(UserRole.Admin)}
              canSell={false}
              canBuy={false}
            />
          ),
      )
      .otherwise(() => null);
  },
);

const ComplianceReminderMain = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const [red600] = useColors([`red.600`]);

  return (
    <Flex gap="2" data-testid="compliance-reminder">
      <Box flexShrink={0}>
        <WarningCircle color={red600} size={20} weight="fill" />
      </Box>
      <Text textStyle="text-sm">{children}</Text>
    </Flex>
  );
};

const ComplianceReminderComponent = {
  Link: withCurrentActor(
    ({
      children,
      actor,
    }: {
      readonly children?: ReactNode;
      readonly actor: UserWithInstitutionFragment;
    }) => {
      const {
        institution,
        suitabilityAnswers,
        agreedToCustomerAgreement,
        membershipAgreementSigned,
        identityVerified,
        accreditationAnswers,
      } = actor;
      const isInstitutionUser = getIsInstitutionUser(actor);

      const canVerifyIdentity = !isInstitutionUser;

      const agreedToCA =
        agreedToCustomerAgreement ||
        membershipAgreementSigned ||
        institution?.membershipAgreementSigned;

      const href = useMemo(() => {
        if (!identityVerified && canVerifyIdentity)
          return `/account/profile/identity-verification`;
        if (!agreedToCA) return `/account/profile/customer-agreement`;
        if (accreditationAnswers.length < 1)
          return `/account/profile/accreditation`;
        if (suitabilityAnswers.length < 1)
          return `/account/profile/suitability`;

        return `/account`;
      }, [
        identityVerified,
        agreedToCA,
        accreditationAnswers,
        suitabilityAnswers,
        canVerifyIdentity,
      ]);

      return (
        <InternalLink color="salmon.900" fontWeight="medium" href={href}>
          {children}
        </InternalLink>
      );
    },
  ),
  BuySellMessage: () => (
    <ComplianceReminderMessageComponent
      IndividualMessageComponent={
        ComplianceReminderComponent.Individual.BuySellMessage
      }
      InstitutionMessageComponent={
        ComplianceReminderComponent.Institutional.BuySellMessage
      }
    />
  ),
  BuyMessage: () => (
    <ComplianceReminderMessageComponent
      IndividualMessageComponent={
        ComplianceReminderComponent.Individual.BuyMessage
      }
      InstitutionMessageComponent={
        ComplianceReminderComponent.Institutional.BuyMessage
      }
      InstitutionMessageSuitabilityOverrideComponent={
        ComplianceReminderComponent.Institutional.BuyMessageSuitability
      }
    />
  ),
  SellMessage: () => (
    <ComplianceReminderMessageComponent
      IndividualMessageComponent={
        ComplianceReminderComponent.Individual.SellMessage
      }
      InstitutionMessageComponent={
        ComplianceReminderComponent.Institutional.SellMessage
      }
    />
  ),
  Message: () => (
    <ComplianceReminderMessageComponent
      IndividualMessageComponent={
        ComplianceReminderComponent.Individual.Message
      }
      InstitutionMessageComponent={
        ComplianceReminderComponent.Institutional.Message
      }
      InstitutionMessageSuitabilityOverrideComponent={
        ComplianceReminderComponent.Institutional.BuyMessageSuitability
      }
    />
  ),
  Individual: {
    BuySellMessage: () => (
      <Trans
        i18nKey="complete_your_profile_buy_sell"
        components={{ italic: <ComplianceReminderComponent.Link /> }}
      />
    ),
    BuyMessage: () => (
      <Trans
        i18nKey="complete_your_profile_buy"
        components={{ italic: <ComplianceReminderComponent.Link /> }}
      />
    ),
    SellMessage: () => (
      <Trans
        i18nKey="complete_your_profile_sell"
        components={{ italic: <ComplianceReminderComponent.Link /> }}
      />
    ),
    Message: ({ canSell, canBuy }: ComplianceReminderMessageProps) =>
      match<ComplianceReminderMessageProps>({
        canSell,
        canBuy,
      })
        .with({ canBuy: false, canSell: false }, () => (
          <ComplianceReminderComponent.Individual.BuySellMessage />
        ))
        .with({ canBuy: true, canSell: false }, () => (
          <ComplianceReminderComponent.Individual.SellMessage />
        ))
        .with({ canBuy: false, canSell: true }, () => (
          <ComplianceReminderComponent.Individual.BuyMessage />
        ))
        .otherwise(() => null),
  },
  Institutional: {
    BuySellMessage: ({
      company,
      isAdmin,
    }: {
      readonly company: string;
      readonly isAdmin: boolean;
    }) =>
      isAdmin ? (
        <Trans
          i18nKey="inst_complete_your_profile_buy_sell"
          components={{ italic: <ComplianceReminderComponent.Link /> }}
          values={{ company }}
        />
      ) : (
        <Trans
          i18nKey="inst_admin_complete_your_profile_buy_sell"
          components={{ italic: <ComplianceReminderComponent.Link /> }}
          values={{ company }}
        />
      ),
    BuyMessage: ({
      company,
      isAdmin,
    }: {
      readonly company: string;
      readonly isAdmin: boolean;
    }) =>
      isAdmin ? (
        <Trans
          i18nKey="inst_complete_your_profile_buy"
          components={{ italic: <ComplianceReminderComponent.Link /> }}
          values={{ company }}
        />
      ) : (
        <Trans
          i18nKey="inst_admin_complete_your_profile_buy"
          components={{ italic: <ComplianceReminderComponent.Link /> }}
          values={{ company }}
        />
      ),
    BuyMessageSuitability: ({
      company,
      isAdmin,
    }: {
      readonly company: string;
      readonly isAdmin: boolean;
    }) =>
      isAdmin ? (
        <Trans
          i18nKey="complete_suitability_buy"
          components={{ italic: <ComplianceReminderComponent.Link /> }}
        />
      ) : (
        <Trans
          i18nKey="inst_admin_complete_suitability_buy"
          components={{ italic: <ComplianceReminderComponent.Link /> }}
          values={{ company }}
        />
      ),
    SellMessage: ({
      company,
      isAdmin,
    }: {
      readonly company: string;
      readonly isAdmin: boolean;
    }) =>
      isAdmin ? (
        <Trans
          i18nKey="inst_complete_your_profile_sell"
          components={{ italic: <ComplianceReminderComponent.Link /> }}
          values={{ company }}
        />
      ) : (
        <Trans
          i18nKey="inst_admin_complete_your_profile_sell"
          components={{ italic: <ComplianceReminderComponent.Link /> }}
          values={{ company }}
        />
      ),
    Message: ({
      canSell,
      canBuy,
      company,
      isAdmin,
    }: ComplianceReminderInstitutionalMessageProps) =>
      match<ComplianceReminderMessageProps>({
        canSell,
        canBuy,
      })
        .with({ canBuy: false, canSell: false }, () => (
          <ComplianceReminderComponent.Institutional.BuySellMessage
            company={company}
            isAdmin={isAdmin}
          />
        ))
        .with({ canBuy: true, canSell: false }, () => (
          <ComplianceReminderComponent.Institutional.BuyMessage
            company={company}
            isAdmin={isAdmin}
          />
        ))
        .with({ canBuy: false, canSell: true }, () => (
          <ComplianceReminderComponent.Institutional.SellMessage
            company={company}
            isAdmin={isAdmin}
          />
        ))
        .otherwise(() => null),
  },
};

const ComplianceReminder = assign(
  ComplianceReminderMain,
  ComplianceReminderComponent,
);

export const { Link } = ComplianceReminderComponent;
export default ComplianceReminder;
