import { useTranslation } from "react-i18next";

import { HStack, Radio, Text } from "@chakra-ui/react";

import { CustomTooltip, FormRadioInput } from "@/components/form";
import { useOtherTransferMethod } from "@/hooks/featureFlags";

import { transferTypeChoices } from "./types";

const TransferTypeChoiceInput = () => {
  const { t } = useTranslation();
  const noSelection = useOtherTransferMethod()
    ? transferTypeChoices.Other
    : transferTypeChoices.No;

  return (
    <FormRadioInput
      label={t(`are_you_able_to_transfer`)}
      name="transferTypeChoice"
    >
      <HStack spacing={6} pt={5} data-testid="transfer-type-choice">
        <Radio variant="base" value={transferTypeChoices.Yes}>
          <CustomTooltip tooltipContent={t(`transfer_type_choice_direct`)}>
            <Text variant="tooltip">Yes</Text>
          </CustomTooltip>
        </Radio>
        <Radio variant="base" value={noSelection}>
          <CustomTooltip tooltipContent={t(`transfer_type_choice_indirect`)}>
            <Text variant="tooltip">No</Text>
          </CustomTooltip>
        </Radio>
        <Radio variant="base" value={transferTypeChoices.DontKnow}>
          <CustomTooltip tooltipContent={t(`transfer_type_choice_unknown`)}>
            <Text variant="tooltip">I don&apos;t know</Text>
          </CustomTooltip>
        </Radio>
      </HStack>
    </FormRadioInput>
  );
};

export default TransferTypeChoiceInput;
