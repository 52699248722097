import { match } from "ts-pattern";

import { CardBody, CardFooter, HStack, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import {
  AccreditationQuestion,
  InvestorStatus,
  useCurrentAccreditationQuestionGroupQuery,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { SupportedInstitutionCountryCodes as SupportedCountryCodes } from "@/utils/accreditation";

import { AccreditationFormProps } from "./AccreditationForm";
import { CAInstitutionAccreditationForm } from "./CAInstitutionAccreditationForm";
import { USInstitutionAccreditationForm } from "./USInstitutionAccreditationForm";

const USInstitutionAccreditationFormSkeleton = () => (
  <VStack w={{ base: `full`, lg: 160 }} gap={2}>
    <Skeleton h={450} />
    <Skeleton h={50} />
  </VStack>
);

const CADInstitutionAccreditationFormSkeleton = () => (
  <VStack w={{ base: `full`, lg: 160 }} gap={2}>
    <Skeleton h={55} w="90%" />
    <Skeleton h={100} />
    <Skeleton h={75} />
    <Skeleton h={110} />
    <Skeleton h={75} />
    <Skeleton h={75} />
    <Skeleton h={75} />
  </VStack>
);

export const InstitutionAccreditationForm = ({
  formId,
  onSuccess,
  accreditationLastUpdatedAt,
}: Omit<AccreditationFormProps, `accreditationQuestions`>) => {
  const actor = useCurrentActor();
  const institutionCountry = actor.institution?.country;
  const isUnsupportedCountry = !SupportedCountryCodes.includes(
    institutionCountry?.name || ``,
  );
  const { data, loading } = useCurrentAccreditationQuestionGroupQuery({
    variables: {
      investorType: InvestorStatus.Institutional,
      countryId: institutionCountry?.id || ``,
    },
    skip: isUnsupportedCountry,
  });

  const accreditationQuestions =
    data?.currentAccreditationQuestionGroup?.questions.filter(
      (question: AccreditationQuestion) => question.accredited,
    ) || [];

  if (loading)
    return (
      <>
        <CardBody>
          {match(institutionCountry?.name)
            .with(`US`, () => <USInstitutionAccreditationFormSkeleton />)
            .with(`CA`, () => <CADInstitutionAccreditationFormSkeleton />)
            .otherwise(() => null)}
        </CardBody>
        <CardFooter>
          <HStack justifyContent="flex-end" w="full">
            <Skeleton h={50} w={250} />
          </HStack>
        </CardFooter>
      </>
    );

  return match(institutionCountry?.name)
    .with(`US`, () => (
      <USInstitutionAccreditationForm
        formId={formId}
        onSuccess={onSuccess}
        accreditationLastUpdatedAt={accreditationLastUpdatedAt}
        accreditationQuestions={accreditationQuestions}
      />
    ))
    .with(`CA`, () => (
      <CAInstitutionAccreditationForm
        formId={formId}
        onSuccess={onSuccess}
        accreditationLastUpdatedAt={accreditationLastUpdatedAt}
        accreditationQuestions={accreditationQuestions}
      />
    ))
    .otherwise(() => null);
};
