import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import {
  IssuerInvitationInviteeFragment,
  IssuerInvitationUsersDocument,
  UserRole,
  useChangeIssuerUserRolesMutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";

type IssuersAddAdminRoleModalProps = {
  invitee: IssuerInvitationInviteeFragment;
};

export function IssuersAddAdminRoleModal({
  invitee,
}: IssuersAddAdminRoleModalProps) {
  const { t } = useTranslation(`issuers`);
  const { closeModal } = useModal();
  const { successToast } = useCustomToast();

  const { id: userId, name } = invitee;

  const [changeIssuerUserRoles, { loading }] = useChangeIssuerUserRolesMutation(
    {
      refetchQueries: [IssuerInvitationUsersDocument],
    },
  );

  const onSubmit = useCallback(async () => {
    const { data } = await changeIssuerUserRoles({
      variables: { userId, roles: [UserRole.Admin] },
    });

    if (data?.changeIssuerUserRoles) {
      closeModal();
      successToast(t(`add_admin_role_success`));
    }
  }, [userId]);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`make_admin`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          <Text>{t(`make_admin_description`, { name })}</Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            sentryLabel="[IssuersAddAdminRole/Submit]"
            submitText={t(`confirm`)}
            onClick={onSubmit}
            isLoading={loading}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
}
