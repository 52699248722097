import * as Apollo from "@apollo/client";
import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";
import {
  AccreditationAnswer,
  AccreditationQuestion,
  AnswerInstitutionAccreditationQuestionsMutation,
  AnswerInstitutionAccreditationQuestionsMutationVariables,
  InvestorStatus,
  Maybe,
} from "@/gql";
import { useCurrentActor } from "@/hooks";

import { InstitutionAccreditationForm } from "./InstitutionAccreditationForm";
import { InvestorAccreditationForm } from "./InvestorAccreditationForm";

export type UseAccreditationFormProps = {
  readonly accreditationQuestions: Maybe<AccreditationQuestion>[];
  readonly accreditationAnswers?: (Omit<
    AccreditationAnswer,
    `userId` | `question`
  > &
    Pick<AccreditationAnswer, `text`> & {
      readonly question: Pick<
        AccreditationQuestion,
        "id" | "text" | "key" | "order"
      >;
    })[];
  readonly onSuccess: () => void;
  readonly mutationOptions?: Apollo.MutationHookOptions<
    AnswerInstitutionAccreditationQuestionsMutation,
    AnswerInstitutionAccreditationQuestionsMutationVariables
  >;
};

const InvestorAccreditationPageV2 = () => {
  const { t } = useTranslation();
  const actor = useCurrentActor();
  const isIndividualUser = actor.investorStatus === InvestorStatus.Individual;

  return (
    <OnboardingContainerV2
      canGoBack={isIndividualUser}
      metaTitle="Investor Accreditation"
    >
      <Flex direction="column">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(
              isIndividualUser
                ? `investor_accreditation_title`
                : `institution_accreditation_title`,
            )}
            {` `}*
          </Text>
          {isIndividualUser && (
            <VStack gap={1} alignItems="flex-start">
              <Text>{t`investor_accreditation_description`}</Text>
              <Text>{t`investor_accreditation_description_two`}</Text>
            </VStack>
          )}
        </VStack>

        {isIndividualUser ? (
          <InvestorAccreditationForm />
        ) : (
          <InstitutionAccreditationForm />
        )}
      </Flex>
    </OnboardingContainerV2>
  );
};

export default InvestorAccreditationPageV2;
