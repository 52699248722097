/* eslint-disable arrow-body-style */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { FeedItem } from "@knocklabs/client";
import { createContext, ReactNode, useEffect, useState } from "react";

import { withCurrentActor } from "@/components/hoc";
import {
  PostActivityUnaccreditedSellerDashboardPageActiveBidsPageDocument,
  PostActivityUnaccreditedSellerDashboardPageInquiriesPageDocument,
  PostActivityUnaccreditedSellerDashboardPageStandingBidsPageDocument,
  UnaccreditedSellerActiveBidNotificationsCountDocument,
  UnaccreditedSellerInquiryNotificationsCountDocument,
  UnaccreditedSellerPastBidNotificationsCountDocument,
  UnaccreditedSellerStandingBidNotificationsCountDocument,
  UnaccreditedSellerTransactionNotificationsCountDocument,
  UnaccreditedSellerByCompanyIdActiveBidNotificationsCountDocument,
  UnaccreditedSellerByCompanyIdInquiryNotificationsCountDocument,
  UnaccreditedSellerByCompanyIdPastBidNotificationsCountDocument,
  UnaccreditedSellerByCompanyIdStandingBidNotificationsCountDocument,
  UnaccreditedSellerByCompanyIdTransactionNotificationsCountDocument,
  PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageDocument,
  UserWithInstitutionFragment,
} from "@/gql";
import { useKnockClient, useNotificationFeed } from "@/hooks";
import { useApolloClient } from "@/hooks/useApolloClient";
import { fetchAllUnreadNotificationsMap } from "@/services/knock";

const initialContextValue = {
  getIsNewBid: () => false,
  getInquiryNumNewMessages: () => 0,
  getIsNewStandingBid: () => false,
  getIsNewInquiry: () => false,
  getIsNewAcceptedBid: () => false,
  newBidIds: [],
  newStandingBidIds: [],
  newInquiryIds: [],
  newMessagesCountByInquiryId: {},
  newAcceptedBidIds: [],
};

export const UnaccreditedSellerInlineNotificationsContext = createContext<{
  readonly getIsNewBid: ({ id }: { readonly id: string }) => boolean;
  readonly getIsNewStandingBid: ({ id }: { readonly id: string }) => boolean;
  readonly getIsNewInquiry: ({ id }: { readonly id: string }) => boolean;
  readonly getInquiryNumNewMessages: ({
    id,
  }: {
    readonly id: string;
  }) => number;
  readonly getIsNewAcceptedBid: ({ id }: { readonly id: string }) => boolean;
  readonly newBidIds: readonly string[];
  readonly newStandingBidIds: readonly string[];
  readonly newInquiryIds: readonly string[];
  readonly newMessagesCountByInquiryId: Record<string, number>;
  readonly newAcceptedBidIds: readonly string[];
}>(initialContextValue);

enum NotificationKeys {
  NewBid = `bid-to-lister`,
  NewDiscussionRequest = `discussion-request-to-recipient`,
  NewMessage = `discussion-message-to-recipient`,
  NewStandingBid = `new-standing-bid-to-holders`,
  NewAcceptedBid = `acceptance-confirmation-to-lister`,
  NewAcceptedCounteredBid = `countered-bid-acceptance-to-lister`,
}

const keys = [
  NotificationKeys.NewBid,
  NotificationKeys.NewDiscussionRequest,
  NotificationKeys.NewMessage,
  NotificationKeys.NewStandingBid,
  NotificationKeys.NewAcceptedBid,
  NotificationKeys.NewAcceptedCounteredBid,
];

interface BidToListerNotificationData {
  readonly bid_id?: string;
}

interface DiscussionRequestToRecipientNotificationData {
  readonly discussion_id?: string;
}

interface DiscussionMessageToRecipientNotificationData {
  readonly discussion_id?: string;
  readonly discussion_topic_id?: string;
}
interface NewStandingBidToHoldersNotificationData {
  readonly standing_bid_id?: string;
}

interface AcceptanceConfirmationToListerNotificationData {
  readonly bid_id: string;
}

interface CounteredBidAcceptanceToListerNotificationData {
  readonly bid_id: string;
}

interface UnreadNotifications {
  readonly [NotificationKeys.NewBid]: readonly FeedItem<BidToListerNotificationData>[];
  readonly [NotificationKeys.NewDiscussionRequest]: readonly FeedItem<DiscussionRequestToRecipientNotificationData>[];
  readonly [NotificationKeys.NewMessage]: readonly FeedItem<DiscussionMessageToRecipientNotificationData>[];
  readonly [NotificationKeys.NewStandingBid]: readonly FeedItem<NewStandingBidToHoldersNotificationData>[];
  readonly [NotificationKeys.NewAcceptedBid]: readonly FeedItem<AcceptanceConfirmationToListerNotificationData>[];
  readonly [NotificationKeys.NewAcceptedCounteredBid]: readonly FeedItem<CounteredBidAcceptanceToListerNotificationData>[];
}

const UnaccreditedSellerInlineNotificationsProvider = withCurrentActor(
  ({
    children,
    actor,
    userNotificationToken,
  }: {
    readonly children: ReactNode;
    readonly actor: UserWithInstitutionFragment;
    readonly userNotificationToken: string;
  }) => {
    const knockClient = useKnockClient({ actor, userNotificationToken });
    const notificationFeed = useNotificationFeed({ knockClient });

    const [unreadNotifications, setUnreadNotifications] =
      useState<UnreadNotifications>(
        keys.reduce(
          (acc, key) => ({ ...acc, [key]: [] }),
          {} as UnreadNotifications,
        ),
      );

    const fetch = async () => {
      const unreadNotifications = await fetchAllUnreadNotificationsMap({
        notificationFeed,
      });

      if (Object.values(unreadNotifications).length === 0) return;

      setUnreadNotifications(
        keys.reduce(
          (acc, key) => ({
            ...acc,
            [key]: unreadNotifications[key] || [],
          }),
          {} as UnreadNotifications,
        ),
      );
    };

    const client = useApolloClient();

    const handleItemsReceived = () => {
      client.refetchQueries({
        include: [
          PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageDocument,
          PostActivityUnaccreditedSellerDashboardPageActiveBidsPageDocument,
          PostActivityUnaccreditedSellerDashboardPageInquiriesPageDocument,
          PostActivityUnaccreditedSellerDashboardPageStandingBidsPageDocument,
          UnaccreditedSellerInquiryNotificationsCountDocument,
          UnaccreditedSellerStandingBidNotificationsCountDocument,
          UnaccreditedSellerActiveBidNotificationsCountDocument,
          UnaccreditedSellerTransactionNotificationsCountDocument,
          UnaccreditedSellerPastBidNotificationsCountDocument,
          UnaccreditedSellerByCompanyIdActiveBidNotificationsCountDocument,
          UnaccreditedSellerByCompanyIdInquiryNotificationsCountDocument,
          UnaccreditedSellerByCompanyIdPastBidNotificationsCountDocument,
          UnaccreditedSellerByCompanyIdStandingBidNotificationsCountDocument,
          UnaccreditedSellerByCompanyIdTransactionNotificationsCountDocument,
        ],
      });

      fetch();
    };

    useEffect(() => {
      notificationFeed.on(`items.received.realtime`, handleItemsReceived);
      fetch();

      return () => {
        notificationFeed.off(`items.received.realtime`, handleItemsReceived);
      };
    }, []);

    const newBidIds = unreadNotifications[NotificationKeys.NewBid].flatMap(
      ({ data }) => (data && data.bid_id ? [data.bid_id] : []),
    );

    const newInquiryIds = unreadNotifications[
      NotificationKeys.NewDiscussionRequest
    ].flatMap(({ data }) =>
      data && data.discussion_id ? [data.discussion_id] : [],
    );

    const newMessagesCountByInquiryId = unreadNotifications[
      NotificationKeys.NewMessage
    ].reduce(
      (acc, { data }) => {
        if (!data || !data.discussion_id) return acc;

        const { discussion_id } = data;

        return {
          ...acc,
          [discussion_id]: (acc[discussion_id] || 0) + 1,
        };
      },
      {} as Record<string, number>,
    );

    const getIsNewBid = ({ id }: { readonly id: string }) =>
      newBidIds.includes(id);

    const newStandingBidIds = unreadNotifications[
      NotificationKeys.NewStandingBid
    ].flatMap(({ data }) =>
      data && data.standing_bid_id ? [data.standing_bid_id] : [],
    );

    const getIsNewStandingBid = ({ id }: { readonly id: string }) =>
      newStandingBidIds.includes(id);

    const getIsNewInquiry = ({ id }: { readonly id: string }) =>
      newInquiryIds.includes(id);

    const getInquiryNumNewMessages = ({ id }: { readonly id: string }) =>
      newMessagesCountByInquiryId[id] || 0;

    const newAcceptedBidIds = [
      ...unreadNotifications[NotificationKeys.NewAcceptedBid],
      ...unreadNotifications[NotificationKeys.NewAcceptedCounteredBid],
    ].flatMap(({ data }) => (data && data.bid_id ? [data.bid_id] : []));

    const getIsNewAcceptedBid = ({ id }: { readonly id: string }) =>
      newAcceptedBidIds.includes(id);

    const contextValue = {
      getIsNewBid,
      getIsNewStandingBid,
      getIsNewInquiry,
      getInquiryNumNewMessages,
      getIsNewAcceptedBid,
      newBidIds,
      newStandingBidIds,
      newInquiryIds,
      newMessagesCountByInquiryId,
      newAcceptedBidIds,
    };

    return (
      <UnaccreditedSellerInlineNotificationsContext.Provider
        value={contextValue}
      >
        {children}
      </UnaccreditedSellerInlineNotificationsContext.Provider>
    );
  },
);

export default UnaccreditedSellerInlineNotificationsProvider;
