import { WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Button, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PIN_LENGTH,
} from "@/components/common";
import { RELOAD_DELAY_MS } from "@/components/frontegg";
import { useModal } from "@/hooks";
import {
  MfaToastKeys,
  useDisableAppMfa,
  VALID_TOAST_FLAG,
} from "@/hooks/frontegg";

interface ModalBodyWithCodeChallengeProps {
  readonly loading: boolean;
  readonly onOpenDisableAllMfaModal: () => void;
  readonly onPinChange: (value: string) => void;
  readonly onPinComplete: (value: string) => void;
}

const ModalBodyWithCodeChallenge = ({
  loading,
  onOpenDisableAllMfaModal,
  onPinChange,
  onPinComplete,
}: ModalBodyWithCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Trans
        i18nKey="remove_authenticator_app_description_with_code"
        t={t}
        components={{
          a: (
            <Button
              as="span"
              cursor="pointer"
              fontWeight={400}
              textDecoration="underline"
              variant="text-ghost"
              whiteSpace="none"
              onClick={onOpenDisableAllMfaModal}
              paddingBottom={2}
            />
          ),
        }}
      />
      <CodeInput
        disabled={loading}
        onChange={onPinChange}
        onComplete={onPinComplete}
      />
    </>
  );
};

const ModalBodyWithoutCodeChallenge = () => {
  const { t } = useTranslation();

  return (
    <Trans i18nKey="remove_authenticator_app_description_without_code" t={t} />
  );
};

interface ModalFooterWithCodeChallengeProps {
  readonly loading: boolean;
  readonly onPinComplete: () => void;
  readonly pinValue: string;
}

const ModalFooterWithCodeChallenge = ({
  loading,
  onPinComplete,
  pinValue,
}: ModalFooterWithCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <HiiveSubmitButton
      isDisabled={pinValue.length !== PIN_LENGTH}
      isLoading={loading}
      sentryLabel="[DisableAppMfa/Submit]"
      submitText={t(`remove`)}
      type="submit"
      onClick={onPinComplete}
    />
  );
};

interface ModalFooterWithoutCodeChallengeProps {
  readonly loading: boolean;
  readonly onSubmit: () => void;
}

const ModalFooterWithoutCodeChallenge = ({
  loading,
  onSubmit,
}: ModalFooterWithoutCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <HiiveSubmitButton
      isLoading={loading}
      sentryLabel="[DisableAppMfa/Submit]"
      submitText={t(`confirm`)}
      type="submit"
      onClick={onSubmit}
    />
  );
};

interface DisableAppMfaModalProps {
  readonly email: string;
  readonly skipCodeChallenge: boolean;
}

const DisableAppMfaModal = ({
  email,
  skipCodeChallenge = false,
}: DisableAppMfaModalProps) => {
  const { t } = useTranslation();
  const { reload } = useRouter();
  const { currentModal, onOpenModal, modals } = useModal();

  const [pinValue, setPinValue] = useState<string>(``);

  const [disableMfa, { loading, error, success }] = useDisableAppMfa({
    onSuccess: () => {
      sessionStorage.setItem(
        MfaToastKeys.showAppMfaRemovedToast,
        VALID_TOAST_FLAG,
      );
      setTimeout(() => reload(), RELOAD_DELAY_MS);
    },
  });

  const handleOpenDisableAllMfaModal = () => {
    onOpenModal(modals.disableAllMfa(email), currentModal)();
  };

  const handlePinComplete = async (value: string) => {
    if (skipCodeChallenge) {
      return;
    }

    await disableMfa({ token: value });
  };

  const handleSubmit = async () => disableMfa();

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`remove_authenticator_app`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          {skipCodeChallenge ? (
            <ModalBodyWithoutCodeChallenge />
          ) : (
            <ModalBodyWithCodeChallenge
              loading={loading}
              onOpenDisableAllMfaModal={handleOpenDisableAllMfaModal}
              onPinChange={setPinValue}
              onPinComplete={handlePinComplete}
            />
          )}
          {error && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{t(`incorrect_code`)}</Text>
            </CallOut>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          {skipCodeChallenge ? (
            <ModalFooterWithoutCodeChallenge
              loading={loading || success}
              onSubmit={handleSubmit}
            />
          ) : (
            <ModalFooterWithCodeChallenge
              loading={loading || success}
              onPinComplete={() => handlePinComplete(pinValue)}
              pinValue={pinValue}
            />
          )}
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default DisableAppMfaModal;
