import { ReactNode, createContext } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/state";

export const Token = createContext<string | null>(null);

export function TokenProvider({ children }: { children: ReactNode }) {
  const token = useSelector((state: RootState) => state.auth.token);

  return <Token.Provider value={token}>{children}</Token.Provider>;
}
