import { Box } from "@chakra-ui/react";

import { ActionNeeded, AlertLevel } from "@/components/common";
import { OnboardingContainerV2 } from "@/components/onboarding-v2";

const UserIsHeldFromPlatformPage = ({
  body,
  header,
  metaTitle,
}: {
  body: string;
  header: string;
  metaTitle: string;
}) => (
  <OnboardingContainerV2 canGoBack={false} metaTitle={metaTitle}>
    <Box mb={3}>
      <ActionNeeded heading={header} variant={AlertLevel.Info}>
        {body}
      </ActionNeeded>
    </Box>
  </OnboardingContainerV2>
);

export default UserIsHeldFromPlatformPage;
