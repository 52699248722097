import { useTranslation } from "react-i18next";

import { Card } from "@chakra-ui/react";

import { Note } from "@/components/common";
import {
  IssuerPageHeader,
  IssuerPortalPageContainer,
} from "@/components/issuers";
import {
  IssuerProfilePageCompanyFragment,
  useIssuerProfilePageQuery,
} from "@/gql";
import { useCurrentIssuerActor } from "@/hooks";
import { sanitizeCompanyData } from "@/utils";

import { CompanyInfoTabs } from "./CompanyInfoTabs";
import { IssuerDescriptionCard } from "./IssuerDescriptionCard";
import { IssuerGeneralDetailsCard } from "./IssuerGeneralDetailsCard";
import { IssuerLastRoundDetailsCard } from "./IssuerLastRoundDetailsCard";
import { IssuerProfilePageSkeleton } from "./IssuerProfilePageSkeleton";

interface IssuerProfilePageContentProps {
  readonly company: IssuerProfilePageCompanyFragment;
}

const IssuerProfilePageContent = ({
  company,
}: IssuerProfilePageContentProps) => (
  <>
    <IssuerDescriptionCard company={company} />
    <IssuerGeneralDetailsCard company={company} />
    <IssuerLastRoundDetailsCard company={company} />
  </>
);

const IssuerProfilePage = () => {
  const { t } = useTranslation(`issuers`);
  const actor = useCurrentIssuerActor();
  const { data } = useIssuerProfilePageQuery({
    variables: { id: actor.issuer?.id || `` },
  });

  const { companyById: company } = data || {};

  return (
    <IssuerPortalPageContainer
      header={
        <>
          <IssuerPageHeader title={t(`company_info`)} />
          <CompanyInfoTabs />
        </>
      }
      content={
        <Card py={8} px={6} gap={6}>
          {company?.id ? (
            <IssuerProfilePageContent company={sanitizeCompanyData(company)} />
          ) : (
            <IssuerProfilePageSkeleton />
          )}
          <Card variant="flat" px={6} py={6}>
            <Note textStyle="text-sm">
              {t(`last_round_details_description_preqin`)}
            </Note>
          </Card>
        </Card>
      }
    />
  );
};

export default IssuerProfilePage;
