import { useTranslation } from "react-i18next";

import { CardBody, Text, VStack, HStack } from "@chakra-ui/react";

import { useCurrentActor } from "@/hooks";
import { CAOtherAccreditationCriteriaQuestionKey as OtherAccreditationCriteriaQuestionKey } from "@/utils/accreditation";

import {
  InstitutionAccreditationBodyProps,
  AccreditationValuesContainer,
} from ".";

const CAInstitutionAccreditationBody = ({
  onModify,
}: Omit<InstitutionAccreditationBodyProps, `questionGroup`>) => {
  const { t } = useTranslation();
  const actor = useCurrentActor();

  const accAnswers = actor.institution?.accreditationAnswers;
  const hasAccAnswers = !!accAnswers && accAnswers.length > 0;

  if (!hasAccAnswers) return null;

  const accAnswerQuestion = accAnswers[0].question;

  const isOther =
    accAnswerQuestion.key === OtherAccreditationCriteriaQuestionKey;

  return (
    <CardBody>
      <AccreditationValuesContainer onModify={onModify}>
        <VStack alignItems="flex-start" gap={6}>
          <Text textStyle="heading-lg">{t`firm_fund_must_be_accredited_and_how`}</Text>
          <HStack alignItems="flex-start">
            <Text textStyle="heading-md">{t`answer`}:</Text>
            <Text>
              {isOther ? accAnswers[0]?.text : accAnswerQuestion.text}
            </Text>
          </HStack>
        </VStack>
      </AccreditationValuesContainer>
    </CardBody>
  );
};

export default CAInstitutionAccreditationBody;
