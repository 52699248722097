/* eslint-disable functional/immutable-data */
import { useEffect, useRef, useState } from "react";

import { getTopOffset, getBottomOffset, handleRefs } from "@/utils";

type DimsMap<TSectionKeys extends string> = Record<
  TSectionKeys,
  { readonly top: number; readonly bottom: number }
>;

export interface GetIsActiveProps<TSectionKeys extends string> {
  readonly dimsMap: DimsMap<TSectionKeys>;
  readonly scrollY: number;
  readonly disabledSectionKeys?: readonly TSectionKeys[];
}

type GetIsActive<TSectionKeys extends string> = ({
  dimsMap,
  scrollY,
  disabledSectionKeys,
}: GetIsActiveProps<TSectionKeys>) => boolean;

export type UseSectionScrollTrackingGetSectionProps<
  TSectionKeys extends string,
  TSectionElement extends Element,
> = (sectionKey: TSectionKeys) => {
  readonly containerElement: Element | null;
  readonly ref: (node: TSectionElement) => void;
};

export const useSectionScrollTracking = <
  TSectionKeys extends string,
  TSectionElement extends Element,
>({
  sections,
  disabledSectionKeys,
  containerElement = document.body,
  onClickSection,
}: {
  readonly sections: readonly {
    readonly key: TSectionKeys;
    readonly getIsActive: GetIsActive<TSectionKeys>;
  }[];
  readonly disabledSectionKeys: readonly TSectionKeys[];
  readonly containerElement: Element | null;
  readonly onClickSection: (element: Element) => void;
}) => {
  const scrollY = useRef(0);

  const sectionKeys = sections.map(({ key }) => key);

  const getIsActiveArray = sections.map(({ getIsActive }) => getIsActive);

  const [isActiveMap, setIsActiveMap] = useState<Record<TSectionKeys, boolean>>(
    sectionKeys.reduce(
      (prev, curr) => ({ [curr]: null, ...prev }),
      {} as Record<TSectionKeys, boolean>,
    ),
  );

  const initialElements = sectionKeys.reduce(
    (prev, curr) => ({ [curr]: null, ...prev }),
    {} as Record<TSectionKeys, null>,
  );

  const sectionRefs =
    useRef<Record<TSectionKeys, Element | null>>(initialElements);

  const [elements, setElements] =
    useState<Record<TSectionKeys, Element | null>>(initialElements);

  const getIsActiveArrayChanged = (
    prevIsActiveArray: readonly boolean[],
    currentIsActiveArray: readonly boolean[],
  ) =>
    prevIsActiveArray.some(
      (active, index) => active !== currentIsActiveArray[index],
    );

  const calculateIsActiveMap = (isActiveMap: Record<TSectionKeys, boolean>) => {
    const currentScroll = window.scrollY;

    scrollY.current = currentScroll;
    const previousIsActiveArray = Object.values(
      isActiveMap,
    ) as readonly boolean[];

    const dimsMap = Object.keys(elements).reduce(
      (prev, sectionKey: TSectionKeys) => {
        if (!elements[sectionKey])
          return {
            ...prev,
            [sectionKey]: {
              top: null,
              bottom: null,
            },
          };

        return {
          ...prev,
          [sectionKey]: {
            top: getTopOffset(
              elements[sectionKey] as Element,
              containerElement!,
            ),
            bottom: getBottomOffset(
              elements[sectionKey] as Element,
              containerElement!,
            ),
          },
        };
      },
      {} as Record<
        TSectionKeys,
        { readonly top: number; readonly bottom: number }
      >,
    );

    const currentIsActiveArray = getIsActiveArray.map(
      (getIsActive: GetIsActive<TSectionKeys>) =>
        getIsActive({
          dimsMap,
          scrollY: currentScroll,
          disabledSectionKeys,
        }),
    );

    if (getIsActiveArrayChanged(previousIsActiveArray, currentIsActiveArray)) {
      return currentIsActiveArray.reduce(
        (prev, curr, index) => ({ ...prev, [sectionKeys[index]]: curr }),
        {} as Record<TSectionKeys, boolean>,
      );
    }

    return isActiveMap;
  };

  const handleScroll = () => {
    if (!containerElement) return;

    setIsActiveMap((isActiveMap) => calculateIsActiveMap(isActiveMap));
  };

  useEffect(() => {
    if (containerElement) {
      handleScroll(); // Call once on mount

      window.addEventListener(`scroll`, handleScroll, { passive: true });
    }

    return () => {
      window.removeEventListener(`scroll`, handleScroll);
    };
  }, [elements, containerElement]);

  useEffect(() => {
    if (containerElement) {
      handleScroll();
    }
  }, [disabledSectionKeys]);

  const getSectionProps = (sectionKey: TSectionKeys) => ({
    containerElement,
    ref: handleRefs([
      (node: TSectionElement) => {
        if (sectionRefs.current[sectionKey] !== null) return;
        sectionRefs.current[sectionKey] = node;
        setElements((elements) => ({ ...elements, [sectionKey]: node }));
      },
    ]),
  });

  const getNavButtonProps = (sectionKey: TSectionKeys) => {
    const sectionElement = elements[sectionKey];
    const isDisabled = disabledSectionKeys.includes(sectionKey);
    const isActive = !!(!isDisabled && isActiveMap[sectionKey]);

    return {
      isActive,
      isDisabled,
      ...(!!sectionElement && {
        onClick: () => onClickSection(sectionElement),
      }),
    };
  };

  return { getSectionProps, getNavButtonProps };
};
