import startCase from "lodash/startCase";

export const formatMetaTitle = (
  primaryTitle: string,
  secondaryTitle?: string,
) => {
  const startCasePrimary = startCase(primaryTitle);
  if (!secondaryTitle) {
    return startCasePrimary;
  }
  const startCaseSecondary = startCase(secondaryTitle);
  return `${startCasePrimary} - ${startCaseSecondary}`;
};
