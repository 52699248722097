import { Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  FormLabel,
  GridItem,
  ModalBody,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  FullDivider,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { CheckboxInput, FixedValueInput, FormikQL } from "@/components/form";
import {
  EditInstitutionUserModalUserFragment,
  InstitutionUsersDocument,
  useChangeInstitutionUserRolesMutation,
  UserRole,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";

import { RoleInput } from "./RoleInput";

interface EditUserFormValues {
  readonly role: UserRole;
  readonly signer: boolean;
}

const validationSchema = Yup.object().shape({
  role: Yup.string().nullable().required(`Required`),
  signer: Yup.boolean().nullable(),
});

const getInstitutionRole = (roles: readonly UserRole[]) => {
  switch (true) {
    case roles.includes(UserRole.Admin):
      return UserRole.Admin;
    case roles.includes(UserRole.Trader):
      return UserRole.Trader;
    case roles.includes(UserRole.Viewer):
      return UserRole.Viewer;
    default:
      throw new Error(`Unmatched roles in getInstitutionRole on EditUserForm`);
  }
};

const createMapVariables =
  ({ id }: { readonly id: string }) =>
  ({ signer, role }: EditUserFormValues) => {
    const roles = signer ? [role, UserRole.Signer] : [role];
    return {
      userId: id,
      roles,
    };
  };

const createInitialValues = (
  institutionUser: EditInstitutionUserModalUserFragment,
): EditUserFormValues => {
  const signer = institutionUser.roles.includes(UserRole.Signer);
  const role = getInstitutionRole(institutionUser.roles);

  return {
    role,
    signer,
  };
};

interface EditInstitutionUserModalProps {
  readonly institutionUser: EditInstitutionUserModalUserFragment;
  readonly hasOtherInstitutionAdmins: boolean;
}
const EditInstitutionUserModal = ({
  institutionUser,
  hasOtherInstitutionAdmins,
}: EditInstitutionUserModalProps) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();
  const mutation = useChangeInstitutionUserRolesMutation({
    refetchQueries: [InstitutionUsersDocument],
  });
  const { successToast } = useCustomToast();
  const onSuccess = () => {
    closeModal();
    successToast(`User roles updated successfully!`);
  };

  const initialValues = createInitialValues(institutionUser);
  const mapVariables = createMapVariables(institutionUser);

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        mutation={mutation}
        mutationNames={[`changeInstitutionUserRoles`]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        mapVariables={mapVariables}
        onSuccess={onSuccess}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <HiiveModalHeader>
              <Text>{t(`edit_role`)}</Text>
            </HiiveModalHeader>
            <ModalBody data-dd-privacy="mask">
              <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
                <GridItem colSpan={1}>
                  <FixedValueInput
                    fixedValue={institutionUser.name}
                    name="name"
                    label="Name"
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <FixedValueInput
                    fixedValue={institutionUser.email}
                    name="email"
                    label="Email"
                  />
                </GridItem>

                <GridItem colSpan={2}>
                  <FullDivider />
                </GridItem>

                <GridItem colSpan={2}>
                  <RoleInput isDisabled={!hasOtherInstitutionAdmins} />
                </GridItem>

                <GridItem colSpan={2}>
                  <FormLabel mb={2.5}>{t(`make_user_signer`)}</FormLabel>
                  <CheckboxInput
                    name="signer"
                    align="flex-start"
                    label={
                      <Text textStyle="text-md">
                        {t(`make_user_signer_description`)}
                      </Text>
                    }
                  />
                </GridItem>
              </SimpleGrid>
            </ModalBody>
            <HiiveModalFooter>
              <ButtonFooterWrapper>
                <HiiveCancelButton
                  onCancel={closeModal}
                  sentryLabel="[EditInstitutionUser/Cancel]"
                />
                <HiiveSubmitButton
                  submitText="Apply"
                  type="submit"
                  sentryLabel="[EditInstitutionUser/Submit]"
                  isLoading={isSubmitting}
                />
              </ButtonFooterWrapper>
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default EditInstitutionUserModal;
