import { useTranslation } from "react-i18next";

import { Flex, Text, TextProps, VStack } from "@chakra-ui/react";

import { LongHyphen, SensitiveText } from "@/components/common";
import { CustomTooltip } from "@/components/form";
import { BrowseCompaniesPagePricingBlockCompanyFragment } from "@/gql";
import { Maybe } from "@/types";
import { formatPricePerShare } from "@/utils";

const PriceHeading = ({
  children,
  tooltip,
}: TextProps & {
  readonly children: React.ReactNode;
  readonly tooltip: React.ReactNode;
}) => (
  <CustomTooltip tooltipContent={tooltip} placement="top">
    <Text
      onClick={(event) => event.preventDefault()}
      display="inline-block"
      textStyle="heading-3xs"
      lineHeight="unset"
      textTransform="uppercase"
      borderBottom="0.5px dashed"
    >
      {children}
    </Text>
  </CustomTooltip>
);

const PriceValue = ({
  value,
  ...rest
}: TextProps & {
  readonly value: Maybe<number> | undefined;
}) => (
  <SensitiveText textStyle="heading-xs" {...rest}>
    {value ? formatPricePerShare(value) : <LongHyphen />}
  </SensitiveText>
);

const PricingBlock = ({
  company,
}: {
  readonly company: BrowseCompaniesPagePricingBlockCompanyFragment;
}) => {
  const { t } = useTranslation(`company`);
  return (
    <Flex
      w="full"
      px={4}
      py={2}
      borderColor="grey.100"
      borderRadius="md"
      borderWidth={0.5}
      bg="grey.50"
      alignItems="flex-end"
      textAlign="center"
      justifyContent="space-between"
      gap={{ base: 2, md: 4 }}
    >
      <VStack minW="25%">
        <PriceHeading
          tooltip={t(`highest_bid_tooltip`, { companyName: company.name })}
        >
          {t`highest_bid`}
        </PriceHeading>
        <PriceValue value={company.currentPrices?.highestBid} />
      </VStack>

      <VStack minW="25%">
        <PriceHeading
          tooltip={t(`lowest_ask_tooltip`, { companyName: company.name })}
        >
          {t`lowest_ask`}
        </PriceHeading>
        <PriceValue value={company.currentPrices?.lowestAsk} />
      </VStack>

      <VStack minW="25%">
        <PriceHeading
          tooltip={t(`last_accepted_bid_tooltip`, {
            companyName: company.name,
          })}
        >
          {t`last_accepted_bid`}
        </PriceHeading>
        <PriceValue value={company.currentPrices?.lastTransaction} />
      </VStack>
    </Flex>
  );
};

export default PricingBlock;
