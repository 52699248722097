import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  DateTableCell,
  NullTableCell,
  SkeletonTableCell,
  TooltipTableCell,
} from "@/components/issuers";
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaders,
  TableRow,
  TableRows,
} from "@/components/table";
import { IssuerInvitationFragment, UserRole } from "@/gql";
import { useCurrentIssuerActor } from "@/hooks";
import { getInvitationUsersTableData } from "@/utils";

import {
  InvitationActionCell,
  InvitationStatusCell,
  RoleTypesTableCell,
} from "./IssuerManageUsersTableCells";

type IssuerManageUsersTableProps = {
  invitations: IssuerInvitationFragment[];
};

const TABLE_SKELETON_ROW_LENGTH = 3;

export function IssuerManageUsersTable({
  invitations,
}: IssuerManageUsersTableProps) {
  const { t } = useTranslation(`issuers`);
  const { roles } = useCurrentIssuerActor();

  const isAdminActor = roles.includes(UserRole.Admin);

  const invitationUsersTableData = useMemo(
    () => getInvitationUsersTableData(invitations),
    [invitations],
  );

  return (
    <Table>
      <TableHeaders>
        <TableHeader>{t(`name`)}</TableHeader>
        <TableHeader>{t(`job_title`)}</TableHeader>
        <TableHeader>{t(`email`)}</TableHeader>
        <TableHeader>{t(`role`)}</TableHeader>
        <TableHeader>{t(`added_on`)}</TableHeader>
        <TableHeader>{t(`status`)}</TableHeader>
        {isAdminActor && <TableHeader />}
      </TableHeaders>
      <TableRows>
        {invitationUsersTableData.map(
          ([invitation, { status: inviteeStatus }], index) => {
            const { id, invitee, email, roles, insertedAt } = invitation;

            const { name, jobTitle } = invitee ?? {};

            return (
              <TableRow key={id} striping index={index}>
                {name ? (
                  <TooltipTableCell label={name} shouldWrapText>
                    {name}
                  </TooltipTableCell>
                ) : (
                  <NullTableCell />
                )}

                {jobTitle ? (
                  <TooltipTableCell label={jobTitle} shouldWrapText>
                    {jobTitle}
                  </TooltipTableCell>
                ) : (
                  <NullTableCell />
                )}

                {email ? (
                  <TooltipTableCell label={email} shouldWrapText>
                    {email}
                  </TooltipTableCell>
                ) : (
                  <NullTableCell />
                )}

                {roles ? (
                  <RoleTypesTableCell roles={roles} />
                ) : (
                  <NullTableCell />
                )}

                {insertedAt ? (
                  <DateTableCell>{insertedAt}</DateTableCell>
                ) : (
                  <NullTableCell />
                )}

                {inviteeStatus ? (
                  <InvitationStatusCell status={inviteeStatus} />
                ) : (
                  <NullTableCell />
                )}

                {isAdminActor && (
                  <InvitationActionCell invitation={invitation} />
                )}
              </TableRow>
            );
          },
        )}
      </TableRows>
    </Table>
  );
}

export function IssuerManageUsersTableSkeleton() {
  const { t } = useTranslation(`issuers`);

  return (
    <Table>
      <TableHeaders>
        <TableHeader>{t(`name`)}</TableHeader>
        <TableHeader>{t(`job_title`)}</TableHeader>
        <TableHeader>{t(`email`)}</TableHeader>
        <TableHeader>{t(`role`)}</TableHeader>
        <TableHeader>{t(`added_on`)}</TableHeader>
        <TableHeader>{t(`status`)}</TableHeader>
        <TableHeader />
      </TableHeaders>
      <TableRows>
        {Array.from({ length: TABLE_SKELETON_ROW_LENGTH }).map((_, index) => (
          <TableRow
            key={`issuer_user_content_table_skeleton_row_${index.toString()}`}
          >
            <SkeletonTableCell />
            <SkeletonTableCell />
            <SkeletonTableCell />
            <SkeletonTableCell />
            <SkeletonTableCell />
            <SkeletonTableCell />
            <TableCell />
          </TableRow>
        ))}
      </TableRows>
    </Table>
  );
}
