import { useTranslation } from "react-i18next";

import { Card, CardHeader, Text, HStack } from "@chakra-ui/react";

import { IssuerProfilePageCompanyFragment } from "@/gql";
import { formatLastRoundDate, formatValuation } from "@/utils";

import { IssuerInfoCard } from "./IssuerInfoCard";
import { IssuerInfoRow } from "./IssuerInfoRow";

export const IssuerLastRoundDetailsCard = ({
  company,
}: {
  readonly company: IssuerProfilePageCompanyFragment;
}) => {
  const { lastRoundSeries, lastRoundDate, lastRoundRaised } = company;
  const { t } = useTranslation(`issuers`);

  return (
    <Card variant="flat">
      <CardHeader px={6} py={6}>
        <HStack justifyContent="space-between">
          <Text as="h5" textStyle="heading-sm">
            {t(`last_round_details`)}
          </Text>
        </HStack>
      </CardHeader>
      <IssuerInfoCard>
        <IssuerInfoRow title={t(`date`)}>
          {lastRoundDate ? formatLastRoundDate(lastRoundDate) : t(`na`)}
        </IssuerInfoRow>
        <IssuerInfoRow title={t(`round`)}>
          {lastRoundSeries || t(`na`)}
        </IssuerInfoRow>
        <IssuerInfoRow title={t(`capital_raised`)}>
          {lastRoundRaised ? formatValuation(lastRoundRaised) : t(`na`)}
        </IssuerInfoRow>
      </IssuerInfoCard>
    </Card>
  );
};
