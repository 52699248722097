import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import { useRouter } from "next/router";

import { Text, VStack } from "@chakra-ui/react";

import { getIssuerTransactionDetailRoute } from "@/utils";

import { IssuerTransactionsCard } from "./IssuerTransactionsCard";
import { IssuerTransactionsCardListEmptyState } from "./IssuerTransactionsCardListEmptyState";
import { IssuerTransactionsCardListSkeleton } from "./IssuerTransactionsCardListSkeleton";
import { IssuerTransactionsTableContext } from "./IssuerTransactionsProvider";
import { IssuerTransactionsSearchBar } from "./IssuerTransactionsSearchBar";
import { IssuerTransactionsStageDescription } from "./IssuerTransactionsStageDescription";

function IssuerTransactionsEmptyCardStack() {
  const { t } = useTranslation(`issuers`);

  return (
    <VStack spacing={4} mt={12}>
      <Text textStyle="heading-lg">{t(`no_results`)}</Text>
      <Text textAlign="center">{t(`no_results_description`)}</Text>
    </VStack>
  );
}

function IssuerTransactionsCardStack() {
  const { t } = useTranslation(`issuers`);
  const { push } = useRouter();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { transactions, onLoadMore, pageInfo } = useContext(
    IssuerTransactionsTableContext,
  );

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    const shouldLoadMore = inView && pageInfo.hasNextPage;

    if (shouldLoadMore) {
      onLoadMore();
    }

    setIsLoadingMore(shouldLoadMore);
  }, [inView, onLoadMore, pageInfo]);

  return (
    <>
      {transactions?.map((edge, index) => {
        const isLastItem = index === transactions.length - 1;
        const transactionDetailPath = getIssuerTransactionDetailRoute(
          edge.node.id,
        );
        return (
          <IssuerTransactionsCard
            key={edge.node.id}
            transaction={edge.node}
            ref={isLastItem ? ref : null}
            onClick={() => push(transactionDetailPath)}
          />
        );
      })}
      {isLoadingMore && <IssuerTransactionsCardListSkeleton />}
      {!pageInfo.hasNextPage && (
        <Text color="grey.500" mt={8} mb={16}>
          {t(`all_transactions_loaded`)}
        </Text>
      )}
    </>
  );
}

function IssuerTransactionsCardListContent() {
  const { totalCount } = useContext(IssuerTransactionsTableContext);

  return (
    <VStack spacing={2} w="full">
      <VStack pt={4} pb={8} px={4} spacing={4} w="full" alignItems="flex-start">
        <IssuerTransactionsStageDescription />
        <IssuerTransactionsSearchBar />
      </VStack>
      {totalCount > 0 ? (
        <IssuerTransactionsCardStack />
      ) : (
        <IssuerTransactionsEmptyCardStack />
      )}
    </VStack>
  );
}

export function IssuerTransactionsCardList() {
  const { filterBy, loading, totalCount } = useContext(
    IssuerTransactionsTableContext,
  );

  const { searchText } = filterBy ?? {};

  if (loading) {
    return (
      <>
        <VStack
          pt={4}
          pb={8}
          px={4}
          spacing={4}
          w="full"
          alignItems="flex-start"
        >
          <IssuerTransactionsStageDescription />
          <IssuerTransactionsSearchBar />
        </VStack>
        <IssuerTransactionsCardListSkeleton />
      </>
    );
  }

  const shouldShowEmptyState = !searchText && totalCount === 0;

  return shouldShowEmptyState ? (
    <IssuerTransactionsCardListEmptyState />
  ) : (
    <IssuerTransactionsCardListContent />
  );
}
