import { BidInfoCardBidFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { getIsBroker } from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";

import BrokerSellerBidInfoCard from "./BrokerSellerBidInfoCard";
import BuyerBidInfoCard from "./BuyerBidInfoCard";
import SellerBidInfo from "./SellerBidInfoCard";

const BidInfoCard = ({ bid }: { readonly bid: BidInfoCardBidFragment }) => {
  const actor = useCurrentActor();

  const isBuyer = getIsBuyerForBid(actor, bid);
  const isBroker = getIsBroker(actor);

  if (isBuyer) return <BuyerBidInfoCard bid={bid} />;

  if (!isBuyer && isBroker) return <BrokerSellerBidInfoCard bid={bid} />;

  return <SellerBidInfo bid={bid} />;
};

export default BidInfoCard;
