import { useTranslation } from "react-i18next";

import { Box, Text } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  OffPlatformReminderDiscussionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { getDiscussionIsSeller, getDiscussionDocumentOrderText } from "@/utils";

const OffPlatformReminder = ({
  discussion,
  actor,
}: {
  readonly discussion: OffPlatformReminderDiscussionFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();
  const topicType = getDiscussionDocumentOrderText(discussion);
  const sellerOrBuyer = getDiscussionIsSeller(actor, discussion)
    ? `buyer`
    : `seller`;

  return (
    topicType !== `order` && (
      <Box textStyle="deprecated-text-2xs" lineHeight="shorter" flex="1">
        <Text mb={4}>{t(`message_name_${sellerOrBuyer}`)}</Text>
      </Box>
    )
  );
};

export default withCurrentActor(OffPlatformReminder);
