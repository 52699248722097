import { useFormikContext } from "formik";
import isNil from "lodash/isNil";
import { forwardRef, useDeferredValue } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  FocusedShareDetails,
  HiiveSubmitButton,
  ListingNumShareAndPriceDetailsPreview,
} from "@/components/common";
import { ListingSummaryTile, ConfirmIntentInput } from "@/components/listings";
import {
  AcceptedBidSharePriceDetails,
  ListingSellerRoundingDisclaimer,
} from "@/components/postings";
import { UnaccreditedSellerModifyListingPageListingFragment } from "@/gql";
import { currencyValue, roundUp } from "@/utils";

import { ModifyListingFormValues } from "./types";

export const SummaryAndConfirmationCard = forwardRef<
  HTMLDivElement,
  { readonly listing: UnaccreditedSellerModifyListingPageListingFragment }
>(({ listing }, ref) => {
  const { values, isSubmitting } = useFormikContext<ModifyListingFormValues>();
  const { numShares } = values;

  const { t } = useTranslation();

  const { pricePerShare, otherDetails, transferTypeChoice } =
    useDeferredValue(values);

  const displayedNumberOfShares = roundUp(numShares);

  const displayedPricePerShare = !isNil(pricePerShare)
    ? currencyValue(pricePerShare)
    : null;

  const hasAcceptedBidPrices = listing.acceptedBidPrices.length >= 1;
  const canSeeAcceptedBidPrices = hasAcceptedBidPrices && !listing.solicited;

  return (
    <Card w="full" ref={ref}>
      <CardHeader>
        <Text
          textStyle="heading-sm"
          _before={{
            content: `counter(section) ". "`,
          }}
        >
          Summary & Confirmation
        </Text>
      </CardHeader>
      <CardBody>
        <VStack alignItems="flex-start" w="full" spacing={4}>
          <Text textStyle="heading-2xs">Review Listing Summary</Text>
          <Text>
            Here is the information you entered. Please confirm it is accurate.
            You can modify your listing at anytime, even after it is posted.
            {` `}
          </Text>
          <ListingSummaryTile
            numShares={numShares}
            pricePerShare={pricePerShare}
            otherDetails={otherDetails}
            transferTypeChoice={transferTypeChoice}
          />
          <Text>
            This is how your listing will look inside the marketplace. You can
            modify it anytime.
          </Text>
          <FocusedShareDetails.Header
            title={t(`listing_preview`)}
            variant="listing-preview"
          >
            <FocusedShareDetails.HeaderCard>
              <ListingNumShareAndPriceDetailsPreview
                numShares={displayedNumberOfShares}
                pricePerShare={displayedPricePerShare}
                listing={listing}
              />
              {canSeeAcceptedBidPrices && (
                <AcceptedBidSharePriceDetails listing={listing} />
              )}
            </FocusedShareDetails.HeaderCard>
            <ListingSellerRoundingDisclaimer />
          </FocusedShareDetails.Header>
        </VStack>
      </CardBody>
      <CardBody>
        <VStack alignItems="flex-start" spacing="4">
          <Text textStyle="heading-2xs">What happens next</Text>
          <Text>{t(`modify_listing_what_happens_next`)}</Text>
        </VStack>
      </CardBody>
      <CardBody>
        <ConfirmIntentInput />
      </CardBody>
      <CardFooter bg="grey.25">
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            isLoading={isSubmitting}
            type="submit"
            submitText="Modify Listing"
            sentryLabel="[UnaccreditedSellerModifyListing/SummaryAndConfirmationCard/Submit]"
            px={16}
          />
        </ButtonFooterWrapper>
      </CardFooter>
    </Card>
  );
});
