/* eslint-disable consistent-return */
import { CaretDown, CaretRight } from "@phosphor-icons/react";
import { sentenceCase } from "change-case";
import { Fragment, ReactNode, useLayoutEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Badge,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardFooterProps,
  CardHeader,
  CardProps,
  Center,
  Divider,
  Flex,
  GridItem,
  HStack,
  Image,
  Link,
  SimpleGrid,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";

import {
  DiscussionThreadCardBody,
  DiscussionThreadCardFooter,
  FullContentWrapper,
  HiiveButton,
  MailtoLink,
  WithQuery,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  UserWithInstitutionFragment,
  TransactionExecutionPageTransactionFragment,
  useTransactionExecutionPageTransactionByIdQuery,
  ExecutionWorkflowStatus,
  TransactionExecutionPageWorkflowFragment,
  ExecutionStepStatus,
  ExecutionStepVisibility,
  ExecutionMilestone,
  TransactionExecutionPageWorkflowMilestoneFragment,
  TransactionExecutionPageWorkflowConditionFragment,
  useTransactionExecutionPageOnExecutionWorkflowUpdatedSubscription,
  ExecutionStepType,
  TransactionExecutionPageWorkflowStepFragment,
  ValidTransferMethodV2,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import {
  constants,
  formatCurrency,
  formatPricePerShare,
  formatShares,
  getCC,
  getDiscussionTopicCounterpartyText,
  getMarketAnalystContact,
} from "@/utils";
import * as datetime from "@/utils/datetime";

import MilestoneStatusIcon from "./MilestoneStatusIcon";
import CompletedStep from "./Step/CompletedStep";
import Tasks from "./Task/Tasks";
import { TransactionDocumentsTab } from "./TransactionDocmentsTab";
import { TransactionExecutionPageSkeleton } from "./TransactionExecutionPageSkeleton";
import WorkflowConditionCard from "./WorkflowConditionCard";
import WorkflowConditionStatusIcon from "./WorkflowConditionStatusIcon";
import {
  hasCancelledWorkflow,
  hasIssuerDeclinedWorkflow,
  hasIssuerROFRedWorkflow,
  hasMilestoneCondition,
} from "./utils";

const MilestoneRow = ({
  showLine = true,
  isActive = false,
  isCompleted = false,
  isBuySide = false,
  completedAt,
  milestone,
  copy,
  conditions,
}: {
  readonly milestone: string;
  readonly copy: string;
  readonly showLine?: boolean;
  readonly isActive?: boolean;
  readonly isCompleted?: boolean;
  readonly completedAt?: string;
  readonly isBuySide?: boolean;
  readonly conditions: TransactionExecutionPageWorkflowConditionFragment[];
}) => {
  const [show, setShow] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  const lineRef = useRef<SVGSVGElement | null>(null);

  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const padding = 8;
  const gap = 8;
  const borderWidth = 1;

  useLayoutEffect(() => {
    if (!ref.current || !lineRef.current) {
      if (!resizeObserverRef.current) return;

      resizeObserverRef.current.disconnect();
      return;
    }

    resizeObserverRef.current = new ResizeObserver((entries) => {
      if (!lineRef.current) return;

      const {
        contentRect: { height },
      } = entries[0];

      const h = height + 2 * padding + 2 * borderWidth + gap;

      lineRef.current.style.height = `${h}px`;
      if (!isCompleted) {
        lineRef.current.style.strokeDasharray = `${3 / h}`;
      }
    });

    resizeObserverRef.current.observe(ref.current);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [ref.current]);

  const headingColor = match({ isCompleted, isActive })
    .with(
      {
        isCompleted: true,
      },
      () => `grey.900`,
    )
    .with({ isActive: true }, () => `teal.900`)
    .otherwise(() => `grey.600`);

  const status = match({ isCompleted, isActive })
    .with({ isCompleted: true }, () => ExecutionStepStatus.Completed)
    .with({ isActive: true }, () => ExecutionStepStatus.InProgress)
    .otherwise(() => ExecutionStepStatus.Pending);

  const overrideStatus =
    milestone === ExecutionMilestone.Approved &&
    hasMilestoneCondition(conditions);

  return (
    <Box
      px={6}
      py={2}
      w="full"
      borderColor={isActive ? `teal.50` : `white`}
      bg={isActive ? `teal.25` : `white`}
      borderBottomWidth={1}
      borderTopWidth={1}
      as={chakra.button}
      textAlign="left"
      onClick={() => setShow(!show)}
      _hover={{
        bg: isActive ? `teal.25` : `grey.25`,
      }}
      _active={{
        bg: isActive ? `teal.50` : `grey.50`,
      }}
      _focusVisible={{
        outline: `none`,
        boxShadow: `focus`,
      }}
      transition="background-color .1s ease-in-out"
    >
      <HStack spacing={0} gap={3} alignItems="flex-start">
        <Box h="full" position="relative">
          {showLine && (
            <chakra.svg
              ref={lineRef}
              position="absolute"
              top="50%"
              left="50%"
              transform="auto"
              translateX="-50%"
              w="2px"
              stroke={isCompleted ? `teal.1000` : `teal.700`}
              viewBox="0 0 2 1"
              preserveAspectRatio="none"
            >
              <chakra.line y1={0} y2={1} x1={1} x2={1} strokeWidth={1} />
            </chakra.svg>
          )}
          <Box w={4} h={4} position="relative" color="teal.1000">
            {overrideStatus ? (
              conditions.map(({ condition }) => (
                <Fragment key={condition}>
                  <WorkflowConditionStatusIcon
                    condition={condition}
                    isBuySide={isBuySide}
                  />
                </Fragment>
              ))
            ) : (
              <MilestoneStatusIcon status={status} />
            )}
          </Box>
        </Box>
        <VStack alignItems="flex-start" ref={ref} w="full">
          <HStack justifyContent="space-between" w="full">
            <Text
              textStyle="heading-3xs"
              lineHeight="16px"
              color={headingColor}
            >
              {sentenceCase(milestone)}
            </Text>
            <HStack>
              <Text textStyle="text-xs" lineHeight="16px">
                {completedAt &&
                  datetime.format(`Do MMM (h:mm A z)`, completedAt)}
              </Text>
              {show ? (
                <CaretDown width="16" height="16" />
              ) : (
                <CaretRight width="16" height="16" />
              )}
            </HStack>
          </HStack>
          {show && <Text textStyle="text-xs">{copy}</Text>}
        </VStack>
      </HStack>
    </Box>
  );
};

const MilestonesCard = ({
  transaction,
  milestoneOrder,
  milestoneCompletions,
  conditions,
  isBuySide = false,
}: {
  readonly transaction: TransactionExecutionPageTransactionFragment;
  readonly milestoneOrder: ExecutionMilestone[];
  readonly milestoneCompletions: TransactionExecutionPageWorkflowMilestoneFragment[];
  readonly conditions: TransactionExecutionPageWorkflowConditionFragment[];
  readonly isBuySide?: boolean;
}) => {
  const { t } = useTranslation();

  const lastCompletedMilestone =
    milestoneCompletions[milestoneCompletions.length - 1]?.milestone;

  const activeMilestone =
    milestoneOrder[milestoneOrder.indexOf(lastCompletedMilestone) + 1];

  const isCompleted = (milestone: ExecutionMilestone) =>
    milestoneCompletions.map((m) => m.milestone).includes(milestone);

  const getCompletedAt = (milestone: ExecutionMilestone) =>
    milestoneCompletions[
      milestoneCompletions.findIndex((m) => m.milestone === milestone)
    ]?.completedAt || null;

  const hasConditions = conditions ? conditions?.length > 0 : false;

  const transferTypeText = match(transaction.transferMethodV2)
    .with(ValidTransferMethodV2.Direct, () => t(`direct`))
    .with(ValidTransferMethodV2.Unknown, () => t(`in_review`))
    .otherwise(() => t(`indirect`));

  return (
    <Card w="full">
      <CardHeader py={4}>
        <HStack justify="space-between" flexWrap="wrap">
          <Text textStyle="heading-md">
            <Trans i18nKey="transaction_status" />
          </Text>
          <HStack
            backgroundColor="grey.25"
            borderColor="grey.200"
            borderWidth="1px"
            borderRadius="6px"
            px={2}
            spacing={1}
          >
            <Text textStyle="text-xs">
              <Trans i18nKey="transfer_type" />
            </Text>
            <Text textStyle="heading-3xs">{transferTypeText}</Text>
          </HStack>
        </HStack>
      </CardHeader>
      <CardBody p={0} py={4} borderBottom="none">
        <VStack spacing={0} gap={2}>
          {milestoneOrder.map((milestone, index) => (
            <MilestoneRow
              key={milestone}
              milestone={milestone}
              copy="lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              showLine={index !== milestoneOrder.length - 1}
              isCompleted={isCompleted(milestone)}
              isActive={activeMilestone === milestone}
              completedAt={getCompletedAt(milestone)}
              conditions={conditions}
              isBuySide={isBuySide}
            />
          ))}
        </VStack>
      </CardBody>
      {hasConditions && (
        <CardBody pt={0} p={6}>
          {conditions.map(({ condition }) => (
            <WorkflowConditionCard
              key={condition}
              condition={condition}
              isBuySide={isBuySide}
            />
          ))}
        </CardBody>
      )}
    </Card>
  );
};

const FeeBreakdownHeading = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <Text textStyle="heading-2xs" color="grey.400">
    {children}
  </Text>
);

const FeeBreakdownRow = ({
  title,
  value,
}: {
  readonly title: string;
  readonly value: string;
}) => (
  <HStack justifyContent="space-between" w="full">
    <Text textStyle="heading-2xs">{title}</Text>
    <Text>{value}</Text>
  </HStack>
);

const FeeBreakdownCardHeader = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <CardHeader py={4} as={VStack} alignItems="flex-start">
    {children}
  </CardHeader>
);

interface FeeBreakdownCardBodyProps extends CardProps {
  readonly children: ReactNode;
}

const FeeBreakdownCardBody = ({
  children,
  ...cardProps
}: FeeBreakdownCardBodyProps) => (
  <CardBody
    p={0}
    px={6}
    py={4}
    as={VStack}
    alignItems="flex-start"
    {...cardProps}
  >
    {children}
  </CardBody>
);

interface FeeBreakdownCardFooterProps extends CardFooterProps {
  readonly children: ReactNode;
}

const FeeBreakdownCardFooterProps = ({
  children,
  ...cardFooterProps
}: FeeBreakdownCardBodyProps) => (
  <CardFooter py={4} as={VStack} alignItems="flex-start" {...cardFooterProps}>
    {children}
  </CardFooter>
);

const FeeBreakdownCard = ({ children }: { readonly children: ReactNode }) => (
  <Card w="full" variant="flat">
    {children}
  </Card>
);

const FeeBreakdown = {
  Heading: FeeBreakdownHeading,
  Row: FeeBreakdownRow,
  Card: FeeBreakdownCard,
  CardHeader: FeeBreakdownCardHeader,
  CardBody: FeeBreakdownCardBody,
  CardFooter: FeeBreakdownCardFooterProps,
};

// TODO UPDATE with new article links
const marketingWebsiteLinks = [
  constants.marketing_website_links.calculate_price_of_shares,
  constants.marketing_website_links.dive_into_vc_secondaries,
  constants.marketing_website_links.how_to_sell_private_shares,
];

const InformativeArticlesCard = ({
  transaction,
}: {
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation();

  const marketAnalystEmail = getMarketAnalystContact(transaction.company);
  const cc = getCC(transaction.company, true);

  return (
    <Card w="full">
      <CardHeader py={4}>
        <Text textStyle="heading-md">{t(`informative_articles`)}</Text>
      </CardHeader>
      <CardBody>
        <VStack alignItems="flex-start">
          {marketingWebsiteLinks.map(({ title, url }) => (
            <Link
              target="_blank"
              textDecoration="underline"
              href={url}
              key={url}
            >
              {title}
            </Link>
          ))}
        </VStack>
      </CardBody>
      <CardFooter bg="grey.25">
        <Flex direction="column" gap={4}>
          <Text>{t(`for_assistance`)}:</Text>
          <MailtoLink
            email={marketAnalystEmail}
            cc={cc}
            subject={`Contact ${transaction.company.name} Security Specialist`}
            fontWeight="medium"
          >
            {marketAnalystEmail}
          </MailtoLink>
        </Flex>
      </CardFooter>
    </Card>
  );
};

const SellerFeeBreakdownCard = ({
  transaction,
}: {
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const {
    commissionAmount = 0,
    flatFeeAmount = 0,
    netFees = 0,
  } = transaction.commission || {};

  const formattedNumShares = formatShares(transaction.numShares);
  const formattedPricePerShare = formatPricePerShare(transaction.pricePerShare);
  const formattedGrossProceeds = formatCurrency(transaction.grossProceeds, {
    fromCents: true,
  });
  const formattedClosingFee = formatCurrency(flatFeeAmount, {
    fromCents: true,
  });
  const formattedCommissionAmount = formatCurrency(commissionAmount, {
    fromCents: true,
  });
  const formattedNetFees = formatCurrency(netFees, {
    fromCents: true,
  });

  const netProceeds = Math.max(transaction.grossProceeds - netFees, 0);

  const formattedNetProceeds = formatCurrency(netProceeds, { fromCents: true });

  return (
    <FeeBreakdown.Card>
      <FeeBreakdown.CardHeader>
        <FeeBreakdown.Heading>Gross Proceeds</FeeBreakdown.Heading>
        <FeeBreakdown.Row
          title={`${formattedNumShares} Shares @ ${formattedPricePerShare}`}
          value={formattedGrossProceeds}
        />
      </FeeBreakdown.CardHeader>
      <FeeBreakdown.CardBody bg="grey.25">
        <FeeBreakdown.Heading>Hiive Fees</FeeBreakdown.Heading>
        <FeeBreakdown.Row
          title="Hiive Closing Fee"
          value={formattedClosingFee}
        />
        <FeeBreakdown.Row
          title="Hiive Commission"
          value={formattedCommissionAmount}
        />
        {/* TODO Add in Fee Discounts / Fees for Brokers / Disclaimers / Tooltips */}
      </FeeBreakdown.CardBody>
      <FeeBreakdown.CardBody bg="grey.25">
        <FeeBreakdown.Row title="Total Hiive Fees" value={formattedNetFees} />
      </FeeBreakdown.CardBody>
      <FeeBreakdown.CardFooter bg="teal.25">
        <FeeBreakdown.Heading>Net Proceeds</FeeBreakdown.Heading>
        <FeeBreakdown.Row title="Net Proceeds" value={formattedNetProceeds} />
      </FeeBreakdown.CardFooter>
    </FeeBreakdown.Card>
  );
};

const BuyerFeeBreakdownCard = ({
  transaction,
}: {
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const formattedNumShares = formatShares(transaction.numShares);
  const formattedPricePerShare = formatPricePerShare(transaction.pricePerShare);
  const formattedGrossProceeds = formatCurrency(transaction.grossProceeds, {
    fromCents: true,
  });

  return (
    <FeeBreakdown.Card>
      <FeeBreakdown.CardBody>
        <FeeBreakdown.Heading>Total</FeeBreakdown.Heading>
        <FeeBreakdown.Row
          title={`${formattedNumShares} Shares @ ${formattedPricePerShare}`}
          value={formattedGrossProceeds}
        />
      </FeeBreakdown.CardBody>
    </FeeBreakdown.Card>
  );
};

const getTransactionIsBuySide = ({
  actor,
  transaction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) =>
  actor.id === transaction.buyerId ||
  actor.id === transaction.buyerBrokerId ||
  (!!actor.institutionId &&
    actor.institutionId === transaction.buyerInstitutionId);

const getTransactionIsSellSide = ({
  actor,
  transaction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) =>
  actor.id === transaction.sellerId ||
  actor.id === transaction.sellerBrokerId ||
  (!!actor.institutionId &&
    actor.institutionId === transaction.sellerInstitutionId);

const getTransactionCounterpartyName = ({
  actor,
  transaction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const isSellSide = getTransactionIsSellSide({ actor, transaction });
  const isBuySide = getTransactionIsBuySide({ actor, transaction });

  return match({ isSellSide, isBuySide })
    .with({ isSellSide: true }, () => {
      if (!!transaction.buyerBroker) {
        return transaction.buyerBroker.name;
      }

      if (!!transaction.buyerInstitution) {
        return transaction.buyerInstitution.legalName;
      }

      if (!!transaction.representedBuyer) {
        return `${transaction.representedBuyer.firstName} ${transaction.representedBuyer.lastName}`;
      }

      return transaction.buyer.name;
    })
    .with({ isBuySide: true }, () => {
      if (!!transaction.sellerBroker) {
        return transaction.sellerBroker.name;
      }

      if (!!transaction.sellerInstitution) {
        return transaction.sellerInstitution.legalName;
      }

      if (!!transaction.representedSeller) {
        return `${transaction.representedSeller.firstName} ${transaction.representedSeller.lastName}`;
      }

      return transaction.seller.name;
    })
    .otherwise(() => {
      throw new Error(`Unrecognized party`);
    });
};

const MessagesCard = withCurrentActor(
  ({
    actor,
    transaction,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly transaction: TransactionExecutionPageTransactionFragment;
  }) => {
    if (!transaction.bid.discussion) return null;

    const counterpartyText = getDiscussionTopicCounterpartyText(
      actor,
      transaction.bid.discussion.topic,
    );

    return (
      <Card>
        <CardHeader py={4}>
          <Text textStyle="heading-md">{counterpartyText}</Text>
        </CardHeader>
        <DiscussionThreadCardBody discussion={transaction.bid.discussion} />
        <DiscussionThreadCardFooter discussion={transaction.bid.discussion} />
      </Card>
    );
  },
);

const TransactionDetailsCard = withCurrentActor(
  ({
    actor,
    transaction,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly transaction: TransactionExecutionPageTransactionFragment;
  }) => {
    const { t } = useTranslation();

    const isSellSide = getTransactionIsSellSide({ actor, transaction });
    const isBuySide = getTransactionIsBuySide({ actor, transaction });

    const counterpartyName = getTransactionCounterpartyName({
      actor,
      transaction,
    });

    return (
      <Card>
        <CardHeader py={4}>
          <Text textStyle="heading-md">{t(`transaction_details`)}</Text>
        </CardHeader>
        <CardBody>
          <VStack spacing={4}>
            <Card variant="flat" color="grey.700" w="full">
              <CardBody
                p={0}
                px={6}
                py={3}
                as={HStack}
                justifyContent="space-between"
              >
                <Text textStyle="heading-xs">Counter Party</Text>
                <Text textStyle="text-sm">{counterpartyName}</Text>
              </CardBody>
            </Card>
            {match({ isSellSide, isBuySide })
              .with({ isSellSide: true }, () => (
                <SellerFeeBreakdownCard transaction={transaction} />
              ))
              .with({ isBuySide: true }, () => (
                <BuyerFeeBreakdownCard transaction={transaction} />
              ))
              .otherwise(() => {
                throw new Error(`Unrecognized party`);
              })}
          </VStack>
        </CardBody>
        <CardFooter bg="grey.25" as={HStack} justifyContent="flex-end" py={4}>
          <HiiveButton
            as={Link}
            href={`/listings/${transaction.bid.listingId}`}
            sentryLabel="[TransactionExecutionPage] View Initial Listing"
            size="xl"
            variant="rounded-solid-grey"
          >
            View Initial Listing
          </HiiveButton>
        </CardFooter>
      </Card>
    );
  },
);

const TransactionDetailItem = ({
  title,
  value,
}: {
  readonly title: string;
  readonly value: string;
}) => (
  <HStack
    gap={2}
    w={{ base: `full`, lg: `auto` }}
    justifyContent={{ base: `space-between`, lg: `auto` }}
  >
    <Text color="grey.700" textStyle="heading-xs">
      {title}
    </Text>
    <Text color="grey.700" textStyle="text-sm">
      {value}
    </Text>
  </HStack>
);

function StepName({
  step,
}: {
  readonly step: TransactionExecutionPageWorkflowStepFragment;
}) {
  const { t } = useTranslation();
  return match(step.type)
    .with(ExecutionStepType.EntityCollection, () => t(`transacting_party`))
    .otherwise(() => step.name);
}

const InProgressStepCards = ({
  steps,
  isBuySide,
  transaction,
}: {
  readonly steps: TransactionExecutionPageWorkflowFragment["steps"];
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) =>
  steps.map((step) => {
    // TODO:: can we switch this to just hide the copy?
    const showCopy = [
      ExecutionStepType.EntityCollection,
      ExecutionStepType.IssuerApproval,
      ExecutionStepType.Manual,
      ExecutionStepType.FileUpload,
      ExecutionStepType.Anvil,
      ExecutionStepType.TransactionModification,
      ExecutionStepType.BankAccountCollection,
      ExecutionStepType.FundsRequest,
    ].includes(step.type);

    const noTasks = step.tasks.length === 0;

    return (
      <Card w="full" key={step.id}>
        <CardHeader py={4}>
          <Text textStyle="heading-md">
            <StepName step={step} />
          </Text>
        </CardHeader>
        {showCopy && (
          <Box px={6} py={4}>
            <Text whiteSpace="pre-line">
              {isBuySide ? step.instructions.buyer : step.instructions.seller}
            </Text>
          </Box>
        )}
        {step.tasks.length > 0 && (
          <CardBody p={0} px={6}>
            <Tasks
              tasks={step.tasks}
              isBuySide={isBuySide}
              transaction={transaction}
            />
            {noTasks && (
              <Flex>
                <Spacer h={4} />
              </Flex>
            )}
          </CardBody>
        )}
      </Card>
    );
  });

const PendingStep = ({
  step,
  isBuySide,
}: {
  readonly step: TransactionExecutionPageWorkflowFragment["steps"][0];
  readonly isBuySide: boolean;
}) => {
  const { onToggle, isOpen } = useDisclosure();

  return (
    <>
      <CardBody
        p={0}
        _last={{ borderBottomRadius: 6, border: `none` }}
        _hover={{ bg: `grey.15` }}
        _active={{ bg: `grey.25` }}
        transition="background-color .1s ease-in-out"
      >
        <chakra.button w="full" onClick={onToggle} px={6} py={4}>
          <HStack justifyContent="space-between" w="full">
            <Text textStyle="heading-md">{step.name}</Text>
            {isOpen ? <CaretDown /> : <CaretRight />}
          </HStack>
        </chakra.button>
      </CardBody>
      {isOpen && (
        <CardBody
          p={0}
          py={4}
          px={6}
          _last={{ borderBottomRadius: 6, border: `none` }}
        >
          <Text whiteSpace="pre-line">
            {isBuySide ? step.instructions.buyer : step.instructions.seller}
          </Text>
        </CardBody>
      )}
    </>
  );
};

const PendingStepCards = ({
  steps,
  isBuySide,
}: {
  readonly steps: TransactionExecutionPageWorkflowFragment["steps"];
  readonly isBuySide: boolean;
}) => {
  const { t } = useTranslation(`execution`);

  return (
    <Card w="full">
      <CardHeader py={4}>
        <VStack alignItems="flex-start">
          <Text textStyle="heading-md">{t(`next_tasks`)}</Text>
          <Text textStyle="text-sm">{t(`next_tasks_description`)}</Text>
        </VStack>
      </CardHeader>
      {steps.map((step) => (
        <PendingStep key={step.id} step={step} isBuySide={isBuySide} />
      ))}
    </Card>
  );
};

const CompletedStepsCard = ({
  steps,
  isBuySide,
  transaction,
}: {
  readonly steps: TransactionExecutionPageWorkflowFragment["steps"];
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation(`execution`);

  const numCompletedSteps = steps.length;

  if (numCompletedSteps === 0)
    return (
      <Card w="full" boxShadow="none">
        <CardBody>
          <Text>{t(`no_completed_tasks`)}</Text>
        </CardBody>
      </Card>
    );

  return (
    <Card w="full">
      <CardHeader p={0} px={6} py={4} borderBottomRadius={0}>
        <HStack justifyContent="space-between">
          <HStack>
            <Text textStyle="heading-md">{t(`completed_tasks`)}</Text>
            <Badge bg="grey.50" borderWidth="1px" borderColor="grey.100">
              {numCompletedSteps}
            </Badge>
          </HStack>
        </HStack>
      </CardHeader>
      {steps.map((step) => (
        <CompletedStep
          key={step.id}
          step={step}
          isBuySide={isBuySide}
          transaction={transaction}
        />
      ))}
    </Card>
  );
};

const NoStepsCard = () => (
  <Card w="full">
    <CardHeader py={4}>
      <Text textStyle="heading-md">
        <Trans i18nKey="no_tasks" />
      </Text>
    </CardHeader>
    <CardBody>
      <Text>
        <Trans i18nKey="no_tasks_explainer" />
      </Text>
    </CardBody>
  </Card>
);

function StepTabs({
  workflow,
  isBuySide,
  transaction,
}: {
  readonly workflow?: TransactionExecutionPageWorkflowFragment | null;
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) {
  const { t } = useTranslation();

  if (!workflow || workflow.status === ExecutionWorkflowStatus.Pending) {
    return <NoStepsCard />;
  }

  const userType = isBuySide
    ? ExecutionStepVisibility.Buyer
    : ExecutionStepVisibility.Seller;

  const inProgressSteps = workflow.steps.filter(
    (step) =>
      step.status === ExecutionStepStatus.InProgress &&
      step.visibility.includes(userType),
  );

  const pendingSteps = workflow.steps.filter(
    (step) =>
      step.status === ExecutionStepStatus.Pending &&
      step.visibility.includes(userType),
  );

  const completedSteps = workflow.steps.filter(
    (step) =>
      step.status === ExecutionStepStatus.Completed &&
      step.visibility.includes(userType),
  );

  return (
    <Tabs variant="boxed-teal" w="full">
      <TabList pb={4}>
        <Tab>{t(`now`)}</Tab>
        <Tab>{t(`later`)}</Tab>
        <Tab>{t(`done`)}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          {inProgressSteps.length === 0 &&
          workflow.status !== ExecutionWorkflowStatus.Completed ? (
            <NoStepsCard />
          ) : (
            <InProgressStepCards
              steps={inProgressSteps}
              isBuySide={isBuySide}
              transaction={transaction}
            />
          )}
        </TabPanel>
        <TabPanel>
          <PendingStepCards steps={pendingSteps} isBuySide={isBuySide} />
        </TabPanel>
        <TabPanel>
          <CompletedStepsCard
            steps={completedSteps}
            isBuySide={isBuySide}
            transaction={transaction}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

const TransactionExecutionPageContent = withCurrentActor(
  ({
    transaction,
    actor,
  }: {
    readonly transaction: TransactionExecutionPageTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation();

    const { netFees = 0 } = transaction.commission || {};

    const formattedGrossProceeds = formatCurrency(transaction.grossProceeds, {
      fromCents: true,
    });

    const { workflow } = transaction;

    useTransactionExecutionPageOnExecutionWorkflowUpdatedSubscription({
      variables: { workflowId: workflow?.id || `` },
      skip: !workflow,
    });

    const netProceeds = Math.max(transaction.grossProceeds - netFees, 0);

    const formattedNetProceeds = formatCurrency(netProceeds, {
      fromCents: true,
    });

    const isSellSide = getTransactionIsSellSide({ actor, transaction });
    const isBuySide = getTransactionIsBuySide({ actor, transaction });

    const counterpartyName = getTransactionCounterpartyName({
      actor,
      transaction,
    });

    const isDesktop = useIsDesktop();

    if (!workflow) {
      return (
        <Center mt={12}>
          <Spinner />
        </Center>
      );
    }

    const userType = isBuySide
      ? ExecutionStepVisibility.Buyer
      : ExecutionStepVisibility.Seller;
    const numInProgressSteps = workflow?.steps.filter(
      (step) =>
        step.status === ExecutionStepStatus.InProgress &&
        step.visibility.includes(userType),
    ).length;

    const hasIssuerDeclined =
      !!transaction.workflow &&
      hasIssuerDeclinedWorkflow(transaction.workflow?.conditions);
    const hasIssuerROFRed =
      !!transaction.workflow &&
      hasIssuerROFRedWorkflow(transaction.workflow?.conditions);
    const hasCancelled =
      !!transaction.workflow &&
      hasCancelledWorkflow(transaction.workflow.conditions);

    const hideSteps =
      hasIssuerDeclined || hasCancelled || (hasIssuerROFRed && isBuySide);

    return (
      <FullContentWrapper px={{ base: 4, lg: 8 }}>
        <Box w="full" maxW="max-width-md">
          <SimpleGrid columns={12} gap={6}>
            <GridItem colSpan={{ base: 12, lg: 8 }}>
              <Tabs variant="black">
                <Flex direction="column" gap={4}>
                  <Card>
                    <CardHeader bg="teal.50" borderBottomColor="teal.100" />
                    <CardBody
                      position="relative"
                      p={0}
                      pt="44px"
                      px={6}
                      pb={6}
                      borderBottomColor="grey.50"
                    >
                      {!!transaction.company.logoUrl && (
                        <Center
                          bg="white"
                          w="14"
                          h="14"
                          borderWidth="0.5px"
                          borderColor="grey.200"
                          borderRadius="md"
                          p={2}
                          position="absolute"
                          top="0"
                          transform="auto"
                          translateY="-50%"
                        >
                          <Image
                            src={transaction.company.logoUrl}
                            alt={transaction.company.name}
                          />
                        </Center>
                      )}
                      <HStack
                        mb={4}
                        w="full"
                        justifyContent="space-between"
                        alignItems={{ base: `auto`, lg: `flex-end` }}
                      >
                        <VStack alignItems="flex-start" spacing={0}>
                          <Text textStyle="heading-4xl">
                            {transaction.company.name}
                          </Text>
                          <Text color="teal.900" textStyle="text-xs">
                            <chakra.span fontWeight="medium">
                              {t(`transaction`)}
                            </chakra.span>
                            {` `}
                            {transaction.bid.displayId}
                          </Text>
                          {!isDesktop && (
                            <Text textStyle="text-xs" color="grey.500">
                              {t(`transaction_with`, { counterpartyName })}
                            </Text>
                          )}
                        </VStack>
                        <VStack alignItems="flex-end" spacing={0}>
                          <Text textStyle="heading-xl">
                            {isSellSide
                              ? formattedNetProceeds
                              : formattedGrossProceeds}
                          </Text>
                          {isDesktop && (
                            <Text textStyle="text-xs" color="grey.500">
                              {t(`transaction_with`, { counterpartyName })}
                            </Text>
                          )}
                        </VStack>
                      </HStack>
                      <Divider mb={4} borderColor="grey.75" />
                      <HStack gap={6} rowGap={2} flexWrap="wrap">
                        <TransactionDetailItem
                          title={t(`shares`)}
                          value={`${formatShares(
                            transaction.numShares,
                          )} @ ${formatPricePerShare(
                            transaction.pricePerShare,
                          )}`}
                        />
                        <TransactionDetailItem
                          title={isBuySide ? t(`total`) : t(`gross_proceeds`)}
                          value={formattedGrossProceeds}
                        />
                        {isSellSide && (
                          <TransactionDetailItem
                            title={t(`net_proceeds`)}
                            value={formattedNetProceeds}
                          />
                        )}
                      </HStack>
                    </CardBody>
                    <CardFooter
                      overflow="auto"
                      py={0}
                      px={0}
                      bg="grey.15"
                      css={{
                        "&::-webkit-scrollbar": {
                          display: `none`,
                        },
                      }}
                    >
                      <TabList h="12">
                        <Tab>
                          <HStack>
                            <Text>{t(`tasks`)}</Text>
                            {numInProgressSteps && (
                              <Badge bg="salmon.900" color="white">
                                {numInProgressSteps}
                              </Badge>
                            )}
                          </HStack>
                        </Tab>
                        <Tab>{t(`messages`)}</Tab>
                        <Tab>{t(`details`)}</Tab>
                        <Tab>{t(`documents`)}</Tab>
                      </TabList>
                    </CardFooter>
                  </Card>
                  <TabPanels>
                    <TabPanel>
                      <VStack spacing={4}>
                        {transaction.workflow?.conditions.map(
                          ({ condition }) => (
                            <Fragment key={condition}>
                              <WorkflowConditionCard
                                condition={condition}
                                isBuySide={isBuySide}
                                variant="full"
                              />
                            </Fragment>
                          ),
                        )}
                        {!hideSteps && (
                          <StepTabs
                            workflow={workflow}
                            isBuySide={isBuySide}
                            transaction={transaction}
                          />
                        )}
                      </VStack>
                    </TabPanel>
                    <TabPanel>
                      <MessagesCard transaction={transaction} />
                    </TabPanel>
                    <TabPanel>
                      <TransactionDetailsCard transaction={transaction} />
                    </TabPanel>
                    <TabPanel>
                      <TransactionDocumentsTab transaction={transaction} />
                    </TabPanel>
                  </TabPanels>
                </Flex>
              </Tabs>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <VStack spacing={4}>
                {workflow &&
                  workflow?.status !== ExecutionWorkflowStatus.Pending && (
                    <MilestonesCard
                      transaction={transaction}
                      milestoneOrder={workflow?.milestoneOrder}
                      milestoneCompletions={workflow?.milestoneCompletions}
                      conditions={workflow?.conditions}
                      isBuySide={isBuySide}
                    />
                  )}
                {isDesktop && (
                  <InformativeArticlesCard transaction={transaction} />
                )}
              </VStack>
            </GridItem>
          </SimpleGrid>
        </Box>
      </FullContentWrapper>
    );
  },
);

export const TransactionExecutionPage = ({ id }: { readonly id: string }) => {
  const query = useTransactionExecutionPageTransactionByIdQuery({
    variables: {
      id,
    },
  });

  return (
    <WithQuery query={query} fallback={<TransactionExecutionPageSkeleton />}>
      {({ data: { transactionById: transaction } }) => {
        if (!transaction) return null;

        return <TransactionExecutionPageContent transaction={transaction} />;
      }}
    </WithQuery>
  );
};
