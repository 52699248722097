import { Trans, useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Link, ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import {
  AcceptStandingBidModalStandingBidFragment,
  AcceptStandingBidMutation,
} from "@/gql";
import { constants, formatPricePerShare, formatShares, makeUrl } from "@/utils";

import { useAcceptStandingBidSequenceModalContext } from "./AcceptStandingBidSequenceModalContext";
import { StepKeys } from "./steps";
import { AcceptStandingBidSequenceModalFormValues } from "./types";

interface AcceptStandingBidConfirmationModalProps
  extends StepPropsV2<StepKeys, AcceptStandingBidSequenceModalFormValues> {
  readonly standingBid: AcceptStandingBidModalStandingBidFragment;
}

const AcceptStandingBidConfirmationModal = ({
  stepRouter,
  values,
  standingBid,
  isSubmitting,
}: AcceptStandingBidConfirmationModalProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { submitMutation } = useAcceptStandingBidSequenceModalContext();

  const { stepControls } = stepRouter;

  const onClickSubmit = () =>
    submitMutation().then((response: AcceptStandingBidMutation) => {
      stepControls.nextStep();

      const transaction = response.acceptStandingBid?.bid?.transaction;

      if (!transaction) return;

      router.push(makeUrl(transaction));
    });

  const numShares = formatShares(values.numShares);
  const pricePerShare = formatPricePerShare(standingBid.pricePerShare);

  return (
    <div data-testid="standing-bid-accept-confirmation-modal">
      <HiiveModalHeader>
        {t(`are_you_sure_accept`, { bidType: `bid` })}
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start">
          <Text textStyle="heading-lg">
            {`${standingBid.displayId}: ${numShares} shares @ ${pricePerShare}/sh`}
          </Text>
          <Text>
            <Trans
              i18nKey="accept_bid_terms_and_conditions"
              components={{
                italic: (
                  <Link
                    key="terms-and-conditions"
                    href={constants.t_and_c_url}
                    target="_blank"
                    textDecorationLine="underline"
                  />
                ),
              }}
            />
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[AcceptStandingBid/Cancel]"
            onCancel={stepControls.previousStep}
          />
        </Show>
        <HiiveSubmitButton
          sentryLabel="[AcceptStandingBid/Submit]"
          isLoading={isSubmitting}
          submitText={t(`yes_accept_bid`, { bidType: `bid` })}
          type="button"
          onClick={onClickSubmit}
        />
      </HiiveModalFooter>
    </div>
  );
};

export default AcceptStandingBidConfirmationModal;
