import { CardBody, CardFooter, CardHeader, HStack } from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardDisplayId,
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  BrokerBidActivityCardTimestamps,
} from "@/components/common";
import { BrokerCompanyBidCardBidFragment } from "@/gql";
import { getBidNumSharesActual, makeUrl, bidLabel } from "@/utils";

const BrokerCompanyBidCardDetails = ({
  bid,
}: {
  readonly bid: BrokerCompanyBidCardBidFragment;
}) => {
  const numShares = getBidNumSharesActual(bid);
  const price = bid.pricePerShare;

  return (
    <ActivityCardPricingWrapper>
      <ActivityCardPricingNumSharesAndPrice
        numShares={numShares}
        price={price}
      />
      <ActivityCardPricingTotal numShares={numShares} price={price} />
    </ActivityCardPricingWrapper>
  );
};

const BrokerCompanyBidCard = ({
  bid,
}: {
  readonly bid: BrokerCompanyBidCardBidFragment;
}) => {
  const title = bidLabel(bid.listing);

  return (
    <ActivityCard href={makeUrl(bid)}>
      <CardHeader>
        <HStack>
          <ActivityCardBadge variant="bid" title={title} />
          <ActivityCardDisplayId displayId={bid.displayId} />
        </HStack>
      </CardHeader>
      <CardBody>
        <BrokerCompanyBidCardDetails bid={bid} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            <BrokerBidActivityCardTimestamps bid={bid} />
          </ActivityCardFooterStatusWrapper>
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};

export default BrokerCompanyBidCard;
