import { Trans } from "react-i18next";

import { Box, HStack } from "@chakra-ui/react";

import { Indicator } from "@/gql";

import { ChartSeriesKey, ChartSeriesSetting } from "./types";

const Triangle = ({
  color,
  rotate = false,
}: {
  readonly color: string;
  readonly rotate?: boolean;
}) => (
  <Box
    w="0"
    h="0"
    borderLeft="5px solid transparent"
    borderRight="5px solid transparent"
    borderBottom="8px solid"
    borderBottomColor={color}
    position="relative"
    bottom="1px"
    transform={rotate ? `rotate(180deg)` : undefined}
  />
);

const Circle = ({ color }: { readonly color: string }) => (
  <Box
    w="10px"
    h="10px"
    border="2px solid"
    borderColor={color}
    borderRadius="50%"
  />
);

export const chartSeriesSetting: Record<ChartSeriesKey, ChartSeriesSetting> = {
  lastRoundPrice: {
    color: `grey.200`,
    translation: `last_primary_fundraise`,
    indicator: (
      <HStack w="10px" spacing="1px">
        <Box w="2px" h="3px" backgroundColor="grey.200" />
        <Box w="4px" h="3px" backgroundColor="grey.200" />
        <Box w="2px" h="3px" backgroundColor="grey.200" />
      </HStack>
    ),
  },
  acceptedBids: {
    color: `skyBlue.1300`,
    translation: `accepted_bid`,
    indicator: <Circle color="skyBlue.1300" />,
  },
  indexPrice: {
    color: `skyBlue.1100`,
    translation: `aggregate`,
    indicator: <Box w="10px" h="3px" backgroundColor="skyBlue.1100" />,
  },
  acceptedBidsShareTypeCommon: {
    color: `chart.commonBid`,
    translation: `accepted_bid`,
    indicator: <Circle color="chart.commonBid" />,
  },
  indexPriceShareTypeCommon: {
    color: `chart.common`,
    translation: `common`,
    indicator: <Box w="10px" h="3px" backgroundColor="chart.common" />,
  },
  acceptedBidsTransferTypeDirect: {
    color: `chart.directBid`,
    translation: `accepted_bid`,
    indicator: <Circle color="chart.directBid" />,
  },
  indexPriceTransferTypeDirect: {
    color: `chart.direct`,
    translation: `direct`,
    indicator: <Box w="10px" h="3px" backgroundColor="chart.direct" />,
  },
  acceptedBidsShareTypePreferred: {
    color: `chart.preferredBid`,
    translation: `accepted_bid`,
    indicator: <Circle color="chart.preferredBid" />,
  },
  indexPriceShareTypePreferred: {
    color: `chart.preferred`,
    translation: `preferred`,
    indicator: <Box w="10px" h="3px" backgroundColor="chart.preferred" />,
  },
  acceptedBidsTransferTypeIndirect: {
    color: `chart.indirectBid`,
    translation: `accepted_bid`,
    indicator: <Circle color="chart.indirectBid" />,
  },
  indexPriceTransferTypeIndirect: {
    color: `chart.indirect`,
    translation: `indirect`,
    indicator: <Box w="10px" h="3px" backgroundColor="chart.indirect" />,
  },
  postedAsks: {
    color: `chart.ask`,
    translation: `posted_ask`,
    indicator: <Triangle rotate color="chart.ask" />,
  },
  postedBids: {
    color: `chart.bid`,
    translation: `posted_bid`,
    indicator: <Triangle color="chart.bid" />,
  },
  postedAsksShareTypePreferred: {
    color: `chart.ask`,
    translation: `posted_ask`,
    indicator: <Triangle rotate color="chart.ask" />,
  },
  postedBidsShareTypePreferred: {
    color: `chart.bid`,
    translation: `posted_bid`,
    indicator: <Triangle color="chart.bid" />,
  },
  postedAsksTransferTypeDirect: {
    color: `chart.ask`,
    translation: `posted_ask`,
    indicator: <Triangle rotate color="chart.ask" />,
  },
  postedBidsTransferTypeDirect: {
    color: `chart.bid`,
    translation: `posted_bid`,
    indicator: <Triangle color="chart.bid" />,
  },
  postedAsksTransferTypeIndirect: {
    color: `chart.ask`,
    translation: `posted_ask`,
    indicator: <Triangle rotate color="chart.ask" />,
  },
  postedBidsTransferTypeIndirect: {
    color: `chart.bid`,
    translation: `posted_bid`,
    indicator: <Triangle color="chart.bid" />,
  },
  postedAsksShareTypeCommon: {
    color: `chart.ask`,
    translation: `posted_ask`,
    indicator: <Triangle rotate color="chart.ask" />,
  },
  postedBidsShareTypeCommon: {
    color: `chart.bid`,
    translation: `posted_bid`,
    indicator: <Triangle color="chart.bid" />,
  },
};

// used to determine if indexPrice is shown as line or area
export const EXCLUDING_INDICATORS = Object.values(Indicator).filter(
  (value) =>
    ![
      Indicator.AcceptedBids,
      Indicator.IndexPrice,
      Indicator.LastRoundPrice,
    ].includes(value),
);

export const TREND_LINES_CONTENT = {
  [Indicator.IndexPrice]: {
    color: `skyBlue.1100`,
    label: <Trans i18nKey="aggregate" />,
    description: <Trans i18nKey="aggregate_description" />,
  },
  [Indicator.IndexPriceTransferTypeDirect]: {
    color: `chart.direct`,
    label: <Trans i18nKey="direct" />,
    description: <Trans i18nKey="old_direct_description" />,
  },
  [Indicator.IndexPriceTransferTypeIndirect]: {
    color: `chart.indirect`,
    label: <Trans i18nKey="indirect" />,
    description: <Trans i18nKey="old_indirect_description" />,
  },
  [Indicator.IndexPriceShareTypeCommon]: {
    color: `chart.common`,
    label: <Trans i18nKey="common" />,
    description: <Trans i18nKey="common_description" />,
  },
  [Indicator.IndexPriceShareTypePreferred]: {
    color: `chart.preferred`,
    label: <Trans i18nKey="preferred" />,
    description: <Trans i18nKey="preferred_description" />,
  },
};

export const TREND_LINES_TO_OPTIONS = {
  [Indicator.IndexPrice]: `aggregate`,
  [Indicator.IndexPriceTransferTypeDirect]: `transferTypeDirect`,
  [Indicator.IndexPriceTransferTypeIndirect]: `transferTypeIndirect`,
  [Indicator.IndexPriceShareTypeCommon]: `shareTypeCommon`,
  [Indicator.IndexPriceShareTypePreferred]: `shareTypePreferred`,
};
