import { CheckCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  HStack,
  Text,
  VStack,
  Badge,
  Box,
  Image,
  Card,
} from "@chakra-ui/react";

import { ModernTreasuryVerifyAccountCardBankAccountFragment } from "@/gql";
import { useColors } from "@/hooks";
import { toShortDate } from "@/utils/datetime";

export const LastTransactionBadge = ({
  transaction,
}: {
  readonly transaction: {
    readonly friendlyId: string;
    readonly company: {
      readonly logoUrl?: string;
      readonly name: string;
    };
  };
}) => {
  const [grey900] = useColors([`grey.900`]);
  return (
    <Badge
      as={HStack}
      variant="ghost"
      bg="white"
      borderColor="grey.75"
      borderWidth="1px"
    >
      {transaction.company.logoUrl && (
        <Box h="4" minW="4">
          <Image
            h="full"
            src={transaction.company.logoUrl}
            alt={transaction.company.name}
          />
        </Box>
      )}
      <Text textStyle="text-sm" color={grey900}>
        {transaction.friendlyId}
      </Text>
    </Badge>
  );
};

/**
 * Displays details of a user's bank account.
 */
const BankAccountCard = ({
  bankAccount,
  displayInsertedAt = true,
  displayLastTransaction,
  fullSize = true,
}: {
  readonly bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment;
  readonly displayInsertedAt?: boolean;
  readonly displayLastTransaction?: boolean;
  readonly fullSize?: boolean;
}) => {
  const { t } = useTranslation();
  const [grey900, grey700, grey500] = useColors([
    `grey.900`,
    `grey.700`,
    `grey.500`,
  ]);

  return (
    <Card shadow={0} bg={fullSize ? `white` : `grey.25`}>
      <HStack justifyContent="space-between" m={4}>
        {fullSize ? (
          <HStack>
            <CheckCircle weight="fill" size="20px" />
            <VStack gap={0} alignItems="start">
              <Text
                textStyle="heading-md"
                color={grey900}
              >{t`verified_account`}</Text>
              <Text textStyle="text-md" color={grey700}>
                {t(`bank_accounts.redacted`, {
                  ns: `postings`,
                })}
                {` `}
                {bankAccount.last4}
              </Text>
            </VStack>
          </HStack>
        ) : (
          <HStack>
            <Text textStyle="heading-md" color={grey900}>{t`account`}</Text>
            <Text textStyle="text-md" color={grey700}>
              {t(`bank_accounts.redacted`, {
                ns: `postings`,
              })}
              {` `}
              {bankAccount.last4}
            </Text>
          </HStack>
        )}
        {displayInsertedAt && (
          <Text textStyle="text-sm" color={grey500}>{`${t`added`} ${toShortDate(
            bankAccount.insertedAt,
          )}`}</Text>
        )}
        {displayLastTransaction && bankAccount.lastUsedTransaction && (
          <HStack alignItems="center">
            <Text
              textStyle="text-sm"
              color={grey700}
            >{t`used_for_last_transaction`}</Text>
            <LastTransactionBadge
              transaction={{
                friendlyId: bankAccount.lastUsedTransaction.bid.displayId,
                company: {
                  logoUrl:
                    bankAccount.lastUsedTransaction.company.logoUrl || ``,
                  name: bankAccount.lastUsedTransaction.company.name,
                },
              }}
            />
          </HStack>
        )}
      </HStack>
    </Card>
  );
};

export default BankAccountCard;
