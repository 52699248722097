import { useCurrentContextQuery } from "@/gql";

import IndividualActiveTransactionsCard from "./IndividualActiveTransactionsCard";
import InstitutionActiveTransactionsCard from "./InstitutionActiveTransactionsCard";

const ActiveTransactionsCard = () => {
  const { data } = useCurrentContextQuery();
  const currentActor = data?.currentContext?.currentActor;
  const isInstitution = !!currentActor?.institutionId;

  return isInstitution ? (
    <InstitutionActiveTransactionsCard />
  ) : (
    <IndividualActiveTransactionsCard />
  );
};

export default ActiveTransactionsCard;
