import { useField } from "formik";
import difference from "lodash/difference";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
} from "@chakra-ui/react";

import { CustomCheckbox } from "@/components/common";
import { StandingBidAcceptsTransferMethodsOptionsV2 } from "@/gql";
import { useOtherTransferMethod } from "@/hooks/featureFlags";

const includesAll = (superset: readonly string[], subset: readonly string[]) =>
  difference(subset, superset).length === 0;

const StandingBidAcceptedTransferMethods = ({
  name,
  label = `Which transfer types are you interested in?`,
}: {
  readonly name: string;
  readonly label?: string;
}) => {
  const { t } = useTranslation();
  const useOtherMethod = useOtherTransferMethod();

  const [field, { touched, error }, { setValue }] = useField(name);

  const value = field.value as readonly string[];

  const getIsChecked = (options: readonly string[]) =>
    includesAll(value, options);

  const onChange =
    (options: readonly string[]) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue([...value, ...options]);

        return;
      }

      setValue(value.filter((x) => !options.includes(x)));
    };

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {useOtherMethod ? (
        <Stack spacing={2}>
          <CustomCheckbox
            spacing={2}
            isChecked
            isDisabled
            onChange={onChange([
              StandingBidAcceptsTransferMethodsOptionsV2.Direct,
            ])}
          >
            {t(`direct`)}
            <span style={{ fontSize: `.75em` }}>
              {` `}
              {t(`direct_text`)}
            </span>
          </CustomCheckbox>
          <CustomCheckbox
            spacing={2}
            isChecked={getIsChecked([
              StandingBidAcceptsTransferMethodsOptionsV2.Fund,
            ])}
            onChange={onChange([
              StandingBidAcceptsTransferMethodsOptionsV2.Fund,
            ])}
          >
            {t(`fund`)}
            <span style={{ fontSize: `.75em` }}>
              {` `}
              {t(`fund_text`)}
            </span>
          </CustomCheckbox>
          <CustomCheckbox
            spacing={2}
            isChecked={getIsChecked([
              StandingBidAcceptsTransferMethodsOptionsV2.Other,
            ])}
            onChange={onChange([
              StandingBidAcceptsTransferMethodsOptionsV2.Other,
            ])}
          >
            {t(`other`)}
            <span style={{ fontSize: `.75em` }}>
              {` `}
              {t(`other_text`)}
            </span>
          </CustomCheckbox>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <CustomCheckbox
            spacing={2}
            isChecked={getIsChecked([
              StandingBidAcceptsTransferMethodsOptionsV2.ForwardContract,
            ])}
            onChange={onChange([
              StandingBidAcceptsTransferMethodsOptionsV2.ForwardContract,
            ])}
          >
            Forward contract
          </CustomCheckbox>
          <CustomCheckbox
            spacing={2}
            isChecked={getIsChecked([
              StandingBidAcceptsTransferMethodsOptionsV2.Fund,
            ])}
            onChange={onChange([
              StandingBidAcceptsTransferMethodsOptionsV2.Fund,
            ])}
          >
            Fund
          </CustomCheckbox>
        </Stack>
      )}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default StandingBidAcceptedTransferMethods;
