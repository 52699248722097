/* eslint-disable @typescript-eslint/no-explicit-any */
import { BoxProps } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";

import { ConfirmationProps } from "./FormFooter";
import FormFooterWrapper from "./FormFooterWrapper";

interface FormFooterWithConfirmationProps extends BoxProps {
  readonly onShowConfirm?: () => void;
  readonly submitText?: string;
  readonly cancelText?: string;
  readonly onCancel?: () => void;
  readonly disableSubmit?: boolean;
  readonly confirmationProps: ConfirmationProps;
  readonly hideSubmit?: boolean;
  readonly formName: string;
}

export const FormFooterWithConfirmation = ({
  submitText = `Submit`,
  cancelText = `Cancel`,
  onCancel,
  disableSubmit,
  confirmationProps,
  hideSubmit,
  formName,
  ...boxProps
}: FormFooterWithConfirmationProps) => {
  const { onShowConfirm, validateForm, setErrors, setFieldTouched } =
    confirmationProps;

  const handleSubmit = async () => {
    const errors = await validateForm();
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      setErrors(errors);
      Object.keys(errors).forEach((field) => setFieldTouched(field)); // won't display errors unless touched is set to true
      return;
    }

    onShowConfirm();
  };

  return (
    <FormFooterWrapper {...boxProps}>
      {onCancel && (
        <HiiveButton
          onClick={onCancel}
          size="md"
          variant="rounded-outline-grey"
          sentryLabel={`[${formName}/FormFooterWithConfirmation] Cancel`}
        >
          {cancelText}
        </HiiveButton>
      )}
      {!hideSubmit && (
        <HiiveButton
          onClick={handleSubmit}
          size="md"
          variant="rounded-solid-salmon"
          isDisabled={disableSubmit}
          sentryLabel={`[${formName}/FormFooterWithConfirmation] Submit`}
        >
          {submitText}
        </HiiveButton>
      )}
    </FormFooterWrapper>
  );
};
