import { useMemo } from "react";

import { CardBody, CardFooter, CardHeader, Flex, Text } from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardFooterWrapper,
  ActivityCardMostRecentMessage,
} from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyPageYourActivityDiscussionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  getDiscussionHasCounterpartyMessage,
  getDiscussionStatusText,
  getPostingTitle,
} from "@/utils";

const YourDiscussionCard = ({
  discussion,
  actor,
}: {
  readonly discussion: CompanyPageYourActivityDiscussionFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const hasCounterpartyMessage = getDiscussionHasCounterpartyMessage(
    actor,
    discussion,
  );

  const discussionStatus = useMemo(
    () => getDiscussionStatusText(discussion),
    [discussion.state],
  );

  const postingTitle = `${getPostingTitle(discussion.topic)} inquiry`;

  const hasMessages = discussion.totalNumMessages > 0;

  return (
    <ActivityCard href={`/discussions/${discussion.id}`}>
      <CardHeader>
        <Flex alignItems="center" gap={2}>
          <ActivityCardBadge
            flexBasis={{ base: `min-content`, sm: `auto` }}
            variant={
              discussion.topic.__typename === `Listing` ? `listing` : `bid`
            }
            title={postingTitle}
          />
          <Text textStyle="text-lg" whiteSpace="nowrap">
            {discussion.topic.displayId}
          </Text>
          <Text ml="auto" textStyle="text-xs" whiteSpace="nowrap">
            {discussion.displayId}
          </Text>
        </Flex>
      </CardHeader>
      {hasCounterpartyMessage && !!discussion.mostRecentMessage && (
        <CardBody>
          <ActivityCardMostRecentMessage discussion={discussion} />
        </CardBody>
      )}
      <CardFooter>
        <ActivityCardFooterWrapper>
          <Text textStyle="text-sm">{discussionStatus}</Text>
          {hasMessages && (
            <MessagesBadge
              numUnreadMessages={discussion.numUnreadMessages}
              totalNumMessages={discussion.totalNumMessages}
            />
          )}
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};

export default withCurrentActor(YourDiscussionCard);
