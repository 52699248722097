"use client";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

const BOT_PATTERN = `(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)`;

const IGNORED_ERROR_MESSAGES = [
  `Network Error`,
  `unauthorized`,
  `status code 401`,
  `websocket error`,
];

const regex = new RegExp(BOT_PATTERN, `i`);
const conditionalSampleRate = regex.test(globalThis.navigator?.userAgent || ``)
  ? 0
  : 100;

if (
  process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID != null &&
  process.env.NEXT_PUBLIC_INFRA_ENV != null
) {
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    env: process.env.NEXT_PUBLIC_INFRA_ENV,
    forwardConsoleLogs: [`warn`, `error`],
    forwardErrorsToLogs: true,
    forwardReports: `all`,
    service: `client`,
    sessionSampleRate: conditionalSampleRate,
    site: `datadoghq.com`,
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  });

  datadogRum.init({
    allowedTracingUrls: [
      {
        match: `https://api.hiive.com`,
        propagatorTypes: [`tracecontext`],
      },
      {
        match: /https:\/\/.*\.hiive\.dev/,
        propagatorTypes: [`tracecontext`],
      },
    ],
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    beforeSend: (event) => {
      if (event.type === `error`) {
        return IGNORED_ERROR_MESSAGES.some((message) =>
          event.error.message.includes(message),
        );
      }
      return true;
    },
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    defaultPrivacyLevel: `mask-user-input`,
    enableExperimentalFeatures: [`feature_flags`],
    enablePrivacyForActionName: true,
    env: process.env.NEXT_PUBLIC_INFRA_ENV,
    service: `client`,
    sessionReplaySampleRate: 100,
    sessionSampleRate: conditionalSampleRate,
    site: `datadoghq.com`,
    trackLongTasks: true,
    trackResources: true,
    trackSessionAcrossSubdomains: true,
    trackUserInteractions: true,
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  });
}

export default function DatadogInit() {
  return null;
}
