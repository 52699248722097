import { List } from "@phosphor-icons/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box, HStack, useToken } from "@chakra-ui/react";

import { Logo, InternalLink } from "@/components/common";
import { NotificationMenu, useHoldingSwitcher } from "@/components/nav";
import { useLockBodyScroll, useTabRouter } from "@/hooks";

import { MobileNavBarWrapper } from "./MobileNavBarWrapper";
import { UnaccreditedSellerNavigationTab } from "./UnaccreditedSellerNavigationTab";
import { tabKeys, TabKeys } from "./tabs";

const UnaccreditedSellerMobileNavBar = () => {
  const [grey900] = useToken(`colors`, [`grey.900`]);
  const { t } = useTranslation();
  const tabRouter = useTabRouter<TabKeys>({ initialTab: tabKeys.index });
  const { holdingCompany } = useHoldingSwitcher();
  const { onLock, onUnlock } = useLockBodyScroll();

  const { tabsInfo, tabControls } = tabRouter;
  const showNavigationMenu = tabsInfo.currentTabKey === tabKeys.navigation;

  useEffect(() => {
    if (showNavigationMenu) {
      onLock();
      return;
    }
    onUnlock();
  }, [tabsInfo.currentTabKey]);

  if (!holdingCompany) return null;

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex="modal"
      data-dd-privacy="mask"
    >
      {showNavigationMenu ? (
        <UnaccreditedSellerNavigationTab tabRouter={tabRouter} />
      ) : (
        <MobileNavBarWrapper>
          <HStack spacing={0} gap={4}>
            <Box
              as="button"
              onClick={() => tabControls.jumpToTab(`navigation`)}
            >
              <List size={36} color={grey900} />
            </Box>
            <InternalLink
              href={`/dashboard/${holdingCompany.companyId}`}
              aria-label={t(`hiive`)}
            >
              <Logo.Grey />
            </InternalLink>
          </HStack>
          <HStack spacing={0} gap={4}>
            <NotificationMenu />
          </HStack>
        </MobileNavBarWrapper>
      )}
    </Box>
  );
};

export default UnaccreditedSellerMobileNavBar;
