/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import { useField } from "formik";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  GridItem,
  HStack,
  ModalBody,
  Show,
  SimpleGrid,
  Text,
  VStack,
  Radio,
} from "@chakra-ui/react";

import {
  CompanyEligibilityCriteriaCheckbox,
  HiiveAdvancedOptionsDropdown,
  HiiveBackButton,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CheckboxInput,
  DateInput,
  EmailInput,
  OtherDetailsInput,
  QuestionTooltip,
  StepPropsV2,
  TextInput,
  FormRadioInput,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  representedEmailSchema,
  representedNameSchema,
  TransferMethodInput,
} from "@/components/postings";
import {
  RepresentedListingAdditionalListingNotesTooltip,
  RepresentedUserTooltip,
} from "@/components/tooltip";
import {
  CreateListingMutation,
  ShareSeries,
  TransferMethodV2,
  UserWithInstitutionFragment,
} from "@/gql";
import { useModal, useStepValidator } from "@/hooks";
import { fromBool, toBool } from "@/utils";

import { CreateListingSequenceModalFormValues } from ".";
import {
  CreateListingSequenceModalStepFormContext,
  useCreateListingSequenceModalStepFormContext,
} from "./CreateListingSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";

interface AdditionalDetailsProps
  extends StepPropsV2<StepKeys, CreateListingSequenceModalFormValues> {
  readonly actor: UserWithInstitutionFragment;
}

interface ValidationSchemaOptions {
  readonly isEligibilityRequirementsCheckboxRequired: boolean;
  readonly isInformationDisclosureCheckboxRequired: boolean;
}

export const createValidationSchema = (
  actor: UserWithInstitutionFragment,
  options: ValidationSchemaOptions,
) =>
  Yup.object().shape({
    transferMethodV2: Yup.string().required(`Required`),
    confirmedEligibilityRequirements: Yup.boolean().when([], {
      is: () => options.isEligibilityRequirementsCheckboxRequired,
      then: Yup.boolean().oneOf([true], `Required`),
    }),
    confirmedInformationDisclosure: Yup.boolean().when([], {
      is: () => options.isInformationDisclosureCheckboxRequired,
      then: Yup.boolean().oneOf([true], `Required`),
    }),
    confirmed: Yup.boolean().oneOf([true], `Required`),
    hasExpirationDate: Yup.bool().required(`Required`),
    expireAt: Yup.string()
      .nullable()
      .test({
        name: `checkRequired`,
        message: `Required`,
        test: function () {
          if (this.parent.hasExpirationDate) {
            return !!this.parent.expireAt;
          }
          return true;
        },
      }),
    otherDetails: Yup.string().nullable(),
    muteNotifyWatchers: Yup.boolean().nullable(),
    manualSigningProcedure: Yup.boolean(),
    representedEmail: representedEmailSchema(actor),
    representedFirstName: representedNameSchema(actor),
    representedLastName: representedNameSchema(actor),
    notifyRepresentingEmail: Yup.boolean().required(`Required`),
  });

export const AdditionalDetails = withCurrentActor(
  ({ actor, values, stepRouter, isSubmitting }: AdditionalDetailsProps) => {
    const { closeModal } = useModal();
    const { stepControls } = stepRouter;
    const router = useRouter();
    const { t } = useTranslation();

    const { submitMutation } = useCreateListingSequenceModalStepFormContext();
    const { company, shareSeriesMakeup } = values;

    const onSuccess = () =>
      submitMutation().then((response: CreateListingMutation) => {
        const newListingId = response.createListing.listing?.id;
        if (!newListingId) return;
        stepControls.nextStep();
        router.push(`/listings/${newListingId}`);
      });

    const requiresInformationDisclosure =
      !!company?.requiresInformationDisclosure;

    const eligibilityCriterionText = company?.companyEligibilityCriterion?.text;
    const onlyCommonShares = shareSeriesMakeup.every(
      (shareSeriesMakeupElement) =>
        shareSeriesMakeupElement.shareSeries === ShareSeries.Common,
    );
    const showConfirmEligibilityRequirementsCheckbox =
      !isNil(eligibilityCriterionText) && onlyCommonShares;

    const validationSchema = createValidationSchema(actor, {
      isEligibilityRequirementsCheckboxRequired:
        showConfirmEligibilityRequirementsCheckbox,
      isInformationDisclosureCheckboxRequired: requiresInformationDisclosure,
    });

    useStepValidator({
      Context: CreateListingSequenceModalStepFormContext,
      stepKey: stepKeys.createListingAdditionalDetails,
      validator: {
        validationSchema,
        onSuccess,
      },
      values,
    });

    const [_field, _, { setValue: setManualSigningProcedure }] = useField(
      `manualSigningProcedure`,
    );

    const isManual = (transferMethodV2: TransferMethodV2) =>
      [
        TransferMethodV2.ForwardContract,
        TransferMethodV2.Fund,
        TransferMethodV2.HiiveFund,
        TransferMethodV2.Other,
      ].includes(transferMethodV2);

    const isDisabled = isManual(values.transferMethodV2);

    const handleChangeTransferMethod = (transferMethodV2: TransferMethodV2) => {
      if (isManual(transferMethodV2)) {
        setManualSigningProcedure(true);
      }
    };

    return (
      <>
        <ModalBody>
          <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
            <GridItem colSpan={2} data-testid="transfer-method-input">
              <TransferMethodInput
                onChange={handleChangeTransferMethod}
                name="transferMethodV2"
                label="How will these be transferred?"
              />
            </GridItem>
            <GridItem colSpan={2}>
              <FormRadioInput
                label={t(`end_date_question`)}
                name="hasExpirationDate"
                mapper={{ from: fromBool, to: toBool }}
                data-testid="create-listing-end-date-question"
                tooltipContent={t(
                  `listing_additional_details_expiry_date_tooltip`,
                )}
              >
                <HStack spacing={12}>
                  <Radio variant="base" value="true">
                    {t(`yes`)}
                  </Radio>
                  <Radio variant="base" value="false">
                    {t(`no`)}
                  </Radio>
                </HStack>
              </FormRadioInput>
            </GridItem>
            {values.hasExpirationDate && (
              <GridItem colSpan={2}>
                <DateInput
                  name="expireAt"
                  label={t(`end_date_input_label`)}
                  tooltipContent={t(`end_date_input_tooltip`)}
                />
              </GridItem>
            )}
            <GridItem colSpan={2}>
              <OtherDetailsInput
                name="otherDetails"
                label="Additional listing notes"
                placeholder="Add details"
                tooltipContent={
                  <RepresentedListingAdditionalListingNotesTooltip />
                }
              />
            </GridItem>
            {actor.isHiiveUser && (
              <GridItem colSpan={2}>
                <HiiveAdvancedOptionsDropdown
                  validationSchema={validationSchema}
                  fieldNames={[
                    `representedEmail`,
                    `representedFirstName`,
                    `representedLastName`,
                    `manualSigningProcedure`,
                    `muteNotifyWatchers`,
                  ]}
                >
                  <VStack spacing={4} alignItems="flex-start">
                    {!!values.transferMethodV2 && (
                      <CheckboxInput
                        isDisabled={isDisabled}
                        name="manualSigningProcedure"
                        label={t(`manual_signing_procedure`)}
                      />
                    )}
                    <CheckboxInput
                      name="muteNotifyWatchers"
                      label={t(`do_not_send_activity_notifications`)}
                    />
                    <Text
                      align="center"
                      textStyle="deprecated-heading-lg"
                      color="h-dark-grey"
                    >
                      {t(`represented_user_info`)}
                      <QuestionTooltip
                        translateY={1.5}
                        translateX={1.0}
                        transform="auto"
                        tooltipContent={<RepresentedUserTooltip />}
                      />
                    </Text>
                    <HStack gap={4} w="full" alignItems="top">
                      <TextInput
                        data-testid="represented-user-first-name"
                        name="representedFirstName"
                        placeholder={t(`first_name`)}
                      />
                      <TextInput
                        data-testid="represented-user-last-name"
                        name="representedLastName"
                        placeholder={t(`last_name`)}
                      />
                    </HStack>
                    <EmailInput
                      data-testid="represented-email"
                      name="representedEmail"
                      placeholder="Email"
                      type="email"
                    />
                    <CheckboxInput
                      name="notifyRepresentingEmail"
                      label="Notify the person being represented"
                    />
                  </VStack>
                </HiiveAdvancedOptionsDropdown>
              </GridItem>
            )}
            {showConfirmEligibilityRequirementsCheckbox && (
              <GridItem colSpan={2}>
                <CompanyEligibilityCriteriaCheckbox
                  name="confirmedEligibilityRequirements"
                  label={t(`agreed_to_eligibility_requirements`, {
                    companyName: company?.name,
                  })}
                  eligibilityCriterionText={eligibilityCriterionText}
                />
              </GridItem>
            )}
            {requiresInformationDisclosure && (
              <GridItem colSpan={2}>
                <CheckboxInput
                  name="confirmedInformationDisclosure"
                  label={t(`agreed_to_issuer_disclosure`, {
                    companyName: company?.name,
                  })}
                />
              </GridItem>
            )}
            <GridItem colSpan={2}>
              <CheckboxInput
                name="confirmed"
                label={t(`create_listing_intent_for_sale`)}
              />
            </GridItem>
          </SimpleGrid>
        </ModalBody>
        <HiiveModalFooter>
          <Show above="md" ssr={false}>
            <HiiveCancelButton
              sentryLabel="[CreateListing/AdditionalDetails/Cancel]"
              onCancel={closeModal}
            />
          </Show>
          <HiiveBackButton
            sentryLabel="[CreateListing/AdditionalDetails/Back]"
            onClick={stepControls.previousStep}
          />
          <HiiveSubmitButton
            sentryLabel="[CreateListing/AdditionalDetails/Submit]"
            isLoading={isSubmitting}
            submitText="Add Listing"
            type="submit"
          />
        </HiiveModalFooter>
      </>
    );
  },
);
