import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Box, BoxProps } from "@chakra-ui/react";

import { IssuerStage } from "@/gql";

function StageBadge(props: BoxProps) {
  return (
    <Box
      bg="grey.50"
      borderRadius={6}
      color="grey.900"
      display="inline-block"
      fontSize={12}
      px={1}
      py={0.5}
      {...props}
    />
  );
}

interface IssuerTransactionsStageBadgeProps {
  readonly stage: IssuerStage;
}

export function IssuerTransactionsStageBadge({
  stage,
}: IssuerTransactionsStageBadgeProps) {
  const { t } = useTranslation(`issuers`);

  return match(stage)
    .with(IssuerStage.InReview, () => (
      <StageBadge bg="yellow.100" color="yellow.800">
        {t(`in_review`)}
      </StageBadge>
    ))
    .with(IssuerStage.InProgress, () => (
      <StageBadge bg="skyBlue.100" color="teal.900">
        {t(`in_progress`)}
      </StageBadge>
    ))
    .with(IssuerStage.Cancelled, () => (
      <StageBadge bg="red.50" color="red.800">
        {t(`cancelled`)}
      </StageBadge>
    ))
    .with(IssuerStage.Closed, () => (
      <StageBadge bg="emerald.100" color="emerald.700">
        {t(`closed`)}
      </StageBadge>
    ))
    .otherwise(() => null);
}
