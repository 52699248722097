import { X } from "@phosphor-icons/react";
import { InlineWidget } from "react-calendly";
import { useTranslation } from "react-i18next";

import { Flex, IconButton, ModalBody, ModalHeader } from "@chakra-ui/react";

import { HiiveModalContentWrapper } from "@/components/common";
import { useModal } from "@/hooks";

const Header = ({ onClose }: { readonly onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <ModalHeader
      as={Flex}
      justifyContent="flex-end"
      pr={{ base: 4, md: 6 }}
      alignItems="center"
      w="full"
      bg="white"
      color="grey.900"
      border="none"
      px={0}
      py={0}
      pt={4}
    >
      <IconButton
        ml="auto"
        aria-label={t(`close`)}
        onClick={onClose}
        variant="icon-close"
        icon={<X size={32} />}
      />
    </ModalHeader>
  );
};

const CalendlyModal = ({ url }: { readonly url: string }) => {
  const { closeModal } = useModal();

  return (
    <HiiveModalContentWrapper>
      <Header onClose={closeModal} />
      <ModalBody px={0} py={0} h={{ base: `100vh`, md: `90vh` }}>
        <InlineWidget
          url={url}
          styles={{
            margin: 0,
            padding: 0,
            height: `100%`,
          }}
        />
      </ModalBody>
    </HiiveModalContentWrapper>
  );
};

export default CalendlyModal;
