export enum UploadStatus {
  Uploading = `uploading`,
  Error = `error`,
  Uploaded = `uploaded`,
}

export interface Upload {
  readonly id: string;
  readonly status: UploadStatus;
  readonly name: string;
  readonly type: string;
  readonly progress?: number;
}
