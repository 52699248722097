import { useMemo } from "react";

import { IssuerInvitationFragment, UserRole } from "@/gql";

export const useAcceptedActionMenuItems = (
  invitations: IssuerInvitationFragment[],
  invitation: IssuerInvitationFragment,
) =>
  useMemo(() => {
    const { adminCount, activeUserCount } = invitations.reduce(
      (acc, invitationUser) => {
        const isAdmin = invitationUser?.invitee?.roles.includes(UserRole.Admin);
        const isActive = invitationUser?.invitee?.isActive;

        return {
          adminCount: acc.adminCount + (isAdmin ? 1 : 0),
          activeUserCount: acc.activeUserCount + (isActive ? 1 : 0),
        };
      },
      { adminCount: 0, activeUserCount: 0 },
    );

    const hasOnlyOneAdmin = adminCount === 1;
    const hasOnlyOneActiveUser = activeUserCount === 1;
    const isAdminInvitee =
      invitation.invitee?.roles.includes(UserRole.Admin) ?? false;

    const showMakeAdmin = !isAdminInvitee;
    const showReassignAdmin =
      isAdminInvitee && hasOnlyOneAdmin && !hasOnlyOneActiveUser;
    const showRemoveAdmin = isAdminInvitee && !hasOnlyOneActiveUser;
    const showRemoveUserWarning = hasOnlyOneActiveUser;

    return {
      showMakeAdmin,
      showReassignAdmin,
      showRemoveAdmin,
      showRemoveUserWarning,
    };
  }, [invitations, invitation]);
