import { useContext } from "react";

import { IssuerTransactionPageContent } from "./IssuerTransactionPageContent";
import { IssuerTransactionPageMainPanelHeader } from "./IssuerTransactionPageMainPanelHeader";
import { IssuerTransactionStatusPageSkeleton } from "./IssuerTransactionPageSkeleton";
import {
  IssuerTransactionContext,
  IssuerTransactionContextTransaction,
} from "./IssuerTransactionProvider";
import { IssuerTransactionStatusCard } from "./IssuerTransactionStatusCard";

type IssuerTransactionStatusPageSectionsProps = {
  transaction: NonNullable<IssuerTransactionContextTransaction>;
};

function IssuerTransactionStatusPageSections({
  transaction,
}: IssuerTransactionStatusPageSectionsProps) {
  return (
    <>
      <IssuerTransactionPageMainPanelHeader transaction={transaction} />
      <IssuerTransactionStatusCard transaction={transaction} />
    </>
  );
}

function IssuerTransactionStatusPage() {
  const { transaction } = useContext(IssuerTransactionContext);

  return (
    <IssuerTransactionPageContent>
      {transaction ? (
        <IssuerTransactionStatusPageSections transaction={transaction} />
      ) : (
        <IssuerTransactionStatusPageSkeleton />
      )}
    </IssuerTransactionPageContent>
  );
}

export default IssuerTransactionStatusPage;
