import { List } from "@phosphor-icons/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Box, HStack, useToken } from "@chakra-ui/react";

import { Logo, TabProps, InternalLink } from "@/components/common";
import { EnforceAppMfaConfigurationContext } from "@/components/layouts";
import { NotificationMenu } from "@/components/nav";
import { InvestorType, UserWithInstitutionFragment } from "@/gql";
import { hasInvestorType } from "@/utils";

import { MobileNavBarWrapper } from "./MobileNavBarWrapper";
import { SearchButton } from "./SearchButton";
import { TabKeys } from "./tabs";

interface IndexTabProps extends TabProps<TabKeys> {
  readonly actor: UserWithInstitutionFragment;
}

export const IndexTab = ({
  actor,
  tabRouter: { tabControls },
}: IndexTabProps) => {
  const { t } = useTranslation();
  const [grey900] = useToken(`colors`, [`grey.900`]);

  const isSeller = hasInvestorType(actor, InvestorType.Seller);
  const isTrader = hasInvestorType(actor, InvestorType.Trader);
  const isBroker = hasInvestorType(actor, InvestorType.Broker);

  const showNotificationAndSearchComponents = !useContext(
    EnforceAppMfaConfigurationContext,
  );

  return (
    <MobileNavBarWrapper>
      <HStack spacing={0} gap={4}>
        <Box as="button" onClick={() => tabControls.jumpToTab(`navigation`)}>
          <List size={36} color={grey900} />
        </Box>
        {isBroker ? (
          <InternalLink href="/dashboard" aria-label={t(`hiive_connect`)}>
            <Logo.ConnectGrey />
          </InternalLink>
        ) : (
          <InternalLink href="/dashboard" aria-label={t(`hiive`)}>
            <Logo.Grey />
          </InternalLink>
        )}
      </HStack>
      {showNotificationAndSearchComponents && (
        <HStack spacing={0} gap={4}>
          <NotificationMenu />
          {(isSeller || isTrader || isBroker) && (
            <SearchButton onClick={() => tabControls.jumpToTab(`search`)} />
          )}
        </HStack>
      )}
    </MobileNavBarWrapper>
  );
};
