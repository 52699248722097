import { useTranslation } from "react-i18next";

import { CardBody, Text, HStack, VStack } from "@chakra-ui/react";

import { useCurrentActor } from "@/hooks";

import AccreditationValuesContainer from "./AccreditationValuesContainer";

const IndividualAccreditationCardBody = ({
  onModify,
}: {
  readonly onModify: () => void;
}) => {
  const { t } = useTranslation();
  const actor = useCurrentActor();

  const { accreditationAnswers } = actor;

  const accreditationAnswer = accreditationAnswers[0];

  return (
    <CardBody>
      <AccreditationValuesContainer onModify={onModify}>
        <VStack alignItems="flex-start" gap={6}>
          <Text textStyle="heading-lg">{t`must_be_accredited`}</Text>
          <HStack alignItems="flex-start">
            <Text textStyle="heading-md">{t`answer`}:</Text>
            <Text>{accreditationAnswer.question.text}</Text>
          </HStack>
        </VStack>
      </AccreditationValuesContainer>
    </CardBody>
  );
};

export default IndividualAccreditationCardBody;
