import React, {
  createContext,
  useState,
  ReactNode,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { match } from "ts-pattern";

import {
  AvailableTimeRanges,
  DailyPriceData,
  GraphIndicators,
  IndexPriceTrend,
  Indicator,
  PriceLines,
  useCompanyPriceDataQuery,
} from "@/gql";

import { ChartDailyPriceData, ChartSeriesKey, OptionsState } from "./types";
import {
  getStartDate,
  mapCompanyPriceData,
  getVisibleSeries,
  getSelectedTrendLinesCount,
  getDisplayedPriceTrendsCount,
} from "./utils";

const initialOptionsState: OptionsState = {
  aggregate: {
    available: true,
    selected: true,
  },
  transferTypeDirect: {
    available: false,
    selected: false,
  },
  transferTypeIndirect: {
    available: false,
    selected: false,
  },
  shareTypeCommon: {
    available: false,
    selected: false,
  },
  shareTypePreferred: {
    available: false,
    selected: false,
  },
  acceptedBids: {
    available: false,
    selected: false,
  },
  postedBidsAsks: {
    available: false,
    selected: false,
  },
  lastRoundPps: {
    available: false,
    selected: false,
  },
};

const initialSelectedIndicators: readonly Indicator[] = [Indicator.IndexPrice];

// this is explicitly defined as BE supports more indicators than we have in the UI and ts complains otherwise
const AVAILABLE_INDICATORS = [
  Indicator.IndexPrice,
  Indicator.IndexPriceTransferTypeDirect,
  Indicator.IndexPriceTransferTypeIndirect,
  Indicator.IndexPriceShareTypeCommon,
  Indicator.IndexPriceShareTypePreferred,
  Indicator.AcceptedBids,
  Indicator.AcceptedBidsTransferTypeDirect,
  Indicator.AcceptedBidsTransferTypeIndirect,
  Indicator.AcceptedBidsShareTypeCommon,
  Indicator.AcceptedBidsShareTypePreferred,
  Indicator.PostedBidsAsks,
  Indicator.PostedBidsAsksTransferTypeDirect,
  Indicator.PostedBidsAsksTransferTypeIndirect,
  Indicator.PostedBidsAsksShareTypeCommon,
  Indicator.PostedBidsAsksShareTypePreferred,
  Indicator.LastRoundPrice,
];
interface PricingChartContextProps {
  readonly startDate: string;
  readonly range: AvailableTimeRanges;
  readonly setRange: (range: AvailableTimeRanges) => void;
  readonly selectedIndicators: readonly Indicator[];
  readonly selectedTrendLinesCount: number;
  readonly displayedPriceTrendsCount: number;
  readonly data: readonly ChartDailyPriceData[];
  readonly loading: boolean;
  readonly priceTrends: readonly IndexPriceTrend[];
  readonly visibleSeries: readonly ChartSeriesKey[];
  readonly optionsState: OptionsState;
  readonly setOptionsState: (state: OptionsState) => void;
  readonly handleOptionToggle: (option: keyof OptionsState) => void;
  readonly handleOptionToggleOff: (option: keyof OptionsState) => void;
  readonly handleResetOptions: () => void;
  readonly handleApplyOptions: () => void;
}

export const PricingChartContext = createContext<PricingChartContextProps>({
  startDate: ``,
  range: AvailableTimeRanges.Max,
  setRange: () => null,
  selectedIndicators: initialSelectedIndicators,
  selectedTrendLinesCount: 0,
  displayedPriceTrendsCount: 0,
  data: [],
  loading: false,
  priceTrends: [],
  visibleSeries: [],
  optionsState: initialOptionsState,
  setOptionsState: () => null,
  handleOptionToggle: () => null,
  handleOptionToggleOff: () => null,
  handleResetOptions: () => null,
  handleApplyOptions: () => null,
});

export const PricingChartProvider = ({
  id,
  maxDate,
  priceLines,
  graphIndicators,
  children,
}: {
  readonly id: string;
  readonly maxDate: string;
  readonly priceLines: PriceLines;
  readonly graphIndicators: GraphIndicators;
  readonly children: ReactNode;
}) => {
  const [optionsState, setOptionsState] =
    useState<OptionsState>(initialOptionsState);
  const [range, setRange] = useState<AvailableTimeRanges>(
    AvailableTimeRanges.Max,
  );
  const [selectedIndicators, setSelectedIndicators] = useState<
    readonly Indicator[]
  >([]);

  const startDate = getStartDate({
    range,
    maxDate,
  });

  const handleOptionToggle = useCallback(
    (option: keyof OptionsState) => {
      setOptionsState((prev) => ({
        ...prev,
        [option]: {
          ...prev[option],
          selected: !prev[option].selected,
        },
      }));
    },
    [setOptionsState],
  );

  const handleOptionToggleOff = useCallback(
    (option: keyof OptionsState) => {
      setOptionsState((prev) => ({
        ...prev,
        [option]: {
          ...prev[option],
          selected: false,
        },
      }));
    },
    [setOptionsState],
  );

  const isIndicatorSelected = (
    indicator: Indicator,
    optionsState: OptionsState,
  ) => {
    const {
      aggregate,
      transferTypeDirect,
      transferTypeIndirect,
      shareTypeCommon,
      shareTypePreferred,
      acceptedBids,
      postedBidsAsks,
      lastRoundPps,
    } = optionsState;

    return match(indicator)
      .with(Indicator.IndexPrice, () => aggregate.selected)
      .with(
        Indicator.IndexPriceTransferTypeDirect,
        () => transferTypeDirect.selected,
      )
      .with(
        Indicator.IndexPriceTransferTypeIndirect,
        () => transferTypeIndirect.selected,
      )
      .with(Indicator.IndexPriceShareTypeCommon, () => shareTypeCommon.selected)
      .with(
        Indicator.IndexPriceShareTypePreferred,
        () => shareTypePreferred.selected,
      )
      .with(
        Indicator.AcceptedBids,
        () => acceptedBids.selected && aggregate.selected,
      )
      .with(
        Indicator.AcceptedBidsTransferTypeDirect,
        () => acceptedBids.selected && transferTypeDirect.selected,
      )
      .with(
        Indicator.AcceptedBidsTransferTypeIndirect,
        () => acceptedBids.selected && transferTypeIndirect.selected,
      )
      .with(
        Indicator.AcceptedBidsShareTypeCommon,
        () => acceptedBids.selected && shareTypeCommon.selected,
      )
      .with(
        Indicator.AcceptedBidsShareTypePreferred,
        () => acceptedBids.selected && shareTypePreferred.selected,
      )
      .with(
        Indicator.PostedBidsAsks,
        () => postedBidsAsks.selected && aggregate.selected,
      )
      .with(
        Indicator.PostedBidsAsksTransferTypeDirect,
        () => postedBidsAsks.selected && transferTypeDirect.selected,
      )
      .with(
        Indicator.PostedBidsAsksTransferTypeIndirect,
        () => postedBidsAsks.selected && transferTypeIndirect.selected,
      )
      .with(
        Indicator.PostedBidsAsksShareTypeCommon,
        () => postedBidsAsks.selected && shareTypeCommon.selected,
      )
      .with(
        Indicator.PostedBidsAsksShareTypePreferred,
        () => postedBidsAsks.selected && shareTypePreferred.selected,
      )
      .with(Indicator.LastRoundPrice, () => lastRoundPps.selected)
      .otherwise(() => false);
  };

  const setOptionsFromSettings = () => {
    setOptionsState(() => ({
      ...initialOptionsState,
      transferTypeDirect: {
        available: priceLines.transferTypeDirect,
        selected: false,
      },
      transferTypeIndirect: {
        available: priceLines.transferTypeIndirect,
        selected: false,
      },
      shareTypeCommon: {
        available: priceLines.shareTypeCommon,
        selected: false,
      },
      shareTypePreferred: {
        available: priceLines.shareTypePreferred,
        selected: false,
      },
      acceptedBids: {
        available: graphIndicators.acceptedBids,
        selected: graphIndicators.acceptedBids,
      },
      postedBidsAsks: {
        available: graphIndicators.postedBidsAsks,
        selected: false,
      },
      lastRoundPps: {
        available: graphIndicators.lastRoundPps,
        selected: false,
      },
    }));
  };

  const handleApplyOptions = () => {
    const indicators = AVAILABLE_INDICATORS.filter((indicator: Indicator) =>
      isIndicatorSelected(indicator, optionsState),
    );
    setSelectedIndicators(indicators);
  };

  const handleResetOptions = () => {
    setOptionsFromSettings();
    setSelectedIndicators([
      ...initialSelectedIndicators,
      ...(graphIndicators.acceptedBids ? [Indicator.AcceptedBids] : []),
    ]);
  };

  const { data: response, loading } = useCompanyPriceDataQuery({
    variables: {
      companyId: id,
      startDate,
      // eslint-disable-next-line functional/prefer-readonly-type
      indicators: selectedIndicators as Indicator[],
      includeIndexPrice: selectedIndicators.includes(Indicator.IndexPrice),
      includeIndexPriceTransferTypeDirect: selectedIndicators.includes(
        Indicator.IndexPriceTransferTypeDirect,
      ),
      includeIndexPriceTransferTypeIndirect: selectedIndicators.includes(
        Indicator.IndexPriceTransferTypeIndirect,
      ),
      includeIndexPriceShareTypeCommon: selectedIndicators.includes(
        Indicator.IndexPriceShareTypeCommon,
      ),
      includeIndexPriceShareTypePreferred: selectedIndicators.includes(
        Indicator.IndexPriceShareTypePreferred,
      ),
      includeAcceptedBids: selectedIndicators.includes(Indicator.AcceptedBids),
      includeAcceptedBidsTransferTypeDirect: selectedIndicators.includes(
        Indicator.AcceptedBidsTransferTypeDirect,
      ),
      includeAcceptedBidsTransferTypeIndirect: selectedIndicators.includes(
        Indicator.AcceptedBidsTransferTypeIndirect,
      ),
      includeAcceptedBidsShareTypeCommon: selectedIndicators.includes(
        Indicator.AcceptedBidsShareTypeCommon,
      ),
      includeAcceptedBidsShareTypePreferred: selectedIndicators.includes(
        Indicator.AcceptedBidsShareTypePreferred,
      ),
      includePostedBidsAsks: selectedIndicators.includes(
        Indicator.PostedBidsAsks,
      ),
      includePostedBidsAsksTransferTypeDirect: selectedIndicators.includes(
        Indicator.PostedBidsAsksTransferTypeDirect,
      ),
      includePostedBidsAsksTransferTypeIndirect: selectedIndicators.includes(
        Indicator.PostedBidsAsksTransferTypeIndirect,
      ),
      includePostedBidsAsksShareTypeCommon: selectedIndicators.includes(
        Indicator.PostedBidsAsksShareTypeCommon,
      ),
      includePostedBidsAsksShareTypePreferred: selectedIndicators.includes(
        Indicator.PostedBidsAsksShareTypePreferred,
      ),
      includeLastRoundPrice: selectedIndicators.includes(
        Indicator.LastRoundPrice,
      ),
    },
    // do not run an empty indicators query
    skip: selectedIndicators.length === 0,
  });

  useEffect(() => {
    // apply initial options based on the chart config
    setOptionsFromSettings();
    // since AcceptedBids can be turned off we can't rely on it being in the initialSelectedIndicators
    setSelectedIndicators([
      ...initialSelectedIndicators,
      ...(graphIndicators.acceptedBids ? [Indicator.AcceptedBids] : []),
    ]);
  }, [priceLines, graphIndicators]);

  const data = useMemo(
    () =>
      mapCompanyPriceData(
        response?.companyPriceData?.dailyPriceData?.filter(
          (item): item is DailyPriceData => item !== null,
        ) ?? [],
      ),
    [response],
  );

  const priceTrends =
    (response?.companyPriceData?.indexPriceTrends?.filter(
      Boolean,
    ) as readonly IndexPriceTrend[]) ?? [];

  const visibleSeries = getVisibleSeries(data);

  const selectedTrendLinesCount = getSelectedTrendLinesCount(optionsState);
  const displayedPriceTrendsCount = getDisplayedPriceTrendsCount(optionsState);

  const value = useMemo(
    () => ({
      startDate,
      range,
      setRange,
      selectedIndicators,
      selectedTrendLinesCount,
      displayedPriceTrendsCount,
      data,
      loading,
      priceTrends,
      visibleSeries,
      optionsState,
      setOptionsState,
      handleOptionToggle,
      handleOptionToggleOff,
      handleResetOptions,
      handleApplyOptions,
    }),
    [
      startDate,
      range,
      selectedIndicators,
      selectedTrendLinesCount,
      displayedPriceTrendsCount,
      data,
      loading,
      priceTrends,
      visibleSeries,
      optionsState,
      setOptionsState,
      handleOptionToggle,
      handleOptionToggleOff,
      handleResetOptions,
      handleApplyOptions,
    ],
  );

  return (
    <PricingChartContext.Provider value={value}>
      {children}
    </PricingChartContext.Provider>
  );
};
