import { useRouter } from "next/router";

import { ModalBody, Show, Text } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import {
  PlaceBidSequenceModalPlaceBidMutation,
  PlaceBidSequenceModalPlaceBidListingFragment,
} from "@/gql";
import { isHiiveFund } from "@/utils";

import { usePlaceBidSequenceModalStepFormContext } from "./PlaceBidSequenceModalStepFormContext";
import { StepKeys } from "./steps";
import { PlaceBidSequenceModalFormValues } from "./types";

interface PlaceBidConfirmationModalProps
  extends StepPropsV2<StepKeys, PlaceBidSequenceModalFormValues> {
  readonly isSubmitting: boolean;
  readonly initialListing?: PlaceBidSequenceModalPlaceBidListingFragment;
}

const PlaceBidConfirmation = ({
  isSubmitting,
  initialListing,
  stepRouter: { stepControls },
}: PlaceBidConfirmationModalProps) => {
  const { submitMutation } = usePlaceBidSequenceModalStepFormContext();
  const router = useRouter();

  const onClickSubmit = () =>
    submitMutation().then((response: PlaceBidSequenceModalPlaceBidMutation) => {
      const newBidId = response.placeBid.bid?.id;
      if (!newBidId) return;

      stepControls.nextStep();
      router.push(`/listings/bids/${newBidId}`);
    });

  return (
    <>
      <HiiveModalHeader>Confirm</HiiveModalHeader>
      <ModalBody>
        <Text textStyle="heading-lg">
          Are you sure you want to place this{` `}
          {initialListing && isHiiveFund(initialListing) ? `order` : `bid`}?
        </Text>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[PlaceBidConfirmation/Cancel]"
            onCancel={stepControls.previousStep}
          />
        </Show>
        <HiiveSubmitButton
          sentryLabel="[PlaceBidConfirmation/Submit]"
          isLoading={isSubmitting}
          submitText="Confirm"
          onClick={onClickSubmit}
        />
      </HiiveModalFooter>
    </>
  );
};
export default PlaceBidConfirmation;
