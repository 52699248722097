import { MagnifyingGlass } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Input,
  InputProps,
} from "@chakra-ui/react";

import { Combobox } from "@/components/form";
import { useColors } from "@/hooks";

type InternalEntitiesSearchInputProps = {
  onClear: () => void;
} & InputProps;

const InternalEntitiesSearchInput = ({
  onClear,
  ...props
}: InternalEntitiesSearchInputProps) => {
  const [grey500] = useColors([`grey.500`]);
  const { t } = useTranslation(`transactions`);

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <MagnifyingGlass size={20} color={grey500} />
      </InputLeftElement>
      <Input type="search" placeholder={t(`search`)} {...props} />
      {!!props.value && (
        <InputRightElement
          as={IconButton}
          variant="icon"
          aria-label={t(`clear`)}
          onClick={onClear}
        >
          <Combobox.ClearButton />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default InternalEntitiesSearchInput;
