import { t } from "i18next";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";

import { Flex, Link, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { OnboardingContainerV2 } from "@/components/onboarding-v2";
import { InstitutionalCustomerAgreement } from "@/components/pandadoc";
import {
  SubmissionStatus,
  useTransitionCurrentStepMutation,
  useUpdateOnboardingMutation,
} from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";

export const CustomerAgreementPage = () => {
  const actor = useCurrentActor();
  const [signedCustomerAgreement, setSignedCustomerAgreement] = useState(false);

  const [updateOnboarding] = useUpdateOnboardingMutation();
  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  useEffect(() => {
    if (
      actor.onboardingV2?.customerAgreementSubmissionStatus ===
      SubmissionStatus.Submitted
    ) {
      setSignedCustomerAgreement(true);
    }
  }, [actor.onboardingV2?.customerAgreementSubmissionStatus]);

  const transitionCurrentStep = async () => {
    await transitionCurrentStepMutation();
  };

  const onFormSigning = async () => {
    await updateOnboarding({
      variables: {
        input: {
          customerAgreementSubmissionStatus: SubmissionStatus.Submitted,
        },
      },
    });

    setSignedCustomerAgreement(true);
  };

  const onSkip = async () => {
    await updateOnboarding({
      variables: {
        input: {
          customerAgreementSubmissionStatus: SubmissionStatus.Skipped,
        },
      },
    });

    await transitionCurrentStep();
  };

  return (
    <OnboardingContainerV2 canGoBack metaTitle="Customer Agreement">
      <Flex direction="column" alignItems="center">
        <VStack
          spacing={2}
          alignItems="flex-start"
          mb={9}
          w={{ base: `full`, lg: `70%` }}
        >
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`customer_agreement`)} *
          </Text>
          <Text color="grey.900">
            <Trans
              i18nKey="customer_agreement_description"
              components={{
                a: (
                  <Link
                    fontWeight={500}
                    href="/terms-and-conditions"
                    rel="noreferrer"
                    target="_blank"
                    textDecoration="underline"
                  />
                ),
              }}
            />
          </Text>
        </VStack>

        <InstitutionalCustomerAgreement onFormSigning={onFormSigning} />
        <Flex justify="flex-end" mt={8} gap={7} w={{ base: `full`, lg: `70%` }}>
          {!signedCustomerAgreement && (
            <HiiveButton
              sentryLabel="[InvestorInvestingGoalsPageV2/Submit]"
              variant="text-grey"
              type="button"
              size="xl"
              maxW="unset"
              onClick={onSkip}
              w={{ base: `full`, lg: `unset` }}
            >
              {t(`skip`)}
            </HiiveButton>
          )}
          <HiiveButton
            sentryLabel="[InvestorInvestingGoalsPageV2/Submit]"
            variant="rounded-solid-salmon"
            onClick={transitionCurrentStep}
            size="xl"
            isLoading={isTransitioningCurrentStep}
            maxW="unset"
            w={{ base: `full`, lg: `unset` }}
            isDisabled={!signedCustomerAgreement}
          >
            {t(`next`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </OnboardingContainerV2>
  );
};
