import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Link, Text, VStack } from "@chakra-ui/react";

import {
  OnboardingContainerV2,
  SlideAnimation,
} from "@/components/onboarding-v2";

export const UnsupportedCountryTraderAwaitingApprovalPage = () => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `awaiting_approval`,
  });

  return (
    <OnboardingContainerV2
      canGoBack={false}
      metaTitle={t(`thank_you_for_your_interest`)}
    >
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`thank_you_for_your_interest`)}
          </Text>
        </VStack>
        <SlideAnimation>
          <Card>
            <CardBody>
              <Flex direction="column" gap={7}>
                <Text>{t(`your_hiive_account_is_pending_approval`)}</Text>
                <Text>
                  <Trans
                    i18nKey="awaiting_approval.if_you_reside_in_the_US_or_canada_please_contact_us"
                    ns="onboarding"
                    components={{
                      mailto: (
                        <Link
                          variant="chunky"
                          textDecoration="underline"
                          href="mailto:support@hiive.com"
                        />
                      ),
                    }}
                  />
                </Text>
                <Text>{t(`if_you_do_not_live_in_canada_or_the_US`)}</Text>
                <Text>{t(`thank_you_for_your_patience`)}</Text>
              </Flex>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
    </OnboardingContainerV2>
  );
};
