globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"7f303e6e3642739169e2655fb7bd83a3435792b6"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
import { withoutGraphQLFetch } from "apollo-link-sentry";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || `local`;

const INFRA_ENV = process.env.NEXT_PUBLIC_INFRA_ENV || `local`;

// eslint-disable-next-line no-nested-ternary
const RELEASE = process.env.VERCEL
  ? INFRA_ENV === `production`
    ? process.env.NEXT_PUBLIC_RELEASE
    : `${process.env.VERCEL_GIT_REPO_SLUG}@${process.env.VERCEL_GIT_COMMIT_SHA}`
  : undefined;

Sentry.init({
  // Only allow errors from scripts that originated from a domain with hiive in the URL.
  allowUrls: [`hiive`],
  enabled: VERCEL_ENV !== `local`,
  dsn:
    SENTRY_DSN ||
    `https://c1f6f48f315a422cae9f221662708771@o1070619.ingest.sentry.io/6086073`,
  // A list of errors we don't care about.'
  ignoreErrors: [/ResizeObserver/, /NetworkError/, /AbortError/],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: VERCEL_ENV === `production` ? INFRA_ENV : VERCEL_ENV,
  release: RELEASE,
  beforeBreadcrumb: withoutGraphQLFetch((breadcrumb, hint = {}) => {
    // Include the value of data-sentry attribute, eg:
    // <button data-sentry="very-important-button">Hi there!</button>
    if (breadcrumb.category?.startsWith(`ui`)) {
      const { target } = hint.event;
      const customName = target.dataset.sentry;
      const customMessage = `${target.tagName.toLowerCase()}[sentry="${customName}"]`;
      // eslint-disable-next-line functional/immutable-data, no-param-reassign
      breadcrumb.message = customName ? customMessage : breadcrumb.message;
    }
    return breadcrumb;
  }),
});
