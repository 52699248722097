import { ChannelTypePreferences, PreferenceSet } from "@knocklabs/client";
import { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Center,
  GridItem,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { useAnalytics, useKnockClient } from "@/hooks";

import { NotificationOption } from "./NotificationOption";

type KnockCategoryKey = `activity-listing` | `activity-standingbid`;

type KnockNotificationPreferenceChange = {
  readonly key: KnockCategoryKey;
  readonly value: boolean;
};

const initialNotificationPreferences: PreferenceSet = {
  id: `default`,
  channel_types: {
    email: true,
    in_app_feed: true,
    push: true,
    sms: true,
    chat: true,
  },
  categories: {
    "activity-listing": {
      channel_types: {
        email: true,
        in_app_feed: true,
        push: true,
        sms: true,
        chat: true,
      },
    },
    "activity-standingbid": {
      channel_types: {
        email: true,
        in_app_feed: true,
        push: true,
        sms: true,
        chat: true,
      },
    },
  },
  workflows: {},
};

export const UnaccreditedSellerNotificationsCard = withCurrentActor(
  ({
    actor,
    userNotificationToken,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly userNotificationToken: string;
  }) => {
    const analytics = useAnalytics();
    const knockClient = useKnockClient({ actor, userNotificationToken });
    const [loading, setLoading] = useState<boolean>(true);
    const [preferences, setPreferences] = useState<PreferenceSet>(
      initialNotificationPreferences,
    );

    const fetchPreferences = async () => {
      const data = await knockClient.preferences.get();

      const nextPreferences: PreferenceSet = {
        ...data,
        channel_types:
          data.channel_types || initialNotificationPreferences.channel_types,
        categories:
          data.categories || initialNotificationPreferences.categories,
      };

      setPreferences(nextPreferences);
      setLoading(false);
    };

    useEffect(() => {
      fetchPreferences();
    }, []);

    const handleUpdatePreferences = async (
      prefsChange: readonly KnockNotificationPreferenceChange[],
    ) => {
      const categories = prefsChange.reduce(
        (previousValue, { key, value }) => ({
          ...previousValue,
          [key]: {
            channel_types: {
              email: value,
              in_app_feed: true,
              push: true,
              sms: true,
              chat: true,
            },
          },
        }),
        preferences.categories,
      );

      const nextPreferences = {
        ...preferences,
        categories,
      };

      const data = await knockClient.preferences.set(nextPreferences);
      setPreferences(data);
    };

    const getNotificationOptionValue = (key: KnockCategoryKey) =>
      (
        preferences.categories[key] as {
          readonly channel_types: ChannelTypePreferences;
        }
      )?.channel_types?.email;

    return (
      <Card w="full" flex="1">
        <CardHeader>
          <Text textStyle="heading-sm">Notifications</Text>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Center mt={12}>
              <Spinner />
            </Center>
          ) : (
            <>
              <Text textStyle="heading-sm" mb={3}>
                Emails
              </Text>
              <SimpleGrid columns={2} columnGap={9}>
                <GridItem colSpan={{ base: 2, xl: 1 }}>
                  <NotificationOption
                    title="Notifications for listings on my holdings"
                    value={getNotificationOptionValue(`activity-listing`)}
                    onChange={(value) => {
                      analytics.track(`Notification Option Toggled`, {
                        option: `new-listings`,
                        toggle: value,
                      });
                      handleUpdatePreferences([
                        { key: `activity-listing`, value },
                      ]);
                    }}
                  />
                  <NotificationOption
                    title="Notifications for standing bids on my holdings"
                    value={getNotificationOptionValue(`activity-standingbid`)}
                    onChange={(value) => {
                      analytics.track(`Notification Option Toggled`, {
                        option: `new-standing-bids`,
                        toggle: value,
                      });
                      handleUpdatePreferences([
                        { key: `activity-standingbid`, value },
                      ]);
                    }}
                  />
                </GridItem>
              </SimpleGrid>
            </>
          )}
        </CardBody>
      </Card>
    );
  },
);
