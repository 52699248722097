import { useEffect } from "react";

import { Box, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import { FlowKind, ModalKind } from "@/components/providers";
import { UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment } from "@/gql";
import { useFlowLauncher, useModal } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getSecuritySpecialistContact,
  getShortDocumentTitleByTransferMethod,
  getTransferMethodByUnaccreditedSellerTransferMethodInput,
  getV2TransferMethodFromV1,
} from "@/utils";

import { StepKeys } from "./steps";
import { UnaccreditedSellerAcceptStandingBidSequenceModalFormValues } from "./types";

interface UnaccreditedSellerAcceptStandingBidSuccessModalProps
  extends StepPropsV2<
    StepKeys,
    UnaccreditedSellerAcceptStandingBidSequenceModalFormValues
  > {
  readonly standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment;
}

const UnaccreditedSellerAcceptStandingBidSuccessModal = ({
  standingBid,
  values,
}: UnaccreditedSellerAcceptStandingBidSuccessModalProps) => {
  const transferMethod =
    getTransferMethodByUnaccreditedSellerTransferMethodInput(
      values.transferMethod,
    );

  const shortDocumentTitle = getShortDocumentTitleByTransferMethod(
    getV2TransferMethodFromV1(transferMethod),
  );

  const securitySpecialistEmail = getSecuritySpecialistContact(
    standingBid.company,
  );

  const { showFlow } = useFlowLauncher();

  const { closeModal, addModalCloseHandler, removeModalCloseHandler } =
    useModal();

  useEffect(() => {
    addModalCloseHandler(ModalKind.UnaccreditedSellerAcceptStandingBid, () => {
      showFlow(FlowKind.U16RTransactionCelebration);
    });

    return () => {
      removeModalCloseHandler(ModalKind.UnaccreditedSellerAcceptStandingBid);
    };
  }, []);

  return (
    <>
      <HiiveModalHeader>Standing bid accepted!</HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start">
          <Text textStyle="heading-xl">
            {`You accepted ${standingBid.company.name} standing bid ${
              standingBid.shortId
            } for ${
              !!values.numShares && formatShares(values.numShares)
            } shares at ${formatPricePerShare(values.pricePerShare * 100)}/sh!`}
          </Text>
          <Text>
            The next step is for you and the buyer to execute the{` `}
            {shortDocumentTitle} for this transaction. You may message the
            bidder directly with any questions or comments.
          </Text>
          <Box>
            <Text as="span">You may also reach out anytime to the{` `}</Text>
            <MailtoLink
              fontWeight="medium"
              email={securitySpecialistEmail}
              subject={`Contact ${standingBid.company.name} Security Specialist`}
            >
              {`${standingBid.company.name} Security Specialist`}
            </MailtoLink>
            <Text as="span">{` `}for assistance on your transaction.</Text>
          </Box>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          sentryLabel="[UnaccreditedSellerAcceptStandingBidSuccess/Submit]"
          type="button"
          submitText="Okay"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default UnaccreditedSellerAcceptStandingBidSuccessModal;
