import { ReactNode } from "react";

import { CardBody, VStack, Divider } from "@chakra-ui/react";

interface IssuerInfoCardProps {
  children: ReactNode;
}

export const IssuerInfoCard = ({ children }: IssuerInfoCardProps) => (
  <CardBody p={0}>
    <VStack
      divider={<Divider style={{ margin: `0px` }} borderColor="grey.100" />}
      align="stretch"
    >
      {children}
    </VStack>
  </CardBody>
);
