import { CheckCircle } from "@phosphor-icons/react";
import currency from "currency.js";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Box, Card, HStack, List, ListItem, Text } from "@chakra-ui/react";

import { FundsReceipt } from "@/gql";

type FundsReceiptsProps = {
  receipts: FundsReceipt[];
};

const FundsReceipts = ({ receipts }: FundsReceiptsProps) => {
  const { t } = useTranslation(`transactions`);
  const formattedDate = (createdAt: string) =>
    dayjs(createdAt).format(`D, MMMM YYYY`);

  return (
    <Card variant="outline" boxShadow="none">
      <List>
        {receipts.map((receipt, key) => (
          <ListItem
            key={receipt.id}
            px={4}
            py={3}
            borderBottom={receipts[key + 1] ? `0.5px solid` : ``}
            borderBottomColor="grey.200"
          >
            <HStack h={10} justifyContent="space-between" alignItems="center">
              <HStack gap={4}>
                <CheckCircle size={24} weight="fill" />
                <Box>
                  <Text textStyle="heading-md" lineHeight={6}>
                    {t(`payment_received`)}
                  </Text>
                  <Text fontSize={12} color="grey.500">
                    {formattedDate(receipt.insertedAt)}
                  </Text>
                </Box>
              </HStack>
              <Text fontSize={15} fontWeight={500} color="grey.900">
                {currency(receipt.amount / 100).format()}
              </Text>
            </HStack>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default FundsReceipts;
