import { Show } from "@chakra-ui/react";

import { useCurrentActor } from "@/hooks";
import { getIsUnaccreditedSeller } from "@/utils";

import {
  DesktopNavBar,
  UnaccreditedSellerDesktopNavBarV2,
} from "./DesktopNavBar";
import { MobileNavBar } from "./MobileNavBar";
import UnaccreditedSellerMobileNavBar from "./MobileNavBar/UnaccreditedSellerMobileNavBar";

const NavBar = () => {
  const actor = useCurrentActor();
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  const renderDesktopNavBar = () => {
    if (isUnaccreditedSeller) {
      return <UnaccreditedSellerDesktopNavBarV2 />;
    }
    return <DesktopNavBar />;
  };

  const renderMobileNavBar = () => {
    if (isUnaccreditedSeller) {
      return <UnaccreditedSellerMobileNavBar />;
    }
    return <MobileNavBar />;
  };

  return (
    <>
      <Show above="lg" ssr={false}>
        {renderDesktopNavBar()}
      </Show>
      <Show below="lg" ssr={false}>
        {renderMobileNavBar()}
      </Show>
    </>
  );
};

export default NavBar;
