import {
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTimestamp,
} from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { CompanyYourBidCardBidFragment } from "@/gql";
import { getBidNumSharesActual, makeUrl, bidLabel } from "@/utils";

const CompanyYourBidCardDetails = ({
  bid,
}: {
  readonly bid: CompanyYourBidCardBidFragment;
}) => {
  const numShares = getBidNumSharesActual(bid);
  const price = bid.pricePerShare;

  return (
    <ActivityCardPricingWrapper>
      <ActivityCardPricingNumSharesAndPrice
        numShares={numShares}
        price={price}
      />
      <ActivityCardPricingTotal numShares={numShares} price={price} />
    </ActivityCardPricingWrapper>
  );
};

const CompanyYourBidCard = ({
  bid,
}: {
  readonly bid: CompanyYourBidCardBidFragment;
}) => {
  const title = bidLabel(bid.listing);

  return (
    <ActivityCard href={makeUrl(bid)}>
      <CardHeader>
        <HStack>
          <ActivityCardBadge variant="bid" title={title} />
          <Text textStyle="text-lg">{bid.displayId}</Text>
        </HStack>
      </CardHeader>
      <CardBody>
        <CompanyYourBidCardDetails bid={bid} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            {!!bid.expireAt && (
              <ActivityCardTimestamp title="Expires" date={bid.expireAt} />
            )}
          </ActivityCardFooterStatusWrapper>
          {!!bid.discussion && bid.discussion.totalNumMessages > 0 && (
            <MessagesBadge
              numUnreadMessages={bid.discussion.numUnreadMessages}
              totalNumMessages={bid.discussion.totalNumMessages}
            />
          )}
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};

export default CompanyYourBidCard;
