import { MagnifyingGlass } from "@phosphor-icons/react";
import { ChangeEvent, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

import { useDebounce } from "@/hooks";

import { IssuerTransactionsTableContext } from "./IssuerTransactionsProvider";

const DEBOUNCE_DELAY = 500;

export const IssuerTransactionsSearchBar = () => {
  const { t } = useTranslation(`issuers`);
  const { debounce } = useDebounce();
  const { filterBy, onSearchTextChange } = useContext(
    IssuerTransactionsTableContext,
  );

  const searchText = useMemo(() => filterBy?.searchText ?? ``, [filterBy]);

  const handleSearchTextChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      debounce(() => {
        onSearchTextChange(e.target.value);
      }, DEBOUNCE_DELAY);
    },
    [debounce, onSearchTextChange],
  );

  return (
    <InputGroup maxW={{ base: `full`, lg: `45%` }}>
      <InputLeftElement pointerEvents="none">
        <MagnifyingGlass size={20} color="var(--chakra-colors-grey-200)" />
      </InputLeftElement>
      <Input
        autoFocus
        defaultValue={searchText}
        onChange={handleSearchTextChange}
        placeholder={t(`transactions_table_search_placeholder`)}
        type="text"
      />
    </InputGroup>
  );
};
