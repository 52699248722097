import { Trans, useTranslation } from "react-i18next";

import { Box, Text, VStack } from "@chakra-ui/react";

const ListingOtherDetailsTooltip = () => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="start">
      <Box>
        <Trans
          i18nKey="listing_additional_notes"
          components={{
            ul: <ul style={{ paddingLeft: `1.2em` }} />,
            li: <li />,
          }}
        />
      </Box>
      <Text>{t(`dont_share_contact`)}</Text>
    </VStack>
  );
};

export default ListingOtherDetailsTooltip;
