import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useCustomToast } from "@/hooks";
import { useMarketplaceConfiguration } from "@/hooks/featureFlags";

export const MfaToastKeys = {
  showAppMfaEnrolledToast: `showAppMfaEnrolledToast`,
  showAppMfaRemovedToast: `showAppMfaRemovedToast`,
  showSmsMfaEnrolledToast: `showSmsMfaEnrolledToast`,
  showSmsMfaRemovedToast: `showSmsMfaRemovedToast`,
  showAllMfaRemovedToast: `showAllMfaRemovedToast`,
};

export const VALID_TOAST_FLAG = `VALID`;

export const useMfaEnrollmentToast = () => {
  const { successToast } = useCustomToast();
  const { t } = useTranslation();
  const marketplaceConfigurationEnabled = useMarketplaceConfiguration();

  const toastMessages = {
    [MfaToastKeys.showAppMfaEnrolledToast]: t(`authenticator_app_mfa_added`),
    [MfaToastKeys.showAppMfaRemovedToast]: t(`authenticator_app_mfa_removed`),
    [MfaToastKeys.showSmsMfaEnrolledToast]: t(`text_message_mfa_added`),
    [MfaToastKeys.showSmsMfaRemovedToast]: t(`text_message_mfa_removed`),
    [MfaToastKeys.showAllMfaRemovedToast]: t(
      `multi_factor_authentication_removed`,
    ),
  };

  useEffect(() => {
    if (!marketplaceConfigurationEnabled) {
      return;
    }
    Object.entries(toastMessages).forEach(([key, message]) => {
      const showToast = sessionStorage.getItem(key) === VALID_TOAST_FLAG;
      if (showToast) {
        successToast(message);
        sessionStorage.removeItem(key);
      }
    });
  }, []);
};
