import { useTranslation } from "react-i18next";

import { VStack } from "@chakra-ui/react";

import { NavMenuLink } from "./NavMenuLink";

export const AccreditedNavMenu = ({
  onClick,
}: {
  readonly onClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <VStack spacing={0} w="full">
      <NavMenuLink onClick={onClick} href="/dashboard">
        {t(`dashboard`)}
      </NavMenuLink>
      <NavMenuLink onClick={onClick} href="/companies/browse">
        {t(`browse_companies`)}
      </NavMenuLink>
      <NavMenuLink onClick={onClick} href="/hiive50">
        {t(`hiive_50`)}
      </NavMenuLink>
    </VStack>
  );
};
