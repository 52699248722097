/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  GridItem,
  HStack,
  ModalBody,
  Radio,
  Show,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveAdvancedOptionsDropdown,
  HiiveBackButton,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CheckboxInput,
  DateInput,
  FormRadioInput,
  OtherDetailsInput,
  QuestionTooltip,
  StepPropsV2,
  TextInput,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  representedEmailSchema,
  representedNameSchema,
} from "@/components/postings";
import {
  BrokerStandingBidOtherDetailsTooltip,
  RepresentedStandingBidExpiryTooltip,
  RepresentedUserTooltip,
  StandingBidOtherDetailsTooltip,
} from "@/components/tooltip";
import { PlaceStandingBidMutation, UserWithInstitutionFragment } from "@/gql";
import { useModal, useStepValidator } from "@/hooks";
import { constants, Nullable, getIsBroker, fromBool, toBool } from "@/utils";
import * as datetime from "@/utils/datetime";

import {
  PlaceStandingBidSequenceModalStepFormContext,
  usePlaceStandingBidSequenceModalStepFormContext,
} from "./PlaceStandingBidSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { PlaceStandingBidSequenceModalFormValues } from "./types";

export const createValidationSchema = (actor: UserWithInstitutionFragment) =>
  Yup.object().shape({
    affiliate: getIsBroker(actor)
      ? Yup.boolean().nullable().required(`Required`)
      : Yup.boolean().nullable(),
    confirmed: Yup.boolean().oneOf([true], `Required`),
    expireAt: Yup.string().required(`Required`),
    otherDetails: Yup.string().nullable(),
    muteNotifyWatchers: Yup.boolean().nullable(),
    representedEmail: representedEmailSchema(actor),
    representedFirstName: representedNameSchema(actor),
    representedLastName: representedNameSchema(actor),
    notifyRepresentingEmail: Yup.boolean().required(`Required`),
    solicited: getIsBroker(actor)
      ? Yup.boolean().nullable().required(`Required`)
      : Yup.boolean().nullable(),
  });

interface AdditionalDetailsProps
  extends StepPropsV2<
    StepKeys,
    Nullable<PlaceStandingBidSequenceModalFormValues>
  > {
  readonly actor: UserWithInstitutionFragment;
}

export const AdditionalDetails = withCurrentActor(
  ({ values, actor, stepRouter, isSubmitting }: AdditionalDetailsProps) => {
    const { stepControls } = stepRouter;
    const { submitMutation } =
      usePlaceStandingBidSequenceModalStepFormContext();

    const { t } = useTranslation();

    const router = useRouter();
    const { closeModal } = useModal();

    const onSuccess = () => {
      submitMutation().then((response: PlaceStandingBidMutation) => {
        const newStandingBidId = response.placeStandingBid?.standingBid?.id;
        if (!newStandingBidId) {
          closeModal();
          return;
        }
        stepControls.nextStep();
        router.push(`/standing-bids/${newStandingBidId}`);
      });
    };

    const validationSchema = createValidationSchema(actor);

    useStepValidator({
      Context: PlaceStandingBidSequenceModalStepFormContext,
      stepKey: stepKeys.placeStandingBidAdditionalDetails,
      validator: {
        validationSchema,
        onSuccess,
      },
      values,
    });

    const maxExpiryDate = datetime
      .add(`day`, constants.max_standing_bid_expire_after_days, dayjs())
      .toDate();

    const isBroker = getIsBroker(actor);

    const { formName, submitText, otherDetailsTooltip } = isBroker
      ? {
          formName: `SubmitStandingBid`,
          submitText: `Submit Standing Bid`,
          otherDetailsTooltip: <BrokerStandingBidOtherDetailsTooltip />,
        }
      : {
          formName: `PlaceStandingBid`,
          submitText: `Place Standing Bid`,
          otherDetailsTooltip: <StandingBidOtherDetailsTooltip />,
        };

    return (
      <>
        <ModalBody>
          <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
            <GridItem colSpan={2}>
              <DateInput
                name="expireAt"
                label="Expiration date"
                maxDate={maxExpiryDate}
                tooltipContent={<RepresentedStandingBidExpiryTooltip />}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <OtherDetailsInput
                name="otherDetails"
                label="Additional standing bid notes"
                tooltipContent={otherDetailsTooltip}
              />
            </GridItem>
            {isBroker && (
              <>
                <GridItem colSpan={2}>
                  <FormRadioInput
                    label={t(`broker_submit_bid_affiliate_question`, {
                      companyName: values.company?.name,
                    })}
                    name="affiliate"
                    mapper={{ from: fromBool, to: toBool }}
                    data-testid="affiliate-bid-radio-button"
                  >
                    <HStack spacing={12}>
                      <Radio variant="base" value="true">
                        Yes
                      </Radio>
                      <Radio variant="base" value="false">
                        No
                      </Radio>
                    </HStack>
                  </FormRadioInput>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormRadioInput
                    label={t(`broker_submit_bid_unsolicited_question`, {
                      companyName: values.company?.name,
                    })}
                    name="solicited"
                    mapper={{ from: fromBool, to: toBool }}
                    data-testid="solicited-bid-radio-button"
                  >
                    <HStack spacing={12}>
                      <Radio variant="base" value="false">
                        Yes
                      </Radio>
                      <Radio variant="base" value="true">
                        No
                      </Radio>
                    </HStack>
                  </FormRadioInput>
                </GridItem>
              </>
            )}
            {actor.isHiiveUser && (
              <GridItem colSpan={2}>
                <HiiveAdvancedOptionsDropdown
                  validationSchema={validationSchema}
                  fieldNames={[
                    `muteNotifyWatchers`,
                    `representedEmail`,
                    `representedFirstName`,
                    `representedLastName`,
                    `notifyRepresentingEmail`,
                  ]}
                >
                  <VStack spacing={4} alignItems="flex-start">
                    <CheckboxInput
                      name="muteNotifyWatchers"
                      label={t(`do_not_send_activity_notifications`)}
                    />
                    <Text
                      align="center"
                      textStyle="deprecated-heading-lg"
                      color="h-dark-grey"
                    >
                      {t(`represented_user_info`)}
                      <QuestionTooltip
                        translateY={1.5}
                        translateX={1.0}
                        transform="auto"
                        tooltipContent={<RepresentedUserTooltip />}
                      />
                    </Text>
                    <HStack gap={4} w="full" alignItems="top">
                      <TextInput
                        data-testid="represented-user-first-name"
                        name="representedFirstName"
                        placeholder={t(`first_name`)}
                      />
                      <TextInput
                        data-testid="represented-user-last-name"
                        name="representedLastName"
                        placeholder={t(`last_name`)}
                      />
                    </HStack>
                    <TextInput
                      name="representedEmail"
                      placeholder="Email"
                      type="email"
                    />
                    <CheckboxInput
                      name="notifyRepresentingEmail"
                      label="Notify the person being represented"
                    />
                  </VStack>
                </HiiveAdvancedOptionsDropdown>
              </GridItem>
            )}

            {!isBroker && (
              <GridItem colSpan={2}>
                <CheckboxInput
                  name="confirmed"
                  label={t(`place_standing_bid_confirm_intent_prompt`)}
                  helperText={t(
                    `place_standing_bid_confirm_intent_description`,
                  )}
                />
              </GridItem>
            )}
          </SimpleGrid>
        </ModalBody>
        {isBroker && (
          <ModalBody>
            <GridItem colSpan={2}>
              <CheckboxInput
                name="confirmed"
                label={t(`broker_submit_bid_acknowledgement`)}
              />
            </GridItem>
          </ModalBody>
        )}
        <HiiveModalFooter>
          <Show above="md" ssr={false}>
            <HiiveCancelButton
              sentryLabel={`[${formName}/AdditionalDetails/Cancel]`}
              onCancel={closeModal}
            />
          </Show>
          <HiiveBackButton
            sentryLabel={`[${formName}/AdditionalDetails/Back]`}
            onClick={stepControls.previousStep}
          />
          <HiiveSubmitButton
            isLoading={isSubmitting}
            sentryLabel={`[${formName}/AdditionalDetails/Submit]`}
            submitText={submitText}
            type="submit"
          />
        </HiiveModalFooter>
      </>
    );
  },
);
