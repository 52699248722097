import { Circle } from "@phosphor-icons/react";
import {
  Control,
  FieldError,
  FieldValues,
  Path,
  useController,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  InputProps,
  ListItem,
  List,
  ListIcon,
  Text,
  HStack,
} from "@chakra-ui/react";

import { PasswordInput } from "./PasswordInput";

interface FormPasswordWithInteractiveValidationInputProps<
  TFieldValues extends FieldValues,
> extends InputProps {
  readonly control: Control<TFieldValues>;
  readonly excludedTexts: string[];
  readonly label: string;
  readonly labelSrOnly?: FormLabelProps["srOnly"];
  readonly name: Path<TFieldValues>;
}

const minCharactersRegex = /.{10,}/;
const minUppercaseRegex = /(?=.*[A-Z])/;
const minLowercaseRegex = /(?=.*[a-z])/;
const minSpecialCharacterRegex = /(?=.*[!?@#$%^&*_0-9])/;
const validationPassColour = `emerald.600`;
const validationFailColour = `red.600`;

const PasswordValidation = ({
  value,
  error,
  invalid,
  excludedTexts,
}: {
  readonly value: string;
  readonly error?: FieldError;
  readonly invalid: boolean;
  readonly excludedTexts: string[];
}) => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `sign_up`,
  });

  const getListItemColour = (value: string, regex: RegExp) =>
    regex.test(value) ? validationPassColour : validationFailColour;

  const getExcludingFieldsColour = (value: string) => {
    const lowerCasePassword = value.toLocaleLowerCase();
    const containsExcludedText = excludedTexts.some((excludedText) =>
      lowerCasePassword.includes(excludedText.toLocaleLowerCase()),
    );

    return containsExcludedText ? validationFailColour : validationPassColour;
  };

  if (!invalid) return null;

  if (value.length === 0)
    return <FormErrorMessage>{error?.message}</FormErrorMessage>;

  return (
    <List my={2}>
      <ListItem as={HStack} spacing={0} alignItems="center">
        <ListIcon
          as={Circle}
          weight="fill"
          width={1}
          color={getListItemColour(value, minCharactersRegex)}
        />
        <Text
          textStyle="text-sm"
          color={getListItemColour(value, minCharactersRegex)}
        >{t`ten_characters`}</Text>
      </ListItem>
      <ListItem as={HStack} spacing={0} alignItems="center">
        <ListIcon
          as={Circle}
          weight="fill"
          width={1}
          color={getListItemColour(value, minUppercaseRegex)}
        />
        <Text
          textStyle="text-sm"
          color={getListItemColour(value, minUppercaseRegex)}
        >{t`one_uppercase`}</Text>
      </ListItem>
      <ListItem as={HStack} spacing={0} alignItems="center">
        <ListIcon
          as={Circle}
          weight="fill"
          width={1}
          color={getListItemColour(value, minLowercaseRegex)}
        />
        <Text
          textStyle="text-sm"
          color={getListItemColour(value, minLowercaseRegex)}
        >{t`one_lowercase`}</Text>
      </ListItem>
      <ListItem as={HStack} spacing={0} alignItems="center">
        <ListIcon
          as={Circle}
          weight="fill"
          width={1}
          color={getListItemColour(value, minSpecialCharacterRegex)}
        />
        <Text
          textStyle="text-sm"
          color={getListItemColour(value, minSpecialCharacterRegex)}
        >{t`one_number_or_special_character`}</Text>
      </ListItem>
      <ListItem as={HStack} spacing={0} alignItems="center">
        <ListIcon
          as={Circle}
          weight="fill"
          width={1}
          color={getExcludingFieldsColour(value)}
        />
        <Text
          textStyle="text-sm"
          color={getExcludingFieldsColour(value)}
        >{t`contain_name_email_or_hiive`}</Text>
      </ListItem>
    </List>
  );
};

const FormPasswordWithInteractiveValidationInput = <
  TFieldValues extends FieldValues,
>({
  control,
  name,
  label,
  labelSrOnly,
  isRequired,
  excludedTexts,
  ...inputProps
}: FormPasswordWithInteractiveValidationInputProps<TFieldValues>) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel srOnly={labelSrOnly}>
        {isRequired ? `${label} *` : label}
      </FormLabel>
      <PasswordInput {...inputProps} {...field} />
      <PasswordValidation
        error={error}
        value={field.value}
        invalid={invalid}
        excludedTexts={excludedTexts}
      />
    </FormControl>
  );
};

export default FormPasswordWithInteractiveValidationInput;
