import { useRouter } from "next/router";

import { WithQuery } from "@/components/common";
import { useHoldingSwitcher } from "@/components/nav";
import { usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery } from "@/gql";

import { UnaccreditedSellerModifyListingPageContentSkeleton } from "./UnaccreditedSellerModifyListingPageSkeleton";

const UnaccreditedSellerModifyListingPage = () => {
  const { holdingCompany } = useHoldingSwitcher();
  const router = useRouter();

  const id = holdingCompany?.companyId;
  const query =
    usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery({
      variables: { id: id || `` },
      skip: !id,
    });

  if (!holdingCompany || !id) return null;

  return (
    <WithQuery
      query={query}
      fallback={<UnaccreditedSellerModifyListingPageContentSkeleton />}
    >
      {({ data: { companyById: company } }) => {
        const listing = company.activity.myListings[0];
        if (!listing) {
          router.replace(`/dashboard/${id}`);
          return null;
        }

        if (listing.id) {
          router.replace(`/modify-listing/${listing.id}`);
          return null;
        }

        return null;
      }}
    </WithQuery>
  );
};
export default UnaccreditedSellerModifyListingPage;
