import { X, List } from "@phosphor-icons/react";

import { Box, HStack } from "@chakra-ui/react";

import { Logo } from "@/components/common";

interface IssuerMobileNavHeaderProps {
  onToggle: () => void;
  isOpen: boolean;
}

const MOBILE_NAV_ICON_SIZE = 32;

export function MobileNavDivider() {
  return (
    <Box
      h={0}
      w="full"
      borderColor="grey.100"
      borderStyle="solid"
      borderWidth={0.5}
    />
  );
}

export function IssuerMobileNavHeader({
  onToggle,
  isOpen,
}: IssuerMobileNavHeaderProps) {
  return (
    <>
      <Box px={5} py="17px" w="full">
        {isOpen ? (
          <X size={MOBILE_NAV_ICON_SIZE} onClick={onToggle} />
        ) : (
          <HStack gap={6} justifyContent="space-between">
            <List size={MOBILE_NAV_ICON_SIZE} onClick={onToggle} />
            <Logo.RedBlackIcon w={5} h={5} />
          </HStack>
        )}
      </Box>
      <MobileNavDivider />
    </>
  );
}
