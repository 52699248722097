import { VStack } from "@chakra-ui/react";

import { IssuerTransactionsCardSkeleton } from "./IssuerTransactionsCardSkeleton";

const CARD_SKELETON_NUM = 13;

export const IssuerTransactionsCardListSkeleton = () => (
  <VStack spacing={2} w="full" mt={2}>
    {Array.from({ length: CARD_SKELETON_NUM }).map((_, index) => (
      <IssuerTransactionsCardSkeleton
        key={`${new Date().toISOString()}-${index.toString()}`}
      />
    ))}
  </VStack>
);
