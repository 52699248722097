import { useTranslation } from "react-i18next";

import { SimpleGrid, Card, CardBody, GridItem } from "@chakra-ui/react";

import {
  ExecutionWorkflowActionType,
  TransactionExecutionPageTransactionFragment,
  useExecutionWorkflowActionMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";

import { ExecutionFileCard } from "./ExecutionFileCard";

export function TransactionDocumentsTab({
  transaction,
}: {
  transaction: TransactionExecutionPageTransactionFragment;
}) {
  const { t } = useTranslation(`execution`);

  const [executionWorkflowAction] = useMutationWithError(
    useExecutionWorkflowActionMutation(),
    `executionWorkflowAction`,
  );

  if (transaction.fileUploads.length === 0)
    return (
      <Card w="full" boxShadow="none">
        <CardBody p={6}>{t(`no_documents_uploaded`)}</CardBody>
      </Card>
    );

  const handleDownload = async (fileUploadId: string) => {
    if (!transaction?.workflow?.id) return;

    const response = await executionWorkflowAction({
      variables: {
        id: transaction.workflow.id,
        type: ExecutionWorkflowActionType.GenerateFileDownloadUrl,
        input: {
          generateFileDownloadUrl: {
            fileUploadId,
          },
        },
      },
    });

    const result = response?.executionWorkflowAction?.result;
    if (
      result?.__typename === `ExecutionWorkflowActionGenerateFileDownloadUrl` &&
      result.url
    ) {
      window.open(result.url, `_blank`);
    }
  };

  return (
    <Card w="full">
      <CardBody px={4} py={6}>
        <SimpleGrid columns={{ sm: 2, base: 1 }} gap={3} w="full">
          {transaction.fileUploads.map((fileUpload) => (
            <GridItem key={fileUpload.id}>
              <ExecutionFileCard
                file={fileUpload}
                onDownload={handleDownload}
              />
            </GridItem>
          ))}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
}
