export const FRONTEGG_INPUT_ERROR_STATUS = 400;

export const FRONTEGG_LOGIN_DELAY_MS = 500;
export const RELOAD_DELAY_MS = 2500;

// eslint-disable-next-line functional/no-classes
export class FronteggInputError extends Error {}

export const rootStyle = {
  a: {
    fontSize: `14px`,
    textDecoration: `underline`,
  },
  "@media (max-width: 766.95px)": {
    ".MuiBox-root": {
      position: `relative`,
      height: `auto`,
      overflow: `auto`,
    },
  },
  "@media (min-width: 767px)": {
    ".MuiBox-root": {
      minHeight: `fit-content`,
    },
  },
};

export const boxStyle = {
  borderColor: `var(--chakra-colors-grey-200)`,
  borderRadius: `var(--chakra-radii-md)`,
  borderStyle: `solid`,
  borderWidth: `0.5px`,
  boxShadow: `0px 4px 10px 1px rgba(0, 0, 0, 0.05)`,
  "@media (max-width: 766.95px)": {
    ".MuiTypography-label": {
      fontSize: `16px`,
    },
  },
};

export const boxTitleStyle = {
  display: `none`,
};

export const containerStyle = {
  marginBottom: `1.75rem`,
};
