import { withFronteggApp } from "@frontegg/nextjs/pages";
import { SpeedInsights } from "@vercel/speed-insights/next";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "react-loading-skeleton/dist/skeleton.css";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import type { AppProps } from "next/app";
import Script from "next/script";

import { ChakraProvider } from "@chakra-ui/react";

import { AsyncLDProvider } from "@/components/async-with-ld-provider";
import { DatadogInit } from "@/components/datadog";
import {
  PageFooter,
  PageHeader,
  RedirectToForgotPassword,
  boxStyle,
  boxTitleStyle,
  containerStyle,
  rootStyle,
} from "@/components/frontegg";
import { HoldingProvider } from "@/components/nav";
import {
  AnalyticsProvider,
  FrigadeProvider,
  TokenProvider,
  HiiveApolloProvider,
  SessionProvider,
} from "@/components/providers";
import { useMaintenanceMode } from "@/hooks";
import "@/i18n";
import store, { persistor } from "@/state";
import Fonts from "@/styles/fonts";
import "@/styles/globals.css";
import theme from "@/styles/theme";
import type { Page } from "@/types/page";

import NextMaintenancePage from "./maintenance";

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const clientSideId = process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID;

type Props = AppProps & {
  readonly Component: Page;
};

const FrontChatScript = () => (
  <Script
    src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
    strategy="afterInteractive"
  />
);

const GoogleAnalyticsScript = () => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) return null;
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
        `}
      </Script>
    </>
  );
};

const AppPages = ({ Component, pageProps }: Props) => {
  const maintenanceModeEnabled = useMaintenanceMode();

  if (maintenanceModeEnabled) {
    return <NextMaintenancePage />;
  }

  const getLayout: (
    page: React.ReactElement<
      unknown,
      string | React.JSXElementConstructor<unknown>
    >,
    props: unknown,
  ) => React.ReactNode = Component.getLayout ?? ((page) => page);

  return getLayout(<Component {...pageProps} />, pageProps);
};

const MyApp = (props: Props) => (
  <>
    <DatadogInit />
    <ChakraProvider theme={theme}>
      <Fonts />
      <ReduxProvider store={store}>
        <TokenProvider>
          <HiiveApolloProvider>
            <SessionProvider>
              <PersistGate loading={null} persistor={persistor}>
                <AnalyticsProvider>
                  <FrigadeProvider>
                    <GoogleAnalyticsScript />
                    <FrontChatScript />
                    <AsyncLDProvider clientSideId={clientSideId}>
                      <HoldingProvider>
                        <AppPages {...props} />
                      </HoldingProvider>
                    </AsyncLDProvider>
                  </FrigadeProvider>
                </AnalyticsProvider>
              </PersistGate>
            </SessionProvider>
          </HiiveApolloProvider>
        </TokenProvider>
      </ReduxProvider>
    </ChakraProvider>
    <SpeedInsights />
  </>
);

export default withFronteggApp(MyApp, {
  hostedLoginBox: false,
  authOptions: {
    keepSessionAlive: true,
  },
  customLoader: true,
  themeOptions: {
    loginBox: {
      // Override Frontegg "Forgot Password" to hide default component and inject custom component that redirects to Hiive "Forgot Password" page
      forgotPassword: {
        pageHeader: () => <RedirectToForgotPassword />,
        rootStyle: {
          display: `none`,
        },
      },
      login: {
        docTitle: `Sign in | Hiive`,
        signupMessage: ``,
        // @ts-expect-error Need to apply custom style to the root element
        rootStyle,
        boxStyle,
        boxTitleStyle,
        containerStyle,
        pageHeader: () => <PageHeader />,
        pageFooter: () => <PageFooter />,
      },
    },
  },
  contextOptions: {
    tenantResolver: () =>
      // TODO: Remove tenantResolver function once penetration testing is complete
      process.env.NEXT_PUBLIC_MODE && process.env.NEXT_PUBLIC_MODE === `sandbox`
        ? {
            tenant: process.env.NEXT_PUBLIC_FRONTEGG_TENANT,
          }
        : { tenant: undefined },
  },
});
