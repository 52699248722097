import { ApolloProvider } from "@apollo/client";
import { ReactNode } from "react";

import { useApolloClient } from "@/hooks";

export function HiiveApolloProvider({ children }: { children: ReactNode }) {
  const apolloClient = useApolloClient();

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
