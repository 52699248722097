import { getTopOffset } from "@/utils";

import {
  GetIsActiveProps,
  useSectionScrollTracking,
} from "./useSectionScrollTracking";

/**
 * This fn creates standard scroll section boundaries for forms where we have card sections
 * & need to show which section is currently "active" / scrolled over
 *
 * Example:
 *
 * ----- Top of Section A
 *
 * Section A will be "active" e.g getIsActive will return true
 * when the scroll position is between the top of Section A and the Top of Section B
 *
 * ----- Bottom of Section A
 * ----- Top of Section B
 *
 * ----- Bottom of Section B
 * ----- Top of Section C
 *
 *
 * ----- Bottom of Section C
 * */
const getBasicScrollTrackingSections = <TSectionKeys extends string>(
  keys: readonly TSectionKeys[],
) =>
  keys.map((key, index) => ({
    key,
    getIsActive: ({
      dimsMap,
      scrollY,
      disabledSectionKeys,
    }: GetIsActiveProps<TSectionKeys>) => {
      const enabledKeys = keys.filter(
        (key) => !disabledSectionKeys?.includes(key),
      );
      if (enabledKeys.length === 1 && index === 0) {
        return true;
      }
      const topLocation = dimsMap[key].top;
      const nextElementTopLocation =
        enabledKeys[index + 1] && dimsMap[enabledKeys[index + 1]].top;

      if (topLocation >= 0) {
        const yPos = Math.ceil(scrollY);
        // if there's a next element available, element isActive when scroll pos is between the current and next element top
        if (nextElementTopLocation >= 0) {
          return (
            yPos >= Math.floor(topLocation) &&
            yPos < Math.floor(nextElementTopLocation)
          );
        }

        // otherwise, check that the scroll pos is greater than the elements top location
        return yPos >= Math.floor(topLocation);
      }

      return false;
    },
  }));

const useBasicSectionScrollTracking = <
  TSectionKeys extends string,
  TSectionElement extends Element,
>({
  keys,
  containerElement,
  disabledSectionKeys = [],
}: {
  readonly keys: readonly TSectionKeys[];
  readonly containerElement: Element | null;
  readonly disabledSectionKeys?: readonly TSectionKeys[];
}) => {
  const { getSectionProps, getNavButtonProps } = useSectionScrollTracking<
    TSectionKeys,
    TSectionElement
  >({
    sections: getBasicScrollTrackingSections(keys),
    disabledSectionKeys,
    containerElement,
    onClickSection: (sectionElement: Element) => {
      if (!containerElement) return;
      /**
       * Something to note - window.scrollTo doesn't always scroll to *exactly* where "top" is, and the behavior varies by browser / by zoom level
       * Hence the need for Math.ceil / Math.floor to make the active section check more reliable
       */
      window.scrollTo({
        behavior: `smooth`,
        top: getTopOffset(sectionElement, containerElement),
      });
    },
  });

  return { getSectionProps, getNavButtonProps };
};

export default useBasicSectionScrollTracking;
