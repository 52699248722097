import { defineStyleConfig } from "@chakra-ui/react";

export const Badge = defineStyleConfig({
  baseStyle: {
    fontSize: `sm`,
    fontWeight: `medium`,
    textTransform: `capitalize`,
    borderRadius: `38px`,
    px: `2.5`,
    py: `1`,
    lineHeight: `none`,
  },
  variants: {
    grey: {
      borderColor: `grey.100`,
      borderRadius: `md`,
      borderWidth: 0.5,
      bg: `grey.50`,
      display: `flex`,
      alignItems: `center`,
      fontWeight: `normal`,
      px: 5,
      py: 2.5,
    },
    ghost: {
      borderColor: `grey.50`,
      borderRadius: `md`,
      borderWidth: 0.5,
      background: `transparent`,
      display: `flex`,
      alignItems: `center`,
      fontWeight: `normal`,
      px: 2,
      py: 1,
    },
    "rounded-grey": {
      bg: `grey.400`,
      color: `white`,
      borderRadius: `md`,
      p: 2,
      textTransform: `none`,
    },
    "rounded-teal": {
      bg: `teal.50`,
      color: `grey.900`,
      borderRadius: `md`,
      p: 2,
      textTransform: `none`,
    },
    "rounded-plum": {
      bg: `plum.50`,
      color: `grey.900`,
      borderRadius: `md`,
      p: 2,
      textTransform: `none`,
    },
    "rounded-olive": {
      bg: `olive.50`,
      color: `grey.900`,
      borderRadius: `md`,
      px: 2,
      py: 2,
      textTransform: `none`,
    },
    "rounded-salmon-gradient": {
      bg: `linear-gradient(90deg, #F15F61 -0.61%, #F58F90 99.39%)`,
      color: `white`,
      borderRadius: `md`,
      px: 2,
      py: 1,
      textTransform: `none`,
      fontWeight: `normal`,
    },
    "status-active": {
      bg: `emerald.100`,
      color: `emerald.600`,
      fontWeight: `normal`,
      borderRadius: `md`,
    },
    "status-pending": {
      bg: `teal.50`,
      color: `teal.900`,
      fontWeight: `normal`,
      borderRadius: `md`,
    },
    "status-rejected": {
      bg: `red.50`,
      color: `red.800`,
      fontWeight: `normal`,
      borderRadius: `md`,
    },
    "rounded-lighter-sky": {
      bg: `skyBlue.100`,
      color: `teal.1000`,
      borderRadius: `md`,
      fontWeight: `normal`,
      px: 2,
      py: 1,
    },
    "rounded-light-grey": {
      bg: `grey.50`,
      color: `grey.900`,
      borderRadius: `md`,
      fontWeight: `normal`,
      px: 2,
      py: 1,
    },
  },
});
