import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Link, Text, VStack } from "@chakra-ui/react";

import {
  OnboardingContainerV2,
  SlideAnimation,
} from "@/components/onboarding-v2";

const UnaccreditedSellerAwaitingApprovalPage = () => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `awaiting_approval`,
  });

  return (
    <OnboardingContainerV2 canGoBack={false} metaTitle={t(`welcome_to_hiive`)}>
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`welcome_to_hiive`)}
          </Text>
        </VStack>
        <SlideAnimation>
          <Card>
            <CardBody>
              <Flex direction="column" gap={4}>
                <Text>{t(`thank_you_for_your_interest_u16r`)}</Text>
                <Text>{t(`your_account_is_pending_approval`)}</Text>
                <Text>{t(`our_accounts_team_is_reviewing`)}</Text>
                <Text>
                  <Trans
                    i18nKey="awaiting_approval.please_reach_out"
                    ns="onboarding"
                    components={{
                      mailto: (
                        <Link
                          fontWeight="medium"
                          textDecoration="underline"
                          href="mailto:support@hiive.com"
                        />
                      ),
                    }}
                  />
                </Text>
              </Flex>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
    </OnboardingContainerV2>
  );
};

export default UnaccreditedSellerAwaitingApprovalPage;
