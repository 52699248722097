import { Form } from "formik";
import { useTranslation } from "react-i18next";

import { ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  RevokeAlternateSignerInvitationModalDocumentFragment,
  StandingBidPageStandingBidByIdDocument,
  TransactionPageTransactionByIdDocument,
  useRevokeAlternateSignerInvitationModalMutation,
  UserWithInstitutionFragment,
} from "@/gql";
import { useModal } from "@/hooks";
import { getIsBroker } from "@/utils";

const initialValues = {};
const createMapVariables =
  ({ id }: RevokeAlternateSignerInvitationModalDocumentFragment) =>
  () => ({
    documentId: id,
  });

const RevokeAlternateSignerInvitationModal = withCurrentActor(
  ({
    document,
    actor,
  }: {
    readonly document: RevokeAlternateSignerInvitationModalDocumentFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation();

    const mutation = useRevokeAlternateSignerInvitationModalMutation({
      refetchQueries: [
        StandingBidPageStandingBidByIdDocument,
        TransactionPageTransactionByIdDocument,
      ],
    });

    const { closeModal } = useModal();

    const mapVariables = createMapVariables(document);

    const isBroker = getIsBroker(actor);

    const onSuccess = () => {
      closeModal();
    };

    const confirmationMessage = isBroker
      ? t(`broker_revoke_alternate_signer_invitation_confirmation`)
      : t(`revoke_alternate_signer_invitation_confirmation`);

    const bodyMessage = isBroker
      ? t(`broker_revoke_alternate_signer_invitation_body`)
      : t(`revoke_alternate_signer_invitation_body`);

    return (
      <HiiveModalContentWrapper>
        <FormikQL
          mutation={mutation}
          mutationNames={[`revokeAlternateSignerInvitation`]}
          initialValues={initialValues}
          mapVariables={mapVariables}
          onSuccess={onSuccess}
        >
          {({ isSubmitting }) => (
            <Form>
              <HiiveModalHeader>Confirmation</HiiveModalHeader>
              <ModalBody>
                <VStack alignItems="flex-start" spacing={3}>
                  <Text fontWeight="medium">{confirmationMessage}</Text>
                  <Text>{bodyMessage}</Text>
                </VStack>
              </ModalBody>
              <HiiveModalFooter>
                <Show above="md" ssr={false}>
                  <HiiveCancelButton
                    sentryLabel="[RevokeAlternateSignerInvitation/Cancel]"
                    onCancel={closeModal}
                  />
                </Show>
                <HiiveSubmitButton
                  sentryLabel="[RevokeAlternateSignerInvitation/Submit]"
                  isLoading={isSubmitting}
                  submitText="Confirm"
                  type="submit"
                />
              </HiiveModalFooter>
            </Form>
          )}
        </FormikQL>
      </HiiveModalContentWrapper>
    );
  },
);

export default RevokeAlternateSignerInvitationModal;
