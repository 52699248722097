import { match } from "ts-pattern";

import { SigningProcedure, TransferMethodV2 } from "@/gql";

export function getSigningProcedure(
  transferMethodV2: TransferMethodV2,
  manualSigningProcedure?: boolean,
) {
  if (manualSigningProcedure) {
    return SigningProcedure.Manual;
  }

  return match(transferMethodV2)
    .with(TransferMethodV2.Direct, () => SigningProcedure.Automated)
    .otherwise(() => SigningProcedure.Manual);
}
