/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  MutationTuple,
  OperationVariables,
  QueryResult,
} from "@apollo/client";
import { Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import { ReactElement, useEffect } from "react";

import { Spinner, Center } from "@chakra-ui/react";

import { InputError } from "@/gql";
import { useCustomToast } from "@/hooks";

interface FormikQLProps<
  TMutationData,
  TMutationVariables,
  TQueryData,
  TQueryVariables extends OperationVariables,
  TFormValues extends FormikValues,
> {
  readonly query?: QueryResult<TQueryData, TQueryVariables>;

  readonly mutation: MutationTuple<TMutationData, TMutationVariables>;
  readonly mutationNames: readonly string[];
  readonly mapVariables?: (values: TFormValues) => TMutationVariables;

  readonly children: ({
    data,
  }: {
    readonly data?: TQueryData;
  } & FormikProps<TFormValues>) => ReactElement;

  readonly initialValues: TFormValues;
  readonly validationSchema?: any | ((data: TQueryData) => any);
  readonly onSuccess?: (response: any) => void;
  readonly resetAfterSubmit?: boolean;
}

type RunMutation<TMutationData, TMutationVariables, TFormValues> = (
  options:
    | MutationFunctionOptions<
        TMutationData,
        TMutationVariables,
        DefaultContext,
        ApolloCache<any>
      >
    | {
        readonly variables:
          | TMutationVariables
          | { readonly input: TFormValues };
      }
    | undefined,
) => Promise<
  FetchResult<TMutationData, Record<string, unknown>, Record<string, unknown>>
>;

function onSubmit<
  TFormValues extends FormikValues,
  TMutationData,
  TMutationVariables,
>({
  runMutation,
  mutationNames,
  onSuccess,
  mapVariables,
  onError,
  resetAfterSubmit,
}: {
  readonly runMutation: RunMutation<
    TMutationData,
    TMutationVariables,
    TFormValues
  >;
  readonly mutationNames: readonly string[];
  readonly onSuccess?: (response?: TMutationData | null) => void;
  readonly mapVariables?: (values: Record<string, unknown>) => any;
  readonly onError: (error: string) => void;
  readonly resetAfterSubmit: boolean;
}) {
  return async (values: TFormValues, actions: FormikHelpers<TFormValues>) => {
    const { data } = await runMutation({
      variables: mapVariables?.(values) || { input: values },
    });

    const errors: readonly string[] = mutationNames
      .filter((mutationName) => {
        if ((data as Record<string, any>)[mutationName]) return false;
        return true;
      })
      .map((mutationName) => `mutationName ${mutationName} is invalid`);

    if (errors.length > 0) {
      onError(errors.join(`, `));
      return;
    }

    const mutationErrors = mutationNames.flatMap((mutationName) => {
      const mutationData = (data as Record<string, any>)[mutationName];

      return mutationData.errors ? mutationData.errors : [];
    });

    if (mutationErrors.length > 0) {
      mutationErrors.forEach((err: InputError) =>
        actions.setFieldError(err.field, err.message),
      );
      return;
    }

    onSuccess?.(data);

    if (resetAfterSubmit) actions.resetForm();
  };
}

/**
 * Contains common logic for the integration between Formik and GraphQL.
 * Handles loading and errors.
 */
function FormikQL<
  TMutationData,
  TMutationVariables,
  TQueryData,
  TQueryVariables extends OperationVariables,
  TFormValues extends FormikValues,
>({
  query,
  mutation,
  mutationNames,
  mapVariables,
  children,
  initialValues,
  validationSchema,
  onSuccess,
  resetAfterSubmit = false,
}: FormikQLProps<
  TMutationData,
  TMutationVariables,
  TQueryData,
  TQueryVariables,
  TFormValues
>) {
  const { errorToast } = useCustomToast();

  const [runMutation] = mutation;

  useEffect(() => {
    if (!query?.error) return;
    errorToast(query.error.toString());
  }, [query?.error]);

  if (query?.loading)
    return (
      <Center m={20}>
        <Spinner />
      </Center>
    );

  // eslint-disable-next-line no-param-reassign
  validationSchema =
    typeof validationSchema === `function`
      ? validationSchema(query?.data)
      : validationSchema;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit({
        mutationNames,
        runMutation,
        onError: errorToast,
        onSuccess,
        mapVariables,
        resetAfterSubmit,
      })}
    >
      {(formikProps) => children({ data: query?.data, ...formikProps })}
    </Formik>
  );
}

export default FormikQL;
