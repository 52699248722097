import { useTranslation } from "react-i18next";

import { EntityType } from "@/gql";

const useTranslatedEntityNames = () => {
  const { t } = useTranslation(`account`, {
    keyPrefix: `entities`,
  });

  const getFormattedEntityName = (key: EntityType) => {
    const entityTypesMap: Record<EntityType, string> = {
      [EntityType.Corporation]: t(`corporation`),
      [EntityType.Llc]: t(`llc`),
      [EntityType.Partnership]: t(`partnership`),
      [EntityType.Other]: t(`other`),
      [EntityType.Trust]: t(`trust`),
      [EntityType.Individual]: t(`individual`),
    };

    return entityTypesMap[key];
  };

  return {
    getFormattedEntityName,
  };
};

export default useTranslatedEntityNames;
