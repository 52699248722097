import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardBody, HStack, Text, VStack } from "@chakra-ui/react";

import {
  formatCurrency,
  formatPricePerShare,
  formatShares,
  shareTypeToString,
} from "@/utils";

import { IssuerTransactionContextTransaction } from "./IssuerTransactionProvider";
import { getNoticeReceivedDate } from "./util";

type IssuerTransactionTermsCardProps = {
  transaction: NonNullable<IssuerTransactionContextTransaction>;
};

type IssuerTransactionTermsCardDetailRow = {
  label: string;
  children: ReactNode;
};

function getPurchasePrice(numShares: number, pricePerShare: number) {
  return numShares * pricePerShare;
}

function IssuerTransactionTermsCardDetailRow({
  label,
  children,
}: IssuerTransactionTermsCardDetailRow) {
  return (
    <HStack justifyContent="space-between" w="full">
      <Text fontSize={14}>{label}</Text>
      <Text fontSize={14}>{children}</Text>
    </HStack>
  );
}

function IssuerTransactionTermsCardDetails({
  transaction: { bid, company, numShares, pricePerShare, workflow },
}: IssuerTransactionTermsCardProps) {
  const { t } = useTranslation(`issuers`);

  const { shareTypeV2 } = bid?.listing || {};
  const { transferFee } = company?.issuerPreference || {};
  const purchasePrice = getPurchasePrice(numShares, pricePerShare);

  return (
    <VStack alignItems="flex-start" gap={2} w="full">
      <IssuerTransactionTermsCardDetailRow label={t(`notice_date`)}>
        {workflow && getNoticeReceivedDate(workflow)}
      </IssuerTransactionTermsCardDetailRow>
      <IssuerTransactionTermsCardDetailRow label={t(`holding_type`)}>
        {shareTypeV2 ? shareTypeToString(shareTypeV2) : `–`}
      </IssuerTransactionTermsCardDetailRow>
      <IssuerTransactionTermsCardDetailRow label={t(`transfer_fee`)}>
        {transferFee
          ? formatCurrency(transferFee, {
              precision: 2,
              fromCents: true,
            })
          : `–`}
      </IssuerTransactionTermsCardDetailRow>
      <IssuerTransactionTermsCardDetailRow label={t(`number_of_shares`)}>
        {formatShares(numShares)}
      </IssuerTransactionTermsCardDetailRow>
      <IssuerTransactionTermsCardDetailRow label={t(`price_per_share_pps`)}>
        {formatPricePerShare(pricePerShare)}
      </IssuerTransactionTermsCardDetailRow>
      <HStack justifyContent="space-between" w="full">
        <Text fontSize={14} fontWeight={500}>
          {t(`purchase_price`)}
        </Text>
        <Text fontSize={16} fontWeight={500}>
          {formatCurrency(purchasePrice, {
            precision: 2,
            fromCents: true,
          })}
        </Text>
      </HStack>
    </VStack>
  );
}

export function IssuerTransactionTermsCard({
  transaction,
}: IssuerTransactionTermsCardProps) {
  const { t } = useTranslation(`issuers`);

  return (
    <Card variant="issuer">
      <CardBody>
        <VStack alignItems="flex-start" gap={2} w="full">
          <Text fontSize={16} fontWeight={500}>
            {t(`terms`)}
          </Text>
          <IssuerTransactionTermsCardDetails transaction={transaction} />
        </VStack>
      </CardBody>
    </Card>
  );
}
