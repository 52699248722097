import pluralize from "pluralize";

import { Fade, Text } from "@chakra-ui/react";

const BidCancellationWarning = ({
  numberOfBidsToCancel,
}: {
  readonly numberOfBidsToCancel: number;
}) => (
  <Fade in transition={{ enter: { duration: 0.3 } }}>
    <Text textStyle="text-sm">
      <Text as="strong" fontWeight="medium">
        Warning:{` `}
      </Text>
      The new amount of the number of shares is below the number of shares for
      current bids on your listing. If you proceed,{` `}
      {numberOfBidsToCancel}
      {` `}
      {pluralize(`bid`, numberOfBidsToCancel)}
      {` `}
      on your listing will be cancelled.
    </Text>
  </Fade>
);

export default BidCancellationWarning;
