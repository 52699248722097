import currency from "currency.js";
import { Fragment, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import { Box, GridItem, SimpleGrid, Text } from "@chakra-ui/react";

import {
  SensitiveText,
  SharePrice,
  Skeleton,
  TileHeader,
} from "@/components/common";
import { CustomTooltip } from "@/components/form";
import { FeeDiscountApplicationState, FeeDiscountType } from "@/gql";
import { useFeeDiscountNewProceedsSectionEnabled } from "@/hooks/featureFlags";

import FeeBreakdownDisclaimer, {
  DisclaimerType,
} from "./FeeBreakdownDisclaimer";
import mapFeeDiscountApplications, {
  FeeDiscountApplicationItems,
} from "./mapFeeDiscountApplications";

const BOX_STYLES = {
  alignItems: `baseline`,
  gridTemplateColumns: `1fr min-content`,
  borderColor: `grey.200`,
  borderWidth: 0.5,
  borderBottomWidth: 0,
  px: 6,
  py: 4,
  overflow: `hidden`,
};

const FIRST_ITEM_STYLES = {
  borderTopRadius: `md`,
};

const LAST_ITEM_STYLES = {
  borderBottomRadius: `md`,
  borderBottomWidth: BOX_STYLES.borderWidth,
};

const FIRST_ITEM_STANDALONE_STYLES = {
  borderTopColor: `grey.300`,
};
const LAST_ITEM_STANDALONE_STYLES = {
  borderBottomWidth: BOX_STYLES.borderWidth,
};

function formatCurrencyCents(value: number) {
  return currency(value, { fromCents: true }).format();
}

const SKELETON_STYLE = {
  h: 4,
  w: 40,
};

type GridItemRowProps = {
  readonly isLoading?: boolean;
  readonly label?: string;
  readonly subtitle?: string;
  readonly items: readonly {
    readonly label: ReactNode;
    readonly tooltip?: string;
    readonly value: number;
    readonly discount?: boolean;
    readonly lineThrough?: boolean;
  }[];
};

function GridItemRow({ isLoading, label, subtitle, items }: GridItemRowProps) {
  return (
    <>
      {label && (
        <GridItem colSpan={2}>
          <TileHeader color="grey.400">{label}</TileHeader>
          {subtitle && (
            <SensitiveText textStyle="text-sm" textTransform="none">
              {subtitle}
            </SensitiveText>
          )}
        </GridItem>
      )}
      {items.map(({ label, tooltip, value, discount, lineThrough }) => (
        <Fragment key={`${label}-${value}`}>
          <GridItem>
            <CustomTooltip tooltipContent={tooltip}>
              <TileHeader variant={tooltip ? `tooltip` : ``}>
                {label}
              </TileHeader>
            </CustomTooltip>
          </GridItem>

          {isLoading ? (
            <Skeleton {...SKELETON_STYLE} />
          ) : (
            <GridItem justifySelf="end">
              {discount ? (
                <SensitiveText textAlign="right">
                  ({formatCurrencyCents(value)})
                </SensitiveText>
              ) : (
                <SensitiveText
                  textAlign="right"
                  textDecoration={lineThrough ? `line-through` : `none`}
                >
                  {formatCurrencyCents(value)}
                </SensitiveText>
              )}
            </GridItem>
          )}
        </Fragment>
      ))}
    </>
  );
}

enum GridItemProceedType {
  Gross = `Gross`,
  Net = `Net`,
}

type GridItemProceedsProps = {
  readonly type: GridItemProceedType;
  readonly isLoadingAmount?: boolean;
  readonly isLoadingPps?: boolean;
  readonly label: string;
  readonly tooltip?: string;
  readonly subtitle?: string;
  readonly newNumShares?: number;
  readonly newPricePerShare?: number;
  readonly numShares: number;
  readonly pricePerShare: number;
  readonly amount: number;
};

function GridItemProceeds({
  type,
  isLoadingAmount,
  isLoadingPps,
  label,
  tooltip,
  subtitle,
  amount,
  newNumShares,
  newPricePerShare,
  numShares,
  pricePerShare,
}: GridItemProceedsProps) {
  const feeDiscountNewProceedsSectionEnabled =
    useFeeDiscountNewProceedsSectionEnabled();
  const { t } = useTranslation();

  const items =
    feeDiscountNewProceedsSectionEnabled && type === GridItemProceedType.Net
      ? [
          {
            label: t(`net_price_per_share_base`),
            value: newPricePerShare || pricePerShare,
          },
          {
            label: t(`net_proceeds`),
            value: amount,
          },
        ]
      : [
          {
            label: isLoadingPps ? (
              <Skeleton {...SKELETON_STYLE} />
            ) : (
              <SharePrice
                numShares={numShares}
                pricePerShare={pricePerShare}
                newNumShares={newNumShares}
                newPricePerShare={newPricePerShare}
              />
            ),
            tooltip,
            value: amount,
          },
        ];

  return (
    <GridItemRow
      isLoading={isLoadingAmount}
      label={label}
      subtitle={subtitle}
      items={items}
    />
  );
}

type FeeBreakdownProps = {
  readonly isLoading?: boolean;

  readonly newNumShares?: number;
  readonly newPricePerShare?: number;

  readonly numShares?: number | null;
  readonly pricePerShare?: number | null;
  readonly feeDiscountApplications?: FeeDiscountApplicationItems;
  readonly feeDiscountApplicationState?: FeeDiscountApplicationState | null;
  readonly initial?: boolean;

  readonly flatFeeAmount?: number | null;
  readonly commissionAmount?: number | null;
  readonly feeDiscountAmount?: number | null;
  readonly netFees?: number | null;

  readonly disclaimer?: ReactNode;
  readonly disclaimerVariant?: keyof typeof DisclaimerType;

  readonly grossProceedsTitle?: string;
  readonly hiiveFeesTitle?: string;
  readonly netProceedsTitle?: string;
  readonly netProceedsSubtitle?: string;

  readonly grossProceedsTooltip?: string;
  readonly flatFeeAmountTooltip?: string;
  readonly commissionAmountTooltip?: string;
  readonly netProceedsTooltip?: string;

  readonly combineHiiveFeesLabel?: string;
  readonly combineHiiveFeesTooltip?: string;

  readonly isStandaloneTop?: boolean;
  readonly isStandaloneBottom?: boolean;

  readonly isHighFeesWarningVisible?: boolean;
};

export default function FeeBreakdown({
  isLoading,

  newNumShares,
  newPricePerShare,

  numShares: initialNumShares,
  pricePerShare: initialPricePerShare,
  feeDiscountApplications,
  feeDiscountApplicationState,
  initial,

  flatFeeAmount: initFlatFeeAmount,
  commissionAmount: initCommissionAmount,
  netFees: initialNetFees,

  disclaimer,
  disclaimerVariant,

  grossProceedsTitle,
  hiiveFeesTitle,
  netProceedsTitle,
  netProceedsSubtitle,

  grossProceedsTooltip,
  flatFeeAmountTooltip,
  commissionAmountTooltip,
  netProceedsTooltip,

  combineHiiveFeesLabel,
  combineHiiveFeesTooltip,

  isStandaloneTop,
  isStandaloneBottom,

  isHighFeesWarningVisible,
}: FeeBreakdownProps) {
  const { t } = useTranslation();

  const commissionAmount = initCommissionAmount || 0;
  const flatFeeAmount = initFlatFeeAmount || 0;
  const numShares = initialNumShares || 0;
  const pricePerShare = initialPricePerShare || 0;
  const netFees = initialNetFees || 0;

  const finalNumShares = newNumShares ?? numShares;
  const finalPricePerShare = newPricePerShare ?? pricePerShare;

  const grossProceeds = finalPricePerShare * finalNumShares;
  const netProceeds = grossProceeds - netFees > 0 ? grossProceeds - netFees : 0;
  const netPricePerShare =
    (finalNumShares && Math.trunc(netProceeds / finalNumShares)) || 0;

  const mappedFeeDiscountApplications = mapFeeDiscountApplications(
    feeDiscountApplications,
    initial,
    feeDiscountApplicationState,
  );

  const isOverride = mappedFeeDiscountApplications.some(
    ({ type }) => type === FeeDiscountType.OverrideCommission,
  );

  const totalBeforeOverride = flatFeeAmount + commissionAmount;
  const isOverrideHigherFee = isOverride && netFees > totalBeforeOverride;

  const feeItems = match([isOverrideHigherFee, combineHiiveFeesLabel])
    .with([true, P._], () => [])
    .with([false, P.string], () => [
      {
        label: combineHiiveFeesLabel,
        tooltip: combineHiiveFeesTooltip,
        value: totalBeforeOverride,
        lineThrough: isOverride,
      },
    ])
    .otherwise(() => [
      {
        label: t(`base_fee`),
        tooltip: flatFeeAmountTooltip,
        value: flatFeeAmount,
        lineThrough: isOverride,
      },
      {
        label: t(`hiive_commission`),
        tooltip: commissionAmountTooltip,
        value: commissionAmount,
        lineThrough: isOverride,
      },
    ]);

  return (
    <Box>
      <SimpleGrid
        {...BOX_STYLES}
        {...{
          ...(isStandaloneTop
            ? FIRST_ITEM_STANDALONE_STYLES
            : FIRST_ITEM_STYLES),
        }}
      >
        <GridItemProceeds
          type={GridItemProceedType.Gross}
          isLoadingAmount={isLoading}
          label={grossProceedsTitle || t(`gross_sale_amount`)}
          tooltip={grossProceedsTooltip}
          newNumShares={newNumShares}
          newPricePerShare={newPricePerShare}
          numShares={numShares}
          pricePerShare={pricePerShare}
          amount={grossProceeds}
        />
      </SimpleGrid>

      <SimpleGrid {...BOX_STYLES} bg="grey.50">
        <GridItemRow
          isLoading={isLoading}
          label={hiiveFeesTitle || t(`hiive_fees`)}
          items={feeItems}
        />
        {!!mappedFeeDiscountApplications.length && (
          <GridItemRow
            isLoading={isLoading}
            items={mappedFeeDiscountApplications.map(({ amount, name }) => ({
              label: name,
              value: isOverride ? netFees : amount || 0,
              discount: !isOverride,
            }))}
          />
        )}
      </SimpleGrid>

      <SimpleGrid {...BOX_STYLES} bg="grey.50">
        <GridItemRow
          isLoading={isLoading}
          items={[{ label: t(`total_hiive_fees`), value: netFees }]}
        />
      </SimpleGrid>

      <SimpleGrid
        {...BOX_STYLES}
        {...(disclaimerVariant || disclaimer ? {} : LAST_ITEM_STYLES)}
        bg="teal.25"
      >
        <GridItemProceeds
          type={GridItemProceedType.Net}
          isLoadingAmount={isLoading}
          isLoadingPps={isLoading}
          label={netProceedsTitle || t(`net_amount`)}
          tooltip={
            netProceedsTooltip || t(`approximate_net_proceeds_tooltip_v2`)
          }
          subtitle={netProceedsSubtitle}
          newNumShares={newNumShares}
          numShares={numShares}
          pricePerShare={netPricePerShare}
          amount={netProceeds}
        />
      </SimpleGrid>

      {(disclaimerVariant || disclaimer) && (
        <SimpleGrid
          {...BOX_STYLES}
          {...{
            ...(isStandaloneBottom
              ? LAST_ITEM_STANDALONE_STYLES
              : LAST_ITEM_STYLES),
          }}
        >
          {isHighFeesWarningVisible && (
            <GridItem colSpan={2}>
              <Text color="salmon.900" textStyle="text-sm" mb={3}>
                {t(`high_fees_warning_message`)}
              </Text>
            </GridItem>
          )}

          <GridItem colSpan={2}>
            {disclaimerVariant && (
              <FeeBreakdownDisclaimer variant={disclaimerVariant} />
            )}
            {disclaimer}
          </GridItem>
        </SimpleGrid>
      )}
    </Box>
  );
}
