import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { HStack } from "@chakra-ui/react";

export const MobileNavBarWrapper = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <HStack
      w="full"
      h="mobile-navbar-height"
      bg="white"
      px={4}
      borderBottomWidth="0.5px"
      borderColor="grey.200"
      justifyContent="space-between"
      aria-label={t(`mobile_navigation_menu`)}
    >
      {children}
    </HStack>
  );
};
