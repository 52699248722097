import { useTranslation } from "react-i18next";

import { HiiveButton } from "@/components/common";
import {
  AddEntityInput,
  CurrentContextDocument,
  TransactionPageTransactionByIdDocument,
  useAddEntityMutation,
} from "@/gql";
import { useCustomToast, useMutationWithError } from "@/hooks";

type CreateSuggestedEntityConfirmationButtonProps = {
  input: AddEntityInput;
};
/**
 * Creates the suggested entity when a user has no non archived entities
 */
const CreateSuggestedEntityConfirmationButton = ({
  input,
}: CreateSuggestedEntityConfirmationButtonProps) => {
  const { t } = useTranslation(`account`);

  const { successToast } = useCustomToast();
  const onSuccess = () => {
    successToast(t(`entities.entity_saved_successfully`));
  };
  const [addEntityMutation, isSubmitting] = useMutationWithError(
    useAddEntityMutation({
      refetchQueries: [
        CurrentContextDocument,
        TransactionPageTransactionByIdDocument,
      ],
    }),
    `addEntity`,
  );

  const handleSubmit = async () => {
    await addEntityMutation({
      variables: {
        input,
      },
    });
    onSuccess();
  };

  return (
    <HiiveButton
      onClick={handleSubmit}
      isLoading={isSubmitting}
      maxW="unset"
      sentryLabel="[TransactingEntityCard] Confirm"
      size="xl"
      variant="rounded-solid-salmon"
      w={{ base: `full`, md: `170px` }}
    >
      {t(`confirm`, {
        ns: `translation`,
      })}
    </HiiveButton>
  );
};

export default CreateSuggestedEntityConfirmationButton;
