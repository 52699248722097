import { ApolloError } from "@apollo/client";
import { createContext, ReactNode, useMemo } from "react";

import {
  useIssuerTransactionPageQuery,
  IssuerTransactionPageQuery,
} from "@/gql";

export type IssuerTransactionContextTransaction = ReturnType<
  typeof mapIssuerTransaction
>;

const mapIssuerTransaction = (data?: IssuerTransactionPageQuery) => {
  if (!data?.transactionById) {
    return null;
  }

  return {
    ...data.transactionById,
  };
};

type IssuerTransactionContext = {
  error?: ApolloError;
  loading: boolean;
  transaction: IssuerTransactionContextTransaction;
};

const DEFAULT_CONTEXT: IssuerTransactionContext = {
  error: undefined,
  loading: false,
  transaction: null,
};

export const IssuerTransactionContext =
  createContext<IssuerTransactionContext>(DEFAULT_CONTEXT);

interface IssuerTransactionProviderProps {
  readonly children: ReactNode;
  readonly transactionId: string;
}

export function IssuerTransactionProvider({
  children,
  transactionId,
}: IssuerTransactionProviderProps) {
  const { data, error, loading } = useIssuerTransactionPageQuery({
    variables: { id: transactionId },
  });

  const contextValue = useMemo(
    () => ({ error, loading, transaction: mapIssuerTransaction(data) }),
    [data, error, loading],
  );

  return (
    <IssuerTransactionContext.Provider value={contextValue}>
      {children}
    </IssuerTransactionContext.Provider>
  );
}
