import { useUser } from "@frigade/react";
import { Form } from "formik";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  IndividualTraderCustomerAgreement,
  HiiveButton,
} from "@/components/common";
import { FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { TraderAgreement } from "@/components/onboarding-v2";
import {
  CurrentContextDocument,
  UserWithInstitutionFragment,
  useUpdateAgreedToCustomerAgreementMutation,
} from "@/gql";
import { useNewTermsAndConditions } from "@/hooks/featureFlags";

const initialValues = { agreedToCustomerAgreement: true };

const mapVariables = ({
  agreedToCustomerAgreement,
}: {
  readonly agreedToCustomerAgreement: boolean;
}) => ({ agreedToCustomerAgreement });

export const IndividualTraderCustomerAgreementCard = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { addPropertiesToUser } = useUser();
    const showNewTermsAndConditions = useNewTermsAndConditions();

    const { t } = useTranslation();

    const mutation = useUpdateAgreedToCustomerAgreementMutation({
      refetchQueries: [CurrentContextDocument],
    });

    const onSuccess = () =>
      addPropertiesToUser({ agreedToCustomerAgreement: true });

    if (actor.agreedToCustomerAgreement) {
      return (
        <Card w="full" flex="1">
          <CardHeader>
            <Text textStyle="heading-sm">{t(`customer_agreement`)}</Text>
          </CardHeader>
          <CardBody p={{ base: 4, lg: 10 }}>
            <VStack spacing={4} alignItems="flex-start">
              <Box>
                <Text mb={4}>You have agreed to the Customer Agreement.</Text>
                <Text>
                  By signing the Customer Agreement, you have acknowledged and
                  accepted that it incorporates Hiive&apos;s{` `}
                  <Link
                    href="/terms-and-conditions"
                    textStyle="heading-md"
                    target="_blank"
                  >
                    Terms and Conditions
                  </Link>
                  , and consent to be bound by them.
                </Text>
              </Box>
              {showNewTermsAndConditions ? (
                <Box
                  borderWidth="0.5px"
                  borderColor="grey.200"
                  borderRadius="md"
                  p={4}
                  bg="white"
                  overflowX="auto"
                >
                  <TraderAgreement isClickwrapAgreement={false} />
                </Box>
              ) : (
                <IndividualTraderCustomerAgreement />
              )}
            </VStack>
          </CardBody>
        </Card>
      );
    }
    return (
      <Card w="full" flex="1">
        <CardHeader>
          <Text textStyle="heading-sm">Customer Agreement</Text>
        </CardHeader>
        <CardBody p={{ base: 4, lg: 10 }}>
          <VStack spacing={4} alignItems="flex-start">
            <Box>
              <Text mb={4}>
                {` `}
                Before you can post your own listing, or respond to potential
                buyers’ bids, you must read and agree to the Customer Agreement.
              </Text>
              <Text>
                The Customer Agreement references the Hiive Terms and Conditions
                which can be found{` `}
                <Link
                  fontWeight="medium"
                  href="/terms-and-conditions"
                  target="_blank"
                >
                  here
                </Link>
                .
              </Text>
            </Box>
            <IndividualTraderCustomerAgreement />
            <FormikQL
              initialValues={initialValues}
              mapVariables={mapVariables}
              mutation={mutation}
              mutationNames={[`updateAgreedToCustomerAgreement`]}
              onSuccess={onSuccess}
            >
              {({ isSubmitting }) => (
                <Form autoComplete="off">
                  <Text textAlign="center" my={4}>
                    By clicking the &quot;agree&quot; button below I acknowledge
                    that I understand and agree to the above.
                  </Text>
                  <HiiveButton
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    sentryLabel="[IndividualTraderCustomerAgreementPage] Next"
                    size="md"
                    type="submit"
                    variant="rounded-solid-salmon"
                  >
                    Agree
                  </HiiveButton>
                </Form>
              )}
            </FormikQL>
          </VStack>
        </CardBody>
      </Card>
    );
  },
);
