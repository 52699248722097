import { useState, useEffect } from "react";

import { useRouter } from "next/router";

import { Box, useMultiStyleConfig } from "@chakra-ui/react";

import { useIsDesktop } from "@/hooks";
import { FrontChat } from "@/hooks/front";

// Company summary page /dashboard/.., /companies/[..id], /discussions/[..id] /standing-bids/[..id] /listings/[..id] /listings/bids/[..id] /transactions/[..id]
const mobileNavRegex =
  /\/dashboard|(dashboard\/(active-bids|transactions|inquiries|standing-bids|past-pids)|(companies|discussions|standing-bids|listings|listings\/bids|transactions)\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/;

export const FrontChatIconSvg = () => (
  <svg width="40" height="40" role="button">
    <defs>
      <filter
        id="chat-logo-a"
        data-testid="chat-logo-shadow"
        width="127.8%"
        height="127.8%"
        x="-13.9%"
        y="-11.1%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="1.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
        />
      </filter>
    </defs>
    <g fill="none" transform="translate(-11 -12)">
      <use
        data-testid="chat-logo-svg-shadow"
        fill="#000"
        filter="url(#chat-logo-a)"
        href="#chat-logo-b"
      />
      <path
        fill="#FFF"
        d="M32,18 C24.2680135,18 18,24.2680135 18,32 C18,39.7319865 24.2680135,46 32,46 L45.6,46 C45.8209139,46 46,45.8209139 46,45.6 L46,32 C46,24.2680135 39.7319865,18 32,18 Z M32,14 C41.9411255,14 50,22.0588745 50,32 L50,45.6 C50,48.0300529 48.0300529,50 45.6,50 L32,50 C22.0588745,50 14,41.9411255 14,32 C14,22.0588745 22.0588745,14 32,14 Z M25.9634146,31.5853659 L37.9634146,31.5853659 C39.0679841,31.5853659 39.9634146,30.6899354 39.9634146,29.5853659 C39.9634146,28.4807964 39.0679841,27.5853659 37.9634146,27.5853659 L25.9634146,27.5853659 C24.8588451,27.5853659 23.9634146,28.4807964 23.9634146,29.5853659 C23.9634146,30.6899354 24.8588451,31.5853659 25.9634146,31.5853659 Z M31.195122,38.8292683 L37.195122,38.8292683 C38.2996915,38.8292683 39.195122,37.9338378 39.195122,36.8292683 C39.195122,35.7246988 38.2996915,34.8292683 37.195122,34.8292683 L31.195122,34.8292683 C30.0905525,34.8292683 29.195122,35.7246988 29.195122,36.8292683 C29.195122,37.9338378 30.0905525,38.8292683 31.195122,38.8292683 Z"
      />
    </g>
  </svg>
);

const hasBottomMenu = (router: { asPath: string }): boolean =>
  router.asPath.match(mobileNavRegex) !== null;

export const FrontChatLauncher = ({
  isInitialized,
  frontChat,
}: {
  readonly isInitialized: boolean;
  readonly frontChat: FrontChat;
}) => {
  const isDesktop = useIsDesktop();
  const router = useRouter();

  const variant = !hasBottomMenu(router) || isDesktop ? `desktop` : `mobile`;

  const [showChat, setShowChat] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const { pagePosition, iconContainer } = useMultiStyleConfig(
    `FrontChatLauncher`,
    { variant },
  );

  useEffect(() => {
    if (isInitialized) {
      frontChat(
        `onWindowVisibilityChanged`,
        (params: { is_window_visible: boolean }) => {
          if (params.is_window_visible === false) {
            frontChat(`hide`);
            setShowChat(false);
          }
        },
      );
    }
  }, [showChat]);

  useEffect(() => {
    if (isInitialized) {
      frontChat(`onUnreadChange`, (params: { unread_count: number }) => {
        if (
          isDesktop &&
          params.unread_count > 0 &&
          params.unread_count > unreadCount
        ) {
          frontChat(`show`);
          setShowChat(true);
          setUnreadCount(params.unread_count);
        }
      });
    }
  }, [isInitialized, unreadCount]);

  // Launch button will hide when chat window is shown
  return (
    isInitialized &&
    !showChat && (
      <Box __css={pagePosition}>
        <Box
          onClick={() => {
            frontChat(`show`);
            setShowChat(true);
          }}
          __css={iconContainer}
        >
          <div role="button" style={{ padding: `9px` }}>
            <FrontChatIconSvg />
          </div>
        </Box>
      </Box>
    )
  );
};
