import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { VStack } from "@chakra-ui/react";

import { BackButton } from "@/components/common";
import { ROUTES } from "@/utils";

import { useQueryParams } from "./useQueryParams";

type IssuerTransactionPageContentProps = {
  children: ReactNode;
};

export function IssuerTransactionPageContent({
  children,
}: IssuerTransactionPageContentProps) {
  const { t } = useTranslation(`issuers`);
  const { queryParams } = useQueryParams();
  const { stage } = queryParams;

  const getBackUrl = useCallback(
    () =>
      stage
        ? `${ROUTES.ISSUER_TRANSACTIONS}?stage=${stage}`
        : ROUTES.ISSUER_TRANSACTIONS,
    [stage],
  );

  return (
    <VStack w="full" h="full" mt={{ base: 7, md: 10, xl: 12 }}>
      <VStack
        maxW="1032px"
        w="full"
        spacing={{ base: 6, md: 8 }}
        alignItems="flex-start"
      >
        <BackButton
          label={t(`back_to_transactions`)}
          px={{ base: 4, xl: 0 }}
          url={getBackUrl()}
        />
        <VStack w="full" alignItems="flex-start" gap={{ base: 3, md: 4 }}>
          {children}
        </VStack>
      </VStack>
    </VStack>
  );
}
