export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any;
};

/** The various kinds of input validation errors that can occur */
export enum ErrorType {
  Confirmation = `CONFIRMATION`,
  Exclusion = `EXCLUSION`,
  Format = `FORMAT`,
  Inclusion = `INCLUSION`,
  Length = `LENGTH`,
  Number = `NUMBER`,
  Other = `OTHER`,
  Required = `REQUIRED`,
  Subset = `SUBSET`,
}

export enum TradeIntentType {
  Buy = `BUY`,
  Sell = `SELL`,
}

export type InputError = {
  __typename?: "InputError";
  /** The originating field for the error */
  field: Scalars["String"];
  /** A description of the error */
  message: Scalars["String"];
  /** Validation type */
  type?: Maybe<ErrorType>;
};

/** Login inputs */
export type LoginInput = {
  /** Email address */
  email: Scalars["String"];
  /** Password for user */
  password: Scalars["String"];
};

export type RootMutationType = {
  __typename?: "RootMutationType";
  /** Login the user with the supplied credentials */
  login?: Maybe<SessionMutationResponse>;
  /**
   * Updates the existing users password, invalidating the current session and forcing a sign
   * in during the process
   */
  updatePassword?: Maybe<UserMutationResponse>;
};

export type RootMutationTypeLoginArgs = {
  input: LoginInput;
};

export type RootMutationTypeUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};

export type RootQueryType = {
  __typename?: "RootQueryType";
  clientConfig: Scalars["ID"];
};

/** Represents a user session, including a token, refresh token and a currrent user */
export type Session = {
  __typename?: "Session";
  /** Long lived refresh token to be persisted */
  refreshToken: Scalars["String"];
  /** JWT for the authed user */
  token: Scalars["String"];
  /** The authed user */
  user: User;
};

/** A session, but with errors attached */
export type SessionMutationResponse = {
  __typename?: "SessionMutationResponse";
  errors?: Maybe<Array<InputError>>;
  session?: Maybe<Session>;
};

/** Inputs for updating a password for an auth'd user */
export type UpdatePasswordInput = {
  /** Current password */
  currentPassword: Scalars["String"];
  /** Password */
  password: Scalars["String"];
  /** Password confirmation */
  passwordConfirmation: Scalars["String"];
};

/** A signed up user */
export type User = {
  __typename?: "User";
  email: Scalars["String"];
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  name: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

/** User response, but with errors */
export type UserMutationResponse = {
  __typename?: "UserMutationResponse";
  errors?: Maybe<Array<InputError>>;
  user?: Maybe<User>;
};
