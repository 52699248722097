import { useAuthUserOrNull } from "@frontegg/nextjs";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useFronteggMutation } from "./useFronteggMutation";
import useMfaDevices from "./useMfaDevices";

interface PreDisableSmsMfaResponse {
  readonly errors: string[];
  readonly otcToken: string;
  readonly phoneNumber: string;
}

const usePreDisableSmsMfa = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: PreDisableSmsMfaResponse, lastFourDigits?: string) => void;
  onError?: (error: Error) => void;
}) => {
  const { t } = useTranslation();

  const { state, setState, execute } = useFronteggMutation<
    null,
    PreDisableSmsMfaResponse
  >();

  const user = useAuthUserOrNull();
  const getMfaDevices = useMfaDevices();

  const executePreDisableMfa = useCallback(
    async (apiUrl: string, lastFourDigits?: string) =>
      execute(apiUrl, {
        fallbackErrorMessage: t(`failed_to_disable_mfa`),
        onSuccess: (data) => {
          onSuccess?.(data, lastFourDigits);
        },
        onError,
      }),
    [execute],
  );

  const preDisableMfa = useCallback(
    async (lastFourDigits?: string) => {
      const mfaDevices = await getMfaDevices();

      if (mfaDevices instanceof Error) {
        return setState((currentState) => ({
          ...currentState,
          error: new Error(mfaDevices.message),
        }));
      }

      const { phones } = mfaDevices;

      if (phones.length === 0) {
        return setState((currentState) => ({
          ...currentState,
          error: new Error(t(`no_mfa_device_found`)),
        }));
      }

      const [{ id: deviceId }] = phones;

      const apiUrl = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/sms/${deviceId}/disable`;

      return executePreDisableMfa(apiUrl, lastFourDigits);
    },
    [t, user],
  );

  return [preDisableMfa, state] as const;
};

export default usePreDisableSmsMfa;
