import { useMemo } from "react";

import { client, createClient } from "@/apollo-client";

import { getApiUrl } from "./useServerPreview";
import { useToken } from "./useToken";

export function createApolloClient(apiUrl: string | null, token: string) {
  return createClient(apiUrl || ``, token);
}

export function useApolloClient() {
  const token = useToken();

  return useMemo(() => {
    const apiUrl = getApiUrl();

    if (token) {
      return createApolloClient(apiUrl, token);
    }

    return client;
  }, [token]);
}
