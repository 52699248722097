import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Fade,
  Show,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveButton,
  UnableToTransact,
  UnaccreditedSellerComplianceReminder,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  UnaccreditedSellerListForSaleSvg,
  UnaccreditedSellerListForSaleSvgLarge,
} from "@/components/svg";
import {
  UnaccreditedSellerCompanyByIdActionsFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { TradeIntentType } from "@/types";

interface ListYourSharesCardProps {
  readonly actor: UserWithInstitutionFragment;
  readonly singleColumn: boolean;
  readonly id: string;
  readonly company: Pick<
    UnaccreditedSellerCompanyByIdActionsFragment,
    | `id`
    | `securitySpecialist`
    | `name`
    | `tradeableSellSide`
    | `myPermissionsV2`
  >;
}

export const ListYourSharesCardV2 = withCurrentActor(
  ({ actor, singleColumn, id, company }: ListYourSharesCardProps) => {
    const router = useRouter();
    const { t } = useTranslation();

    const hasVerifiedIdentity = actor.identityVerified;

    return (
      <Card
        position="relative"
        overflow="hidden"
        h="full"
        minH={singleColumn ? { md: 88 } : undefined}
      >
        <CardBody h="full" position="relative">
          <VStack
            alignItems="flex-start"
            justifyContent="space-between"
            h="full"
            spacing={5}
          >
            <VStack alignItems="flex-start" h="full" spacing={3}>
              <Text textStyle="heading-3xl">
                {t(`list_your_shares_for_sale`)}
              </Text>
              <Text
                textStyle="text-md"
                maxW={{ base: `unset`, xl: `55%` }}
                zIndex="1"
              >
                {t(`list_your_shares_for_sale_description`)}
              </Text>
            </VStack>
            {hasVerifiedIdentity && company.tradeableSellSide && (
              <Box>
                <HiiveButton
                  variant="rounded-solid-salmon"
                  size="xl"
                  px={16}
                  onClick={() => router.push(`/create-listing/${id}`)}
                  sentryLabel="[UnaccreditedSellerDashboardPage/ListYourSharesCard] Create Listing"
                >
                  {t(`create_listing`)}
                </HiiveButton>
              </Box>
            )}
            {hasVerifiedIdentity && !company.tradeableSellSide && (
              <Box zIndex={2}>
                <UnableToTransact
                  company={company}
                  tradeIntent={TradeIntentType.Sell}
                />
              </Box>
            )}
          </VStack>
          <Show above="xl" ssr={false}>
            <Fade in>
              <Box
                position="absolute"
                zIndex="0"
                h="full"
                w="50%"
                right="0"
                top="0"
                preserveAspectRatio="xMinYMid slice"
                as={
                  singleColumn
                    ? UnaccreditedSellerListForSaleSvgLarge
                    : UnaccreditedSellerListForSaleSvg
                }
              />
            </Fade>
          </Show>
        </CardBody>

        {!hasVerifiedIdentity && (
          <CardFooter bg="grey.25">
            <UnaccreditedSellerComplianceReminder />
          </CardFooter>
        )}
      </Card>
    );
  },
);
