import { Trans, useTranslation } from "react-i18next";

import { Link, Text } from "@chakra-ui/react";

import { CollapsibleInlineText } from "@/components/common";

const TermsAndConditionsLink = ({
  children,
}: {
  readonly children?: JSX.Element;
}) => (
  <Link
    href="/terms-and-conditions#fees-and-commissions"
    textStyle="text-xs"
    textDecoration="underline"
    target="_blank"
  >
    {children}
  </Link>
);

export enum DisclaimerType {
  brokerSeller = `brokerSeller`,
  seller = `seller`,
  unaccreditedSeller = `unaccreditedSeller`,
  createListingSeller = `createListingSeller`,
  listingSeller = `listingSeller`,
  listingBroker = `listingBroker`,
  acceptStandingBid = `acceptStandingBid`,
  counterBid = `counterBid`,
}

const DEFAULT_TEMPLATE_TEXT_COMPONENTS: Record<string, JSX.Element> = {
  italic: <TermsAndConditionsLink />,
};

type DisclaimerVariant = {
  readonly collapsedText: string;
  readonly expandedText: string;
  readonly expandedTextComponents?: typeof DEFAULT_TEMPLATE_TEXT_COMPONENTS;
};

const DISCLAIMER_VARIANT_MAP: Record<DisclaimerType, DisclaimerVariant> = {
  [DisclaimerType.brokerSeller]: {
    collapsedText: `broker_seller_collapsed_disclaimer`,
    expandedText: `broker_seller_expanded_disclaimer`,
  },
  [DisclaimerType.seller]: {
    collapsedText: `seller_collapsed_disclaimer`,
    expandedText: `seller_expanded_disclaimer`,
  },
  [DisclaimerType.unaccreditedSeller]: {
    collapsedText: `listing_proceeds_detailed_collapsed`,
    expandedText: `listing_proceeds_detailed_expanded`,
  },
  [DisclaimerType.createListingSeller]: {
    collapsedText: `create_listing_collapsed_disclaimer`,
    expandedText: `listing_seller_expanded_disclaimer`,
  },
  [DisclaimerType.listingSeller]: {
    collapsedText: `listing_seller_collapsed_disclaimer`,
    expandedText: `listing_seller_expanded_disclaimer`,
  },
  [DisclaimerType.listingBroker]: {
    collapsedText: `listing_broker_collapsed_disclaimer`,
    expandedText: `listing_broker_expanded_disclaimer`,
  },
  [DisclaimerType.acceptStandingBid]: {
    collapsedText: `standing_bid_fee_info_collapsed`,
    expandedText: `standing_bid_fee_info_expanded`,
  },
  [DisclaimerType.counterBid]: {
    collapsedText: `counter_bid_collapsed_disclaimer`,
    expandedText: `counter_bid_expanded_disclaimer`,
  },
} as const;

type FeeBreakdownDisclaimerProps = Partial<DisclaimerVariant> & {
  readonly variant?: keyof typeof DisclaimerType;
};

export default function FeeBreakdownDisclaimer({
  variant,
  ...props
}: FeeBreakdownDisclaimerProps) {
  const { t } = useTranslation();

  const {
    collapsedText = ``,
    expandedText = ``,
    expandedTextComponents = DEFAULT_TEMPLATE_TEXT_COMPONENTS,
  } = variant ? DISCLAIMER_VARIANT_MAP[variant] : { ...props };

  return (
    <CollapsibleInlineText
      collapsedText={t(collapsedText as unknown as TemplateStringsArray)}
      expandedText={
        <Text as="span" align="left" textStyle="text-xs">
          {expandedText && (
            <Trans
              i18nKey={expandedText as unknown as undefined}
              components={expandedTextComponents}
            />
          )}
        </Text>
      }
      label={{ collapsed: t(`read_more`), expanded: t(`view_less`) }}
    />
  );
}
