import { WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Button, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PIN_LENGTH,
} from "@/components/common";
import { RELOAD_DELAY_MS } from "@/components/frontegg";
import { useCountDown, useModal } from "@/hooks";
import {
  MfaToastKeys,
  useDisableSmsMfa,
  usePreDisableSmsMfa,
  VALID_TOAST_FLAG,
} from "@/hooks/frontegg";

import { SendNewCode } from "./SendNewCode";

interface ModalBodyWithCodeChallengeProps {
  readonly lastFourDigits: string;
  readonly loading: boolean;
  readonly onOpenDisableAllMfaModal: () => void;
  readonly onPinChange: (value: string) => void;
  readonly onPinComplete: (value: string) => void;
  readonly onSendNewCode: () => void;
  readonly timeLeft: number;
}

const ModalBodyWithCodeChallenge = ({
  lastFourDigits,
  loading,
  onOpenDisableAllMfaModal,
  onPinChange,
  onPinComplete,
  onSendNewCode,
  timeLeft,
}: ModalBodyWithCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Trans
        i18nKey="remove_sms_description_with_code"
        t={t}
        components={{
          a: (
            <Button
              as="span"
              cursor="pointer"
              fontWeight={400}
              textDecoration="underline"
              variant="text-ghost"
              whiteSpace="none"
              onClick={onOpenDisableAllMfaModal}
              paddingBottom={2}
            />
          ),
        }}
        values={{ lastFourDigits }}
      />
      <CodeInput
        disabled={loading}
        onChange={onPinChange}
        onComplete={onPinComplete}
      />
      <SendNewCode
        onSendNewCode={onSendNewCode}
        sentryLabel="[DisableSmsMfaVerify/SendCode]"
        timeLeft={timeLeft}
      />
    </>
  );
};

interface ModalBodyWithoutCodeChallengeProps {
  readonly lastFourDigits: string;
}

const ModalBodyWithoutCodeChallenge = ({
  lastFourDigits,
}: ModalBodyWithoutCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <Trans
      i18nKey="remove_sms_description_without_code"
      t={t}
      values={{ lastFourDigits }}
    />
  );
};

interface ModalFooterWithCodeChallengeProps {
  readonly loading: boolean;
  readonly onPinComplete: () => void;
  readonly pinValue: string;
}

const ModalFooterWithCodeChallenge = ({
  loading,
  onPinComplete,
  pinValue,
}: ModalFooterWithCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <HiiveSubmitButton
      isDisabled={pinValue.length !== PIN_LENGTH}
      isLoading={loading}
      sentryLabel="[DisableSmsMfa/Submit]"
      submitText={t(`remove`)}
      type="submit"
      onClick={onPinComplete}
    />
  );
};

interface ModalFooterWithoutCodeChallengeProps {
  readonly loading: boolean;
  readonly onSubmit: () => void;
}

const ModalFooterWithoutCodeChallenge = ({
  loading,
  onSubmit,
}: ModalFooterWithoutCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <HiiveSubmitButton
      isLoading={loading}
      sentryLabel="[DisableSmsMfa/Submit]"
      submitText={t(`confirm`)}
      type="submit"
      onClick={onSubmit}
    />
  );
};

interface DisableSmsMfaModalProps {
  readonly email: string;
  readonly lastFourDigits: string;
  readonly otcToken?: string;
  readonly skipCodeChallenge: boolean;
}

const DisableSmsMfaModal = ({
  email,
  lastFourDigits,
  otcToken: initialOtcToken,
  skipCodeChallenge = false,
}: DisableSmsMfaModalProps) => {
  const { t } = useTranslation();
  const { reload } = useRouter();
  const { onOpenModal, modals, currentModal } = useModal();
  const { timeLeft, resetTimer } = useCountDown();

  const [pinValue, setPinValue] = useState<string>(``);
  const [otcToken, setOtcToken] = useState<string | undefined>(initialOtcToken);

  const [preDisableSmsMfa, { error: preDisableMfaError }] = usePreDisableSmsMfa(
    {
      onSuccess: (data) => {
        const { otcToken: newOtcToken } = data;

        setOtcToken(newOtcToken);
        resetTimer();
      },
    },
  );

  const [disableMfa, { error: disableMfaError, loading, success }] =
    useDisableSmsMfa({
      onSuccess: () => {
        sessionStorage.setItem(
          MfaToastKeys.showSmsMfaRemovedToast,
          VALID_TOAST_FLAG,
        );
        setTimeout(() => reload(), RELOAD_DELAY_MS);
      },
    });

  const handleOpenDisableAllMfaModal = () => {
    onOpenModal(modals.disableAllMfa(email), currentModal)();
  };

  const handleDisableMfaWithoutPin = async () => {
    await disableMfa();
  };

  const handlePinComplete = async (value: string) => {
    if (skipCodeChallenge || !otcToken) {
      return;
    }

    await disableMfa({ code: value, otcToken });
  };

  const showError = !!disableMfaError || !!preDisableMfaError;
  const errorMessage = preDisableMfaError
    ? t(`failed_to_disable_mfa`)
    : t(`incorrect_code`);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`remove_sms`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          {skipCodeChallenge ? (
            <ModalBodyWithoutCodeChallenge lastFourDigits={lastFourDigits} />
          ) : (
            <ModalBodyWithCodeChallenge
              lastFourDigits={lastFourDigits}
              loading={loading}
              onOpenDisableAllMfaModal={handleOpenDisableAllMfaModal}
              onPinChange={setPinValue}
              onPinComplete={handlePinComplete}
              onSendNewCode={preDisableSmsMfa}
              timeLeft={timeLeft}
            />
          )}

          {showError && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{errorMessage}</Text>
            </CallOut>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          {skipCodeChallenge ? (
            <ModalFooterWithoutCodeChallenge
              loading={loading || success}
              onSubmit={handleDisableMfaWithoutPin}
            />
          ) : (
            <ModalFooterWithCodeChallenge
              loading={loading || success}
              onPinComplete={() => handlePinComplete(pinValue)}
              pinValue={pinValue}
            />
          )}
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default DisableSmsMfaModal;
