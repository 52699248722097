import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Box, BoxProps } from "@chakra-ui/react";

import { InviteeStatus } from "@/utils";

function InvitationStatusBadge(props: BoxProps) {
  return (
    <Box
      bg="grey.50"
      borderRadius={6}
      color="grey.900"
      display="inline-block"
      fontSize={12}
      px={1}
      py={0.5}
      {...props}
    />
  );
}

export function IssuerManageUsersStatusBadge({
  status,
}: {
  status: InviteeStatus;
}) {
  const { t } = useTranslation(`issuers`);

  return match(status)
    .with(InviteeStatus.Accepted, () => (
      <InvitationStatusBadge bg="emerald.100" color="emerald.600">
        {t(`active`)}
      </InvitationStatusBadge>
    ))
    .with(InviteeStatus.Pending, () => (
      <InvitationStatusBadge>{t(`pending`)}</InvitationStatusBadge>
    ))
    .with(InviteeStatus.Expired, () => (
      <InvitationStatusBadge bg="yellow.100" color="yellow.800">
        {t(`invite_expired`)}
      </InvitationStatusBadge>
    ))
    .with(InviteeStatus.Deactivated, () => (
      <InvitationStatusBadge bg="red.50" color="red.600">
        {t(`deactivated`)}
      </InvitationStatusBadge>
    ))
    .exhaustive();
}
