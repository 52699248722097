import { MagnifyingGlass } from "@phosphor-icons/react";
import isNil from "lodash/isNil";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  useBreakpointValue,
  Box,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  chakra,
  HStack,
  useToken,
} from "@chakra-ui/react";

import { CompanyCombobox } from "@/components/companies";
import { Combobox } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  CompaniesSearchCompanyFragment,
  CompanyStatus,
  ListCompaniesOrderBy,
  useCompaniesSearchListCompaniesLazyQuery,
  UserWithInstitutionFragment,
} from "@/gql";
import { useColors, useCombobox, useDebounce } from "@/hooks";

const getListCompaniesStatuses = ({
  isSuperadmin,
}: UserWithInstitutionFragment) =>
  isSuperadmin
    ? [CompanyStatus.Listed, CompanyStatus.Delisted, CompanyStatus.Draft]
    : [CompanyStatus.Listed];

/**
 * Navbar companies search
 * Accessibility documentation: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/search_role
 */
const CompaniesSearch = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();
  const { debounce, isDebouncing } = useDebounce();
  const [search, setSearch] = useState<string>(``);
  const [selectedCompany, setSelectedCompany] =
    useState<CompaniesSearchCompanyFragment | null>(null);

  const router = useRouter();

  const autoFocus = useBreakpointValue(
    { base: true, lg: false },
    { ssr: false },
  );

  const getVariables = useCallback(
    (searchText: string) => ({
      first: 20,
      orderBy: ListCompaniesOrderBy.MarketActivity,
      searchText,
      statuses: getListCompaniesStatuses(actor),
    }),
    [actor],
  );

  const [loadCompanies, { data, loading }] =
    useCompaniesSearchListCompaniesLazyQuery({
      fetchPolicy: `no-cache`,
    });

  useEffect(() => {
    // Initial load
    loadCompanies({
      variables: getVariables(``),
    });
  }, []);

  const handleChangeSearch = (search: string) => {
    setSearch(search);
    if (!search) {
      setSelectedCompany(null);
    }

    debounce(() => {
      loadCompanies({
        variables: getVariables(search),
      });
    });
  };

  const handleClickItem = (item: CompaniesSearchCompanyFragment | null) => {
    if (isNil(item)) return;
    handleChangeSearch(item.name);
    setSelectedCompany(item);
    router.push(`/companies/${item.id}`);
  };

  const items =
    data?.listCompanies?.edges?.flatMap((edge) => {
      if (!edge || !edge.node) return [];

      return [edge.node];
    }) || [];

  const isLoading = loading || isDebouncing;

  const { inputProps, menuProps, labelProps, itemProps, actions } =
    useCombobox<CompaniesSearchCompanyFragment>({
      items,
      itemToString: (item) => item.name,
      getItemKey: (item) => item.id,
      inputValue: search,
      onChangeInputValue: handleChangeSearch,
      onSelectItem: handleClickItem,
      isLoading,
      isTabItemSelectAllowed: false,
    });

  const handleClearSearch = () => {
    handleChangeSearch(``);
    setSelectedCompany(null);
    actions.focus();
  };

  const [grey500] = useColors([`grey.500`]);
  const [size18] = useToken(`sizes`, [`18`]);

  const canClearSearch = !!search;

  const iconInputOffset = selectedCompany?.logoUrl ? size18 : undefined;

  return (
    <Box
      as={chakra.form}
      role="search"
      onSubmit={(e) => e.preventDefault()}
      w={{ base: `full`, lg: 76 }}
    >
      <Combobox.Label srOnly {...labelProps}>
        {t(`search_company`)}
      </Combobox.Label>
      <Combobox.Container>
        <InputGroup>
          <InputLeftElement pointerEvents="none" w={iconInputOffset}>
            <HStack spacing={2}>
              <MagnifyingGlass size={20} color={grey500} />
              {selectedCompany?.logoUrl && (
                <Box w={6}>
                  <CompanyCombobox.SelectedIcon company={selectedCompany} />
                </Box>
              )}
            </HStack>
          </InputLeftElement>
          <Combobox.Input
            type="search"
            spellCheck={false}
            autoFocus={autoFocus}
            placeholder={t(`search_company`)}
            pl={iconInputOffset}
            {...inputProps}
          />
          {canClearSearch && (
            <InputRightElement
              as={IconButton}
              variant="icon"
              aria-label={t(`clear`)}
              onClick={handleClearSearch}
            >
              <Combobox.ClearButton />
            </InputRightElement>
          )}
        </InputGroup>
        <Combobox.Menu
          maxH={242}
          isLoading={isLoading}
          fallback={<CompanyCombobox.Skeleton />}
          isLazy={false}
          {...menuProps}
        >
          {items.map((company, index) => (
            <CompanyCombobox.Item
              key={company.id}
              item={company}
              index={index}
              {...itemProps}
            >
              <CompanyCombobox.ItemIcon company={company} />
              <Text as="span">{company.name}</Text>
            </CompanyCombobox.Item>
          ))}
        </Combobox.Menu>
      </Combobox.Container>
    </Box>
  );
};
export default withCurrentActor(CompaniesSearch);
