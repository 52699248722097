import { SensitiveText, Tile, TileHeader, TileRow } from "@/components/common";
import {
  BidInfoCardStatusTileBidFragment,
  BidInfoCardStatusTileBrokerFragment,
  BidState,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { getHasExpired, toTimestamp } from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";

import BidStatus from "./BidStatus";

const PartnerBrokerTile = ({
  broker,
}: {
  readonly broker: BidInfoCardStatusTileBrokerFragment;
}) => (
  <>
    <TileHeader maxW={{ base: `full`, md: 52 }}>
      Representing Partner Broker
    </TileHeader>
    <div>{`${broker.firstName} ${broker.lastName} - ${broker.email}`}</div>
  </>
);

const ExpiryTile = ({
  bid,
}: {
  readonly bid: BidInfoCardStatusTileBidFragment;
}) => (
  <>
    <TileHeader>
      {getHasExpired(bid.expireAt) ? `Expired` : `Expires`}
    </TileHeader>
    <SensitiveText>{toTimestamp(bid.expireAt)}</SensitiveText>
  </>
);

const PlacedAtTile = ({ placedAt }: { readonly placedAt: string }) => (
  <>
    <TileHeader>Placed</TileHeader>
    <SensitiveText>{toTimestamp(placedAt)}</SensitiveText>
  </>
);

export const BidInfoCardStatusTile = ({
  bid,
  showCounterBidDetails,
}: {
  readonly bid: BidInfoCardStatusTileBidFragment;
  readonly showCounterBidDetails: boolean;
}) => {
  const actor = useCurrentActor();
  const isBuyer = getIsBuyerForBid(actor, bid);

  const bidInReview = bid.state === BidState.InReview;
  const placedAt = showCounterBidDetails
    ? bid.counteredAt
    : bid.placedAt || bid.insertedAt;

  const showPlacedAt = !bidInReview && !!placedAt;
  const showExpiredAt = !!bid.expireAt && !showCounterBidDetails;
  const showStatus = !showCounterBidDetails;

  const showRepresentingPartnerBroker =
    actor.isHiiveUser && !!bid.brokerId && isBuyer;

  return (
    <Tile
      py={2}
      gap={4}
      display="grid"
      gridTemplateColumns={{
        base: `1fr`,
        md: `auto 1fr`,
      }}
      alignItems="baseline"
    >
      {showPlacedAt && (
        <TileRow>
          <PlacedAtTile placedAt={placedAt} />
        </TileRow>
      )}
      {showExpiredAt && (
        <TileRow>
          <ExpiryTile bid={bid} />
        </TileRow>
      )}
      {showStatus && (
        <TileRow>
          <BidStatus bid={bid} />
        </TileRow>
      )}
      {showRepresentingPartnerBroker && bid.broker && (
        <TileRow>
          <PartnerBrokerTile broker={bid.broker} />
        </TileRow>
      )}
    </Tile>
  );
};
