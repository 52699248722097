import { Box } from "@chakra-ui/react";

const NavSpacer = ({
  shouldRenderExtraSpace,
}: {
  shouldRenderExtraSpace: boolean;
}) => {
  const navbar_spacer_height = {
    base: `mobile-navbar-height`,
    lg: `desktop-navbar-height`,
  };

  const banner_spacer_height = {
    base: `banner-spacer-base`,
    banner: `banner-spacer-banner`,
  };

  return (
    <>
      <Box h={navbar_spacer_height} />
      {shouldRenderExtraSpace && <Box h={banner_spacer_height} />}
    </>
  );
};
export default NavSpacer;
