import { match } from "ts-pattern";

import {
  ExecutionTaskType,
  ExecutionTaskStatus,
  ExecutionAnvilSignDocumentTaskData,
  TransactionExecutionPageTaskFragment,
  TransactionExecutionPageTransactionFragment,
} from "@/gql";

import AnvilFillDocumentTaskCard from "./AnvilFillDocumentTaskCard";
import AnvilSignDocumentTaskCard from "./AnvilSignDocumentTaskCard";
import CollectBankAccountTaskCard from "./CollectBankAccountTaskCard";
import ConfirmTransactionModificationTaskCard from "./ConfirmTransactionModificationTaskCard";
import EntityCollectionTaskCard from "./EntityCollectionTaskCard";
import SendFundsTaskCard from "./SendFundsTaskCard/SendFundsTaskCard";
import UploadFilesTaskCard from "./UploadFilesTaskCard";

const Tasks = ({
  tasks,
  isBuySide,
  transaction,
}: {
  readonly tasks: TransactionExecutionPageTaskFragment[];
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => (
  <>
    {tasks
      .filter((task) => task.status !== ExecutionTaskStatus.Pending)
      .map((task) =>
        match(task)
          .with(
            {
              type: ExecutionTaskType.AnvilFillDocument,
              data: {
                __typename: `ExecutionAnvilFillDocumentTaskData`,
              },
            },
            (task) => (
              <AnvilFillDocumentTaskCard task={task} data={task.data} />
            ),
          )
          .with(
            {
              type: ExecutionTaskType.AnvilSignDocument,
              data: { __typename: `ExecutionAnvilSignDocumentTaskData` },
            },
            (task) => (
              <AnvilSignDocumentTaskCard
                task={task}
                data={task.data as ExecutionAnvilSignDocumentTaskData}
                isBuySide={isBuySide}
              />
            ),
          )
          .with(
            {
              type: ExecutionTaskType.UploadFiles,
              data: { __typename: `ExecutionUploadFilesTaskData` },
            },
            (task) => <UploadFilesTaskCard task={task} />,
          )
          .with(
            {
              type: ExecutionTaskType.CollectEntity,
              data: { __typename: `ExecutionCollectEntityTaskData` },
            },
            (task) => <EntityCollectionTaskCard task={task} data={task.data} />,
          )
          .with(
            {
              type: ExecutionTaskType.ConfirmTransactionModification,
              data: {
                __typename: `ExecutionConfirmTransactionModificationTaskData`,
              },
            },
            (task) => (
              <ConfirmTransactionModificationTaskCard
                task={task}
                data={task.data}
                isBuySide={isBuySide}
                transaction={transaction}
              />
            ),
          )
          .with(
            {
              type: ExecutionTaskType.CollectBankAccount,
              data: { __typename: `ExecutionCollectBankAccountTaskData` },
            },
            (task) => (
              <CollectBankAccountTaskCard task={task} data={task.data} />
            ),
          )
          .with(
            {
              type: ExecutionTaskType.SendFunds,
              data: { __typename: `ExecutionSendFundsTaskData` },
            },
            (task) => (
              <SendFundsTaskCard
                data={task.data}
                task={task}
                wireAmount={transaction.grossProceeds}
              />
            ),
          )
          .otherwise(() => null),
      )}
  </>
);

export default Tasks;
