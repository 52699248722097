import { withCurrentActor } from "@/components/hoc";
import { getIsUnaccreditedSeller } from "@/utils";

import { AccreditedDashboardPage } from "./AccreditedDashboardPage";

const DashboardPage = withCurrentActor(({ actor }) => {
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  if (isUnaccreditedSeller) {
    // rerouted to /dashboard/[holdingCompanyId] in HoldingProvider
    return null;
  }

  return <AccreditedDashboardPage />;
});

export default DashboardPage;
