import { Plus } from "@phosphor-icons/react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Image,
  List,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveButton,
  LongHyphen,
  SensitiveText,
  WatchlistButton,
  WithQuery,
} from "@/components/common";
import {
  CompanyWatchlistCompanyFragment,
  useCompanyWatchlistActorQuery,
} from "@/gql";
import { useTabRouter } from "@/hooks";
import { formatPricePerShare } from "@/utils";

import { DashboardWatchlistSkeleton } from "./DashboardWatchlistSkeleton";
import { WatchlistAddCombobox } from "./WatchlistAddCombobox";

const WatchlistPrice = ({
  title,
  price,
}: {
  readonly title: string;
  readonly price?: number | null;
}) => (
  <HStack justifyContent="space-between" w="full">
    <Text textStyle="heading-2xs" textTransform="uppercase">
      {title}
    </Text>
    <SensitiveText textStyle="text-sm">
      {!price ? (
        <LongHyphen color="grey.900" />
      ) : (
        <>{formatPricePerShare(price)}</>
      )}
    </SensitiveText>
  </HStack>
);

const DashboardWatchlistItem = ({
  company,
}: {
  readonly company: CompanyWatchlistCompanyFragment;
}) => {
  const router = useRouter();
  const { t } = useTranslation(`dashboard`);
  return (
    <Box position="relative">
      <Card
        variant="subcard"
        as="button"
        w="full"
        onClick={() => router.push(`/companies/${company.id}`)}
      >
        <CardBody w="full">
          <HStack justifyContent="space-between" position="relative">
            <VStack>
              <HStack>
                {company.logoUrl && (
                  <VStack h="6" minW="6">
                    <Image h="full" src={company.logoUrl} alt={company.name} />
                  </VStack>
                )}
                <Text textStyle="heading-md" textAlign="left">
                  {company.name}
                </Text>
              </HStack>
            </VStack>
            <Spacer h={10} w={10} />
          </HStack>
        </CardBody>
        <CardFooter bg="grey.25" w="full">
          <VStack alignItems="flex-start" w="full">
            <WatchlistPrice
              title={t`watchlist_highest_bid`}
              price={company.currentPrices?.highestBid}
            />
            <WatchlistPrice
              title={t`watchlist_lowest_ask`}
              price={company.currentPrices?.lowestAsk}
            />
            <WatchlistPrice
              title={t`watchlist_last_accepted_bid`}
              price={company.currentPrices?.lastTransaction}
            />
          </VStack>
        </CardFooter>
      </Card>
      <WatchlistButton
        position="absolute"
        right="10.5px"
        top="10.5px"
        isCollapsed
        companyId={company.id}
      />
    </Box>
  );
};

const DashboardWatchlistEmptyState = () => (
  <VStack>
    <Text maxW="3xs">You do not have any companies on your watchlist.</Text>
  </VStack>
);

export const DashboardWatchlist = () => {
  const query = useCompanyWatchlistActorQuery();

  const { tabsInfo, tabControls } = useTabRouter<"header" | "search">({
    initialTab: `header`,
  });

  return (
    <WithQuery query={query} fallback={<DashboardWatchlistSkeleton />}>
      {({ data: { currentActor } }) => (
        <Card data-testid="watchlist" w="full">
          <CardHeader py={4}>
            <HStack flex="1 0 auto" align="left" alignItems="center">
              {tabsInfo.currentTabKey === `search` ? (
                <WatchlistAddCombobox
                  onCancel={() => tabControls.jumpToTab(`header`)}
                />
              ) : (
                <>
                  <Text textStyle="heading-sm">Your Watchlist</Text>
                  <Spacer flexGrow={1} />
                  <HiiveButton
                    variant="boxed-salmon"
                    leftIcon={<Plus size={14} weight="bold" />}
                    onClick={() => tabControls.jumpToTab(`search`)}
                    sentryLabel="[DashboardWatchlist] Add Company"
                  >
                    Add Company
                  </HiiveButton>
                </>
              )}
            </HStack>
          </CardHeader>
          <CardBody
            textAlign="center"
            // Using 566px to make exactly 3 of the watchlist cards fit inside the maximum width with appropriate padding
            maxH="566px"
            overflowY="auto"
          >
            <List spacing={4}>
              {currentActor.watchlist.length > 0 ? (
                <AnimatePresence initial={false}>
                  {currentActor.watchlist.map((company) => (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      key={company.id}
                    >
                      <DashboardWatchlistItem company={company} />
                    </motion.div>
                  ))}
                </AnimatePresence>
              ) : (
                <DashboardWatchlistEmptyState />
              )}
            </List>
          </CardBody>
        </Card>
      )}
    </WithQuery>
  );
};
