import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { MappedActiveInvitees } from "@/components/issuers";
import {
  IssuerInvitationInviteeFragment,
  IssuerInvitationUsersDocument,
  useChangeIssuerUserRolesMutation,
  UserRole,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";

import { ReassignAdminSelectInput } from "./IssuersReassignAdminSelectInput";

type IssuersRemoveAdminRoleModalProps = {
  invitee: IssuerInvitationInviteeFragment;
  activeInvitees: MappedActiveInvitees;
  showReassignAdmin: boolean;
};

export function IssuersRemoveAdminRoleModal({
  invitee,
  activeInvitees,
  showReassignAdmin,
}: IssuersRemoveAdminRoleModalProps) {
  const { t } = useTranslation(`issuers`);
  const { closeModal } = useModal();
  const { errorToast, successToast } = useCustomToast();
  const { replace, asPath } = useRouter();
  const targetUserIdRef = useRef<string>(``);

  const { id: userId, name } = invitee;

  const [changeIssuerUserRoles, { loading }] = useChangeIssuerUserRolesMutation(
    {
      refetchQueries: [IssuerInvitationUsersDocument],
    },
  );

  const onSubmit = useCallback(async () => {
    const successMessage = showReassignAdmin
      ? t(`changes_updated`)
      : t(`remove_user_success`);

    try {
      if (showReassignAdmin) {
        const { data: reassignAdminData } = await changeIssuerUserRoles({
          variables: {
            userId: targetUserIdRef.current,
            roles: [UserRole.Admin],
          },
        });

        if (!reassignAdminData?.changeIssuerUserRoles) {
          throw new Error(t(`failed_to_change_user_roles`));
        }
      }

      const { data } = await changeIssuerUserRoles({
        variables: { userId, roles: [UserRole.Viewer] },
      });

      if (!data?.changeIssuerUserRoles) {
        throw new Error(t(`failed_to_change_user_roles`));
      }

      if (data.changeIssuerUserRoles?.user) {
        closeModal();
        successToast(successMessage);
        replace(asPath);
      }
    } catch (error) {
      errorToast(error.message);
    }
  }, [showReassignAdmin, userId]);

  const updateTargetUserId = useCallback((targetUserId: string) => {
    targetUserIdRef.current = targetUserId;
  }, []);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`remove_admin`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          {showReassignAdmin ? (
            <ReassignAdminSelectInput
              activeInvitees={activeInvitees}
              onReassignAdmin={updateTargetUserId}
            />
          ) : (
            <Text>{t(`remove_admin_description`, { name })}</Text>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            sentryLabel="[IssuersRemoveAdminRole/Submit]"
            submitText={t(`confirm`)}
            onClick={onSubmit}
            isLoading={loading}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
}
