import { Bank } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Badge,
  Button,
  Card,
  Center,
  HStack,
  Text,
  VStack,
  CardFooter,
  CardBody,
  CardHeader,
  useDisclosure,
} from "@chakra-ui/react";

import { SimpleListbox } from "@/components/form";
import {
  TransactionExecutionPageTaskFragment,
  ExecutionTaskStatus,
  ExecutionCollectBankAccountTaskDataFragment,
  ExecutionEntityBankAccountFragment,
} from "@/gql";
import { toFullDate, toShortDate } from "@/utils/datetime";

import { CollectBankAccountModal } from "./CollectBankAccountModal";

const BankAccountSimpleListboxElement = ({
  bankAccount,
}: {
  readonly bankAccount: ExecutionEntityBankAccountFragment;
}) => {
  const { t } = useTranslation();

  const { last4 } = bankAccount;

  return (
    <HStack justifyContent="space-between" w="full">
      <HStack gap={2}>
        <Text textStyle="heading-md" color="grey.900">{t`account`}</Text>
        <Text textStyle="text-md" color="grey.700">
          {t(`bank_accounts.redacted`, {
            ns: `postings`,
          })}
          {` `}
          {last4}
        </Text>
      </HStack>
      <HStack>
        <Text
          textStyle="text-sm"
          color="grey.500"
        >{`${t(`added`)} ${toShortDate(bankAccount.insertedAt)}`}</Text>
      </HStack>
    </HStack>
  );
};

const renderListItem = (bankAccount: ExecutionEntityBankAccountFragment) => (
  <BankAccountSimpleListboxElement bankAccount={bankAccount} />
);

const CollectBankAccountTaskCard = ({
  task,
  data,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionCollectBankAccountTaskDataFragment;
}) => {
  const { t } = useTranslation(`execution`);
  const [selectedAccount, setSelectedAccount] =
    useState<ExecutionEntityBankAccountFragment>();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { entity } = data;

  const bankAccounts = entity?.bankAccounts ?? [];
  const hasBankAccounts = bankAccounts.length > 0;

  return (
    <>
      <Card variant="flat" mb={6} bg={hasBankAccounts ? `grey.15` : `inherit`}>
        <CardHeader
          px={4}
          py={3}
          as={HStack}
          w="full"
          justifyContent="space-between"
          bg={hasBankAccounts ? `white` : `inherit`}
          borderBottom={hasBankAccounts ? `inherit` : `none`}
        >
          <HStack gap={4}>
            <Center>
              <Bank width="24px" height="24px" />
            </Center>
            <VStack alignItems="start" spacing={0.5}>
              <Text textStyle="heading-md">{t(`add_bank_account`)}</Text>
              <Text textStyle="text-xs" color="grey.700">
                {t(`add_bank_account_description`)}
              </Text>
            </VStack>
          </HStack>
          {task.status === ExecutionTaskStatus.InProgress && (
            <Badge variant="status-pending">
              <Text textStyle="text-sm" lineHeight="unset" color="teal.900">
                {t(`pending`)}
              </Text>
            </Badge>
          )}
          {!!task.completedAt && (
            <Text>
              {t(`completed`)}, {toFullDate(task.completedAt)}
            </Text>
          )}
        </CardHeader>
        {ExecutionTaskStatus.InProgress && hasBankAccounts && (
          <CardBody p={4} as={VStack} w="full">
            <Card
              px={3}
              py={2}
              as={VStack}
              variant="flat"
              w="full"
              spacing={0}
              alignItems="start"
            >
              <Text textStyle="heading-3xs" color="grey.600">
                {t(`bank_details_for`)}:
              </Text>
              <Text textStyle="text-sm" color="grey.600">
                {entity?.legalName} &#x2022;{` `}
                {entity?.jurisdictionOfFormation?.name}
              </Text>
            </Card>
            <SimpleListbox
              placeholder={t`select_bank_account`}
              value={selectedAccount}
              items={bankAccounts}
              itemToString={(bankAccount) => bankAccount?.last4 ?? ``}
              getItemKey={(bankAccount) => bankAccount?.id ?? ``}
              onItemSelected={(bankAccount) => setSelectedAccount(bankAccount)}
              itemRenderer={renderListItem}
            />
          </CardBody>
        )}
      </Card>
      {task.status === ExecutionTaskStatus.InProgress && (
        <CardFooter bg="grey.25" px={6} py={4} mx={-6} justifyContent="end">
          {hasBankAccounts ? (
            <HStack>
              <Button
                isLoading={false}
                variant="rounded-outline-grey"
                size="xl"
                onClick={onOpen}
              >
                {t(`add_new_account`)}
              </Button>
              <Button
                isLoading={false}
                variant="rounded-solid-grey"
                size="xl"
                onClick={() => {}}
                isDisabled={!selectedAccount}
              >
                {t(`confirm`)}
              </Button>
            </HStack>
          ) : (
            <Button
              isLoading={false}
              variant="rounded-solid-grey"
              size="xl"
              onClick={onOpen}
            >
              {t(`add_account`)}
            </Button>
          )}
        </CardFooter>
      )}
      <CollectBankAccountModal
        isOpen={isOpen}
        onClose={onClose}
        entity={entity}
        taskId={task.id}
      />
    </>
  );
};

export default CollectBankAccountTaskCard;
