import { GridItem } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { useDefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery } from "@/gql";

import { ListSharesSellNowBlockSkeleton } from "./ListSharesSellNowBlockSkeleton";
import { ListYourSharesCard } from "./ListYourSharesCard";
import { SellNowCard } from "./SellNowCard";

const ListSharesSellNowBlock = () => {
  const query =
    useDefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery();

  return (
    <WithQuery query={query} fallback={<ListSharesSellNowBlockSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyCompanyActivity: { availableStandingBids },
          unaccreditedSellerMyActivity: { myCompany },
        },
      }) => {
        const hasAvailableStandingBid = availableStandingBids.length > 0;

        return (
          <>
            <GridItem
              colSpan={{ base: 2, lg: hasAvailableStandingBid ? 1 : 2 }}
            >
              <ListYourSharesCard singleColumn={!hasAvailableStandingBid} />
            </GridItem>
            {hasAvailableStandingBid && (
              <GridItem colSpan={{ base: 2, lg: 1 }}>
                <SellNowCard id={myCompany.id} />
              </GridItem>
            )}
          </>
        );
      }}
    </WithQuery>
  );
};
export default ListSharesSellNowBlock;
