import { match } from "ts-pattern";

import { Box, Flex } from "@chakra-ui/react";

import { StandingBidTransactions } from "@/components/postings";
import {
  StandingBidSellerActivityStandingBidFragment,
  UserPermissionV2,
  UserWithInstitutionFragment,
} from "@/gql";
import { useIHavePermission } from "@/hooks";
import {
  getAvailableStandingBidActions,
  getIsInstitutionViewer,
} from "@/utils";
import { isStandingBidInTerminalState } from "@/utils/standing-bid";

import { AcceptStandingBidCTACard } from "./AcceptStandingBidCTACard";
import { MockAcceptStandingBidCTACard } from "./MockAcceptStandingBidCTACard";
import { StandingBidSellerMessaging } from "./StandingBidSellerMessaging";

const ConditionalAcceptStandingBidCTACard = ({
  actorHasAcceptStandingBidPermission,
  canAcceptStandingBid,
  standingBid,
}: {
  readonly actorHasAcceptStandingBidPermission: boolean;
  readonly canAcceptStandingBid: boolean;
  readonly standingBid: StandingBidSellerActivityStandingBidFragment;
}) => {
  const acceptStandingBidCTACard = match([
    actorHasAcceptStandingBidPermission,
    canAcceptStandingBid,
  ])
    .with([true, true], () => (
      <AcceptStandingBidCTACard standingBid={standingBid} />
    ))
    .with([false, false], () => (
      <MockAcceptStandingBidCTACard
        allowPartialAccept={standingBid.allowPartialAccept}
        standingBid={standingBid}
      />
    ))
    .with([true, false], () => (
      <MockAcceptStandingBidCTACard
        allowPartialAccept={standingBid.allowPartialAccept}
        standingBid={standingBid}
      />
    ))
    .with([false, true], () => (
      <MockAcceptStandingBidCTACard
        allowPartialAccept={standingBid.allowPartialAccept}
        standingBid={standingBid}
      />
    ))
    .otherwise(() => null);

  return acceptStandingBidCTACard;
};

const StandingBidSellerActivity = ({
  actor,
  standingBid,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly standingBid: StandingBidSellerActivityStandingBidFragment;
}) => {
  const { canAcceptStandingBid } = getAvailableStandingBidActions(standingBid);
  const actorHasAcceptStandingBidPermission = useIHavePermission(
    UserPermissionV2.AcceptStandingBid,
  );

  const isInstitutionViewer = getIsInstitutionViewer(actor);

  if (isInstitutionViewer) {
    return null;
  }

  return (
    <Flex w="full" direction="column" gap={4}>
      {!isStandingBidInTerminalState(standingBid.state) && (
        <ConditionalAcceptStandingBidCTACard
          actorHasAcceptStandingBidPermission={
            actorHasAcceptStandingBidPermission
          }
          canAcceptStandingBid={canAcceptStandingBid}
          standingBid={standingBid}
        />
      )}
      <StandingBidSellerMessaging standingBid={standingBid} />
      <Box
        __css={{
          "&:not(:only-child)": {
            mt: 4,
          },
        }}
      >
        <StandingBidTransactions standingBid={standingBid} />
      </Box>
    </Flex>
  );
};

export default StandingBidSellerActivity;
