import { useTranslation } from "react-i18next";

import { Text, VStack } from "@chakra-ui/react";

import { UserWithInstitutionFragment } from "@/gql";
import { useSignOut } from "@/hooks";

export const BasicLogout = ({
  actor,
  alignItems,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly alignItems: string;
}) => {
  const { t } = useTranslation();
  const signOut = useSignOut();

  return (
    <VStack alignItems={alignItems} spacing={0}>
      <Text textStyle="text-md" color="grey.500">
        {actor.email}
      </Text>
      <Text
        color="grey.500"
        textStyle={{ base: `heading-xs`, lg: `heading-md` }}
        cursor="pointer"
        onClick={() => signOut()}
      >
        {t(`log_out`)}
      </Text>
    </VStack>
  );
};

export default BasicLogout;
