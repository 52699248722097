import { useApolloClient } from "@apollo/client";
import { CaretLeft } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import Link from "next/link";
import { useRouter } from "next/router";

import {
  Box,
  Center,
  Flex,
  HStack,
  Spinner,
  Text,
  Divider,
} from "@chakra-ui/react";

import { Loader, Logo, MailtoLink, WithQuery } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { DocumentNotReady, PandadocDocFrame } from "@/components/pandadoc";
import { TransactionDisclaimer } from "@/components/postings";
import {
  BasicUserFragment,
  BidPageBidByIdDocument,
  BidPageMyActivityDocument,
  CompletedTransfersCardMyActivityDocument,
  ListingPageListingByIdDocument,
  SignDocumentAlertMessagesMyActivityDocument,
  SigningProcedure,
  TransactionByIdDocument,
  TransactionDocumentPageDocumentFragment,
  TransactionDocumentPageTransactionFragment,
  TransactionState,
  UserActivityMyActivityDocument,
  useRecordTransactionDocumentSignedMutation,
  UserWithInstitutionFragment,
  useTransactionByIdQuery,
  useViewTransactionDocumentMutation,
  ViewTransactionDocumentMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";
import {
  appendSellerCompanyIdToUrl,
  constants,
  getIsUnaccreditedSeller,
  getLongDocumentTitleByDocumentType,
  makeUrl,
} from "@/utils";

import { DocumentTransactionCard } from "./DocumentTransactionCard";

const getDocumentReadyText = (
  transaction: TransactionDocumentPageTransactionFragment,
  actor: BasicUserFragment,
) => {
  const iSigned = (
    document?: TransactionDocumentPageDocumentFragment | null,
  ) => {
    if (!document || !document.signers) return false;
    return document?.signers?.some((signer) => signer?.userId === actor.id);
  };

  const myInstitutionSigned = (
    document?: TransactionDocumentPageDocumentFragment | null,
  ) => {
    if (!document || !document.signers) return false;
    return document.signers.some(
      (signer) =>
        !!signer.institutionId && signer.institutionId === actor.institutionId,
    );
  };

  const bothSigned = (
    document?: TransactionDocumentPageDocumentFragment | null,
  ) => {
    if (!document || !document.signers) return false;
    return document.signers.length === 2;
  };

  if (!transaction.document) {
    return `document`;
  }

  const title = getLongDocumentTitleByDocumentType(transaction.document.type);

  return match(transaction)
    .with(
      { document: P.when(bothSigned) },
      () => `Completed ${title} can be viewed and downloaded below.`,
    )
    .with(
      { document: P.when(iSigned) },
      () => `Awaiting the signature of the counter party.`,
    )
    .with(
      { document: P.when(myInstitutionSigned) },
      () =>
        `A member of your institution has signed this ${title}. Awaiting the signature of the counter party.`,
    )
    .otherwise(() => `Please review and sign the ${title} below.`);
};

const DocumentReady = withCurrentActor(
  ({
    documentUrl,
    transaction,
    actor,
  }: {
    readonly documentUrl: string;
    readonly transaction: TransactionDocumentPageTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const [recordTransactionDocumentSigned, loading] = useMutationWithError(
      useRecordTransactionDocumentSignedMutation(),
      `recordTransactionDocumentSigned`,
    );
    const { t } = useTranslation();

    const client = useApolloClient();

    const onDocumentComplete = async () => {
      await recordTransactionDocumentSigned({
        variables: {
          input: {
            documentId: transaction.document?.id || ``,
            institutionId: !!actor.institutionId ? actor.institutionId : ``,
          },
        },
      });
      client.refetchQueries({
        include: [
          UserActivityMyActivityDocument,
          CompletedTransfersCardMyActivityDocument,
          SignDocumentAlertMessagesMyActivityDocument,
          BidPageMyActivityDocument,
          BidPageBidByIdDocument,
          ListingPageListingByIdDocument,
          TransactionByIdDocument,
        ],
      });
    };

    if (loading)
      return (
        <Center m={20}>
          <Spinner />
        </Center>
      );

    return (
      <>
        <Text
          mb={2}
          align="center"
          maxW="lg"
          textStyle="deprecated-text-xl"
          color="h-dark-grey"
          data-testid="document-ready-text"
        >
          {getDocumentReadyText(transaction, actor)}
        </Text>
        <Text
          mb={9}
          align="center"
          maxW="lg"
          textStyle="deprecated-text-xl"
          color="h-dark-grey"
        >
          {t(`contact_security_specialist`)}
          <br />
          <Box as="span" fontWeight="medium">
            <MailtoLink
              email={constants.email_security_specialist}
              subject="Questions on Transaction Document"
            >
              {constants.email_security_specialist}
            </MailtoLink>
          </Box>
        </Text>
        <Center w="full">
          <Divider
            w={500}
            borderTop="1px"
            borderColor="grey.200 !important"
            mb={10}
            px={10}
          />
        </Center>
        <Box w={{ base: `100%`, lg: `900px` }}>
          <TransactionDisclaimer />
          <Flex justify="center" align="center" w="full">
            {transaction.document && (
              <PandadocDocFrame
                w="full"
                h="754px"
                title={`${getLongDocumentTitleByDocumentType(
                  transaction.document.type,
                )} Embed`}
                url={documentUrl}
                onComplete={onDocumentComplete}
              />
            )}
          </Flex>
        </Box>
      </>
    );
  },
);

const BackButton = withCurrentActor(
  ({
    transaction,
    actor,
  }: {
    readonly transaction: TransactionDocumentPageTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
    const url = isUnaccreditedSeller
      ? appendSellerCompanyIdToUrl(makeUrl(transaction), transaction.company.id)
      : makeUrl(transaction);
    return (
      <Link href={url}>
        <HStack spacing={2} alignItems="center" cursor="pointer">
          <Center pb={0.5}>
            <CaretLeft size={16} color="#818285" cursor="pointer" />
          </Center>
          <Box color="h-mid-grey" textStyle="deprecated-heading-lg">
            Back to Bid{` `}
            {transaction.bid.listing.company.name.slice(0, 3).toUpperCase()}-
            {transaction.bid.shortId}
          </Box>
        </HStack>
      </Link>
    );
  },
);

const TransactionDocument = ({
  transaction,
}: {
  readonly transaction: TransactionDocumentPageTransactionFragment;
}) => {
  const [viewDocumentMutation, loading] = useMutationWithError(
    useViewTransactionDocumentMutation(),
    `viewTransactionDocument`,
  );
  const [url, setUrl] = useState<string>(``);
  const [isDocumentReady, setIsDocumentReady] = useState<boolean>(false);
  const { t } = useTranslation();

  const onSuccess = (response: ViewTransactionDocumentMutation) => {
    const _url = response.viewTransactionDocument;
    if (!_url) return;
    setUrl(_url);
    setIsDocumentReady(true);
  };

  useEffect(() => {
    viewDocumentMutation({
      variables: { transactionId: transaction.id },
    }).then(onSuccess);
  }, []);

  if (transaction.state === TransactionState.Expired) {
    return (
      <Text textStyle="deprecated-heading-lg">{t(`document_expired`)}</Text>
    );
  }

  const hideDocument = !!transaction.pendingModification;

  if (hideDocument) {
    return (
      <>
        <Text
          mb={2}
          align="center"
          maxW="lg"
          textStyle="deprecated-text-xl"
          color="h-dark-grey"
          data-testid="document-ready-text"
        >
          {t(`document_please_review_modification`)}
        </Text>
        <Text
          mb={9}
          align="center"
          maxW="lg"
          textStyle="deprecated-text-xl"
          color="h-dark-grey"
        >
          {t(`contact_security_specialist`)}
          <br />
          <Box as="span" fontWeight="medium">
            <MailtoLink
              email={constants.email_security_specialist}
              subject="Questions on Transaction Document"
            >
              {constants.email_security_specialist}
            </MailtoLink>
          </Box>
        </Text>
      </>
    );
  }

  if (loading) return <Loader minHeight="100vh" />;

  return (
    <>
      <Box display="none" data-testid="document-external-id">
        {transaction?.document?.externalId}
      </Box>
      {isDocumentReady ? (
        <DocumentReady documentUrl={url} transaction={transaction} />
      ) : (
        <DocumentNotReady />
      )}
    </>
  );
};

const TransactionDocumentPage = ({
  transaction,
}: {
  readonly transaction: TransactionDocumentPageTransactionFragment;
}) => {
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    // Redirect for Manual Transactions
    if (transaction.signingProcedure === SigningProcedure.Automated) {
      setLoading(false);
      return;
    }
    router.replace(`/listings/bids/${transaction.bid.id}`);
  }, [transaction]);

  if (loading) return <Loader m={20} />;

  return (
    <Flex
      bg="grey.25"
      direction="column"
      align="center"
      w="full"
      maxW="max-width-md"
      minH="100vh"
      px={{ base: 4, lg: 8 }}
      pb={14}
      pt={7}
    >
      <Box w="full" mb={10}>
        <BackButton transaction={transaction} />
      </Box>
      <Center>
        <Logo.DarkGreyWithPink mb={{ base: 9, md: 14 }} />
      </Center>

      <Text
        textAlign="center"
        textStyle="deprecated-heading-4xl"
        color="h-dark-grey"
        mb={5}
      >
        {transaction.bid.listing.company.name}
        {` `}
        Transaction -{` `}
        {transaction.document &&
          getLongDocumentTitleByDocumentType(transaction.document.type)}
      </Text>

      <Box w="full" maxW="42rem" mb={{ base: 4, md: 8 }}>
        <DocumentTransactionCard transaction={transaction} />
      </Box>
      <Flex direction="column" justify="center" align="center" w="full">
        <TransactionDocument transaction={transaction} />
      </Flex>
    </Flex>
  );
};

const TransactionDocumentPageWithQuery = ({
  transactionId,
}: {
  readonly transactionId: string;
}) => (
  <WithQuery
    query={useTransactionByIdQuery({
      variables: { id: transactionId as string },
    })}
  >
    {({
      data: { transactionById: transaction },
    }: {
      readonly data: {
        readonly transactionById: TransactionDocumentPageTransactionFragment;
      };
    }) => <TransactionDocumentPage transaction={transaction} />}
  </WithQuery>
);

export default TransactionDocumentPageWithQuery;
