import React, { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Image,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";

import { HiiveButton, InternalLink } from "@/components/common";
import { TransactingEntityItemEntityFragment } from "@/gql";

const MTPrivacyPolicyLink = ({
  children,
}: {
  readonly children?: ReactNode;
}) => (
  <InternalLink
    href="https://www.moderntreasury.com/legal/privacy-policy"
    textStyle="text-xs"
    textDecoration="underline"
    target="_blank"
  >
    {children}
  </InternalLink>
);

const ModernTreasuryConnectAccountCard = ({
  entity,
}: {
  readonly entity: TransactingEntityItemEntityFragment;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const transactionId = router.query.id as string;

  const onAddAccountClick = async () => {
    await router.push(`/transactions/${transactionId}/connect-account`);
  };

  const { legalName } = entity;

  return (
    <Card w="full">
      <CardHeader>
        <Text
          textStyle={{ base: `heading-xl`, md: `heading-2xl` }}
        >{t`account_information`}</Text>
      </CardHeader>
      <CardBody as={VStack} justifyContent="center" w="full">
        <Flex w="100%" mb={2}>
          <Trans
            i18nKey="add_bank_account_for_entity"
            components={{
              bold: <Text as="span" fontWeight="bold" pl="1" />,
            }}
            values={{ entityName: legalName }}
          />
        </Flex>
        <Card shadow="none" w="full">
          <CardBody p={4}>
            <HStack justifyContent="flex-start" gap={4}>
              <Image
                src="/assets/hiive-mt-icon.png"
                w="80px"
                objectFit="contain"
              />
              <VStack justifyContent="center" alignItems="flex-start" gap={1}>
                <Text
                  textStyle="heading-md"
                  color="grey.700"
                >{t`hiive_mt_title`}</Text>
                <Text
                  textStyle="text-xs"
                  color="grey.500"
                >{t`hiive_mt_subtitle`}</Text>
              </VStack>
            </HStack>
          </CardBody>
        </Card>
        <Text textStyle="text-xs" color="grey.700" mt={2} pb={0}>
          <Trans
            i18nKey="modern_treasury_privacy_policy_disclaimer"
            components={{ italic: <MTPrivacyPolicyLink /> }}
          />
        </Text>
      </CardBody>
      <CardFooter as={HStack} justifyContent="flex-end" py={4} bg="grey.25">
        <HiiveButton
          onClick={onAddAccountClick}
          variant="rounded-solid-salmon"
          sentryLabel="[ModernTreasuryAccountCard] addAccount"
          size="xl"
          maxW="unset"
          w={{ base: `full`, md: `170px` }}
        >{t`add_account`}</HiiveButton>
      </CardFooter>
    </Card>
  );
};

export default ModernTreasuryConnectAccountCard;
