import { ReactNode } from "react";

import { Box, HStack, VStack } from "@chakra-ui/react";

import { useIsDesktop } from "@/hooks";

import { IssuerDesktopNav } from "./desktop/IssuerDesktopNav";
import { IssuerMobileNav } from "./mobile/IssuerMobileNav";

type IssuerNavLayoutProps = {
  content: ReactNode;
};

function DesktopNavLayout({ content }: IssuerNavLayoutProps) {
  return (
    <HStack alignItems="flex-start" h="100vh" gap={0}>
      <IssuerDesktopNav />
      <Box w="full" h="full" overflowY="auto">
        {content}
      </Box>
    </HStack>
  );
}

function MobileNavLayout({ content }: IssuerNavLayoutProps) {
  return (
    <VStack alignItems="flex-start" h="100vh" gap={0}>
      <IssuerMobileNav />
      <Box w="full" h="full" overflowY="auto" zIndex={0}>
        {content}
      </Box>
    </VStack>
  );
}

export function IssuerNavLayout({ content }: IssuerNavLayoutProps) {
  const isDesktop = useIsDesktop();

  return isDesktop ? (
    <DesktopNavLayout content={content} />
  ) : (
    <MobileNavLayout content={content} />
  );
}
