import { createContext } from "react";

import { StepFormContextProps } from "@/components/form";

const createStepFormContext = <TStepKeys extends string, TMutationData>() =>
  createContext<StepFormContextProps<TStepKeys, TMutationData>>({
    submitMutation: () =>
      new Promise<TMutationData>((resolve) => {
        resolve(undefined as unknown as TMutationData);
      }),
    mutationData: null,
    registerValidator: () => null,
  });

export default createStepFormContext;
