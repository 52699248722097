import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Box, Flex, Text } from "@chakra-ui/react";

import { Layout } from "@/components/layouts";
import { useMaintenanceMode } from "@/hooks";

const NextMaintenancePage = () => {
  const maintenanceModeEnabled = useMaintenanceMode();
  const router = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    if (maintenanceModeEnabled) return;
    router.push(`/dashboard`);
  }, [maintenanceModeEnabled]);

  return (
    <Layout title="Scheduled maintenance">
      <Box
        px={{ base: 4, md: 8 }}
        py={{ base: 10, md: 20 }}
        minH="100vh"
        width="100%"
        bg="grey.50"
      >
        <Flex direction="column" alignItems="center" pt={{ base: 10, md: 20 }}>
          <Text textStyle="heading-5xl" textAlign="center">
            {t(`scheduled_maintenance`)}
          </Text>
          <Text textStyle="text-4xl" textAlign="center" mb={5}>
            {t(`scheduled_maintenance_description`)}
            <Text>{t(`please_check_back_soon`)}</Text>
          </Text>
        </Flex>
      </Box>
    </Layout>
  );
};

export default NextMaintenancePage;
