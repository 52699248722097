import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardBody, VStack, Text, Grid, GridItem } from "@chakra-ui/react";

import { FileCard } from "@/components/common";
import { IssuerTransactionPageTransactionFragment } from "@/gql";

import { IssuerTransactionPageContent } from "./IssuerTransactionPageContent";
import { IssuerTransactionPageMainPanelHeader } from "./IssuerTransactionPageMainPanelHeader";
import { IssuerTransactionDocumentsPageSkeleton } from "./IssuerTransactionPageSkeleton";
import { IssuerTransactionContext } from "./IssuerTransactionProvider";

interface IssuerTransactionDocumentsPageContentProps {
  readonly transaction: IssuerTransactionPageTransactionFragment;
}

function IssuerTransactionDocumentsPageContent({
  transaction,
}: IssuerTransactionDocumentsPageContentProps) {
  const { t } = useTranslation(`issuers`);
  const { fileUploads } = transaction;

  return (
    <>
      <IssuerTransactionPageMainPanelHeader transaction={transaction} />
      <Card w="full" variant="issuer">
        {fileUploads?.length ? (
          <CardBody
            as={Grid}
            templateColumns={{ base: `repeat(1, 1fr)`, md: `repeat(2, 1fr)` }}
            columnGap={3}
            rowGap={{ base: 8, md: 4 }}
            p={6}
          >
            {fileUploads.map((file) => (
              <GridItem key={file.id}>
                <FileCard fileUpload={file} />
              </GridItem>
            ))}
          </CardBody>
        ) : (
          <CardBody>
            <VStack gap={3} my={5}>
              <Text textStyle="heading-lg">{t(`no_documents_yet`)}</Text>
              <Text>{t(`transaction_has_no_documents`)}</Text>
            </VStack>
          </CardBody>
        )}
      </Card>
    </>
  );
}

export function IssuerTransactionDocumentsPage() {
  const { transaction } = useContext(IssuerTransactionContext);

  return (
    <IssuerTransactionPageContent>
      {transaction ? (
        <IssuerTransactionDocumentsPageContent transaction={transaction} />
      ) : (
        <IssuerTransactionDocumentsPageSkeleton />
      )}
    </IssuerTransactionPageContent>
  );
}
