import { Box, Text } from "@chakra-ui/react";

export const RofrIcon = () => (
  <Box
    bg="black"
    borderRadius="100%"
    h={4}
    w={4}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Text color="white" textStyle="heading-3xs">
      R
    </Text>
  </Box>
);
