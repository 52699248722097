import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Table,
  Thead,
  Tr,
  Tbody,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { ThreeDotsVerticalIcon } from "@/components/icons";
import {
  InstitutionInvitationsTableInvitationFragment,
  InvitationStatus,
  RoleType,
  useInstitutionInvitationsQuery,
  useResendInvitationMutation,
  useCancelInvitationMutation,
  InstitutionInvitationsDocument,
} from "@/gql";
import { useCustomToast, useMutationWithError } from "@/hooks";
import * as datetime from "@/utils/datetime";

import { StyledTd } from "./StyledTd";
import { StyledTh } from "./StyledTh";

const ActionsMenu = ({
  invitation,
}: {
  readonly invitation: InstitutionInvitationsTableInvitationFragment;
}) => {
  const { t } = useTranslation();
  const [resendInvitationMutation] = useMutationWithError(
    useResendInvitationMutation(),
    `resendInvitation`,
  );

  const [cancelInvitationMutation] = useMutationWithError(
    useCancelInvitationMutation({
      refetchQueries: [InstitutionInvitationsDocument],
    }),
    `cancelInvitation`,
  );

  const { successToast } = useCustomToast();

  const onSuccess = () => {
    successToast(t(`invitation_resent_successfully`));
  };

  const handleResendInvitation = () => {
    const params = { variables: { id: invitation.id } };

    resendInvitationMutation(params).then(onSuccess);
  };

  const handleCancelInvitation = () => {
    const params = { variables: { id: invitation.id } };
    cancelInvitationMutation(params);
  };

  if (invitation.status === InvitationStatus.Accepted) return null;

  return (
    <Menu>
      <MenuButton>
        <ThreeDotsVerticalIcon />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleResendInvitation}>
          {t(`resend_invitation`)}
        </MenuItem>
        {invitation.status !== InvitationStatus.Expired && (
          <MenuItem onClick={handleCancelInvitation}>
            {t(`cancel_invitation`)}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

const InvitationRow = ({
  invitation,
}: {
  readonly invitation: InstitutionInvitationsTableInvitationFragment;
}) => {
  const roleText = useMemo(() => {
    switch (true) {
      case invitation.roles.includes(RoleType.Admin):
        return `Admin`;
      case invitation.roles.includes(RoleType.Trader):
        return `Trader`;
      case invitation.roles.includes(RoleType.Viewer):
        return `Viewer`;
      default:
        throw new Error(`Unhandled roleText case in InvitationRow`);
    }
  }, [invitation.roles]);

  const signerText = useMemo(() => {
    if (invitation.roles.includes(RoleType.Signer)) return `Yes`;
    return `No`;
  }, [invitation.roles]);

  const statusText = useMemo(
    () =>
      ({
        [InvitationStatus.Expired]: `Expired`,
        [InvitationStatus.Pending]: `Pending`,
        [InvitationStatus.Accepted]: `Accepted`,
      })[invitation.status],
    [invitation.status],
  );

  return (
    <Tr borderColor="grey.100">
      <StyledTd>{invitation.email}</StyledTd>
      <StyledTd>{roleText}</StyledTd>
      <StyledTd>{signerText}</StyledTd>
      <StyledTd>{statusText}</StyledTd>
      <StyledTd>
        {datetime.format(`MMM. DD, YYYY`, invitation.updatedAt)}
      </StyledTd>
      <StyledTd>
        <ActionsMenu invitation={invitation} />
      </StyledTd>
    </Tr>
  );
};

const sortByInsertedAt = (
  invitations: readonly InstitutionInvitationsTableInvitationFragment[],
) =>
  [...invitations].sort((a, b) =>
    datetime.diff(a.insertedAt, b.insertedAt) < 0 ? 1 : -1,
  );

export const InstitutionInvitationsTable = () => {
  const query = useInstitutionInvitationsQuery();
  return (
    <WithQuery query={query}>
      {({ data: { institutionInvitations } }) => (
        <Box
          borderWidth="0.5px"
          borderColor="grey.200"
          borderRadius="md"
          pt={2}
          bg="white"
          overflowX="auto"
        >
          <Table variant="simple" bg="white">
            <Thead>
              <Tr textTransform="uppercase">
                <StyledTh>Email</StyledTh>
                <StyledTh>Role</StyledTh>
                <StyledTh>Signer</StyledTh>
                <StyledTh>Status</StyledTh>
                <StyledTh>Created</StyledTh>
                <StyledTh width={12} />
              </Tr>
            </Thead>
            <Tbody>
              {sortByInsertedAt(institutionInvitations).map(
                (invitation: InstitutionInvitationsTableInvitationFragment) => (
                  <InvitationRow key={invitation.id} invitation={invitation} />
                ),
              )}
            </Tbody>
          </Table>
        </Box>
      )}
    </WithQuery>
  );
};
