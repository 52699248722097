import currency from "currency.js";
import pluralize from "pluralize";

import { useColors } from "@/hooks";
import { formatShares } from "@/utils";

const SharePrice = ({
  label,
  numShares,
  newNumShares,
  newPricePerShare,
  pricePerShare,
}: {
  readonly label?: string;
  readonly numShares: number;
  readonly newNumShares?: number;
  readonly newPricePerShare?: number;
  readonly pricePerShare: number;
}) => {
  const [grey200] = useColors([`grey.200`]);

  return (
    <>
      {!!newNumShares && newNumShares !== numShares ? (
        <>
          <span style={{ color: grey200, textDecoration: `line-through` }}>
            {formatShares(numShares)}
          </span>
          {` `}
          {formatShares(newNumShares)}
        </>
      ) : (
        <>{formatShares(numShares)}</>
      )}
      {` `}
      {label === `unit`
        ? pluralize(`unit`, numShares)
        : pluralize(`share`, numShares)}
      {` `}@{` `}
      {!!newPricePerShare && newPricePerShare !== pricePerShare ? (
        <>
          <span style={{ color: grey200, textDecoration: `line-through` }}>
            {currency(pricePerShare, {
              fromCents: true,
            }).format()}
          </span>
          {` `}
          {currency(newPricePerShare, {
            fromCents: true,
          }).format()}
        </>
      ) : (
        <>
          {` `}
          {currency(pricePerShare, {
            fromCents: true,
          }).format()}
        </>
      )}
    </>
  );
};

export default SharePrice;
