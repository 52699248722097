import { useField } from "formik";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
  Text,
  VStack,
} from "@chakra-ui/react";

import { CreateEntityModalTypes } from "@/components/entities";

type LegalNameInputProps = {
  isUnaccredittedSeller?: boolean;
  showLabelText?: boolean;
  type?: CreateEntityModalTypes;
} & Omit<InputProps, `type`>;

const LegalNameInput = ({
  isUnaccredittedSeller = false,
  name = `legalName`,
  showLabelText = true,
  type,
  ...props
}: LegalNameInputProps) => {
  const { t } = useTranslation(`account`, {
    keyPrefix: `entities`,
  });
  const isBuyer = type === CreateEntityModalTypes.Buyer;
  const isSeller = type === CreateEntityModalTypes.Seller;
  const [field, { error, touched }] = useField(name);

  const getLabelText = () => {
    if (isBuyer) return t(`legal_entity_name_description_buyer`);
    if (isSeller || isUnaccredittedSeller)
      return t(`legal_entity_name_description_seller`);

    return t(`legal_entity_name_description`);
  };

  return (
    <FormControl
      as={VStack}
      id={name}
      isInvalid={(error && touched) || false}
      gap={2}
      alignItems="start"
    >
      <FormLabel m={0} htmlFor={name}>
        {t(`legal_entity_name`)}
      </FormLabel>
      {showLabelText && <Text textStyle="text-sm">{getLabelText()}</Text>}
      <Input {...field} {...props} />
      <FormErrorMessage m={0}>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default LegalNameInput;
