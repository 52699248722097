import { SVGProps } from "react";

const PersonaSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    {...props}
  >
    <circle
      cx="20"
      cy="20.0254"
      r="19.5833"
      fill="#3F48FD"
      stroke="white"
      strokeWidth="0.833334"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7676 10.0039C16.4943 10.0039 13.2676 13.1775 13.2676 17.4485V30.8373H16.9304V24.8634H20.7676C25.0408 24.8634 28.2676 21.6898 28.2676 17.4485C28.2676 13.1775 25.0408 10.0039 20.7676 10.0039ZM24.6048 17.4485C24.6048 15.0757 22.9478 13.4444 20.7676 13.4444C18.5874 13.4444 16.9304 15.0757 16.9304 17.4485V21.4228H20.7676C22.9478 21.4228 24.6048 19.7916 24.6048 17.4485Z"
      fill="white"
    />
  </svg>
);

export default PersonaSvg;
