import { t } from "i18next";

import { useRouter } from "next/router";

import {
  HStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  VStack,
  AccordionIcon,
  Box,
  Circle,
} from "@chakra-ui/react";

import { HoldingCompanyLogo } from "@/components/unaccredited-seller";
import {
  Holding,
  HoldingsQuery,
  ListedHoldingFragment,
  UnlistedHoldingFragment,
} from "@/gql";
import { useSubscribeToNotifications } from "@/hooks";
import {
  doesHoldingHaveNotification,
  getNotifiedHoldings,
  mapHoldingNotifications,
} from "@/utils";

const HoldingNotification = ({
  holding,
  onClick,
}: {
  readonly holding: Holding;
  readonly onClick: () => void;
}) => (
  <Box position="relative" borderRadius="lg" onClick={onClick}>
    <HoldingCompanyLogo
      logoUrl={holding.company.logoUrl}
      name={holding.company.name}
    />
    <Circle
      bg="salmon.900"
      pos="absolute"
      size={2.5}
      top="0"
      border="solid 1px white"
      transform="translate(40%, -40%)"
      right="0"
    />
  </Box>
);

export const MobileHoldingMenu = ({
  holdingCompany,
  holdings,
  setHoldingCompany,
  onClick,
}: {
  readonly holdingCompany: ListedHoldingFragment | null;
  readonly holdings: HoldingsQuery["myHoldings"]["holdingsV2"];
  readonly setHoldingCompany: (holdingCompany: ListedHoldingFragment) => void;
  readonly onClick: () => void;
}) => {
  const router = useRouter();
  const { unreadNotifications: notifications } = useSubscribeToNotifications();

  const singleHolding = holdings?.length === 1;

  if (!holdings || !holdingCompany || singleHolding) return null;

  const holdingNotifications = mapHoldingNotifications({
    holdings,
    notifications,
    currentHolding: holdingCompany,
  });

  const renderMenuItem = (
    holding: ListedHoldingFragment | UnlistedHoldingFragment,
  ) => {
    if (holding.id === holdingCompany.id) return null;
    switch (holding.__typename) {
      case `Holding`:
        return (
          <VStack
            key={holding.id}
            onClick={() => {
              router.push(`/dashboard/${holding.companyId}`);
              setHoldingCompany(holding);
              onClick();
            }}
            height={12}
            alignItems="left"
            justifyContent="center"
          >
            <HStack spacing={3} justifyContent="space-between" w="full">
              <HStack>
                <HoldingCompanyLogo
                  logoUrl={holding.company.logoUrl}
                  name={holding.company.name}
                />
                <Text textStyle="heading-lg" noOfLines={1} maxW={48}>
                  {holding.company.name}
                </Text>
              </HStack>
              {doesHoldingHaveNotification({
                holding,
                holdingNotifications,
              }) && <Circle bg="salmon.900" size={2.5} />}
            </HStack>
          </VStack>
        );
      case `UnlistedHolding`:
        return (
          <VStack
            key={holding.id}
            height={12}
            alignItems="left"
            justifyContent="center"
            opacity="0.3"
          >
            <HStack w="full" justifyContent="space-between">
              <HStack spacing={3}>
                <HoldingCompanyLogo name={holding.companyName} />
                <Text textStyle="heading-lg" noOfLines={1} maxW={48}>
                  {holding.companyName}
                </Text>
              </HStack>
              <Text textStyle="text-sm">{t(`unavailable`)}</Text>
            </HStack>
          </VStack>
        );
      default:
        return null;
    }
  };

  const notifiedHoldings = getNotifiedHoldings({
    holdings,
    holdingNotifications,
  });

  return (
    <Accordion
      allowToggle
      w="full"
      mt={-2}
      borderTopWidth={0}
      data-dd-privacy="mask"
    >
      <AccordionItem w="full" borderTopWidth={0} borderBottomWidth={0}>
        <HStack>
          <AccordionButton
            w="full"
            height={14}
            _hover={{ bg: `none` }}
            borderWidth={1}
            borderColor="transparent"
            borderRightColor="grey.50"
          >
            <HStack justifyContent="space-between" w="full">
              <HStack spacing={3}>
                <HoldingCompanyLogo
                  logoUrl={holdingCompany.company.logoUrl}
                  name={holdingCompany.company.name}
                />
                <Text textStyle="heading-lg" position="relative" top="1px">
                  {holdingCompany.company.name}
                </Text>
              </HStack>
              <AccordionIcon />
            </HStack>
          </AccordionButton>
          {notifiedHoldings.length > 0 && (
            <HStack gap={4} pr={4} pl={2} justifyContent="space-between">
              {notifiedHoldings.map((holding: Holding) => (
                <HoldingNotification
                  key={holding.id}
                  holding={holding}
                  onClick={() => {
                    router.push(`/dashboard/${holding.companyId}`);
                    setHoldingCompany(holding);
                    onClick();
                  }}
                />
              ))}
            </HStack>
          )}
        </HStack>
        <AccordionPanel
          w="full"
          py={0}
          pl={6}
          pr={4}
          borderColor="grey.50"
          borderTopWidth="1px"
          bg="grey.25"
        >
          {holdings.map((holding) => renderMenuItem(holding))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
