import { Circle } from "@phosphor-icons/react";
import { Trans } from "react-i18next";

import { Badge, HStack, Text } from "@chakra-ui/react";

import { useColors } from "@/hooks";

type ActiveTransactionTasksBadgeProps = {
  taskCount: number;
};

const ActiveTransactionTasksBadge = ({
  taskCount,
}: ActiveTransactionTasksBadgeProps) => {
  const [salmon900] = useColors([`salmon.900`]);
  return (
    <Badge
      alignContent="center"
      borderRadius={6}
      bg="grey.50"
      display="flex"
      justifyContent="center"
      h="28px"
      px={2}
      py={1}
    >
      <HStack gap={2}>
        <Circle size={8} weight="fill" color={salmon900} />
        <Text
          color="grey.900"
          textStyle="text-xs"
          fontWeight={500}
          lineHeight="18px"
        >
          <Trans i18nKey="task" ns="dashboard" count={taskCount} />
        </Text>
      </HStack>
    </Badge>
  );
};

export default ActiveTransactionTasksBadge;
