import currency from "currency.js";
import isNil from "lodash/isNil";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  Button,
  GridItem,
  HStack,
  ModalBody,
  Show,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveAdvancedOptionsDropdown,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  ShareDetails,
  ShareDetailsStats,
} from "@/components/common";
import {
  CheckboxInput,
  EmailInput,
  FixedValueInput,
  FormNumberInput,
  FormSelectListingInput,
  MoneyInput,
  QuestionTooltip,
  StepPropsV2,
  TextInput,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  bidTimeLimitSchema,
  representedEmailSchema,
  representedNameSchema,
} from "@/components/postings";
import {
  PlaceStandingBidTooltip,
  RepresentedUserTooltip,
} from "@/components/tooltip";
import {
  ListingPermission,
  ListingState,
  PlaceBidSequenceModalPlaceBidCompanyFragment,
  PlaceBidSequenceModalPlaceBidListingFragment,
  UserWithInstitutionFragment,
  TransferMethodV2,
} from "@/gql";
import { iHaveEntityPermission, useModal, useStepValidator } from "@/hooks";
import {
  bidLabel,
  constants,
  formatCurrency,
  formatShares,
  getNumOfShares,
  getNumSharesAvailableRounded,
  getPricePerShare,
  isHiiveFund,
} from "@/utils";

import { PlaceBidSequenceModalStepFormContext } from "./PlaceBidSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { PlaceBidSequenceModalFormValues } from "./types";

const PlaceBidTranslations = {
  description: `place_bid_description`,
  grossValue: `gross_value_of_your_bid`,
  header: `place_a_bid`,
  label: `share`,
  numShares: `number_of_shares`,
  placeBidIntent: `place_bid_intent`,
  placeBid: `place_bid`,
  pricePerShare: `price_per_share`,
} as const;

const FundPlaceBidTranslations = {
  description: `place_bid_description`,
  grossValue: `gross_value_of_your_bid`,
  header: `place_a_bid`,
  label: `unit`,
  numShares: `number_of_fund_units`,
  placeBidIntent: `place_bid_intent`,
  placeBid: `place_bid`,
  pricePerShare: `price_per_fund_unit`,
  validForDays: `bid_valid_for_days`,
} as const;

const HiiveFundPlaceBidTranslations = {
  description: `hiive_fund_place_bid_description`,
  grossValue: `gross_investment_amount`,
  header: `place_an_order`,
  label: `unit`,
  numShares: `number_of_fund_units`,
  placeBidIntent: `hiive_fund_place_bid_intent`,
  placeBid: `place_order`,
  pricePerShare: `price_per_fund_unit`,
  validForDays: `order_valid_for_days`,
} as const;

const getPlaceBidTranslationKeys = (
  listing: PlaceBidSequenceModalPlaceBidListingFragment | undefined,
) =>
  match({ transferMethod: listing && listing.transferMethodV2 })
    .with(
      { transferMethod: TransferMethodV2.HiiveFund },
      () => HiiveFundPlaceBidTranslations,
    )
    .with(
      { transferMethod: TransferMethodV2.Fund },
      () => FundPlaceBidTranslations,
    )
    .otherwise(() => PlaceBidTranslations);

const createValidationSchema = ({
  selectedListing,
  actor,
}: {
  readonly selectedListing?: PlaceBidSequenceModalPlaceBidListingFragment | null;
  readonly actor: UserWithInstitutionFragment;
}) =>
  Yup.object().shape({
    listingId: Yup.string().required(`Must select a listing to bid on.`),
    pricePerShare: Yup.number()
      .nullable()
      .test(
        `min-bid-size`,
        `The bid size can't be less than ${currency(
          constants.min_bid_lot.number,
        ).format()}`,
        function test(value: number) {
          const { numShares } = this.parent;

          return value * numShares >= constants.min_bid_lot.number;
        },
      )
      .required(`Price per share is required`),
    numShares: Yup.number()
      .max(
        !!selectedListing ? getNumSharesAvailableRounded(selectedListing) : 0,
        `Can't request more shares than a listing has`,
      )
      .required(`Required`),
    timeLimit: bidTimeLimitSchema,
    representedEmail: representedEmailSchema(actor),
    representedFirstName: representedNameSchema(actor),
    representedLastName: representedNameSchema(actor),
    notifyRepresentingEmail: Yup.boolean().required(`Required`),
    confirmed: Yup.boolean().oneOf([true], `Required`),
    muteNotifyWatchers: Yup.boolean().nullable(),
  });

const getSelectedListing = (
  listingId: string,
  listingOptions: readonly PlaceBidSequenceModalPlaceBidListingFragment[],
) => {
  if (!listingId) return null;

  return listingOptions.find((listingOption) => listingOption.id === listingId);
};

interface PlaceBidModalProps
  extends StepPropsV2<StepKeys, PlaceBidSequenceModalFormValues> {
  readonly company: PlaceBidSequenceModalPlaceBidCompanyFragment;
  readonly actor: UserWithInstitutionFragment;
  readonly allowDirectBidPlacing?: boolean;
  readonly initialListing?: PlaceBidSequenceModalPlaceBidListingFragment;
}

const PlaceBidShareDetails = ({
  listing,
}: {
  readonly listing: PlaceBidSequenceModalPlaceBidListingFragment;
}) => {
  const numberOfShares = getNumOfShares(listing, true);
  const pricePerShare = getPricePerShare(listing);

  return (
    <ShareDetails
      variant="listing"
      title="Listing Details"
      data-dd-privacy="mask"
    >
      <ShareDetailsStats
        numberOfShares={numberOfShares}
        pricePerShare={pricePerShare}
      />
    </ShareDetails>
  );
};

const PlaceBid = withCurrentActor(
  ({
    company,
    initialListing,
    allowDirectBidPlacing,
    values,
    actor,
    stepRouter,
    setFieldValue,
  }: PlaceBidModalProps) => {
    const { stepControls } = stepRouter;
    const { closeModal, onOpenModal, modals } = useModal();

    const router = useRouter();

    const handleSelectListing = (
      listing: PlaceBidSequenceModalPlaceBidListingFragment,
    ) => {
      const numShares = getNumSharesAvailableRounded(listing);
      const pricePerShareCents = getPricePerShare(listing);
      const pricePerShareDollars = !isNil(pricePerShareCents)
        ? pricePerShareCents / 100
        : ``;

      setFieldValue(`numShares`, numShares);
      setFieldValue(`pricePerShare`, pricePerShareDollars);
    };

    const { t } = useTranslation();

    const translationKeys = getPlaceBidTranslationKeys(initialListing);

    useEffect(() => {
      if (!initialListing) return;
      handleSelectListing(initialListing);
    }, []);

    const listingsICanBidOn = (
      company: PlaceBidSequenceModalPlaceBidCompanyFragment,
    ) =>
      company.activity.othersListings.filter(
        (listing) =>
          listing.state === ListingState.Open &&
          iHaveEntityPermission(listing, ListingPermission.PlaceBid),
      );

    const listingOptions = listingsICanBidOn(company);

    const onClickPlaceStandingBid = () =>
      onOpenModal(modals.placeStandingBid(company))();

    const selectedListing = getSelectedListing(
      values.listingId,
      listingOptions,
    );

    const onClickViewFullListing = () => {
      if (!selectedListing) return;

      closeModal();
      router.push(`/listings/${selectedListing.id}`);
    };

    const onSuccess = () => stepControls.nextStep();

    const validationSchema = createValidationSchema({
      selectedListing,
      actor,
    });

    useStepValidator({
      Context: PlaceBidSequenceModalStepFormContext,
      stepKey: stepKeys.placeBid,
      validator: {
        validationSchema,
        onSuccess,
      },
      values,
    });

    const { pricePerShare } = values;

    const parsedPPS = pricePerShare ? parseFloat(pricePerShare.toString()) : 0;

    return (
      <>
        <HiiveModalHeader>{t(translationKeys.header)}</HiiveModalHeader>
        {!initialListing && (
          <>
            <ModalBody borderBottomWidth={0} pb={3}>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                spacing={0}
                flexWrap="wrap"
              >
                <Text textStyle="text-base">
                  Select a listing to make a bid, or alternatively,
                </Text>
                <HStack spacing={1}>
                  <Button
                    variant="text-salmon"
                    p={0}
                    onClick={onClickPlaceStandingBid}
                  >
                    place a standing bid
                  </Button>
                  <QuestionTooltip
                    tooltipContent={<PlaceStandingBidTooltip />}
                  />
                </HStack>
              </Stack>
            </ModalBody>
            <ModalBody px={0} pt={0}>
              <FormSelectListingInput
                onSelectListing={handleSelectListing}
                options={listingOptions}
              />
            </ModalBody>
          </>
        )}
        {!!selectedListing && (
          <>
            <ModalBody>
              <VStack w="full" alignItems="flex-start" spacing={5}>
                <Text mb={4}>{t(translationKeys.description)}</Text>
                <PlaceBidShareDetails listing={selectedListing} />

                <div>
                  To place {isHiiveFund(selectedListing) ? `an order` : `a bid`}
                  ,{` `}
                  <Button
                    variant="text-salmon"
                    px={0}
                    py={0}
                    onClick={onClickViewFullListing}
                  >
                    view listing
                  </Button>
                </div>
              </VStack>
            </ModalBody>
            {allowDirectBidPlacing && (
              <ModalBody>
                <SimpleGrid columns={2} columnGap={9} rowGap={6} w="full">
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    {!selectedListing.acceptPartialBid ? (
                      <FixedValueInput
                        label={t(translationKeys.numShares)}
                        name="numShares"
                        fixedValue={formatShares(values.numShares)}
                      />
                    ) : (
                      <FormNumberInput
                        name="numShares"
                        label={t(translationKeys.numShares)}
                      />
                    )}
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    {initialListing && isHiiveFund(initialListing) ? (
                      <>
                        <Text fontWeight="medium">
                          {t(translationKeys.pricePerShare)}
                        </Text>
                        <Text mt={4}>{formatCurrency(parsedPPS)}</Text>
                      </>
                    ) : (
                      <MoneyInput
                        name="pricePerShare"
                        label={t(translationKeys.pricePerShare)}
                      />
                    )}
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormNumberInput
                      name="timeLimit"
                      label={
                        t(`bid_valid_for_days`, {
                          bidLabel: bidLabel(selectedListing),
                        }) as string
                      }
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <CheckboxInput
                      name="confirmed"
                      label={t(translationKeys.placeBidIntent)}
                    />
                  </GridItem>
                  {actor.isHiiveUser && (
                    <GridItem colSpan={2}>
                      <HiiveAdvancedOptionsDropdown
                        validationSchema={validationSchema}
                        fieldNames={[
                          `muteNotifyWatchers`,
                          `representedEmail`,
                          `representedFirstName`,
                          `representedLastName`,
                        ]}
                      >
                        <VStack spacing={4} alignItems="flex-start">
                          <CheckboxInput
                            name="muteNotifyWatchers"
                            label="Do not send a notification to watchers about this change."
                          />
                          <Text
                            align="center"
                            textStyle="deprecated-heading-lg"
                            color="h-dark-grey"
                          >
                            {t(`represented_user_info`)}
                            <QuestionTooltip
                              translateY={1.5}
                              translateX={1.0}
                              transform="auto"
                              tooltipContent={<RepresentedUserTooltip />}
                            />
                          </Text>
                          <HStack gap={4} w="full" alignItems="top">
                            <TextInput
                              data-testid="represented-user-first-name"
                              name="representedFirstName"
                              placeholder={t(`first_name`)}
                            />
                            <TextInput
                              data-testid="represented-user-last-name"
                              name="representedLastName"
                              placeholder={t(`last_name`)}
                            />
                          </HStack>
                          <EmailInput
                            name="representedEmail"
                            placeholder="Email"
                            type="email"
                          />
                          <CheckboxInput
                            name="notifyRepresentingEmail"
                            label="Notify the person being represented"
                          />
                        </VStack>
                      </HiiveAdvancedOptionsDropdown>
                    </GridItem>
                  )}
                </SimpleGrid>
              </ModalBody>
            )}
          </>
        )}
        {allowDirectBidPlacing && (
          <HiiveModalFooter>
            <Show above="md" ssr={false}>
              <HiiveCancelButton
                sentryLabel="[PlaceBid/Cancel]"
                onCancel={closeModal}
              />
            </Show>
            <HiiveSubmitButton
              sentryLabel="[PlaceBid/Submit]"
              submitText={t(translationKeys.placeBid)}
              type="submit"
            />
          </HiiveModalFooter>
        )}
      </>
    );
  },
);
export default PlaceBid;
