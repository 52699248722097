import { TFunction } from "i18next";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Card, CardBody, Flex } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import {
  AddHoldingsForm,
  HoldingDetailsFormFieldValues,
  useExistingHoldingFields,
} from "@/components/unaccredited-seller";
import {
  HoldingType,
  useHoldingDetailsPageSetHoldingsMutation,
  useTransitionCurrentStepMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

const holdingSchema = (t: TFunction) =>
  Yup.object().shape({
    companyId: Yup.string(),
    numShares: Yup.number()
      .positive(
        t(`validation_min_items`, {
          min: 1,
          field: t(`share`),
        }),
      )
      .transform((value) => (Number.isNaN(value) ? null : value))
      .test(
        `valid-number-when-defined`,
        t(`validation_valid_number`, { field: t(`shares`) }),
        (value) => isNil(value) || Number.isInteger(value),
      )
      .nullable(),
    type: Yup.string().oneOf(Object.values(HoldingType)).required(),
    name: Yup.string().required(t(`validation_required`, { field: t(`name`) })),
    logoUrl: Yup.string().nullable(),
  });

const createValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    holdings: Yup.array()
      .of(holdingSchema(t))
      .min(1, t(`seller_lot_details_min_items`, {}))
      .max(4, t(`seller_lot_details_max_items`, {})),
    sellerLotDisclaimer: Yup.boolean()
      .oneOf([true], t`required`)
      .required(t`required`),
  });

const initialValues: HoldingDetailsFormFieldValues = {
  holdings: [],
  sellerLotDisclaimer: false,
};

const mapVariables = ({
  sellerLotDisclaimer: _sellerLotDisclaimer,
  holdings,
  ...values
}: HoldingDetailsFormFieldValues) => ({
  addHoldingsInput: {
    holdings: holdings.map(({ companyId, numShares, type, name }) => ({
      value: type === HoldingType.Listed ? companyId || `` : name,
      numShares,
      type,
    })),
    ...values,
  },
});

export const HoldingDetailsForm = () => {
  const { t } = useTranslation();

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const mutation = useHoldingDetailsPageSetHoldingsMutation();
  const validationSchema = createValidationSchema(t);

  const onNext = async () => {
    await transitionCurrentStepMutation();
  };

  const { handleSubmit, control, watch, reset } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess: onNext,
  });

  const formId = `addHoldingsFormOnboarding`;
  const values = watch();

  useExistingHoldingFields((holdings) => {
    reset(
      {
        ...initialValues,
        holdings,
      },
      {
        keepDefaultValues: true,
      },
    );
  });

  return (
    <Flex direction="column" gap={8}>
      <SlideAnimation>
        <Card>
          <CardBody>
            <AddHoldingsForm
              formId={formId}
              control={control}
              watch={watch}
              handleSubmit={handleSubmit}
            />
          </CardBody>
        </Card>
      </SlideAnimation>
      <Flex justifyContent="flex-end" w="full">
        <HiiveButton
          type="submit"
          isLoading={isTransitioningCurrentStep}
          size="xl"
          form={formId}
          w={{ base: `full`, sm: `unset` }}
          maxW="unset"
          variant="rounded-solid-salmon"
          sentryLabel="[HoldingDetailsPage] Next"
          isDisabled={values?.holdings?.length === 0}
        >
          {t(`next`)}
        </HiiveButton>
      </Flex>
    </Flex>
  );
};
