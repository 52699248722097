import { WarningCircle } from "@phosphor-icons/react";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveBackButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PIN_LENGTH,
} from "@/components/common";
import { useCountDown, useModal } from "@/hooks";
import { useEnrollSmsMfa, useVerifySmsMfa } from "@/hooks/frontegg";
import { normalizePhoneNumber } from "@/utils/format";

import { SendNewCode } from "./SendNewCode";

const EnrollSmsMfaVerifyModal = ({
  otcToken: initialOtcToken,
  phoneNumber,
}: {
  readonly otcToken: string;
  readonly phoneNumber: string;
}) => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();

  const [pinValue, setPinValue] = useState<string>(``);
  const [otcToken, setOtcToken] = useState<string>(initialOtcToken);

  const { timeLeft, resetTimer } = useCountDown();

  const [enrollMfa, { error: enrollSmsMfaError }] = useEnrollSmsMfa({
    onSuccess: (data) => {
      const { otcToken: newOtcToken } = data;

      setOtcToken(newOtcToken);
      resetTimer();
    },
  });

  const [verifyMfa, { loading, error: verifySmsMfaError }] = useVerifySmsMfa({
    onSuccess: (data) => {
      const { recoveryCode } = data;

      const mfaSmsEnrollCompleteModal = onOpenModal(
        modals.enrollSmsMfaSuccess(recoveryCode),
      );

      mfaSmsEnrollCompleteModal();
    },
  });

  const showError = !!verifySmsMfaError || !!enrollSmsMfaError;
  const errorMessage = enrollSmsMfaError
    ? t(`failed_to_enroll_mfa`)
    : t(`incorrect_code`);

  const handleComplete = useCallback(
    async (value: string) => verifyMfa({ otcToken, code: value }),
    [otcToken, verifyMfa, onOpenModal],
  );

  const handleSendNewCode = async () => {
    const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
    await enrollMfa(normalizedPhoneNumber);
  };

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`verify_phone_number`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          <Text>
            <Trans
              i18nKey="verify_phone_number_description_1"
              t={t}
              values={{ lastFourDigits: phoneNumber.slice(-4) }}
            />
          </Text>
          <Text>{t(`verify_phone_number_description_2`)}</Text>

          <CodeInput
            disabled={loading}
            onChange={setPinValue}
            onComplete={handleComplete}
          />
          {showError && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{errorMessage}</Text>
            </CallOut>
          )}
          <SendNewCode
            onSendNewCode={handleSendNewCode}
            sentryLabel="[EnrollSmsMfaVerify/SendCode]"
            timeLeft={timeLeft}
          />
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveBackButton
            sentryLabel="[EnrollSmsMfaVerify/Back]"
            onClick={() => onOpenModal(modals.enrollSmsMfa())()}
          >
            {t(`cancel`)}
          </HiiveBackButton>
          <HiiveSubmitButton
            isDisabled={pinValue.length !== PIN_LENGTH || loading}
            sentryLabel="[EnrollSmsMfaVerify/Submit]"
            submitText={t(`next`)}
            type="submit"
            onClick={() => handleComplete(pinValue)}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default EnrollSmsMfaVerifyModal;
