import isNil from "lodash/isNil";

import { AskPriceComparisonChartCompanyFragment } from "@/gql";

export const getHasAskPriceChart = (
  company: AskPriceComparisonChartCompanyFragment,
) => {
  const { hasAskPriceComparisonChart, lastRoundPricePerShare } = company;

  return (
    [
      company.currentPrices?.lowestAsk,
      company.currentPrices?.highestBid,
      company.currentPrices?.lastTransaction,
      lastRoundPricePerShare,
    ].some((price) => !isNil(price)) && hasAskPriceComparisonChart
  );
};
