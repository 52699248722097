import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { HStack, Tab, TabList, Tabs } from "@chakra-ui/react";

import { TAB_MARGIN_OFFSET } from "@/components/issuers";
import { ROUTES } from "@/utils";

const ACCOUNT_SETTINGS_TABS = [
  ROUTES.ISSUER_ACCOUNT_DETAILS,
  ROUTES.ISSUER_ACCOUNT_SECURITY,
];

export const AccountSettingsTabs = () => {
  const { t } = useTranslation();
  const { push, pathname } = useRouter();

  const tabIndex = ACCOUNT_SETTINGS_TABS.indexOf(pathname) ?? 0;

  const handleTabChange = useCallback(
    (index: number) => {
      const route = ACCOUNT_SETTINGS_TABS[index];
      if (route && pathname !== route) {
        push(route);
      }
    },
    [pathname, push],
  );

  return (
    <Tabs
      variant="black"
      mx={TAB_MARGIN_OFFSET}
      defaultIndex={tabIndex}
      onChange={handleTabChange}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <TabList alignItems="center" pb={0}>
          <Tab py={4} borderTopRadius={8}>
            {t(`details`)}
          </Tab>
          <Tab py={4} borderTopRadius={8}>
            {t(`security`)}
          </Tab>
        </TabList>
      </HStack>
    </Tabs>
  );
};
