import { ShareDetailsDisclaimer } from "@/components/common";
import { TransactionRoundedSharesTooltip } from "@/components/tooltip";
import { formatShares } from "@/utils";

const BidBuyerRoundingDisclaimer = ({
  numSharesOriginal,
  bidText,
  shareText,
}: {
  readonly numSharesOriginal: number;
  readonly bidText: string;
  readonly shareText: string;
}) => (
  <ShareDetailsDisclaimer tooltip={<TransactionRoundedSharesTooltip />}>
    of your original {bidText} of{` `}
    {formatShares(numSharesOriginal)} {shareText}s
  </ShareDetailsDisclaimer>
);

export default BidBuyerRoundingDisclaimer;
