import { Form } from "formik";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ButtonFooterWrapper, HiiveSubmitButton } from "@/components/common";
import { EmailInput, FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment, useUpdateUserEmailMutation } from "@/gql";
import { useCustomToast } from "@/hooks";
import { ToastDuration } from "@/types/toast";
import { constants } from "@/utils";

const { EMAIL_REGEX } = constants;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .required(`Email is required`)
    .matches(EMAIL_REGEX, `Invalid email address`),
});

interface ChangeEmailFormValues {
  readonly email: string;
}

const createInitialValues = (email: string) => ({
  email,
});

const mapVariables = (values: ChangeEmailFormValues) => ({
  email: values.email,
});

export const ChangeEmailCard = withCurrentActor(
  ({ actor: { email } }: { readonly actor: UserWithInstitutionFragment }) => {
    const { successToast } = useCustomToast();
    const router = useRouter();
    const mutation = useUpdateUserEmailMutation();

    const onSuccess = () => {
      successToast(
        `Submitted successfully! Please confirm your new email address to complete the update.`,
        { duration: ToastDuration.Long },
      );
      router.push(`/account/request-updated-email-verification`);
    };

    const initialValues = createInitialValues(email);

    return (
      <FormikQL
        mutation={mutation}
        mutationNames={[`updateUserEmail`]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        mapVariables={mapVariables}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values }) => (
          <Card w="full" flex="1" as={Form} autoComplete="off">
            <CardHeader>
              <Text textStyle="heading-sm">Change Email</Text>
            </CardHeader>
            <CardBody p={{ base: 4, lg: 10 }}>
              <SimpleGrid columns={2} columnGap={9}>
                <GridItem colSpan={{ base: 2, xl: 1 }}>
                  <VStack spacing={6}>
                    <EmailInput
                      name="email"
                      label="Email address"
                      placeholder="Enter email address"
                      bg="h-white"
                    />
                    <ButtonFooterWrapper>
                      <HiiveSubmitButton
                        submitText="Update"
                        type="submit"
                        sentryLabel="[ChangeEmail/Submit]"
                        isLoading={isSubmitting}
                        isDisabled={email === values.email}
                      />
                    </ButtonFooterWrapper>
                  </VStack>
                </GridItem>
              </SimpleGrid>
            </CardBody>
          </Card>
        )}
      </FormikQL>
    );
  },
);
