import isNil from "lodash/isNil";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  Tag,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";

import { InternalLink, Skeleton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { useSuitabilityQuestionGroupsQuery } from "@/gql";
import { useAccreditationPageStatus, useSuitabilityPageStatus } from "@/hooks";
import { useMarketplaceConfiguration } from "@/hooks/featureFlags";
import {
  areSuitabilityAnswersCurrent,
  getAvailableInstitutionActions,
  getIsBroker,
  getIsInstitutionUser,
  getIsUnaccreditedSeller,
  getSuitabilityAnsweredVersion,
  getUserCountry,
} from "@/utils";

const AccountPageLinkTag = ({ text }: { readonly text: string }) => (
  <Tag size="md" variant="salmon">
    <TagLabel>{text}</TagLabel>
  </Tag>
);

const AccountPageLink = ({
  href,
  children,
  onClick,
  isIncomplete,
  attentionRequired,
  loading,
}: {
  readonly href: string;
  readonly children: ReactNode;
  readonly onClick?: () => void;
  readonly isIncomplete?: boolean;
  readonly attentionRequired?: boolean;
  readonly loading?: boolean;
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  const isActive = router.asPath === href;

  if (loading) {
    return <Skeleton h={10} w="full" />;
  }

  return (
    <InternalLink
      href={href}
      px={5}
      py={2}
      borderRadius="lg"
      _hover={{ bg: `grey.50` }}
      _active={{ bg: `grey.75` }}
      bg={isActive ? `grey.50` : `white`}
      borderColor={isActive ? `grey.100` : `white`}
      transition=".2s all ease-in-out"
      borderWidth="1px"
      display="flex"
      alignItems="center"
      textStyle="text-md"
      textAlign="left"
      w="full"
      onClick={onClick}
    >
      {children}
      {(isIncomplete || attentionRequired) && (
        <>
          <Box flexGrow={1} />
          <AccountPageLinkTag
            text={attentionRequired ? t(`attention_required`) : t(`incomplete`)}
          />
        </>
      )}
    </InternalLink>
  );
};

const AccountPageLinkGroup = ({
  title,
  children,
}: {
  readonly title: string;
  readonly children: ReactNode;
}) => (
  <VStack spacing={2} alignItems="flex-start">
    <Text textStyle="heading-sm">{title}</Text>
    {children}
  </VStack>
);

export const AccountPageNavigation = withCurrentActor(({ actor }) => {
  const { t } = useTranslation();

  const {
    institution,
    agreedToCustomerAgreement,
    membershipAgreementSigned,
    identityVerified,
  } = actor;
  const isInstitutionUser = getIsInstitutionUser(actor);
  const isBroker = getIsBroker(actor);
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  const accreditationPageStatus = useAccreditationPageStatus(actor);
  const suitabilityPageStatus = useSuitabilityPageStatus(actor);

  const country = getUserCountry(actor);

  const { data } = useSuitabilityQuestionGroupsQuery({
    variables: {
      investorStatus: actor.investorStatus!,
      countryId: country.id,
    },
  });

  const suitabilityCurrent = areSuitabilityAnswersCurrent(
    actor,
    data?.currentSuitabilityQuestionGroup,
  );
  const isSuitabilityAnsweredVersionV1 =
    getSuitabilityAnsweredVersion(actor) === 1;

  const { canUpdateComplianceEmail, canManageInstitutionUsers } =
    getAvailableInstitutionActions(institution);

  const canVerifyIdentity = !isInstitutionUser && !isBroker;

  const canManageInstitution =
    canUpdateComplianceEmail || canManageInstitutionUsers;

  const canAgreeToCA = !isBroker;

  const agreedToCA =
    agreedToCustomerAgreement ||
    membershipAgreementSigned ||
    institution?.membershipAgreementSigned;

  const isAccredited = actor.accreditationAnswers?.length > 0;

  const hasProvidedTrustedContactPerson = !isNil(actor.trustedContactPerson);

  const canViewProfileLinks =
    accreditationPageStatus !== `hidden` ||
    canVerifyIdentity ||
    canAgreeToCA ||
    suitabilityPageStatus !== `hidden`;

  const { isHiiveUser } = actor;
  const canViewTransactionEntitiesMenu = !(isHiiveUser || isBroker);

  const marketplaceConfigurationEnabled = useMarketplaceConfiguration();

  return (
    <Card w="full" h="min-content">
      {canViewProfileLinks && (
        <CardBody>
          <AccountPageLinkGroup title="Profile">
            {accreditationPageStatus !== `hidden` && (
              <AccountPageLink
                href="/account/profile/accreditation"
                isIncomplete={!isAccredited}
              >
                {t(`accreditation`)}
              </AccountPageLink>
            )}

            {canVerifyIdentity && (
              <AccountPageLink
                href="/account/profile/identity-verification"
                isIncomplete={!identityVerified}
              >
                {t(`iv`)}
              </AccountPageLink>
            )}
            {canAgreeToCA && (
              <AccountPageLink
                href="/account/profile/customer-agreement"
                isIncomplete={!agreedToCA}
              >
                {t(`customer_agreement`)}
              </AccountPageLink>
            )}
            {suitabilityPageStatus !== `hidden` && (
              <AccountPageLink
                href="/account/profile/suitability"
                isIncomplete={!suitabilityCurrent}
                attentionRequired={isSuitabilityAnsweredVersionV1}
              >
                {t(`suitability`)}
              </AccountPageLink>
            )}

            {isUnaccreditedSeller && (
              <AccountPageLink href="/account/profile/my-holdings">
                {t(`my_holdings`)}
              </AccountPageLink>
            )}
            {canViewTransactionEntitiesMenu && (
              <AccountPageLink href="/account/profile/transacting-entities">
                {t(`entities.transacting_entities`, {
                  ns: `account`,
                })}
              </AccountPageLink>
            )}
            {!isInstitutionUser && (
              <AccountPageLink
                href="/account/profile/trusted-contact-person"
                isIncomplete={!hasProvidedTrustedContactPerson}
              >
                {t(`trusted_contact_person`)}
              </AccountPageLink>
            )}
          </AccountPageLinkGroup>
        </CardBody>
      )}
      <CardBody>
        <AccountPageLinkGroup title="Transfers">
          <AccountPageLink href="/account/transfers/completed-transfers">
            Completed Transfers
          </AccountPageLink>
        </AccountPageLinkGroup>
      </CardBody>
      <CardBody>
        <AccountPageLinkGroup title="Settings">
          {marketplaceConfigurationEnabled ? (
            <AccountPageLink href="/account/settings/contact">
              {t(`contact`)}
            </AccountPageLink>
          ) : (
            <>
              <AccountPageLink href="/account/settings/change-email">
                Change Email
              </AccountPageLink>
              <AccountPageLink href="/account/settings/change-phone">
                Change Phone
              </AccountPageLink>
            </>
          )}

          {marketplaceConfigurationEnabled ? (
            <AccountPageLink href="/account/settings/security">
              {t(`security`)}
            </AccountPageLink>
          ) : (
            <AccountPageLink href="/account/settings/change-password">
              Change Password
            </AccountPageLink>
          )}
        </AccountPageLinkGroup>
      </CardBody>
      <CardBody>
        <AccountPageLinkGroup title="Preferences">
          <AccountPageLink href="/account/preferences/notifications">
            Notifications
          </AccountPageLink>
        </AccountPageLinkGroup>
      </CardBody>
      {canManageInstitution && institution && (
        <CardBody>
          <AccountPageLinkGroup title={`${institution?.legalName} admin`}>
            {canManageInstitutionUsers && (
              <AccountPageLink href="/account/manage-users/account-users">
                Account Users
              </AccountPageLink>
            )}
            {canUpdateComplianceEmail && (
              <AccountPageLink href="/account/settings/change-compliance-email">
                Compliance Email
              </AccountPageLink>
            )}
          </AccountPageLinkGroup>
        </CardBody>
      )}
    </Card>
  );
});
