import { WarningCircle } from "@phosphor-icons/react";
import { nanoid } from "nanoid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Card, CardBody, VStack, Text, TextProps, Box } from "@chakra-ui/react";

import { CallOut } from "@/components/common";
import {
  MilestoneTimeline,
  MilestoneTimelineContent,
  MilestoneTimelineIcon,
  MilestoneTimelineNode,
  MilestoneTimelineIcons,
  MilestoneTimelineIndicator,
  MilestoneTimelineLine,
  MilestoneTimelineLabel,
  MilestoneTimelineAfter,
  MilestoneTimelineDescription,
} from "@/components/transactions";
import { ExecutionMilestone, IssuerStage } from "@/gql";
import { useColors } from "@/hooks";
import {
  IssuerMilestoneTimelineStatus,
  IssuerTransactionValidMilestones,
  MappedMilestoneTimelineDoneNode,
  MappedMilestoneTimelineInProgressNode,
  MappedMilestoneTimelinePendingNode,
  mapIssuerMilestoneTimeline,
} from "@/utils";
import * as datetime from "@/utils/datetime";

import { IssuerTransactionContextTransaction } from "./IssuerTransactionProvider";

type IssuerTransactionStatusCardProps = {
  transaction: NonNullable<IssuerTransactionContextTransaction>;
};

type IssuerMilestoneTimelineProps = {
  workflow: NonNullable<
    NonNullable<IssuerTransactionContextTransaction>["workflow"]
  >;
};

type IssuerMilestoneTimelineDoneNodeProps = {
  node: MappedMilestoneTimelineDoneNode;
};

type IssuerMilestoneTimelineInProgressNodeProps = {
  node: MappedMilestoneTimelineInProgressNode;
};

type IssuerMilestoneTimelinePendingNodeProps = {
  node: MappedMilestoneTimelinePendingNode;
};

type IssuerMilestoneTimelineLabelProps = {
  name: IssuerTransactionValidMilestones;
} & TextProps;

const MILESTONE_TIMELINE_COLLAPSED_HEIGHT = 9;
const MILESTONE_TIMELINE_EXPANDED_HEIGHT = 16;

const MILESTONE_ORDER = [
  ExecutionMilestone.Submitted,
  ExecutionMilestone.Approved,
  ExecutionMilestone.Signed,
  ExecutionMilestone.Complete,
] as const;

function IssuerMilestoneTimelineLabel({
  name,
  ...restProps
}: IssuerMilestoneTimelineLabelProps) {
  const { t } = useTranslation(`issuers`);

  const milestoneNames = useMemo(
    () => ({
      [ExecutionMilestone.Submitted]: t(`submitted`),
      [ExecutionMilestone.Approved]: t(`approved`),
      [ExecutionMilestone.Signed]: t(`signed`),
      [ExecutionMilestone.Complete]: t(`completed`),
    }),
    [t],
  );

  return (
    <MilestoneTimelineLabel {...restProps}>
      {milestoneNames[name]}
    </MilestoneTimelineLabel>
  );
}

function IssuerMilestoneTimelineDoneNode({
  node: {
    name,
    isLastMilestone,
    milestone: { completedAt },
  },
}: IssuerMilestoneTimelineDoneNodeProps) {
  const { t } = useTranslation(`issuers`);
  const description = useMemo(
    () =>
      match(name)
        .with(ExecutionMilestone.Complete, () =>
          t(`transfer_of_funds_confirmed`),
        )
        .otherwise(() => null),
    [name],
  );

  return (
    <MilestoneTimelineNode
      px={0}
      indicator={
        <MilestoneTimelineIndicator
          color="plum.1000"
          line={
            !isLastMilestone && <MilestoneTimelineLine stroke="plum.1000" />
          }
          icon={<MilestoneTimelineIcon variant={MilestoneTimelineIcons.Fill} />}
        />
      }
    >
      <MilestoneTimelineContent
        label={<IssuerMilestoneTimelineLabel name={name} />}
        after={
          <MilestoneTimelineAfter>
            {datetime.format(`Do MMM (h:mm A z)`, completedAt)}
          </MilestoneTimelineAfter>
        }
        description={
          description && (
            <MilestoneTimelineDescription>
              {description}
            </MilestoneTimelineDescription>
          )
        }
      />
    </MilestoneTimelineNode>
  );
}

function IssuerMilestoneTimelineInProgressNode({
  node: { name, isLastMilestone },
}: IssuerMilestoneTimelineInProgressNodeProps) {
  const { t } = useTranslation(`issuers`);

  const description = useMemo(
    () =>
      match(name)
        .with(ExecutionMilestone.Approved, () =>
          t(`transaction_awaiting_approval`),
        )
        .with(ExecutionMilestone.Signed, () =>
          t(`documents_awaiting_signatures`),
        )
        .with(ExecutionMilestone.Complete, () => t(`funds_awaiting_transfer`))
        .otherwise(() => null),
    [name],
  );

  return (
    <MilestoneTimelineNode
      px={0}
      indicator={
        <MilestoneTimelineIndicator
          color="plum.1000"
          line={
            !isLastMilestone && (
              <MilestoneTimelineLine
                h={
                  description
                    ? MILESTONE_TIMELINE_EXPANDED_HEIGHT
                    : MILESTONE_TIMELINE_COLLAPSED_HEIGHT
                }
                strokeDasharray={0.06}
              />
            )
          }
          icon={
            <MilestoneTimelineIcon variant={MilestoneTimelineIcons.Active} />
          }
        />
      }
    >
      <MilestoneTimelineContent
        label={<IssuerMilestoneTimelineLabel name={name} color="plum.1000" />}
        description={
          description && (
            <MilestoneTimelineDescription>
              {description}
            </MilestoneTimelineDescription>
          )
        }
      />
    </MilestoneTimelineNode>
  );
}

function IssuerMilestoneTimelinePendingNode({
  node: { name, isLastMilestone },
}: IssuerMilestoneTimelinePendingNodeProps) {
  return (
    <MilestoneTimelineNode
      px={0}
      indicator={
        <MilestoneTimelineIndicator
          color="grey.600"
          line={!isLastMilestone && <MilestoneTimelineLine stroke="grey.600" />}
          icon={
            <MilestoneTimelineIcon variant={MilestoneTimelineIcons.Outline} />
          }
        />
      }
    >
      <IssuerMilestoneTimelineLabel name={name} color="grey.600" />
    </MilestoneTimelineNode>
  );
}

function IssuerMilestoneTimeline({ workflow }: IssuerMilestoneTimelineProps) {
  const { milestoneCompletions } = workflow;

  const milestoneTimeline = useMemo(
    () => mapIssuerMilestoneTimeline(MILESTONE_ORDER, milestoneCompletions),
    [milestoneCompletions],
  );

  return (
    <MilestoneTimeline>
      {milestoneTimeline.map((node) =>
        match(node)
          .with({ status: IssuerMilestoneTimelineStatus.Done }, () => (
            <IssuerMilestoneTimelineDoneNode
              key={nanoid()}
              node={node as MappedMilestoneTimelineDoneNode}
            />
          ))
          .with({ status: IssuerMilestoneTimelineStatus.InProgress }, () => (
            <IssuerMilestoneTimelineInProgressNode
              key={nanoid()}
              node={node as MappedMilestoneTimelineInProgressNode}
            />
          ))
          .with({ status: IssuerMilestoneTimelineStatus.Pending }, () => (
            <IssuerMilestoneTimelinePendingNode
              key={nanoid()}
              node={node as MappedMilestoneTimelinePendingNode}
            />
          ))
          .exhaustive(),
      )}
    </MilestoneTimeline>
  );
}

function TransactionCancelledCallOut() {
  const { t } = useTranslation(`issuers`);
  const [red700] = useColors([`red.700`]);

  return (
    <CallOut variant="red" borderWidth={1} px={6}>
      <Box>
        <WarningCircle color={red700} weight="fill" height="24" width="24" />
      </Box>
      <Text textStyle="heading-md">{t(`transaction_cancelled`)}</Text>
    </CallOut>
  );
}

export function IssuerTransactionStatusCard({
  transaction,
}: IssuerTransactionStatusCardProps) {
  const { t } = useTranslation(`issuers`);

  const { workflow, issuerStage } = transaction;

  return (
    <Card variant="issuer">
      <CardBody>
        <VStack alignItems="flex-start" w="full" gap={6}>
          <Text fontSize={16} fontWeight={500}>
            {t(`transaction_status`)}
          </Text>
          {workflow && <IssuerMilestoneTimeline workflow={workflow} />}
          {issuerStage && issuerStage === IssuerStage.Cancelled && (
            <TransactionCancelledCallOut />
          )}
        </VStack>
      </CardBody>
    </Card>
  );
}
