import { Control, Path, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Text,
} from "@chakra-ui/react";

import { FormRadioTile } from "@/components/react-hook-form";
import { EntityType } from "@/gql";

import { InstitutionInfoFormValues } from "./InstitutionInfoForm";

export const InstitutionEntityTypeField = ({
  name,
  countryName,
  control,
}: {
  countryName: string;
  name: Path<InstitutionInfoFormValues>;
  control: Control<InstitutionInfoFormValues>;
}) => {
  const { t } = useTranslation(`account`);

  const {
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  if (countryName === `UK`) return null;

  return (
    <Flex direction="column" gap={4}>
      <FormLabel m={0} p={0}>
        {t(`what_is_the_firm_fund_structure`, { ns: `translation` })}
      </FormLabel>
      <FormControl isInvalid={!!error?.message}>
        {match(countryName)
          .with(`CA`, () => (
            <FormRadioTile.Group
              p={0}
              size="sm"
              gap={4}
              gridTemplateColumns={{ base: `1fr`, md: `repeat(3, 1fr)` }}
            >
              <FormRadioTile.Tile
                control={control}
                name={name}
                value={EntityType.Corporation}
              >
                <Text>{t(`entities.corporation`)}</Text>
              </FormRadioTile.Tile>
              <FormRadioTile.Tile
                control={control}
                name={name}
                value={EntityType.Partnership}
              >
                <Text>{t(`entities.partnership`)}</Text>
              </FormRadioTile.Tile>
              <FormRadioTile.Tile
                control={control}
                name={name}
                value={EntityType.Other}
              >
                <Text>{t(`entities.other`)}</Text>
              </FormRadioTile.Tile>
              <FormErrorMessage
                as={GridItem}
                colSpan={{ base: 1, md: 3 }}
                m={0}
              >
                {error?.message}
              </FormErrorMessage>
            </FormRadioTile.Group>
          ))
          .otherwise(() => (
            <FormRadioTile.Group
              p={0}
              size="sm"
              gap={4}
              gridTemplateColumns={{ base: `1fr`, md: `repeat(2, 1fr)` }}
            >
              <FormRadioTile.Tile
                control={control}
                name="entityType"
                value={EntityType.Corporation}
              >
                <Text>{t(`entities.corporation`)}</Text>
              </FormRadioTile.Tile>
              <FormRadioTile.Tile
                control={control}
                name="entityType"
                value={EntityType.Llc}
              >
                <Text>{t(`entities.llc`)}</Text>
              </FormRadioTile.Tile>
              <FormRadioTile.Tile
                control={control}
                name="entityType"
                value={EntityType.Partnership}
              >
                <Text>{t(`entities.partnership`)}</Text>
              </FormRadioTile.Tile>
              <FormRadioTile.Tile
                control={control}
                name="entityType"
                value={EntityType.Other}
              >
                <Text>{t(`entities.other`)}</Text>
              </FormRadioTile.Tile>
              <FormErrorMessage
                as={GridItem}
                colSpan={{ base: 1, md: 2 }}
                m={0}
              >
                {error?.message}
              </FormErrorMessage>
            </FormRadioTile.Group>
          ))}
      </FormControl>
    </Flex>
  );
};
