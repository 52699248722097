import { stagger, useAnimate } from "framer-motion";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { StaticImageData } from "next/image";

import { Flex, SimpleGrid, Text, VStack } from "@chakra-ui/react";

import { HiiveButton, NextImage } from "@/components/common";
import { OnboardingContainerV2 } from "@/components/onboarding-v2";
import { useTransitionCurrentStepMutation } from "@/gql";
import { useMutationWithError } from "@/hooks";

import createListingImage from "./create_listing.png";
import explorePricingImage from "./explore_pricing.png";
import sellSharesImage from "./sell_shares.png";

const useImageRowAnimation = () => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      `li`,
      {
        opacity: 1,
        filter: `blur(0px)`,
        transform: `translateY(0px)`,
      },
      {
        duration: 0.3,
        delay: stagger(0.5, { startDelay: 0.2 }),
      },
    );
  }, []);

  return scope;
};

const ImageRow = ({
  title,
  description,
  image,
}: {
  title: string;
  description: string;
  image: StaticImageData;
}) => (
  <SimpleGrid
    as="li"
    gridTemplateColumns={{ base: `1fr`, md: `repeat(2, 1fr)` }}
    alignItems="center"
    gap={12}
    opacity={0}
    filter="blur(1px)"
    transform="translateY(-10px)"
  >
    <NextImage
      priority
      quality={100}
      alt={title}
      height="192px"
      width="335px"
      src={image}
      w="full"
      h={{ base: `unset`, md: `auto` }}
    />
    <VStack align="flex-start" justifyContent="center" spacing={2}>
      <Text textStyle="heading-md">{title}</Text>
      <Text textStyle="text-sm">{description}</Text>
    </VStack>
  </SimpleGrid>
);

const SellerInfoGuidePage = () => {
  const { t } = useTranslation();
  const animationScope = useImageRowAnimation();

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const onNext = async () => {
    await transitionCurrentStepMutation();
  };

  return (
    <OnboardingContainerV2
      canGoBack
      metaTitle={t(`selling_on_hiive_in_three_steps`)}
    >
      <Flex direction="column" maxW="45rem" rowGap={9}>
        <VStack spacing={2} alignItems="flex-start">
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`selling_on_hiive_in_three_steps`)}
          </Text>
          <Text color="grey.900">
            {t(`hiive_platform_aims_for_transparency`)}
          </Text>
        </VStack>

        <Flex
          as="ul"
          direction="column"
          columnGap={12}
          rowGap={9}
          ref={animationScope}
        >
          <ImageRow
            title={t(`explore_pricing`)}
            description={t(`browse_listings_and_bids`)}
            image={explorePricingImage}
          />
          <ImageRow
            title={t(`create_your_listing`)}
            description={t(`finalize_an_asking_price`)}
            image={createListingImage}
          />
          <ImageRow
            title={t(`accept_a_bid_and_sell_shares`)}
            description={t(`interested_buyers_may_bid`)}
            image={sellSharesImage}
          />
        </Flex>
        <Flex justifyContent="flex-end" w="full">
          <HiiveButton
            type="submit"
            isLoading={isTransitioningCurrentStep}
            size="xl"
            w={{ base: `full`, sm: `auto` }}
            maxW="auto"
            variant="rounded-solid-salmon"
            sentryLabel="[SellerInfoGuidePage] Next"
            onClick={onNext}
          >
            {t(`next`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </OnboardingContainerV2>
  );
};

export default SellerInfoGuidePage;
