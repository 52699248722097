import { Building, UserList, ListDashes } from "@phosphor-icons/react";

import { ROUTES } from "@/utils";

import { IssuerNavContentLinkType } from "./IssuerNavContent";

const NAV_ICON_SIZE = 20;

export enum IssuerNavContentType {
  Link = `link`,
  Divider = `divider`,
}

export type IssuerNavContentEntity =
  | ({
      type: IssuerNavContentType.Link;
    } & IssuerNavContentLinkType)
  | { type: IssuerNavContentType.Divider };

export const NAV_CONTENT_ITEMS: IssuerNavContentEntity[] = [
  {
    type: IssuerNavContentType.Link,
    href: ROUTES.ISSUER_TRANSACTIONS,
    icon: <ListDashes size={NAV_ICON_SIZE} />,
    labelKey: `transactions`,
  },
  {
    type: IssuerNavContentType.Link,
    href: ROUTES.ISSUER_PROFILE,
    icon: <Building size={NAV_ICON_SIZE} />,
    labelKey: `company_info`,
    paths: [ROUTES.ISSUER_PROFILE, ROUTES.ISSUER_TEMPLATES],
  },
  { type: IssuerNavContentType.Divider },
  {
    type: IssuerNavContentType.Link,
    href: ROUTES.ISSUER_USERS,
    icon: <UserList size={NAV_ICON_SIZE} />,
    labelKey: `manage_users`,
  },
];
