import { Info } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { Box, Card, CardBody, Flex, Text } from "@chakra-ui/react";

const WireInformationAlert = () => {
  const { t } = useTranslation(`execution`);
  return (
    <Card variant="flat" p={0} w="full" bg="grey.50" borderColor="grey.800">
      <CardBody p={4}>
        <Flex alignContent="start" gap={2}>
          <Box p={1}>
            <Info size={13} weight="fill" />
          </Box>
          <Text textStyle="heading-xs">{t(`the_account_number`)}</Text>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default WireInformationAlert;
