import { DeviceMobileCamera } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { Card, Text, VStack } from "@chakra-ui/react";

import { ActionCard, ActionCardAction } from "@/components/common";
import { EnrollAppMfaSuccessAction } from "@/components/modals";
import { useModal, useCurrentIssuerActor } from "@/hooks";
import { useEnrollAppMfa } from "@/hooks/frontegg";

const AuthenticatorAppEnrollmentCard = () => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();

  const { isHiiveUser } = useCurrentIssuerActor();

  const [enrollAppMfa] = useEnrollAppMfa({
    onSuccess: (data) => {
      onOpenModal(
        modals.enrollAppMfa(
          isHiiveUser,
          data.qrCode,
          EnrollAppMfaSuccessAction.Redirect,
        ),
      )();
    },
    onError: () => {
      onOpenModal(modals.enrollAppMfaError())();
    },
  });

  const action: ActionCardAction = {
    label: t(`add`),
    onClick: enrollAppMfa,
    variant: `rounded-solid-salmon`,
  };

  return (
    <VStack
      spacing={8}
      marginTop={9}
      marginX={4}
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Text textStyle="heading-lg" textColor="grey.900">
        {t(`multi_factor_authentication`)}
      </Text>
      <Card>
        <VStack spacing={6} margin={6} align="left">
          <Text>{t(`must_add_mfa_issuer_portal`)}</Text>
          <ActionCard
            action={action}
            icon={<DeviceMobileCamera size={24} />}
            text={t(`authenticator_app`)}
            subText={t(
              `security_mfa_authenticator_app_action_card_hiive_user_sub_text`,
            )}
          />
        </VStack>
      </Card>
    </VStack>
  );
};

export default AuthenticatorAppEnrollmentCard;
