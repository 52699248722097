/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import isNil from "lodash/isNil";
import * as Yup from "yup";

import { GridItem, ModalBody, Show, SimpleGrid } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveNextButton,
} from "@/components/common";
import {
  ConditionalInput,
  FormListbox,
  FormNumberInput,
  MoneyInput,
  StepPropsV2,
} from "@/components/form";
import { StandingBidAcceptedTransferMethods } from "@/components/postings";
import { StandingBidAllowPartialAcceptTooltip } from "@/components/tooltip";
import {
  CommissionStructure,
  CreateListingModalCompanyFragment,
  ModifyStandingBidModalStandingBidFragment,
  StandingBidAcceptsSharesOptions,
  StandingBidState,
} from "@/gql";
import { useIsDesktop, useModal, useStepValidator } from "@/hooks";
import { Nullable, constants, acceptsSharesToString } from "@/utils";

import { ModifyStandingBidModalStepFormContext } from "./ModifyStandingBidModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { ModifyStandingBidModalFormValues } from "./types";

export const createValidationSchema = (
  standingBid: ModifyStandingBidModalStandingBidFragment,
  initialValues: Nullable<ModifyStandingBidModalFormValues>,
) =>
  Yup.object().shape({
    numShares: Yup.number().nullable().required(`Required`),
    pricePerShare: Yup.number()
      .nullable()
      .moreThan(0, `Must be a positive number.`)
      .required(`Required`)
      .test({
        name: `checkBidMeetsMinValue`,
        params: {},
        message: `Bid must be a minimum value of ${constants.min_standing_bid_lot.text}. Try adjusting the minimum lot size and/or price per share.`,
        test: function (pricePerShare: number) {
          const hasLotChanges =
            pricePerShare !== initialValues.pricePerShare ||
            this.parent.numShares !== initialValues.numShares;

          return (
            !hasLotChanges ||
            pricePerShare * this.parent.numShares >=
              constants.min_standing_bid_lot.number
          );
        },
      }),
    acceptsShares: Yup.string().nullable().required(`Required`),
    allowPartialAccept: Yup.boolean().required(`Required`),
    minPartialAcceptNumShares: Yup.number()
      .min(1, `Must be at least 1`)
      .nullable()
      .test({
        name: `checkRequired`,
        message: `Required when partial acceptance is allowed`,
        test: function (minPartialAcceptNumShares: number | null) {
          return (
            !this.parent.allowPartialAccept || !isNil(minPartialAcceptNumShares)
          );
        },
      })
      .test({
        name: `checkPartialAcceptMeetsMinValue`,
        message: `Partial accept amount for a Bid must have a minimum value of ${constants.min_standing_bid_lot.text}`,
        test: function (minPartialAcceptNumShares: number) {
          return (
            !this.parent.allowPartialAccept ||
            this.parent.pricePerShare * minPartialAcceptNumShares >=
              constants.min_standing_bid_lot.number
          );
        },
      })
      .test({
        name: `checkLessThanNumShares`,
        message: `Must be less than or equal to the total number of shares`,
        test: function (minPartialAcceptNumShares: number) {
          return (
            !this.parent.allowPartialAccept ||
            standingBid.state === StandingBidState.ConditionallyCompleted ||
            minPartialAcceptNumShares <= this.parent.numShares
          );
        },
      }),
    acceptsTransferMethodsV2: Yup.array()
      .of(Yup.string())
      .min(1, `Must select at least one`)
      .nullable()
      .required(`Required`),
  });

interface BidDetailsProps
  extends StepPropsV2<StepKeys, Nullable<ModifyStandingBidModalFormValues>> {
  readonly company?: CreateListingModalCompanyFragment;
  readonly commissionStructure?: CommissionStructure;
  readonly standingBid: ModifyStandingBidModalStandingBidFragment;
}

const BidDetails = ({
  stepRouter,
  values,
  standingBid,
  initialValues,
}: BidDetailsProps) => {
  const { closeModal } = useModal();
  const { stepControls } = stepRouter;

  const { allowPartialAccept } = values;

  const isDesktop = useIsDesktop();

  const onSuccess = () => stepControls.nextStep();

  useStepValidator({
    Context: ModifyStandingBidModalStepFormContext,
    stepKey: stepKeys.modifyStandingBidBidDetails,
    validator: {
      validationSchema: createValidationSchema(standingBid, initialValues),
      onSuccess,
    },
    values,
  });

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormNumberInput name="numShares" label="Number of shares" />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <MoneyInput name="pricePerShare" label="Price per share" />
          </GridItem>
          <GridItem colSpan={2}>
            <FormListbox
              name="acceptsShares"
              label="Share type"
              itemToString={(item: StandingBidAcceptsSharesOptions) =>
                acceptsSharesToString(item)
              }
              getItemKey={(item) => item}
              items={[
                StandingBidAcceptsSharesOptions.Both,
                StandingBidAcceptsSharesOptions.Common,
                StandingBidAcceptsSharesOptions.Pref,
              ]}
            />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <ConditionalInput
              name="allowPartialAccept"
              label="Allow partial acceptance?"
              tooltipContent={<StandingBidAllowPartialAcceptTooltip />}
              horizontal={isDesktop}
            />
          </GridItem>
          {allowPartialAccept && (
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormNumberInput
                name="minPartialAcceptNumShares"
                label="Minimum number of shares"
              />
            </GridItem>
          )}

          <GridItem colSpan={2}>
            <StandingBidAcceptedTransferMethods name="acceptsTransferMethodsV2" />
          </GridItem>
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[ModifyStandingBid/BidDetails/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveNextButton
          sentryLabel="[ModifyStandingBid/BidDetails/Submit]"
          type="submit"
        />
      </HiiveModalFooter>
    </>
  );
};

export default BidDetails;
