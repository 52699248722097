import { DotsThreeVertical } from "@phosphor-icons/react";

import {
  Box,
  Card,
  CardBody,
  HStack,
  Menu,
  MenuButton,
  VStack,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

function IssuerManageUsersCardSkeleton() {
  return (
    <Card w="full" boxShadow="none" borderColor="grey.200" borderRadius="none">
      <CardBody>
        <VStack alignItems="flex-start" gap={2}>
          <VStack alignItems="flex-start" gap={1} w="full">
            <HStack
              alignItems="flex-start"
              justifyContent="center"
              pb={2}
              w="full"
            >
              <Skeleton h="22px" w="50px" />
              <Box flexGrow={1} />
              <Menu>
                <MenuButton>
                  <DotsThreeVertical size={24} />
                </MenuButton>
              </Menu>
            </HStack>
            <HStack w="full">
              <Skeleton h="20px" w="120px" />
            </HStack>
            <HStack w="full">
              <Skeleton h="20px" w="50px" />
              <Box flexGrow={1} />
              <Skeleton h="20px" w="50px" />
            </HStack>
          </VStack>
          <HStack w="full">
            <Skeleton h="20px" w="120px" />
            <Box flexGrow={1} />
            <Skeleton h="16px" w="120px" />
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
}

const CARD_SKELETON_NUM = 13;

export function IssuerManageUsersCardListSkeleton() {
  return (
    <VStack spacing={2} w="full" mt={2}>
      {Array.from({ length: CARD_SKELETON_NUM }).map((_, index) => (
        <IssuerManageUsersCardSkeleton
          key={`${new Date().toISOString()}-${index.toString()}`}
        />
      ))}
    </VStack>
  );
}
