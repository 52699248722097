import { Envelope, Phone } from "@phosphor-icons/react";
import parsePhoneNumber from "libphonenumber-js";
import { useTranslation } from "react-i18next";

import { Card, CardBody, CardHeader, Text, VStack } from "@chakra-ui/react";

import { ActionCard } from "@/components/common";
import { useCurrentActor, useModal } from "@/hooks";

export const ContactCard = () => {
  const { t } = useTranslation();
  const { email, phoneNumber } = useCurrentActor();

  const { onOpenModal, modals } = useModal();

  const parsedPhoneNumber = phoneNumber
    ? parsePhoneNumber(phoneNumber)?.formatInternational()
    : ``;

  return (
    <Card w="full" flex="1">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`contact_info`)}</Text>
      </CardHeader>
      <CardBody p={{ base: 6, lg: 10 }} data-dd-privacy="mask">
        <VStack spacing={6}>
          <ActionCard
            action={{
              label: t(`update`),
              onClick: onOpenModal(modals.changeEmail(email)),
            }}
            icon={<Envelope size={24} />}
            subText={email}
            text={t(`email`)}
          />
          <ActionCard
            action={{
              label: t(`update`),
              onClick: onOpenModal(modals.changePhone()),
            }}
            icon={<Phone size={24} />}
            subText={parsedPhoneNumber as string}
            text={t(`phone`)}
          />
        </VStack>
      </CardBody>
    </Card>
  );
};
