import { useRouter } from "next/router";

import { useHoldingSwitcher } from "@/components/nav";
import { InvestorType } from "@/gql";
import { useCurrentActor } from "@/hooks";

const UnaccreditedSellerCreateListingPage = () => {
  const { holdingCompany } = useHoldingSwitcher();
  const actor = useCurrentActor();
  const router = useRouter();

  const id = holdingCompany?.companyId;

  if (actor.investorType !== InvestorType.UnaccreditedSeller) {
    router.replace(`/page-not-found`);
    return null;
  }
  if (!holdingCompany || !id) return null;

  if (id) {
    router.replace(`/create-listing/${id}`);
    return null;
  }

  return null;
};

export default UnaccreditedSellerCreateListingPage;
