import { useTranslation } from "react-i18next";

import { CardBody, HStack, VStack } from "@chakra-ui/react";

import {
  ActivityCardListingNumShareAndPriceDetails,
  ActivityCardListingSolicitedCardBody,
  ActivityCardPropertyPill,
} from "@/components/common";
import {
  CompanyListingCardBodyCompanyFragment,
  CompanyListingCardBodyListingFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  getShowListingSolicitedState,
  shareTypeToString,
  transferMethodToString,
} from "@/utils";

const TransferMethod = ({
  listing,
}: {
  readonly listing: CompanyListingCardBodyListingFragment;
}) => {
  const { transferMethodV2 } = listing;
  const { t } = useTranslation();
  return (
    <ActivityCardPropertyPill
      title={t`transfer_type`}
      value={transferMethodToString(transferMethodV2)}
    />
  );
};

const ShareType = ({
  listing,
}: {
  readonly listing: CompanyListingCardBodyListingFragment;
}) => {
  const { shareTypeV2 } = listing;
  const { t } = useTranslation();

  return (
    <ActivityCardPropertyPill
      title={t`share_type`}
      value={shareTypeToString(shareTypeV2)}
    />
  );
};

const ListingCardBodyContent = ({
  listing,
  company,
}: {
  readonly listing: CompanyListingCardBodyListingFragment;
  readonly company: CompanyListingCardBodyCompanyFragment;
}) => {
  const actor = useCurrentActor();
  const showListingSolicited = getShowListingSolicitedState(listing, actor);

  if (showListingSolicited)
    return (
      <ActivityCardListingSolicitedCardBody
        spacing={0}
        alignItems="flex-start"
        textAlign="left"
        company={company}
      />
    );

  return (
    <>
      <ActivityCardListingNumShareAndPriceDetails listing={listing} />
      <VStack align="flex-end" spacing={1.5}>
        <TransferMethod listing={listing} />
        <ShareType listing={listing} />
      </VStack>
    </>
  );
};

const CompanyListingCardBody = ({
  listing,
  company,
}: {
  readonly listing: CompanyListingCardBodyListingFragment;
  readonly company: CompanyListingCardBodyCompanyFragment;
}) => (
  <CardBody pt={2} pb={2}>
    <HStack justify="space-between">
      <ListingCardBodyContent listing={listing} company={company} />
    </HStack>
  </CardBody>
);

export default CompanyListingCardBody;
