import { useTranslation } from "react-i18next";

import { Badge, CardHeader, HStack, Text } from "@chakra-ui/react";

type ActiveTransactionsCardHeaderProps = {
  transactionsCount?: number;
};

const ActiveTransactionsCardHeader = ({
  transactionsCount = 0,
}: ActiveTransactionsCardHeaderProps) => {
  const { t } = useTranslation(`dashboard`);

  return (
    <CardHeader>
      <HStack gap={3} alignItems="center">
        <Text color="grey.900" fontWeight={500} textStyle="heading-sm">
          {t(`active_transactions`)}
        </Text>
        <Badge bg="grey.50" borderWidth="1px" borderColor="grey.100">
          {transactionsCount}
        </Badge>
      </HStack>
    </CardHeader>
  );
};

export default ActiveTransactionsCardHeader;
