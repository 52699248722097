import { CaretLeft } from "@phosphor-icons/react";
import { t } from "i18next";
import { useState } from "react";

import { useRouter } from "next/router";

import { Link, HStack, Text, StackProps } from "@chakra-ui/react";

interface BackButtonProps extends StackProps {
  readonly label: string;
  readonly url?: string;
}

export default function BackButton({
  label = t(`back_button_text`),
  url,
  ...restProps
}: BackButtonProps) {
  const router = useRouter();

  const [isRoutingToPreviousRoute, setIsRoutingToPreviousRoute] =
    useState(false);

  const handleBackClick = async () => {
    if (isRoutingToPreviousRoute) return;

    setIsRoutingToPreviousRoute(true);

    if (url) {
      await router.replace(url);
      return;
    }

    await router.back();
  };

  return (
    <HStack
      as={Link}
      alignItems="center"
      onClick={handleBackClick}
      cursor={isRoutingToPreviousRoute ? `not-allowed` : `pointer`}
      pointerEvents={isRoutingToPreviousRoute ? `none` : `auto`}
      {...restProps}
    >
      <CaretLeft size={20} />
      <Text textStyle="colfax-16-medium">{label}</Text>
    </HStack>
  );
}
