import { useContext } from "react";

import { IssuerTransactionPageContent } from "./IssuerTransactionPageContent";
import { IssuerTransactionPageMainPanelHeader } from "./IssuerTransactionPageMainPanelHeader";
import { IssuerTransactionDetailPageSkeleton } from "./IssuerTransactionPageSkeleton";
import {
  IssuerTransactionContext,
  IssuerTransactionContextTransaction,
} from "./IssuerTransactionProvider";
import { IssuerTransactionTermsCard } from "./IssuerTransactionTermsCard";
import { IssuerTransactionTransactingParties } from "./IssuerTransactionTransactingParties";

type IssuerTransactionDetailPageSectionsProps = {
  transaction: NonNullable<IssuerTransactionContextTransaction>;
};

function IssuerTransactionDetailPageSections({
  transaction,
}: IssuerTransactionDetailPageSectionsProps) {
  return (
    <>
      <IssuerTransactionPageMainPanelHeader transaction={transaction} />
      <IssuerTransactionTransactingParties transaction={transaction} />
      <IssuerTransactionTermsCard transaction={transaction} />
    </>
  );
}

function IssuerTransactionDetailPage() {
  const { transaction } = useContext(IssuerTransactionContext);

  return (
    <IssuerTransactionPageContent>
      {transaction ? (
        <IssuerTransactionDetailPageSections transaction={transaction} />
      ) : (
        <IssuerTransactionDetailPageSkeleton />
      )}
    </IssuerTransactionPageContent>
  );
}

export default IssuerTransactionDetailPage;
