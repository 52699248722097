import { match } from "ts-pattern";

import { CardBody, HStack, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import {
  AccreditationQuestionGroup,
  InvestorStatus,
  useCurrentAccreditationQuestionGroupQuery,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import SomethingWentWrong from "@/pages/something-went-wrong";
import { SupportedInstitutionCountryCodes as SupportedCountryCodes } from "@/utils/accreditation";

import {
  USInstitutionAccreditationBody,
  CAInstitutionAccreditationBody,
} from ".";

export type InstitutionAccreditationBodyProps = {
  readonly questionGroup?: Omit<AccreditationQuestionGroup, `version`> | null;
  readonly onModify: () => void;
};

const InstitutionAccreditationCardBodySkeleton = () => (
  <VStack w="full" alignItems="flex-start" gap={6}>
    <HStack w="90%" justifyContent="space-between">
      <Skeleton h={30} w={550} />
      <Skeleton h={35} w={150} />
    </HStack>
    <Skeleton h={55} w="90%" />
  </VStack>
);

const USInstitutionAccreditationCardBodySkeleton = () => (
  <VStack w="full" alignItems="flex-start" gap={6}>
    <HStack w="90%" justifyContent="space-between">
      <Skeleton h={30} w={550} />
      <Skeleton h={35} w={150} />
    </HStack>
    <Skeleton h={250} w="70%" />
    <Skeleton h={55} w="90%" />
  </VStack>
);

const InstitutionAccreditationCardBody = ({
  onModify,
}: {
  readonly onModify: () => void;
}) => {
  const actor = useCurrentActor();
  const institutionCountry = actor.institution?.country;
  const isUnsupportedCountry = !SupportedCountryCodes.includes(
    institutionCountry?.name || ``,
  );
  const { data, loading } = useCurrentAccreditationQuestionGroupQuery({
    variables: {
      investorType: InvestorStatus.Institutional,
      countryId: institutionCountry?.id || ``,
    },
    skip: isUnsupportedCountry,
  });

  if (loading || !data)
    return (
      <CardBody>
        {match(actor.institution?.country.name)
          .with(`US`, () => <USInstitutionAccreditationCardBodySkeleton />)
          .otherwise(() => (
            <InstitutionAccreditationCardBodySkeleton />
          ))}
      </CardBody>
    );

  if (!data?.currentAccreditationQuestionGroup) return <SomethingWentWrong />;

  return match(actor.institution?.country.name)
    .with(`US`, () => (
      <USInstitutionAccreditationBody
        onModify={onModify}
        questionGroup={data.currentAccreditationQuestionGroup}
      />
    ))
    .with(`CA`, () => <CAInstitutionAccreditationBody onModify={onModify} />)
    .otherwise(() => null);
};

export default InstitutionAccreditationCardBody;
