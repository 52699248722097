import { match } from "ts-pattern";

import { SlideAnimation } from "@/components/onboarding-v2";
import {
  AccreditationQuestionGroup,
  InvestorStatus,
  useCurrentAccreditationQuestionGroupQuery,
  useTransitionCurrentStepMutation,
} from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";
import SomethingWentWrong from "@/pages/something-went-wrong";
import { SupportedInstitutionCountryCodes as SupportedCountryCodes } from "@/utils/accreditation";

import {
  CADInstitutionAccreditationForm,
  CADInstitutionAccreditationFormSkeleton,
} from "./CADInstitutionAccreditationForm";
import {
  CHInstitutionAccreditationForm,
  CHInstitutionAccreditationFormSkeleton,
} from "./CHInstitutionAccreditationForm";
import {
  INInstitutionAccreditationForm,
  INInstitutionAccreditationFormSkeleton,
} from "./INInstitutionAccreditationForm";
import {
  OtherInstitutionAccreditationForm,
  OtherInstitutionAccreditationFormSkeleton,
} from "./OtherInstitutionAccreditationForm";
import {
  UKInstitutionAccreditationForm,
  UKInstitutionAccreditationFormSkeleton,
} from "./UKInstitutionAccreditationForm";
import {
  USInstitutionAccreditationForm,
  USInstitutionAccreditationFormSkeleton,
} from "./USInstitutionAccreditationForm";

export type InstitutionAccreditationFormProps = {
  readonly questionGroup?: Omit<AccreditationQuestionGroup, `version`>;
  readonly onSuccess: () => void;
};

const InstitutionAccreditationFormByCountry = ({
  countryName,
  questionGroup,
  onSuccess,
}: {
  readonly countryName: string;
  readonly questionGroup?: Omit<AccreditationQuestionGroup, `version`>;
  readonly onSuccess: () => void;
}) =>
  match(countryName)
    .with(`US`, () => (
      <USInstitutionAccreditationForm
        questionGroup={questionGroup}
        onSuccess={onSuccess}
      />
    ))
    .with(`CA`, () => (
      <CADInstitutionAccreditationForm
        questionGroup={questionGroup}
        onSuccess={onSuccess}
      />
    ))
    .with(`CH`, () => (
      <CHInstitutionAccreditationForm
        questionGroup={questionGroup}
        onSuccess={onSuccess}
      />
    ))
    .with(`UK`, () => (
      <UKInstitutionAccreditationForm
        questionGroup={questionGroup}
        onSuccess={onSuccess}
      />
    ))
    .with(`IN`, () => (
      <INInstitutionAccreditationForm
        questionGroup={questionGroup}
        onSuccess={onSuccess}
      />
    ))
    .otherwise(() => (
      <OtherInstitutionAccreditationForm onSuccess={onSuccess} />
    ));

const InstitutionAccreditationFormSkeletonByCountry = ({
  countryName,
}: {
  readonly countryName: string;
}) =>
  match(countryName)
    .with(`US`, () => <USInstitutionAccreditationFormSkeleton />)
    .with(`CA`, () => <CADInstitutionAccreditationFormSkeleton />)
    .with(`CH`, () => <CHInstitutionAccreditationFormSkeleton />)
    .with(`UK`, () => <UKInstitutionAccreditationFormSkeleton />)
    .with(`IN`, () => <INInstitutionAccreditationFormSkeleton />)
    .otherwise(() => <OtherInstitutionAccreditationFormSkeleton />);

export const InstitutionAccreditationForm = () => {
  const actor = useCurrentActor();
  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const institutionCountry = actor.institution?.country;
  const isUnsupportedCountry = !SupportedCountryCodes.includes(
    institutionCountry?.name || ``,
  );
  const { data, loading } = useCurrentAccreditationQuestionGroupQuery({
    variables: {
      investorType: InvestorStatus.Institutional,
      countryId: institutionCountry?.id || ``,
    },
    skip: isUnsupportedCountry,
  });

  const onSuccess = async () => {
    await transitionCurrentStepMutation();
  };

  if (loading || isTransitioningCurrentStep)
    return (
      <InstitutionAccreditationFormSkeletonByCountry
        countryName={institutionCountry?.name || ``}
      />
    );

  if (isUnsupportedCountry) {
    return <OtherInstitutionAccreditationForm onSuccess={onSuccess} />;
  }

  if (!data?.currentAccreditationQuestionGroup) return <SomethingWentWrong />;

  return (
    <form>
      <SlideAnimation>
        <InstitutionAccreditationFormByCountry
          countryName={institutionCountry?.name || ``}
          questionGroup={data.currentAccreditationQuestionGroup}
          onSuccess={onSuccess}
        />
      </SlideAnimation>
    </form>
  );
};
