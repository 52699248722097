import { useTranslation } from "react-i18next";

import { Box, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import { StepPropsV2 } from "@/components/form";
import { AcceptStandingBidModalStandingBidFragment } from "@/gql";
import { useModal } from "@/hooks";
import { useOtherTransferMethod } from "@/hooks/featureFlags";
import {
  formatPricePerShare,
  formatShares,
  getSecuritySpecialistContact,
  getShortDocumentTitleByTransferMethod,
  getTransferMethodByStandingBidAcceptsTransferMethodsOptions,
} from "@/utils";

import { StepKeys } from "./steps";
import { AcceptStandingBidSequenceModalFormValues } from "./types";

interface AcceptStandingBidModalSuccessProps
  extends StepPropsV2<StepKeys, AcceptStandingBidSequenceModalFormValues> {
  readonly standingBid: AcceptStandingBidModalStandingBidFragment;
}

const AcceptStandingBidSuccessModal = ({
  standingBid,
  values,
}: AcceptStandingBidModalSuccessProps) => {
  const { t } = useTranslation();
  const transferMethod =
    getTransferMethodByStandingBidAcceptsTransferMethodsOptions(
      values.transferMethodV2,
    );

  const shortDocumentTitle =
    getShortDocumentTitleByTransferMethod(transferMethod);

  const securitySpecialistEmail = getSecuritySpecialistContact(
    standingBid.company,
  );
  const useOtherMethod = useOtherTransferMethod();
  const numShares = formatShares(values.numShares);
  const pricePerShare = formatPricePerShare(standingBid.pricePerShare);

  const { closeModal } = useModal();

  return (
    <>
      <HiiveModalHeader>Standing bid accepted!</HiiveModalHeader>
      <ModalBody>
        {useOtherMethod ? (
          <VStack alignItems="flex-start">
            <Text textStyle="heading-xl" mb={4}>
              {`${standingBid.displayId}: ${numShares} shares @ ${pricePerShare}/sh`}
            </Text>
            <Text mb={4}>{t(`bid_accepted_congrats`)}</Text>
            <VStack mb={4} spacing={6} alignItems="start">
              <Text>
                {t(`for_support`)}
                {` `}
                <SecuritySpecialistLink company={standingBid.company} />.
              </Text>
            </VStack>
          </VStack>
        ) : (
          <VStack alignItems="flex-start">
            <Text textStyle="heading-xl">
              {`You accepted ${standingBid.company.name} Standing Bid ${
                standingBid.shortId
              } for ${
                !!values.numShares && formatShares(values.numShares)
              } shares at ${formatPricePerShare(values.pricePerShare * 100)}/sh!`}
            </Text>
            <Text>
              The next step is for you and the buyer to execute the{` `}
              {shortDocumentTitle} for this transaction. You may message the
              bidder directly with any questions or comments.
            </Text>
            <Box>
              <Text as="span">You may also reach out anytime to the{` `}</Text>
              <MailtoLink
                fontWeight="medium"
                email={securitySpecialistEmail}
                subject={`Contact ${standingBid.company.name} Security Specialist`}
              >
                {`${standingBid.company.name} Security Specialist`}
              </MailtoLink>
              <Text as="span">{` `}for assistance on your transaction.</Text>
            </Box>
          </VStack>
        )}
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          sentryLabel="[AcceptStandingBidSuccess/Submit]"
          type="button"
          submitText="Done"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default AcceptStandingBidSuccessModal;
