import { useFlows } from "@frigade/react";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import useLocalStorage from "use-local-storage";

import { useRouter } from "next/router";

import { Fade, GridItem, SimpleGrid, VStack } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { useFeeDiscountAlert } from "@/components/companies";
import {
  ContentWrapper,
  HeroBannerSkeleton,
  PreListingHeroBannerV2,
} from "@/components/dashboard";
import {
  UnaccreditedSellerMultipleHoldingsFlow,
  UnaccreditedSellerWelcomeFlow,
  LegacySellerToUnaccreditedSellerAnnouncementFlow,
} from "@/components/frigade";
import { withCurrentActor } from "@/components/hoc";
import { FlowKind } from "@/components/providers";
import {
  DefaultUnaccreditedSellerDashboardPageMyCompanyFragment,
  UserWithInstitutionFragment,
  useUnaccreditedSellerDashboardByIdPageQuery,
} from "@/gql";
import { useFlowLauncher } from "@/hooks";
import { RootState } from "@/state";
import { getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2 } from "@/utils";

import { CompanyOverviewCard } from "./CompanyOverviewCard";
import { GettingStartedWithYourSharesBanner } from "./GettingStartedWithYourSharesBanner";
import { ListSharesSellNowBlockV2 } from "./ListSharesSellNowBlock";
import { VerifyYourIdentityCard } from "./VerifyYourIdentityCard";

const UnaccreditedSellerDashboardPageContent = withCurrentActor(
  ({
    company,
    actor,
  }: {
    readonly company: DefaultUnaccreditedSellerDashboardPageMyCompanyFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const hasCompletedPersona = useSelector(
      (state: RootState) => state.persona.isComplete,
    );

    const [showGettingStartedBanner, setShowGettingStartedBanner] =
      useLocalStorage(`show-getting-started-banner`, true);

    const { feeDiscountAlertType } = useFeeDiscountAlert({
      company,
    });

    const { identityVerified } = actor;
    const showVerificationCard = !identityVerified && !hasCompletedPersona;

    const contentWrapperTransformValues = !!feeDiscountAlertType
      ? { base: `translateY(-0.5rem)`, lg: `translateY(-8rem)` }
      : { base: `translateY(-1.3rem)`, lg: `translateY(-12.5rem)` };

    const { getFlowStatus } = useFlows();
    const { getFlowID } = useFlowLauncher();

    const welcomeFlowId = getFlowID(FlowKind.U16RWelcome);
    const welcomeFlowStatus = getFlowStatus(welcomeFlowId);
    const showMultipleHoldingsFlow = [
      `COMPLETED_FLOW`,
      `SKIPPED_FLOW`,
    ].includes(welcomeFlowStatus);

    return (
      <>
        {showMultipleHoldingsFlow && (
          <UnaccreditedSellerMultipleHoldingsFlow
            flowKind={FlowKind.U16RMultipleHoldings}
          />
        )}
        <LegacySellerToUnaccreditedSellerAnnouncementFlow
          flowKind={FlowKind.LegacySellerToU16RAnnouncement}
        />
        <UnaccreditedSellerWelcomeFlow flowKind={FlowKind.U16RWelcome} />
        <PreListingHeroBannerV2 id={company.id} />
        <ContentWrapper transform={contentWrapperTransformValues}>
          <SimpleGrid columns={2} gap={4}>
            {showVerificationCard && (
              <GridItem colSpan={2}>
                <Fade in>
                  <VerifyYourIdentityCard />
                </Fade>
              </GridItem>
            )}
            <AnimatePresence>
              {showGettingStartedBanner && (
                <GridItem
                  as={motion.div}
                  colSpan={2}
                  initial={{ translateY: -10 }}
                  animate={{ translateY: 0 }}
                  exit={{
                    opacity: 0,
                    height: 0,
                    transition: { duration: 0.25, ease: `easeInOut` },
                  }}
                >
                  <GettingStartedWithYourSharesBanner
                    company={company}
                    onClose={() => setShowGettingStartedBanner(false)}
                  />
                </GridItem>
              )}
            </AnimatePresence>
            {company.tradeable && <ListSharesSellNowBlockV2 id={company.id} />}
            <GridItem colSpan={2}>
              <Fade in>
                <CompanyOverviewCard company={company} />
              </Fade>
            </GridItem>
          </SimpleGrid>
        </ContentWrapper>
      </>
    );
  },
);

const DefaultUnaccreditedSellerDashboardPageSkeleton = () => (
  <VStack w="full" spacing={3} bg={{ base: `transparent`, lg: `white` }}>
    <HeroBannerSkeleton />
  </VStack>
);

const DefaultUnaccreditedSellerDashboardPageV2 = ({ id }: { id: string }) => {
  const router = useRouter();

  const query = useUnaccreditedSellerDashboardByIdPageQuery({
    variables: { id },
    fetchPolicy: `cache-and-network`,
  });

  return (
    <WithQuery
      query={query}
      fallback={<DefaultUnaccreditedSellerDashboardPageSkeleton />}
    >
      {({ data: { companyById: company } }) => {
        const { activity } = company;

        if (activity.myListings.length > 0) {
          router.replace(`/dashboard/${id}/active-bids`);
          return null;
        }

        if (
          getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2({
            activity,
          })
        ) {
          router.replace(`/dashboard/${id}/standing-bids`);
          return null;
        }

        return <UnaccreditedSellerDashboardPageContent company={company} />;
      }}
    </WithQuery>
  );
};

export default DefaultUnaccreditedSellerDashboardPageV2;
