import { useDisclosure } from "@chakra-ui/react";

import {
  DesktopNav,
  NAV_CONTENT_ITEMS,
  IssuerDesktopNavContent,
} from "@/components/nav";

import { IssuerDesktopNavFooter } from "./IssuerDesktopNavFooter";
import { IssuerDesktopNavHeader } from "./IssuerDesktopNavHeader";

export function IssuerDesktopNav() {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    <DesktopNav isOpen={isOpen}>
      <IssuerDesktopNavHeader isOpen={isOpen} onToggle={onToggle} />
      <IssuerDesktopNavContent content={NAV_CONTENT_ITEMS} isOpen={isOpen} />
      <IssuerDesktopNavFooter isOpen={isOpen} />
    </DesktopNav>
  );
}
