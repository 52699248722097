import numeral from "numeral";
import { match } from "ts-pattern";

import {
  ShareTypeV2,
  StandingBidAcceptsSharesOptions,
  StandingBidAcceptsTransferMethodsOptionsV2,
  TransferMethodV2,
  UnaccreditedSellerTransferMethodInput,
  ValidTransferMethodV2,
} from "@/gql";

export const transferMethodToString = (
  transferMethod: TransferMethodV2 | ValidTransferMethodV2,
) =>
  match(transferMethod)
    .with(TransferMethodV2.Direct, () => `Direct`)
    .with(TransferMethodV2.ForwardContract, () => `Forward`)
    .with(TransferMethodV2.SpvLister, () => `SPV`)
    .with(TransferMethodV2.SpvThirdparty, () => `SPV`)
    .with(TransferMethodV2.Fund, () => `Fund`)
    .with(TransferMethodV2.HiiveFund, () => `Fund`)
    .with(TransferMethodV2.Unknown, () => `TBD`)
    .with(TransferMethodV2.Other, () => `Other`)
    .otherwise(() => {
      throw new Error(
        `Unrecognized transfer method in transferMethodToString: ${transferMethod}`,
      );
    });

export const transferMethodToLongString = (transferMethod: TransferMethodV2) =>
  match(transferMethod)
    .with(TransferMethodV2.Direct, () => `Direct`)
    .with(TransferMethodV2.ForwardContract, () => `With a Forward Contract`)
    .with(TransferMethodV2.SpvLister, () => `SPV Managed by Me`)
    .with(TransferMethodV2.SpvThirdparty, () => `SPV Managed by Third Party`)
    .with(TransferMethodV2.Fund, () => `Via a Fund`)
    .with(TransferMethodV2.HiiveFund, () => `Via Hiive Fund`)
    .with(TransferMethodV2.Unknown, () => `To Be Determined`)
    .with(TransferMethodV2.Other, () => `Other`)
    .otherwise(() => {
      throw new Error(
        `Unrecognized transfer method in transferMethodToLongString: ${transferMethod}`,
      );
    });

export const standingBidAcceptsTransferMethodOptionsToString = (
  transferMethod: StandingBidAcceptsTransferMethodsOptionsV2,
) =>
  match(transferMethod)
    .with(StandingBidAcceptsTransferMethodsOptionsV2.Direct, () => `Direct`)
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.ForwardContract,
      () => `Forward`,
    )
    .with(StandingBidAcceptsTransferMethodsOptionsV2.SpvLister, () => `SPV`)
    .with(StandingBidAcceptsTransferMethodsOptionsV2.SpvThirdparty, () => `SPV`)
    .with(StandingBidAcceptsTransferMethodsOptionsV2.Fund, () => `Fund`)
    .with(StandingBidAcceptsTransferMethodsOptionsV2.Other, () => `Other`)
    .exhaustive();

export const standingBidAcceptsTransferMethodOptionsToLongString = (
  transferMethod:
    | StandingBidAcceptsTransferMethodsOptionsV2
    | UnaccreditedSellerTransferMethodInput,
) =>
  match(transferMethod)
    .with(StandingBidAcceptsTransferMethodsOptionsV2.Direct, () => `Direct`)
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.ForwardContract,
      () => `With a Forward Contract`,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.SpvLister,
      () => `SPV Managed by Me`,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptionsV2.SpvThirdparty,
      () => `SPV Managed by Third Party`,
    )
    .with(StandingBidAcceptsTransferMethodsOptionsV2.Fund, () => `Fund`)
    .with(StandingBidAcceptsTransferMethodsOptionsV2.Other, () => `Other`)
    .otherwise(() => {
      throw new Error(
        `Unrecognized transfer method in standingBidAcceptsTransferMethodOptionsToLongString: ${transferMethod}`,
      );
    });

export const shareTypeToString = (shareType: ShareTypeV2) =>
  ({
    [ShareTypeV2.Common]: `Common`,
    [ShareTypeV2.Pref]: `Preferred`,
    [ShareTypeV2.Mix]: `Mix`,
    [ShareTypeV2.RestrictedStockUnits]: `Restricted Stock Units`,
  })[shareType];

export const acceptsSharesToString = (
  acceptsShares: StandingBidAcceptsSharesOptions,
): string =>
  match(acceptsShares)
    .with(StandingBidAcceptsSharesOptions.Both, () => `Any`)
    .with(StandingBidAcceptsSharesOptions.Common, () => `Common`)
    .with(StandingBidAcceptsSharesOptions.Pref, () => `Preferred`)
    .exhaustive();

export const acceptsTransferMethodsToString = (
  acceptsTransferMethods: readonly StandingBidAcceptsTransferMethodsOptionsV2[],
) =>
  [
    ...new Set(
      acceptsTransferMethods.map(
        (transferMethod) =>
          ({
            [StandingBidAcceptsTransferMethodsOptionsV2.Direct]: `Direct`,
            [StandingBidAcceptsTransferMethodsOptionsV2.ForwardContract]: `Forward`,
            [StandingBidAcceptsTransferMethodsOptionsV2.SpvLister]: `SPV`,
            [StandingBidAcceptsTransferMethodsOptionsV2.SpvThirdparty]: `SPV`,
            [StandingBidAcceptsTransferMethodsOptionsV2.Fund]: `Fund`,
            [StandingBidAcceptsTransferMethodsOptionsV2.Other]: `Other`,
          })[transferMethod],
      ),
    ),
  ]
    .sort()
    .join(`, `);

export const lot = (
  numOfShares: number,
  pricePerShare: number,
  format?: string,
) => {
  const inputString = format || `$0,0[.]00`;
  return `${numeral((pricePerShare / 100) * numOfShares).format(inputString)}`;
};
