import { WarningCircle } from "@phosphor-icons/react";
import currency from "currency.js";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Spacer,
  Box,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ActivityCardBadge,
  ActivityCardCompany,
  ActivityCardDisplayId,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  SensitiveText,
  TileHeader,
  SharePrice,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  DocumentTransactionCardTransactionFragment,
  DocumentTransactionCardTransactionModificationFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useColors } from "@/hooks";
import {
  appendSellerCompanyIdToUrl,
  getIsUnaccreditedSeller,
  makeUrl,
} from "@/utils";

const PendingModificationCardBody = ({
  pendingModification,
  currentNumShares,
  currentPricePerShare,
}: {
  readonly pendingModification: DocumentTransactionCardTransactionModificationFragment;
  readonly currentNumShares: number;
  readonly currentPricePerShare: number;
}) => {
  const { t } = useTranslation();

  const [red600] = useColors([`grey.200`, `red.600`]);

  const { pricePerShare, numShares } = pendingModification;
  const bidTotal = numShares * pricePerShare;

  return (
    <>
      <Box mb={4}>
        <VStack textAlign="left">
          <HStack textAlign="left" width="100%">
            <WarningCircle fill={red600} weight="fill" size="24px" />
            <Text
              textStyle={{
                base: `heading-lg`,
                md: `heading-xl`,
              }}
            >{t`transaction_modification_header`}</Text>
          </HStack>
          <Text>{t`transaction_modification_body`}</Text>
        </VStack>
      </Box>
      <Card p={4} shadow="none">
        <Flex wrap="wrap">
          <Box textAlign="left" alignItems="center">
            <TileHeader>
              <SharePrice
                numShares={currentNumShares}
                newNumShares={numShares}
                pricePerShare={currentPricePerShare}
                newPricePerShare={pricePerShare}
              />
            </TileHeader>
          </Box>
          <Spacer />
          <Box>
            <SensitiveText textAlign="right">
              {currency(bidTotal, {
                fromCents: true,
              }).format()}
            </SensitiveText>
          </Box>
        </Flex>
      </Card>
    </>
  );
};

export const DocumentTransactionCard = withCurrentActor(
  ({
    transaction,
    actor,
  }: {
    readonly transaction: DocumentTransactionCardTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const router = useRouter();
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
    const url = isUnaccreditedSeller
      ? appendSellerCompanyIdToUrl(makeUrl(transaction), transaction.company.id)
      : makeUrl(transaction);
    const title = transaction.bid.standingBid
      ? `Transaction on Standing Bid`
      : `Transaction on Bid`;

    const displayId =
      transaction.bid.standingBid?.displayId || transaction.bid.displayId;

    const { numShares, pricePerShare } = transaction;

    return (
      <Card variant="activity" as="button" onClick={() => router.push(url)}>
        <CardHeader>
          <Flex alignItems="center" gap={2}>
            <ActivityCardBadge variant="transaction" title={title} />
            <ActivityCardDisplayId displayId={displayId} />
            <ActivityCardCompany
              ml="auto"
              company={transaction.bid.listing.company}
            />
          </Flex>
        </CardHeader>
        {!!transaction.pendingModification ? (
          <CardBody>
            <PendingModificationCardBody
              pendingModification={transaction.pendingModification}
              currentNumShares={numShares}
              currentPricePerShare={pricePerShare}
            />
          </CardBody>
        ) : (
          <CardBody>
            <ActivityCardPricingWrapper>
              <ActivityCardPricingNumSharesAndPrice
                numShares={numShares}
                price={pricePerShare}
              />
              <ActivityCardPricingTotal
                numShares={numShares}
                price={pricePerShare}
              />
            </ActivityCardPricingWrapper>
          </CardBody>
        )}
      </Card>
    );
  },
);
