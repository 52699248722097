import { DotsThreeOutlineVertical, File } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Text,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
} from "@chakra-ui/react";

import { TransactionExecutionPageFileUploadFragment } from "@/gql";

export function ExecutionFileCard({
  file,
  onDownload,
}: {
  readonly file: TransactionExecutionPageFileUploadFragment;
  readonly onDownload: (fileUploadId: string) => void;
}) {
  const { t } = useTranslation(`execution`);

  const fileName = file.filename.slice(0, file.filename.lastIndexOf(`.`));
  // eslint-disable-next-line functional/immutable-data
  const fileExtension = file.filename.split(`.`).pop()?.toUpperCase();

  const handleDownload = () => {
    onDownload(file.id);
  };

  return (
    <Box
      key={file.id}
      borderRadius="lg"
      background="grey.15"
      border="1px solid"
      borderColor="grey.100"
      w="full"
      p={2}
    >
      <HStack justifyContent="space-between">
        <HStack w="full">
          <Box>
            <File size={32} />
          </Box>
          <VStack alignItems="start" gap={0} w="full">
            <Text textStyle="heading-xs" noOfLines={1} wordBreak="break-all">
              {fileName}
            </Text>
            <Text textStyle="text-sm" color="grey.600">
              {fileExtension}
            </Text>
          </VStack>
        </HStack>
        <Menu>
          <MenuButton
            width="24px"
            height="24px"
            p={0}
            m={0}
            border="none"
            bg="grey.15"
            flexShrink={0}
          >
            <DotsThreeOutlineVertical weight="fill" size={14} width="100%" />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleDownload}>
              <Text>{t(`download`)}</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Box>
  );
}
