import { useTranslation } from "react-i18next";

import { useDisclosure } from "@chakra-ui/react";

import { HiiveButton, HiiveButtonProps } from "@/components/common";
import {
  CreateEntityModalTypes,
  CreateInternalEntityModal,
  EntityCardTypes,
} from "@/components/entities";

type CreateNewInternalEntityButtonProps = {
  transactionId?: string;
  type: EntityCardTypes;
} & Omit<HiiveButtonProps, "sentryLabel" | "type">;

const CreateNewInternalEntityButton = ({
  transactionId,
  type,
  ...props
}: CreateNewInternalEntityButtonProps) => {
  const { t } = useTranslation(`transactions`);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <HiiveButton
        onClick={onOpen}
        sentryLabel="[CreateNewInternalEntityButton] Create"
        size="xl"
        type="submit"
        variant="rounded-outline-grey"
        {...props}
      >
        {t(`add_new`)}
      </HiiveButton>
      <CreateInternalEntityModal
        isOpen={isOpen}
        onClose={onClose}
        transactionId={transactionId}
        type={
          type === EntityCardTypes.Buyer
            ? CreateEntityModalTypes.Buyer
            : CreateEntityModalTypes.Seller
        }
      />
    </>
  );
};

export default CreateNewInternalEntityButton;
