import { TFunction } from "i18next";
import { match } from "ts-pattern";

import { CurrentActorContext } from "@/components/providers";
import { SuitabilityQuestionGroupsQuery } from "@/gql";
import { getIsInstitutionUser, getUserCountry } from "@/utils/user";

export const getSuitabilityAnswersForActor = (actor: CurrentActorContext) => {
  const isInstitutionUser = getIsInstitutionUser(actor);
  return (
    (isInstitutionUser
      ? actor.institution?.suitabilityAnswers
      : actor.suitabilityAnswers) || []
  );
};

export const getSuitabilityAnsweredVersion = (actor: CurrentActorContext) => {
  const suitabilityAnswers = getSuitabilityAnswersForActor(actor);
  return (
    (suitabilityAnswers &&
      suitabilityAnswers[0]?.suitabilityOption?.suitabilityQuestion
        ?.suitabilityQuestionGroup?.version) ||
    0
  );
};

export const getOutdatedSuitabilityText = ({
  companyName,
  currentSuitabilityVersion,
  isInstitutionUser,
  t,
}: {
  readonly companyName?: string;
  readonly currentSuitabilityVersion: number;
  readonly isInstitutionUser: boolean;
  readonly t: TFunction;
}) =>
  match({
    currentSuitabilityVersion,
    isInstitutionUser,
  })
    .with(
      {
        currentSuitabilityVersion: 2,
        isInstitutionUser: true,
      },
      () => t(`update_required_suitability_v3_institutional`, { companyName }),
    )
    .with(
      {
        currentSuitabilityVersion: 2,
        isInstitutionUser: false,
      },
      () => t(`update_required_suitability_v3_individual`),
    )
    .with(
      {
        currentSuitabilityVersion: 1,
        isInstitutionUser: true,
      },
      () => t(`update_required_suitability_institutional`, { companyName }),
    )
    .with(
      {
        currentSuitabilityVersion: 1,
        isInstitutionUser: false,
      },
      () => t(`update_required_suitability_individual`),
    )
    .with(
      {
        isInstitutionUser: true,
      },
      () => t(`update_required_suitability_institutional`, { companyName }),
    )
    .with(
      {
        isInstitutionUser: false,
      },
      () => t(`update_required_suitability_individual`),
    )
    .otherwise(() => ``);

export const getSuitabilityUnansweredText = ({
  companyName,
  isInstitutionAdmin,
  isInstitutionUser,
  verified,
  t,
}: {
  readonly companyName?: string;
  readonly isInstitutionAdmin: boolean;
  readonly isInstitutionUser: boolean;
  readonly verified: boolean;
  readonly t: TFunction;
}) =>
  match({
    isInstitutionUser,
    verified,
    isInstitutionAdmin,
  })
    .with(
      {
        isInstitutionUser: false,
        verified: true,
      },
      () => t(`you_can_currently_sell`),
    )
    .with(
      {
        isInstitutionUser: true,
        isInstitutionAdmin: true,
        verified: true,
      },
      () =>
        t(`company_can_currently_sell`, {
          companyName,
        }),
    )
    .with(
      {
        isInstitutionUser: true,
        isInstitutionAdmin: false,
        verified: true,
      },
      () =>
        t(`company_admin_can_currently_sell`, {
          companyName,
        }),
    )
    .with(
      {
        isInstitutionUser: false,
        verified: false,
      },
      () => t(`if_want_to_make_bids`),
    )
    .with(
      {
        isInstitutionUser: true,
        isInstitutionAdmin: true,
        verified: false,
      },
      () =>
        t(`company_if_want_to_make_bids`, {
          companyName,
        }),
    )
    .with(
      {
        isInstitutionUser: true,
        isInstitutionAdmin: false,
        verified: false,
      },
      () =>
        t(`company_admin_if_want_to_make_bids`, {
          companyName,
        }),
    )
    .otherwise(() => t(`you_can_currently_sell`));

export const getIsSuitabilityOutdated = (user: CurrentActorContext) =>
  getSuitabilityAnsweredVersion(user) < 2 &&
  [`US`, `CA`].includes(getUserCountry(user).name);

export const areSuitabilityAnswersCurrent = (
  actor: CurrentActorContext,
  questionSet: SuitabilityQuestionGroupsQuery["currentSuitabilityQuestionGroup"],
) => {
  const currentVersion = questionSet?.version;

  // Optimistically return true while we wait for requests to finish
  if (!questionSet) return true;

  return currentVersion === getSuitabilityAnsweredVersion(actor);
};

export const getSuitabilityLastUpdatedAtForActor = (
  actor: CurrentActorContext,
) => {
  const isInstitutionUser = getIsInstitutionUser(actor);
  const { suitabilityLastUpdatedAt } = isInstitutionUser
    ? // This check isn't necessary but ts throws a type error if it isnt here. Case is handled above
      (actor.institution ?? actor)
    : actor;
  return suitabilityLastUpdatedAt;
};
