import { nanoid } from "nanoid";
import { useMemo } from "react";

import { VStack } from "@chakra-ui/react";

import { IssuerInvitationFragment } from "@/gql";
import { getInvitationUsersTableData } from "@/utils";

import { IssuerManageUsersCard } from "./IssuerManageUsersCard";

type IssuerManageUsersCardListProps = {
  invitations: IssuerInvitationFragment[];
};

export function IssuerManageUsersCardList({
  invitations,
}: IssuerManageUsersCardListProps) {
  const invitationUsersTableData = useMemo(
    () => getInvitationUsersTableData(invitations),
    [invitations],
  );

  return (
    <VStack gap={2} alignItems="flex-start" w="full" mt={2}>
      {invitationUsersTableData.map((mappedInvitationUser) => (
        <IssuerManageUsersCard
          key={nanoid()}
          invitation={mappedInvitationUser}
        />
      ))}
    </VStack>
  );
}
