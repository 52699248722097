import { CheckCircle, WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import {
  CardBody,
  CardFooter,
  chakra,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";

import { HiiveButton, PoweredByModernTreasury } from "@/components/common";
import { SomethingWentWrongError } from "@/components/error";
import { FormMoneyInput } from "@/components/react-hook-form";
import {
  BankAccountVerificationStatus,
  ModernTreasuryVerifyAccountCardBankAccountFragment,
  UserBankAccountFragment,
  useSubmitBankAccountVerificationMutation,
} from "@/gql";
import { useColors, useCustomToast } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { useApolloClient } from "@/hooks/useApolloClient";

import BankAccountCard from "./BankAccountCard";
import FlatCard from "./FlatCard";
import ModernTreasuryVeryAccountCardContainer from "./ModernTreasuryVerifyAccountCardContainer";

const executionEmail = `execution@hiive.com`;
// const MAX_VERIFICATIONS_ATTEMPTS = 5;

const VerificationCancelledCard = () => {
  const { t } = useTranslation();
  const [red900] = useColors([`red.900`]);

  return (
    <ModernTreasuryVeryAccountCardContainer>
      <CardBody>
        <FlatCard>
          <VStack
            w="full"
            m={4}
            gap={1}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <HStack
              justifyContent="flex-start"
              alignItems="flex-start"
              w="full"
            >
              <WarningCircle size={24} weight="fill" color={red900} />
              <Text textStyle="heading-md">{t`verification_cancelled`}</Text>
            </HStack>
            <Text textStyle="text-sm" color="grey.700" pl={7}>
              <Trans
                i18nKey="verification_cancelled_description"
                components={{
                  bold: <Text as="span" fontWeight="bold" />,
                }}
                values={{ email: executionEmail }}
              />
            </Text>
          </VStack>
        </FlatCard>
        <PoweredByModernTreasury />
      </CardBody>
    </ModernTreasuryVeryAccountCardContainer>
  );
};

const VerificationFailedCard = () => {
  const { t } = useTranslation();
  const [red900] = useColors([`red.900`]);

  return (
    <ModernTreasuryVeryAccountCardContainer>
      <CardBody>
        <FlatCard>
          <HStack m={4} alignItems="flex-start">
            <VStack>
              <WarningCircle size={24} weight="fill" color={red900} />
            </VStack>
            <VStack alignItems="flex-start" gap={1}>
              <Text textStyle="heading-md">{t`verification_failed`}</Text>
              <Text textStyle="text-sm" color="grey.700">
                <Trans
                  i18nKey="verification_failed_description"
                  components={{
                    bold: <Text as="span" fontWeight="bold" />,
                  }}
                  values={{ email: executionEmail }}
                />
              </Text>
            </VStack>
          </HStack>
        </FlatCard>
        <PoweredByModernTreasury />
      </CardBody>
    </ModernTreasuryVeryAccountCardContainer>
  );
};

const VerifyingForeignAccountCard = () => {
  const { t } = useTranslation();
  const [grey100, grey700] = useColors([`grey.100`, `grey.700`]);

  return (
    <ModernTreasuryVeryAccountCardContainer>
      <CardBody>
        <FlatCard>
          <VStack alignItems="flex-start" m={6}>
            <HStack justifyContent="flex-start">
              <CheckCircle size={24} weight="fill" color={grey100} />
              <Text textStyle="heading-md">{t`verifying_account_information`}</Text>
            </HStack>
            <VStack alignItems="flex-start">
              <Text textStyle="text-md" color="grey.700">
                <Trans
                  i18nKey="verifying_account_information_description"
                  values={{ email: `execution@hiive.com` }}
                  color={grey700}
                />
              </Text>
            </VStack>
          </VStack>
        </FlatCard>
        <PoweredByModernTreasury />
      </CardBody>
    </ModernTreasuryVeryAccountCardContainer>
  );
};

const VerifiedAccountCard = ({
  bankAccount,
}: {
  readonly bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment;
}) => (
  <ModernTreasuryVeryAccountCardContainer>
    <CardBody>
      <BankAccountCard bankAccount={bankAccount} />
      <PoweredByModernTreasury />
    </CardBody>
  </ModernTreasuryVeryAccountCardContainer>
);

const VerifyAccountInformationErrorCard = ({
  onTryAgain,
}: {
  readonly onTryAgain: () => void;
}) => {
  const { t } = useTranslation();
  const [red900] = useColors([`red.900`]);

  return (
    <ModernTreasuryVeryAccountCardContainer>
      <CardBody>
        <FlatCard>
          <HStack
            justifyContent="flex-start"
            alignItems="flex-start"
            mt={5}
            mb={4}
            mx={5}
          >
            <WarningCircle
              size={36}
              weight="fill"
              color={red900}
              height="full"
            />
            <VStack alignItems="flex-start">
              <Text textStyle="heading-md">{t`verifying_account_information_error`}</Text>
              <Text textStyle="text-sm" color="grey.700">
                <Trans
                  i18nKey="verifying_account_information_error_description"
                  components={{
                    bold: <Text as="span" fontWeight="bold" />,
                  }}
                  values={{ email: executionEmail }}
                />
              </Text>
            </VStack>
          </HStack>
        </FlatCard>
        <PoweredByModernTreasury />
      </CardBody>
      <CardFooter
        as={HStack}
        justifyContent="space-between"
        py={4}
        bg="grey.25"
      >
        <Text>{t(`bank_verification_attempts_info`)}</Text>
        <HiiveButton
          disabled
          onClick={onTryAgain}
          variant="rounded-solid-salmon"
          sentryLabel="[ModernTreasuryVerifyAccountCard] try again"
          size="xl"
        >{t`try_again`}</HiiveButton>
      </CardFooter>
    </ModernTreasuryVeryAccountCardContainer>
  );
};

/**
 * Lets users verify bank account via microdeposits.
 */
const VerifyAccountInformationFormCard = ({
  sellerBankAccountId,
  isVerificationAvailable = true,
  onSuccess,
  onError,
}: {
  readonly sellerBankAccountId?: string;
  readonly isVerificationAvailable?: boolean;
  readonly onSuccess: () => void;
  readonly onError: () => void;
}) => {
  const { t } = useTranslation();
  const [grey100] = useColors([`grey.100`]);

  const mutation = useSubmitBankAccountVerificationMutation();

  const initialValues = {
    depositOne: ``,
    depositTwo: ``,
  };

  const validationSchema = Yup.object({
    depositOne: Yup.number()
      .transform((value) =>
        Number.isNaN(value) ? undefined : parseFloat(value),
      )
      .min(0.01, t`deposit_min_error`)
      .max(0.99, t`deposit_max_error`)
      .required(t`deposit_one_error`),
    depositTwo: Yup.number()
      .transform((value) =>
        Number.isNaN(value) ? undefined : parseFloat(value),
      )
      .min(0.01, t`deposit_min_error`)
      .max(0.99, t`deposit_max_error`)
      .required(t`deposit_two_error`),
  });

  const { handleSubmit, isLoading, control } = useFormQL({
    mutation,
    mapVariables: (items) => {
      const depositValueOne = parseFloat(items.depositOne) * 100;
      const depositValueTwo = parseFloat(items.depositTwo) * 100;
      return {
        depositValueOne: Math.round(depositValueOne),
        depositValueTwo: Math.round(depositValueTwo),
        sellerBankAccountId,
      };
    },
    initialValues,
    validationSchema,
    onSuccess,
    onError,
  });

  const formMoneyInputProps = {
    placeholder: t(`money_input_placeholder`),
    isDisabled: !isVerificationAvailable,
    allowNegative: false,
    decimalScale: 2,
  };

  return (
    <chakra.form onSubmit={handleSubmit}>
      <ModernTreasuryVeryAccountCardContainer>
        <CardBody>
          <FlatCard>
            <VStack gap={2} alignItems="flex-start" m={6}>
              <HStack justifyContent="flex-start">
                <CheckCircle size={24} weight="fill" color={grey100} />
                <Text textStyle="heading-md">{t`verifying_account_information`}</Text>
              </HStack>
              <Text textStyle="text-sm" color="grey.700">
                <Trans
                  i18nKey="verify_account_information_description"
                  values={{ email: executionEmail }}
                  components={{ br: <Box h={3} /> }}
                />
              </Text>
              <SimpleGrid w="full" rowGap={4} pt={3}>
                <GridItem>
                  <FormMoneyInput
                    name="depositOne"
                    label={t`deposit_one_value`}
                    autoFocus
                    control={control}
                    {...formMoneyInputProps}
                  />
                </GridItem>
                <GridItem>
                  <FormMoneyInput
                    name="depositTwo"
                    label={t`deposit_two_value`}
                    control={control}
                    {...formMoneyInputProps}
                  />
                </GridItem>
              </SimpleGrid>
            </VStack>
          </FlatCard>
          <PoweredByModernTreasury />
        </CardBody>
        <CardFooter as={HStack} justifyContent="flex-end" py={4} bg="grey.25">
          <HiiveButton
            isLoading={isLoading}
            type="submit"
            isDisabled={!isVerificationAvailable}
            variant="rounded-solid-salmon"
            sentryLabel="[ModernTreasuryVerifyAccountCard] submit"
            size="xl"
          >{t`submit`}</HiiveButton>
        </CardFooter>
      </ModernTreasuryVeryAccountCardContainer>
    </chakra.form>
  );
};

const ModernTreasuryVerifyAccountCard = ({
  transactionBankAccount,
}: {
  readonly transactionBankAccount: UserBankAccountFragment;
}) => {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const { successToast } = useCustomToast();

  const [showAttemptedVerificationError, setShowAttemptedVerificationError] =
    useState<boolean>(false);

  const [
    showAttemptedVerificationSuccess,
    setShowAttemptedVerificationSuccess,
  ] = useState<boolean>(false);

  const onVerifyAccountSuccess = () => {
    setShowAttemptedVerificationSuccess(true);
    successToast(t`account_successfully_verified`);
  };

  const onVerifyAccountError = () => {
    setShowAttemptedVerificationError(true);
  };

  const onTryVerificationAgain = () => {
    apolloClient.refetchQueries({
      include: `active`,
    });
  };

  const bankAccountVerificationStatus =
    transactionBankAccount.verificationStatus;

  return match(bankAccountVerificationStatus)
    .with(BankAccountVerificationStatus.PendingVerification, () =>
      match({
        showAttemptedVerificationError,
        showAttemptedVerificationSuccess,
      })
        .with({ showAttemptedVerificationError: true }, () => (
          <VerifyAccountInformationErrorCard
            onTryAgain={onTryVerificationAgain}
          />
        ))
        .with({ showAttemptedVerificationSuccess: true }, () => (
          <VerifiedAccountCard bankAccount={transactionBankAccount} />
        ))
        .otherwise(() => (
          <VerifyAccountInformationFormCard
            sellerBankAccountId={transactionBankAccount.id}
            onSuccess={onVerifyAccountSuccess}
            onError={onVerifyAccountError}
          />
        )),
    )
    .with(BankAccountVerificationStatus.Manual, () => (
      <VerifyingForeignAccountCard />
    ))
    .with(BankAccountVerificationStatus.Cancelled, () => (
      <VerificationCancelledCard />
    ))
    .with(BankAccountVerificationStatus.Verified, () => (
      <VerifiedAccountCard bankAccount={transactionBankAccount} />
    ))
    .with(BankAccountVerificationStatus.Failed, () => (
      <VerificationFailedCard />
    ))
    .otherwise(() => <SomethingWentWrongError />);
};

export default ModernTreasuryVerifyAccountCard;
