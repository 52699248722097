/* eslint-disable functional/immutable-data */

/* eslint-disable no-return-assign */
import { useApolloClient } from "@apollo/client";
import { useOrganization } from "@frigade/react";
import { useAuthUserOrNull } from "@frontegg/nextjs";
import isEmpty from "lodash/isEmpty";
import { createContext, ReactElement, useEffect } from "react";

import { useRouter } from "next/router";

import { Box, Flex } from "@chakra-ui/react";

import { CompleteProfileBanner, Loader } from "@/components/common";
import { FeatureFlagsHelper } from "@/components/helpers";
import { NavBar, NavSpacer } from "@/components/nav";
import {
  FrontChatProvider,
  KnockProvider,
  ModalProvider,
  CurrentActorProvider,
} from "@/components/providers";
import {
  CurrentContextDocument,
  useCurrentContextQuery,
  useOnIdentityInquiryUpdatedSubscription,
  InvestorStatus,
} from "@/gql";
import {
  canAccessPlatform,
  useSession,
  useIsChangingRoute,
  useSignOut,
  useToken,
  useCorrectPortalRedirect,
  useIsOnCorrectPortal,
} from "@/hooks";
import {
  useBypassMfaEnforcement,
  useIssuerPortalRetoolParity,
  useMarketplaceCompleteProfileBanner,
  useMarketplaceConfiguration,
  useSuitabilityUpfront,
} from "@/hooks/featureFlags";
import { useMfaEnrollmentToast } from "@/hooks/frontegg";
import { useIdentifyUser } from "@/hooks/useIdentifyUser";
import { getIsAppMfaEnabled, getIsInstitutionUser, ROUTES } from "@/utils";
import { getIsIssuerUser, getIsProfileIncomplete } from "@/utils/user";

import Layout from "./Layout";

interface AuthenticatedLayoutProps {
  readonly children: JSX.Element | readonly JSX.Element[];
  readonly title?: string;
  readonly useCustomLayout?: boolean;
}

const useIdentifySentryUser = () => {
  const token = useToken();
  const user = useSession();
  const identifyUser = useIdentifyUser();

  useEffect(() => {
    if (!user) {
      return;
    }

    identifyUser(user);
  }, [token, user]);
};

const useCheckAuthenticated = () => {
  const { data, loading } = useCurrentContextQuery();
  const fronteggUser = useAuthUserOrNull();
  const isChangingRoute = useIsChangingRoute();
  const token = useToken();
  const signOut = useSignOut();
  const marketplaceConfigurationEnabled = useMarketplaceConfiguration();

  const actor = data?.currentContext?.currentActor;
  const isActorOnboarding = !actor?.onboardingComplete;

  const hasHiiveToken = token && !fronteggUser;

  const shouldEnforceFronteggToken =
    marketplaceConfigurationEnabled &&
    hasHiiveToken &&
    !isActorOnboarding &&
    !isChangingRoute();

  const hasToken = !!token;
  const hasActor = !isEmpty(actor);

  const hasTokenButNoActor =
    hasToken && !hasActor && !isChangingRoute() && !loading;

  const shouldSignOut =
    shouldEnforceFronteggToken || !hasToken || hasTokenButNoActor;

  useEffect(() => {
    if (shouldSignOut) {
      signOut();
    }
  }, [shouldSignOut]);
};

const useIsOnboarding = () => {
  const router = useRouter();

  const exemptRoutes = [`/terms-and-conditions`];

  const onboardingAccessibleRoutes = [
    `/welcome`,
    `/terms-and-conditions`,
    `/verify-email`,
    `/something-went-wrong`,
    `/page-not-found`,
  ];

  const isOnboarding =
    onboardingAccessibleRoutes.includes(router.asPath) ||
    exemptRoutes.includes(router.asPath);

  return isOnboarding;
};

export const EnforceAppMfaConfigurationContext = createContext(false);

const AuthenticatedProviders = ({
  children,
}: {
  readonly children: ReactElement;
}) => (
  <CurrentActorProvider>
    <ModalProvider>
      <FeatureFlagsHelper />
      <KnockProvider>
        <FrontChatProvider>{children}</FrontChatProvider>
      </KnockProvider>
    </ModalProvider>
  </CurrentActorProvider>
);

const AuthenticatedLayout = ({
  children,
  title,
  useCustomLayout = false,
}: AuthenticatedLayoutProps) => {
  const { data, loading } = useCurrentContextQuery();
  const signOut = useSignOut();
  const client = useApolloClient();
  const { replace, push, pathname } = useRouter();

  const isMarketplaceConfigurationEnabled = !!useMarketplaceConfiguration();
  const issuerPortalRetoolParity = useIssuerPortalRetoolParity();
  const bypassMfaEnforcement = useBypassMfaEnforcement();
  const { setOrganizationIdWithProperties } = useOrganization();
  const isSUPEnabled = useSuitabilityUpfront();
  const isFronteggUser = !!useAuthUserOrNull();
  const redirectToCorrectPortal = useCorrectPortalRedirect();
  const isOnCorrectPortal = useIsOnCorrectPortal();

  const isOnboarding = useIsOnboarding();
  const isBannerFlagOn = useMarketplaceCompleteProfileBanner();
  useCheckAuthenticated();
  useIdentifySentryUser();
  useMfaEnrollmentToast();

  const actor = data?.currentContext?.currentActor;
  const isHiiveUser = actor?.isHiiveUser;
  const hasActor = !isEmpty(actor);
  const isIssuerUser =
    hasActor && getIsIssuerUser(actor) && issuerPortalRetoolParity;

  const isAppMfaEnabled = hasActor ? getIsAppMfaEnabled(actor) : false;
  const isEmailVerified = actor?.emailVerified;
  const isInstitutionUser = hasActor ? getIsInstitutionUser(actor) : false;
  const isOnboardingComplete = actor?.onboardingComplete;
  const isReOnboarding =
    hasActor &&
    !isOnboarding &&
    actor.institution?.onboarding.reonboardingStartedAt;

  const shouldEnforceAppMfaConfiguration =
    hasActor &&
    isHiiveUser &&
    isFronteggUser &&
    isMarketplaceConfigurationEnabled &&
    !bypassMfaEnforcement &&
    !isAppMfaEnabled &&
    !isIssuerUser;

  const shouldRedirectToOnboarding =
    (hasActor && !isOnboarding && !canAccessPlatform(actor) && !isIssuerUser) ||
    isReOnboarding;

  const shouldRedirectToSetupMfa =
    shouldEnforceAppMfaConfiguration &&
    pathname !== ROUTES.SETUP_MFA &&
    canAccessPlatform(actor);

  const shouldRedirectToRequestEmailVerification =
    isMarketplaceConfigurationEnabled &&
    !isEmailVerified &&
    isInstitutionUser &&
    hasActor &&
    canAccessPlatform(actor);

  const shouldRenderCompleteProfileBanner = !!(
    isBannerFlagOn &&
    isOnboardingComplete &&
    !isIssuerUser &&
    getIsProfileIncomplete(actor)
  );

  const shouldSkipVerifiedIdentitySubscription =
    actor?.investorStatus !== InvestorStatus.Individual ||
    actor?.identityVerified;

  const verifiedIdentityResult = useOnIdentityInquiryUpdatedSubscription({
    skip: shouldSkipVerifiedIdentitySubscription,
  });

  useEffect(() => {
    if (!hasActor || isOnCorrectPortal(actor)) {
      return;
    }

    redirectToCorrectPortal(actor);
  }, [actor]);

  useEffect(() => {
    if (verifiedIdentityResult.data) {
      client.refetchQueries({
        include: [CurrentContextDocument],
      });
    }
  }, [verifiedIdentityResult]);

  useEffect(() => {
    if (actor?.institution) {
      setOrganizationIdWithProperties(actor.institution.id, {
        name: actor.institution.legalName,
        membershipAgreementSigned: actor.institution.membershipAgreementSigned,
      });
    }
  }, [
    actor?.institution?.membershipAgreementSigned,
    actor?.institution?.legalName,
  ]);

  useEffect(() => {
    if (shouldRedirectToRequestEmailVerification) {
      push(ROUTES.REQUEST_EMAIL_VERIFICATION);
    }

    if (shouldRedirectToOnboarding) {
      push(ROUTES.WELCOME);
    }

    if (shouldRedirectToSetupMfa) {
      replace(ROUTES.SETUP_MFA);
    }
  }, [
    shouldRedirectToOnboarding,
    shouldRedirectToRequestEmailVerification,
    shouldRedirectToSetupMfa,
    isSUPEnabled,
    signOut,
    push,
    replace,
  ]);

  if (
    loading ||
    shouldRedirectToSetupMfa ||
    shouldRedirectToOnboarding ||
    isIssuerUser
  ) {
    return <Loader minHeight="100vh" />;
  }

  if (useCustomLayout)
    return (
      <AuthenticatedProviders>
        <Layout title={title}>
          <Flex
            as="main"
            id="main-element"
            direction="column"
            minH="100vh"
            flex="1 1 auto"
            position="relative"
          >
            {children}
          </Flex>
        </Layout>
      </AuthenticatedProviders>
    );
  return (
    <AuthenticatedProviders>
      <Layout title={title}>
        <Flex
          direction="column"
          minH="100vh"
          flex="1 1 auto"
          position="relative"
        >
          <EnforceAppMfaConfigurationContext.Provider
            value={!!shouldEnforceAppMfaConfiguration}
          >
            <Box position="fixed" top={0} left={0} right={0} zIndex="modal">
              {shouldRenderCompleteProfileBanner && <CompleteProfileBanner />}
              <NavBar />
            </Box>
            <NavSpacer
              shouldRenderExtraSpace={shouldRenderCompleteProfileBanner}
            />
          </EnforceAppMfaConfigurationContext.Provider>
          <Flex
            as="main"
            id="main-element"
            flex="1 1 auto"
            direction="column"
            align="center"
          >
            {children}
          </Flex>
        </Flex>
      </Layout>
    </AuthenticatedProviders>
  );
};

export default AuthenticatedLayout;
