import { forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Box,
  Card,
  CardBody,
  Divider,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { IssuerStage } from "@/gql";
import { formatPricePerShare, formatShares, shareTypeToString } from "@/utils";

import { IssuerTransactionsDaysAgoBadge } from "./IssuerTransactionsDaysAgoBadge";
import {
  IssuerTransactionsTableContext,
  NonNullableTransactionWorkflowEdge,
} from "./IssuerTransactionsProvider";
import { IssuerTransactionsStageBadge } from "./IssuerTransactionsStageBadge";
import { RofrIcon } from "./RofrIcon";
import {
  getCancelledDate,
  getClosedDate,
  getDaysSinceSubmission,
  getNoticeReceivedDate,
} from "./util";

interface StageDateTextProps {
  readonly stage: IssuerStage | null | undefined;
  readonly workflow: NonNullableTransactionWorkflowEdge["node"]["workflow"];
}

export function StageDateText({ stage, workflow }: StageDateTextProps) {
  const { t } = useTranslation(`issuers`);

  return match(stage)
    .with(IssuerStage.InReview, () => null)
    .with(IssuerStage.Closed, () => (
      <Text fontSize={12} color="grey.500" textAlign="right">
        {t(`date_closed`)}: {getClosedDate(workflow)}
      </Text>
    ))
    .with(IssuerStage.Cancelled, () => (
      <Text fontSize={12} color="grey.500" textAlign="right">
        {t(`date_cancelled`)}: {getCancelledDate(workflow)}
      </Text>
    ))
    .otherwise(() => (
      <Text fontSize={12} color="grey.500" textAlign="right">
        {t(`notice_date`)}: {getNoticeReceivedDate(workflow)}
      </Text>
    ));
}

export const IssuerTransactionsCard = forwardRef<
  HTMLDivElement,
  {
    readonly transaction: NonNullableTransactionWorkflowEdge["node"];
    readonly onClick: () => void;
  }
>(({ transaction, onClick }, ref) => {
  const { t } = useTranslation(`issuers`);
  const { filterBy } = useContext(IssuerTransactionsTableContext);

  const { isRofr, issuerStage, workflow } = transaction;
  const { stage } = filterBy ?? {};

  const inReview = stage === IssuerStage.InReview;

  return (
    <Card
      w="full"
      boxShadow="none"
      borderColor="grey.200"
      ref={ref}
      onClick={onClick}
      borderRadius="none"
    >
      <CardBody>
        <VStack alignItems="flex-start" gap={2}>
          <VStack alignItems="flex-start" gap={1} w="full">
            <HStack alignItems="center" pb={2} w="full">
              <Text textStyle="text-sm">{transaction.bid.displayId}</Text>
              {isRofr ? <RofrIcon /> : `-`}
              {inReview && (
                <IssuerTransactionsDaysAgoBadge
                  daysAgo={getDaysSinceSubmission(workflow)}
                />
              )}
              {!stage && <IssuerTransactionsStageBadge stage={issuerStage} />}
              <Box flexGrow={1} />
              <Text textStyle="text-sm">
                {formatShares(transaction.numShares)} @
              </Text>
            </HStack>
            <HStack alignItems="center" w="full">
              <Text textStyle="text-sm" color="grey.500">
                {shareTypeToString(transaction.bid.listing.shareTypeV2)}
              </Text>
              <Box flexGrow={1} />
              <Text textStyle="heading-lg">
                {formatPricePerShare(transaction.pricePerShare)}
              </Text>
            </HStack>
            <Divider />
            <HStack w="full">
              <Text textStyle="text-sm" color="grey.500">
                {t(`seller`)}
              </Text>
              <Box flexGrow={1} />
              <Text textStyle="text-sm" color="grey.500">
                {t(`buyer`)}
              </Text>
            </HStack>
            <HStack w="full">
              <Text textStyle="text-sm">
                {transaction.sellerEntity?.legalName ?? `-`}
              </Text>
              <Box flexGrow={1} />
              <Text textStyle="text-sm">
                {transaction.buyerEntity?.legalName ?? `-`}
              </Text>
            </HStack>
          </VStack>
          {!inReview && (
            <>
              <Divider />
              <HStack w="full">
                <Box flexGrow={1} />
                <StageDateText stage={stage} workflow={workflow} />
              </HStack>
            </>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
});
