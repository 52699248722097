import {
  CheckCircle,
  Circle,
  CaretUp,
  CaretRight,
} from "@phosphor-icons/react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Card,
  CardFooter,
  HStack,
  Text,
  Badge,
  Center,
  VStack,
  Divider,
  CardHeader,
  CardBody,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Link,
} from "@chakra-ui/react";

import { CollapsibleInlineText, SharePrice } from "@/components/common";
import {
  getTransactionModificationStatus,
  useTransactionModificationStatusTitle,
  TransactionModificationStatus,
} from "@/components/transactions";
import {
  TransactionExecutionPageTaskFragment,
  ExecutionConfirmTransactionModificationTaskData,
  ExecutionTaskStatus,
  ExecutionTaskActorType,
  TransactionExecutionPageTransactionFragment,
  ExecutionConfirmTransactionModificationTaskDataCommission,
} from "@/gql";
import { useColors } from "@/hooks";
import { formatCurrency } from "@/utils";
import { toFullDate } from "@/utils/datetime";

import ConfirmTransactionModificationAcceptAction from "./ConfirmTransactionModificationAcceptAction";
import ConfirmTransactionModificationDeclineAction from "./ConfirmTransactionModificationDeclineAction";

const FeesSection = ({
  commission,
  numShares,
  pricePerShare,
}: {
  commission: ExecutionConfirmTransactionModificationTaskDataCommission;
  numShares: number;
  pricePerShare: number;
}) => {
  const { t } = useTranslation(`execution`);
  const { commissionAmount = 0, flatFeeAmount = 0, netFees = 0 } = commission;

  const formattedClosingFee = formatCurrency(flatFeeAmount, {
    fromCents: true,
  });
  const formattedCommissionAmount = formatCurrency(commissionAmount, {
    fromCents: true,
  });
  const formattedNetFees = formatCurrency(netFees, {
    fromCents: true,
  });

  const feesCount = [commissionAmount, flatFeeAmount].filter(
    (fee) => fee > 0,
  ).length;

  const formattedNetProceeds = formatCurrency(
    numShares * pricePerShare - netFees,
    {
      fromCents: true,
    },
  );

  return (
    <>
      <Accordion allowToggle w="full">
        <AccordionItem border="none">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                as={HStack}
                w="full"
                justifyContent="space-between"
                p={0}
                _hover={{ bg: `transparent` }}
                _focus={{ boxShadow: `none` }}
              >
                <VStack alignItems="start" gap={1}>
                  <Text textStyle="heading-xs" color="grey.500">
                    {t(`total_hiive_fees`)}
                  </Text>
                  <Text textStyle="text-sm">
                    {feesCount} {t(`fees`)}
                  </Text>
                </VStack>
                <HStack>
                  <Text>-{formattedNetFees}</Text>
                  {isExpanded ? (
                    <CaretUp width="16" height="16" />
                  ) : (
                    <CaretRight width="16" height="16" />
                  )}
                </HStack>
              </AccordionButton>
              <AccordionPanel p={0} pt={4}>
                <Card variant="flat" bg="grey.50" border="none" w="full">
                  <CardBody p={4} pr={6}>
                    <VStack spacing={4}>
                      {flatFeeAmount > 0 && (
                        <HStack w="full" justifyContent="space-between">
                          <Text color="grey.700">{t(`hiive_closing_fee`)}</Text>
                          <Text color="grey.700">-{formattedClosingFee}</Text>
                        </HStack>
                      )}
                      {commissionAmount > 0 && (
                        <HStack w="full" justifyContent="space-between">
                          <Text color="grey.700">{t(`hiive_commission`)}</Text>
                          <Text color="grey.700">
                            -{formattedCommissionAmount}
                          </Text>
                        </HStack>
                      )}
                    </VStack>
                  </CardBody>
                </Card>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      <Divider borderColor="grey.200" />
      <HStack w="full" justifyContent="space-between" pr={6}>
        <VStack alignItems="start" gap={1}>
          <Text textStyle="heading-xs">{t(`net_proceeds`)}</Text>
          <Text textStyle="heading-2xs" textTransform="capitalize">
            <SharePrice numShares={numShares} pricePerShare={pricePerShare} />
          </Text>
        </VStack>
        <Text color="grey.700">{formattedNetProceeds}</Text>
      </HStack>
    </>
  );
};

const ConfirmTransactionModificationTaskCard = ({
  task,
  data,
  isBuySide,
  transaction,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionConfirmTransactionModificationTaskData;
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation(`execution`);
  const statusTitles = useTransactionModificationStatusTitle();
  const [grey900] = useColors([`grey.900`]);

  const status = getTransactionModificationStatus(data.acknowledgments);
  const showAcknowledgments = status === TransactionModificationStatus.Pending;
  // sorting to make sure current user's acknowledgment is always on top
  const sortedAcknowledgments = data.acknowledgments.slice().sort((a, b) => {
    const getPriority = (actorType: ExecutionTaskActorType) => {
      if (
        (actorType === ExecutionTaskActorType.Seller && !isBuySide) ||
        (actorType === ExecutionTaskActorType.Buyer && isBuySide)
      ) {
        return 0;
      }
      return 1;
    };

    return getPriority(a.actorType) - getPriority(b.actorType);
  });

  const { numShares: oldShares, pricePerShare: oldPricePerShare } = transaction;
  const { numShares: newShares, pricePerShare: newPricePerShare } = data;
  const grossProceeds = newPricePerShare * newShares;
  const formattedGrossProceeds = formatCurrency(grossProceeds, {
    fromCents: true,
  });

  return (
    <>
      <Card variant="flat" borderColor="grey.400" bg="grey.15" mb={6}>
        <CardHeader bg="white" p={0} px={4} py={5}>
          <VStack alignItems="start" gap={4}>
            <HStack w="full" justifyContent="space-between">
              <Text textStyle="heading-md">{t(`approval_status`)}</Text>
              <Badge variant={statusTitles[status].variant} px={2} py={1}>
                <Text>{statusTitles[status].title}</Text>
              </Badge>
            </HStack>
            {showAcknowledgments &&
              sortedAcknowledgments.map((acknowledgment) => (
                <HStack
                  w="full"
                  key={acknowledgment.actorType}
                  justifyContent="space-between"
                >
                  <HStack gap={4}>
                    <Center width="24px" height="24px">
                      {!!acknowledgment.acknowledgedAt ? (
                        <CheckCircle
                          color={grey900}
                          weight="fill"
                          size="18px"
                        />
                      ) : (
                        <Circle color={grey900} size="18px" />
                      )}
                    </Center>
                    <Text textStyle="heading-md" color="grey.700">
                      {match(acknowledgment.actorType)
                        .with(ExecutionTaskActorType.Seller, () =>
                          isBuySide
                            ? t(`counterparty_approval`)
                            : t(`your_approval`),
                        )
                        .with(ExecutionTaskActorType.Buyer, () =>
                          isBuySide
                            ? t(`your_approval`)
                            : t(`counterparty_approval`),
                        )
                        .otherwise(() => ``)}
                    </Text>
                  </HStack>
                  {!!acknowledgment.acknowledgedAt && (
                    <Text textStyle="text-xs">
                      {t(`approved`)}
                      {` `}
                      {toFullDate(acknowledgment.acknowledgedAt)}
                    </Text>
                  )}
                </HStack>
              ))}
          </VStack>
        </CardHeader>
        <CardBody p={0} px={4} py={5}>
          <VStack alignItems="start" gap={4}>
            {isBuySide ? (
              <HStack w="full" justifyContent="space-between" pr={6}>
                <VStack alignItems="start" gap={1}>
                  <Text textStyle="heading-xs">
                    {t(isBuySide ? `gross_value` : `net_proceeds`)}
                  </Text>
                  <Text textStyle="heading-2xs" textTransform="capitalize">
                    <SharePrice
                      numShares={newShares}
                      pricePerShare={newPricePerShare}
                    />
                  </Text>
                </VStack>
                <Text color="grey.700">{formattedGrossProceeds}</Text>
              </HStack>
            ) : (
              <>
                <HStack w="full" justifyContent="space-between" pr={6}>
                  <VStack alignItems="start" gap={1}>
                    <Text textStyle="heading-xs" color="grey.500">
                      {t(`gross_sale_amount`)}
                    </Text>
                    <Text textStyle="heading-2xs" textTransform="capitalize">
                      <SharePrice
                        numShares={oldShares}
                        pricePerShare={oldPricePerShare}
                        newNumShares={newShares}
                        newPricePerShare={newPricePerShare}
                      />
                    </Text>
                  </VStack>
                  <Text color="grey.700">{formattedGrossProceeds}</Text>
                </HStack>
                <FeesSection
                  numShares={newShares}
                  pricePerShare={newPricePerShare}
                  commission={data.commission}
                />
              </>
            )}
            <CollapsibleInlineText
              collapsedText={
                <Text
                  as="span"
                  textStyle="text-xs"
                  lineHeight="inherit"
                  color="grey.500"
                >
                  {t(`proceeds_description_collapsed`)}
                </Text>
              }
              expandedText={
                <>
                  <Text
                    as="span"
                    textStyle="text-xs"
                    lineHeight="inherit"
                    color="grey.500"
                    mb={1}
                  >
                    <Trans
                      i18nKey="proceeds_description_expanded"
                      ns="execution"
                    />
                  </Text>
                  <Text
                    as="span"
                    textStyle="text-xs"
                    lineHeight="inherit"
                    color="grey.500"
                  >
                    <Trans
                      i18nKey="proceeds_description_terms"
                      ns="execution"
                      components={{
                        a: (
                          <Link
                            textDecoration="underline"
                            href="/terms-and-conditions"
                            target="_blank"
                          />
                        ),
                      }}
                    />
                  </Text>
                </>
              }
              label={{ collapsed: t(`read_more`), expanded: t(`see_less`) }}
            />
          </VStack>
        </CardBody>
      </Card>
      {task.status === ExecutionTaskStatus.InProgress && (
        <CardFooter
          bg="grey.25"
          px={6}
          py={4}
          mx={-6}
          justifyContent="end"
          gap="4"
        >
          <ConfirmTransactionModificationDeclineAction
            taskId={task.id}
            data={data}
            companyName={transaction.company.name}
            transferMethod={transaction.bid.listing.transferMethodV2}
          />
          <ConfirmTransactionModificationAcceptAction
            taskId={task.id}
            data={data}
            companyName={transaction.company.name}
            transferMethod={transaction.bid.listing.transferMethodV2}
          />
        </CardFooter>
      )}
    </>
  );
};

export default ConfirmTransactionModificationTaskCard;
