import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Button, VStack } from "@chakra-ui/react";

import {
  IssuerPageHeader,
  IssuerPortalPageContainer,
} from "@/components/issuers";
import { UserRole } from "@/gql";
import { useIsDesktop, useModal, useCurrentIssuerActor } from "@/hooks";

import { IssuerManageUsersCardList } from "./IssuerManageUsersCardList";
import { IssuerManageUsersCardListSkeleton } from "./IssuerManageUsersCardListSkeleton";
import {
  IssuerManageUsersTable,
  IssuerManageUsersTableSkeleton,
} from "./IssuerManageUsersTable";
import {
  IssuerManageUsersTableContext,
  IssuerManageUsersTableProvider,
} from "./IssuerManageUsersTableProvider";

function IssuerManageUsersPageContent() {
  const { invitations, loading } = useContext(IssuerManageUsersTableContext);

  if (loading) {
    return <IssuerManageUsersTableSkeleton />;
  }

  return invitations && <IssuerManageUsersTable invitations={invitations} />;
}

function IssuerManageUsersMobilePageContent() {
  const { invitations, loading } = useContext(IssuerManageUsersTableContext);

  if (loading) {
    return <IssuerManageUsersCardListSkeleton />;
  }

  return invitations && <IssuerManageUsersCardList invitations={invitations} />;
}

export function IssuerManageUsersPage() {
  const { t } = useTranslation(`issuers`);
  const { onOpenModal, modals } = useModal();
  const { roles } = useCurrentIssuerActor();
  const isDesktop = useIsDesktop();

  const isAdmin = roles.includes(UserRole.Admin);

  const INVITE_A_USER_BUTTON_WIDTH = isDesktop ? `175` : `full`;

  return (
    <IssuerManageUsersTableProvider>
      <IssuerPortalPageContainer
        header={
          <IssuerPageHeader
            title={t(`manage_users`)}
            actions={
              isAdmin && (
                <Button
                  size="xl"
                  variant="rounded-outline-grey"
                  w={INVITE_A_USER_BUTTON_WIDTH}
                  maxW="none"
                  onClick={onOpenModal(modals.issuerInviteUser())}
                >
                  {t(`invite_a_user`)}
                </Button>
              )
            }
          />
        }
        content={
          <VStack w="full" h="full" alignItems="flex-start" gap={12}>
            {isDesktop ? (
              <IssuerManageUsersPageContent />
            ) : (
              <IssuerManageUsersMobilePageContent />
            )}
          </VStack>
        }
      />
    </IssuerManageUsersTableProvider>
  );
}
