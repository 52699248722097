import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  GridItem,
  HStack,
  SimpleGrid,
  VStack,
  Text,
  Tab,
  TabList,
  Tabs,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

import {
  ISSUER_TRANSACTION_TRANSACTING_PARTIES_CARD_HEIGHT,
  IssuerTransactionTransactingPartiesBuyerBadge,
  IssuerTransactionTransactingPartiesSellerBadge,
} from "./IssuerTransactionTransactingParties";

type IssuerTransactionPageMainPanelHeaderSkeletonProps = {
  index?: number;
};

function IssuerTransactionPageMainPanelHeaderSkeleton({
  index = 0,
}: IssuerTransactionPageMainPanelHeaderSkeletonProps) {
  const { t } = useTranslation(`issuers`);

  return (
    <Card w="full" overflow="hidden" variant="issuer">
      <VStack px={6} py={6} alignItems="flex-start">
        <HStack justifyContent="space-between" w="full">
          <Skeleton h="2rem" w="5.875rem" />
          <Skeleton h="2rem" w="2rem" />
        </HStack>
        <Skeleton h="1.5rem" w="5rem" />
      </VStack>
      <Tabs
        backgroundColor="grey.15"
        variant="black"
        pointerEvents="none"
        cursor="pointer"
        index={index}
      >
        <HStack
          alignItems="center"
          justifyContent="space-between"
          borderTopWidth="1px"
          borderTop="light-grey-border"
        >
          <TabList alignItems="center" opacity={0.5}>
            <Tab px={7} py={5}>
              {t(`details`)}
            </Tab>
            <Tab px={7} py={5}>
              {t(`status`)}
            </Tab>
            <Tab px={7} py={5}>
              {t(`documents`)}
            </Tab>
          </TabList>
        </HStack>
      </Tabs>
    </Card>
  );
}

function IssuerTransactionTransactingPartiesSkeleton() {
  return (
    <SimpleGrid w="full" columns={{ base: 1, md: 2 }} gap={3}>
      <GridItem colSpan={1}>
        <Card
          w="full"
          h={{
            base: `auto`,
            md: ISSUER_TRANSACTION_TRANSACTING_PARTIES_CARD_HEIGHT,
          }}
          borderRadius={{ base: 0, md: 8 }}
          borderRightWidth={{ base: 0, md: 0.5 }}
          borderLeftWidth={{ base: 0, md: 0.5 }}
          boxShadow={{ base: `none`, md: `card` }}
        >
          <CardBody w="full">
            <VStack alignItems="flex-start" gap={5} w="full">
              <IssuerTransactionTransactingPartiesSellerBadge />
              <VStack alignItems="flex-start" w="full">
                <Skeleton h="1.5rem" w="90%" />
                <Skeleton h="1.5rem" w="70%" />
                <Skeleton h="1.5rem" w="50%" />
              </VStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem colSpan={1}>
        <Card
          w="full"
          h={{
            base: `auto`,
            md: ISSUER_TRANSACTION_TRANSACTING_PARTIES_CARD_HEIGHT,
          }}
          variant="issuer"
        >
          <CardBody w="full">
            <VStack alignItems="flex-start" gap={5} w="full">
              <IssuerTransactionTransactingPartiesBuyerBadge />
              <VStack alignItems="flex-start" w="full">
                <Skeleton h="1.5rem" w="90%" />
                <Skeleton h="1.5rem" w="70%" />
                <Skeleton h="1.5rem" w="50%" />
              </VStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>
    </SimpleGrid>
  );
}

function IssuerTransactionTermsCardSkeleton() {
  const { t } = useTranslation(`issuers`);
  return (
    <Card w="full" variant="issuer">
      <CardBody>
        <VStack alignItems="flex-start" gap={2} w="full">
          <Text fontSize={16} fontWeight={500}>
            {t(`terms`)}
          </Text>
          <VStack alignItems="flex-start" gap={2} w="full">
            <HStack justifyContent="space-between" w="full">
              <Text fontSize={14}>{t(`notice_date`)}</Text>
              <Text>
                <Skeleton h="1.5rem" w={32} />
              </Text>
            </HStack>
            <HStack justifyContent="space-between" w="full">
              <Text fontSize={14}>{t(`holding_type`)}</Text>
              <Text>
                <Skeleton h="1.5rem" w={32} />
              </Text>
            </HStack>
            <HStack justifyContent="space-between" w="full">
              <Text fontSize={14}>{t(`transfer_fee`)}</Text>
              <Text>
                <Skeleton h="1.5rem" w={32} />
              </Text>
            </HStack>
            <HStack justifyContent="space-between" w="full">
              <Text fontSize={14}>{t(`number_of_shares`)}</Text>
              <Text>
                <Skeleton h="1.5rem" w={32} />
              </Text>
            </HStack>
            <HStack justifyContent="space-between" w="full">
              <Text fontSize={14}>{t(`price_per_share_pps`)}</Text>
              <Text>
                <Skeleton h="1.5rem" w={32} />
              </Text>
            </HStack>
            <HStack justifyContent="space-between" w="full">
              <Text fontSize={14} fontWeight={500}>
                {t(`purchase_price`)}
              </Text>
              <Text fontWeight={500}>
                <Skeleton h="1.5rem" w={32} />
              </Text>
            </HStack>
          </VStack>
        </VStack>
      </CardBody>
    </Card>
  );
}

function IssuerTransactionStatusCardSkeleton() {
  const { t } = useTranslation(`issuers`);
  return (
    <Card w="full" variant="issuer">
      <CardBody>
        <VStack alignItems="flex-start" gap={6} w="full">
          <Text fontSize={16} fontWeight={500}>
            {t(`transaction_status`)}
          </Text>
          <Skeleton h={200} w="full" />
        </VStack>
      </CardBody>
    </Card>
  );
}

function IssuerTransactionDocumentsCardSkeleton() {
  return (
    <Card w="full" variant="issuer">
      <CardBody>
        <VStack alignItems="flex-start" gap={2} w="full">
          <>
            <Skeleton h="16px" w="30%" />
            <Skeleton h="16px" w="60%" />
          </>
        </VStack>
      </CardBody>
    </Card>
  );
}

export function IssuerTransactionDetailPageSkeleton() {
  return (
    <>
      <IssuerTransactionPageMainPanelHeaderSkeleton />
      <IssuerTransactionTransactingPartiesSkeleton />
      <IssuerTransactionTermsCardSkeleton />
    </>
  );
}

export function IssuerTransactionStatusPageSkeleton() {
  return (
    <>
      <IssuerTransactionPageMainPanelHeaderSkeleton index={1} />
      <IssuerTransactionStatusCardSkeleton />
    </>
  );
}

export function IssuerTransactionDocumentsPageSkeleton() {
  return (
    <>
      <IssuerTransactionPageMainPanelHeaderSkeleton index={2} />
      <IssuerTransactionDocumentsCardSkeleton />
    </>
  );
}
