import { ParsedUrlQuery } from "node:querystring";

export const ISSUER_PORTAL_ROUTES = {
  ISSUER_ACCOUNT_DETAILS: `/issuer/account/details`,
  ISSUER_ACCOUNT_SECURITY: `/issuer/account/security`,
  ISSUER_ROOT: `/issuer`,
  ISSUER_PORTAL_HOME_PAGE: `/issuer/transactions`,
  ISSUER_PROFILE: `/issuer/profile`,
  ISSUER_TEMPLATES: `/issuer/templates`,
  ISSUER_TRANSACTIONS: `/issuer/transactions`,
  ISSUER_USERS: `/issuer/users`,
  ISSUER_SETUP_MFA: `/issuer/account/setup-mfa`,
};

export const getIssuerTransactionDetailRoute = (
  transactionId: string,
  queryParams?: ParsedUrlQuery,
) => {
  const formattedQueryParams = queryParams
    ? `?${new URLSearchParams(queryParams as Record<string, string>)}`
    : ``;
  return `/issuer/transactions/${transactionId}/details${formattedQueryParams}`;
};

export const ROUTES = {
  ACCOUNT_REQUEST_UPDATED_EMAIL_VERIFICATION: `/account/request-updated-email-verification`,
  ACCOUNT_PASSWORD_UPDATE_CONFIRMED: `/account/password-update-confirmed`,
  ACCOUNT_SETTINGS_CONTACT: `/account/settings/contact`,
  ACCOUNT_SETTINGS_CHANGE_EMAIL: `/account/settings/change-email`,
  DASHBOARD: `/dashboard`,
  DEFAULT_LOGIN: `/login`,
  FORGOT_PASSWORD: `/forgot-password`,
  FRONTEGG_LOGIN: `/account/login`,
  FRONTEGG_LOGOUT: `/account/logout`,
  INDEX: `/`,
  REQUEST_EMAIL_VERIFICATION: `/request-email-verification`,
  SETUP_MFA: `/account/setup-mfa`,
  WELCOME: `/welcome`,
  ...ISSUER_PORTAL_ROUTES,
};
