import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import {
  IssuerInvitationFragment,
  IssuerInvitationUsersDocument,
  useCancelIssuerInvitationMutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";

type IssuersCancelInvitationModalProps = {
  invitation: IssuerInvitationFragment;
};

export function IssuersCancelInvitationModal({
  invitation: { id, email },
}: IssuersCancelInvitationModalProps) {
  const { t } = useTranslation(`issuers`);
  const { closeModal } = useModal();
  const { successToast } = useCustomToast();

  const [cancelInvitation, { loading }] = useCancelIssuerInvitationMutation({
    refetchQueries: [IssuerInvitationUsersDocument],
  });

  const onSubmit = useCallback(async () => {
    const { data } = await cancelInvitation({ variables: { id } });

    if (data?.cancelInvitation) {
      closeModal();
      successToast(t(`cancel_invitation_success`));
    }
  }, [id]);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`cancel_invitation_question`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          <Text>{t(`cancel_invitation_confirmation`, { email })}</Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            sentryLabel="[IssuersCancelInvitation/Submit]"
            submitText={t(`confirm`)}
            onClick={onSubmit}
            isLoading={loading}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
}
