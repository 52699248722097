import { sentenceCase } from "change-case";
import { useTranslation } from "react-i18next";

import {
  Button,
  Text,
  VStack,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalBody,
} from "@chakra-ui/react";

import { HiiveModalFooter, HiiveModalHeader } from "@/components/common";
import {
  ExecutionConfirmTransactionModificationTaskData,
  TransactionExecutionPageTransactionByIdDocument,
  useCompleteExecutionTaskMutation,
  ExecutionConfirmTransactionModificationApprovalStatus,
} from "@/gql";
import { useMutationWithError } from "@/hooks";

const ConfirmTransactionModificationAcceptAction = ({
  taskId,
  data,
  companyName,
  transferMethod,
}: {
  readonly taskId: string;
  readonly data: ExecutionConfirmTransactionModificationTaskData;
  readonly companyName: string;
  readonly transferMethod: string;
}) => {
  const { t } = useTranslation(`execution`);

  const { numShares: newShares, pricePerShare: newPricePerShare } = data;

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [completeTask] = useMutationWithError(
    useCompleteExecutionTaskMutation({
      refetchQueries: [TransactionExecutionPageTransactionByIdDocument],
    }),
    `completeExecutionTask`,
  );

  const handleComplete = () => {
    completeTask({
      variables: {
        taskId,
        input: {
          confirmTransactionModification: {
            approvalStatus:
              ExecutionConfirmTransactionModificationApprovalStatus.Approved,
          },
        },
      },
    });
  };

  return (
    <>
      <Button variant="rounded-solid-grey" size="xl" onClick={onOpen}>
        {t(`accept`)}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <HiiveModalHeader closeModal={onClose}>
            <Text>{t(`accept_proposed_modified_transaction`)}</Text>
          </HiiveModalHeader>
          <ModalBody>
            <VStack spacing={4} alignItems="start">
              <Text textStyle="heading-2xl">
                {t(`accept_proposed_modified_transaction_title`)}
              </Text>
              <Text>
                {t(`accept_proposed_modified_transaction_description`)}
              </Text>
              <Text>
                {t(`proposed_update`, {
                  shares: newShares,
                  companyName,
                  price: newPricePerShare,
                  type: sentenceCase(transferMethod),
                })}
              </Text>
            </VStack>
          </ModalBody>
          <HiiveModalFooter>
            <Button onClick={onClose} size="xl" variant="rounded-outline-grey">
              {t(`cancel`)}
            </Button>
            <Button
              onClick={handleComplete}
              variant="rounded-solid-grey"
              size="xl"
            >
              {t(`confirm`)}
            </Button>
          </HiiveModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmTransactionModificationAcceptAction;
