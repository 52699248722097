import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Text, VStack } from "@chakra-ui/react";

import {
  IssuerTableHeader,
  SortableTableHeader,
  TooltipTableCell,
} from "@/components/issuers";
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaders,
  TableRow,
  TableRows,
} from "@/components/table";
import { IssuerStage, IssuerTransactionSortField } from "@/gql";
import {
  formatPricePerShare,
  formatShares,
  getIssuerTransactionDetailRoute,
  shareTypeToString,
} from "@/utils";

import { IssuerTransactionsEmptyState } from "./IssuerTransactionsEmptyState";
import {
  IssuerTransactionsTableContext,
  NonNullableTransactionWorkflowEdge,
} from "./IssuerTransactionsProvider";
import { IssuerTransactionsSearchBar } from "./IssuerTransactionsSearchBar";
import { IssuerTransactionsStageDescription } from "./IssuerTransactionsStageDescription";
import styles from "./IssuerTransactionsTable.module.css";
import {
  StageCell,
  StageDateTableCell,
  StageDateTableHeader,
} from "./IssuerTransactionsTableCells";
import { IssuerTransactionsTablePaginator } from "./IssuerTransactionsTablePaginator";
import { IssuerTransactionsTableSkeleton } from "./IssuerTransactionsTableSkeleton";
import { RofrIcon } from "./RofrIcon";
import { useQueryParams } from "./useQueryParams";

const IssuerTransactionsEmptyTableRow = () => {
  const { t } = useTranslation(`issuers`);

  return (
    <TableRow bg="white" h="300px">
      <TableCell colSpan={9} borderColor="grey.200" borderWidth="1px">
        <VStack spacing={4}>
          <Text textStyle="heading-lg">{t(`no_results`)}</Text>
          <Text>{t(`no_results_description`)}</Text>
        </VStack>
      </TableCell>
    </TableRow>
  );
};

interface IssuerTransactionsTableRowsProps {
  readonly transactions: NonNullableTransactionWorkflowEdge[];
}

const IssuerTransactionsTableRows = ({
  transactions,
}: IssuerTransactionsTableRowsProps) => {
  const { push } = useRouter();
  const { filterBy } = useContext(IssuerTransactionsTableContext);
  const { queryParams } = useQueryParams();

  const getTransactionDetailPath = useCallback(
    (transactionId: string) =>
      getIssuerTransactionDetailRoute(transactionId, queryParams),
    [queryParams],
  );

  const { stage } = filterBy ?? {};

  return (
    <TableRows>
      {transactions.map((edge) => {
        const { node: transaction } = edge;
        const { isRofr, workflow } = transaction;

        const transactionDetailPath = getTransactionDetailPath(transaction.id);

        return (
          <TableRow
            className={styles[`table-row`]}
            key={transaction.id}
            onClick={() => push(transactionDetailPath)}
          >
            <TooltipTableCell label={transaction.bid.displayId} shouldWrapText>
              {transaction.bid.displayId}
            </TooltipTableCell>
            {stage !== IssuerStage.InReview && (
              <TableCell>{isRofr ? <RofrIcon /> : `-`}</TableCell>
            )}
            <TooltipTableCell
              label={transaction.sellerEntity?.legalName ?? `-`}
              shouldWrapText
            >
              {transaction.sellerEntity?.legalName ?? `-`}
            </TooltipTableCell>
            <TooltipTableCell
              label={transaction.buyerEntity?.legalName ?? `-`}
              shouldWrapText
            >
              {transaction.buyerEntity?.legalName ?? `-`}
            </TooltipTableCell>
            <TableCell>
              {shareTypeToString(transaction.bid.listing.shareTypeV2)}
            </TableCell>
            <StageDateTableCell stage={stage} workflow={workflow} />
            {!stage && <StageCell stage={transaction.issuerStage} />}
            <TableCell textAlign="right">
              <Text fontWeight={500}>
                {formatShares(transaction.numShares)}
              </Text>
            </TableCell>
            <TableCell textAlign="right">
              <Text fontWeight={500}>
                {formatPricePerShare(transaction.pricePerShare)}
              </Text>
            </TableCell>
          </TableRow>
        );
      })}
    </TableRows>
  );
};

interface IssuerTransactionsTableContentProps {
  readonly transactions: NonNullableTransactionWorkflowEdge[];
}

const IssuerTransactionsTableContent = ({
  transactions,
}: IssuerTransactionsTableContentProps) => {
  const { t } = useTranslation(`issuers`);
  const { filterBy, totalCount } = useContext(IssuerTransactionsTableContext);

  const { stage } = filterBy ?? {};

  return (
    <VStack w="full" spacing={8} alignItems="flex-start">
      <IssuerTransactionsStageDescription />
      <IssuerTransactionsSearchBar />
      <Table>
        <TableHeaders>
          <SortableTableHeader
            field={IssuerTransactionSortField.DisplayId}
            name={t(`id`)}
          />
          {stage !== IssuerStage.InReview && (
            <IssuerTableHeader
              name={t(`rofr`)}
              toolTipLabel={t(`right_of_first_refusal`)}
            />
          )}
          <SortableTableHeader
            field={IssuerTransactionSortField.Seller}
            name={t(`seller`)}
          />
          <SortableTableHeader
            field={IssuerTransactionSortField.Buyer}
            name={t(`buyer`)}
          />
          <TableHeader>{t(`holding_type`)}</TableHeader>
          <StageDateTableHeader stage={stage} />
          {!stage && <TableHeader>{t(`stage`)}</TableHeader>}
          <SortableTableHeader
            field={IssuerTransactionSortField.NumShares}
            name={t(`no_of_shares`)}
            rightAlign
          />
          <SortableTableHeader
            field={IssuerTransactionSortField.PricePerShare}
            name={t(`pps`)}
            rightAlign
            toolTipLabel={t(`price_per_share`)}
          />
        </TableHeaders>
        {totalCount > 0 ? (
          <IssuerTransactionsTableRows transactions={transactions} />
        ) : (
          <IssuerTransactionsEmptyTableRow />
        )}
      </Table>
      {totalCount > 0 && <IssuerTransactionsTablePaginator />}
    </VStack>
  );
};

export const IssuerTransactionsTable = () => {
  const { filterBy, loading, totalCount, transactions } = useContext(
    IssuerTransactionsTableContext,
  );

  const { searchText } = filterBy ?? {};

  if (loading) {
    return <IssuerTransactionsTableSkeleton />;
  }

  const shouldShowEmptyState = !searchText && totalCount === 0;

  return shouldShowEmptyState ? (
    <IssuerTransactionsEmptyState />
  ) : (
    <IssuerTransactionsTableContent transactions={transactions} />
  );
};
