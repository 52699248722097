import {
  loadModernTreasury,
  EmbeddableFlow,
  EmbeddableFlowError,
  EmbeddableFlowCreateOptions,
} from "@modern-treasury/modern-treasury-js";
import { useState } from "react";

import useColors from "./useColors";

type useModernTreasuryEmbeddableFlowProps = {
  readonly onFlowSuccess: () => void;
  readonly onFlowError: (error: EmbeddableFlowError) => void;
  readonly clientToken: string;
};

export default function useModernTreasuryExecutionEmbeddableFlow({
  onFlowSuccess,
  onFlowError,
  clientToken,
}: useModernTreasuryEmbeddableFlowProps) {
  const [embeddableFlow, setEmbeddableFlow] = useState<EmbeddableFlow>();
  const [grey900] = useColors([`grey.900`]);

  const createEmbeddableFlow = async (): Promise<
    EmbeddableFlow | undefined
  > => {
    const modernTreasuryAPI = await loadModernTreasury(
      process.env.NEXT_PUBLIC_MODERN_TREASURY_PUBLISHABLE_KEY || ``,
    );

    const mtAPIOptions: EmbeddableFlowCreateOptions = {
      clientToken,
      onSuccess: onFlowSuccess,
      onError: onFlowError,
      variables: { colorPrimary: grey900 },
    };

    const embeddableFlow: EmbeddableFlow | undefined =
      modernTreasuryAPI?.createEmbeddableFlow(mtAPIOptions);

    setEmbeddableFlow(embeddableFlow);

    return embeddableFlow;
  };

  const mountEmbeddableFlow = async (containerId: string) => {
    const embeddableFlow = await createEmbeddableFlow();
    embeddableFlow?.mount(containerId);
  };

  const unmountEmbeddableFlow = () => {
    embeddableFlow?.unmount();
  };

  return {
    mountEmbeddableFlow,
    unmountEmbeddableFlow,
  };
}
