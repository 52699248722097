import { Star } from "@phosphor-icons/react";
import { MouseEvent, useMemo } from "react";
import { match } from "ts-pattern";

import { ButtonProps, IconButton } from "@chakra-ui/react";

import { HiiveButton, WithQuery } from "@/components/common";
import {
  Company,
  CompanyWatchlistActorDocument,
  CompanyWatchlistCompanyFragment,
  useAddToWatchlistMutation,
  useCompanyWatchlistActorQuery,
  useRemoveFromWatchlistMutation,
  WatchlistCompanySearchListCompaniesDocument,
} from "@/gql";
import { useMutationWithError } from "@/hooks";

interface WatchlistButtonProps extends ButtonProps {
  readonly companyId: string;
  readonly isCollapsed?: boolean;
}

const WatchlistButton = ({
  companyId,
  watchlist,
  isCollapsed,
  ...rest
}: WatchlistButtonProps & {
  readonly watchlist: readonly CompanyWatchlistCompanyFragment[];
}) => {
  const as = isCollapsed ? `IconButton` : `Button`;

  const [removeFromWatchlistMutation, isRemovingFromWatchlist] =
    useMutationWithError(
      useRemoveFromWatchlistMutation(),
      `removeFromWatchlist`,
    );
  const [addToWatchlistMutation, isAddingToWatchlist] = useMutationWithError(
    useAddToWatchlistMutation(),
    `addToWatchlist`,
  );

  const isWatchingCompany = useMemo(
    () => watchlist.some((c: Company) => c.id === companyId),
    [companyId, watchlist],
  );

  const addToWatchlist = (e?: MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    addToWatchlistMutation({
      variables: { companyId },
      refetchQueries: [CompanyWatchlistActorDocument],
      awaitRefetchQueries: true,
    });
  };

  const removeFromWatchlist = (e?: MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    removeFromWatchlistMutation({
      variables: { companyId },
      refetchQueries: [
        CompanyWatchlistActorDocument,
        WatchlistCompanySearchListCompaniesDocument,
      ],
      awaitRefetchQueries: true,
    });
  };

  return match({ isWatchingCompany, as })
    .with({ isWatchingCompany: true, as: `Button` }, () => (
      <HiiveButton
        variant="boxed-salmon"
        leftIcon={<Star size={14} weight="fill" />}
        onClick={removeFromWatchlist}
        isLoading={isRemovingFromWatchlist}
        sentryLabel="[WatchlistButton] Remove"
        {...rest}
      >
        Watching
      </HiiveButton>
    ))
    .with({ isWatchingCompany: false, as: `Button` }, () => (
      <HiiveButton
        leftIcon={<Star size={14} />}
        variant="boxed-salmon"
        onClick={addToWatchlist}
        isLoading={isAddingToWatchlist}
        sentryLabel="[WatchlistButton] Add"
        {...rest}
      >
        Add to Watchlist
      </HiiveButton>
    ))
    .with({ isWatchingCompany: true, as: `IconButton` }, () => (
      <IconButton
        aria-label="Watching"
        variant="boxed-salmon"
        icon={<Star size={14} weight="fill" />}
        onClick={removeFromWatchlist}
        isLoading={isRemovingFromWatchlist}
        {...rest}
      />
    ))
    .with({ isWatchingCompany: false, as: `IconButton` }, () => (
      <IconButton
        aria-label="Add to Watchlist"
        icon={<Star size={14} />}
        variant="boxed-salmon"
        onClick={addToWatchlist}
        isLoading={isAddingToWatchlist}
        {...rest}
      />
    ))
    .run();
};

const WatchlistButtonWrapper = (props: WatchlistButtonProps) => (
  <WithQuery query={useCompanyWatchlistActorQuery()} hideLoadingSpinner>
    {({ data: { currentActor } }) => (
      <WatchlistButton {...props} watchlist={currentActor.watchlist} />
    )}
  </WithQuery>
);

export default WatchlistButtonWrapper;
