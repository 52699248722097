import { yupResolver } from "@hookform/resolvers/yup";
import { WarningCircle } from "@phosphor-icons/react";
import i18next from "i18next";
import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import { Link, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  CallOut,
  HiiveBackButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { RELOAD_DELAY_MS } from "@/components/frontegg";
import { ModalKind } from "@/components/providers";
import { FormTextInput } from "@/components/react-hook-form";
import { useModal } from "@/hooks";
import {
  MfaToastKeys,
  useDisableAllMfa,
  VALID_TOAST_FLAG,
} from "@/hooks/frontegg";

const getValidationSchema = (t: typeof i18next.t) =>
  Yup.object({
    recoveryCode: Yup.string().required(t(`recovery_code_is_required`)),
  });

interface DisableAllMfaModalProps {
  readonly email: string;
}

const DisableAllMfaModal = ({ email }: DisableAllMfaModalProps) => {
  const { t } = useTranslation();
  const { reload } = useRouter();
  const { closeModal, onOpenModal, modals, previousModal } = useModal();

  const [disableAllMfa, { loading, error, success }] = useDisableAllMfa({
    onSuccess: () => {
      sessionStorage.setItem(
        MfaToastKeys.showAllMfaRemovedToast,
        VALID_TOAST_FLAG,
      );
      setTimeout(() => reload(), RELOAD_DELAY_MS);
    },
  });

  const validationSchema = getValidationSchema(t);

  type DisableAllMfaFormValues = Yup.InferType<typeof validationSchema>;

  const { control, handleSubmit } = useForm<DisableAllMfaFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      recoveryCode: ``,
    },
  });

  const onSubmit: SubmitHandler<DisableAllMfaFormValues> = useCallback(
    async ({ recoveryCode }) => {
      await disableAllMfa({ email, recoveryCode });
    },
    [disableAllMfa, email],
  );

  const handleOnBack = () => {
    if (!previousModal) {
      closeModal();
      return;
    }

    if (previousModal.kind === ModalKind.DisableAppMfa) {
      const { skipCodeChallenge } = previousModal;
      onOpenModal(modals.disableAppMfa(skipCodeChallenge, email))();
    } else if (previousModal.kind === ModalKind.DisableSmsMfa) {
      const { lastFourDigits, otcToken, skipCodeChallenge } = previousModal;
      onOpenModal(
        modals.disableSmsMfa(
          skipCodeChallenge,
          lastFourDigits,
          email,
          otcToken,
        ),
      )();
    }
  };

  return (
    <HiiveModalContentWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HiiveModalHeader>
          <Text>{t(`verify_with_recovery_code`)}</Text>
        </HiiveModalHeader>
        <ModalBody>
          <VStack alignItems="flex-start" gap={6}>
            <Text>{t(`verify_with_recovery_code_description_1`)}</Text>
            <Text>{t(`verify_with_recovery_code_description_2`)}</Text>
            <FormTextInput
              control={control}
              label={t(`recovery_code`)}
              name="recoveryCode"
              type="number"
              placeholder={t(`enter_recovery_code`)}
            />
            {error && (
              <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
                <WarningCircle color="#C4383A" size={14} weight="fill" />
                <Text textStyle="text-sm">{t(`incorrect_code`)}</Text>
              </CallOut>
            )}
            <Text>
              <Trans
                i18nKey="account_recovery_help_text"
                components={{
                  a: (
                    <Link
                      textDecoration="underline"
                      href="mailto:accounts@hiive.com"
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                }}
              />
            </Text>
          </VStack>
        </ModalBody>
        <HiiveModalFooter>
          <ButtonFooterWrapper>
            <HiiveBackButton
              sentryLabel="[DisableAllMfa/Back]"
              onClick={handleOnBack}
              isDisabled={success || loading}
            >
              {t(`back`)}
            </HiiveBackButton>
            <HiiveSubmitButton
              isLoading={loading || success}
              sentryLabel="[DisableAllMfa/Submit]"
              submitText={t(`verify`)}
              type="submit"
            />
          </ButtonFooterWrapper>
        </HiiveModalFooter>
      </form>
    </HiiveModalContentWrapper>
  );
};

export default DisableAllMfaModal;
