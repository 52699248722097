import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Link, Text, VStack } from "@chakra-ui/react";

import {
  OnboardingContainerV2,
  SlideAnimation,
} from "@/components/onboarding-v2";

const BrokerAwaitingApprovalPage = () => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `awaiting_approval`,
  });

  return (
    <OnboardingContainerV2
      canGoBack={false}
      metaTitle={t(`welcome_to_hiive_connect`)}
    >
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`welcome_to_hiive_connect`)}
          </Text>
        </VStack>
        <SlideAnimation>
          <Card>
            <CardBody>
              <Flex direction="column" gap={7}>
                <Text>
                  {t(`we_are_excited_to_welcome_you_to_hiive_connect`)}
                </Text>

                <Flex direction="column" gap={4}>
                  <Text>
                    {t(`your_information_has_been_submitted_to_compliance`)}
                  </Text>
                  <Text>
                    <Trans
                      i18nKey="awaiting_approval.if_you_have_any_questions_about_the_status_of_your_registration"
                      ns="onboarding"
                      components={{
                        mailto: (
                          <Link
                            variant="chunky"
                            textDecoration="underline"
                            href="mailto:hiiveconnect@hiive.com"
                          />
                        ),
                      }}
                    />
                  </Text>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
    </OnboardingContainerV2>
  );
};

export default BrokerAwaitingApprovalPage;
