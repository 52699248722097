import { useTranslation } from "react-i18next";

import {
  ExecutionCondition,
  ExecutionConfirmTransactionModificationApprovalStatus,
  ExecutionConfirmTransactionModificationTaskDataAcknowledgment,
  ExecutionWorkflowCondition,
} from "@/gql";

export enum TransactionModificationStatus {
  Approved = `APPROVED`,
  Rejected = `REJECTED`,
  Pending = `PENDING`,
}

const MILESTONE_OVERRIDE_CONDITIONS = [
  ExecutionWorkflowCondition.IssuerApprovalDeclined,
  ExecutionWorkflowCondition.Rofr,
];

export const hasIssuerDeclinedWorkflow = (
  conditions: Pick<ExecutionCondition, "condition">[],
) =>
  conditions.some(
    ({ condition }) =>
      condition === ExecutionWorkflowCondition.IssuerApprovalDeclined,
  );

export const hasIssuerROFRedWorkflow = (
  conditions: Pick<ExecutionCondition, "condition">[],
) =>
  conditions.some(
    ({ condition }) => condition === ExecutionWorkflowCondition.Rofr,
  );

export const hasCancelledWorkflow = (
  conditions: Pick<ExecutionCondition, "condition">[],
) =>
  conditions.some(
    ({ condition }) => condition === ExecutionWorkflowCondition.Cancelled,
  );

export const useTransactionModificationStatusTitle = () => {
  const { t } = useTranslation(`execution`);

  return {
    [TransactionModificationStatus.Approved]: {
      title: t(`approved`),
      variant: `status-active`,
    },
    [TransactionModificationStatus.Rejected]: {
      title: t(`rejected`),
      variant: `status-rejected`,
    },
    [TransactionModificationStatus.Pending]: {
      title: t(`pending`),
      variant: `status-pending`,
    },
  };
};

export const getTransactionModificationStatus = (
  acknowledgments: ExecutionConfirmTransactionModificationTaskDataAcknowledgment[],
): TransactionModificationStatus => {
  if (
    acknowledgments.some(
      ({ status }) =>
        status ===
        ExecutionConfirmTransactionModificationApprovalStatus.Declined,
    )
  ) {
    return TransactionModificationStatus.Rejected;
  }

  if (acknowledgments.every(({ status }) => status === `APPROVED`)) {
    return TransactionModificationStatus.Approved;
  }

  return TransactionModificationStatus.Pending;
};
export const hasMilestoneCondition = (
  conditions: Pick<ExecutionCondition, "condition">[],
) =>
  conditions.some(({ condition }) =>
    MILESTONE_OVERRIDE_CONDITIONS.includes(condition),
  );
