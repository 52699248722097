const USQIBQuestionKey = `us-institutional-qib-v3`;
const USOtherAccreditationCriteriaQuestionKey = `us-institutional-other-criteria-v3`;

const CAOtherAccreditationCriteriaQuestionKey = `ca-institutional-other-criteria-v3`;

const SupportedInstitutionCountryCodes = [`US`, `CA`, `CH`, `UK`, `IN`];
const OtherAccreditationCriteriaQuestionKeys = [`in-individual-other-v3`];

export {
  USQIBQuestionKey,
  USOtherAccreditationCriteriaQuestionKey,
  SupportedInstitutionCountryCodes,
  CAOtherAccreditationCriteriaQuestionKey,
  OtherAccreditationCriteriaQuestionKeys,
};
