import { FormikValues } from "formik";
import { useContext, useEffect } from "react";

import { StepValidatorProps } from "@/components/form";

const useStepValidator = <
  TStepKeys extends string,
  TFormValues extends FormikValues,
  TMutationData,
>({
  stepKey,
  validator,
  values,
  Context,
}: StepValidatorProps<TStepKeys, TFormValues, TMutationData>) => {
  const { registerValidator } = useContext(Context);

  useEffect(() => {
    registerValidator({
      stepKey,
      validator,
    });
    /**
     * Validator with onSuccess / validation schema is always re-registered when the form values change
     * This is the safest way to ensure the validator / onSuccess doesn't go stale. Open to reworking.
     */
  }, [values]);
};

export default useStepValidator;
