import { CurrencyCircleDollar, Wallet } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Badge,
  Card,
  CardBody,
  Text,
  Box,
  VStack,
  HStack,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";

import {
  VeryBasicInstitution,
  IssuerTransactionSellerEntityFragment,
  IssuerTransactionBuyerEntityFragment,
  Maybe,
} from "@/gql";

import { IssuerTransactionContextTransaction } from "./IssuerTransactionProvider";

type IssuerTransactionTransactingPartiesProps = {
  transaction: NonNullable<IssuerTransactionContextTransaction>;
};

type IssuerTransactionTransactingPartiesDetailRowProps = {
  label: string;
  children: ReactNode;
};

type IssuerTransactionTransactingPartiesInstitutionCardDetailsProps = {
  institution: VeryBasicInstitution;
  entity?: Maybe<
    IssuerTransactionSellerEntityFragment | IssuerTransactionBuyerEntityFragment
  >;
};

type IssuerTransactionTransactingPartiesIndividualCardDetailsProps = {
  entity?: Maybe<
    IssuerTransactionSellerEntityFragment | IssuerTransactionBuyerEntityFragment
  >;
};

enum TransactingPartyType {
  Buyer = `buyer`,
  Seller = `seller`,
}

export const ISSUER_TRANSACTION_TRANSACTING_PARTIES_CARD_HEIGHT = 270;

export function IssuerTransactionTransactingPartiesSellerBadge() {
  const { t } = useTranslation(`issuers`);
  return (
    <Badge
      bg="teal.100"
      color="teal.1000"
      borderRadius="md"
      fontWeight={500}
      px={2}
      py={1}
    >
      <HStack gap={1}>
        <Box as={CurrencyCircleDollar} size={20} />
        <Text>{t(`seller`)}</Text>
      </HStack>
    </Badge>
  );
}

export function IssuerTransactionTransactingPartiesBuyerBadge() {
  const { t } = useTranslation(`issuers`);
  return (
    <Badge
      bg="plum.100"
      color="plum.1000"
      borderRadius="md"
      fontWeight={500}
      px={2}
      py={1}
    >
      <HStack gap={1}>
        <Box as={Wallet} size={20} />
        <Text>{t(`buyer`)}</Text>
      </HStack>
    </Badge>
  );
}

function IssuerTransactionTransactingPartiesDetailRow({
  label,
  children,
}: IssuerTransactionTransactingPartiesDetailRowProps) {
  return (
    <VStack alignItems="flex-start" gap={0}>
      <Text textStyle="heading-xs">{label}</Text>
      <Text>{children}</Text>
    </VStack>
  );
}

function IssuerTransactionTransactingPartiesIndividualCardDetails({
  entity,
}: IssuerTransactionTransactingPartiesIndividualCardDetailsProps) {
  const { t } = useTranslation(`issuers`);

  const entityLegalName = entity?.legalName;
  const entityJurisdictionOfFormation =
    entity?.jurisdictionOfFormation?.fullName;

  return (
    <VStack alignItems="flex-start" gap={4}>
      <IssuerTransactionTransactingPartiesDetailRow label={t(`name`)}>
        {entityLegalName ? `${entityLegalName} (${t(`individual`)})` : `–`}
      </IssuerTransactionTransactingPartiesDetailRow>
      <IssuerTransactionTransactingPartiesDetailRow label={t(`entity_country`)}>
        {entityJurisdictionOfFormation ?? `–`}
      </IssuerTransactionTransactingPartiesDetailRow>
    </VStack>
  );
}

function IssuerTransactionTransactingPartiesInstitutionCardDetails({
  institution: { legalName },
  entity,
  transactingPartyType,
}: IssuerTransactionTransactingPartiesInstitutionCardDetailsProps & {
  transactingPartyType: TransactingPartyType;
}) {
  const { t } = useTranslation(`issuers`);

  const entityLegalName = entity?.legalName;
  const entityJurisdictionOfFormation =
    entity?.jurisdictionOfFormation?.fullName;

  const entityLabel = match(transactingPartyType)
    .with(TransactingPartyType.Buyer, () => t(`purchasing_entity`))
    .with(TransactingPartyType.Seller, () => t(`selling_entity`))
    .exhaustive();

  return (
    <VStack alignItems="flex-start" gap={4}>
      <IssuerTransactionTransactingPartiesDetailRow label={t(`institution`)}>
        {legalName}
      </IssuerTransactionTransactingPartiesDetailRow>
      <IssuerTransactionTransactingPartiesDetailRow label={entityLabel}>
        {entityLegalName ? `${entityLegalName} (${t(`corporation`)})` : `–`}
      </IssuerTransactionTransactingPartiesDetailRow>
      <IssuerTransactionTransactingPartiesDetailRow label={t(`entity_country`)}>
        {entityJurisdictionOfFormation ?? `–`}
      </IssuerTransactionTransactingPartiesDetailRow>
    </VStack>
  );
}

function IssuerTransactionTransactingPartiesSellerCard({
  transaction: { sellerInstitution, sellerEntity },
}: IssuerTransactionTransactingPartiesProps) {
  return (
    <Card
      variant="issuer"
      h={{
        base: `auto`,
        md: ISSUER_TRANSACTION_TRANSACTING_PARTIES_CARD_HEIGHT,
      }}
    >
      <CardBody>
        <VStack alignItems="flex-start" gap={5}>
          <IssuerTransactionTransactingPartiesSellerBadge />
          <VStack alignItems="flex-start">
            {sellerInstitution ? (
              <IssuerTransactionTransactingPartiesInstitutionCardDetails
                institution={sellerInstitution}
                entity={sellerEntity}
                transactingPartyType={TransactingPartyType.Seller}
              />
            ) : (
              <IssuerTransactionTransactingPartiesIndividualCardDetails
                entity={sellerEntity}
              />
            )}
          </VStack>
        </VStack>
      </CardBody>
    </Card>
  );
}

function IssuerTransactionTransactingPartiesBuyerCard({
  transaction: { buyerInstitution, buyerEntity },
}: IssuerTransactionTransactingPartiesProps) {
  return (
    <Card
      variant="issuer"
      h={{
        base: `auto`,
        md: ISSUER_TRANSACTION_TRANSACTING_PARTIES_CARD_HEIGHT,
      }}
    >
      <CardBody>
        <VStack alignItems="flex-start" gap={5}>
          <IssuerTransactionTransactingPartiesBuyerBadge />
          <VStack alignItems="flex-start">
            {buyerInstitution ? (
              <IssuerTransactionTransactingPartiesInstitutionCardDetails
                institution={buyerInstitution}
                entity={buyerEntity}
                transactingPartyType={TransactingPartyType.Buyer}
              />
            ) : (
              <IssuerTransactionTransactingPartiesIndividualCardDetails
                entity={buyerEntity}
              />
            )}
          </VStack>
        </VStack>
      </CardBody>
    </Card>
  );
}

export function IssuerTransactionTransactingParties({
  transaction,
}: IssuerTransactionTransactingPartiesProps) {
  return (
    <SimpleGrid w="full" columns={{ base: 1, xl: 2 }} gap={3}>
      <GridItem colSpan={1}>
        <IssuerTransactionTransactingPartiesSellerCard
          transaction={transaction}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <IssuerTransactionTransactingPartiesBuyerCard
          transaction={transaction}
        />
      </GridItem>
    </SimpleGrid>
  );
}
