import { useEffect } from "react";

import { useRouter } from "next/router";

import { ROUTES } from "@/utils";

// Frontegg's "Forgot Password?" button performs a client-side redirect to this page; therefore Next.js middleware and getServerSideProps are not executed.
// As such, we redirect to the Hiive "Forgot Password" page here inside a useEffect hook.
const RedirectToForgotPassword = () => {
  const { replace } = useRouter();

  useEffect(() => {
    replace(ROUTES.FORGOT_PASSWORD);
  }, [replace]);

  return null;
};

export default RedirectToForgotPassword;
