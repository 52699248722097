import { ReactNode } from "react";

import { Flex, Box, Text } from "@chakra-ui/react";

interface IssuerInfoRowProps {
  readonly children: ReactNode;
  readonly title: string;
}

export const IssuerInfoRow = ({ children, title }: IssuerInfoRowProps) => (
  <Flex py={5} px={6} display="grid" gridTemplateColumns="1fr 1fr" gap={5}>
    <Text textStyle="heading-2xs" color="grey.900">
      {title}
    </Text>
    <Box>{children}</Box>
  </Flex>
);
