import { useMarketplaceLogin } from "@/hooks/featureFlags";
import { ROUTES } from "@/utils";

const useLoginPath = () => {
  const marketplaceLoginEnabled = useMarketplaceLogin();

  return marketplaceLoginEnabled ? ROUTES.FRONTEGG_LOGIN : ROUTES.DEFAULT_LOGIN;
};

export default useLoginPath;
