import { Form } from "formik";
import { AnimatePresence } from "framer-motion";

import { HiiveModalContentWrapper } from "@/components/common";
import { AnimatedModalStep, StepFormikQL } from "@/components/form";
import {
  StandingBidPageStandingBidByIdDocument,
  UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment,
  UnaccreditedSellerTransferMethodInput,
  useUnaccreditedSellerAcceptStandingBidV2Mutation,
} from "@/gql";
import { useStepRouter } from "@/hooks";
import { getNumOfShares } from "@/utils";

import {
  UnaccreditedSellerAcceptStandingBidModal,
  UnaccreditedSellerAcceptStandingBidConfirmationModal,
  UnaccreditedSellerAcceptStandingBidSuccessModal,
  StepKeys,
  stepKeys,
  UnaccreditedSellerAcceptStandingBidSequenceModalFormValues,
  UnaccreditedSellerAcceptStandingBidSequenceModalContext,
} from "./steps";

const createMapVariables =
  (
    standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment,
  ) =>
  (values: UnaccreditedSellerAcceptStandingBidSequenceModalFormValues) => ({
    standingBidId: standingBid.id,
    input: {
      numShares: values.numShares || 0,
      transferMethod: values.transferMethod,
      shareSeries: values.shareSeries,
    },
  });

const createInitialValues = (
  standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment,
) => ({
  numShares: getNumOfShares(standingBid) ?? null,
  transferMethod: UnaccreditedSellerTransferMethodInput.Direct,
  pricePerShare: standingBid.pricePerShare / 100,
  shareSeries:
    standingBid.shareSeriesOptions.length === 1
      ? standingBid.shareSeriesOptions[0]
      : null,
  confirmedEligibilityRequirements: false,
});

interface UnaccreditedSellerAcceptStandingBidSequenceModalProps {
  readonly standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment;
}

const UnaccreditedSellerAcceptStandingBidSequenceModal = ({
  standingBid,
}: UnaccreditedSellerAcceptStandingBidSequenceModalProps) => {
  const mutation = useUnaccreditedSellerAcceptStandingBidV2Mutation({
    refetchQueries: [StandingBidPageStandingBidByIdDocument],
  });

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.acceptStandingBid,
      stepKeys.acceptStandingBidConfirmation,
      stepKeys.acceptStandingBidSuccess,
    ],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  const mapVariables = createMapVariables(standingBid);
  const initialValues = createInitialValues(standingBid);

  return (
    <HiiveModalContentWrapper>
      <StepFormikQL
        stepRouter={stepRouter}
        mutation={mutation}
        mutationNames={[`unaccreditedSellerAcceptStandingBidV2`]}
        mapVariables={mapVariables}
        initialValues={initialValues}
        context={UnaccreditedSellerAcceptStandingBidSequenceModalContext}
      >
        {({ formikProps }) => (
          <Form>
            <AnimatePresence mode="wait" initial={false}>
              {currentStepKey === stepKeys.acceptStandingBid && (
                <AnimatedModalStep key={stepKeys.acceptStandingBid}>
                  <UnaccreditedSellerAcceptStandingBidModal
                    stepRouter={stepRouter}
                    standingBid={standingBid}
                    {...formikProps}
                  />
                </AnimatedModalStep>
              )}
              {currentStepKey === stepKeys.acceptStandingBidConfirmation && (
                <AnimatedModalStep key={stepKeys.acceptStandingBidConfirmation}>
                  <UnaccreditedSellerAcceptStandingBidConfirmationModal
                    stepRouter={stepRouter}
                    standingBid={standingBid}
                    {...formikProps}
                  />
                </AnimatedModalStep>
              )}
              {currentStepKey === stepKeys.acceptStandingBidSuccess && (
                <AnimatedModalStep key={stepKeys.acceptStandingBidSuccess}>
                  <UnaccreditedSellerAcceptStandingBidSuccessModal
                    stepRouter={stepRouter}
                    standingBid={standingBid}
                    {...formikProps}
                  />
                </AnimatedModalStep>
              )}
            </AnimatePresence>
          </Form>
        )}
      </StepFormikQL>
    </HiiveModalContentWrapper>
  );
};

export default UnaccreditedSellerAcceptStandingBidSequenceModal;
