import { useAuthUserOrNull } from "@frontegg/nextjs";
import { useEffect, useCallback } from "react";

import { useRouter } from "next/router";

import { useToken, useTokenExchange } from "@/hooks";
import { useMarketplaceConfiguration } from "@/hooks/featureFlags";
import { ROUTES } from "@/utils";

export default function useAuthRedirectionEffect(redirect?: string) {
  const { replace } = useRouter();
  const hiiveToken = useToken();
  const fronteggUser = useAuthUserOrNull() ?? { accessToken: undefined };
  const { accessToken: fronteggToken } = fronteggUser;

  const marketplaceConfigurationEnabled = useMarketplaceConfiguration();

  const { exchangeToken, fetching: fetchingSessionToken } = useTokenExchange({
    onError: () => replace(ROUTES.FRONTEGG_LOGOUT),
  });

  const isLoading = fronteggToken || fetchingSessionToken;
  const shouldExchangeToken =
    fronteggUser &&
    !marketplaceConfigurationEnabled &&
    !hiiveToken &&
    !fetchingSessionToken;

  const handleRedirection = useCallback(async () => {
    if (shouldExchangeToken) {
      return exchangeToken(fronteggToken ?? ``);
    }

    if (!fronteggUser) {
      return replace(ROUTES.FRONTEGG_LOGIN);
    }

    return replace(redirect ? (redirect as string) : ROUTES.DASHBOARD);
  }, [
    exchangeToken,
    fronteggUser,
    replace,
    fronteggToken,
    shouldExchangeToken,
    redirect,
  ]);

  useEffect(() => {
    handleRedirection();
  }, [handleRedirection]);

  return { isLoading };
}
