/* eslint-disable functional/immutable-data */
import { FetchResult } from "@apollo/client";

import {
  CreateFileUploadMutation,
  FileUpload,
  FormField,
  useCreateFileUploadMutation,
} from "@/gql";

type OnProgressUpdate = (progress: number) => void;

const post = (
  url: string,
  data: FormData,
  onProgressUpdate: OnProgressUpdate,
): Promise<null | Error> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // Monitor upload progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable && onProgressUpdate) {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        onProgressUpdate(percentCompleted);
      }
    };

    // Handle success and error states
    xhr.onload = () => {
      if (xhr.status === 204) {
        resolve(null);
      } else {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(
          xhr.responseText,
          `application/xml`,
        );
        const message = xmlDoc.querySelector(`Message`);
        const error = new Error(message?.textContent || `File upload failed`);
        reject(error);
      }
    };

    xhr.onerror = () =>
      reject(new Error(`File upload failed due to a network error`));

    // Send the request
    xhr.open(`POST`, url);
    xhr.send(data);
  });

// `file` field must be the last field in the form
// https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
const constructForm = (file: File, formFields: FormField[]) => {
  const form = new FormData();
  formFields.forEach(({ name, value }) => form.append(name, value));
  form.append(`file`, file);
  return form;
};

const ACCEPTED_FILE_TYPES = [
  `application/pdf`,
  `application/msword`,
  `application/vnd.openxmlformats-officedocument.wordprocessingml.document`,
  `image/jpeg`,
  `image/heic`,
  `image/heif`,
  `image/png`,
];

type Policy = {
  url: string;
  objectKey: string;
  formFields: FormField[];
};

type OnUploadComplete = (
  fileUpload: Pick<FileUpload, "id" | "filename" | "url">,
) => void;

interface UploadCallbacks {
  onUploadComplete: OnUploadComplete;
  onProgressUpdate: OnProgressUpdate;
  onError: (err: Error) => void;
}

interface UseS3SecureFileUpload {
  readonly upload: (
    file: File,
    policy: Policy,
    callbacks: UploadCallbacks,
  ) => void;
}

const useS3SecureFileUpload = (): UseS3SecureFileUpload => {
  const [createFileUploadMutation] = useCreateFileUploadMutation();

  const uploadFileToS3 = (
    file: File,
    policy: Policy,
    onProgressUpdate: OnProgressUpdate,
  ) => {
    const { formFields, url } = policy;

    const form = constructForm(file, formFields);

    return post(url, form, (progress: number) => {
      if (onProgressUpdate) onProgressUpdate(progress);
    });
  };

  const createFileUpload = (file: File, objectKey: string) => () => {
    const input = {
      filename: file.name,
      contentType: file.type,
      s3ObjectRef: objectKey,
    };

    return createFileUploadMutation({ variables: { input } });
  };

  const onSuccess =
    (onUploadComplete: OnUploadComplete) =>
    (resp: FetchResult<CreateFileUploadMutation>) => {
      const fileUpload = resp.data?.createFileUpload.fileUpload;

      if (!fileUpload) throw new Error(`Failed to create file upload`);

      onUploadComplete(fileUpload);
    };

  const upload = (file: File, policy: Policy, callbacks: UploadCallbacks) => {
    if (!ACCEPTED_FILE_TYPES.includes(file.type))
      throw new Error(`Invalid file type`);

    const { onUploadComplete, onError, onProgressUpdate } = callbacks;

    uploadFileToS3(file, policy, onProgressUpdate)
      .then(createFileUpload(file, policy.objectKey))
      .then(onSuccess(onUploadComplete))
      .catch(onError);
  };

  return { upload };
};

export default useS3SecureFileUpload;
