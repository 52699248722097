import { Bank, User } from "@phosphor-icons/react";
import pick from "lodash/fp/pick";
import { ReactNode, useState } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import {
  Button,
  Card,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  VStack,
  Box,
  Center,
  Circle,
  CardFooter,
} from "@chakra-ui/react";

import { HiiveModalFooter, HiiveModalHeader } from "@/components/common";
import { Listbox, QuestionTooltip } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  FormCombobox,
  FormCountryCombobox,
  FormTextInput,
} from "@/components/react-hook-form";
import {
  EntityType,
  ExecutionCollectEntityTaskData,
  ExecutionTaskStatus,
  TransactingEntityItemEntityFragment,
  TransactionExecutionPageTaskFragment,
  UserWithInstitutionFragment,
  InstitutionEntityType,
  TransactionExecutionPageTransactionByIdDocument,
  useCompleteExecutionTaskMutation,
} from "@/gql";
import {
  useCountryList,
  useListbox,
  useMutationWithError,
  useTranslatedEntityNames,
} from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import * as datetime from "@/utils/datetime";

enum PartyType {
  Individual = `Individual`,
  Entity = `Entity`,
}

const EntityLineItem = ({
  entity,
}: {
  readonly entity: TransactingEntityItemEntityFragment;
}) => {
  const { t } = useTranslation();
  const countryList = useCountryList();
  const countryName = countryList.getName(entity.jurisdictionOfFormation?.name);

  return (
    <HStack justify="space-between">
      <HStack>
        <Text textStyle="heading-xs">{entity.legalName}</Text>
        <Text textStyle="text-sm">{countryName}</Text>
      </HStack>
      <Text color="grey.600" textStyle="text-sm">
        {t(`added_date`, {
          date: datetime.format(`Do MMMM, YYYY`, entity.insertedAt),
        })}
      </Text>
    </HStack>
  );
};

const institutionTypeToEntityType = (
  institutionType: InstitutionEntityType,
): EntityType =>
  match(institutionType)
    .with(InstitutionEntityType.Corporation, () => EntityType.Corporation)
    .with(InstitutionEntityType.Llc, () => EntityType.Llc)
    .with(InstitutionEntityType.Partnership, () => EntityType.Partnership)
    .otherwise(() => EntityType.Other);

const SuggestedNoEntitySubCard = ({
  actor,
}: {
  actor: UserWithInstitutionFragment;
}) => {
  const { getFormattedEntityName } = useTranslatedEntityNames();
  const countryList = useCountryList();
  const name = actor.institutionId
    ? actor.institution?.legalName
    : `${actor.firstName} ${actor.lastName}`;

  const countryName = actor.institutionId
    ? actor.institution?.country?.name
    : actor.country?.name;

  const entityType = !!actor.institution?.entityType
    ? institutionTypeToEntityType(actor.institution.entityType)
    : EntityType.Individual;

  return (
    <Card w="full" h={12} variant="flat" py={3} px={4}>
      <HStack color="grey.500" justify="space-between">
        <HStack>
          <Text textStyle="heading-xs">{name}</Text>
          <Text textStyle="text-sm">{countryList.getName(countryName)}</Text>
        </HStack>
        <Text textStyle="heading-xs">{getFormattedEntityName(entityType)}</Text>
      </HStack>
    </Card>
  );
};

const SingleEntitySubCard = ({
  entity,
}: {
  entity: TransactingEntityItemEntityFragment;
}) => (
  <Card w="full" variant="flat" py={3} px={4}>
    <EntityLineItem entity={entity} />
  </Card>
);

const EntitySelector = ({
  availableEntities,
  currentEntityId,
  onEntitySelected,
}: {
  readonly availableEntities: TransactingEntityItemEntityFragment[];
  currentEntityId?: TransactingEntityItemEntityFragment["id"] | null;
  onEntitySelected: (entity: TransactingEntityItemEntityFragment) => void;
}) => {
  const { t } = useTranslation();
  const currentEntity = availableEntities.find(
    (entity) => entity.id === currentEntityId,
  );

  const selectedOption = (value: TransactingEntityItemEntityFragment) =>
    value && <EntityLineItem entity={value} />;

  const { buttonProps, menuProps, itemProps } = useListbox({
    onClickItem: onEntitySelected,
    items: availableEntities,
    itemToString: (unit) => unit?.legalName ?? ``,
    selectedItem: currentEntity,
    buttonStyle: {
      height: `inherit`,
      minHeight: 48,
    },
    itemRenderer: () => selectedOption(currentEntity!),
  });

  return (
    <Listbox.Container>
      <Listbox.Button
        {...buttonProps}
        placeholder={t(`select_transacting_party`)}
      />
      <Listbox.Menu {...menuProps}>
        {availableEntities.map((entity, index) => (
          <Listbox.Item
            key={entity.id}
            item={entity}
            index={index}
            {...itemProps}
          >
            <EntityLineItem entity={entity} />
          </Listbox.Item>
        ))}
      </Listbox.Menu>
    </Listbox.Container>
  );
};

const EntitySelectorWrapper = ({
  availableEntities,
  actor,
  currentEntityId,
  onEntitySelected,
}: {
  readonly availableEntities: TransactingEntityItemEntityFragment[];
  actor: UserWithInstitutionFragment;
  currentEntityId?: TransactingEntityItemEntityFragment["id"] | null;
  onEntitySelected: (entity: TransactingEntityItemEntityFragment) => void;
}) => {
  if (availableEntities.length === 0) {
    return <SuggestedNoEntitySubCard actor={actor} />;
  }
  if (availableEntities.length === 1) {
    return <SingleEntitySubCard entity={availableEntities[0]} />;
  }
  return (
    <EntitySelector
      availableEntities={availableEntities}
      currentEntityId={currentEntityId}
      onEntitySelected={onEntitySelected}
    />
  );
};

const RadioWithSubtext = ({
  toolTipContent,
  title,
  subText,
  value,
  selected,
}: {
  readonly toolTipContent: string;
  readonly title: string;
  readonly value: PartyType;
  readonly subText: string | ReactNode;
  readonly selected: boolean;
}) => (
  <Radio spacing={4} variant="base" value={value}>
    <VStack align="start" spacing={1}>
      <HStack>
        <Text textStyle="heading-lg">{title}</Text>
        <QuestionTooltip iconColor="grey.900" tooltipContent={toolTipContent} />
      </HStack>
      {selected && (
        <Text textStyle="text-lg" color="grey.700">
          {subText}
        </Text>
      )}
    </VStack>
  </Radio>
);

const PartyTypeRadio = ({
  actor,
  onChange,
  value,
}: {
  actor: UserWithInstitutionFragment;
  value: PartyType | null;
  onChange?: (value: string) => void;
}) => {
  const countryList = useCountryList();
  const fullName = `${actor.firstName} ${actor.lastName}`;
  const countryName = countryList.getName(actor.country?.name);

  const { t } = useTranslation(`account`);
  const individualSubText = (
    <>
      {fullName} &#x2022; {countryName}
    </>
  );

  return (
    <RadioGroup w="full" onChange={onChange} value={value as string} pb={4}>
      <Box bg="grey.25" borderWidth={1} w="full" p={4} borderTopRadius={8}>
        <RadioWithSubtext
          title={t(`entities.individual`)}
          selected={value === PartyType.Individual}
          toolTipContent="Id pariatur tempor irure ullamco sit ullamco."
          subText={individualSubText}
          value={PartyType.Individual}
        />
      </Box>
      <Box
        bg="grey.25"
        borderWidth={1}
        borderTopWidth={0}
        w="full"
        p={4}
        borderBottomRadius={8}
      >
        <RadioWithSubtext
          title={t(`entities.entity`)}
          value={PartyType.Entity}
          selected={value === PartyType.Entity}
          toolTipContent="Id pariatur tempor irure ullamco sit ullamco."
          subText="Create your entity by filling out information"
        />
      </Box>
    </RadioGroup>
  );
};

const firstEntityParams = (actor: UserWithInstitutionFragment) =>
  match(actor)
    .with({ institution: null }, (actor) => ({
      legalName: `${actor.firstName} ${actor.lastName}`,
      type: EntityType.Individual,
      jurisdictionOfFormationId: actor.country?.id,
    }))
    .otherwise((actor) => ({
      legalName: actor.institution?.legalName,
      type:
        actor.institution?.entityType &&
        institutionTypeToEntityType(actor.institution?.entityType),
      otherType:
        actor.institution?.entityType &&
        institutionTypeToEntityType(actor.institution?.entityType) ===
          EntityType.Other
          ? `unknown`
          : undefined,
      jurisdictionOfFormationId: actor.institution?.country?.id,
    }));

const InstitutionEntitySubForm = ({
  control,
  watch,
}: {
  control: Control<InstitutionEntitySubFormValues>;
  watch: (name: string) => string;
}) => {
  const { t } = useTranslation(`account`);
  const entityTypes = [
    EntityType.Corporation,
    EntityType.Partnership,
    EntityType.Llc,
    EntityType.Trust,
    EntityType.Other,
  ] as EntityType[];

  const { getFormattedEntityName } = useTranslatedEntityNames();
  const typeValue = watch(`type`);

  const filterItems = ({ search }: { readonly search: string }) =>
    entityTypes.filter((type) =>
      type.toLowerCase().startsWith(search.toLowerCase()),
    );

  return (
    <VStack w="full" spacing={4}>
      <FormCombobox
        control={control}
        name="type"
        itemToString={(item) => getFormattedEntityName(item as EntityType)}
        getItemKey={(item) => item as EntityType}
        isLoading={false}
        getItems={filterItems}
        placeholder={t(`entities.select_entity_type`)}
        label={t(`entities.entity_type`)}
      />
      {typeValue === EntityType.Other && (
        <FormTextInput
          control={control}
          placeholder={t(`entities.add_party_type`)}
          name="otherType"
          label={t(`entities.provide_entity_type`)}
        />
      )}
      <FormTextInput
        control={control}
        name="legalName"
        label={t(`entities.legal_entity_name`)}
      />
      <FormCountryCombobox
        control={control}
        placeholder={t(`entities.search_country`)}
        name="jurisdictionOfFormationId"
        label={t(`entities.jurisdiction_of_formation`)}
        showSearchIcon
      />
    </VStack>
  );
};

type InstitutionEntitySubFormValues = {
  legalName: string;
  jurisdictionOfFormationId: string;
  type: EntityType;
  otherType?: string;
};

const validationSchema = Yup.object().shape({
  legalName: Yup.string().required(),
  jurisdictionOfFormationId: Yup.string().required(),
  type: Yup.string().required(),
  otherType: Yup.string().when(`type`, {
    is: (type: EntityType) => type === EntityType.Other,
    then: (schema) => schema.required(),
  }),
});

const mapVariables =
  (task: TransactionExecutionPageTaskFragment) =>
  (values: InstitutionEntitySubFormValues) => ({
    taskId: task.id,
    input: {
      collectEntity: pick([
        `legalName`,
        `jurisdictionOfFormationId`,
        `type`,
        `otherType`,
      ])(values),
    },
  });

const NewEntityForm = withCurrentActor(
  ({
    task,
    onClose,
    actor,
  }: {
    task: TransactionExecutionPageTaskFragment;
    onClose: () => void;
    actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation(`account`);
    const isInstitution = !!actor.institution;

    const [broadPartyType, setBroadPartyType] = useState<PartyType | null>(
      isInstitution ? PartyType.Entity : null,
    );
    const mutation = useCompleteExecutionTaskMutation();

    const alreadyHasIndividualEntity =
      actor.entities.filter((entity) => entity.type === EntityType.Individual)
        .length > 0 && broadPartyType === PartyType.Individual;

    const initialValues = {
      legalName: ``,
      jurisdictionOfFormationId: ``,
      type: `` as EntityType,
      otherType: undefined,
    };

    const { handleSubmit, control, watch, reset, setValue, formState } =
      useFormQL({
        mutation,
        initialValues,
        validationSchema,
        mapVariables: mapVariables(task),
        onSuccess: onClose,
      });

    const individualFullName = `${actor.firstName} ${actor.lastName}`;

    const handleChangPartyType = (value: PartyType) => {
      setBroadPartyType(value as PartyType);
      if (value === PartyType.Individual) {
        setValue(`legalName`, individualFullName);
        setValue(`jurisdictionOfFormationId`, actor.country?.id ?? ``);
        setValue(`type`, EntityType.Individual, { shouldValidate: true });
      } else {
        reset();
      }
    };

    return (
      <form autoComplete="off" onSubmit={handleSubmit}>
        <HiiveModalHeader closeModal={onClose}>
          <Text>{t(`entities.new_transacting_party`)}</Text>
        </HiiveModalHeader>
        <ModalBody w="full">
          {!isInstitution && (
            <VStack align="start" spacing={0}>
              <Text py={2} textStyle="heading-md">
                {t(`entities.select_transacting_party`)}
              </Text>
              <PartyTypeRadio
                actor={actor}
                value={broadPartyType}
                onChange={handleChangPartyType}
              />
            </VStack>
          )}
          <VStack align="start" spacing={0}>
            {match(broadPartyType)
              .with(PartyType.Individual, () => (
                <Text textStyle="text-sm">
                  {t(`entities.individual_entity_warning`)}
                </Text>
              ))
              .with(PartyType.Entity, () => (
                <InstitutionEntitySubForm control={control} watch={watch} />
              ))
              .otherwise(() => null)}
          </VStack>
        </ModalBody>
        <HiiveModalFooter>
          <Button onClick={onClose} size="xl" variant="rounded-outline-grey">
            {t(`entities.cancel`)}
          </Button>
          <Button
            isDisabled={!formState.isValid || alreadyHasIndividualEntity}
            type="submit"
            size="xl"
            variant="rounded-solid-grey"
          >
            {t(`entities.assign`)}
          </Button>
        </HiiveModalFooter>
      </form>
    );
  },
);

const NewEntityModal = ({
  task,
  onClose,
  isOpen,
}: {
  task: TransactionExecutionPageTaskFragment;
  onClose: () => void;
  isOpen: boolean;
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl">
    <ModalOverlay />
    <ModalContent>
      <NewEntityForm onClose={onClose} task={task} />
    </ModalContent>
  </Modal>
);

const CompletedEntityCard = ({
  entity,
}: {
  readonly entity: TransactingEntityItemEntityFragment;
}) => {
  const { getFormattedEntityName } = useTranslatedEntityNames();
  const countryList = useCountryList();
  const countryName = countryList.getName(entity.jurisdictionOfFormation?.name);

  return (
    <Box
      border="1px solid"
      borderColor="grey.400"
      borderRadius="md"
      px={4}
      py={3}
      bg="grey.25"
    >
      <HStack gap={4}>
        <Center>
          {entity.type === EntityType.Individual ? (
            <User width="24px" height="24px" />
          ) : (
            <Bank width="24px" height="24px" />
          )}
        </Center>
        <VStack alignItems="start" spacing={0.5}>
          <Text textStyle="heading-md">{entity.legalName}</Text>
          <HStack>
            <Text textStyle="text-xs" color="grey.700">
              {countryName}
            </Text>
            <Circle size="1" bg="grey.600" />
            <Text textStyle="text-xs" color="grey.700">
              {getFormattedEntityName(entity.type)}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

const EntityCollectionTaskCard = withCurrentActor(
  ({
    task,
    data,
    actor,
  }: {
    readonly task: TransactionExecutionPageTaskFragment;
    readonly data: ExecutionCollectEntityTaskData;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const [completeTask] = useMutationWithError(
      useCompleteExecutionTaskMutation({
        refetchQueries: [TransactionExecutionPageTransactionByIdDocument],
      }),
      `completeExecutionTask`,
    );

    const availableEntities = !!actor.institutionId
      ? actor.institution?.entities || []
      : actor.entities;

    const [selectedEntityId, setSelectedEntityId] = useState<string | null>(
      availableEntities.length === 1 ? availableEntities[0].id : null,
    );

    const handleComplete = () => {
      const collectEntityParams =
        availableEntities.length === 0
          ? firstEntityParams(actor)
          : {
              entityId: selectedEntityId,
            };

      completeTask({
        variables: {
          taskId: task.id,
          input: {
            collectEntity: collectEntityParams,
          },
        },
      });
    };

    const { isOpen, onClose, onOpen } = useDisclosure();
    const { t } = useTranslation();

    const isCompletionDisabled =
      availableEntities.length !== 0 && !selectedEntityId;

    const entityPickerTitle =
      availableEntities.length === 0
        ? t(`continue_with_this_transacting_party`)
        : t(`transacting_party`);

    const currentEntity = availableEntities.find(
      (entity) => entity.id === data?.entityId,
    );

    const completed = task.status === ExecutionTaskStatus.Completed;

    if (completed && currentEntity)
      return <CompletedEntityCard entity={currentEntity} />;

    return (
      <>
        <Card variant="flat" bg="grey.25" p={4} mb={6}>
          <VStack w="full" alignItems="flex-start">
            <HStack>
              <Text textStyle="heading-md">{entityPickerTitle}</Text>
              <QuestionTooltip
                iconColor="grey.900"
                tooltipContent={t(`entity_collection_task_tooltip`)}
              />
            </HStack>
            <EntitySelectorWrapper
              actor={actor}
              currentEntityId={selectedEntityId}
              availableEntities={availableEntities}
              onEntitySelected={(entity) => setSelectedEntityId(entity.id)}
            />
          </VStack>
        </Card>
        <CardFooter bg="grey.25" justifyContent="end" mx={-6} gap={4}>
          <Button variant="rounded-outline-grey" size="xl" onClick={onOpen}>
            {t(`change_party`)}
          </Button>
          <Button
            onClick={handleComplete}
            variant="rounded-solid-grey"
            size="xl"
            isDisabled={isCompletionDisabled}
          >
            {t(`confirm`)}
          </Button>
        </CardFooter>
        <NewEntityModal task={task} isOpen={isOpen} onClose={onClose} />
      </>
    );
  },
);

export default EntityCollectionTaskCard;
