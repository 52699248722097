import { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  Divider,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import { IssuerStage } from "@/gql";

import { IssuerTransactionsTableContext } from "./IssuerTransactionsProvider";

export function IssuerTransactionsCardSkeleton() {
  const { t } = useTranslation(`issuers`);
  const { filterBy } = useContext(IssuerTransactionsTableContext);

  const { stage } = filterBy ?? {};

  const inReview = stage === IssuerStage.InReview;

  return (
    <Card w="full" boxShadow="none" borderColor="grey.200" borderRadius="none">
      <CardBody>
        <VStack alignItems="flex-start" gap={2}>
          <VStack alignItems="flex-start" gap={1} w="full">
            <HStack alignItems="center" pb={2} w="full" minH="22px">
              <Skeleton h="20px" w="90px" />
              {inReview && <Skeleton h="22px" w="40px" />}
              {!stage && <Skeleton h="16px" w="40px" />}
              <Box flexGrow={1} />
              <Text textStyle="text-sm">
                <Skeleton h="20px" w="90px" />
              </Text>
            </HStack>
            <HStack alignItems="center" w="full">
              <Skeleton h="20px" w="90px" />
              <Box flexGrow={1} />
              <Skeleton h="22px" w="90px" />
            </HStack>
            <Divider />
            <HStack w="full">
              <Text textStyle="text-sm" color="grey.500">
                {t(`seller`)}
              </Text>
              <Box flexGrow={1} />
              <Text textStyle="text-sm" color="grey.500">
                {t(`buyer`)}
              </Text>
            </HStack>
            <HStack w="full">
              <Skeleton h="20px" w="90px" />
              <Box flexGrow={1} />
              <Skeleton h="20px" w="90px" />
            </HStack>
          </VStack>
          {!inReview && (
            <>
              <Divider />
              <HStack w="full">
                <Box flexGrow={1} />
                <Skeleton h="14px" w="140px" />
              </HStack>
            </>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
}
