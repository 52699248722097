import { useField } from "formik";
import isNil from "lodash/isNil";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  chakra,
  Flex,
  FormLabel,
  GridItem,
  HStack,
  Link,
  ModalBody,
  Show,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  CompanyEligibilityCriteriaAccordion,
  CompanyEligibilityCriteriaCheckbox,
  HiiveAdvancedOptionsDropdown,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CheckboxInput,
  EmailInput,
  FixedValueInput,
  FormListbox,
  FormNumberInput,
  OtherDetailsInput,
  QuestionTooltip,
  StepPropsV2,
  TextInput,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  representedEmailSchema,
  representedNameSchema,
  StandingBidFeeBreakdown,
} from "@/components/postings";
import { RepresentedUserTooltip } from "@/components/tooltip";
import {
  AcceptStandingBidModalStandingBidFragment,
  ShareSeries,
  StandingBidAcceptsTransferMethodsOptionsV2,
  UserWithInstitutionFragment,
} from "@/gql";
import { useModal, useStepValidator } from "@/hooks";
import {
  formatCurrency,
  formatShares,
  getShareSeriesText,
  standingBidAcceptsTransferMethodOptionsToLongString,
} from "@/utils";

import { AcceptStandingBidSequenceModalContext } from "./AcceptStandingBidSequenceModalContext";
import { stepKeys, StepKeys } from "./steps";
import { AcceptStandingBidSequenceModalFormValues } from "./types";

interface AcceptStandingBidModalProps
  extends StepPropsV2<StepKeys, AcceptStandingBidSequenceModalFormValues> {
  readonly standingBid: AcceptStandingBidModalStandingBidFragment;
  readonly actor: UserWithInstitutionFragment;
}

const useValidationSchema = (
  actor: UserWithInstitutionFragment,
  standingBid: AcceptStandingBidModalStandingBidFragment,
  showConfirmEligibilityRequirementsCheckbox: boolean,
) => {
  const { t } = useTranslation();
  const requiredError = t(`required`);
  const minShares = standingBid.minPartialAcceptNumShares || 1;
  const maxShares = standingBid.numSharesAvailable || 1;

  const numSharesSchema = Yup.number()
    .nullable()
    .min(minShares, `Must be at least ${formatShares(minShares)}`)
    .max(maxShares, `Cannot be more than ${formatShares(maxShares)}`)
    .required(`Required`);

  return Yup.object().shape({
    ...(standingBid.allowPartialAccept && { numShares: numSharesSchema }),
    shareSeries: Yup.string().nullable().required(requiredError),
    transferMethodV2: Yup.string().nullable().required(requiredError),
    otherListingDetails: Yup.string().nullable(),
    representedEmail: representedEmailSchema(actor),
    representedFirstName: representedNameSchema(actor),
    representedLastName: representedNameSchema(actor),
    notifyRepresentingEmail: Yup.bool().nullable().required(requiredError),
    confirmedEligibilityRequirements: Yup.boolean().when([], {
      is: () => showConfirmEligibilityRequirementsCheckbox,
      then: Yup.boolean().oneOf([true], requiredError),
    }),
  });
};

const SharesInput = ({
  standingBid,
}: {
  standingBid: AcceptStandingBidModalStandingBidFragment;
}) => {
  const { t } = useTranslation();
  const [_field, { error }, _] = useField(`numShares`);

  const { numSharesAvailable, allowPartialAccept, minPartialAcceptNumShares } =
    standingBid;

  if (allowPartialAccept)
    return (
      <>
        <FormNumberInput
          name="numShares"
          label={t(`number_of_shares_selling`)}
        />
        {!error && (
          <Text textStyle="text-sm" mt={1}>
            Enter between {formatShares(minPartialAcceptNumShares)} and
            {` `}
            {formatShares(numSharesAvailable)}
          </Text>
        )}
      </>
    );

  return <FormNumberInput name="numShares" label={t(`number_of_shares`)} />;
};

const AcceptStandingBidModal = ({
  actor,
  values,
  stepRouter,
  ...props
}: AcceptStandingBidModalProps) => {
  const { stepControls } = stepRouter;
  const { standingBid } = props;

  const onSuccess = () => stepControls.nextStep();

  const { t } = useTranslation();
  const { closeModal } = useModal();

  // Might be a way to improve some of this logic
  const getTransferMethodOptions = () => {
    const transferMethods = Object.values(
      StandingBidAcceptsTransferMethodsOptionsV2,
    );
    const filteredTransferMethods = transferMethods.filter((transferMethod) =>
      standingBid.acceptsTransferMethodsV2.includes(transferMethod),
    );

    return filteredTransferMethods;
  };

  const { numSharesAvailable, allowPartialAccept, company } = standingBid;

  const { text: eligibilityCriterionText } =
    company.companyEligibilityCriterion || {};

  const lockedShares =
    !allowPartialAccept && !isNil(numSharesAvailable)
      ? formatShares(numSharesAvailable)
      : null;

  const transferMethodOptions = getTransferMethodOptions();

  const showConfirmEligibilityRequirementsCheckbox =
    eligibilityCriterionText && values.shareSeries === ShareSeries.Common;

  const validationSchema = useValidationSchema(
    actor,
    standingBid,
    !!showConfirmEligibilityRequirementsCheckbox,
  );

  useStepValidator({
    Context: AcceptStandingBidSequenceModalContext,
    stepKey: stepKeys.acceptStandingBid,
    validator: {
      validationSchema,
      onSuccess,
    },
    values,
  });

  return (
    <div data-testid="accept-standing-bid-modal">
      <HiiveModalHeader>
        Accept Standing Bid{` `}
        <Text as="span">{standingBid.displayId}</Text>
      </HiiveModalHeader>
      <ModalBody pb={9}>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          {eligibilityCriterionText && (
            <GridItem colSpan={2}>
              <CompanyEligibilityCriteriaAccordion
                companyName={company.name}
                markdown={eligibilityCriterionText}
              />
            </GridItem>
          )}
          <GridItem colSpan={{ base: 2, md: 1 }}>
            {lockedShares ? (
              <FixedValueInput
                label="Number of shares"
                fixedValue={lockedShares}
              />
            ) : (
              <SharesInput standingBid={standingBid} />
            )}
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <Flex justify="space-between">
              <FormLabel>Price per share</FormLabel>
              <Link
                href="/terms-and-conditions#fees-and-commissions"
                target="_blank"
                fontSize="sm"
                color="salmon.900"
              >
                Fees
              </Link>
            </Flex>
            <Flex alignItems="center" h={10}>
              <Text>{formatCurrency(values.pricePerShare)}</Text>
            </Flex>
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormListbox
              name="shareSeries"
              label={t(`share_series`)}
              itemToString={(item) => getShareSeriesText(item)}
              getItemKey={(item) => item}
              items={standingBid.shareSeriesOptions}
            />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            {transferMethodOptions.length === 1 ? (
              <FixedValueInput
                name="transferMethodV2"
                label={t(`transfer_type`)}
                fixedValue={standingBidAcceptsTransferMethodOptionsToLongString(
                  transferMethodOptions[0],
                )}
              />
            ) : (
              <FormListbox
                name="transferMethodV2"
                label={t(`transfer_type`)}
                itemToString={
                  standingBidAcceptsTransferMethodOptionsToLongString
                }
                getItemKey={(item) => item}
                items={transferMethodOptions}
              />
            )}
          </GridItem>
          <GridItem colSpan={2}>
            <OtherDetailsInput
              name="otherListingDetails"
              label="Additional listing notes"
              placeholder="Add optional details"
              tooltipContent={
                <Trans
                  i18nKey="accept_standing_bid_additional_notes_tooltip"
                  components={{
                    ul: <chakra.ul pl={5} />,
                    li: <li />,
                  }}
                />
              }
            />
          </GridItem>
          {actor.isHiiveUser && (
            <GridItem colSpan={2}>
              <HiiveAdvancedOptionsDropdown
                validationSchema={validationSchema}
                fieldNames={[
                  `mute_notifications`,
                  `representedEmail`,
                  `representedFirstName`,
                  `representedLastName`,
                ]}
              >
                <VStack spacing={4} alignItems="flex-start">
                  <CheckboxInput
                    name="mute_notifications"
                    label={t(`do_not_send_activity_notifications`)}
                  />
                  <Text
                    align="center"
                    textStyle="deprecated-heading-lg"
                    color="h-dark-grey"
                  >
                    {t(`represented_user_info`)}
                    <QuestionTooltip
                      translateY={1.5}
                      translateX={1.0}
                      transform="auto"
                      tooltipContent={<RepresentedUserTooltip />}
                    />
                  </Text>
                  <HStack gap={4} w="full" alignItems="top">
                    <TextInput
                      data-testid="represented-user-first-name"
                      name="representedFirstName"
                      placeholder={t(`first_name`)}
                    />
                    <TextInput
                      data-testid="represented-user-last-name"
                      name="representedLastName"
                      placeholder={t(`last_name`)}
                    />
                  </HStack>
                  <EmailInput
                    data-testid="represented-email"
                    name="representedEmail"
                    placeholder="Email"
                    type="email"
                  />
                  <CheckboxInput
                    name="notifyRepresentingEmail"
                    label="Notify the person being represented"
                  />
                </VStack>
              </HiiveAdvancedOptionsDropdown>
            </GridItem>
          )}

          <GridItem colSpan={2}>
            <StandingBidFeeBreakdown
              companyId={standingBid.company.id}
              numShares={values.numShares}
              pricePerShare={values.pricePerShare}
            />
          </GridItem>
          {showConfirmEligibilityRequirementsCheckbox && (
            <GridItem colSpan={2}>
              <CompanyEligibilityCriteriaCheckbox
                name="confirmedEligibilityRequirements"
                label={t(`agreed_to_eligibility_requirements`, {
                  companyName: company?.name,
                })}
                eligibilityCriterionText={eligibilityCriterionText}
              />
            </GridItem>
          )}
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[AcceptStandingBid/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveSubmitButton
          sentryLabel="[AcceptStandingBid/Submit]"
          type="submit"
          submitText="Continue"
        />
      </HiiveModalFooter>
    </div>
  );
};

export default withCurrentActor(AcceptStandingBidModal);
