import { ChannelTypePreferences, PreferenceSet } from "@knocklabs/client";
import { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Center,
  GridItem,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { InvestorType, UserWithInstitutionFragment } from "@/gql";
import { useAnalytics, useKnockClient } from "@/hooks";
import { hasInvestorType } from "@/utils";

import { NotificationOption } from "./NotificationOption";

type KnockCategoryKey = `activity-listing` | `activity-standingbid`;

type KnockNotificationPreferenceChange = {
  readonly key: KnockCategoryKey;
  readonly value: boolean;
};

const initialNotificationPreferences: PreferenceSet = {
  id: `default`,
  channel_types: {
    email: true,
    in_app_feed: true,
    push: true,
    sms: true,
    chat: true,
  },
  categories: {
    "activity-listing": {
      channel_types: {
        email: true,
        in_app_feed: true,
        push: true,
        sms: true,
        chat: true,
      },
    },
    "activity-standingbid": {
      channel_types: {
        email: true,
        in_app_feed: true,
        push: true,
        sms: true,
        chat: true,
      },
    },
  },
  workflows: {},
};

const NotificationOptions = withCurrentActor(
  ({
    preferences,
    handleUpdatePreferences,
    actor,
  }: {
    readonly preferences: PreferenceSet;
    readonly handleUpdatePreferences: (
      prefsChange: readonly KnockNotificationPreferenceChange[],
    ) => Promise<void>;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const analytics = useAnalytics();
    const getNotificationOptionValue = (key: KnockCategoryKey) =>
      (
        preferences.categories[key] as {
          readonly channel_types: ChannelTypePreferences;
        }
      )?.channel_types?.email;

    const isSeller = hasInvestorType(actor, InvestorType.Seller);
    const isTrader = hasInvestorType(actor, InvestorType.Trader);
    const isUnaccreditedSeller = hasInvestorType(
      actor,
      InvestorType.UnaccreditedSeller,
    );
    const isBroker = hasInvestorType(actor, InvestorType.Broker);

    const showStandingBidsNotificationOption =
      isSeller || isTrader || isUnaccreditedSeller || isBroker;

    return (
      <>
        <Text textStyle="heading-sm" mb={3}>
          Emails
        </Text>
        <SimpleGrid columns={2} columnGap={9}>
          <GridItem colSpan={{ base: 2, xl: 1 }}>
            <NotificationOption
              title="New listings on companies in my watchlist"
              value={getNotificationOptionValue(`activity-listing`)}
              onChange={(value) => {
                analytics.track(`Notification Option Toggled`, {
                  option: `new-listings`,
                  toggle: value,
                });
                handleUpdatePreferences([{ key: `activity-listing`, value }]);
              }}
            />
            {showStandingBidsNotificationOption && (
              <NotificationOption
                title="New standing bids on companies in my watchlist"
                value={getNotificationOptionValue(`activity-standingbid`)}
                onChange={(value) => {
                  analytics.track(`Notification Option Toggled`, {
                    option: `new-standing-bids`,
                    toggle: value,
                  });
                  handleUpdatePreferences([
                    { key: `activity-standingbid`, value },
                  ]);
                }}
              />
            )}
          </GridItem>
        </SimpleGrid>
      </>
    );
  },
);

export const NotificationsCard = withCurrentActor(
  ({
    actor,
    userNotificationToken,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly userNotificationToken: string;
  }) => {
    const knockClient = useKnockClient({ actor, userNotificationToken });
    const [loading, setLoading] = useState<boolean>(true);
    const [preferences, setPreferences] = useState<PreferenceSet>(
      initialNotificationPreferences,
    );

    const fetchPreferences = async () => {
      const data = await knockClient.preferences.get();

      const nextPreferences: PreferenceSet = {
        ...data,
        channel_types:
          data.channel_types || initialNotificationPreferences.channel_types,
        categories:
          data.categories || initialNotificationPreferences.categories,
      };

      setPreferences(nextPreferences);
      setLoading(false);
    };

    useEffect(() => {
      fetchPreferences();
    }, []);

    const handleUpdatePreferences = async (
      prefsChange: readonly KnockNotificationPreferenceChange[],
    ) => {
      const categories = prefsChange.reduce(
        (previousValue, { key, value }) => ({
          ...previousValue,
          [key]: {
            channel_types: {
              email: value,
              in_app_feed: true,
              push: true,
              sms: true,
              chat: true,
            },
          },
        }),
        preferences.categories,
      );

      const nextPreferences = {
        ...preferences,
        categories,
      };

      const data = await knockClient.preferences.set(nextPreferences);
      setPreferences(data);
    };

    return (
      <Card w="full" flex="1">
        <CardHeader>
          <Text textStyle="heading-sm">Notifications</Text>
        </CardHeader>
        <CardBody p={{ base: 4, lg: 10 }}>
          {loading ? (
            <Center mt={12}>
              <Spinner />
            </Center>
          ) : (
            <NotificationOptions
              preferences={preferences}
              handleUpdatePreferences={handleUpdatePreferences}
            />
          )}
        </CardBody>
      </Card>
    );
  },
);
