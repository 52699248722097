import { FormListbox } from "@/components/form";
import { TransferMethod, TransferMethodV2 } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { FlagValue, useOtherTransferMethod } from "@/hooks/featureFlags";
import { transferMethodToLongString } from "@/utils";

const allowedTransferMethods = (
  canSelectHiiveFund: boolean | FlagValue,
  otherFlagOn: FlagValue,
) =>
  [TransferMethodV2.Direct, TransferMethodV2.Fund]
    .concat(canSelectHiiveFund ? [TransferMethodV2.HiiveFund] : [])
    .concat(
      otherFlagOn
        ? [TransferMethodV2.Other]
        : [TransferMethodV2.ForwardContract],
    );

const TransferMethodInput = ({
  name,
  label,
  onChange,
}: {
  readonly name: string;
  readonly label: string;
  readonly onChange?: (item: TransferMethod | TransferMethodV2) => void;
}) => {
  const actor = useCurrentActor();
  const transferMethods = allowedTransferMethods(
    actor.isHiiveUser,
    useOtherTransferMethod(),
  );

  return (
    <FormListbox
      onSelectItem={onChange}
      name={name}
      label={label}
      itemToString={transferMethodToLongString}
      getItemKey={(item) => item}
      items={transferMethods}
    />
  );
};

export default TransferMethodInput;
