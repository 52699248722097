export default {
  accepted: `accepted`,
  active_transactions: `active transactions`,
  approved: `approved`,
  awaiting_closing: `awaiting closing`,
  awaiting_signatures: `Awaiting signatures on the {{documentTitle}}`,
  awaiting_your_signature: `Awaiting your signature on the {{documentTitle}}`,
  bid_accepted: `bid accepted`,
  cancelled: `cancelled`,
  closed_fee_paid: `closed fee paid`,
  closed_fee_pending: `closed fee pending`,
  complete: `complete`,
  confirmed: `confirmed`,
  expired: `expired`,
  in_review: `in review`,
  issuer_approval_declined: `issuer approval declined`,
  issuer_approval: `issuer approval`,
  issuer_pending_approval: `issuer pending approval`,
  my_transactions: `my transactions`,
  other_transactions: `other {{name}} transactions`,
  pending: `pending`,
  review_and_sign: `Review and Sign`,
  send_to_customer: `Send {{type}} to customer`,
  signed: `signed`,
  submitted: `submitted`,
  task_one: `{{count}} task`,
  task_other: `{{count}} tasks`,
  watchlist_highest_bid: `Highest Bid`,
  watchlist_lowest_ask: `Lowest Ask`,
  watchlist_last_accepted_bid: `Last Accepted Bid`,
};
