import { t } from "i18next";
import isNil from "lodash/isNil";

import { Maybe } from "@/gql";
import { formatShares, lot } from "@/utils";

import ShareDetailsPricePerShareStat from "./ShareDetailsPricePerShareStat";
import ShareDetailsStat from "./ShareDetailsStat";
import ShareDetailsStatsWrapper from "./ShareDetailsStatsWrapper";

const ShareDetailsStats = ({
  numberOfShares,
  pricePerShare,
  shareText = `shares`,
  shareTextAbbreviation = `sh`,
  allowPartialAccept = false,
}: {
  readonly numberOfShares: Maybe<number> | undefined;
  readonly pricePerShare: Maybe<number> | undefined;
  readonly shareText?: Maybe<string> | undefined;
  readonly shareTextAbbreviation?: Maybe<string> | undefined;
  readonly allowPartialAccept?: Maybe<boolean> | undefined;
}) => {
  const numberOfSharesText = numberOfShares
    ? formatShares(numberOfShares)
    : `-`;

  const totalText =
    !isNil(numberOfShares) && !isNil(pricePerShare)
      ? lot(numberOfShares, pricePerShare)
      : `-`;

  return (
    <ShareDetailsStatsWrapper data-dd-privacy="mask">
      <ShareDetailsStat
        title={allowPartialAccept ? t(`maximum_shares`) : (shareText as string)}
      >
        {numberOfSharesText}
      </ShareDetailsStat>
      <ShareDetailsPricePerShareStat
        pricePerShare={pricePerShare}
        shareTextAbbreviation={shareTextAbbreviation}
      />
      <ShareDetailsStat
        title={t(allowPartialAccept ? `maximum_total` : `total`)}
      >
        {totalText}
      </ShareDetailsStat>
    </ShareDetailsStatsWrapper>
  );
};

export default ShareDetailsStats;
