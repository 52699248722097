import {
  loadModernTreasury,
  EmbeddableFlow,
  EmbeddableFlowError,
  EmbeddableFlowCreateOptions,
} from "@modern-treasury/modern-treasury-js";
import { useState } from "react";

import { useCreateAccountCollectionFlowMutation } from "@/gql";
import { useColors } from "@/hooks/index";

type useModernTreasuryEmbeddableFlowProps = {
  readonly onFlowSuccess: () => void;
  readonly onFlowError: (error: EmbeddableFlowError) => void;
};

export default function useModernTreasuryEmbeddableFlow({
  onFlowSuccess,
  onFlowError,
}: useModernTreasuryEmbeddableFlowProps) {
  const [embeddableFlow, setEmbeddableFlow] = useState<EmbeddableFlow>();
  const [startAccountCollectionMutation] =
    useCreateAccountCollectionFlowMutation();
  const [salmon900] = useColors([`salmon.900`]);

  const getClientToken = async (countryCode: string, transactionId: string) =>
    startAccountCollectionMutation({
      variables: { countryId: countryCode, transactionId },
    });

  const createEmbeddableFlow = async (
    countryCode: string,
    transactionId: string,
  ): Promise<EmbeddableFlow | undefined | null> => {
    const modernTreasuryAPI = await loadModernTreasury(
      process.env.NEXT_PUBLIC_MODERN_TREASURY_PUBLISHABLE_KEY || ``,
    );

    const { data } = await getClientToken(countryCode, transactionId);

    const mtAPIOptions: EmbeddableFlowCreateOptions = {
      clientToken:
        data?.createBankAccountCollectionFlow?.accountCollectionFlow
          ?.clientToken || ``,
      onSuccess: onFlowSuccess,
      onError: onFlowError,
      variables: { colorPrimary: salmon900 },
    };

    const embeddableFlow: EmbeddableFlow | undefined =
      modernTreasuryAPI?.createEmbeddableFlow(mtAPIOptions);

    setEmbeddableFlow(embeddableFlow);

    return embeddableFlow;
  };

  const mountEmbeddableFlow = async (
    countryCode: string,
    containerId: string,
    transactionId: string,
  ) => {
    const embeddableFlow = await createEmbeddableFlow(
      countryCode,
      transactionId,
    );
    embeddableFlow?.mount(containerId);
  };

  const unmountEmbeddableFlow = () => {
    embeddableFlow?.unmount();
  };

  return {
    mountEmbeddableFlow,
    unmountEmbeddableFlow,
  };
}
