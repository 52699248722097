import { ReactNode } from "react";

import { Box } from "@chakra-ui/react";

import { useIsDesktop } from "@/hooks";

interface IssuerPortalPageContainerProps {
  readonly header?: ReactNode;
  readonly content: ReactNode;
}

export function IssuerPortalPageContainer({
  header,
  content,
}: IssuerPortalPageContainerProps) {
  const isDesktop = useIsDesktop();

  return (
    <>
      {header && (
        <Box
          bg="white"
          pt={{ base: 8, md: 16 }}
          px={{ base: 5, md: 10 }}
          borderBottomWidth="1px"
          borderBottomColor="grey.100"
          w="full"
        >
          {header}
        </Box>
      )}
      <Box
        px={{ base: 0, md: 10 }}
        py={{ base: 0, md: 8 }}
        mt={isDesktop ? `unset` : 3}
      >
        {content}
      </Box>
    </>
  );
}
