import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/nextjs";
import { useCallback } from "react";

import { InvestorType } from "@/gql";

type IdentifiableUser = {
  id: string;
  investorType?: InvestorType | null;
};

export function useIdentifyUser() {
  return useCallback((actor?: IdentifiableUser) => {
    Sentry.setUser({ id: actor?.id });

    if (actor?.id != null) {
      datadogRum.setUser({
        id: actor?.id,
        investor_type: actor?.investorType,
      });
    } else {
      datadogRum.clearUser();
    }
  }, []);
}
