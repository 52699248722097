import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useFronteggMutation } from "./useFronteggMutation";

interface EnrollMfaResponse {
  readonly otcToken: string;
  readonly phoneNumber: string;
}

interface EnrollMfaRequestPayload {
  phoneNumber: string;
}

const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/sms/enroll`;

const useEnrollSmsMfa = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: EnrollMfaResponse, phoneNumber: string) => void;
  onError?: (error: Error) => void;
}) => {
  const { t } = useTranslation();

  const { state, setState, execute } = useFronteggMutation<
    EnrollMfaRequestPayload,
    EnrollMfaResponse
  >();

  const enrollMfa = useCallback(
    async (phoneNumber: string) =>
      execute(API_URL, {
        body: {
          phoneNumber,
        },
        fallbackErrorMessage: t(`failed_to_enroll_mfa`),
        onSuccess: (data) => onSuccess?.(data, phoneNumber),
        onError,
      }),
    [execute],
  );

  return [enrollMfa, state, setState] as const;
};

export default useEnrollSmsMfa;
