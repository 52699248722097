import { GridItem, Text } from "@chakra-ui/react";

const TransactionSizeError = ({ error }: { readonly error: string }) => (
  <GridItem colSpan={2}>
    <Text textStyle="text-sm" color="red.500">
      {error}
    </Text>
  </GridItem>
);

export default TransactionSizeError;
