import { X } from "@phosphor-icons/react";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardHeader,
  Center,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ButtonFooterWrapper, HiiveSubmitButton } from "@/components/common";
import { CustomTooltip, EmailInput, FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  UpdateInstitutionComplianceEmailMutation,
  UserWithInstitutionFragment,
  useUpdateInstitutionComplianceEmailMutation,
} from "@/gql";
import { useCustomToast } from "@/hooks";
import { constants } from "@/utils";

const { EMAIL_REGEX } = constants;

interface ChangeEmailFormValues {
  readonly complianceEmail: string;
}

const validationSchema = Yup.object().shape({
  complianceEmail: Yup.string()
    .nullable()
    .min(0)
    .matches(EMAIL_REGEX, `Invalid email address`),
});

const createInitialValues = (complianceEmail?: string | null) => ({
  complianceEmail: complianceEmail || ``,
});

const createMapVariables =
  (institutionId: string) => (values: ChangeEmailFormValues) => ({
    institutionId,
    complianceEmail: values.complianceEmail,
  });

const XButton = ({ onClick }: { readonly onClick: () => void }) => (
  <CustomTooltip tooltipContent="Remove" placement="top">
    <Center
      w={10}
      h={10}
      cursor="pointer"
      onClick={onClick}
      color="black"
      transition=".1s all ease-in-out"
      _hover={{
        color: `grey.600`,
      }}
    >
      <X fill="current" />
    </Center>
  </CustomTooltip>
);

export const ChangeComplianceEmailCard = withCurrentActor(
  ({
    actor: { institution },
  }: {
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { successToast } = useCustomToast();
    const { t } = useTranslation();
    const onSuccess = (response: UpdateInstitutionComplianceEmailMutation) => {
      const newComplianceEmail =
        response.updateInstitutionComplianceEmail.institution?.complianceEmail;

      if (!newComplianceEmail) {
        successToast(`Successfully removed compliance email.`);
        return;
      }

      successToast(`Successfully updated compliance email.`);
    };
    const mutation = useUpdateInstitutionComplianceEmailMutation();
    const initialValues = createInitialValues(institution?.complianceEmail);
    const mapVariables = createMapVariables(institution?.id || ``);

    return (
      <FormikQL
        mutation={mutation}
        mutationNames={[`updateInstitutionComplianceEmail`]}
        initialValues={initialValues}
        mapVariables={mapVariables}
        validationSchema={validationSchema}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values, setFieldValue, submitForm }) => {
          const handleClickClearComplianceEmail = () => {
            setFieldValue(`complianceEmail`, null);
            submitForm();
          };
          return (
            <Card w="full" flex="1" as={Form} autoComplete="off">
              <CardHeader>
                <Text textStyle="heading-sm">
                  {t(`compliance_email_setting_page_title`)}
                </Text>
              </CardHeader>
              <CardBody>
                <VStack w="full" alignItems="flex-start" spacing={6}>
                  <Text>{t(`compliance_email_setting_page_description`)}</Text>
                  <SimpleGrid columns={2} columnGap={9} w="full">
                    <GridItem colSpan={{ base: 2, xl: 1 }}>
                      <VStack spacing={6}>
                        <HStack w="full" alignItems="end">
                          <EmailInput
                            name="complianceEmail"
                            label="Email address"
                            placeholder="Enter email address"
                            bg="white"
                            rightElement={
                              institution?.complianceEmail && (
                                <XButton
                                  onClick={handleClickClearComplianceEmail}
                                />
                              )
                            }
                          />
                        </HStack>
                        <ButtonFooterWrapper>
                          <HiiveSubmitButton
                            submitText="Update"
                            type="submit"
                            sentryLabel="[ChangeEmail/Submit]"
                            isLoading={isSubmitting}
                            isDisabled={
                              institution?.complianceEmail ===
                                values.complianceEmail ||
                              values.complianceEmail === ``
                            }
                          />
                        </ButtonFooterWrapper>
                      </VStack>
                    </GridItem>
                  </SimpleGrid>
                </VStack>
              </CardBody>
            </Card>
          );
        }}
      </FormikQL>
    );
  },
);
