import { useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { Listing } from "@/gql";
import { useModal } from "@/hooks";
import {
  abbrCountLabel,
  countLabel,
  formatPricePerShare,
  formatShares,
  isHiiveFund,
  getListingNumOfShares,
  getSecuritySpecialistContact,
} from "@/utils";

import { useCreateListingSequenceModalStepFormContext } from "./CreateListingSequenceModalStepFormContext";

const getSuccessMessage = (
  listing: Pick<Listing, "listingPricePerShare"> & {
    readonly company: Pick<Listing["company"], "name">;
  },
  numShares: number,
  shareText: string,
  shareAbbreviation: string,
) =>
  !!listing.listingPricePerShare
    ? `Your ${listing.company.name} Listing for ${formatShares(
        numShares,
      )} ${shareText} at ${formatPricePerShare(
        listing.listingPricePerShare,
      )}/${shareAbbreviation} is now live on Hiive!`
    : `Your ${listing.company.name} Listing for ${formatShares(
        numShares,
      )} ${shareText} is now live on Hiive!`;

const CreateListingSuccessModal = () => {
  const { t } = useTranslation();
  const { mutationData } = useCreateListingSequenceModalStepFormContext();

  const { closeModal } = useModal();

  if (!mutationData || !mutationData.createListing.listing) {
    closeModal();
    return null;
  }

  const { listing } = mutationData.createListing;

  const numShares = getListingNumOfShares(listing, false);

  const shareText = `${countLabel(listing)}s`;
  const shareAbbreviation = abbrCountLabel(listing);

  const message = getSuccessMessage(
    listing,
    numShares,
    shareText,
    shareAbbreviation,
  );

  const securitySpecialistContact = getSecuritySpecialistContact(
    listing.company,
  );

  const listingSuccessMsg = isHiiveFund(listing)
    ? `create_hiive_fund_listing_success_body`
    : `create_listing_success_body`;

  return (
    <div data-testid="create-listing-success-modal">
      <HiiveModalHeader>Listing made!</HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start">
          <Text textStyle="heading-xl">{message}</Text>
          <Text>{t(listingSuccessMsg)}</Text>
          <Text>
            You can also reach out anytime to the {listing.company.name}
            {` `}
            Security Specialist{` `}
            <Text as="span" fontWeight="medium">
              <MailtoLink
                email={securitySpecialistContact}
                subject={`Contact ${listing.company.name} Security Specialist`}
              >
                {securitySpecialistContact}
              </MailtoLink>
            </Text>
            {` `}
            for assistance on your transaction.
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          sentryLabel="[CreateListingSuccess/Submit]"
          type="submit"
          submitText="Okay"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </div>
  );
};

export default CreateListingSuccessModal;
