import { HiiveModalContentWrapper } from "@/components/common";
import {
  PlaceBidSequenceModalCompanyFragment,
  PlaceBidSequenceModalListingFragment,
} from "@/gql";

import { PlaceBidSequenceModalAuthorization } from "./PlaceBidSequenceModalAuthorization";

const PlaceBidSequenceModal = ({
  company,
  initialListing,
  allowDirectBidPlacing,
}: {
  readonly company: PlaceBidSequenceModalCompanyFragment;
  readonly initialListing?: PlaceBidSequenceModalListingFragment;
  readonly allowDirectBidPlacing?: boolean;
}) => (
  <HiiveModalContentWrapper>
    <PlaceBidSequenceModalAuthorization
      company={company}
      initialListing={initialListing}
      allowDirectBidPlacing={allowDirectBidPlacing}
    />
  </HiiveModalContentWrapper>
);

export default PlaceBidSequenceModal;
