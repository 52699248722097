import { File } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box,
  CardProps,
} from "@chakra-ui/react";

import { ThreeDotsVerticalIcon } from "@/components/icons";
import { StaTemplateFileUploadFragment } from "@/gql";

interface FileCardProps extends CardProps {
  fileUpload: StaTemplateFileUploadFragment;
}

const FileCard = ({ fileUpload, ...props }: FileCardProps) => {
  const { t } = useTranslation(`issuers`);
  const { filename, insertedAt, url } = fileUpload;

  const [name, extension] = filename.split(`.`);

  const formattedDate = useMemo(
    () => dayjs(insertedAt).format(`MMM D, YYYY`),
    [insertedAt],
  );

  const handleDownload = () => {
    window.open(url, `_blank`);
  };

  return (
    <Card variant="flat" p={2} bg="grey.15" borderRadius={9} {...props}>
      <HStack justify="space-between">
        <HStack>
          <Box>
            <File size={24} />
          </Box>
          <VStack align="flex-start" gap={0}>
            <Text textStyle="heading-xs" textColor="grey.700" noOfLines={1}>
              {name}
            </Text>
            <HStack gap={1}>
              {extension && (
                <>
                  <Text
                    textStyle="text-sm"
                    textColor="grey.400"
                    textTransform="uppercase"
                  >
                    {extension}
                  </Text>
                  <Text textStyle="text-sm" textColor="grey.400">
                    •
                  </Text>
                </>
              )}
              <Text textStyle="text-sm" textColor="grey.900">
                {formattedDate}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <Menu placement="bottom-end" gutter={12}>
          <MenuButton>
            <ThreeDotsVerticalIcon />
          </MenuButton>
          <MenuList w={240} boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.25)">
            <MenuItem onClick={() => handleDownload()}>
              {t(`download`)}
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Card>
  );
};

export default FileCard;
