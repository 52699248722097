import { useTranslation } from "react-i18next";

import {
  Card,
  CardHeader,
  Flex,
  Tag,
  Text,
  HStack,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

import { HiiveModalHeader } from "@/components/common";
import { IssuerProfilePageCompanyFragment } from "@/gql";
import { formatValuation } from "@/utils";

import { IssuerInfoCard } from "./IssuerInfoCard";
import { IssuerInfoRow } from "./IssuerInfoRow";

const MAX_INVESTORS = 7;

interface InvestorListProps {
  readonly investorNames: string[];
  readonly onOpen: () => void;
}
interface IndustryListProps {
  readonly industries: Record<string, string>[];
}

const InvestorList = ({ investorNames, onOpen }: InvestorListProps) => {
  const { t } = useTranslation(`issuers`);
  const investorNamesCount = investorNames.length;

  if (investorNamesCount === 0) {
    return <>{t(`na`)}</>;
  }

  const displayedInvestors = investorNames.slice(0, MAX_INVESTORS).join(`, `);
  const remainingCount = investorNamesCount - MAX_INVESTORS;

  return (
    <>
      {displayedInvestors}
      {` `}
      {remainingCount > 0 && (
        <Button variant="boxed-grey-mini" onClick={onOpen} ml={2}>
          {`+${remainingCount} more`}
        </Button>
      )}
    </>
  );
};

const IndustryList = ({ industries }: IndustryListProps) => {
  const { t } = useTranslation(`issuers`);

  return industries.length === 0 ? (
    <> {t(`na`)} </>
  ) : (
    industries.map(({ name, id }) => (
      <Tag width={{ base: `min-content`, lg: `auto` }} variant="blue" key={id}>
        {name}
      </Tag>
    ))
  );
};

const InvestorModal = ({
  investorNames,
  isOpen,
  onClose,
}: {
  readonly investorNames: string[];
  readonly isOpen: boolean;
  readonly onClose: () => void;
}) => {
  const { t } = useTranslation(`issuers`);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <HiiveModalHeader closeModal={onClose}>
          {t(`notable_investors`)}
        </HiiveModalHeader>
        <ModalBody>{investorNames.join(`, `)}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const IssuerGeneralDetailsCard = ({
  company,
}: {
  readonly company: IssuerProfilePageCompanyFragment;
}) => {
  const { totalRaised, lastRoundPostValuation, investors, industries } =
    company;
  const { t } = useTranslation(`issuers`);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const investorNames = investors.map((investor) => investor.name);

  return (
    <>
      <InvestorModal
        investorNames={investorNames}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Card variant="flat">
        <CardHeader px={6} py={6}>
          <HStack justifyContent="space-between">
            <Text as="h5" textStyle="heading-sm">
              {t(`general_details`)}
            </Text>
          </HStack>
        </CardHeader>
        <IssuerInfoCard>
          <IssuerInfoRow title={t(`last_round_value`)}>
            {lastRoundPostValuation
              ? `${formatValuation(lastRoundPostValuation)} post`
              : t(`na`)}
          </IssuerInfoRow>
          <IssuerInfoRow title={t(`total_capital_raised`)}>
            {totalRaised ? formatValuation(totalRaised) : t(`na`)}
          </IssuerInfoRow>
          <IssuerInfoRow title={t(`notable_investors`)}>
            <InvestorList investorNames={investorNames} onOpen={onOpen} />
          </IssuerInfoRow>
          <IssuerInfoRow title={t(`industries`)}>
            <Flex gap={2} wrap="wrap">
              <IndustryList industries={industries} />
            </Flex>
          </IssuerInfoRow>
        </IssuerInfoCard>
      </Card>
    </>
  );
};
