/* eslint-disable functional/immutable-data */
import { gsap, Power1 } from "gsap";
import isNil from "lodash/isNil";
import { SVGProps, useEffect, useRef } from "react";
import { match } from "ts-pattern";

import { Box, chakra } from "@chakra-ui/react";

import { AskPriceComparisonChartCompanyFragment } from "@/gql";
import { useColors, useDebounce } from "@/hooks";
import { formatPricePerShare } from "@/utils";

import { BannerCardWrapper } from "./BannerCardWrapper";
import { Card } from "./Card";
import { CardBanner } from "./CardBanner";
import { CardPrice } from "./CardPrice";
import { CardTitle } from "./CardTitle";
import { priceKeys } from "./data";

const Circle = ({ cx, ...svgProps }: SVGProps<SVGCircleElement>) => {
  const circleRef = useRef(null);
  const initialRenderRef = useRef(true);

  useEffect(() => {
    if (!circleRef.current || isNil(cx)) return;

    if (initialRenderRef.current) {
      gsap.set(circleRef.current, { attr: { cx } });
      initialRenderRef.current = false;
      return;
    }

    gsap.to(circleRef.current, {
      attr: { cx },
      duration: 0.75,
      ease: Power1.easeOut,
    });
  }, [cx]);

  return (
    <circle ref={circleRef} r="6" strokeWidth={3} fill="white" {...svgProps} />
  );
};

const Path = ({ d, ...pathProps }: SVGProps<SVGPathElement>) => {
  const pathRef = useRef(null);
  const initialRenderRef = useRef(true);

  const [grey200] = useColors([`grey.200`]);

  useEffect(() => {
    if (!pathRef.current || isNil(d)) return;

    if (initialRenderRef.current) {
      gsap.set(pathRef.current, { attr: { d } });
      initialRenderRef.current = false;
      return;
    }

    gsap.to(pathRef.current, {
      attr: { d },
      duration: 0.75,
      ease: Power1.easeOut,
    });
  }, [d]);

  return <path ref={pathRef} fill="none" stroke={grey200} {...pathProps} />;
};

export const HorizontalAskPriceComparisonChart = ({
  askPriceInDollars,
  company: { currentPrices, lastRoundPricePerShare },
}: {
  readonly askPriceInDollars: number;
  readonly company: AskPriceComparisonChartCompanyFragment;
}) => {
  const { debounce } = useDebounce();

  const [salmon900, grey900, teal900, olive900, plum900, grey200] = useColors([
    `salmon.900`,
    `grey.900`,
    `teal.900`,
    `olive.900`,
    `plum.900`,
    `grey.200`,
  ]);

  /**
   * Prices
   */
  const prices = {
    ...(!isNil(lastRoundPricePerShare) && {
      [priceKeys.lastRoundPPS]: {
        value: lastRoundPricePerShare / 100,
        key: priceKeys.lastRoundPPS,
      },
    }),
    ...(!isNil(currentPrices?.highestBid) && {
      [priceKeys.highestBid]: {
        value: currentPrices.highestBid / 100,
        key: priceKeys.highestBid,
        title: `Highest Bid`,
      },
    }),
    ...(!isNil(currentPrices?.lastTransaction) && {
      [priceKeys.lastTransaction]: {
        value: currentPrices.lastTransaction / 100,
        key: priceKeys.lastTransaction,
      },
    }),
    ...(!isNil(currentPrices?.lowestAsk) && {
      [priceKeys.lowestAsk]: {
        value: currentPrices.lowestAsk / 100,
        key: priceKeys.lowestAsk,
      },
    }),
  };

  const numPrices = Object.values(prices).length;

  /**
   * Parameters
   */

  const cH = 64; // Card Height
  const cW = 168; // Card Width
  const baseS = 24; // Base Card Spacing
  const gapS = ((4 - numPrices) * (cW + baseS)) / (numPrices - 1); // Spacing in the gaps between cards, when there's less then 4 cards
  const cS = baseS + gapS;
  const cY = 20; // Card Y from top
  const cX = 40; // Card X from left
  const d1 = 40; // Distance to first bezier control point from bottom of cards
  const d2 = 60; // Distance from first control point to the graph
  const d3 = 80; // Distance from graph to top of "Your Ask" card

  const gM = cX + cW / 2; // Graph margin from left/right
  const gW = 4 * cW + 3 * baseS + 2 * cX - 2 * gM; // Graph width

  const height = 2 * cY + 2 * cH + d1 + d2 + d3; // Height of full chart
  const width = 2 * cX + 4 * cW + 3 * baseS; // Width of full chart

  const priceValues = Object.values(prices).map(({ value }) => value);

  const highest = Math.max(...[askPriceInDollars, ...priceValues]);

  if (highest === 0) {
    throw new Error(
      `Invalid pricing causing division by zero in <HorizontalAskPriceComparisonChart/>`,
    );
  }

  const gAskPrice = (Math.abs(askPriceInDollars) / highest) * gW;

  const pricePoints = Object.values(prices)
    .sort((a, b) => a.value - b.value)
    .map((price) => ({
      gPrice: (Math.abs(price.value) / highest) * gW,
      formattedPrice: formatPricePerShare(price.value, false),
      key: price.key,
    }));

  const askPriceRef = useRef(null);
  const initialRenderRef = useRef(true);

  useEffect(() => {
    if (!askPriceRef.current || isNil(askPriceInDollars)) return;

    if (initialRenderRef.current) {
      gsap.set(askPriceRef.current, { translateX: gAskPrice });
      initialRenderRef.current = false;
      return;
    }

    debounce(
      () =>
        gsap.to(askPriceRef.current, {
          translateX: gAskPrice,
          duration: 1,
          ease: Power1.easeInOut,
        }),
      100,
    );
  }, [askPriceInDollars]);

  return (
    <Box
      as={chakra.svg}
      xmlns="http://www.w3.org/2000/svg"
      w="full"
      viewBox={`0 0 ${width} ${height}`}
      bg="grey.25"
      borderColor="grey.200"
      borderWidth="0.5px"
      borderRadius={6}
    >
      <defs>
        <filter id="dropshadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="5" />
          <feOffset dx="2" dy="2" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.15" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <line
        strokeWidth="24"
        stroke={grey200}
        strokeLinecap="round"
        x1={gM}
        y1={cY + cH + d1 + d2}
        x2={gM + gW}
        y2={cY + cH + d1 + d2}
      />
      <line
        strokeWidth="22"
        stroke="white"
        strokeLinecap="round"
        x1={gM}
        y1={cY + cH + d1 + d2}
        x2={gM + gW}
        y2={cY + cH + d1 + d2}
      />
      {pricePoints.map(({ gPrice, formattedPrice, key }, index) => {
        const foreignObjectProps = {
          y: cY,
          x: cX + index * (cW + cS),
          width: cW,
          height: cH,
        };

        const circleProps = {
          cx: gM + gPrice,
          cy: cY + cH + d1 + d2,
        };

        const pathProps = {
          d: `
            M${cX + cW / 2 + index * (cW + cS)} ${cY + cH}
            C${cX + cW / 2 + index * (cW + cS)} ${cY + cH},
            ${cX + cW / 2 + index * (cW + cS)} ${cY + cH + d1} ,
            ${gM + gPrice} ${cY + cH + d1 + d2}
          `,
        };

        return (
          <>
            {match(key)
              .with(`lastRoundPPS`, () => (
                <g key={key}>
                  <foreignObject {...foreignObjectProps}>
                    <Card>
                      <CardTitle>Last Round PPS</CardTitle>
                      <CardPrice>{formattedPrice}</CardPrice>
                    </Card>
                  </foreignObject>
                  <Path {...pathProps} />
                  <Circle stroke={grey900} {...circleProps} />
                </g>
              ))
              .with(`lastTransaction`, () => (
                <g key={key}>
                  <g filter="url(#dropshadow)">
                    <foreignObject {...foreignObjectProps}>
                      <BannerCardWrapper>
                        <CardBanner bg="teal.25" borderColor="teal.1000" />
                        <Card
                          borderLeftRadius="0"
                          borderLeftWidth="0"
                          bg="white"
                        >
                          <CardTitle>Last Accepted Bid</CardTitle>
                          <CardPrice>{formattedPrice}</CardPrice>
                        </Card>
                      </BannerCardWrapper>
                    </foreignObject>
                  </g>
                  <Path {...pathProps} />
                  <Circle stroke={teal900} {...circleProps} />
                </g>
              ))
              .with(`lowestAsk`, () => (
                <g key={key}>
                  <g filter="url(#dropshadow)">
                    <foreignObject {...foreignObjectProps}>
                      <BannerCardWrapper>
                        <CardBanner bg="olive.25" borderColor="olive.1000" />
                        <Card
                          borderLeftRadius="0"
                          borderLeftWidth="0"
                          bg="white"
                        >
                          <CardTitle>Lowest Ask</CardTitle>
                          <CardPrice>{formattedPrice}</CardPrice>
                        </Card>
                      </BannerCardWrapper>
                    </foreignObject>
                  </g>
                  <Path {...pathProps} />
                  <Circle stroke={olive900} {...circleProps} />
                </g>
              ))
              .with(`highestBid`, () => (
                <g key={key}>
                  <g filter="url(#dropshadow)">
                    <foreignObject {...foreignObjectProps}>
                      <BannerCardWrapper>
                        <CardBanner bg="plum.25" borderColor="plum.1000" />
                        <Card
                          borderLeftRadius="0"
                          borderLeftWidth="0"
                          bg="white"
                        >
                          <CardTitle>Highest Bid</CardTitle>
                          <CardPrice>{formattedPrice}</CardPrice>
                        </Card>
                      </BannerCardWrapper>
                    </foreignObject>
                  </g>
                  <Path {...pathProps} />
                  <Circle stroke={plum900} {...circleProps} />
                </g>
              ))
              .otherwise(() => null)}
          </>
        );
      })}
      <g ref={askPriceRef}>
        <g filter="url(#dropshadow)">
          <foreignObject
            width={cW}
            height={cH}
            x={gM - cW / 2}
            y={cY + cH + d1 + d2 + d3}
          >
            <BannerCardWrapper>
              <CardBanner bg="salmon.900" borderColor="salmon.900" />
              <Card
                borderColor="grey.900"
                borderLeftWidth={0}
                borderLeftRadius={0}
                color="white"
                bg="grey.900"
              >
                <CardTitle>Your Ask</CardTitle>
                <CardPrice>
                  {formatPricePerShare(askPriceInDollars, false)}
                </CardPrice>
              </Card>
            </BannerCardWrapper>
          </foreignObject>
        </g>
        <path
          stroke={grey200}
          d={`
            M${gM} ${cY + cH + d1 + d2}
            v${d3}
            `}
        />
        <Circle cx={gM} cy={cY + cH + d1 + d2} stroke={salmon900} />
      </g>
    </Box>
  );
};
