import { ChangeEvent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Select, Text, VStack } from "@chakra-ui/react";

import { MappedActiveInvitees } from "@/components/issuers";

const FIRST_OPTION_INDEX = 0;

interface ReassignAdminSelectInputProps {
  readonly activeInvitees: MappedActiveInvitees;
  readonly onReassignAdmin: (targetUserId: string) => void;
}

export function ReassignAdminSelectInput({
  activeInvitees,
  onReassignAdmin,
}: ReassignAdminSelectInputProps) {
  const { t } = useTranslation(`issuers`);

  const options = activeInvitees.map((invitee) => ({
    label: `${invitee?.name} (${invitee?.email})`,
    value: invitee?.id ?? ``,
  }));

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      onReassignAdmin(e.target.value);
    },
    [onReassignAdmin],
  );

  useEffect(() => {
    if (options.length === 1) {
      onReassignAdmin(options[FIRST_OPTION_INDEX].value);
    }
  }, [options, onReassignAdmin]);

  return (
    <VStack spacing={4}>
      <Text>{t(`reassign_admin_description`)}</Text>
      <Select borderColor="grey.700" onChange={handleChange}>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </VStack>
  );
}
