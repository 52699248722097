import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import { Text } from "@chakra-ui/react";

import { TileHeader, TileRow, Skeleton } from "@/components/common";
import { withConfig } from "@/components/hoc";
import {
  ListingPageListingStatusTileListingFragment,
  ListingState,
  RootConfigFragment,
  TransferMethodV2,
} from "@/gql";
import {
  bidLabel,
  getLongDocumentTitleByTransferMethod,
  getShortDocumentTitleByTransferMethod,
  hoursToDays,
  isHiiveFund,
  toTimestamp,
} from "@/utils";

interface ListingStatusTileProps {
  readonly listing: ListingPageListingStatusTileListingFragment;
  readonly config: RootConfigFragment;
}

const StatusTileSkeleton = () => <Skeleton h="5rem" w="full" />;

const StatusTile = withConfig(
  ({ listing, config }: ListingStatusTileProps) => {
    const { t } = useTranslation();

    const listingLiveStatus = isHiiveFund(listing)
      ? t(`listing_live_status_hiive`)
      : t(`listing_live_status`);
    const documentTypeLongText = getLongDocumentTitleByTransferMethod(
      listing.transferMethodV2,
    );
    const documentTypeShortText = getShortDocumentTitleByTransferMethod(
      listing.transferMethodV2,
    );
    const hoursToSign = hoursToDays(config.stnLoiHoursValidHours);
    const orderOrBid = bidLabel(listing).toLowerCase();
    const conditionallySold = t(`conditionally_sold`);
    const pendingClosing = t(`pending_closing`);
    const conditionalText = t(`listing_conditionally_sold_status`, {
      documentTypeLongText,
      documentTypeShortText,
      hoursToSign,
    });

    const statusText = match(listing)
      .with({ state: ListingState.InReview }, () =>
        t(`listing_submitted_in_review`, {
          date: toTimestamp(listing.insertedAt),
        }),
      )
      .with({ state: ListingState.Open }, () => listingLiveStatus)
      .with(
        {
          state: ListingState.ConditionallySold,
          transferMethodV2: TransferMethodV2.Unknown,
        },
        () => `${conditionallySold} - ${conditionalText}`,
      )
      .with(
        {
          state: ListingState.ConditionallySold,
          transferMethodV2: TransferMethodV2.SpvLister,
        },
        () => `${conditionallySold} - ${conditionalText}`,
      )
      .with(
        {
          state: ListingState.ConditionallySold,
          transferMethodV2: TransferMethodV2.SpvThirdparty,
        },
        () => `${conditionallySold} - ${conditionalText}`,
      )
      .with(
        {
          state: ListingState.ConditionallySold,
        },
        () => `${conditionallySold} - ${pendingClosing}`,
      )
      .with(
        { state: P.union(ListingState.Closed, ListingState.Withdrawn) },
        () => t(`listing_closed_status`, { orderOrBid }),
      )
      .otherwise(() => null);

    const showStatusTile =
      !!statusText || !!listing.placedAt || !!listing.expireAt;

    if (!showStatusTile) return null;

    return (
      <TileRow>
        <TileHeader>{t(`status`)}</TileHeader>
        <Text>{statusText}</Text>
      </TileRow>
    );
  },
  { fallback: <StatusTileSkeleton /> },
);

export default StatusTile;
