import { useTranslation } from "react-i18next";

import { Box, Flex, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { OnboardingContainerV2 } from "@/components/onboarding-v2";
import {
  InvestorSuitabilityForm,
  useSuitabilityForm,
} from "@/components/suitability";
import { useTransitionCurrentStepMutation } from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";

const InvestorSuitabilityPageV2 = () => {
  const { t } = useTranslation();
  const actor = useCurrentActor();

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const onSuccess = async () => {
    await transitionCurrentStepMutation();
  };

  const {
    formProps,
    formId,
    questionTree,
    loading,
    country,
    renderDisclaimer,
    version,
  } = useSuitabilityForm({ onSuccess, user: actor });

  return (
    <OnboardingContainerV2 metaTitle="Investor Suitability">
      <Flex direction="column" w={{ base: `full`, lg: `178` }}>
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`investor_suitability_title`)} *
          </Text>
        </VStack>

        <Box>
          <InvestorSuitabilityForm
            formProps={formProps}
            formId={formId}
            loading={loading || isTransitioningCurrentStep}
            questionTree={questionTree}
            country={country.name}
            renderDisclaimer={renderDisclaimer}
            version={version}
          />
          <Flex w="full" justify="flex-end" mt={8}>
            <HiiveButton
              sentryLabel="[InvestorStatusPageV2/Submit]"
              variant="rounded-solid-salmon"
              type="submit"
              size="xl"
              maxW="unset"
              w={{ base: `full`, lg: `unset` }}
              form={formId}
              isLoading={loading || isTransitioningCurrentStep}
            >
              {t(`next`)}
            </HiiveButton>
          </Flex>
        </Box>
      </Flex>
    </OnboardingContainerV2>
  );
};

export default withCurrentActor(InvestorSuitabilityPageV2);
