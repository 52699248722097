import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Link, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";

const InstitutionIsBrokerPage = () => {
  const { t } = useTranslation();

  return (
    <OnboardingContainerV2 metaTitle={t(`thank_you_for_your_interest`)}>
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`thank_you_for_your_interest`)}
          </Text>
        </VStack>
        <Card>
          <CardBody>
            <Flex direction="column" gap={7}>
              <Text>{t(`thank_you_for_your_interest_in_hiive`)}</Text>
              <Text>
                <Trans
                  i18nKey="if_you_have_any_questions"
                  components={{
                    mailto: (
                      <Link
                        variant="chunky"
                        display="block"
                        href="mailto:support@hiive.com"
                      />
                    ),
                  }}
                />
              </Text>
              <Text>
                <Trans
                  i18nKey="if_you_are_not_a_broker_dealer"
                  components={{
                    bold: <Text as="span" fontWeight="bold" />,
                  }}
                />
              </Text>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </OnboardingContainerV2>
  );
};

export default InstitutionIsBrokerPage;
