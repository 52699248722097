import { useContext, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Tabs, HStack, TabList, Tab } from "@chakra-ui/react";

import { TAB_MARGIN_OFFSET } from "@/components/issuers";
import { IssuerStage } from "@/gql";

import { IssuerTransactionsTableContext } from "./IssuerTransactionsProvider";

const getStageIndex = (stage: IssuerStage | null | undefined) =>
  match(stage)
    .with(IssuerStage.InReview, () => 0)
    .with(IssuerStage.InProgress, () => 1)
    .with(IssuerStage.Closed, () => 2)
    .with(IssuerStage.Cancelled, () => 3)
    .otherwise(() => 4);

const getStageFromIndex = (index: number) =>
  match(index)
    .with(0, () => IssuerStage.InReview)
    .with(1, () => IssuerStage.InProgress)
    .with(2, () => IssuerStage.Closed)
    .with(3, () => IssuerStage.Cancelled)
    .otherwise(() => null);

export const IssuerTransactionsStageTabs = () => {
  const { t } = useTranslation(`issuers`);
  const { filterBy, onStageChange } = useContext(
    IssuerTransactionsTableContext,
  );

  const index = useMemo(() => getStageIndex(filterBy?.stage), [filterBy]);

  const tabStyles = {
    flexShrink: 0,
    py: 4,
    borderTopRadius: 8,
  };

  const handleTabChange = useCallback(
    (index: number) => {
      const stage = getStageFromIndex(index);
      onStageChange(stage);
    },
    [onStageChange],
  );

  return (
    <Tabs
      variant="black"
      mt={8}
      mx={TAB_MARGIN_OFFSET}
      w="full"
      index={index}
      onChange={handleTabChange}
    >
      <HStack alignItems="center" justifyContent="space-between" w="100vw">
        <TabList
          alignItems="center"
          pb={0}
          overflow="auto"
          css={{
            "&::-webkit-scrollbar": {
              display: `none`,
            },
            "-msOverflowStyle": `none`, // IE and Edge
            scrollbarWidth: `none`, // Firefox
          }}
        >
          <Tab {...tabStyles}>{t(`in_review`)}</Tab>
          <Tab {...tabStyles}>{t(`in_progress`)}</Tab>
          <Tab {...tabStyles}>{t(`closed`)}</Tab>
          <Tab {...tabStyles}>{t(`cancelled`)}</Tab>
          <Tab {...tabStyles}>{t(`view_all`)}</Tab>
        </TabList>
      </HStack>
    </Tabs>
  );
};
