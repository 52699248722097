import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import {
  IssuerInvitationFragment,
  IssuerInvitationUsersDocument,
  useResendIssuerInvitationMutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";

type IssuersResendInviteModalProps = {
  invitation: IssuerInvitationFragment;
};

export function IssuersResendInvitationUserModal({
  invitation: { id, email },
}: IssuersResendInviteModalProps) {
  const { t } = useTranslation(`issuers`);
  const { closeModal } = useModal();
  const { successToast } = useCustomToast();

  const [resendInvitation, { loading }] = useResendIssuerInvitationMutation({
    refetchQueries: [IssuerInvitationUsersDocument],
  });

  const onSubmit = useCallback(async () => {
    const { data } = await resendInvitation({ variables: { id } });

    if (data?.resendInvitation) {
      closeModal();
      successToast(t(`invite_resent`));
    }
  }, []);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`resend_invite`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" spacing={6}>
          <Text>{t(`resend_invite_to_email`, { email })}</Text>
          <Text>{t(`issuer_portal_expiration_notice_resend_invite`)}</Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            sentryLabel="[IssuerInviteUser/Submit]"
            submitText={t(`resend_invite`)}
            type="submit"
            isLoading={loading}
            onClick={onSubmit}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
}
