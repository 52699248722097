import { ForwardedRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Link,
  Text,
} from "@chakra-ui/react";

import { UserWithInstitutionFragment } from "@/gql";
import { useCurrentActor, useAnalytics } from "@/hooks";
import { getIsInstitutionUser } from "@/utils/user";

const getPath = (actor: UserWithInstitutionFragment) => {
  const identityVerificationPath = `/account/profile/identity-verification`;
  const suitabilityPath = `/account/profile/suitability`;

  if (getIsInstitutionUser(actor)) {
    return suitabilityPath;
  }

  if (!actor.identityVerified) {
    return identityVerificationPath;
  }

  return suitabilityPath;
};

export const CompleteProfileBanner = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const actor = useCurrentActor();
    const { t } = useTranslation();
    const analytics = useAnalytics();

    return (
      <Link
        href={getPath(actor)}
        _hover={{ textDecoration: `none` }}
        onClick={() => {
          analytics.track(`Complete Profile Banner Clicked`, {
            userId: actor.id,
            investorStatus: actor.investorStatus,
            email: actor.email,
            investorType: actor.investorType,
          });
        }}
      >
        <Alert
          status="error"
          w="100%"
          backgroundColor="salmon.50"
          justifyContent="center"
          ref={ref}
        >
          <AlertIcon boxSize="16px" />
          <AlertDescription fontWeight={400} fontSize={14}>
            <Text fontWeight={500} textTransform="uppercase" display="inline">
              {t(`information_needed`)}
            </Text>
            <Text display="inline">
              {` ${t(`complete_profile_to_keep_using`)} `}
              <Text display="inline" decoration="underline" fontWeight={500}>
                {t(`go_to_account_profile`)}
              </Text>
              {` ${t(`do_this_now`)}`}
            </Text>
          </AlertDescription>
        </Alert>
      </Link>
    );
  },
);
