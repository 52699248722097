import { DotsThreeVertical } from "@phosphor-icons/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

import { TableActionButton } from "@/components/table";
import { IssuerInvitationFragment } from "@/gql";
import { useModal } from "@/hooks";
import { getInviteeStatus, InviteeStatus } from "@/utils";

import { IssuerManageUsersTableContext } from "./IssuerManageUsersTableProvider";
import { useAcceptedActionMenuItems } from "./useAcceptedActionMenuItems";

type InvitationActionButtonProps = {
  invitation: IssuerInvitationFragment;
};

type InvitationPendingActionMenuProps = {
  invitation: IssuerInvitationFragment;
};

type InvitationAcceptedActionMenuProps = {
  invitation: IssuerInvitationFragment;
};

type InvitationExpiredActionMenuProps = {
  invitation: IssuerInvitationFragment;
};

function InvitationAcceptedActionMenu({
  invitation,
}: InvitationAcceptedActionMenuProps) {
  const { t } = useTranslation(`issuers`);
  const { modals, onOpenModal } = useModal();
  const { activeInvitees, invitations } = useContext(
    IssuerManageUsersTableContext,
  );

  const {
    showMakeAdmin,
    showReassignAdmin,
    showRemoveAdmin,
    showRemoveUserWarning,
  } = useAcceptedActionMenuItems(invitations, invitation);

  const { invitee } = invitation;

  if (!invitee) {
    return null;
  }

  const filteredActiveInvitees = activeInvitees.filter(
    (activeInvitee) => activeInvitee?.id !== invitee.id,
  );

  return (
    <MenuList>
      {showRemoveAdmin && (
        <MenuItem
          onClick={onOpenModal(
            modals.issuerRemoveAdminRole(
              invitee,
              filteredActiveInvitees,
              showReassignAdmin,
            ),
          )}
        >
          {t(`remove_admin`)}
        </MenuItem>
      )}

      {showMakeAdmin && (
        <MenuItem onClick={onOpenModal(modals.issuerAddAdminRole(invitee))}>
          {t(`make_admin`)}
        </MenuItem>
      )}

      <MenuItem
        onClick={onOpenModal(
          modals.issuerRemoveUser(
            invitee,
            filteredActiveInvitees,
            showReassignAdmin,
            showRemoveUserWarning,
          ),
        )}
      >
        {t(`remove_user`)}
      </MenuItem>
    </MenuList>
  );
}

function InvitationPendingActionMenu({
  invitation,
}: InvitationPendingActionMenuProps) {
  const { t } = useTranslation(`issuers`);
  const { modals, onOpenModal } = useModal();
  return (
    <MenuList>
      <MenuItem
        onClick={onOpenModal(modals.issuerResendInvitation(invitation))}
      >
        {t(`resend_invite`)}
      </MenuItem>
      <MenuItem
        onClick={onOpenModal(modals.issuerCancelInvitation(invitation))}
      >
        {t(`cancel_invitation`)}
      </MenuItem>
    </MenuList>
  );
}

function InvitationDeactivatedActionMenu({
  invitation,
}: InvitationPendingActionMenuProps) {
  const { t } = useTranslation(`issuers`);
  const { modals, onOpenModal } = useModal();

  const { invitee } = invitation;

  if (!invitee) {
    return null;
  }

  return (
    <MenuList>
      <MenuItem onClick={onOpenModal(modals.issuerActivateUser(invitee))}>
        {t(`activate_user`)}
      </MenuItem>
    </MenuList>
  );
}

function InvitationExpiredActionMenu({
  invitation,
}: InvitationExpiredActionMenuProps) {
  const { t } = useTranslation(`issuers`);
  const { modals, onOpenModal } = useModal();

  return (
    <MenuList>
      <MenuItem
        onClick={onOpenModal(modals.issuerResendInvitation(invitation))}
      >
        {t(`resend_invite`)}
      </MenuItem>
    </MenuList>
  );
}

function InvitationActionMenu({ invitation }: InvitationActionButtonProps) {
  const inviteeStatus = getInviteeStatus(invitation);

  return match(inviteeStatus)
    .with(InviteeStatus.Accepted, () => (
      <InvitationAcceptedActionMenu invitation={invitation} />
    ))
    .with(InviteeStatus.Expired, () => (
      <InvitationExpiredActionMenu invitation={invitation} />
    ))
    .with(InviteeStatus.Pending, () => (
      <InvitationPendingActionMenu invitation={invitation} />
    ))
    .with(InviteeStatus.Deactivated, () => (
      <InvitationDeactivatedActionMenu invitation={invitation} />
    ))
    .exhaustive();
}

export function InvitationMobileActionButton({
  invitation,
}: InvitationActionButtonProps) {
  return (
    <Menu autoSelect={false}>
      <MenuButton>
        <DotsThreeVertical size={24} />
      </MenuButton>
      <InvitationActionMenu invitation={invitation} />
    </Menu>
  );
}

export function InvitationActionButton({
  invitation,
}: InvitationActionButtonProps) {
  return (
    <Menu autoSelect={false}>
      <TableActionButton />
      <InvitationActionMenu invitation={invitation} />
    </Menu>
  );
}
