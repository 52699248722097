import { useRouter } from "next/router";

import { WithQuery } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { BidPageContent, BidPageSkeleton } from "@/components/postings";
import {
  BidPageBidFragment,
  useBidPageBidByIdQuery,
  UserWithInstitutionFragment,
} from "@/gql";
import { appendSellerCompanyIdToUrl, getIsUnaccreditedSeller } from "@/utils";

const BidPage = withCurrentActor(
  ({
    id,
    actor,
  }: {
    readonly id: string;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const router = useRouter();
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
    const query = useBidPageBidByIdQuery({
      variables: {
        id: id as string,
      },
      fetchPolicy: `cache-and-network`,
      skip: !router.isReady,
    });

    return (
      <WithQuery query={query} fallback={<BidPageSkeleton />}>
        {({
          data: { bidById: bid },
        }: {
          readonly data: { readonly bidById: BidPageBidFragment };
        }) => {
          if (!!bid.transaction) {
            const url = `/transactions/${bid.transaction.id}`;
            if (isUnaccreditedSeller) {
              router.replace(appendSellerCompanyIdToUrl(url, bid.company.id));
            }
            router.replace(url);
            return null;
          }

          return <BidPageContent bid={bid} />;
        }}
      </WithQuery>
    );
  },
);

export default BidPage;
