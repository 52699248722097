import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useFronteggMutation } from "./useFronteggMutation";
import useMfaDevices from "./useMfaDevices";

interface DisableMfaRequestPayload {
  readonly token: string;
}

const useDisableAppMfa = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { t } = useTranslation();
  const getMfaDevices = useMfaDevices();

  const { state, setState, execute } =
    useFronteggMutation<DisableMfaRequestPayload>();

  const executeDisableMfa = useCallback(
    async (apiUrl: string, body?: DisableMfaRequestPayload) =>
      execute(apiUrl, {
        body,
        fallbackErrorMessage: t(`incorrect_code`),
        onSuccess,
      }),
    [execute],
  );

  const disableMfa = useCallback(
    async (body?: DisableMfaRequestPayload) => {
      const mfaDevices = await getMfaDevices();

      if (mfaDevices instanceof Error) {
        return setState((currentState) => ({
          ...currentState,
          error: new Error(mfaDevices.message),
        }));
      }

      const { authenticators } = mfaDevices;

      if (authenticators.length === 0) {
        return setState((currentState) => ({
          ...currentState,
          error: new Error(t(`no_mfa_device_found`)),
        }));
      }

      const [{ id: deviceId }] = authenticators;

      const apiUrl = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/authenticator/${deviceId}/disable/verify`;

      return executeDisableMfa(apiUrl, body);
    },
    [getMfaDevices, t],
  );

  return [disableMfa, state] as const;
};

export default useDisableAppMfa;
