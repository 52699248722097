import { Card, CardBody, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import { useUserActivityMyActivityQuery } from "@/gql";

import ActiveTransactionItemCard from "./ActiveTransactionItemCard";
import ActiveTransactionsCardHeader from "./ActiveTransactionsCardHeader";

const IndividualActiveTransactionsCard = () => {
  const { data, loading } = useUserActivityMyActivityQuery({
    fetchPolicy: `network-only`,
  });

  const activeTransactions = data?.myActivity.myTransactions;

  if (activeTransactions?.length === 0 && !loading) return null;

  return (
    <Card
      _hover={{
        boxShadow: `none`,
      }}
      boxShadow="none"
      shadow="none"
      w="full"
    >
      <ActiveTransactionsCardHeader
        transactionsCount={activeTransactions?.length}
      />
      <CardBody as={VStack} gap={4} maxH="xs" overflowY="auto">
        {loading && (
          <VStack gap={4} w="full">
            <Skeleton h={10} />
            <Skeleton h={10} />
            <Skeleton h={10} />
          </VStack>
        )}
        {!loading &&
          activeTransactions?.map((transaction) => (
            <ActiveTransactionItemCard
              key={transaction.id}
              taskCount={transaction.myTasks.length}
              transaction={transaction}
            />
          ))}
      </CardBody>
    </Card>
  );
};

export default IndividualActiveTransactionsCard;
