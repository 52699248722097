import { Form } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ButtonFooterWrapper, HiiveSubmitButton } from "@/components/common";
import { PhoneNumberInput, FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  UserWithInstitutionFragment,
  useUpdateUserPhoneNumberMutation,
} from "@/gql";
import { useCustomToast } from "@/hooks";
import { ToastDuration } from "@/types/toast";
import { validPhoneNumber } from "@/utils";
import { normalizePhoneNumber } from "@/utils/format";

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .nullable()
    .required(`Phone number is required`)
    .test(
      `valid phone number`,
      `Please enter a valid phone number`,
      validPhoneNumber,
    ),
});

interface ChangePhoneNumberFormValues {
  readonly phoneNumber: string;
}

const createInitialValues = (phoneNumber: string) => ({
  phoneNumber,
});

const mapVariables = (values: ChangePhoneNumberFormValues) => ({
  phoneNumber: normalizePhoneNumber(values.phoneNumber),
});

export const ChangePhoneCard = withCurrentActor(
  ({
    actor: { phoneNumber },
  }: {
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { successToast } = useCustomToast();
    const mutation = useUpdateUserPhoneNumberMutation();

    const onSuccess = () =>
      successToast(`Updated successfully!`, { duration: ToastDuration.Long });

    const initialValues = createInitialValues(phoneNumber);
    const phoneFieldName = `phoneNumber`;

    return (
      <FormikQL
        mutation={mutation}
        mutationNames={[`updateUserPhoneNumber`]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        mapVariables={mapVariables}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          const phoneNumberMatches =
            normalizePhoneNumber(phoneNumber) ===
            normalizePhoneNumber(values.phoneNumber);
          const handleChange = (value: string) =>
            setFieldValue(phoneFieldName, `+${value}`);

          return (
            <Card w="full" flex="1" as={Form} autoComplete="off">
              <CardHeader>
                <Text textStyle="heading-sm">Change Phone</Text>
              </CardHeader>
              <CardBody p={{ base: 4, lg: 10 }}>
                <SimpleGrid columns={2} columnGap={9}>
                  <GridItem colSpan={{ base: 2, xl: 1 }}>
                    <VStack spacing={6}>
                      <PhoneNumberInput
                        name={phoneFieldName}
                        label="Telephone"
                        onChange={handleChange}
                      />
                      <ButtonFooterWrapper>
                        <HiiveSubmitButton
                          submitText="Update"
                          type="submit"
                          sentryLabel="[ChangePhone/Submit]"
                          isLoading={isSubmitting}
                          isDisabled={phoneNumberMatches}
                        />
                      </ButtonFooterWrapper>
                    </VStack>
                  </GridItem>
                </SimpleGrid>
              </CardBody>
            </Card>
          );
        }}
      </FormikQL>
    );
  },
);
