import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { DateTableCell } from "@/components/issuers";
import { TableCell, TableHeader } from "@/components/table";
import { IssuerStage } from "@/gql";

import { IssuerTransactionsDaysAgoBadge } from "./IssuerTransactionsDaysAgoBadge";
import { NonNullableTransactionWorkflowEdge } from "./IssuerTransactionsProvider";
import { IssuerTransactionsStageBadge } from "./IssuerTransactionsStageBadge";
import {
  getCancelledDate,
  getClosedDate,
  getDaysSinceSubmission,
  getNoticeReceivedDate,
} from "./util";

interface StageCellProps {
  readonly stage: IssuerStage;
}

export function StageCell({ stage }: StageCellProps) {
  return (
    <TableCell>
      <IssuerTransactionsStageBadge stage={stage} />
    </TableCell>
  );
}

interface StageDateTableHeaderProps {
  readonly stage: IssuerStage | null | undefined;
}

export function StageDateTableHeader({ stage }: StageDateTableHeaderProps) {
  const { t } = useTranslation(`issuers`);

  return match(stage)
    .with(IssuerStage.InReview, () => (
      <TableHeader>{t(`days_since_submission`)}</TableHeader>
    ))
    .with(IssuerStage.Closed, () => (
      <TableHeader>{t(`date_closed`)}</TableHeader>
    ))
    .with(IssuerStage.Cancelled, () => (
      <TableHeader>{t(`date_cancelled`)}</TableHeader>
    ))
    .otherwise(() => <TableHeader>{t(`notice_date`)}</TableHeader>);
}

interface StageDateTableCellProps {
  readonly stage: IssuerStage | null | undefined;
  readonly workflow: NonNullableTransactionWorkflowEdge["node"]["workflow"];
}

export function StageDateTableCell({
  stage,
  workflow,
}: StageDateTableCellProps) {
  return match(stage)
    .with(IssuerStage.InReview, () => (
      <TableCell>
        <IssuerTransactionsDaysAgoBadge
          daysAgo={getDaysSinceSubmission(workflow)}
        />
      </TableCell>
    ))
    .with(IssuerStage.Closed, () => (
      <DateTableCell>{getClosedDate(workflow)}</DateTableCell>
    ))
    .with(IssuerStage.Cancelled, () => (
      <DateTableCell>{getCancelledDate(workflow)}</DateTableCell>
    ))
    .otherwise(() => (
      <DateTableCell>{getNoticeReceivedDate(workflow)}</DateTableCell>
    ));
}
