import { useField } from "formik";
import { useTranslation } from "react-i18next";

import { FormErrorMessage } from "@chakra-ui/react";

import { Listbox } from "@/components/form";
import { TransactingEntityItemEntityFragment } from "@/gql";
import { useListbox } from "@/hooks";

import EntitiesOption from "./EntitiesOption";

type EntitiesSelectProps = {
  entities: TransactingEntityItemEntityFragment[];
  onChange: (entity: TransactingEntityItemEntityFragment) => void;
};

const selectedOption = (value: TransactingEntityItemEntityFragment) => (
  <EntitiesOption option={value} selected />
);

const EntitiesSelect = ({ entities, onChange }: EntitiesSelectProps) => {
  const { t } = useTranslation();
  const [, { error, value: selectedItem }] = useField(`entityId`);
  const selectedEntity = entities.find(
    (entity) => selectedItem === entity?.id,
  )!;
  const { buttonProps, menuProps, itemProps } =
    useListbox<TransactingEntityItemEntityFragment>({
      onClickItem: onChange,
      items: entities,
      itemToString: (item) => item.legalName,
      selectedItem,
      buttonStyle: {
        height: `inherit`,
        minHeight: 48,
      },
      itemRenderer: () => selectedOption(selectedEntity),
    });

  return (
    <Listbox.Container>
      <Listbox.Button<TransactingEntityItemEntityFragment>
        placeholder={t(`select_entity`)}
        {...buttonProps}
      />
      <Listbox.Menu {...menuProps} mt={[2, 2]}>
        {entities.map((item, index) => (
          <Listbox.Item<TransactingEntityItemEntityFragment>
            key={item?.id}
            px={4}
            item={item}
            index={index}
            {...itemProps}
          >
            <EntitiesOption option={item} />
          </Listbox.Item>
        ))}
      </Listbox.Menu>
      <FormErrorMessage>{error}</FormErrorMessage>
    </Listbox.Container>
  );
};

export default EntitiesSelect;
