import { useTranslation } from "react-i18next";

import { HStack, Text, VStack } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import {
  UnaccreditedSellerActiveBidNotificationsCount,
  UnaccreditedSellerInlineNotificationsProvider,
  UnaccreditedSellerInquiryNotificationsCount,
  UnaccreditedSellerPastBidNotificationsCount,
  UnaccreditedSellerStandingBidNotificationsCount,
  UnaccreditedSellerTransactionNotificationsCount,
} from "@/components/unaccredited-seller";
import { useUnaccreditedSellerNavbarMyActivityQuery } from "@/gql";
import { getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard } from "@/utils";

import { NavMenuLink } from "./NavMenuLink";

export const UnaccreditedSellerNavMenu = ({
  onClick,
}: {
  readonly onClick: () => void;
}) => {
  const { t } = useTranslation();
  const query = useUnaccreditedSellerNavbarMyActivityQuery();

  return (
    <UnaccreditedSellerInlineNotificationsProvider>
      <WithQuery query={query}>
        {({
          data: {
            unaccreditedSellerMyActivity,
            unaccreditedSellerMyCompanyActivity,
          },
        }) => {
          const { myCompany, myListing } = unaccreditedSellerMyActivity;

          if (
            !getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard(
              unaccreditedSellerMyActivity,
              unaccreditedSellerMyCompanyActivity,
            ) &&
            !myListing
          ) {
            return null;
          }

          return (
            <VStack spacing={0} alignItems="flex-start" w="full">
              {!!myListing && (
                <NavMenuLink onClick={onClick} href="/dashboard/active-bids">
                  <HStack justifyContent="space-between">
                    <Text>{t(`active_bids`)}</Text>
                    <UnaccreditedSellerActiveBidNotificationsCount />
                  </HStack>
                </NavMenuLink>
              )}
              {!!myListing && (
                <NavMenuLink onClick={onClick} href="/dashboard/inquiries">
                  <HStack justifyContent="space-between">
                    <Text>{t(`inquiries`)}</Text>
                    <UnaccreditedSellerInquiryNotificationsCount />
                  </HStack>
                </NavMenuLink>
              )}
              <NavMenuLink onClick={onClick} href="/dashboard/standing-bids">
                <HStack justifyContent="space-between">
                  <Text>
                    {myCompany.name} {t(`standing_bids`)}
                  </Text>
                  <UnaccreditedSellerStandingBidNotificationsCount />
                </HStack>
              </NavMenuLink>
              <NavMenuLink onClick={onClick} href="/dashboard/transactions">
                <HStack justifyContent="space-between">
                  <Text>{t(`transactions`)}</Text>
                  <UnaccreditedSellerTransactionNotificationsCount />
                </HStack>
              </NavMenuLink>
              {!!myListing && (
                <NavMenuLink onClick={onClick} href="/dashboard/past-bids">
                  <HStack justifyContent="space-between">
                    <Text>{t(`past_bids`)}</Text>
                    <UnaccreditedSellerPastBidNotificationsCount />
                  </HStack>
                </NavMenuLink>
              )}
              <NavMenuLink onClick={onClick} href="/dashboard/overview">
                {myCompany.name} {t(`overview`)}
              </NavMenuLink>
            </VStack>
          );
        }}
      </WithQuery>
    </UnaccreditedSellerInlineNotificationsProvider>
  );
};
