import { MagnifyingGlass } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { Center, useToken } from "@chakra-ui/react";

export const SearchButton = ({ onClick }: { readonly onClick: () => void }) => {
  const { t } = useTranslation();
  const [grey700] = useToken(`colors`, [`grey.700`]);

  return (
    <Center
      aria-label={t(`search_icon`)}
      onClick={onClick}
      as="button"
      w={10}
      h={10}
      borderColor="grey.100"
      _hover={{
        bg: `grey.50`,
      }}
      transition=".15s background-color ease-in-out"
      borderWidth="1px"
      borderRadius="100%"
    >
      <MagnifyingGlass size={20} color={grey700} />
    </Center>
  );
};
