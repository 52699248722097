import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Spacer, Text, VStack } from "@chakra-ui/react";

import { IssuerStage } from "@/gql";

import { IssuerTransactionsTableContext } from "./IssuerTransactionsProvider";
import { IssuerTransactionsStageDescription } from "./IssuerTransactionsStageDescription";

export const IssuerTransactionsCardListEmptyState = () => {
  const { t } = useTranslation(`issuers`);
  const { filterBy } = useContext(IssuerTransactionsTableContext);

  const { stage } = filterBy ?? {};

  const description = match(stage)
    .with(IssuerStage.InReview, () =>
      t(`no_in_review_transactions_description`),
    )
    .with(IssuerStage.InProgress, () =>
      t(`no_in_progress_transactions_description`),
    )
    .with(IssuerStage.Closed, () => t(`no_closed_transactions_description`))
    .with(IssuerStage.Cancelled, () =>
      t(`no_cancelled_transactions_description`),
    )
    .otherwise(() => t(`no_transactions_description`));

  return (
    <VStack py={8} px={4} spacing={4} w="full" alignItems="flex-start">
      <IssuerTransactionsStageDescription />
      <Spacer mb={12} />
      <VStack w="full">
        <Text textStyle="heading-lg">{t(`no_transactions_yet`)}</Text>
        <Text textAlign="center" textColor="grey.600">
          {description}
        </Text>
      </VStack>
    </VStack>
  );
};
