import isEmpty from "lodash/isEmpty";
import { ParsedUrlQuery } from "node:querystring";
import { useCallback } from "react";

import { useRouter } from "next/router";

import { IssuerStage } from "@/gql";

const DEFAULT_QUERY_PARAMS = {
  stage: IssuerStage.InReview,
};

export const useQueryParams = () => {
  const { pathname, query, replace } = useRouter();

  const queryParams = isEmpty(query) ? DEFAULT_QUERY_PARAMS : query;

  const updateQueryParams = useCallback(
    (newQueryParams: ParsedUrlQuery) => {
      const mergedQuery = {
        ...query,
        ...newQueryParams,
      };

      const updatedQuery = Object.fromEntries(
        Object.entries(mergedQuery).filter(([_, value]) => !!value),
      );

      replace(
        {
          pathname,
          query: updatedQuery,
        },
        undefined,
        { shallow: true },
      );
    },
    [pathname, query, replace],
  );

  return { queryParams, updateQueryParams };
};
