import { sentenceCase } from "change-case";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { ExecutionMilestone, TransactionState } from "@/gql";

type StateLabelKey = TransactionState | ExecutionMilestone;
const useTransactionDetails = () => {
  const { t } = useTranslation(`dashboard`);

  const getStateLabel = (key: StateLabelKey) => {
    const label = match<StateLabelKey>(key)
      .with(TransactionState.AwaitingClosing, () => t(`awaiting_closing`))
      .with(TransactionState.BidAccepted, () => t(`bid_accepted`))
      .with(TransactionState.Cancelled, () => t(`cancelled`))
      .with(TransactionState.ClosedFeePaid, () => t(`closed_fee_paid`))
      .with(TransactionState.ClosedFeePending, () => t(`closed_fee_pending`))
      .with(TransactionState.Expired, () => t(`expired`))
      .with(TransactionState.InReview, () => t(`in_review`))
      .with(TransactionState.IssuerApprovalDeclined, () =>
        t(`issuer_approval_declined`),
      )
      .with(TransactionState.IssuerApproved, () => t(`issuer_approval`))
      .with(TransactionState.Pending, () => t(`pending`))
      .with(ExecutionMilestone.Accepted, () => t(`accepted`))
      .with(ExecutionMilestone.Approved, () => t(`approved`))
      .with(ExecutionMilestone.Complete, () => t(`complete`))
      .with(ExecutionMilestone.Confirmed, () => t(`confirmed`))
      .with(ExecutionMilestone.Signed, () => t(`signed`))
      .with(ExecutionMilestone.Submitted, () => t(`submitted`))
      .otherwise(() => ``);

    return sentenceCase(label);
  };

  return {
    getStateLabel,
  };
};

export default useTransactionDetails;
