/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikValues, useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import { StepFormikQLProps, SubmitMutationProps } from "@/components/form";
import { InputError } from "@/gql";

import useCustomToast from "./useCustomToast";

type Validators<TStepKeys extends string> = {
  readonly [key in TStepKeys]?: {
    readonly validationSchema: Yup.ObjectSchema<any>;
    readonly onSuccess: () => void;
  };
};

const useStepFormikQL = <
  TStepKeys extends string,
  TMutationData,
  TMutationVariables,
  TFormValues extends FormikValues,
>({
  mutation,
  mutationNames,
  mapVariables,
  initialValues,
  stepRouter,
}: Omit<
  StepFormikQLProps<TStepKeys, TMutationData, TMutationVariables, TFormValues>,
  "children" | "context"
>) => {
  const [validators, setValidators] = useState<Validators<TStepKeys>>({});

  const [mutationData, setMutationData] = useState<
    TMutationData | undefined | null
  >(null);

  const registerValidator = ({
    stepKey,
    validator,
  }: {
    readonly stepKey: TStepKeys;
    readonly validator: {
      readonly validationSchema: Yup.ObjectSchema<any>;
      readonly onSuccess: () => void;
    };
  }) =>
    setValidators((validators) => ({
      ...validators,
      [stepKey]: validator,
    }));

  const [runMutation] = mutation;
  const { errorToast } = useCustomToast();

  const handleMutationErrorRedirect = (
    mutationErrors: readonly InputError[],
  ) => {
    const mutationErrorFields = mutationErrors.map(({ field }) => field);
    stepRouter.stepsInfo.stepOrder.some((stepKey) => {
      const validator = validators[stepKey];
      if (!validator) return false;

      const validationSchemaFields = Object.keys(
        validator.validationSchema.fields,
      );

      if (
        !validationSchemaFields.some((validationSchemaField) =>
          mutationErrorFields.includes(validationSchemaField),
        )
      )
        return false;

      stepRouter.stepControls.jumpToStep(stepKey);
      return true;
    });
  };

  const submitMutation =
    ({
      values,
      setSubmitting,
      setTouched,
      setFieldTouched,
      setFieldError,
    }: SubmitMutationProps<TFormValues>) =>
    () =>
      new Promise<TMutationData>((resolve) => {
        setSubmitting(true);

        runMutation({
          variables: mapVariables(values),
        }).then(({ data }) => {
          setMutationData(data);

          const errors: readonly string[] = mutationNames
            .filter((mutationName) => {
              if ((data as Record<string, any>)[mutationName]) return false;
              return true;
            })
            .map((mutationName) => `mutationName ${mutationName} is invalid`);

          if (errors.length > 0) {
            errorToast(errors.join(`, `));
            return { errors, mutationErrors: [] };
          }

          const mutationErrors = mutationNames.flatMap((mutationName) => {
            const mutationData = (data as Record<string, any>)[mutationName];

            return mutationData.errors ? mutationData.errors : [];
          });

          if (mutationErrors.length === 0 && !!data) {
            setSubmitting(false);
            setTouched({});
            return resolve(data);
          }

          setSubmitting(false);
          handleMutationErrorRedirect(mutationErrors);

          return mutationErrors.forEach((err: InputError) => {
            setFieldError(err.field, err.message);
            setFieldTouched(err.field);
          });
        });
      });

  const activeValidator = validators[stepRouter.stepsInfo.currentStepKey];

  const validationSchema = !!activeValidator
    ? activeValidator.validationSchema
    : null;

  const onSuccess = !!activeValidator ? activeValidator.onSuccess : () => null;

  const formikProps = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
      onSuccess();
    },
  });

  return {
    formikProps,
    submitMutation,
    mutationData,
    registerValidator,
  };
};

export default useStepFormikQL;
