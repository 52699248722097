import {
  Card,
  CardBody,
  CardHeader,
  Fade,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";

import {
  HiiveButton,
  InternalLink,
  WithQuery,
  Skeleton,
} from "@/components/common";
import { usePostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery } from "@/gql";

const SellNowCardSkeleton = () => (
  <Card w="full">
    <CardHeader>
      <Skeleton h={5} maxW="45%" />
    </CardHeader>
    <CardBody>
      <Flex direction="column" gap={4}>
        <Skeleton h={3} count={5} />
        <Skeleton h={3} maxW="35%" />
        <Skeleton h={12} borderRadius="full" maxW="70%" m="0 auto" />
      </Flex>
    </CardBody>
  </Card>
);

const SellNowCardContent = () => (
  <Card w="full">
    <CardHeader>
      <Text textStyle="heading-sm">Want to sell now?</Text>
    </CardHeader>
    <CardBody>
      <VStack spacing={7}>
        <Text>
          Browse standing bids, which are offers made by potential buyers to buy
          up to a specific quantity of stock at a certain price. You can message
          the buyer with any questions. You cannot counter-offer standing bids,
          but you can ask the buyer to modify their price.
        </Text>
        <InternalLink href="/dashboard/standing-bids">
          <HiiveButton
            boxShadow="card"
            px={16}
            size="xl"
            variant="rounded-solid-salmon"
            sentryLabel="[SellNowCardContent] See Buyers"
          >
            See Other Buyers
          </HiiveButton>
        </InternalLink>
      </VStack>
    </CardBody>
  </Card>
);

export const SellNowCard = () => {
  const query =
    usePostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery();

  return (
    <WithQuery query={query} fallback={<SellNowCardSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myListing },
        },
      }) => {
        if (!myListing) return null;

        return (
          <Fade in>
            <SellNowCardContent />
          </Fade>
        );
      }}
    </WithQuery>
  );
};
