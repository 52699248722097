import { Resources } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Text } from "@chakra-ui/react";

import {
  BidPageBuyerActivityBidFragment,
  BidPageSellerActivityBidFragment,
  TransactingEntityItemEntityFragment,
} from "@/gql";

import EntityCardLayout from "./EntityCardLayout";
import EntityForm from "./EntityForm";

export enum EntityCardTypes {
  Buyer,
  Seller,
}

export type EntityCardProps = {
  children?: React.ReactNode;
  displayId: string;
  entities?: TransactingEntityItemEntityFragment[];
  isInstitution: boolean;
  transaction:
    | BidPageSellerActivityBidFragment["transaction"]
    | BidPageBuyerActivityBidFragment["transaction"];
  type: EntityCardTypes;
};

const EntityCard = ({
  children,
  displayId,
  entities = [],
  isInstitution,
  transaction,
  type,
}: EntityCardProps) => {
  const { t } = useTranslation(`transactions`);
  const isBuyer = type === EntityCardTypes.Buyer;
  const hasEntity = isBuyer
    ? !!transaction?.buyerEntity?.id
    : !!transaction?.sellerEntity?.id;

  const titleTranslationKey = match<
    {
      isBuyer: boolean;
      isInstitution: boolean;
      hasEntity: boolean;
    },
    keyof Resources["transactions"]
  >({
    isBuyer,
    isInstitution,
    hasEntity,
  })
    .with(
      {
        isBuyer: true,
        isInstitution: false,
        hasEntity: false,
      },
      () => `confirm_buyer_information`,
    )
    .with(
      {
        isBuyer: true,
        isInstitution: true,
        hasEntity: false,
      },
      () => `confirm_purchasing_entity`,
    )
    .with(
      {
        isBuyer: false,
        isInstitution: false,
        hasEntity: false,
      },
      () => `confirm_seller_information`,
    )
    .with(
      {
        isBuyer: false,
        isInstitution: true,
        hasEntity: false,
      },
      () => `confirm_selling_entity`,
    )
    .otherwise(() => `transacting_entity`);

  return (
    <EntityCardLayout
      form={
        !hasEntity ? (
          <EntityForm
            displayId={displayId}
            entities={entities}
            isInstitution={isInstitution}
            transactionId={transaction!.id}
            type={type}
          />
        ) : undefined
      }
      hasEntity={hasEntity}
      title={
        <Text textStyle={{ base: `heading-xl`, md: `heading-2xl` }}>
          {t(titleTranslationKey)}
        </Text>
      }
    >
      {children}
    </EntityCardLayout>
  );
};

export default EntityCard;
