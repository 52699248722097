import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Link, ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import {
  UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment,
  UnaccreditedSellerAcceptStandingBidV2Mutation,
} from "@/gql";
import {
  appendSellerCompanyIdToUrl,
  formatPricePerShare,
  formatShares,
  getFullDocumentTitleByTransferMethod,
  getTransferMethodByUnaccreditedSellerTransferMethodInput,
  getV2TransferMethodFromV1,
  makeUrl,
} from "@/utils";

import { useUnaccreditedSellerAcceptStandingBidSequenceModalContext } from "./UnaccreditedSellerAcceptStandingBidSequenceModalContext";
import { StepKeys } from "./steps";
import { UnaccreditedSellerAcceptStandingBidSequenceModalFormValues } from "./types";

interface UnaccreditedSellerAcceptStandingBidConfirmationModalProps
  extends StepPropsV2<
    StepKeys,
    UnaccreditedSellerAcceptStandingBidSequenceModalFormValues
  > {
  readonly standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment;
}

const UnaccreditedSellerAcceptStandingBidConfirmationModal = ({
  stepRouter,
  standingBid,
  isSubmitting,
  values,
}: UnaccreditedSellerAcceptStandingBidConfirmationModalProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { submitMutation } =
    useUnaccreditedSellerAcceptStandingBidSequenceModalContext();

  const { stepControls } = stepRouter;

  const onClickSubmit = () =>
    submitMutation().then(
      (response: UnaccreditedSellerAcceptStandingBidV2Mutation) => {
        stepControls.nextStep();
        const acceptedBid = response.unaccreditedSellerAcceptStandingBidV2.bid;
        if (!acceptedBid) return;

        router.push(
          appendSellerCompanyIdToUrl(
            makeUrl(acceptedBid),
            standingBid.company.id,
          ),
        );
      },
    );

  const transferMethod =
    getTransferMethodByUnaccreditedSellerTransferMethodInput(
      values.transferMethod,
    );

  const documentTitle = getFullDocumentTitleByTransferMethod(
    getV2TransferMethodFromV1(transferMethod),
  );

  return (
    <>
      <HiiveModalHeader>
        {t(`are_you_sure_accept`, { bidType: `bid` })}
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start">
          <Text textStyle="heading-2xl">
            Are you sure you want to accept this standing bid?
          </Text>
          <Text>
            By accepting this bid, you indicate to the buyer that you intend to
            sell at this price and quantity.
          </Text>
          <Text>
            After you accept the bid, you and the buyer will execute an {` `}
            {documentTitle}
            {` `}
            to document your agreement to sell.
          </Text>
          <Text>
            The sale may be conditional on issuer approval and other processes.
            If this sale closes, at closing, you will owe Hiive a fee as set out
            in the{` `}
            <Link
              href="/terms-and-conditions"
              textDecor="underline"
              target="_blank"
            >
              Terms & Conditions
            </Link>
            .
          </Text>
          <Text>
            {formatShares(values.numShares)}
            {` `}
            shares of {standingBid.company.name} @{` `}
            {formatPricePerShare(values.pricePerShare, false)}/sh{` `}
            {transferMethod}
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[UnaccreditedSellerAcceptStandingBidConfirmation/Cancel]"
            onCancel={stepControls.previousStep}
          />
        </Show>
        <HiiveSubmitButton
          sentryLabel="[UnaccreditedSellerAcceptStandingBidConfirmation/Submit]"
          isLoading={isSubmitting}
          submitText="Confirm"
          type="button"
          onClick={onClickSubmit}
        />
      </HiiveModalFooter>
    </>
  );
};

export default UnaccreditedSellerAcceptStandingBidConfirmationModal;
