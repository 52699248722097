import { VStack, useDisclosure, Box } from "@chakra-ui/react";

import { IssuerMobileNavContent, NAV_CONTENT_ITEMS } from "@/components/nav";

import { IssuerMobileNavHeader } from "./IssuerMobileNavBarHeader";
import { IssuerMobileNavFooter } from "./IssuerMobileNavFooter";

export function IssuerMobileNav() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box w="100vw">
      {isOpen ? (
        <VStack
          position="absolute"
          w="100vw"
          h="100vh"
          bg="white"
          top={0}
          left={0}
          zIndex={1}
          align="stretch"
          justifyContent="space-between"
        >
          <VStack gap={0}>
            <IssuerMobileNavHeader onToggle={onToggle} isOpen={isOpen} />
            <IssuerMobileNavContent
              isOpen={isOpen}
              content={NAV_CONTENT_ITEMS}
              onToggle={onToggle}
            />
          </VStack>
          <IssuerMobileNavFooter onToggle={onToggle} />
        </VStack>
      ) : (
        <IssuerMobileNavHeader onToggle={onToggle} isOpen={isOpen} />
      )}
    </Box>
  );
}
