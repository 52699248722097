import {
  CreateEntityModal,
  CreateEntityModalProps,
  CreateEntityModalTypes,
} from "@/components/entities";

type CreateBuyerEntityModalProps = Omit<CreateEntityModalProps, "type">;

const CreateBuyerEntityModal = (props: CreateBuyerEntityModalProps) => (
  <CreateEntityModal type={CreateEntityModalTypes.Buyer} {...props} />
);

export default CreateBuyerEntityModal;
