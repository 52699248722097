import { match } from "ts-pattern";

import { InvitationStatus, IssuerInvitationFragment } from "@/gql";

export enum InviteeStatus {
  Accepted = `ACCEPTED`,
  Pending = `PENDING`,
  Expired = `EXPIRED`,
  Deactivated = `DEACTIVATED`,
}

export function getInviteeStatus({
  status,
  invitee,
}: IssuerInvitationFragment) {
  if (invitee?.isActive === false) {
    return InviteeStatus.Deactivated;
  }

  return match({
    emailVerified: !!invitee?.emailVerified,
    status,
  })
    .with(
      {
        emailVerified: true,
        status: InvitationStatus.Accepted,
      },
      () => InviteeStatus.Accepted,
    )
    .with(
      {
        emailVerified: false,
        status: InvitationStatus.Accepted,
      },
      () => InviteeStatus.Pending,
    )
    .with(
      {
        status: InvitationStatus.Pending,
      },
      () => InviteeStatus.Pending,
    )
    .with(
      {
        status: InvitationStatus.Expired,
      },
      () => InviteeStatus.Expired,
    )
    .exhaustive();
}
