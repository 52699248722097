import { Question } from "@phosphor-icons/react";
import { ReactNode } from "react";

import { Box, BoxProps, Placement } from "@chakra-ui/react";

import CustomTooltip from "./CustomTooltip";

interface QuestionTooltipProps extends BoxProps {
  readonly tooltipContent: ReactNode;
  readonly tooltipSize?: string;
  readonly href?: string;
  readonly placement?: Placement;
  readonly iconSize?: number;
  readonly iconColor?: string;
}

const QuestionTooltip = ({
  tooltipContent,
  tooltipSize,
  href,
  placement,
  iconSize = 24,
  iconColor = `#F15F61`,
  ...boxProps
}: QuestionTooltipProps) => {
  const onClick = () => {
    if (!!href) window.open(href, `_blank`);
  };

  return (
    <CustomTooltip
      placement={placement}
      tooltipContent={tooltipContent}
      tooltipSize={tooltipSize}
    >
      <Box {...boxProps}>
        <Question
          cursor={!!href ? `pointer` : `default`}
          onClick={onClick}
          size={iconSize}
          color={iconColor}
        />
      </Box>
    </CustomTooltip>
  );
};

export default QuestionTooltip;
