import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { ReactNode, useCallback } from "react";

import {
  Tr,
  Tbody,
  Td,
  Box,
  Table as ChakraTable,
  TableContainer as ChakraTableContainer,
  TableProps as ChakraTableProps,
  Thead,
  Th,
  TableRowProps as ChakraTableRowProps,
  TableCellProps as ChakraTableCellProps,
  TableBodyProps as ChakraTableBodyProps,
  TableHeadProps as ChakraTableHeadProps,
  TableColumnHeaderProps,
  VStack,
  HStack,
} from "@chakra-ui/react";

export enum TableSortDirection {
  Asc = `asc`,
  Desc = `desc`,
}

type TableProps = {
  children: ReactNode;
} & ChakraTableProps;

type TableRowProps =
  | ({
      children: ReactNode;
    } & ChakraTableRowProps & { striping: true; index: number })
  | (ChakraTableRowProps & { striping?: false; index?: number });

type TableCellProps = ChakraTableCellProps;

type TableRowsProps = {
  children: ReactNode;
} & ChakraTableBodyProps;

type TableHeadersProps = {
  children: ReactNode;
} & ChakraTableHeadProps;

type SortableTableHeaderProps = {
  children: ReactNode;
  direction?: TableSortDirection;
} & TableColumnHeaderProps;

type TableHeaderProps = TableColumnHeaderProps;

function TableSortIndicator({ direction }: { direction?: TableSortDirection }) {
  return (
    <VStack gap={0} position="relative">
      <Box
        as={CaretUp}
        size={11}
        weight="fill"
        color={direction === TableSortDirection.Asc ? `grey.900` : `grey.200`}
      />
      <Box
        as={CaretDown}
        size={11}
        mt={-1}
        weight="fill"
        color={direction === TableSortDirection.Desc ? `grey.900` : `grey.200`}
      />
    </VStack>
  );
}

export function Table({ children, ...restProps }: TableProps) {
  return (
    <ChakraTableContainer w="full" {...restProps}>
      <ChakraTable>{children}</ChakraTable>
    </ChakraTableContainer>
  );
}

export function TableRow({
  onClick,
  children,
  striping,
  index,
  ...restProps
}: TableRowProps) {
  const getRowBackgroundColor = useCallback(
    (index: number) => (index % 2 === 0 ? `white` : `grey.25`),
    [],
  );

  const bg = striping ? getRowBackgroundColor(index) : `transparent`;

  return (
    <Tr
      bg={bg}
      w="full"
      onClick={onClick}
      _hover={
        onClick
          ? {
              bg: `grey.50`,
            }
          : undefined
      }
      {...restProps}
    >
      {children}
    </Tr>
  );
}

export function TableRows({ children, ...restProps }: TableRowsProps) {
  return <Tbody {...restProps}>{children}</Tbody>;
}

export function TableCell({ children, onClick, ...restProps }: TableCellProps) {
  return (
    <Td
      cursor={onClick ? `pointer` : `default`}
      bg="transparent"
      onClick={onClick}
      {...restProps}
    >
      {children}
    </Td>
  );
}

export function TableHeaders({ children, ...restProps }: TableHeadersProps) {
  return (
    <Thead {...restProps}>
      <Tr>{children}</Tr>
    </Thead>
  );
}

export function TableHeader({ children, ...restProps }: TableHeaderProps) {
  return <Th {...restProps}>{children}</Th>;
}

export function TableSortableHeader({
  children,
  direction,
  ...restProps
}: SortableTableHeaderProps) {
  return (
    <Th _hover={{ cursor: `pointer` }} {...restProps}>
      <HStack>
        <Box>{children}</Box>
        <TableSortIndicator direction={direction} />
      </HStack>
    </Th>
  );
}
