import { useCurrentActor } from "@/hooks";
import {
  getIsBroker,
  getIsInstitutionUser,
  getIsUnaccreditedSeller,
} from "@/utils";

import { BrokerAwaitingApprovalPage } from "./BrokerAwaitingApprovalPage";
import { InstitutionAwaitingApprovalPage } from "./InstitutionAwaitingApprovalPage";
import { TraderAwaitingApprovalPage } from "./TraderAwaitingApprovalPage";
import { UnaccreditedSellerAwaitingApprovalPage } from "./UnaccreditedSellerAwaitingApprovalPage";

const AwaitingApprovalPage = () => {
  const actor = useCurrentActor();
  const isInstitutionUser = getIsInstitutionUser(actor);
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
  const isBroker = getIsBroker(actor);

  if (isInstitutionUser) {
    return <InstitutionAwaitingApprovalPage />;
  }

  if (isUnaccreditedSeller) {
    return <UnaccreditedSellerAwaitingApprovalPage />;
  }

  if (isBroker) {
    return <BrokerAwaitingApprovalPage />;
  }

  return <TraderAwaitingApprovalPage />;
};

export default AwaitingApprovalPage;
