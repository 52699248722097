import {
  UserPermissionV2,
  GetAvailableUserPermissionsUserFragment,
} from "@/gql";
import { iHaveEntityPermission } from "@/hooks";

const getCanSignUnsignedCustomerAgreement = (
  user: GetAvailableUserPermissionsUserFragment,
) =>
  iHaveEntityPermission(user, UserPermissionV2.SignUnsignedCustomerAgreement);

const getCanCreateListing = (user: GetAvailableUserPermissionsUserFragment) =>
  iHaveEntityPermission(user, UserPermissionV2.CreateListing);

const getCanPlaceStandingBid = (
  user: GetAvailableUserPermissionsUserFragment,
) => iHaveEntityPermission(user, UserPermissionV2.PlaceStandingBid);

export const getAvailableUserPermissions = (
  user: GetAvailableUserPermissionsUserFragment,
) => {
  const canSignUnsignedCustomerAgreement =
    getCanSignUnsignedCustomerAgreement(user);

  const canCreateListing = getCanCreateListing(user);
  const canPlaceStandingBid = getCanPlaceStandingBid(user);

  return {
    canSignUnsignedCustomerAgreement,
    canCreateListing,
    canPlaceStandingBid,
  };
};
