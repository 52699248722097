import { CaretDown, CaretRight } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  CardBody,
  Flex,
  HStack,
  Text,
  useDisclosure,
  chakra,
} from "@chakra-ui/react";

import { Tasks } from "@/components/transactions";
import {
  TransactionExecutionPageWorkflowStepFragment,
  TransactionExecutionPageTransactionFragment,
} from "@/gql";
import { toTimestamp } from "@/utils/datetime";

const CompletedStep = ({
  step,
  isBuySide,
  transaction,
}: {
  readonly step: TransactionExecutionPageWorkflowStepFragment;
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation(`execution`);

  const { onToggle, isOpen } = useDisclosure();

  const hasTasks = step.tasks.length > 0;

  return (
    <CardBody
      p={0}
      _last={{ borderBottomRadius: 6, border: `none` }}
      overflow="hidden"
    >
      <HStack
        spacing={4}
        px={6}
        w="full"
        justifyContent="space-between"
        transition="background-color .1s ease-in-out"
        {...(hasTasks && {
          _hover: {
            bg: `grey.15`,
          },
          _active: {
            bg: `grey.25`,
          },
          onClick: onToggle,
          as: chakra.button,
        })}
      >
        <HStack
          justifyContent="space-between"
          py={4}
          flexWrap="wrap"
          w="full"
          {...(isOpen && {
            pb: 4,
          })}
        >
          <Text textStyle="heading-md">{step.name}</Text>
          <Text textAlign="start">
            {t(`completed_date`, {
              completedAt: toTimestamp(step.completedAt),
            })}
          </Text>
        </HStack>
        {hasTasks && (isOpen ? <CaretDown /> : <CaretRight />)}
      </HStack>
      {isOpen && (
        <Flex
          direction="column"
          gap={4}
          p={6}
          borderTop="1px solid"
          borderColor="grey.100"
        >
          <Tasks
            tasks={step.tasks}
            isBuySide={isBuySide}
            transaction={transaction}
          />
        </Flex>
      )}
    </CardBody>
  );
};

export default CompletedStep;
