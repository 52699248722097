import { Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import router from "next/router";

import {
  GridItem,
  ModalBody,
  Show,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveAdvancedOptionsDropdown,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormikQL, CheckboxInput } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  StandingBidPageStandingBidByIdDocument,
  UserWithInstitutionFragment,
  useWithdrawStandingBidMutation,
  WithdrawStandingBidModalStandingBidFragment,
  WithdrawStandingBidMutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import { makeUrl, Nullable } from "@/utils";

interface WithdrawStandingBidModalFormValues {
  readonly standingBidId: string;
  readonly muteNotifyWatchers: boolean;
}

const createInitialValues = (
  standingBid: WithdrawStandingBidModalStandingBidFragment,
): Nullable<WithdrawStandingBidModalFormValues> => ({
  standingBidId: standingBid.id,
  muteNotifyWatchers: false,
});

const validationSchema = Yup.object().shape({
  standingBidId: Yup.string().nullable().required(`Required`),
  muteNotifyWatchers: Yup.boolean().nullable(),
});

const mapVariables = ({
  standingBidId,
  ...values
}: WithdrawStandingBidModalFormValues) => ({
  standingBidId,
  input: {
    ...values,
  },
});

interface WithdrawStandingBidModalProps {
  readonly standingBid: WithdrawStandingBidModalStandingBidFragment;
  readonly actor: UserWithInstitutionFragment;
}
const WithdrawStandingBidModal = ({
  actor,
  standingBid,
}: WithdrawStandingBidModalProps) => {
  const mutation = useWithdrawStandingBidMutation({
    refetchQueries: [StandingBidPageStandingBidByIdDocument],
  });

  const { t } = useTranslation();

  const { closeModal } = useModal();

  const { successToast } = useCustomToast();

  const onSuccess = (response: WithdrawStandingBidMutation) => {
    successToast(`Standing bid withdrawn.`);

    const company = response?.withdrawStandingBid?.standingBid?.company;

    if (company) {
      router.push(makeUrl(company));
    }

    closeModal();
  };

  const initialValues = createInitialValues(standingBid);

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        mutation={mutation}
        mutationNames={[`withdrawStandingBid`]}
        mapVariables={mapVariables}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSuccess={onSuccess}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <HiiveModalHeader>
              Withdraw Standing Bid{` `}
              <Text as="span">{standingBid.displayId}</Text>
            </HiiveModalHeader>
            <ModalBody>
              <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
                <GridItem colSpan={2}>
                  <Text textStyle="text-md">
                    Are you sure? You can&apos;t undo this action afterwards.
                  </Text>
                </GridItem>
                {actor.isHiiveUser && (
                  <GridItem colSpan={2}>
                    <HiiveAdvancedOptionsDropdown
                      validationSchema={validationSchema}
                      fieldNames={[`muteNotifyWatchers`]}
                    >
                      <VStack spacing={4} alignItems="flex-start">
                        <GridItem colSpan={2}>
                          <CheckboxInput
                            name="muteNotifyWatchers"
                            label={t(`do_not_send_activity_notifications`)}
                          />
                        </GridItem>
                      </VStack>
                    </HiiveAdvancedOptionsDropdown>
                  </GridItem>
                )}
              </SimpleGrid>
            </ModalBody>
            <HiiveModalFooter>
              <Show above="md" ssr={false}>
                <HiiveCancelButton
                  sentryLabel="[WithdrawStandingBid/Cancel]"
                  onCancel={closeModal}
                />
              </Show>
              <HiiveSubmitButton
                sentryLabel="[WithdrawStandingBid/Submit]"
                submitText="Withdraw Standing Bid"
                type="submit"
                isLoading={isSubmitting}
              />
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default withCurrentActor(WithdrawStandingBidModal);
