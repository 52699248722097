import isNil from "lodash/isNil";
import { useEffect } from "react";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  VStack,
  Text,
  UnorderedList,
  ListItem,
  Box,
  HStack,
  BoxProps,
} from "@chakra-ui/react";

import { HiiveButton, Skeleton } from "@/components/common";
import {
  FormRadioTile,
  FormTextAreaInput,
  TileCheckboxInput,
} from "@/components/react-hook-form";
import {
  AccreditationQuestion,
  Maybe,
  useAnswerInstitutionAccreditationQuestionsMutation,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { shadows } from "@/styles/theme";

import { InstitutionAccreditationFormProps } from "./InstitutionAccreditationForm";
import { UseAccreditationFormProps } from "./InvestorAccreditationPageV2";

const QIBQuestionKey = `us-institutional-qib-v3`;
const OtherAccreditationCriteriaQuestionKey = `us-institutional-other-criteria-v3`;

interface InstitutionAccreditationFormValues {
  readonly accreditationOptionKey?: string | null;
  readonly accreditationOptionText?: string | null;
  readonly isQib: boolean | null;
}

export const useUSInstitutionAccreditationForm = ({
  accreditationQuestions,
  accreditationAnswers,
  onSuccess,
  mutationOptions,
}: UseAccreditationFormProps) => {
  const { t } = useTranslation();
  const mutation =
    useAnswerInstitutionAccreditationQuestionsMutation(mutationOptions);

  const validationSchema = Yup.object().shape({
    accreditationOptionKey: Yup.string()
      .oneOf(accreditationQuestions.map((q: AccreditationQuestion) => q.key))
      .required(t`required`),
    accreditationOptionText: Yup.string()
      .nullable()
      .when(`accreditationOptionKey`, {
        is: OtherAccreditationCriteriaQuestionKey,
        then: (schema) => schema.required(t`required`),
        otherwise: (schema) => schema.optional(),
      }),
    isQib: Yup.boolean().required(),
  });

  const hasPreviousAnswers =
    !!accreditationAnswers && accreditationAnswers.length > 0;

  const initialValues = {
    accreditationOptionKey: hasPreviousAnswers
      ? accreditationAnswers[0].question.key
      : null,
    accreditationOptionText: hasPreviousAnswers
      ? accreditationAnswers[0].text
      : null,
    isQib: hasPreviousAnswers
      ? accreditationAnswers[0].question.key === QIBQuestionKey
      : null,
  };

  const mapVariables = ({
    accreditationOptionKey,
    accreditationOptionText,
  }: InstitutionAccreditationFormValues) => ({
    input: {
      answers: [
        {
          key: accreditationOptionKey || ``,
          text:
            accreditationOptionKey === OtherAccreditationCriteriaQuestionKey
              ? accreditationOptionText
              : null,
        },
      ],
    },
  });

  return useFormQL({
    mutation,
    mode: `onChange`,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
  });
};

export const USInstitutionAccreditationFormFields = ({
  watch,
  control,
  setValue,
  accreditationQuestions,
  ...boxProps
}: {
  readonly watch: UseFormWatch<InstitutionAccreditationFormValues>;
  readonly control: Control<InstitutionAccreditationFormValues>;
  readonly setValue: UseFormSetValue<InstitutionAccreditationFormValues>;
  readonly accreditationQuestions: Maybe<AccreditationQuestion>[];
} & BoxProps) => {
  const { t } = useTranslation();
  const qibQuestion = accreditationQuestions.find(
    (question: AccreditationQuestion) => question.key === QIBQuestionKey,
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === `isQib`) {
        setValue(
          `accreditationOptionKey`,
          !!value.isQib ? qibQuestion?.key : null,
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const isQib = watch(`isQib`);
  const accreditationOptionKey = watch(`accreditationOptionKey`);
  return (
    <FormRadioTile.Group
      size="md"
      p={{ base: 4, lg: 6 }}
      borderRadius="md"
      borderColor="grey.200"
      borderWidth={0.5}
      boxShadow={shadows.card}
      gap={4}
      bg="white"
      w={{ base: `full`, lg: 160 }}
      {...boxProps}
    >
      <Text textStyle="heading-lg">{qibQuestion?.text}</Text>
      <TileCheckboxInput.Tooltip>
        <VStack alignItems="flex-start" p={2}>
          <Text>{t`us_institution_accreditation_qib_description`}:</Text>
          <UnorderedList>
            <ListItem>{t`us_institution_accreditation_qib_description_bullet_1`}</ListItem>
            <ListItem>{t`us_institution_accreditation_qib_description_bullet_2`}</ListItem>
            <ListItem>{t`us_institution_accreditation_qib_description_bullet_3`}</ListItem>
            <ListItem>{t`us_institution_accreditation_qib_description_bullet_4`}</ListItem>
          </UnorderedList>
        </VStack>
      </TileCheckboxInput.Tooltip>
      <HStack
        w={boxProps?.w === `full` ? { base: `full`, lg: 160 } : undefined}
      >
        <FormRadioTile.Tile
          name="isQib"
          control={control}
          value
          alignItems="center"
        >
          <Box justifyContent="center" alignItems="flex-start">
            <Text>{t`yes`}</Text>
          </Box>
        </FormRadioTile.Tile>
        <FormRadioTile.Tile
          name="isQib"
          control={control}
          value={false}
          alignItems="center"
        >
          <Box justifyContent="center" alignItems="flex-start">
            <Text>{t`no`}</Text>
          </Box>
        </FormRadioTile.Tile>
      </HStack>
      {!isNil(isQib) && !isQib && (
        <VStack alignItems="flex-start" p={2} mt={4} gap={6}>
          <Text textStyle="heading-lg">{t`us_institution_accreditation_how_are_you_accredited`}</Text>
          <VStack alignItems="flex-start" gap={4}>
            {accreditationQuestions
              .filter((q: AccreditationQuestion) => q.key !== qibQuestion?.key)
              .map((q: AccreditationQuestion) => (
                <FormRadioTile.Tile
                  key={q.id}
                  name="accreditationOptionKey"
                  control={control}
                  value={q.key}
                  alignItems="flex-start"
                >
                  <Box justifyContent="center" alignItems="flex-start">
                    <Text>{q.text}</Text>
                  </Box>
                </FormRadioTile.Tile>
              ))}
          </VStack>
          {accreditationOptionKey === OtherAccreditationCriteriaQuestionKey && (
            <VStack alignItems="flex-start" w="full">
              <Text fontWeight={500}>{t`please_describe`}</Text>
              <FormTextAreaInput
                w="full"
                control={control}
                name="accreditationOptionText"
                label=""
                placeholder={t`description`}
                labelSrOnly
              />
            </VStack>
          )}
        </VStack>
      )}
    </FormRadioTile.Group>
  );
};

export const USInstitutionAccreditationForm = ({
  questionGroup,
  onSuccess,
}: InstitutionAccreditationFormProps) => {
  const actor = useCurrentActor();
  const { t } = useTranslation();
  const accQuestions = questionGroup?.questions || [];

  const { handleSubmit, isLoading, control, formState, watch, setValue } =
    useUSInstitutionAccreditationForm({
      accreditationQuestions: accQuestions,
      accreditationAnswers: actor?.institution?.accreditationAnswers,
      onSuccess,
    });

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      id="institution-accreditation-form"
    >
      <USInstitutionAccreditationFormFields
        control={control}
        watch={watch}
        setValue={setValue}
        accreditationQuestions={accQuestions}
      />
      <HStack p={2} mt={6} justifyContent="flex-end" w="full">
        <HiiveButton
          sentryLabel="[InstitutionAccreditationPageV2/Submit]"
          variant="rounded-solid-salmon"
          type="submit"
          size="xl"
          maxW="unset"
          form="institution-accreditation-form"
          w={{ base: `full`, lg: `unset` }}
          isLoading={isLoading}
          onClick={handleSubmit}
          isDisabled={!formState?.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </HStack>
    </form>
  );
};

export const USInstitutionAccreditationFormSkeleton = () => (
  <VStack w={{ base: `full`, lg: 160 }} gap={2}>
    <Skeleton h={450} />
    <Skeleton h={50} />
    <VStack alignItems="flex-end" w="full">
      <Skeleton h={10} w={155} />
    </VStack>
  </VStack>
);
