import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";

import { IssuerProfilePageCompanyFragment } from "@/gql";

interface IssuerDescriptionCardProps {
  company: IssuerProfilePageCompanyFragment;
}

export const IssuerDescriptionCard = ({
  company,
}: IssuerDescriptionCardProps) => {
  const { t } = useTranslation();
  const { logoUrl, name, description } = company;

  return (
    <Card variant="flat">
      <CardHeader>
        <HStack minH={10}>
          {logoUrl && (
            <Image
              h={{ base: 6, sm: 8 }}
              src={logoUrl}
              alt={name}
              mr={{ base: 2, sm: 4 }}
            />
          )}
          <Text
            as="h1"
            w="full"
            lineHeight="initial"
            textStyle={{
              base: `heading-2xl`,
              md: `heading-4xl`,
            }}
          >
            {name}
          </Text>
        </HStack>
      </CardHeader>
      <CardBody>
        <Text as="h4" w="full" textStyle="heading-sm" mb={2}>
          {t(`about`)}
        </Text>
        <Text>{description || t(`na`)}</Text>
      </CardBody>
    </Card>
  );
};
