import currency from "currency.js";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  HStack,
  Link,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";

import { constants } from "@/utils";

const {
  email_execution,
  HIIVE_BANKING_INFO: {
    BANK_NAME,
    BENEFICIARY_ADDRESS,
    BENEFICIARY_NAME,
    BANK_ADDRESS,
    SWIFT_CODE,
  },
} = constants;

type WireInformationProps = {
  accountNumber: string;
  amount: number;
  routingNumber: string;
};

const WireInformation = ({
  accountNumber,
  amount,
  routingNumber,
}: WireInformationProps) => {
  const { t } = useTranslation(`transactions`);

  return (
    <Flex flexDirection="column" w="full" gap={4}>
      <VStack gap={3}>
        <Text>
          {t(`settlement_description_introduction`, {
            bankName: BANK_NAME,
          })}
        </Text>
        <Text>
          <Trans
            values={{ email: email_execution }}
            components={{
              a: (
                <Link
                  href={`mailto:${email_execution}`}
                  rel="noreferrer"
                  target="_blank"
                  textDecoration="underline"
                />
              ),
              bold: <strong />,
            }}
            i18nKey="settlement_description_closing"
            ns="transactions"
          />
        </Text>
      </VStack>
      <Box>
        <Card w="full" overflow="hidden" boxShadow="none">
          <CardHeader py={4} px={6}>
            <VStack gap={1} alignItems="start">
              <Text color="grey.400" fontSize={12} fontWeight={500}>
                {t(`payment_amount`)}
              </Text>
              <Text color="grey.900" fontSize={20} fontWeight={500}>
                {currency(amount / 100).format()}
              </Text>
            </VStack>
          </CardHeader>
          <CardBody p={0} bg="grey.25">
            <Card
              variant="outline"
              border="none"
              bg="none"
              boxShadow="none"
              borderBottom="1px solid"
              borderRadius="none"
              borderColor="grey.200"
            >
              <CardBody p={0} py={4} px={6}>
                <VStack gap={1} alignItems="start">
                  <Text color="grey.400" fontSize={12} fontWeight={500}>
                    {t(`beneficiary_details`)}
                  </Text>
                  <Text color="grey.700" fontSize={16} fontWeight={500}>
                    {BENEFICIARY_NAME}
                  </Text>
                  <Text color="grey.900" fontSize={14} fontWeight={400}>
                    {BENEFICIARY_ADDRESS}
                  </Text>
                </VStack>
              </CardBody>
            </Card>
            <Card variant="outline" border="none" bg="none" boxShadow="none">
              <CardBody p={0} py={4} px={6}>
                <VStack alignItems="start">
                  <VStack gap={1} alignItems="start">
                    <Text color="grey.400" fontSize={12} fontWeight={500}>
                      {t(`beneficiary_bank_details`)}
                    </Text>
                    <Text color="grey.700" fontSize={16} fontWeight={500}>
                      {BANK_NAME}
                    </Text>
                    <Text color="grey.900" fontSize={14} fontWeight={400}>
                      {BANK_ADDRESS}
                    </Text>
                  </VStack>
                  <Divider borderColor="grey.100" />
                  <List as={VStack} w="full" alignItems="start" gap={2}>
                    <ListItem
                      as={HStack}
                      w="full"
                      justifyContent="space-between"
                    >
                      <Text color="grey.900" fontSize={14} fontWeight={500}>
                        {t(`account_number`)}
                      </Text>
                      <Text>{accountNumber}</Text>
                    </ListItem>
                    <ListItem
                      as={HStack}
                      w="full"
                      justifyContent="space-between"
                    >
                      <Text color="grey.900" fontSize={14} fontWeight={500}>
                        {t(`routing_number`)}
                      </Text>
                      <Text>{routingNumber}</Text>
                    </ListItem>
                    <ListItem
                      as={HStack}
                      w="full"
                      justifyContent="space-between"
                    >
                      <Text color="grey.900" fontSize={14} fontWeight={500}>
                        {t(`swift_code`)}
                      </Text>
                      <Text>{SWIFT_CODE}</Text>
                    </ListItem>
                  </List>
                </VStack>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </Box>
    </Flex>
  );
};

export default WireInformation;
