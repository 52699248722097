import { isString } from "lodash";
import { Trans, useTranslation } from "react-i18next";

import {
  Button,
  CardBody,
  CardFooter,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  AddHoldingsForm as InnerAddHoldingsForm,
  createValidationSchema,
  HoldingDetailsFormFieldValues,
} from "@/components/unaccredited-seller";
import {
  HoldingsDocument,
  HoldingType,
  ListedHoldingFragment,
  UnlistedHoldingFragment,
  useAddHoldingsV2Mutation,
} from "@/gql";
import { useFormQL } from "@/hooks/react-hook-form";
import constants from "@/utils/constants";

export const AddHoldingsForm = ({
  holdings,
  onClose,
}: {
  readonly onClose: () => void;
  readonly holdings?:
    | (ListedHoldingFragment | UnlistedHoldingFragment)[]
    | null;
}) => {
  const count = holdings?.length || 0;

  const { t } = useTranslation();
  const mutation = useAddHoldingsV2Mutation({
    refetchQueries: [HoldingsDocument],
  });

  const validationSchema = createValidationSchema(t);

  const initialValues: HoldingDetailsFormFieldValues = {
    holdings: [],
    sellerLotDisclaimer: false,
  };
  const mapVariables = ({
    sellerLotDisclaimer: _sellerLotDisclaimer,
    holdings,
    ...values
  }: HoldingDetailsFormFieldValues) => ({
    addHoldingsInput: {
      holdings: holdings.map(({ companyId, numShares, type, name }) => ({
        value: type === HoldingType.Listed ? companyId || `` : name,
        numShares: isString(numShares) ? parseInt(numShares, 10) : numShares,
        type: type || HoldingType.Listed,
      })),
      ...values,
    },
  });

  const { control, watch, handleSubmit } = useFormQL({
    mutation,
    initialValues,
    mapVariables,
    validationSchema,
    onSuccess: onClose,
  });

  const formId = `editHoldingsForm`;
  const values = watch();

  const existentHoldings = holdings
    ?.filter((holding) => holding.__typename === `Holding`)
    .map((holding: ListedHoldingFragment) => ({
      type: HoldingType.Listed,
      name: holding.company.name,
      numShares: holding.numSharesV2,
      logoUrl: holding.company.logoUrl,
      companyId: holding.companyId,
    }));

  return (
    <>
      <CardBody as={VStack} alignItems="flex-start">
        <VStack alignItems="flex-start" maxW={144}>
          <VStack spacing={4} alignItems="flex-start" mb={2}>
            <Text>{t(`add_holding_description`)}</Text>
            <Text>
              <Trans
                i18nKey="current_holdings"
                count={count}
                components={{ bold: <strong style={{ fontWeight: `500` }} /> }}
                values={{ count, remaining: constants.max_holdings - count }}
              />
            </Text>
          </VStack>
        </VStack>
        <InnerAddHoldingsForm
          formId={formId}
          control={control}
          watch={watch}
          handleSubmit={handleSubmit}
          existentHoldings={existentHoldings}
          maxWidth={144}
        />
      </CardBody>
      <CardFooter h={113}>
        <HStack gap={4} flexDirection="row-reverse" w="full">
          <Button
            type="submit"
            variant="rounded-solid-salmon"
            size="xl"
            form={formId}
            w={{ base: `full`, lg: 180 }}
            minW="auto"
            isDisabled={values?.holdings?.length === 0}
          >
            {t(`submit`)}
          </Button>
          <Button
            variant="rounded-outline-grey"
            size="xl"
            onClick={onClose}
            w={{ base: `full`, lg: 180 }}
            minW="auto"
          >
            {t(`cancel`)}
          </Button>
        </HStack>
      </CardFooter>
    </>
  );
};
