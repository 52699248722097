import { ReactNode } from "react";

import { Text, Box, TextProps } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";
import { withCurrentActor, WithCurrentActorProps } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { useObscureCompanyInfo } from "@/hooks";

interface SensitiveTextProps extends TextProps {
  readonly children: ReactNode;
  readonly isSensitive?: boolean;
}

interface SensitiveProps extends TextProps, WithCurrentActorProps {
  readonly children: ReactNode;
  readonly isSensitive?: boolean;
  readonly actor: UserWithInstitutionFragment;
}

const SensitiveTextInner = withCurrentActor(
  ({
    actor,
    children,
    userNotificationToken: _,
    ...textProps
  }: SensitiveProps) => {
    const { showInfo, message } = useObscureCompanyInfo(actor);

    if (showInfo)
      return (
        <Text as="span" {...textProps}>
          {children}
        </Text>
      );

    return (
      <CustomTooltip tooltipContent={message}>
        <Text cursor="default" {...textProps}>
          <Box as="span" layerStyle={showInfo ? undefined : `sensitive`}>
            {children}
          </Box>
        </Text>
      </CustomTooltip>
    );
  },
);

const SensitiveText = ({
  children,
  isSensitive = true,
  ...textProps
}: SensitiveTextProps) =>
  isSensitive ? (
    <SensitiveTextInner {...textProps} data-dd-privacy="mask">
      {children}
    </SensitiveTextInner>
  ) : (
    <Text as="span" {...textProps}>
      {children}
    </Text>
  );

export default SensitiveText;
