const locale = {
  ahead_of_that_call_we_encourage_you: `Ahead of that call, we encourage you to review the following resources, so that you understand the purpose of the Hiive platform, the types of investments available through the Hiive platform, how Hiive works, and the risks associated with investing in the stock of private companies or funds.`,
  before_we_do_that_institution: `Before we do that, one of our registered representatives will reach out to you by phone to ask you a few questions and give you some additional information, all designed to ensure the platform matches your firm or fund's investing objectives. This call should take no more than 5-10 minutes.`,
  before_we_do_that: `Before we do that, one of our registered representatives will reach out to you by phone to ask you a few questions and give you some additional information, all designed to ensure the platform matches your investing objectives. This call should take no more than 5-10 minutes.`,
  firm_missing_information: `Your firm/fund’s profile is missing information`,
  form_crs_for_hiive_markets_limited: `Form CRS for Hiive Markets Limited`,
  if_you_do_not_live_in_canada_or_the_US: `If you do not live in Canada or the US, and you do not hear from us, it means we are not able to grant you access to the platform at this time. We are actively working to expand our geographic coverage and will reach out to you once we are able to work with you.`,
  if_you_have_any_questions_about_the_status_of_your_registration: `If you have any questions about the status of your registration or would like more information, please contact: <mailto>hiiveconnect@hiive.com</mailto>`,
  if_you_have_any_questions_in_the_meantime: `If you have any questions in the meantime, please reach out to <mailto>support@hiive.com</mailto>.`,
  if_you_have_any_questions: `If you have any questions in the meantime or need more information please contact: <mailto>support@hiive.com</mailto>.`,
  if_you_reside_in_the_US_or_canada_please_contact_us: `If you reside in the United States or Canada, please contact us at <mailto>support@hiive.com</mailto>, with clarifying information about your residency, so we can update your application. If you reside outside of the United States or Canada, and there is a basis to approve your application, we will reach out to you at this email address within 24 hours to gather additional information about your application.`,
  missing_profile_info: `Missing profile information`,
  our_accounts_team_is_reviewing: `Our Accounts team is reviewing your information. If there is a basis to approve your account, we will reach out to you by email.`,
  please_reach_out: `If you have any question, please reach out to us at <mailto>support@hiive.com</mailto>.`,
  please_schedule_a_call: `Please schedule a call`,
  please_select_a_time: `Please select a time here`,
  regulation_best_interest_disclosure: `Regulation Best Interest disclosure for Hiive Markets Limited`,
  risk_disclosures_and_disclaimers: `Risk disclosures and disclaimers`,
  seek_admin_complete_information: `Ask your Admin to log in to Hiive to complete this information. Once completed, you can access the platform as usual.`,
  thank_you_for_your_interest_in_hiive_unsuitable: `Thank you for your interest in the Hiive platform. We look forward to approving your account as soon as possible.`,
  thank_you_for_your_interest_in_the_hiive_platform_institution: `Thank you for your interest in the Hiive platform. We look forward to approving your account as soon as possible.`,
  thank_you_for_your_interest_in_the_hiive_platform_other_supported_individual: `Thank you for your interest in the Hiive platform. We look forward to approving your account as soon as possible. We may need some more information from you, in which case we will reach out to you at the email address you provided during onboarding.`,
  thank_you_for_your_interest_in_the_hiive_platform_us_cad_individual: `Thank you for your interest in the Hiive platform. We look forward to approving your account as soon as possible. Before we do that, we need to set up a short call with one of our registered representatives, who will ask you a few questions and give you some additional information, all designed to ensure the platform matches your investing objectives.`,
  thank_you_for_your_interest_in_the_hiive_platform: `Thank you for your interest in the Hiive platform. We look forward to approving your account as soon as possible. Before we do that, we need to set up a short call with one of our registered representatives, who will ask you a few questions and give you some additional information, all designed to verify your eligibility for the platform and ensure the platform matches your investing objectives.`,
  thank_you_for_your_interest_u16r: `Thank you for your interest in the Hiive platform.`,
  thank_you_for_your_interest: `Thank you for your interest`,
  thank_you_for_your_patience: `Thank you for your patience!`,
  the_call_should_take_5_10_minutes: `The call should take no more than about 5-10 minutes.`,
  this_usually_takes_a_minute: `This usually takes just a minute or two, but if we run into any difficulties or need more information to complete your identify verification, it might take a bit longer. We will reach out to you by email if we need any additional information to verify your identity.`,
  verify_your_identity: `Verify your identity`,
  we_are_excited_to_welcome_you_to_hiive_connect: `We are excited to welcome you to HiiveConnect.`,
  we_are_excited_to_welcome_you_to_hiive: `We are excited to welcome you to the Hiive platform.`,
  we_are_excited_to_welcome_you: `We are excited to welcome you to the Hiive platform. Our Accounts team is currently reviewing your information and we will get back to you as soon as possible.`,
  we_are_verifying_your_identity: `We are verifying your identity.`,
  we_have_sent_all_of_the_above_info: `We have sent all of the above information to you by email, for your records.`,
  welcome_to_hiive_connect: `Welcome to HiiveConnect`,
  welcome_to_hiive: `Welcome to Hiive`,
  your_account_is_pending_approval: `Your account is currently pending approval, either because (1) you reside in a geography that is not supported by Hiive, or (2) you have added to your holdings a company that is not currently listed on Hiive.`,
  your_hiive_account_is_pending_approval: `Your Hiive account is pending approval because you have indicated that you reside outside of the United States or Canada. Due to local laws, you may not be able to access investments through the Hiive platform.`,
  your_information_has_been_submitted_to_compliance: `Your information has been submitted for review by our compliance team, and we will respond within 48-72 hours.`,
  your_information_has_been_submitted_to_operations: `Your information has been submitted for review to our operations team. For any questions or information contact: <mailto>support@hiive.com</mailto>`,
};

export default locale;
