import { User, Bank } from "@phosphor-icons/react";

import { Center } from "@chakra-ui/react";

import { EntityType } from "@/gql";
import { useColors } from "@/hooks";

type EntityItemIconProps = {
  type: EntityType;
};

const EntityItemIcon = ({ type }: EntityItemIconProps) => {
  const isIndividual = type === EntityType.Individual;

  const [grey600] = useColors([`grey.600`]);

  return (
    <Center
      bg="grey.100"
      borderWidth={1}
      borderColor="grey.50"
      h={12}
      w={12}
      borderRadius="100%"
    >
      {isIndividual ? (
        <User color={grey600} size={28} />
      ) : (
        <Bank color={grey600} size={28} />
      )}
    </Center>
  );
};

export default EntityItemIcon;
