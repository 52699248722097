import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Flex, Text, VStack } from "@chakra-ui/react";

import {
  ActivityCardSkeleton,
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  Skeleton,
  WithQuery,
} from "@/components/common";
import {
  BidState,
  PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBidFragment,
  usePostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery,
} from "@/gql";

import { PastBidCard } from "./PastBidCard";

const PastBidsEmptyState = () => {
  const { t } = useTranslation();

  return <ActivityGroupEmptyState message={t(`no_past_bids`)} />;
};

const PastBidsPageContent = ({
  pastBids,
}: {
  readonly pastBids: readonly PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBidFragment[];
}) => {
  const { t } = useTranslation();

  return (
    <ActivitySection pt={{ base: 4, lg: 8 }}>
      <VStack alignItems="flex-start" spacing={4}>
        <Text textStyle="heading-sm" textTransform="uppercase">
          {t(`past_bids`)}
        </Text>
        <Text textStyle="text-md">{t(`past_bids_description`)}</Text>
        <ActivityGroup emptyState={<PastBidsEmptyState />}>
          {pastBids.map((pastBid) => (
            <PastBidCard key={pastBid.id} pastBid={pastBid} />
          ))}
        </ActivityGroup>
      </VStack>
    </ActivitySection>
  );
};

const PastBidsPageSkeleton = () => (
  <Flex direction="column" gap={4} w="full" pt={{ base: 4, lg: 8 }}>
    <Skeleton h="20px" w="full" maxW="128px" />
    <Skeleton h="16px" maxW="55%" />
    <ActivityCardSkeleton includeBody />
  </Flex>
);

const PastBidsPageV2 = ({ id }: { id: string }) => {
  const query =
    usePostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery({
      variables: { id },
    });
  const router = useRouter();

  return (
    <WithQuery query={query} fallback={<PastBidsPageSkeleton />}>
      {({ data: { companyById: company } }) => {
        const listing = company.activity.myListings[0];

        if (!listing) {
          router.replace(`/dashboard/${id}`);
          return null;
        }

        const pastBids = listing.bids.filter((bid) =>
          [BidState.Withdrawn, BidState.Expired].includes(bid.state),
        );

        return <PastBidsPageContent pastBids={pastBids} />;
      }}
    </WithQuery>
  );
};

export default PastBidsPageV2;
