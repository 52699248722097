import { match, P } from "ts-pattern";

import {
  DiscussionState,
  GetDiscussionDocumentTextDiscussionFragment,
  GetDiscussionHasCounterpartyMessageDiscussionFragment,
  GetDiscussionHasCounterpartyMessageUserFragment,
  GetDiscussionIsSellerDiscussionFragment,
  GetDiscussionIsSellerUserFragment,
  GetDiscussionStatusTextDiscussionFragment,
  GetIsBidDiscussionBidFragment,
  GetIsBidDiscussionDiscussionFragment,
  GetDiscussionTopicCounterpartyShortTextListingFragment,
  GetDiscussionTopicCounterpartyShortTextStandingBidFragment,
  GetDiscussionTopicCounterpartyShortTextUserFragment,
  GetDiscussionTopicCounterpartyTextListingFragment,
  GetDiscussionTopicCounterpartyTextStandingBidFragment,
  GetDiscussionTopicCounterpartyTextUserFragment,
  TransferMethodV2,
} from "@/gql";
import { getIsSender, getIsSenderInstitution } from "@/utils";

export const getDiscussionIsSeller = (
  user: GetDiscussionIsSellerUserFragment,
  discussion: GetDiscussionIsSellerDiscussionFragment,
) =>
  match(discussion.topic)
    .with(
      { __typename: `Listing` },
      (topic) =>
        topic.sellerId === user.id ||
        (!!user.institutionId &&
          topic.sellerInstitutionId === user.institutionId),
    )
    .with(
      { __typename: `StandingBid` },
      (topic) =>
        topic.buyerId !== user.id &&
        (!user.institutionId ||
          topic.buyerInstitutionId !== user.institutionId),
    )
    .otherwise(() => {
      throw new Error(`Unhandled discussion in getDiscussionIsSeller`);
    });

export const getDiscussionDocumentOrderText = (
  discussion: GetDiscussionDocumentTextDiscussionFragment,
) =>
  match(discussion.topic)
    .with({ __typename: `StandingBid` }, () => `standing bid`)
    .with(
      { __typename: `Listing`, transferMethodV2: TransferMethodV2.HiiveFund },
      () => `order`,
    )
    .otherwise(() => `bid`);

export const getDiscussionStatusText = (
  discussion: GetDiscussionStatusTextDiscussionFragment,
) =>
  match(discussion)
    .with({ state: DiscussionState.Pending }, () => `Requested`)
    .with({ state: DiscussionState.Active }, () => `In Progress`)
    .otherwise(() => {
      throw new Error(`Unhandled discussion state in <YourDiscussionCard/>`);
    });

export const getDiscussionTopicCounterpartyText = (
  user: GetDiscussionTopicCounterpartyTextUserFragment,
  topic:
    | GetDiscussionTopicCounterpartyTextListingFragment
    | GetDiscussionTopicCounterpartyTextStandingBidFragment,
) =>
  match(topic)
    .with(
      {
        __typename: `Listing`,
        fromHiive: P.when((fromHiive) => fromHiive && !user.isHiiveUser),
      },
      () => `Message Hiive`,
    )
    .with(
      {
        __typename: `StandingBid`,
        fromHiive: P.when((fromHiive) => fromHiive && !user.isHiiveUser),
      },
      () => `Message Hiive`,
    )
    .with(
      {
        __typename: `Listing`,
        sellerInstitutionId: P.when(
          (sellerInstitutionId) =>
            sellerInstitutionId && sellerInstitutionId === user.institutionId,
        ),
        sellerId: P.when((sellerId) => sellerId !== user.id),
      },
      () => `Inquiry with Buyer`,
    )
    .with(
      { __typename: `Listing`, transferMethodV2: `HIIVE_FUND` },
      ({ sellerId }) =>
        sellerId === user.id ? `Message buyer` : `Message Hiive`,
    )
    .with({ __typename: `Listing` }, ({ sellerId }) =>
      sellerId === user.id ? `Message buyer` : `Message seller`,
    )
    .with(
      {
        __typename: `StandingBid`,
        buyerInstitutionId: P.when(
          (buyerInstitutionId) =>
            buyerInstitutionId && buyerInstitutionId === user.institutionId,
        ),
        buyerId: P.when((buyerId) => buyerId !== user.id),
      },
      () => `Inquiry with Seller`,
    )
    .with({ __typename: `StandingBid` }, ({ buyerId }) =>
      buyerId === user.id ? `Message seller` : `Message buyer`,
    )
    .otherwise(() => {
      throw new Error(`Unhandled state in getDiscussionTopicCounterpartyText`);
    });

export const getDiscussionTopicCounterpartyShortText = (
  user: GetDiscussionTopicCounterpartyShortTextUserFragment,
  topic:
    | GetDiscussionTopicCounterpartyShortTextListingFragment
    | GetDiscussionTopicCounterpartyShortTextStandingBidFragment,
) =>
  match(topic)
    .with({ __typename: `Listing` }, ({ sellerId }) =>
      sellerId === user.id ? `buyer` : `seller`,
    )
    .with({ __typename: `StandingBid` }, ({ buyerId }) =>
      buyerId === user.id ? `seller` : `buyer`,
    )
    .otherwise(() => {
      throw new Error(`Unhandled state in getDiscussionTopicCounterpartyText`);
    });

export const getDiscussionHasCounterpartyMessage = (
  user: GetDiscussionHasCounterpartyMessageUserFragment,
  discussion: GetDiscussionHasCounterpartyMessageDiscussionFragment,
) =>
  !!discussion.mostRecentMessage &&
  !getIsSender(user, discussion.mostRecentMessage) &&
  !getIsSenderInstitution(user, discussion.mostRecentMessage);

export const getIsBidDiscussion = (
  discussion: GetIsBidDiscussionDiscussionFragment,
  bids: readonly GetIsBidDiscussionBidFragment[],
) => bids.some((bid) => bid.discussion?.id === discussion.id);
